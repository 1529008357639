import _, {isArray, sortBy, uniq, sum, max, min,} from "lodash";
import {AssetType as TimeSeriesAssetType, AssetTypeList} from "./AssetParameterSelectCombo/AssetParameterSelectCombo";
import timeSeriesTemplates from "../../constants/timeSeriesTemplates.json";
import {Period} from "../../openapi/model/period";
import {GroupBy} from "../../openapi/model/groupBy";
import {ReportView} from "../../openapi/model/reportView";
import {TableRow} from "react-data-table-component";
import {AssetType} from "../../openapi/model/assetType";
import {LocationGroup} from "../../openapi/model/locationGroup";
import {AssetUseCase} from "../../openapi/model/assetUseCase";
import {AggregatedTimeSeriesStatsItem} from "../../openapi/model/aggregatedTimeSeriesStatsItem";
import {AggregatedStatsResponse} from "../../containers/report/latestDataReport/TimeSeriesStatsApiWrapper";
import {Moment} from "moment/moment";
import {Scheme} from "../../openapi/model/scheme";
import {DataPoint} from "../charts/SimpleLineChart";
import moment from "moment";

export interface TimeSeriesRequestParams {
    startDatetime: Moment;
    endDatetime: Moment;
    parameter: string;
    aggregation: string;
    period?: Period;
    group?: string;
    groupBy?: GroupBy;
    viewBy?: ReportView;
    assetType?: TimeSeriesAssetType[];
}

export interface NewTimeSeriesRequestParams {
    startDate: Moment;
    endDate: Moment;
    parameter: string;
    aggregation: string;
    period: Period;
    group?: string;
    groupBy?: GroupBy;
    viewBy?: ReportView;
    assetType: TimeSeriesAssetType[];
    useCase?: AssetUseCase[];
}

export function getFields(assetTypes: AssetTypeList): string[] {
    const parameters: string[] = (isArray(assetTypes)) ?
        assetTypes.reduce((accumulator: string[], value) =>
                [...accumulator, ...getFieldForAssetType(value)]
            , [])
        : getFieldForAssetType(assetTypes)
    return sortBy(uniq(parameters))
}

const getFieldForAssetType = (assetType: TimeSeriesAssetType): string[] => {
    return Object.values(timeSeriesTemplates[assetType].data_fields)
        .map((value) => {
            return value.name;
        })
        .concat(
            Object.values(timeSeriesTemplates[assetType].compound_aggregations).map(
                (value) => {
                    return value.name;
                }
            )
        ).concat(
            ['Quality Assessment']
        );
};

export function getUnitsForParameter(parameter: string): string {

    const units = new Map([
        ["Energy (Heating)", "Wh"],
        ["Energy (Cooling)", "Wh"],
        ["Energy (Electrical Active Import)", "Wh"],
        ["Energy (Electrical Active Export)", "Wh"],
        ["Energy (Electrical Reactive Import)", "VAR"],
        ["Energy (Electrical Reactive Export)", "VAR"],
        ["Volume", "m3"],
        ["Flow Temperature", "°C"],
        ["Return Temperature", "°C"],
        ["Power", "W"],
        ["Synthetic Volume", "m3"],
        ["Synthetic Energy", "m3"],
        ["Flow", "m3/hr"],
        ["Reactive Power", "VA"],
        ["Current", "A"],
        ["Frequency", "Hz"],
        ["Power Factor", ""],
        ["Humidity", "%"],
        ["Temperature", "°C"],
        ["CO2", "ppm"],
        ["RSSI", "dB"],
        ["Data Received", ""],
        ["Devices (Received)", ""],
        ["Devices (Total)", ""],
        ["Data Points Received (Total)", ""],
        ["Data Points Received (Good)", ""],
        ["Data Points Received (Parse Error)", ""],
        ["Data Points Received (Value Error)", ""],
        ["Data Points Received (Duplicate)", ""],
        ["Synthetic Run Time", "mins"]])
    return units.get(parameter) || ''
}

export function getAggregations(
    dataFieldName: string,
    assetType?: keyof typeof timeSeriesTemplates
): string[] {
    if (dataFieldName === 'Quality Assessment') {
        return [
            "expected_rows",
            "received_rows_complete",
            "received_rows_basic_complete",
            "received_rows_incomplete",
            "received_rows_with_errors",
            "received_rows_with_value_errors",
            "expected_data_points",
            "good_data_points",
            "value_error_data_points",

        ]
    }
    if (assetType) {
        const fields = [
            ...timeSeriesTemplates[assetType].data_fields,
            ...timeSeriesTemplates[assetType].compound_aggregations,
        ];
        return Object.values(fields).filter((value) => {
            return value.name === dataFieldName;
        })[0].aggregations;
    } else {
        const fieldNameSet: Set<string> = new Set();
        Object.values(timeSeriesTemplates).forEach((assetAggregations) => {
            const fields = [
                ...assetAggregations.data_fields,
                ...assetAggregations.compound_aggregations,
            ];
            const filtered = Object.values(fields).filter(
                (assetFieldAggregations) => {
                    return assetFieldAggregations.name === dataFieldName;
                }
            );
            if (filtered.length > 0) {
                filtered[0].aggregations.forEach((value: string) => {
                    fieldNameSet.add(value);
                });
            }
        });
        return [...fieldNameSet];
    }
}

export interface SingleAggregationTimeAssetData extends TableRow {
    companyId: string;
    companyName: string;
    schemeName: string;
    schemeId: string;
    assetId?: string;
    serialNumber?: string;
    assetType: AssetType;
    assetPositionId: string;
    assetPositionReference?: string;
    locationId: string;
    locationAddress?: string;
    locationCustomerReference?: string;

    data: { [key: string]: number | undefined | string };
}


export function filterGroupList(
    groups: LocationGroup[],
    filterPrefix?: string,
    level = 1,
): LocationGroup[] {
    if (filterPrefix === undefined) {
        return groups
    } else {
        const currentGroup = filterPrefix.split('/').slice(0, level).join('/')
        const filteredGroups = groups.filter(v => v.group === currentGroup)
        if (filteredGroups.length === 1) {
            if (currentGroup === filterPrefix) {
                return (filteredGroups[0].children && filteredGroups[0].children.length > 0) ? filteredGroups[0].children : [filteredGroups[0]]
            } else {
                return filterGroupList(filteredGroups[0].children || [], filterPrefix, level = level + 1)
            }
        } else {
            return []
        }
    }
}

export const aggregatedStatsCountToPercentage = (
    state: AggregatedStatsResponse
) => {
    return (
        scheme: string,
        use_case: AssetUseCase[] = [AssetUseCase.NONE, AssetUseCase.DOMESTIC, AssetUseCase.PROCESS, AssetUseCase.COMMERCIAL]
    ): [Moment, number][] => {
        return Object.entries(filterStatsByGroupAndUseCase(state, scheme, use_case)).map(([dateString, item]) => {
                return [
                    moment(dateString),
                    item.assetCount > 0 ? (
                        item.nonZeroCount / item.assetPositionCount
                    ) * 100 : 0
                ]
            }
        )

    }
}

type AggregatedTimeSeriesDateItem = { [datetime: string]: AggregatedTimeSeriesStatsItem }

function filterStatsByGroupAndUseCase(
    stats: AggregatedStatsResponse,
    scheme: string,
    useCase: AssetUseCase[],
): AggregatedTimeSeriesDateItem {

    if (Object.prototype.hasOwnProperty.call(stats, scheme)) {
        return combinedAggregatedTimeSeriesStatsItemsByUseCase(
            Object.entries(stats[scheme])
                .filter(
                    ([dataUseCase, _]) => useCase.map((v) => v.toString()).indexOf(dataUseCase) !== -1
                )
        )
    }
    return {}
}

function combinedAggregatedTimeSeriesStatsItemsByUseCase(items: [string, AggregatedTimeSeriesDateItem][]): AggregatedTimeSeriesDateItem {
    switch (items.length) {
        case 0:
            return {}
        case 1:
            return items[0][1]
        default:
            return Object.fromEntries(
                Object.keys(items[0][1]).map((date) => {
                    return [
                        date,
                        combineAggregatedTimeSeriesStatsItems(
                            items.map((v) => v[1][date])
                        )
                    ]
                })
            )

    }
}


function combineAggregatedTimeSeriesStatsItems(items: AggregatedTimeSeriesStatsItem[]): AggregatedTimeSeriesStatsItem {
    if (items.length === 1) {
        return items[0]
    } else {
        const theNonZeroCount = sum(items.map(v => v.nonZeroCount))
        const mean = sum(items.map(v => (v.sum || 0) *v.nonZeroCount))/theNonZeroCount;
        return {
            assetCount: sum(items.map(v => v.assetCount)),
            assetPositionCount: sum(items.map(v => v.assetPositionCount)),
            max: max(items.map(v => v.max)),
            mean: mean,
            min: min(items.map(v => v.min)),
            nonZeroCount: theNonZeroCount,
            presentCount: sum(items.map(v => v.presentCount)),
            sum: sum(items.map(v => v.sum)),
        }
    }
}

export function matchPeriod(period?: string): Period {
    try {
        const p =
            Period[_.upperCase(period?.replace("_", "")) as keyof typeof Period];
        if (p) {
            return p;
        }
        return Period.DAILY;
    } catch (err) {
        return Period.DAILY;
    }
}

export function aggregatedStatsSelector(
    key: keyof AggregatedTimeSeriesStatsItem
) {
    return (
        state: AggregatedStatsResponse,
        scheme: string,
        use_case: AssetUseCase[] = [AssetUseCase.NONE, AssetUseCase.DOMESTIC, AssetUseCase.PROCESS, AssetUseCase.COMMERCIAL]
    ): [Moment, number][] => {
        return Object.entries(filterStatsByGroupAndUseCase(state, scheme, use_case)).map(([dateString, item]) => {
            return [
                moment(dateString),

                _.isNumber(item[key]) ? (item[key] as number) : 0,
            ];
        });
    }
}


export function mapSchemesData(
    stats: AggregatedStatsResponse,
    schemes: Array<Scheme>,
    func: (stats: AggregatedStatsResponse, schemeId: string) => DataPoint[]
): [string, DataPoint[], string][] {
    return schemes.map((scheme: Scheme) => {
        return [
            scheme.schemeName ? scheme.schemeName : scheme.schemeId,
            func(stats, scheme.schemeId),
            scheme.schemeId,
        ];
    });
}

export function mapLocationGroupData(
    stats: AggregatedStatsResponse,
    groups: LocationGroup[],
    func: (stats: AggregatedStatsResponse, groupId: string) => DataPoint[]
): [string, DataPoint[], string][] {
    const res: [string, DataPoint[], string][] = groups.map(
        (group: LocationGroup) => [
            group.group,
            func(stats, group.group),
            group.group,
        ]
    );
    if (Object.prototype.hasOwnProperty.call(stats, "NONE")) {
        return [...res, ["NONE", func(stats, "NONE"), "NONE"]];
    }
    return res;
}