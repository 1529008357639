import { Entities, EntitiesPathMap } from "../constants/entities";

import { useState } from "react";
import axios, { AxiosResponseHeaders } from "axios";
import Config from "../Config";
import { convertKeys } from "./getList";
import * as _ from "lodash";
import { objectKeysToSnakeCase } from "../actions/helpers/apiService";
import { removeMapBlanks } from "../utils/object";

export interface CreateEntity<T = any, I = T> {
  createEntity: (entity: I) => Promise<[T, AxiosResponseHeaders]>;
  creating: boolean;
  error?: Error;
}

export interface CreateEntityProps<T = any> {
  entityType: Entities;
  entity: T;
  postCreate?: (data: any, headers: AxiosResponseHeaders) => any;
  path?: string;
  parseToJson?: boolean;
  put?: boolean;
}

const useCreateEntity = <T, I = Partial<T>>(
  entityType: Entities,
  path?: string,
  put = false,
  parseToJson = true
) => {
  const createEntity = (entity: I): Promise<[T, AxiosResponseHeaders]> => {
    updateState((s) => {
      return { ...s, creating: true };
    });
    return axios(
      Config.apiGateway.URL + "/" + (path ? path : EntitiesPathMap[entityType]),
      {
        method: put ? "PUT" : "POST",
        responseType: parseToJson ? "json" : "blob",
        data: _.isArray(entity)
          ? entity.map(removeMapBlanks).map(objectKeysToSnakeCase)
          : objectKeysToSnakeCase(removeMapBlanks(entity)),
        transformResponse: (data) => {
          if (data !== "" && parseToJson) {
            return convertKeys(JSON.parse(data));
          }
          return data;
        },
      }
    )
      .then((response) => {
        updateState((s) => {
          return { ...s, creating: false, error: undefined };
        });
        return [response.data, response.headers] as [T, AxiosResponseHeaders];
      })
      .catch((error) => {
        updateState((s) => {
          return { ...s, creating: false, error };
        });
        throw error;
      });
  };

  const [state, updateState] = useState<CreateEntity<T, I>>({
    creating: false,
    createEntity,
  });

  return state;
};

export default useCreateEntity;
