import React from 'react';
import {WirelessSurveyOut} from "../../openapi/model/wirelessSurveyOut";
import {Form, Formik, FormikHelpers} from "formik";
import  * as Yup from 'yup';
import FormComponent from "../../components/Forms/FormComponent";
import {Button} from "reactstrap";
import {ErrorBanner} from "../../components/Error/ErrorBanner";
import useCreateEntity from "../../hooks/createEntity";
import {WirelessSurveyDataCollectorOut} from "../../openapi/model/wirelessSurveyDataCollectorOut";
import {WirelessSurveyDataCollectorIn} from "../../openapi/model/wirelessSurveyDataCollectorIn";
import AssetSelectFormField from "./AssetSelectFormField";
import {WirelessSurveySamplePoint} from "../../openapi/model/wirelessSurveySamplePoint";

interface FormValues {
    dataCollectorName: string;
    assetId: string;
    samplePoints: WirelessSurveySamplePoint[];
}

function WirelessSurveyDataCollectorCreateForm(props: {
    survey: WirelessSurveyOut,
    refresh?: () => any,
}) {
    const {survey} = props;
    const {createEntity,  error} = useCreateEntity<WirelessSurveyDataCollectorOut, WirelessSurveyDataCollectorIn>(
        "WIRELESS_SURVEY_DATA_COLLECTOR",
        `wireless_survey/${survey.surveyId}/data_collector`

    )

    function submit(value: FormValues, {setSubmitting, resetForm}: FormikHelpers<FormValues>) {
        setSubmitting(true);
        createEntity({
            ...value,
            companyId: survey.companyId
        }).then(
            () => {
                resetForm()
                props.refresh && props.refresh()
            }
        ).finally(() => setSubmitting(false))
    }

    return (
        <Formik
            initialValues={{
                dataCollectorName: '', assetId: '', samplePoints: [],
            } as FormValues}
            validateOnMount={true}
            validationSchema={Yup.object().shape({
                dataCollectorName: Yup.string().required().min(5),
                assetId: Yup.string().required()
            })}
            onSubmit={submit}
        >
            {({touched, isValid, isSubmitting}): JSX.Element => (
                <><Form>
                    <FormComponent
                        fieldName={"dataCollectorName"}
                        label={"Name"}
                        submitSpinner={false}
                    />
                    <AssetSelectFormField assetType={'gateway'} name={'assetId'}
                                          companyId={survey.companyId}
                    />
                    <Button
                        type="submit"
                        color="primary"
                        disabled={isSubmitting || !isValid || !touched}
                    >
                        Create
                    </Button>
                </Form>
                    <ErrorBanner error={error}/>
                </>
            )}
        </Formik>
    );
}

export default WirelessSurveyDataCollectorCreateForm;