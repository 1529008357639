import React from "react";
import { Routes } from "react-router-dom";

import AssetRegisterListPage from "./AssetRegisterListPage";
import { SentryRoute } from "../../App";

export default function AssetRegisterPage(): JSX.Element {
  return (
    <Routes>
      <SentryRoute path="/" element={<AssetRegisterListPage />} />
    </Routes>
  );
}
