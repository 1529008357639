import React from "react";
import classNames from "classnames";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelectedCompany } from "../../../reducers/company";
import { useSelectedScheme } from "../../../reducers/scheme";
import { Company } from "../../../openapi/model/company";
import { Scheme } from "../../../openapi/model/scheme";

interface Props {
  to: string;
  children: any;
  company: boolean;
  active?: boolean;
  keepSearchString?: boolean;
  "data-cy"?: string;
  disabled?: boolean;
}

export const generateCompanySchemeHash = (
  company?: Company,
  scheme?: Scheme
): string => {
  let theHash = "";
  if (company) {
    theHash = theHash + `#company=${company.companyId}`;
  }
  if (scheme) {
    theHash = theHash + `&scheme=${scheme.schemeId}`;
  }
  return theHash;
};

const LinkButton = (props: Props): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedCompany = useSelectedCompany();
  const selectedScheme = useSelectedScheme();

  const keepSearchString =
    props.keepSearchString !== undefined ? props.keepSearchString : true;

  let theHash = "";
  if (props.company && selectedCompany) {
    theHash = theHash + `#company=${selectedCompany.companyId}`;
  }
  if (selectedScheme && selectedScheme) {
    theHash = theHash + `&scheme=${selectedScheme.schemeId}`;
  }

  return (
    <button
      disabled={props.disabled}
      className={classNames({
        "btn btn-primary": true,
        active: props.active,
      })}
      data-cy={props["data-cy"]}
      onClick={(): void => {
        navigate({
          pathname: props.to,
          search: keepSearchString ? location.search : undefined,
          hash: theHash,
        });
      }}
    >
      {props.children}
    </button>
  );
};

export default LinkButton;
