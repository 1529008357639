import React from "react";
import { useParams } from "react-router-dom";
import { SchemeElementProps } from "../../components/Scheme/SelectedScheme";
import GetEntity from "../../core/action/GetEntity";
import LocationDisplay from "./LocationDisplay";
import { OneCardPage } from "../../components/utils/OneCardPage";
import { ErrorBanner } from "../../components/Error/ErrorBanner";

const LocationHomePage = ({
  companyId,
  schemeId,
}: SchemeElementProps): JSX.Element => {
  const { locationId } = useParams<{ locationId: string }>();

  if (locationId === undefined) {
    throw new Error("LocationId must be specified.");
  }

  return (
    <>
      <GetEntity
        entityType={"LOCATION"}
        entityId={locationId}
        {...{ companyId, schemeId }}
        error={({ error }) => (
          <OneCardPage
            headerTitle={"Location Detail"}
            breadcrumbs={[{ name: "Error" }]}
          >
            <ErrorBanner error={error} />
          </OneCardPage>
        )}
        placeholder={() => (
          <OneCardPage
            headerTitle={"Location Detail"}
            loading={true}
            breadcrumbs={[{ name: "Loading" }]}
          >
            <></>
          </OneCardPage>
        )}
      >
        {(display) => <LocationDisplay {...display} />}
      </GetEntity>
    </>
  );
};

export default LocationHomePage;
