import React, { useContext } from "react";
import { DropdownItem } from "reactstrap";
import { PrivacyContext } from "reducers/privacy";

const SwitchPrivacyMode = (): JSX.Element => {
  const { dispatch, state } = useContext(PrivacyContext);
  const { privacyMode } = state;

  const switchMode = () => {
    if (privacyMode) {
      dispatch({ type: "TURN_PRIVACY_MODE_OFF" });
    } else {
      dispatch({ type: "TURN_PRIVACY_MODE_ON" });
    }
  };

  return (
    <DropdownItem onClick={switchMode}>
      <i className="ni ni-single-02" />
      <span>Switch Privacy Mode {privacyMode ? "Off" : "On"}</span>
    </DropdownItem>
  );
};

export default SwitchPrivacyMode;
