import React from 'react';

import {SelectCombo, SelectComboProps} from "components/Forms/SelectCombo/SelectCombo";
import timeSeriesTemplates from "constants/timeSeriesTemplates.json";
import {getFields} from "../model";

export type AssetType = keyof typeof timeSeriesTemplates
export type AssetTypeList = AssetType | AssetType[];

interface AssetParameterSelectComboProps extends Omit<SelectComboProps, 'options'> {
    assetType?: AssetTypeList;
}




function AssetParameterSelectCombo(props: AssetParameterSelectComboProps) {
    const {assetType, ...rest} = props

    const fields = assetType ? getFields(assetType) : [];

    return <SelectCombo
        {...rest}
        options={fields.map((v) => {
            return {label: v, value: v};
        })}
    />
}

export default AssetParameterSelectCombo;