import React from "react";
import { DeviceAsset } from "../../openapi/model/deviceAsset";
import { Col, Row } from "reactstrap";
import VirtualAssetCreateForm from "./virtual_assets/VirtualAssetCreateForm";
import { AssetPosition } from "../../openapi/model/assetPosition";
import VirtualAssetSummaryList from "./virtual_assets/VirtualAssetSummaryList";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import _ from "lodash";

const VirtualAssetTab = (props: {
  asset: DeviceAsset;
  assetPosition: AssetPosition;
}): JSX.Element => {
  const { asset, assetPosition } = props;
  return (
    <Row>
      {!_.isEmpty(assetPosition.childAssetPositions) && (
        <Col xs={12}>
          <h3>Current Virtual Assets</h3>
          <VirtualAssetSummaryList parentAssetPosition={assetPosition} />
        </Col>
      )}
      <DisplayIfUserType userTypes={"admin"}>
        <Col xs={12}>
          <h3>Create New Virtual Asset</h3>
          <VirtualAssetCreateForm
            parentAsset={asset}
            parentAssetPosition={assetPosition}
          />
        </Col>
      </DisplayIfUserType>
    </Row>
  );
};

export default VirtualAssetTab;
