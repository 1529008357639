/**
 * Meterpoint.io
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type LabellingCases = 'TITLE_CASE' | 'CAMEL_CASE' | 'SNAKE_CASE';

export const LabellingCases = {
    TITLECASE: 'TITLE_CASE' as LabellingCases,
    CAMELCASE: 'CAMEL_CASE' as LabellingCases,
    SNAKECASE: 'SNAKE_CASE' as LabellingCases
};