import React, { useState } from "react";
import { StreamingConfiguration } from "../../openapi/model/streamingConfiguration";
import { FieldArray, Formik, FormikHelpers } from "formik";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import { Button, Col, Row, Table } from "reactstrap";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import RadioButtonGroup from "../../components/Forms/RadioButtonGroup";
import * as Yup from "yup";
import FormComponent from "../../components/Forms/FormComponent";
import useUpdateEntity from "../../hooks/updateEntity";
import SuccessBanner from "../../components/Banner/SuccessBanner";

export default function StreamingSettingsForm(props: {
  streamingConfiguration: StreamingConfiguration;
}): JSX.Element {
  const [updated, setUpdated] = useState<boolean>(false);
  const [currentStreamingConfiguration, setCurrentStreamingConfiguration] =
    useState<StreamingConfiguration>(props.streamingConfiguration);
  const update = useUpdateEntity<StreamingConfiguration>(
    "STREAMING_CONFIGURATION",
    "",
    undefined,
    "streaming"
  );

  const submit = (
    values: StreamingConfiguration,
    { setSubmitting, resetForm }: FormikHelpers<StreamingConfiguration>
  ) => {
    setUpdated(false);
    update
      .updateEntity(values)
      .then(() => {
        setUpdated(true);
        setCurrentStreamingConfiguration(values);
        resetForm();
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <Formik
        onSubmit={submit}
        initialValues={currentStreamingConfiguration}
        validationSchema={Yup.object().shape({
          enabled: Yup.boolean().required(),
          endpoints: Yup.array()
            .of(
              Yup.object().shape({
                url: Yup.string().required().url(),
                authentication: Yup.object().shape({
                  username: Yup.string().required(),
                  password: Yup.string().required(),
                }),
              })
            )
            .max(3),
        })}
        validateOnMount={true}
        enableReinitialize={true}
      >
        {({
          values,
          setFieldValue,
          submitForm,
          isValid,
          isSubmitting,
          dirty,
        }): JSX.Element => {
          return (
            <BlockSpinner loading={isSubmitting}>
              <Row>
                <Col xs={12}>
                  <h4>Enable</h4>
                  <p>
                    Turn off streaming without deleting your endpoint
                    configurations.{" "}
                  </p>
                  <RadioButtonGroup
                    fieldName={"enabled"}
                    paddingX={0}
                    submitSpinner={false}
                    options={[
                      { name: "Streaming enabled", value: true },
                      { name: "Streaming disabled", value: false },
                    ]}
                    currentValue={values.enabled}
                    setFieldValue={setFieldValue}
                    title={""}
                  />
                </Col>
                <Col xs={12}>
                  <h4>Endpoints</h4>
                  <p>
                    Add upto 3 endpoints to receive the formatted data webhook.
                    Endpoints must be https (with a valid certificate) and be
                    secured with HTTP Basic Authentication.
                  </p>
                  <FieldArray
                    name={"endpoints"}
                    render={(arrayHelpers) => (
                      <Table size={"sm"}>
                        <tr>
                          <th className={"px-1 px-1"}>URL</th>
                          <th className={"px-1 px-1"}>Username</th>
                          <th className={"px-1 px-1"}>Password</th>
                          <th>&nbsp;</th>
                        </tr>
                        {values.endpoints && values.endpoints.length > 0 ? (
                          values.endpoints.map((endpoint, index) => (
                            <tbody key={index}>
                              <tr>
                                <td className={"px-1 px-1"}>
                                  <FormComponent
                                    fieldName={`endpoints[${index}].url`}
                                    // label={"URL"}
                                    submitSpinner={false}
                                    paddingX={0}
                                    paddingY={0}
                                  />
                                </td>
                                <td className={"px-1 px-1"}>
                                  <FormComponent
                                    fieldName={`endpoints[${index}].authentication.username`}
                                    // label={"Username"}
                                    submitSpinner={false}
                                    paddingX={0}
                                    paddingY={0}
                                  />
                                </td>
                                <td className={"px-1 px-1"}>
                                  <FormComponent
                                    fieldName={`endpoints[${index}].authentication.password`}
                                    // label={"Password"}
                                    submitSpinner={false}
                                    paddingX={0}
                                    paddingY={0}
                                  />
                                </td>
                                <td className={"px-1 px-1 align-middle"}>
                                  <Button
                                    size={"sm"}
                                    color={"danger"}
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <i className={"fa fa-fw fa-trash"} />
                                    Remove
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={4}>No endpoints</td>
                          </tr>
                        )}
                        {values.endpoints && values.endpoints.length < 3 && (
                          <tr>
                            <td colSpan={4} className={"text-center"}>
                              <Button
                                color="primary"
                                size={"sm"}
                                onClick={() =>
                                  arrayHelpers.push({
                                    url: "",
                                    authentication: {
                                      username: "",
                                      password: "",
                                    },
                                  })
                                }
                              >
                                {/* show this when user has removed all friends from the list */}
                                Add an endpoint
                              </Button>
                            </td>
                          </tr>
                        )}
                      </Table>
                    )}
                  />
                </Col>

                <Col xs={12}>
                  <div className={"form-group row"}>
                    <Button
                      type={"submit"}
                      color={"primary"}
                      onClick={submitForm}
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      Save Changes
                    </Button>
                  </div>
                </Col>
              </Row>
            </BlockSpinner>
          );
        }}
      </Formik>
      {!update.updating && update.error && <ErrorBanner error={update.error} />}

      {!update.updating && updated && (
        <SuccessBanner text={"Streaming configuration updated successfully."} />
      )}
    </>
  );
}
