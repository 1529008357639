import React from "react";
import { AssetPosition } from "../../openapi/model/assetPosition";
import UpdateButton from "../../components/Buttons/UpdateButton";

export default function WirelessClusterGatewayAssignButton(props: {
  clusterPosition: AssetPosition;
  gatewayPosition: AssetPosition;
  refresh?: () => void;
}): JSX.Element {
  const { clusterPosition, gatewayPosition } = props;
  console.log(clusterPosition, gatewayPosition);

  return (
    <UpdateButton
      updatedEntity={{
        ...gatewayPosition,
        parentAssetPositionId: clusterPosition.assetPositionId,
      }}
      entityId={gatewayPosition.assetPositionId}
      entityType={"ASSET_POSITION"}
      text={"Join"}
      icon={"fa fa-link"}
      color={"primary"}
      size={"sm"}
      postUpdate={props.refresh}
    />
  );
}
