import React from "react";
import { Scheme } from "../../openapi/model/scheme";
import { CreateEntity } from "../../hooks/createEntity";
import { TariffIn } from "../../openapi/model/tariffIn";
import { TariffOut } from "../../openapi/model/tariffOut";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { nameRegEx } from "../../model/utils/schema";
import TextFormComponent from "../../components/Forms/TextFormComponent";
import { Button, Col } from "reactstrap";
import PrivacyMode from "../../components/Text/PrivacyMode";

const TariffSchema = Yup.object().shape({
  tariffId: Yup.string()
    .required()
    .matches(...nameRegEx),
  tariffName: Yup.string().required(),
});

type FormValues = Omit<TariffOut, "companyId" | "schemeId">;

const TariffCreateForm = (props: {
  scheme: Scheme;
  create: CreateEntity<TariffOut, TariffIn>;
}) => {
  const { scheme, create } = props;

  const initialValues: FormValues = {
    tariffId: scheme.schemeId,
    tariffName: scheme.schemeName,
  };

  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ): void => {
    setSubmitting(true);
    create
      .createEntity({
        ...values,
        companyId: scheme.companyId,
        schemeId: scheme.schemeId,
      })
      .finally(() => {
        setSubmitting(false);
        resetForm();
      });
  };

  return (
    <>
      <h3>
        Billing has not been enabled for{" "}
        <PrivacyMode>{props.scheme.schemeName}</PrivacyMode>. Please contact
        support.
      </h3>
      <DisplayIfUserType userTypes={"superadmin"}>
        <Formik
          initialValues={initialValues}
          onSubmit={submit}
          validationSchema={TariffSchema}
        >
          {({ submitForm, isSubmitting, isValid }) => (
            <Form>
              <TextFormComponent fieldName={"tariffId"} label={"Tariff Id"} />
              <TextFormComponent
                fieldName={"tariffName"}
                label={"Tariff Name"}
              />
              <Col xs={12}>
                <div className={"form-group row"}>
                  <Button
                    color={"primary"}
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid}
                  >
                    Create Tariff
                  </Button>
                </div>
              </Col>
            </Form>
          )}
        </Formik>
      </DisplayIfUserType>
    </>
  );
};

export default TariffCreateForm;
