/**
 * Meterpoint.io
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type OutputType = 'ELVACO_X109' | 'ELVACO_X110' | 'CUMULATIVE_DATA' | 'LATEST' | 'ALL_DATA' | 'AGGREGATED_DATA' | 'BILLING';

export const OutputType = {
    ELVACOX109: 'ELVACO_X109' as OutputType,
    ELVACOX110: 'ELVACO_X110' as OutputType,
    CUMULATIVEDATA: 'CUMULATIVE_DATA' as OutputType,
    LATEST: 'LATEST' as OutputType,
    ALLDATA: 'ALL_DATA' as OutputType,
    AGGREGATEDDATA: 'AGGREGATED_DATA' as OutputType,
    BILLING: 'BILLING' as OutputType
};