import {Form, Formik} from "formik";
import * as Yup from "yup";
import {FormikHelpers} from "formik/dist/types";
import {isEqual} from "lodash";
import {Col, Row} from "reactstrap";
import SelectComboField from "../../../components/Forms/SelectComboField";
import NewLocationGroupSelect from "../../../components/LocationGroup/NewLocationGroupSelect";
import NewLabelWrapper from "../../../components/Forms/NewLabelWrapper";
import NewButton from "../../../components/Forms/NewButton/NewButton";
import {ButtonColor} from "@psd-platform/monday-ui-react-core/dist/types/components/Button/ButtonConstants";
import React from "react";

export interface LatestDataReportQueryFormValues {
    group?: string;
}

export interface LatestDataReportQueryFormProps {
    onSubmit: (values: LatestDataReportQueryFormValues) => Promise<any>,
    initialValues?: LatestDataReportQueryFormValues
}


export default function LatestDataReportQueryForm(props: LatestDataReportQueryFormProps) {
    const {
        initialValues = {} as LatestDataReportQueryFormValues,
        onSubmit
    } = props
    const wide = true;
    const showErrors = true;

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({})}
            onSubmit={(values: LatestDataReportQueryFormValues, helpers: FormikHelpers<LatestDataReportQueryFormValues>): Promise<any> => {
                helpers.setSubmitting(true)
                return onSubmit(values).finally(() => helpers.setSubmitting(false))
            }}>
            {({values, submitForm}): JSX.Element => {
                const isChanged = !isEqual(values, initialValues)


                return (
                    <Form>
                        <Row noGutters={true}>
                            <Col xs={12} md={wide ? 9 : 12}>
                                <SelectComboField name={'group'}>
                                    {(fieldProps) => <NewLocationGroupSelect
                                        label={{label: 'Group', size: 'sm', showErrors}}
                                        {...fieldProps}/>}
                                </SelectComboField>
                            </Col>
                            <Col xs={12} md={wide ? 3 : 12}>
                                <NewLabelWrapper
                                    size={'sm'} showErrors={true} label={'\u00A0'}>
                                    <NewButton color={'primary' as ButtonColor}
                                               className={'w-100'}
                                               onClick={submitForm}
                                               disabled={!isChanged}
                                    >Search</NewButton>
                                </NewLabelWrapper>
                            </Col>
                        </Row>
                    </Form>
                )
            }}
        </Formik>
    );
}