import React from "react";
import DisplayIfUserType from "../utils/DisplayIfUserType/DisplayIfUserType";
import {Tab, TabGroupInterfaceProps} from "./TabGroupInterface";
import {Button} from "reactstrap";

export default function TabSwitchButtonGroup(
    props: TabGroupInterfaceProps
): JSX.Element {
    return (
        <>
      <span
          className="btn-group py-2 py-lg-3 float-right "
          data-cy={"tab-interface"}
      >
        {props.tabs.map((tab: Tab, index) => {
            return (
                <DisplayIfUserType
                    userTypes={tab.userTypes || ["admin", "editor", "viewer"]}
                    key={index}
                >
                    <Button
                        color={'primary'}
                        disabled={tab.disabled}
                        onClick={() => props.switchTab(tab)}
                        data-cy={`tab-${tab.tabName}`}
                        active={tab.tabPath === props.liveTab?.tabPath}
                    >
                        {tab.tabName}

                    </Button>
                </DisplayIfUserType>
            );
        })}
      </span>
            {props.additionalButtons && (
                <span className={"px-4 py-2 py-lg-3 float-right"}>
          {props.additionalButtons}
        </span>
            )}
        </>
    );
}
