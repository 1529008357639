import * as React from 'react';
import {OneCardPage} from "../../components/utils/OneCardPage";
import SelectedScheme from "../../components/Scheme/SelectedScheme";
import AssetRegisterList from "../assetRegister/AssetRegisterList";
import {AssetType} from "../../openapi/model/assetType";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import {Button} from "reactstrap";
import IntegrationListRow from "./IntegrationListRow";
import IntegrationListHeader from "./IntegrationListHeader";


export function IntegrationListPage() {
  return (
    <OneCardPage
      headerTitle={"Data Integrations"}
      breadcrumbs={[
        {
          url: "/admin/integrations",
          name: "Integrations",
        },
      ]}
    >
      <SelectedScheme>
        {(scheme) => (
          <>
            <AssetRegisterList
              {...scheme}
              assetType={AssetType.ApiIntegration}
              download={false}
              createAssetTags={false}
              header={<IntegrationListHeader/>}
              row={IntegrationListRow}
            />
            {scheme.schemeId && (
              <DisplayIfUserType userTypes={"superadmin"}>
                <CompanyNavLink to={`create`} tag={Button}>
                  Add Data Integration
                </CompanyNavLink>
              </DisplayIfUserType>
            )}
          </>
        )}
      </SelectedScheme>
    </OneCardPage>
  )
}