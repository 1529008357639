import moment from "moment";

export interface Preset {
  text: string;
  start: moment.Moment;
  end: moment.Moment;
}

export interface PresetDay {
  text: string;
  day: moment.Moment;
}

const startOfMonth = moment().startOf("month");
const startOfToday = moment().startOf("day");
const startOfWeek = moment().startOf("week");

export const LAST_3_CALENDAR_MONTHS = {
  text: "Last 3 Calendar Months",
  start: moment(startOfMonth).subtract(3, "month"),
  end: moment(startOfMonth).subtract(1, "second"),
};
export const LAST_CALENDAR_MONTH = {
  text: "Last Calendar Month",
  start: moment(startOfMonth).subtract(1, "month"),
  end: moment(startOfMonth).subtract(1, "second"),
};

export const LAST_28_DAYS = {
  text: "Last 28 Days",
  start: moment(startOfToday).subtract(28, "days"),
  end: moment(startOfToday).subtract(1, "second"),
};

export const LAST_CALENDAR_WEEK = {
  text: "Last Calendar Week",
  start: moment(startOfWeek).subtract(1, "week"),
  end: moment(startOfWeek).subtract(1, "second"),
};

export const YESTERDAY = {
  text: "Yesterday",
  day: moment(startOfToday).subtract(1, "day"),
};

const endOfMonth = (number = 0): PresetDay => {
  const day = moment(startOfMonth).subtract(number, "month").subtract(1, "day");
  return {
    text: `End of ${day.format("MMMM")}`,
    day: day,
  };
};

const endOfYear = (number = 0): PresetDay => {
  const day = moment(startOfMonth).subtract(number, "year").subtract(1, "day");
  return {
    text: `End of ${day.format("YYYY")}`,
    day: day,
  };
};

export const END_OF_LAST_MONTH = endOfMonth();
export const END_OF_PREVIOUS_MONTH = endOfMonth(1);
export const END_OF_PREVIOUS_PREVIOUS_MONTH = endOfMonth(2);

export const END_OF_LAST_YEAR = endOfYear();

export const DEFAULT_PRESETS = [
  LAST_CALENDAR_WEEK,
  LAST_28_DAYS,
  LAST_CALENDAR_MONTH,
  LAST_3_CALENDAR_MONTHS,
];

export const DEFAULT_DAY_PRESETS = [
  YESTERDAY,
  END_OF_LAST_MONTH,
  END_OF_PREVIOUS_MONTH,
  END_OF_PREVIOUS_PREVIOUS_MONTH,
  END_OF_LAST_YEAR,
];

export default DEFAULT_PRESETS;
