import React, {useState} from 'react';
import {formatDate} from "../../../../components/utils/HighlightNullAndOldDates";
import {useTimeout} from "usehooks-ts";
import classNames from "classnames";
import {Badge} from "reactstrap";
import CopyToClipboard from "../../../../components/utils/CopyToClipboard";
import {WirelessSurveyDataCollectorOut} from "../../../../openapi/model/wirelessSurveyDataCollectorOut";
import moment from "moment";



function StreamingGatewayDataTableRow(props: {
    dataCollectorId?: string;
    dataCollector?: WirelessSurveyDataCollectorOut,
    date: string,
    data: string,
    secondaryAddress: string,
    manufacturer: string,
    medium: string,
    version: string,
    rssi: string,
    newOnTimeOnly?: boolean
}) {
    const {newOnTimeOnly= false} = props;
    const [isNew, setIsNew] = useState<boolean>(
        newOnTimeOnly ? (moment().diff(moment(props.date), 'seconds')) <= 60 : true);
    useTimeout(() => {
        setIsNew(false);
    }, 10000)
    const {date, data} = props
    const hex=b64ToHex(data).toUpperCase().match(/.{1,2}/g)?.join(' ')
    return (
        <tr className={classNames({'table-light': isNew})}>
            {props.dataCollectorId &&
                <td>
                    {props.dataCollector?.dataCollectorName || props.dataCollectorId}
                        </td>
            }
             <td>
                 <>{formatDate(date, undefined, true)}</>
                 {isNew && <Badge color={'primary'} pill={true}>New</Badge>}
             </td>
            <td>{props.manufacturer}</td>
            <td>{props.secondaryAddress}</td>
            <td>{props.medium}</td>
            <td>{props.version}</td>
            <td>{props.rssi}</td>
            <td><CopyToClipboard value={hex} slice={10} className={'text-monospace small'}/></td>
        </tr>
    );
}

function toHexString(byteArray: Uint8Array) {
  return Array.from(byteArray, function(byte) {
    return ('0' + (byte & 0xFF).toString(16)).slice(-2);
  }).join('')
}

function base64ToArrayBuffer(base64: string): Uint8Array {
    return Uint8Array.from(atob(base64), c => c.charCodeAt(0));
}

export function b64ToHex(value: string): string {
    return toHexString(base64ToArrayBuffer(value));
}

export default StreamingGatewayDataTableRow;