import React from "react";
import {
  Option,
  SelectComponent,
  SelectFieldProps,
} from "../../components/Forms/SelectComponent";
import _ from "lodash";
import { EventTypes } from "../../openapi/model/eventTypes";

export default function EventTypeSelect(
  props: {
    fieldName: string;
    errors?: any;
    touched?: any;
  } & SelectFieldProps
): JSX.Element {
  const { fieldName, ...rest } = props;

  const options: Option[] = Object.values(EventTypes).map(
    (v: string): Option => {
      return {
        value: v,
        label: _.startCase(v),
      };
    }
  );

  return (
    <SelectComponent options={options} name={fieldName} {...rest} isMulti />
  );
}
