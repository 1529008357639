import React from "react";
import { AssetPosition } from "../../openapi/model/assetPosition";
import { AssetType } from "../../openapi/model/assetType";
import GatewayConnectivityReport from "../assets/gateway/GatewayConnectivityReport";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import CreateActionButton from "../assetActions/CreateActionButton";

export default function WirelessClusterGatewayList(props: {
  clusterPosition: AssetPosition;
}): JSX.Element {
  const { clusterPosition } = props;

  return (
    <GatewayConnectivityReport
      companyId={clusterPosition.companyId}
      schemeId={clusterPosition.schemeId}
      parentAssetPositionId={clusterPosition.assetPositionId}
      clusterForAssignment={clusterPosition}
      additionalFunctionButtons={
        <DisplayIfUserType userTypes={"admin"}>
          <CreateActionButton
            assetPosition={props.clusterPosition}
            actionType={"SYNC_CONFIGURATION_ALL_ASSETS"}
            actionPayload={{ assetType: AssetType.Gateway }}
            actionName={"Sync Configuration all Gateways"}
          />
          <CreateActionButton
            assetPosition={props.clusterPosition}
            actionType={"SYNC_DEVICE_LIST_ALL_ASSETS"}
            actionPayload={{ assetType: AssetType.Gateway }}
            actionName={"Sync Device List all Gateways"}
          />
          <CreateActionButton
            assetPosition={props.clusterPosition}
            actionType={"SYNC_CONFIGURATION_ALL_ASSETS"}
            actionPayload={{ assetType: AssetType.WirelessReceiver }}
            actionName={"Sync Configuration all Receivers"}
          />
          <CreateActionButton
            assetPosition={props.clusterPosition}
            actionType={"SYNC_DEVICE_LIST_ALL_ASSETS"}
            actionPayload={{ assetType: AssetType.WirelessReceiver }}
            actionName={"Sync Device List all Receivers"}
          />
        </DisplayIfUserType>
      }
    />
  );
}
