import React from "react";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import AssetList from "./AssetList";
import AssetListFilterForm from "./AssetListFilterForm";
import SearchParamPage from "../../../components/Search/SearchParamPage";
import SelectedScheme from "../../../components/Scheme/SelectedScheme";

const InnerAssetListPage = (props: any): JSX.Element => {
  return (
    <SelectedScheme>
      <AssetList {...props} />
    </SelectedScheme>
  );
};
const AssetListPage = (): JSX.Element => {
  return (
    <OneCardPage
      headerTitle={"Asset List"}
      breadcrumbs={[
        { url: "/admin/asset", name: "Assets" },
        { url: "/admin/asset", name: "List" },
      ]}
    >
      <AssetListFilterForm
        includeGroup={false}
        includeUseCase={false}
        includeStatus={false}
        includeTextSearch={true}
      />
      <SearchParamPage
        initialState={{
          assetType: undefined,
          assetPositionId: undefined,
          textSearch: undefined,
        }}
      >
        <InnerAssetListPage sort={"serial_number"} />
      </SearchParamPage>
    </OneCardPage>
  );
};

export default AssetListPage;
