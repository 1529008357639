import React from 'react';
import {AggregatedStatsResponse} from "../../../containers/report/latestDataReport/TimeSeriesStatsApiWrapper";
import AggregatedStatsChart from "../../charts/AggregatedStatsChart";
import {UseAxiosResult} from "axios-hooks";
import AggregatedTimeSeriesQueryForm, {
    AggregatedTimeSeriesQueryFormValues,
    mapTimeSeriesRequestParamsToFormValues
} from "../AggregatedTimeSeriesQueryForm/AggregatedTimeSeriesQueryForm";
import {getUnitsForParameter, NewTimeSeriesRequestParams} from "../model";
import {Col, Row} from 'reactstrap';
import BetaAlert from "../../Beta/BetaAlert";
import {
    AssetRegisterAggregatedTimeSeriesResponse
} from "../../../openapi/model/assetRegisterAggregatedTimeSeriesResponse";
import AggregationDistributionChart from "../../charts/AggregationDistributionChart";
import {startCase} from "lodash";
import BlockSpinner from "../../Spinners/BlockSpinner";

interface AggregatedTimeSeriesStatsReportViewProps {
    onSubmit: (v: AggregatedTimeSeriesQueryFormValues) => Promise<any>,
    request: NewTimeSeriesRequestParams,
    data: UseAxiosResult<AggregatedStatsResponse>,
    aggregatedData?: UseAxiosResult<AssetRegisterAggregatedTimeSeriesResponse>,
    companyId: string;
    schemeId?: string;
    showForm?: boolean;
}

function AggregatedTimeSeriesStatsReportView(props: AggregatedTimeSeriesStatsReportViewProps) {
    const [{data, error, loading}] = props.data
    const haveScheme = props.schemeId !== undefined
    const {request, showForm = true} = props
    return (
        <>
            {showForm && <><Row>
                <Col xs={12}>
                    <AggregatedTimeSeriesQueryForm
                        onSubmit={props.onSubmit}
                        initialValues={mapTimeSeriesRequestParamsToFormValues(request)}
                        wide={true}
                        showErrors={false}
                        showGroup={true}
                        showGroupByScheme={!haveScheme}
                        showGroupByGroup={haveScheme}
                        showAssetUseCase={true}
                    />
                </Col>
            </Row>
                <Row>
                    <Col xs={12}>
                        <BetaAlert/>
                    </Col>
                </Row></>}
            <Row>
                <Col xs={12}>
                    <AggregatedStatsChart
                        error={error || undefined}
                        data={data}
                        loading={loading}
                        seriesName={`Mean of ${startCase(request.aggregation)} of ${request.parameter}`}
                        units={getUnitsForParameter(request.parameter)}
                        groupBy={request.groupBy}
                        groupFilter={request.group}
                    />
                </Col>
                {props.schemeId && props.aggregatedData &&
                    <Col>
                        <BlockSpinner loading={props.aggregatedData[0].loading}>
                            <AggregationDistributionChart
                                data={props.aggregatedData[0].data}
                                loading={props.aggregatedData[0].loading}
                                parameter={request.parameter}
                                aggregation={request.aggregation}
                                unit={getUnitsForParameter(request.parameter)}
                                companyId={props.companyId}
                                schemeId={props.schemeId as string}
                                startTime={request.startDate}
                                endTime={request.endDate}
                            />
                        </BlockSpinner>
                    </Col>}

            </Row>
        </>

    );
}

export default AggregatedTimeSeriesStatsReportView;