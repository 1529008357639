import React from 'react';
import NewLabelWrapper, {LabelProps} from "./NewLabelWrapper";
import {TextField} from "@psd-platform/monday-ui-react-core";
import {TextFieldProps} from '@psd-platform/monday-ui-react-core/dist/types/components/TextField/TextField';
import classNames from "classnames";


import styles from './TextInput.module.scss';

export interface TextInputProps extends TextFieldProps {
    placeholder?: string;
    label?: LabelProps;
    name: string,
    onChange: (value: string, event: Pick<React.ChangeEvent, "target">) => void;
    value?: string;
    error?: string;
    touched?: boolean,
}
function TextInput(props: TextInputProps) {
    const {name, label,error, className, ...rest} = props;
    return (
        <NewLabelWrapper {...{...label, error}}>
            <div className={'mx-2'}>
            <TextField
                id={`text-field-${name}`}
                {...rest}
                className={classNames({
                    className,
                    [styles.textInputDisabled]: props.disabled
                })}
            />
            </div>
        </NewLabelWrapper>
    );
}

export default TextInput;