/**
 * Meterpoint.io
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type GatewayConfigurationState = 'IN_SYNC' | 'FACTORY_RESETTING' | 'UPDATING_CONFIGURATION' | 'UPDATING_DEVICE_LIST' | 'UPGRADING_FIRMWARE' | 'ERROR_UPDATING_CONFIGURATION' | 'ERROR_UPDATING_DEVICE_LIST' | 'ERROR_UPGRADING_FIRMWARE' | 'UNKNOWN' | 'MBUS_SEARCH';

export const GatewayConfigurationState = {
    INSYNC: 'IN_SYNC' as GatewayConfigurationState,
    FACTORYRESETTING: 'FACTORY_RESETTING' as GatewayConfigurationState,
    UPDATINGCONFIGURATION: 'UPDATING_CONFIGURATION' as GatewayConfigurationState,
    UPDATINGDEVICELIST: 'UPDATING_DEVICE_LIST' as GatewayConfigurationState,
    UPGRADINGFIRMWARE: 'UPGRADING_FIRMWARE' as GatewayConfigurationState,
    ERRORUPDATINGCONFIGURATION: 'ERROR_UPDATING_CONFIGURATION' as GatewayConfigurationState,
    ERRORUPDATINGDEVICELIST: 'ERROR_UPDATING_DEVICE_LIST' as GatewayConfigurationState,
    ERRORUPGRADINGFIRMWARE: 'ERROR_UPGRADING_FIRMWARE' as GatewayConfigurationState,
    UNKNOWN: 'UNKNOWN' as GatewayConfigurationState,
    MBUSSEARCH: 'MBUS_SEARCH' as GatewayConfigurationState
};