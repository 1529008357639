import React from 'react';
import {ValueList} from "./SelectCombo/SelectCombo";
import {useField} from "formik";


interface NewCheckboxFieldProps {
    name: string;
    children: (props: {
        name: string,
        onChange: (newValue: ValueList) => void,
        value: ValueList,
        error?: string,
        touched?: boolean,
    }) => React.ReactNode;
    onChange?: (newValue: ValueList) => Promise<any>
    }
function NewCheckboxField(props: NewCheckboxFieldProps) {
    const [{value}, {error, touched}, {setValue, setTouched}] = useField(props.name);

    const onChangeFormikSet = async (newValue: any) => {
        await setTouched(true)
        props.onChange && await props.onChange(newValue)
        await setValue(newValue)
    }

    return <>{props.children({name:props.name, onChange:onChangeFormikSet, value, error, touched} )}</>
}

export default NewCheckboxField;