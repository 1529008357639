import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface Props {
  buttonLabel: string;
  closeLabel?: string;
  title: string;
  body: string | JSX.Element;
  className?: string;
  actionButtonColor?: string;
  show: boolean;
  disabled?: boolean;
  handleClose: () => void;
  handleAction?: () => void;
}

const ConfirmModal = (props: Props): JSX.Element => {
  const active: boolean = props.disabled === undefined ? true : props.disabled;

  return (
    <>
      <Modal
        isOpen={props.show}
        toggle={props.handleClose}
        className={props.className}
      >
        <ModalHeader toggle={props.handleClose}>{props.title}</ModalHeader>
        <ModalBody>{props.body}</ModalBody>
        <ModalFooter>
          {props.handleAction && (
            <Button
              color={
                props.actionButtonColor ? props.actionButtonColor : "primary"
              }
              onClick={props.handleAction}
              disabled={!active}
            >
              {props.buttonLabel}
            </Button>
          )}
          <Button color="secondary" onClick={props.handleClose}>
            {props.closeLabel ? props.closeLabel : "Cancel"}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ConfirmModal;
