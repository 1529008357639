import React from "react";
import moment, { Moment } from "moment";
import AllPeriodReportTable, {
  DataColumnDefinition,
} from "../allPeriodReportTable/NewAllPeriodReportTable";
import { AggregatedReportRequest } from "../../../openapi/model/aggregatedReportRequest";
import { AssetType } from "../../../openapi/model/assetType";
import AggregatedTimeSeriesApiWrapper from "../aggregatedTimeSeriesReport/AggregatedTimeSeriesApiWrapper";
import {HeatNetworkReportParams} from "./model";

interface Props {
  companyId?: string;
  schemeId?: string;
  query: HeatNetworkReportParams
}

export const RUN_TIME_REPORT_AGGREGATIONS = [
  "sum_(domestic_hot_water)",
  "sum_(space_heating)",
  "sum_(standby)",
  "sum_(passive_standby)",
  "sum_(error)",
  "sum_(unknown)",
];

const RUN_TIME_COLUMNS: Array<DataColumnDefinition> = [
  {
    parameter: "syntheticRunTime",
    aggregation: "sumDomesticHotWater",
    name: "Average Daily Hours Domestic Hot Water",
  },
  {
    parameter: "syntheticRunTime",
    aggregation: "sumSpaceHeating",
    name: "Average Daily Hours Space Heating",
  },
  {
    parameter: "syntheticRunTime",
    aggregation: "sumStandby",
    name: "Average Daily Hours Standby",
  },
  {
    parameter: "syntheticRunTime",
    aggregation: "sumPassiveStandby",
    name: "Average Daily Hours Passive Standby",
  },
  {
    parameter: "syntheticRunTime",
    aggregation: "sumError",
    name: "Average Daily Hours Error",
  },
  {
    parameter: "syntheticRunTime",
    aggregation: "sumUnknown",
    name: "Average Daily Hours Unknown",
  },
];

const RunTimeReportPage = (props: Props): JSX.Element => {
  const [startDatetime, endDatetime] = [props.query.startDate, props.query.endDate];

  const params = (): AggregatedReportRequest => {
    if (props.companyId && props.schemeId) {
      return {
        companyId: props.companyId,
        schemeId: props.schemeId,
        aggregations: RUN_TIME_REPORT_AGGREGATIONS.map((value) => {
          return {
            parameter: "Synthetic Run Time",
            aggregation: value,
          };
        }),
        assetTypes: [AssetType.HeatMeter, AssetType.HeatingAndCoolingMeter],
        startTime: startDatetime.toDate(),
        endTime: endDatetime.toDate(),
        useCase: props.query.useCases,
        period: "ALL",
        group: props.query.group,
      };
    } else {
      throw new Error("Scheme or Company is not selected.");
    }
  };

  const edt: Moment = moment(endDatetime);
  edt.add(1, "ms");
  const duration = 24 / edt.diff(startDatetime, "minutes");

  return (
    <>
      {props.companyId && props.schemeId && (
        <AggregatedTimeSeriesApiWrapper request={params()}>
          {({ data }) => (
            <AllPeriodReportTable
              data={data}
              columnDefinitions={RUN_TIME_COLUMNS.map((value) => {
                return { ...value, factor: duration };
              })}
              downloadFilename={`${props.schemeId}_run_time_report`}
              startDatetime={startDatetime}
              endDatetime={endDatetime}
            />
          )}
        </AggregatedTimeSeriesApiWrapper>
      )}
    </>
  );
};

export default RunTimeReportPage;
