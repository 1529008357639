import React from "react";
import { Routes } from "react-router-dom";
import { JobsListPage } from "./JobsListPage";
import { SentryRoute } from "../../App";
import JobHomePage from "./JobHomePage";
import JobCreatePage from "./JobCreatePage";
import SelectedScheme from "../../components/Scheme/SelectedScheme";

const JobsPage = (): JSX.Element => {
  return (
    <Routes>
      <SentryRoute path="/" element={<JobsListPage />} />
      <SentryRoute path="/create" element={<JobCreatePage />} />
      <SentryRoute
        path={"/:jobId"}
        element={
          <SelectedScheme>
            {(props) => <JobHomePage {...props} />}
          </SelectedScheme>
        }
      />
      <SentryRoute
        path={"/:jobId/:tab"}
        element={
          <SelectedScheme>
            {(props) => <JobHomePage {...props} />}
          </SelectedScheme>
        }
      />
    </Routes>
  );
};

export default JobsPage;
