import React from "react";
import {Option,} from "../../../components/Forms/SelectComponent";
import {DayOfWeek} from "../../../openapi/model/dayOfWeek";
import {SelectCombo, SelectComboProps} from "../../../components/Forms/SelectCombo/SelectCombo";

export default function DaySelect(
  props: Omit<SelectComboProps,'option'>): JSX.Element {

  const options: Option[] = [
    { label: "Monday", value: DayOfWeek.MONDAY },
    { label: "Tuesday", value: DayOfWeek.TUESDAY },
    { label: "Wednesday", value: DayOfWeek.WEDNESDAY },
    { label: "Thursday", value: DayOfWeek.THURSDAY },
    { label: "Friday", value: DayOfWeek.FRIDAY },
    { label: "Saturday", value: DayOfWeek.SATURDAY },
    { label: "Sunday", value: DayOfWeek.SUNDAY },
  ];

  return (
    <SelectCombo
      options={options}
      {...props}
    />
  );
}
