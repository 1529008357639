import React, {useEffect, useState} from 'react';
import {LatestDataReportParams} from "./model";
import {useLocation, useNavigate} from "react-router-dom";
import {
    convertStringUndefined,
    createSearchString,
    parseSearchString
} from "../../../components/navigation/SearchString";
import {removeMapBlanks} from "../../../utils/object";


interface LatestDataReportQueryUrlWrapperProps {
    defaultValues: LatestDataReportParams,
    children: (
        params: LatestDataReportParams,
        onChange: (v: LatestDataReportParams) => void,
    ) => React.ReactNode

}

function LatestDataReportQueryUrlWrapper(props: LatestDataReportQueryUrlWrapperProps) {
    const navigate = useNavigate();
    const location = useLocation();

    const [init, setInit] = useState<boolean>(false);

    const searchParams = parseSearchString(location.search);
    const currentParams: Partial<LatestDataReportParams> = {
        group: searchParams.group as string || undefined
    }

    const setLocationWithParams = (values: LatestDataReportParams): void => {
        navigate({
            search: createSearchString(
                convertStringUndefined(
                    values,
                )
            ),
            hash: location.hash,
        })
    }
    useEffect(() => {

            setLocationWithParams({...removeMapBlanks(currentParams), ...props.defaultValues,})
            setInit(true)
            // NOTE: Run effect once on component mount
            // recheck dependencies if effect is updated.
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    )


    return <>{init && props.children({...props.defaultValues, ...removeMapBlanks(currentParams)}, setLocationWithParams)}</>
}

export default LatestDataReportQueryUrlWrapper;