import React from "react";
import { Asset } from "../../openapi/model/asset";
import { AssetPosition } from "../../openapi/model/assetPosition";
import * as _ from "lodash";
import { AssetType } from "../../openapi/model/assetType";
import { Badge } from "reactstrap";
import classNames from "classnames";
import { RequireExactlyOne } from "type-fest";

export const AssetTypeToHumanString = (assetType: AssetType): string => {
  return _.startCase(assetType);
};

const AssetTypeHumanLabel = (
  props: RequireExactlyOne<
    {
      asset?: Asset | AssetPosition;
      assetType?: AssetType;
      iconLocation?: "left" | "right";
    },
    "asset" | "assetType"
  >
): JSX.Element => {
  const assetType: AssetType = props.assetType
    ? props.assetType
    : "assetType" in props.asset
    ? props.asset.assetType
    : props.asset.assetPositionType;
  const virtual: boolean | undefined = props.assetType
    ? false
    : "virtualAsset" in props.asset
    ? props.asset.virtualAsset
    : "virtualAssetPosition" in props.asset
    ? props.asset.virtualAssetPosition
    : false;
  const { iconLocation = "right" } = props;
  const icon = virtual && (
    <Badge
      color={"info"}
      className={classNames({
        "px-2 text-white": true,
        "ml-2": iconLocation === "right",
        "mr-2": iconLocation === "left",
      })}
    >
      virtual
    </Badge>
  );
  return (
    <>
      {iconLocation === "left" && icon}
      {AssetTypeToHumanString(assetType)}
      {iconLocation === "right" && icon}
    </>
  );
};

export default AssetTypeHumanLabel;
