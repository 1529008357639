import React from "react";
import SelectComponent, {
  Option,
} from "../../../../components/Forms/SelectComponent";
import { MBusAddressingMode } from "../../../../openapi/model/mBusAddressingMode";
import * as Yup from "yup";
import { yupRequiredString } from "../../../../components/Forms/yupValidators";
import FormComponent from "../../../../components/Forms/FormComponent";
import { Col, Row } from "reactstrap";
import useUserHasRoles from "hooks/user/getCurrentUserRole";
import { MultiValue, SingleValue } from "react-select";
import _ from "lodash";
import { useFormikContext } from "formik";

export const MBusConfigurationFormSchema = Yup.object().shape({
  addressingMode: yupRequiredString,
  primaryAddress: Yup.number().when(
    ["addressingMode"],
    (addressingMode, schema) => {
      console.log(addressingMode)
      console.log(schema)
        console.log(addressingMode === MBusAddressingMode.Secondary)
      return addressingMode === MBusAddressingMode.Secondary
        ? schema.notRequired().nullable(true)
        : schema.min(0).max(255).integer().required();
    }
  ),
  encryptionKey: Yup.string().max(32).nullable(true),
  baudRate: Yup.number().required()
});

const MbusConfigurationForm = ({namespace, addressingMode}:{
  namespace?: string,
  addressingMode?: MBusAddressingMode
}): JSX.Element => {

  const allowComplexEditing = useUserHasRoles("superadmin");
  const allowEditing = useUserHasRoles("admin");
  const { setFieldValue } = useFormikContext();
  const primaryAddress = addressingMode === MBusAddressingMode.Primary;

  const withNamespace = (fieldName:string): string => {
    return namespace ? `${namespace}.${fieldName}` : fieldName
  }

  return (
    <>
      <Row>
        <Col xs={12}>
          <h4>MBus Configuration</h4>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <SelectComponent
            label={"Addressing Mode"}
            options={[
              { label: "Primary", value: MBusAddressingMode.Primary },
              { label: "Secondary", value: MBusAddressingMode.Secondary },
            ]}
            name={withNamespace("addressingMode")}
            paddingX={0}
            submitSpinner={false}
            disabled={!allowComplexEditing}
            onChange={(value: SingleValue<Option> | MultiValue<Option>) => {
              if (_.isArray(value)) {
                throw new Error("MBus Addressing Mode should not be a list.");
              }
              if (
                (value as SingleValue<Option>)?.value ===
                MBusAddressingMode.Primary
              ) {
                setFieldValue(withNamespace("primaryAddress"), 0);
              }
              if (
                (value as SingleValue<Option>)?.value ===
                MBusAddressingMode.Secondary
              ) {
                setFieldValue(withNamespace("primaryAddress"), "");
              }
            }}
          />
        </Col>
        <Col xs={12}>
          <FormComponent
            fieldName={withNamespace("primaryAddress")}
            label={"Primary Address"}
            type={"number"}
            paddingX={0}
            submitSpinner={false}
            disabled={!allowComplexEditing || !primaryAddress}
          />
        </Col>
        <Col xs={12}>
          <FormComponent
            fieldName={withNamespace("encryptionKey")}
            label={"Wireless Encryption Key"}
            paddingX={0}
            submitSpinner={false}
            disabled={!allowEditing}
          />
        </Col>
                <Col xs={12}>
          <SelectComponent
            label={"Baud Rate"}
            options={[
              { label: 300, value: 300 },
              { label: 2400, value: 2400 },
              { label: 9600, value: 9600 },
            ]}
            name={withNamespace("baudRate")}
            paddingX={0}
            submitSpinner={false}
            disabled={!allowEditing || !allowComplexEditing}
          />
        </Col>

      </Row>
    </>
  );
};

export default MbusConfigurationForm;
