import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { PointOptionsObject } from "highcharts";
import { PieChartDataItem as PieChartDataInner } from "../../openapi/model/pieChartDataItem";

// eslint-disable-next-line @typescript-eslint/no-var-requires
require("highcharts/modules/no-data-to-display.js")(Highcharts);

export interface ColouredPieChartDataInner extends PieChartDataInner {
  color?: string;
}

export type ColouredPieChartData = Array<ColouredPieChartDataInner>;

const PieChart = (props: {
  data: ColouredPieChartData;
  half?: boolean;
  donut?: boolean;
}): JSX.Element => {
  const total = props.data.reduce(
    (sum: number, b: ColouredPieChartDataInner) =>
      sum + (b.count ? b.count : 0),
    0
  );
  const series = props.data.map((value): PointOptionsObject => {
    return {
      name: value.label,
      y: value.count as number,
      color: value.color ? value.color : undefined,
      dataLabels: {
        enabled: (value.count as number) / total >= 0.1,
      },
    };
  });

  const options: Highcharts.Options = {
    title: {
      text: "",
    },
    chart: {
      type: "pie",
      height: 180,
      // width: '100',
      plotBorderWidth: 0,
      spacing: [0, 0, 0, 0],
    },
    tooltip: {
      headerFormat: "",
      pointFormat: "{point.name}: <b>{point.y:.0f}</b>",
    },
    series: [
      {
        data: series,
        type: "pie",
        innerSize: props.donut ? "45%" : undefined,
        animation: false,
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          distance: -50,
          style: {
            fontWeight: "bold",
            color: "white",
          },
          format: "<b>{point.name}</b>: {point.y:.0f}",
        },
        startAngle: props.half ? -90 : undefined,
        endAngle: props.half ? 90 : undefined,
        center: ["50%", "75%"],
        size: "150%",
      },
    },
    exporting: {
      enabled: false,
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default PieChart;
