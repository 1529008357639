import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { OneCardPage } from "../../components/utils/OneCardPage";
import FormComponent from "../../components/Forms/FormComponent";
import { Button } from "reactstrap";
import {
  yupMatchPassword,
  yupPassword,
  yupRequiredString,
} from "../../components/Forms/yupValidators";
import useCreateEntity from "../../hooks/createEntity";
import { CompanyRole } from "../../openapi/model/companyRole";
import { useNavigate } from "react-router-dom";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import RadioButtonGroup from "../../components/Forms/RadioButtonGroup";
import { ApiUserIn } from "../../openapi/model/apiUserIn";

interface FormValues {
  clientId: string;
  userType: CompanyRole;
  clientSecret: string;
  confirmClientSecret: string;
}

const CreateApiUserForm = (props: { companyId: string }): JSX.Element => {
  const { createEntity, error } = useCreateEntity<ApiUserIn>(
    "API_USER",
    "user/api"
  );
  const navigate = useNavigate();

  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ): void => {
    setSubmitting(true);
    createEntity({
      clientId: values.clientId,
      clientSecret: values.clientSecret,
      role: values.userType,
      companyId: props.companyId,
    } as ApiUserIn)
      .then(() => {
        resetForm();
        navigate({ pathname: "/admin/users" });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialValues: FormValues = {
    clientId: "",
    userType: CompanyRole.Viewer,
    clientSecret: "",
    confirmClientSecret: "",
  };
  return (
    <OneCardPage
      headerTitle={"Create New Api Key"}
      breadcrumbs={[
        { url: "/admin/users", name: "Users" },
        { url: "/admin/users/api/create", name: "Create Api Key" },
      ]}
      noSchemeInBreadcrumbs={true}
    >
      <Formik
        onSubmit={submit}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          clientId: yupRequiredString.min(10).max(30),
          userType: yupRequiredString,
          clientSecret: yupPassword(20),
          confirmClientSecret: yupMatchPassword("clientSecret"),
        })}
        render={({
          values,
          setFieldValue,
          isValid,
          isSubmitting,
        }): JSX.Element => (
          <BlockSpinner loading={isSubmitting}>
            <Form>
              <div className={"row"}>
                <div className={"col-3"}>
                  <h3 className="text-white mb-0">Add Gateway</h3>
                </div>

                <FormComponent
                  fieldName={"clientId"}
                  label={"Client Id"}
                  submitSpinner={false}
                />
                <RadioButtonGroup
                  fieldName={"userType"}
                  title={"Role"}
                  options={[
                    { name: "admin", value: "admin" },
                    { name: "editor", value: "editor" },
                    { name: "viewer", value: "viewer" },
                  ]}
                  currentValue={values["userType"]}
                  setFieldValue={setFieldValue}
                  submitSpinner={false}
                />
                <FormComponent
                  fieldName={"clientSecret"}
                  label={"Client Secret"}
                  type={"password"}
                  submitSpinner={false}
                />
                <FormComponent
                  fieldName={"confirmClientSecret"}
                  label={"Confirm Client Secret"}
                  type={"password"}
                  submitSpinner={false}
                />
                <div className={"col-12"}>
                  <div className={"form-group row"}>
                    <Button
                      type={"submit"}
                      color={"primary"}
                      disabled={isSubmitting || !isValid}
                    >
                      Add Key
                    </Button>
                  </div>
                </div>
              </div>
              <ErrorBanner error={error} />
            </Form>
          </BlockSpinner>
        )}
      />
    </OneCardPage>
  );
};

export default CreateApiUserForm;
