import React from "react";
import { Routes } from "react-router-dom";

import AssetListPage from "./list/AssetListPage";
import AssetHomepage from "./AssetHomepage";
import BulkUpdateList from "../../components/BulkUpdate/BulkUpdateList";
import GenericUpdatesPage from "../../components/BulkUpdate/GenericUpdatesPage";
import { SentryRoute } from "../../App";
import SelectedScheme from "../../components/Scheme/SelectedScheme";

const GatewayPage = (): JSX.Element => {
  return (
    <Routes>
      <SentryRoute path="/" element={<AssetListPage />} />
      <SentryRoute
        path="/updates"
        element={
          <SelectedScheme>
            {(props) => <BulkUpdateList {...props} objectType={"Asset"} />}
          </SelectedScheme>
        }
      />
      <SentryRoute
        path={"/updates/:updateId"}
        element={
          <SelectedScheme>
            {(props) => (
              <GenericUpdatesPage
                {...props}
                title={"Assets"}
                header={"Updated Assets"}
                tableFields={[
                  "companyId",
                  "schemeId",
                  "assetPositionId",
                  "assetId",
                  "serialNumber",
                  "assetType",
                ]}
                tableFieldHeaders={[
                  "Company ID",
                  "Scheme ID",
                  "Asset Position Id",
                  "Asset Id",
                  "Serial Number",
                  "Asset Type",
                ]}
                objectKey={"assetId"}
              />
            )}
          </SelectedScheme>
        }
      />
      <SentryRoute
        path=":assetId"
        element={
          <SelectedScheme>
            {(props) => <AssetHomepage {...props} />}
          </SelectedScheme>
        }
      >
        <SentryRoute
          path=":tab"
          element={
            <SelectedScheme>
              {(props) => <AssetHomepage {...props} />}
            </SelectedScheme>
          }
        />
      </SentryRoute>
    </Routes>
  );
};

export default GatewayPage;
