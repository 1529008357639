import React from "react";
import { Event } from "../../openapi/model/event";
import GetListPaginatedTable from "../../components/Pagination/GetListPaginatedTable";
import EventListFilterForm, {
  useGetParsedEventListFilterParams,
} from "./EventListFilterForm";
import EventListRow from "./EventListRow";

export interface Props {
  assetId?: string;
  companyId: string;
  schemeId?: string;
  assetPositionId?: string;
  locationId?: string;
  jobId?: string;
  lastUpdated?: string;
  row?: (
    item: any,
    update: (updatedItem: any) => void
  ) => React.ReactElement<{ key: string }>;
  showPagination?: boolean;
  maxDisplay?: number;
  noDataRow?: JSX.Element;
}

export default function EventList(props: Props): JSX.Element {
  const { maxDisplay = 100 } = props;

  const { params } = useGetParsedEventListFilterParams();

  const header = (
    <tr>
      <th>Time</th>
      <th>Type</th>
      <th>Message</th>
      <th>User</th>
      <th>&nbsp;</th>
    </tr>
  );

  const row = props.row
    ? props.row
    : (value: Event): JSX.Element => (
        <EventListRow
          key={value.eventId}
          value={value}
          eventListProps={props}
        />
      );

  return (
    <>
      <GetListPaginatedTable
        entityType={"EVENT"}
        params={{
          companyId: props.companyId,
          schemeId: props.schemeId,
          assetId: props.assetId,
          assetPositionId: props.assetPositionId,
          locationId: props.locationId,
          jobId: props.jobId,
          eventTypes: params.eventTypes,
          startDatetime: params.startDatetime?.toISOString(true),
          endDatetime: params.endDatetime?.toISOString(true),
        }}
        tableClassName={"table-sm"}
        cursor={{ maxItems: maxDisplay, direction: "desc" }}
        header={[header, 4]}
        row={row}
        noDataRow={props.noDataRow}
        filterForm={(props) => <EventListFilterForm {...props} />}
      />
    </>
  );
}
