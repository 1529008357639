import React, {useEffect, useRef, useState} from 'react';
import EditModal from "../../../components/Forms/EditModal";
import {AssetPositionAssetLinkOut} from "../../../openapi/model/assetPositionAssetLinkOut";
import {Button} from "reactstrap";
import {UpdateEntity} from "../../../hooks/updateEntity";
import {ButtonProps} from "reactstrap/es/Button";
import AssetPositionAssetLinkForm from "./AssetPositionAssetLinkForm";
import {FormikProps} from "formik";
import moment, {Moment} from "moment";

interface EditableAssetPositionAssetLinkOut extends  Omit<AssetPositionAssetLinkOut, 'uninstallDate'|'installDate'> {
    installDate?: Moment;
    uninstallDate?: Moment;
}

function AssetPositionAssetUpdateModal(props: {
    link: AssetPositionAssetLinkOut,
    update: UpdateEntity<AssetPositionAssetLinkOut>,
    buttonContent?: React.ReactNode,
    buttonProps?: ButtonProps,
    className?: string
} ){

  const inputRef = useRef();
  const setModalFocus = () => {
    const node = inputRef.current as any;
    node && node.focus();
  };

    const {buttonContent = <span><i className={'fas fa-fw fa-edit'}/> Edit</span>, buttonProps={color:'primary', size:'sm'}} = props

    const [values, setValues] = useState<EditableAssetPositionAssetLinkOut>({...props.link, installDate: undefined, uninstallDate:undefined})

    useEffect(() => {
        setValues({
                ...props.link,
                installDate: props.link.installDate && moment(props.link.installDate),
                uninstallDate: props.link.uninstallDate && moment(props.link.uninstallDate)
            })
    }, [props.link])
  const updateFunction = (entity: Partial<EditableAssetPositionAssetLinkOut>, path?: string):  Promise<void> => {
        return props.update.updateEntity({
            ...entity,
            installDate: entity.installDate?.toDate(),
            uninstallDate: entity.uninstallDate?.toDate(),
        },path)
  }

  const [setModalOpen, modal] = EditModal<EditableAssetPositionAssetLinkOut>(
        {
            setModalFocus,
            values,
            children: (formikProps: FormikProps<AssetPositionAssetLinkOut>) => (
                <AssetPositionAssetLinkForm {...formikProps}/>
            ),
            title: "Edit Link",
            update: {...props.update, updateEntity: updateFunction}
        }
    );

    return (
        <>
            {modal}
            <Button onClick={setModalOpen} {...buttonProps}
            className={props.className}>{buttonContent}</Button>
        </>
    );
}

export default AssetPositionAssetUpdateModal;