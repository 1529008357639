import React from "react";
import { Col, Row, Table } from "reactstrap";
import { DeviceAsset } from "../../../openapi/model/deviceAsset";
import SwitchRelayStateButtons from "./SwitchRelayStateButtons";
import RelayStateLabel from "./RelayStateLabel";
import moment from "moment";
import HighlightNullAndOldDates from "../../../components/utils/HighlightNullAndOldDates";
import { InputOutputModuleState } from "../../../openapi/model/inputOutputModuleState";

const InputOutputModuleRelayStateTab = (props: {
  asset: DeviceAsset;
  refresh?: () => void;
}): JSX.Element => {
  const state: InputOutputModuleState = props.asset
    .state as InputOutputModuleState;
  return (
    <Row>
      <Col xs={12}>
        <Row>
          <Col xs={12}>
            <h3>Relay State</h3>
          </Col>
          <Col xs={12} lg={6} xl={3}>
            <Table>
              <tr>
                <td>Current State</td>
                <td>
                  <RelayStateLabel state={state.relayState} />
                  <HighlightNullAndOldDates
                    ageHighlight={moment.duration(3, "days")}
                    unknownText={"Date of status unknown."}
                  >
                    {state.lastUpdate}
                  </HighlightNullAndOldDates>
                </td>
              </tr>
              <tr>
                <td>Switch State</td>
                <td>
                  <SwitchRelayStateButtons
                    asset={props.asset}
                    refresh={props.refresh}
                  />
                </td>
              </tr>
            </Table>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default InputOutputModuleRelayStateTab;
