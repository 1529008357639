import React, { InputHTMLAttributes } from "react";
import { Col, UncontrolledTooltip } from "reactstrap";
import classNames from "classnames";
import SubmitStateSpinner, {
  SubmitStateSpinnerProps,
} from "./SubmitStateSpinner";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface FormFieldProps extends SubmitStateSpinnerProps {
  label?: string;
  fieldName?: string;
  paddingX?: number | null;
  paddingY?: number;
  labelPosition?: "left" | "right";
  textPosition?: "left" | "right";
  autoSize?: boolean;
  submitSpinner?: boolean;
  infoText?: string;
  vertical?: boolean;
  labelClass?: string;
}

export interface InputFieldProps
  extends FormFieldProps,
    InputHTMLAttributes<HTMLInputElement> {}

const LabelWrapper = (
  props: FormFieldProps & { children: React.ReactNode }
): JSX.Element => {
  const {
    label,
    fieldName,
    paddingX = null,
    paddingY = 1,
    labelPosition = "left",
    textPosition = "right",
    autoSize = false,
    submitSpinner = true,
    infoText = undefined,
    vertical = false,
    ...rest
  } = props;

  return (
    <Col
      xs={12}
      className={classNames({
        [`py-${paddingY}`]: true,
        [`px-${paddingX}`]: paddingX !== null,
      })}
    >
      <div className="form-group row">
        {label && (
          <label
            htmlFor={fieldName}
            className={classNames(
              {
                [`col-form-label pl-0 pr-2 float-${labelPosition}`]: true,
                "mr-2": autoSize,
                "col-12": !autoSize && vertical,
                "col-4": !vertical && !autoSize,
              },
              props.labelClass
            )}
          >
            {label}
            {infoText && (
              <>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  id={`tooltip-${fieldName?.replaceAll('.','-')}`}
                  className={"mx-1"}
                />
                <UncontrolledTooltip target={`tooltip-${fieldName?.replaceAll('.','-')}`}>
                  {infoText}
                </UncontrolledTooltip>
              </>
            )}
          </label>
        )}
        <Col
          xs={autoSize ? "auto" : label && !vertical ? 8 : 12}
          className={`text-${textPosition} px-0`}
        >
          {submitSpinner ? (
            <>
              <span style={{ width: "90%", display: "inline-block" }}>
                {props.children}
              </span>
              <span style={{ width: "10%", display: "inline-block" }}>
                <SubmitStateSpinner {...rest} />
              </span>
            </>
          ) : (
            <>{props.children}</>
          )}
        </Col>
      </div>
    </Col>
  );
};

export default LabelWrapper;
