import React from 'react';
import {Form, Formik} from "formik";
import TextInputField from "../../components/Forms/TextInputField";
import TextInput from "../../components/Forms/TextInput";
import {WirelessSurveyDataCollectorOut} from "../../openapi/model/wirelessSurveyDataCollectorOut";
import {Button, Col, Row} from 'reactstrap';
import moment, {Moment} from "moment";
import {TextFieldTextType} from "@psd-platform/monday-ui-react-core/dist/types/components/TextField/TextFieldConstants";


interface FormValues {
    samplePointName: string;
    startDateTime: string;
    endDateTime?: string;
}


interface ActionValues {
    samplePointName: string;
    startDateTime: Moment;
    endDateTime?: Moment;

}

function WirelessSurveySamplePointForm(props: {
    dataCollector: WirelessSurveyDataCollectorOut,
    refresh?: () => any,
    submit?: (values: ActionValues) => Promise<any>,
    onCancel?: () => any
}) {
    const timeFormat = 'yyyy-MM-DD[T]HH:mm'

    return (
        <Formik
            initialValues={{
                samplePointName: '',
                startDateTime: moment().endOf('minute').format(timeFormat),
                endDateTime: undefined,

            } as FormValues}
            onSubmit={(values) => {
                props.submit && props.submit({
                    ...values,
                    startDateTime: moment(values.startDateTime, timeFormat),
                    endDateTime: values.endDateTime ? moment(values.endDateTime, timeFormat) : undefined
                })

            }}
            onReset={() => {
                props.onCancel && props.onCancel()
            }}
        >
            {() =>
                    <Form>
                <Row noGutters={true}>
                        <Col xs={12}>
                            <TextInputField name={"samplePointName"}>
                                {(fieldProps) => <TextInput
                                    {...fieldProps}
                                    label={{label: 'Sample Point Name'}}
                                />}
                            </TextInputField>
                        </Col>
                        <Col xs={12}>
                            <TextInputField name={"startDateTime"}>
                                {(fieldProps) => <TextInput
                                    {...fieldProps}
                                    type={'datetime-local' as TextFieldTextType}
                                    label={{label: 'Sample Point Start Date Time'}}
                                />}
                            </TextInputField>
                        </Col>
                        <Col xs={12}>
                            <TextInputField name={"endDateTime"}>
                                {(fieldProps) => <TextInput
                                    {...fieldProps}
                                    type={'datetime-local' as TextFieldTextType}
                                    label={{label: 'Sample Point End Date Time'}}
                                />}
                            </TextInputField>
                        </Col>
                        <Col xs={12} className={'text-right'}>
                            <Button color={'danger'} type={'reset'} size={'sm'}>Cancel</Button>
                            <Button color={'primary'} type={'submit'} size={'sm'}>Add</Button>
                        </Col>
                </Row>
                    </Form>
            }
        </Formik>
    )
}

export default WirelessSurveySamplePointForm;