import React, { useEffect, useMemo, useState } from "react";
import { BulkUpdateStatus } from "../../openapi/model/bulkUpdateStatus";
import { OneCardPage } from "../utils/OneCardPage";
import BlockSpinner from "../Spinners/BlockSpinner";
import UpdateProgressBanner from "./UpdateProgressBanner";
import { BulkUpdateExecutionStats } from "../../openapi/model/bulkUpdateExecutionStats";
import GenericUpdateErrorsBanner from "./GenericUpdateErrorsBanner";
import UnusedColumnsBanner from "./UnusedColumnsBanner";
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import ExecuteBulkUpdateButton from "./ExecuteBulkUpdateButton";
import { BulkUpdateInstruction } from "../../openapi/model/bulkUpdateInstruction";
import classNames from "classnames";
import GenericUpdateList from "./GenericUpdateList";
import { GenericUpdateProps } from "./GenericUpdatesPage";
import { GetEntityResultProps } from "../../core/action/GetEntity";

interface Props extends GenericUpdateProps, GetEntityResultProps<any> {}

const filterPayloadList = (
  instructions: BulkUpdateInstruction[],
  filter: string,
  errors = false
): BulkUpdateInstruction[] => {
  return instructions.filter((instruction: any) => {
    const hasErrors =
      instruction.validationErrors.length > 0 ||
      (instruction.immutableChangeErrors &&
        instruction.immutableChangeErrors.length > 0);
    return (
      instruction.type === filter &&
      ((hasErrors && errors) || (!hasErrors && !errors))
    );
  });
};

const arrayToObject = (array: any[], key: string): any =>
  array.reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {});

const idKeyProperty = (objectType: string): string => {
  const idKeys: { [key: string]: string } = {
    asset_position: "assetPositionId",
    location: "locationId",
    asset: "assetId",
  };
  return idKeys[objectType];
};

const GenericUpdatesDisplay = (props: Props): JSX.Element => {
  const [state, setState] = useState<{ active: string }>({ active: "0" });

  const { data, loading, refresh } = props;

  const selected = useMemo(
    () =>
      data && {
        ...data,
        existingMap: arrayToObject(
          data.existing,
          idKeyProperty(data.objectType)
        ),
      },
    [data]
  );

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined = undefined;
    const setTimer = (): void => {
      if (selected) {
        const status = selected.status;

        if (
          selected &&
          (status === BulkUpdateStatus.EXECUTIONSTARTED ||
            status === BulkUpdateStatus.PREVIEWGENERATING)
        ) {
          if (timer) {
            clearTimeout(timer);
          }
          timer = setTimeout(() => {
            refresh();
          }, 5000);
        }
      }
    };
    setTimer();
    return () => timer && clearTimeout(timer);
  }, [selected, refresh]);

  const toggleTab = (tab: string): void => {
    if (state.active !== tab) {
      setState({ active: tab });
    }
  };

  const tabHeader = (title: string, index: string): JSX.Element => {
    return (
      <NavItem key={index}>
        <NavLink
          className={classNames({ active: state.active === index })}
          onClick={(): void => {
            toggleTab(index);
          }}
        >
          {title}
        </NavLink>
      </NavItem>
    );
  };

  const tabContent = (
    title: string,
    list: BulkUpdateInstruction[],
    index: string,
    actioned: boolean
  ): JSX.Element => {
    return (
      <TabPane tabId={index} key={index}>
        <Row>
          <Col>
            <Card style={{ overflowX: "scroll" }}>
              {selected ? (
                <GenericUpdateList
                  title={title}
                  list={list}
                  listPrefix={index}
                  tableFieldHeaders={props.tableFieldHeaders}
                  tableFields={props.tableFields}
                  existingMap={selected.existingMap}
                  actioned={actioned}
                  objectKey={props.objectKey}
                />
              ) : (
                <></>
              )}
            </Card>
          </Col>
        </Row>
      </TabPane>
    );
  };

  const executed = selected?.status !== BulkUpdateStatus.PREVIEWCREATED;

  const lists: {
    title: string;
    list: BulkUpdateInstruction[];
    render?: () => JSX.Element;
  }[] = selected
    ? [
        { title: "All", list: selected.preview },
        {
          title: "New",
          list: filterPayloadList(selected.preview, "NEW"),
        },
        {
          title: "Invalid New ",
          list: filterPayloadList(selected.preview, "NEW", true),
        },
        {
          title: "Valid Changes",
          list: filterPayloadList(selected.preview, "UPDATE"),
        },
        {
          title: "Invalid Changes",
          list: filterPayloadList(selected.preview, "UPDATE", true),
        },
        {
          title: "Unchanged",
          list: filterPayloadList(selected.preview, "UNCHANGED"),
        },
        {
          title: "Rows Not Found on Server",
          list: filterPayloadList(selected.preview, "NOT_FOUND"),
        },
        {
          title: "Rows Found on Server Deleted",
          list: filterPayloadList(selected.preview, "DELETED"),
        },
      ].filter((l) => l.list.length > 0)
    : [];

  const actioned = selected?.status !== BulkUpdateStatus.PREVIEWCREATED;
  const header = props.header + (actioned ? " Status" : " Preview");

  return (
    <OneCardPage
      headerName={"Updated"}
      headerParentName={props.title}
      headerTitle={header}
    >
      <BlockSpinner loading={loading}>
        <div style={{ minHeight: "300px" }}>
          <h3>Update: {selected?.updateId}</h3>
          {selected ? (
            <>
              {executed && (
                <UpdateProgressBanner
                  status={selected.status}
                  previewStats={selected.previewStats}
                  executeStats={
                    selected.executeStats as BulkUpdateExecutionStats
                  }
                />
              )}

              {!executed && (
                <GenericUpdateErrorsBanner
                  previewStats={selected.previewStats}
                />
              )}
              <UnusedColumnsBanner unusedColumns={selected.ignoredFields} />

              {selected.preview.length > 0 ? (
                <>
                  <Nav tabs>
                    {lists.map((value, index) => {
                      return tabHeader(
                        `${value.title} (${value.list.length})`,
                        index.toString()
                      );
                    })}
                  </Nav>
                  <TabContent activeTab={state.active}>
                    {lists.map((value, index) => {
                      return tabContent(
                        value.title,
                        value.list,
                        index.toString(),
                        actioned
                      );
                    })}
                  </TabContent>{" "}
                </>
              ) : (
                <div>
                  <h3>No content in this update.</h3>
                </div>
              )}
              {/*<GenericUpdateToCsvExportButton instructions={this.selected.preview} idKey={this.props.objectKey}*/}
              {/*                                existingMap={this.selected.existingMap}/>*/}
              {selected.status === BulkUpdateStatus.PREVIEWCREATED && (
                <ExecuteBulkUpdateButton
                  companyId={selected.companyId}
                  schemeId={selected.schemeId}
                  updateId={selected.updateId}
                  refresh={refresh}
                />
              )}
            </>
          ) : (
            <p>Update loading</p>
          )}
        </div>
      </BlockSpinner>
    </OneCardPage>
  );
};

export default GenericUpdatesDisplay;
