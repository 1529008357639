import React, {useState} from "react";
import * as Yup from "yup";
import {SubFormProps,} from "../../../components/Forms/WithSubForm";
import RoundingMethodSelect from "../Common/RoundingSelect";
import {BillingReportFields} from "../../../openapi/model/billingReportFields";
import {BillableAssets} from "openapi/model/billableAssets";
import _ from "lodash";
import VolumeUnitSelect from "../Common/VolumeUnitSelect";
import EnergyUnitSelect from "../Common/EnergyUnitSelect";
import {Col, Collapse, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusCircle, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {CumulativeDataConfiguration} from "../../../openapi/model/cumulativeDataConfiguration";
import SelectComboField from "../../../components/Forms/SelectComboField";
import NewCheckboxComponent from "../../../components/Forms/NewCheckboxComponent";
import LabelSelect from "../../../components/TimeSeries/AggregatedTimeSeriesDataReport/LabelSelect";
import NewCheckboxField from "../../../components/Forms/NewCheckboxField";

export const BillingConfigurationSubFormSchema = (
    includedFieldsEditable = true
) => {
    return Yup.object().shape({
        configuration: includedFieldsEditable
            ? Yup.object().shape({
                includedFields: Yup.array().required().min(1),
                includedAssetTypes: Yup.array().required().min(1),
            })
            : Yup.object().shape({
                includedAssetTypes: Yup.array().required().min(1),
            }),
    });
};

export const BillingConfigurationInitialValues = (
    includedFieldsEditable = true
): { configuration: CumulativeDataConfiguration } => {
    return {
        configuration: {
            energy: {
                rounding: "WHOLE",
                units: "kWh",
            },
            volume: {
                rounding: "ONE_DP",
                units: "m3",
            },
            includedFields: includedFieldsEditable
                ? [
                    BillingReportFields.SchemeName,
                    BillingReportFields.LocationAddress,
                    BillingReportFields.AssetPositionId,
                    BillingReportFields.AssetPositionReference,
                    BillingReportFields.SerialNumber,
                    BillingReportFields.BillableType,
                    BillingReportFields.BillableUnit,
                ]
                : undefined,
            includedAssetTypes: [
                BillableAssets.HEATMETER,
                BillableAssets.GASMETER,
                BillableAssets.COOLINGMETER,
                BillableAssets.HEATINGANDCOOLINGMETER,
                BillableAssets.ELECTRICITYMETER,
                BillableAssets.WATERMETER,
            ],
            periodLabel: "right",
        },
    };
};

interface BillingConfigurationFormProps extends SubFormProps {
    includedFieldsEditable?: boolean
}

const BillingConfigurationForm = ({
                                      namespace,
                                      disabled,
                                      includedFieldsEditable = true,
                                  }: BillingConfigurationFormProps): JSX.Element => {

    const withNamespace = (fieldName: string): string => {
        return namespace ? `${namespace}.${fieldName}` : fieldName
    }

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <>
            <Row>
                <Col xs={12}>
                    <h4>
                        Advanced Configuration
                        <FontAwesomeIcon
                            icon={isOpen ? faMinusCircle : faPlusCircle}
                            onClick={toggle}
                            className={"mx-1"}
                        />
                    </h4>
                </Col>
            </Row>
            <Collapse isOpen={isOpen}>
                <Row>
                    <Col xs={12}>
                        <SelectComboField
                            name={withNamespace("energy.rounding")}
                        >
                            {(theFieldProps) => <RoundingMethodSelect
                                label={{label: "Energy Rounding"}}
                                {...theFieldProps}
                                disabled={disabled}
                            />}
                        </SelectComboField>

                    </Col>
                    <Col xs={12}><SelectComboField
                        name={withNamespace("energy.units")}
                    >
                        {(theFieldProps) => <EnergyUnitSelect
                            label={{label: "Energy Units"}}
                            {...theFieldProps}
                            disabled={disabled}

                        />}
                    </SelectComboField>

                    </Col>
                    <Col xs={12}>
                        <SelectComboField
                            name={withNamespace("volume.rounding")}
                        >
                            {(theFieldProps) => <RoundingMethodSelect

                                label={{label: "Volume Rounding"}}
                                {...theFieldProps}
                                disabled={disabled}

                            />}
                        </SelectComboField>
                    </Col>
                    <Col xs={12}>
                        <SelectComboField
                            name={withNamespace("volume.units")}
                        >
                            {(theFieldProps) =>
                                <VolumeUnitSelect
                                    label={{label: "Volume Units"}}
                                    {...theFieldProps}
                                    disabled={disabled}

                                />}
                        </SelectComboField>
                    </Col>
                    {includedFieldsEditable && (
                        <Col xs={12}>
                            <NewCheckboxField
                                name={withNamespace("includedFields")}
                            >
                                {(fieldProps) =>
                                    <NewCheckboxComponent
                                        label={{label: "Report Field Filter"}}
                                        options={Object.values(BillingReportFields).map((key) => {
                                            return {label: key, value: key};
                                        })}
                                        disabled={disabled}
                                        {...fieldProps}
                                    />
                                }
                            </NewCheckboxField>
                        </Col>
                    )}
                    <Col xs={12}>
                        <NewCheckboxField
                            name={withNamespace("includedAssetTypes")}
                        >
                            {(fieldProps) =>
                                <NewCheckboxComponent
                                    {...fieldProps}
                                    label={{label: "Asset Type Filter"}}
                                    options={Object.values(BillableAssets).map((key) => {
                                        return {label: _.upperFirst(_.lowerCase(key)), value: key};
                                    })}
                                    disabled={disabled}
                                />
                            }
                        </NewCheckboxField>
                    </Col>
                    <Col xs={12}> <SelectComboField
                        name={withNamespace("periodLabel")}
                    >
                        {(theFieldProps) =>
                            <LabelSelect
                                label={{label: "Period Labelling"}}
                                {...theFieldProps}
                                disabled={disabled}

                            />}
                    </SelectComboField>
                    </Col>
                </Row>
            </Collapse>
        </>
    );
};


export default BillingConfigurationForm;
