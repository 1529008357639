import React from "react";
import DisplayIfUserType from "../utils/DisplayIfUserType/DisplayIfUserType";
import classNames from "classnames";

interface Props {
  title: string;
  children: React.ReactNode | string;
  type?: "heading" | "detail";
  editAction?: () => void;
  editActionUserType?: "viewer" | "editor" | "admin" | "superadmin";
}

const ObjectPropertyItem = (props: Props): JSX.Element => {
  const {
    title,
    children,
    type = "detail",
    editAction,
    editActionUserType = "admin",
  } = props;

  const detail = (
    <>
      {React.isValidElement(children) ? (
        React.cloneElement(children)
      ) : (
        <>{children}</>
      )}
    </>
  );

  return (
    <div
      className={type === "detail" ? "ml-md-4 mr-md-0 mr-4" : "mr-4"}
      data-cy={`heading-${title}`}
    >
      <h6 style={{ textTransform: "uppercase", marginBottom: 0 }}>
        {title}
        {editAction && (
          <DisplayIfUserType userTypes={editActionUserType}>
            <i
              className={classNames({
                "fa fa-pen ml-1": true,
              })}
              data-cy={"edit"}
              style={{ fontSize: "9px" }}
              onClick={() => editAction()}
            />
          </DisplayIfUserType>
        )}
      </h6>
      {type === "detail" ? <>{detail}</> : <h2>{detail}</h2>}
    </div>
  );
};

export default ObjectPropertyItem;
