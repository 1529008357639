import * as H from "history";

export interface RefererState {
  initialized: boolean;
  path?: H.Location;
}

export interface Action {
  type: string;
  payload: any;
}

const setReferer = (state: RefererState, action: Action): RefererState => {
  if (state.initialized) {
    return state;
  } else
    return {
      initialized: true,
      path:
        action.payload.pathname === "/auth/login" ? undefined : action.payload,
    };
};

const Reducer = (state: RefererState, action: Action): RefererState => {
  switch (action.type) {
    case "SET_REFERER":
      return setReferer(state, action);
    case "CLEAR_REFERER":
      return {
        initialized: true,
        path: undefined,
      };
    default:
      return state;
  }
};

export default Reducer;
