import React from "react";
import {AssetRegisterEntitySuccessProps} from "../../core/action/GetAssetRegisterEntitiesCombination";
import {OneCardPage} from "../../components/utils/OneCardPage";
import {
    assetPositionToBreadcrumb,
    assetToBreadcrumb,
    locationToBreadcrumb,
} from "../../components/navigation/breadcrumbs/Breadcrumbs";
import {Location} from "../../openapi/model/location";
import {AssetPosition} from "../../openapi/model/assetPosition";
import {Asset} from "../../openapi/model/asset";
import {Col, Row} from "reactstrap";
import InnerAssetPreview from "./InnerAssetPreview";
import GatewayHomepage from "./gateway/GatewayHomepage";
import {GatewayAsset} from "../../openapi/model/gatewayAsset";
import {AssetType} from "../../openapi/model/assetType";
import RoutedTabGroupInterface from "../../components/TabGroup/RoutedTabGroupInterface";
import InputOutputModuleRelayStateTab from "./input_output_module/InputOutputModuleRelayStateTab";
import {DeviceAsset} from "../../openapi/model/deviceAsset";
import MeterTimeSeriesCharts from "./meters/MeterTimeSeriesCharts";
import AssetTimeSeriesDataTableTab from "./meters/AssetTimeSeriesDataTableTab";
import VirtualAssetTab from "./VirtualAssetTab";
import AssetPositionAssetList from "../assetPositions/assetPositionAssetLinks/AssetPositionAssetList";
import EventList from "../event/EventList";
import DeviceConfigurationTab from "./meters/configuration/DeviceConfigurationTab";
import WirelessReceiverConfigurationTab from "./meters/configuration/WirelessReceiverConfigurationTab";
import AssetRfTab from "./meters/AssetRFTab";

interface State {
  init: boolean;
  asset?: Asset;
  assetPosition?: AssetPosition;
  location?: Location;
  loading?: boolean;
}

const renderAssetTabs = (
  assetId: string,
  state: State,
  assetType: AssetType,
  refresh?: () => void
): JSX.Element => {
  return (
    <RoutedTabGroupInterface
      tabs={[
        state.asset && assetType === AssetType.InputOutputModule
          ? {
              tabName: "Relay State",
              tabContent: (
                <InputOutputModuleRelayStateTab
                  asset={state.asset as DeviceAsset}
                  refresh={refresh}
                />
              ),
              tabPath: "relay_state",
            }
          : undefined,
        {
          tabName: "Charts",
          tabContent: (
            <MeterTimeSeriesCharts device={state.asset as DeviceAsset} />
          ),
          tabPath: "charts",
            keepSearchString: true,
        },
        {
          tabName: "Data",
          tabContent: (
            <AssetTimeSeriesDataTableTab device={state.asset as DeviceAsset} />
          ),
          tabPath: "data",
            keepSearchString: true,
        },
        state.asset && assetType !== AssetType.Gateway
          ? {
              tabName: "RF Data",
              tabContent: (
                <>
                  <AssetRfTab device={state.asset} assetPosition={state.assetPosition as AssetPosition}/>
                </>
              ),
              tabPath: "rf",
              userTypes: "superadmin",
            keepSearchString: true,
            }
          : undefined,
        state.asset && {
          tabName: "Configuration",
          tabPath: "configuration",
          tabContent: (
            <>
              {state.asset.assetType === "wireless_receiver" ? (
                <WirelessReceiverConfigurationTab
                  asset={state.asset as DeviceAsset}
                />
              ) : (
                <DeviceConfigurationTab asset={state.asset as DeviceAsset} />
              )}
            </>
          ),
          keepSearchString: false,
        },
        state.asset &&
        assetType !== AssetType.InputOutputModule &&
        state.assetPosition
          ? {
              tabName: "Virtual Assets",
              tabPath: "virtual_assets",
              tabContent: (
                <VirtualAssetTab
                  asset={state.asset as DeviceAsset}
                  assetPosition={state.assetPosition}
                />
              ),
              keepSearchString: false,
            }
          : undefined,
        {
          tabName: "Asset Position History",
          tabPath: "link",
          tabContent: (
            <Row>
              <Col xs={12}>
                <h3>Asset Position Asset History</h3>
                {state.asset && (
                  <AssetPositionAssetList
                    companyId={state.asset.companyId}
                    schemeId={state.asset.schemeId}
                    assetId={state.asset.assetId}
                    view={"assetPosition"}
                  />
                )}
              </Col>
            </Row>
          ),
          userTypes: "superadmin",
          keepSearchString: false,
        },
        {
          tabName: "Events",
          tabContent: (
            <>
              <h3>Event List</h3>
              {state.asset && (
                <EventList
                  assetId={state.asset.assetId}
                  companyId={state.asset.companyId}
                />
              )}
            </>
          ),
          tabPath: "events",
          keepSearchString: false,
        },
      ]}
      topLevelPath={`/admin/asset/${assetId}/:tab`}
    />
  );
};

const AssetDisplay = ({
  assetPosition,
  location,
  asset,
  loading,
  refresh,
}: AssetRegisterEntitySuccessProps): JSX.Element => {
  const assigned: boolean = asset
    ? asset.assetPositionId !== undefined && asset.assetPositionId !== null
    : false;

  return (
    <>
      <OneCardPage
        headerTitle={"Asset Detail"}
        breadcrumbs={
          asset
            ? assigned
              ? location &&
                assetPosition && [
                  locationToBreadcrumb(location as Location),
                  assetPositionToBreadcrumb(assetPosition as AssetPosition),
                  assetToBreadcrumb(asset as Asset),
                ]
              : [
                  { name: "Assets", url: "/admin/assets/" },
                  assetToBreadcrumb(asset as Asset),
                ]
            : []
        }
        loading={loading}
      >
        {asset && (
          <>
            <Row>
              <Col xs={12}>
                <InnerAssetPreview
                  asset={asset}
                  assetPosition={assetPosition}
                  location={location}
                  refresh={refresh}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className={"py-2"}>
                {asset && (
                  <>
                    {asset.assetType === "gateway" ? (
                      <GatewayHomepage gateway={asset as GatewayAsset} />
                    ) : (
                      renderAssetTabs(
                        asset.assetId,
                        { init: true, loading, asset, assetPosition },
                        asset.assetType,
                        refresh
                      )
                    )}
                  </>
                )}
              </Col>
            </Row>
          </>
        )}
      </OneCardPage>
    </>
  );
};

export default AssetDisplay;
