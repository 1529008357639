import _ from "lodash";

export function removeBlankStringsFromObject(obj: any): any {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ""));
}

export function objectBlankStringsToUndefined(obj: any): any {
  if (_.isObject(obj)) {
    if (_.isArray(obj)) {
      return obj.map(objectBlankStringsToUndefined);
    } else {
      return Object.fromEntries(
        Object.entries(obj).map(([k, v]) => {
          return [k, objectBlankStringsToUndefined(v)];
        })
      );
    }
  }
  return obj !== "" ? obj : undefined;
}

export function removeMapBlanks(map: { [key: string]: any }): {
  [key: string]: any;
} {
  Object.keys(map).forEach((k) => {
    if (_.isObject(map[k])) map[k] = removeMapBlanks(map[k]);
    if (map[k] === "" || map[k] === null || map[k] === undefined) delete map[k];
  });
  return map;
}

export function removeBlanksFromArray(arr: any): any {
  if (_.isArray(arr)) {
    return arr.filter((v) => {
      return v !== undefined && v !== "";
    });
  }
  return arr;
}
export function removeMapBlankObjects(obj: any): any {
  if (_.isObject(obj)) {
    if (_.isArray(obj)) {
      return obj.map(removeMapBlankObjects);
    }
    return Object.fromEntries(
      Object.entries(obj).map(([k, v]) => {
        if (_.isObject(v)) {
          if (_.isEmpty(v)) {
            return [k, undefined];
          }
          if (_.isArray(v)) {
            return [k, removeBlanksFromArray(v)];
          }
          return [k, removeMapBlankObjects(removeMapBlanks(v))];
        }
        return [k, v];
      })
    );
  }
  return obj;
}
