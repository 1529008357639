import React from "react";
import { AssetRegisterItem } from "../../openapi/model/assetRegisterItem";
import { Col } from "reactstrap";
import AssetPositionNumbersToStringList from "../../components/AssetPosition/AssetPositionNumberSummaryList";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import PrivacyMode from "../../components/Text/PrivacyMode";
import SchemeLabel from "../../components/Scheme/SchemeLabel";

export default function WirelessClusterListRowInner(props: {
  assetRegisterItem: AssetRegisterItem;
  showScheme?: boolean;
}): JSX.Element {
  const { assetRegisterItem, showScheme } = props;
  const { location, assetPosition } = assetRegisterItem;

  return (
    <li
      className={"checklist-entry list-group-item px-0 px-md-2"}
      data-cy={"asset-register-list-item"}
    >
      <div className={"d-md-flex d-none"}>
        {/* Version for large screens */}
        <>
          <Col md={3} lg={4}>
            <CompanyNavLink
              className={"p-0 d-inline"}
              to={`/admin/asset_position/${assetPosition?.assetPositionId}`}
            >
              <PrivacyMode>{location?.address}</PrivacyMode>
            </CompanyNavLink>
          </Col>
          <Col md={6} lg={6}>
            {assetRegisterItem.assetPosition.childAssetPositions?.gateway || 0}{" "}
            gateways
          </Col>
          <Col md={6} lg={6}>
            <AssetPositionNumbersToStringList
              numbers={assetRegisterItem.assetPosition.childAssetPositions}
              includeGateways={false}
            />
          </Col>
          {showScheme && (
            <div className={"mr-4"}>
              (<SchemeLabel schemeId={location.schemeId} />)
            </div>
          )}
        </>
      </div>
      <div className={"d-md-none d-flex flex-wrap"}>
        {/* Version for small screens */}
        <Col xs={12} className={"d-inline-block d-sm-flex"}>
          <div className={"mr-4"}>
            <CompanyNavLink
              className={"p-0 d-inline"}
              to={`/admin/asset_position/${assetPosition?.assetPositionId}`}
            >
              {location?.address}
            </CompanyNavLink>
            {" - "}
          </div>
          {/* TODO: add the stats here for small screens*/}
          {showScheme && (
            <Col md={2}>
              <SchemeLabel schemeId={location.schemeId} />
            </Col>
          )}
        </Col>
      </div>
    </li>
  );
}
