import React from "react";
import {GatewayAsset} from "../../../openapi/model/gatewayAsset";
import {Col, Row} from "reactstrap";
import DisplayIfUserType from "../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import GatewayConfigurationDropdown from "./GatewayConfigurationDropdown";
import GatewaySettingForm from "./GatewaySettingForm";
import GatewayConnectionDisplay from "./GatewayConnectionDisplay";

const GatewayConfigurationTab = (props: {
    gateway: GatewayAsset;
}): JSX.Element => {
    const {gateway} = props;
    return (
        <>
            <Row>
                <DisplayIfUserType userTypes={"superadmin"}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                        <h3>Gateway Settings</h3>
                        <GatewaySettingForm gateway={gateway}/>
                    </Col>
                </DisplayIfUserType>

                <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                    <GatewayConnectionDisplay gateway={gateway}/>
                </Col>

                <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                    <DisplayIfUserType userTypes={"admin"}>
                        <GatewayConfigurationDropdown gateway={gateway}/>
                    </DisplayIfUserType>
                </Col>
            </Row>
        </>
    );
};

export default GatewayConfigurationTab;
