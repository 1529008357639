import React, { useRef } from "react";
import EditModal from "../../components/Forms/EditModal";
import { FormikProps } from "formik";
import useUpdateEntity from "../../hooks/updateEntity";
import { CompanyUser } from "./users";
import RadioButtonGroup from "../../components/Forms/RadioButtonGroup";
import { Col, Row } from "reactstrap";

const EditUserTypeModel = (
  userCompany: CompanyUser,
  postUpdate?: (updatedCompany: CompanyUser) => void
): [() => void, JSX.Element] => {
  const inputRef = useRef();
  const setModalFocus = () => {
    const node = inputRef.current as any;
    node && node.focus();
  };

  return EditModal<CompanyUser>({
    title: "Update User",
    values: userCompany,
    update: useUpdateEntity(
      "USER",
      userCompany.userId,
      (updatedUserCompany: Partial<CompanyUser>) =>
        postUpdate && postUpdate({ ...userCompany, ...updatedUserCompany }),
      `user/${userCompany.userId}/company/${userCompany.companyId}`
    ),
    size: "lg",
    children: (formikProps: FormikProps<CompanyUser>) => (
      <Row>
        <Col xs={12}>
          <h6>Change User Type</h6>
        </Col>
        <RadioButtonGroup
          fieldName={"userType"}
          title={"User type"}
          options={[
            { name: "admin", value: "admin" },
            { name: "editor", value: "editor" },
            { name: "viewer", value: "viewer" },
            { name: "none", value: "none" },
          ]}
          currentValue={formikProps.values["userType"]}
          setFieldValue={formikProps.setFieldValue}
        />
      </Row>
    ),
    setModalFocus,
  });
};

export default EditUserTypeModel;
