import React from "react";
import RadioButtonGroup from "../Forms/RadioButtonGroup";
import { GatewaySettingsType } from "../../openapi/model/gatewaySettingsType";

export default function AssetGatewaySettingTypeSwitch(props: {
  fieldName: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: any;
  submit?: any;
  title?: string;
  paddingX?: number;
  includeDeviceNormal?: boolean;
}): JSX.Element {
  const { fieldName, setFieldValue, values, title = "Device Settings" } = props;

  return (
    <RadioButtonGroup
      fieldName={fieldName}
      title={title}
      paddingX={props.paddingX}
      options={[
        { name: "Default", value: GatewaySettingsType.ELVACODEFAULT },
        {
          name: "CMi Wireless Only",
          value: GatewaySettingsType.ELVACOCMIWIRELESSONLY,
        },
      ]}
      setFieldValue={setFieldValue}
      currentValue={values[fieldName]}
      onChange={(): void => {
        if (props.submit) {
          props.submit();
        }
      }}
      submitSpinner={false}
    />
  );
}
