import React from "react";
import { Routes } from "react-router-dom";

import WirelessClusterListPage from "./WirelessClusterListPage";
import { SentryRoute } from "../../App";
import WirelessClusterCreatePage from "./WirelessClusterCreatePage";

export default function WirelessClusterPage(): JSX.Element {
  return (
    <Routes>
      <SentryRoute path="" element={<WirelessClusterListPage />} />
      <SentryRoute path={"create"} element={<WirelessClusterCreatePage />} />
    </Routes>
  );
}
