import React from "react";
import { CompanyUser } from "../../containers/users/users";
import { Badge } from "reactstrap";
import DeletedLabel from "../Labels/DeletedLabel";

const UserTypeLabel = (props: { user: CompanyUser }) => {
  const { user } = props;

  const apiLabel = user.apiUser ? <Badge color={"warning"}>API</Badge> : <></>;
  switch (user.userType) {
    case "admin":
      return (
        <h3>
          <Badge color={"success"}>Admin</Badge>
          {apiLabel}
          <DeletedLabel object={user} />
        </h3>
      );
    case "editor":
      return (
        <h3>
          <Badge color={"warning"}>Editor</Badge>
          {apiLabel}
          <DeletedLabel object={user} />
        </h3>
      );
    case "none":
      return (
        <h3>
          <Badge color={"danger"}>None</Badge>
          {apiLabel}
          <DeletedLabel object={user} />
        </h3>
      );

    default:
      return (
        <h3>
          <Badge color={"primary"}>Viewer</Badge>
          {apiLabel}
          <DeletedLabel object={user} />
        </h3>
      );
  }
};

export default UserTypeLabel;
