import React, {useState} from 'react';
import {WirelessSurveyDataCollectorOut} from "../../openapi/model/wirelessSurveyDataCollectorOut";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import WirelessSurveySamplePointForm from "./WirelessSurveySamplePointForm";
import {UpdateEntity} from "../../hooks/updateEntity";
import BlockSpinner from "../../components/Spinners/BlockSpinner";

function WirelessSurveySamplePointAddModal(props: {
    dataCollector: WirelessSurveyDataCollectorOut,
    refresh?: () => any
    update: UpdateEntity<WirelessSurveyDataCollectorOut>
    disabled?: boolean;
}) {

    const {update} = props;

    const [open, setOpen] = useState<boolean>(false)
    return (
        <>
            <Modal
                isOpen={open}
            >
                <ModalHeader>Add Sample Point</ModalHeader>
                <ModalBody>
                    <BlockSpinner loading={update.updating}>
                        <WirelessSurveySamplePointForm dataCollector={props.dataCollector} refresh={
                            () => {
                                props.refresh && props.refresh();
                                setOpen(false);
                            }
                        }
                                                       onCancel={() => setOpen(false)} submit={(v) => {
                            return update.updateEntity({
                                ...props.dataCollector,
                                samplePoints: [...props.dataCollector.samplePoints,
                                    {
                                        ...v,
                                        startDateTime: v.startDateTime.toDate(),
                                        endDateTime: v.endDateTime && v.endDateTime.toDate()
                                    }]
                            }).finally(() => {
                                setOpen(false);
                                props.refresh && props.refresh();
                            });
                        }}
                        />
                    </BlockSpinner>
                </ModalBody>
            </Modal>
            <Button size={'sm'} color={'success'} disabled={props.disabled}onClick={() => setOpen(true)}>Add</Button>
        </>
    );
}

export default WirelessSurveySamplePointAddModal;