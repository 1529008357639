import React, { useState } from "react";
import { ReportExecutionOut } from "../../../openapi/model/reportExecutionOut";
import { loadFileFromApi } from "../../../actions/helpers/presigned_s3";
import classNames from "classnames";
import { Button, ButtonProps, UncontrolledTooltip } from "reactstrap";

async function getArtifactsFileFromUrl(
  execution: ReportExecutionOut
): Promise<void> {
  return loadFileFromApi(
    `/report/${execution.reportId}/artifacts?company_id=${execution.companyId}`
  );
}

interface Props extends ButtonProps {
  execution: ReportExecutionOut;
}

function ReportExecutionArtifactsDownloadButton(props: Props) {
  const { execution, className = "" } = props;

  const [loading, setLoading] = useState(false);
  const downloadable = execution.status === "COMPLETED";
  const failed = execution.status === "FAILED";

  const handleClick = async (): Promise<undefined> => {
    if (!loading) {
      setLoading(true);

      getArtifactsFileFromUrl(execution).finally(() => setLoading(false));
    }
    return;
  };

  return (
    <Button
      className={classNames({
        "p-1 ": true,
        disabled: !downloadable,
        "pointer-hover": downloadable,
        [className]: true,
      })}
      id={`tooltip-artifacts-download-${execution.reportId}`}
      color={"primary"}
      onClick={async (e): Promise<any> => {
        e.preventDefault();
        return await handleClick();
      }}
    >
      <i
        className={classNames({
          "fas fa-fw": true,
          "fa-download": !loading && downloadable,
          "fa-ban": !downloadable,
          "fa-cog fa-spinner fa-spin": loading,
        })}
      />
      <UncontrolledTooltip
        delay={0}
        target={`tooltip-artifacts-download-${execution.reportId}`}
      >
        {downloadable
          ? "Download report"
          : failed
          ? "This report failed"
          : "This report is calculating"}
      </UncontrolledTooltip>
    </Button>
  );
}

export default ReportExecutionArtifactsDownloadButton;
