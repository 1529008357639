import * as React from 'react';
import useCreateEntity from "../../hooks/createEntity";
import {Location} from "../../openapi/model/location";
import {AssetPosition} from "../../openapi/model/assetPosition";
import {Form, Formik, FormikHelpers} from "formik";
import {AssetType} from "../../openapi/model/assetType";
import {GuruSystemsIntegrationParams} from "../../openapi/model/guruSystemsIntegrationParams";
import * as Yup from "yup";
import {yupRequiredString} from "../../components/Forms/yupValidators";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import FormComponent from "../../components/Forms/FormComponent";
import {Button} from "reactstrap";
import {ErrorBanner} from "../../components/Error/ErrorBanner";
import GuruApiIntegrationForm from "./GuruAPIIntegrationForm";
import SelectCombo, {Option} from "../../components/Forms/SelectCombo/SelectCombo";
import SelectComboField from "../../components/Forms/SelectComboField";
import LabelWrapper from "../../components/Forms/LabelWrapper";
import SftpReceiverIntegrationForm from "./SFTPReceiverIntegrationForm";
import {GuruSystemsCSVIntegrationParams} from "../../openapi/model/guruSystemsCSVIntegrationParams";
import {FTPImporterParams} from "../../openapi/model/fTPImporterParams";
import {SFTPReceiverParams} from "../../openapi/model/sFTPReceiverParams";
import {SingleValue} from "react-select";

type Props = {
    companyId: string;
    schemeId: string;
    postCreateActions?: () => any;
};

interface FormValues {
    address: string,
    integration: GuruSystemsIntegrationParams | GuruSystemsCSVIntegrationParams | FTPImporterParams | SFTPReceiverParams
}

export function IntegrationCreateForm(props: Props) {
    const createLocation = useCreateEntity<Location>("LOCATION");
    const createAssetPosition = useCreateEntity<AssetPosition>("ASSET_POSITION");

    const submit = (
        values: FormValues,
        {setSubmitting, resetForm}: FormikHelpers<FormValues>
    ): void => {
        setSubmitting(true);
        createLocation
            .createEntity({
                schemeId: props.schemeId,
                companyId: props.companyId,
                address: values.address,
            })
            .then(([location]) => {
                createAssetPosition.createEntity({
                    schemeId: props.schemeId,
                    companyId: props.companyId,
                    locationId: location.locationId,
                    assetPositionType: AssetType.ApiIntegration,
                    configuration: {
                        integration: values.integration
                    }
                });
            })
            .then(() => {
                resetForm();
                if (props.postCreateActions) {
                    props.postCreateActions();
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const initialValues: FormValues = {
        address: "",
        integration: {
            integrationType: "guru_systems",
            apiKey: "",
            siteId: 0
        }
    };
    const creating = createLocation.creating || createAssetPosition.creating;

    function configurationDetails(integrationType: string): React.ReactElement<any> {
        switch (integrationType) {
            case "guru_systems":
                return <GuruApiIntegrationForm namespace={'integration'}/>
            case "sftp_receiver":
                return <SftpReceiverIntegrationForm namespace={'integration'}/>
            case "guru_systems_csv":
                return <SftpReceiverIntegrationForm namespace={'integration'}/>
            default:
                return <></>
        }
    }

    function defaultConfigurationValues(integrationType: string): GuruSystemsIntegrationParams | GuruSystemsCSVIntegrationParams | FTPImporterParams | SFTPReceiverParams {
        switch (integrationType) {
            case "guru_systems":
                return {integrationType: "guru_systems", apiKey: '', siteId: 0} as GuruSystemsIntegrationParams
            case "sftp_receiver":
                return {integrationType: "sftp_receiver", sftpCredentials: {publicKey: ''}} as SFTPReceiverParams
            case "guru_systems_csv":
                return {
                    integrationType: "guru_systems_csv",
                    sftpCredentials: {publicKey: ''}
                } as GuruSystemsCSVIntegrationParams
            default:
                return {}
        }
    }

    return (
        <Formik
            onSubmit={submit}
            initialValues={initialValues}
            enableReinitialize={true}
            validateOnMount={true}
            validationSchema={Yup.object().shape({
                address: yupRequiredString,
            })}
        >
            {({values, setFieldValue, isValid, isSubmitting}): JSX.Element => (
                <BlockSpinner loading={creating}>
                    <Form data-cy={"integration-create"}>
                        <div className={"row"}>
                            <div className={"col-3"}>
                                <h3 className="text-white mb-0">Add Data Integration</h3>
                            </div>
                            <FormComponent
                                fieldName={"address"}
                                label={"Description"}
                            />
                            <LabelWrapper
                                label={'Integration Type'}
                            >
                                <SelectComboField name={'integration.integrationType'}>
                                    {({...fieldProps}) =>

                                        <SelectCombo
                                            {...fieldProps}
                                            isMulti={false}
                                            options={
                                                [
                                                    {value: 'guru_systems', label: "Guru Systems (API)"},
                                                    {value: 'guru_systems_csv', label: "Guru Systems (CSV)"},
                                                    {value: "sftp_receiver", label: 'SFTP Receiver'},
                                                    {value: "ftp_importer", label: 'FTP Importer'},
                                                ]
                                            }
                                            onChange={(v) => {
                                                setFieldValue('integration', defaultConfigurationValues((v as SingleValue<Option>)?.value as string));
                                            }}
                                        />

                                    }
                                </SelectComboField>
                            </LabelWrapper>
                            {
                                configurationDetails(values.integration.integrationType as string)
                            }
                            <div className={"col-12"}>
                                <div className={"form-group row"}>
                                    <div className={"col-12"}>
                                        <Button
                                            type={"submit"}
                                            color={"primary"}
                                            disabled={isSubmitting || !isValid}
                                        >
                                            Add Data Integration
                                        </Button>
                                    </div>
                                </div>
                                <ErrorBanner
                                    error={createLocation.error || createAssetPosition.error}
                                />
                            </div>
                        </div>
                    </Form>
                </BlockSpinner>
            )}
        </Formik>
    );
}