import React from 'react';
import {WirelessReceiverLink} from "../../../../openapi/model/wirelessReceiverLink";
import AssetRegisterListItem, {AssetRegisterListHeading} from "../../../assetRegister/AssetRegisterListItem";
import List from "../../../../components/List/List";
import {formatDate} from "../../../../components/utils/HighlightNullAndOldDates";

interface Props {
    history: Array<WirelessReceiverLink>
}


function ReceiverHistoryListItem(
        item: WirelessReceiverLink,
    index: number,
    _refresh?: () => void
): JSX.Element {
    const {location, assetPosition, asset, createdOn, deletedOn } = item

    return AssetRegisterListItem(
        {
            assetRegisterItem:{
                location, assetPosition, asset, installDate: createdOn, uninstallDate: deletedOn
            }, index,
            showType: false,
            showScheme: false,
            denseColumn: () => <>
                {formatDate(createdOn)} - {deletedOn ? formatDate(deletedOn) : 'present'}
            </>
        }
    )
}
function ReceiverHistoryTable(props: Props) {

    return (
        <List
            row={ReceiverHistoryListItem}
            header={<AssetRegisterListHeading showScheme={false} showType={false} denseColumn={<>Assigned Dates</>}/>}
            data={props.history}
        />
    );
}

export default ReceiverHistoryTable;