import React from "react";
import { Scheme } from "../../openapi/model/scheme";
import { AssetPositionNumbers } from "../../openapi/model/assetPositionNumbers";
import { Mutable } from "type-fest";
import { Table } from "reactstrap";
import * as _ from "lodash";

export function computeTotalNumberOfAssetPositionsForListOfSchemes(
  schemes: Scheme[]
): AssetPositionNumbers {
  const newObj: Mutable<AssetPositionNumbers> = {};
  for (const scheme of schemes) {
    if (scheme.numberOfAssetPositions) {
      for (const [key, value] of Object.entries(
        scheme.numberOfAssetPositions
      )) {
        newObj[key as keyof AssetPositionNumbers] =
          (newObj[key as keyof AssetPositionNumbers]
            ? newObj[key as keyof AssetPositionNumbers]
            : 0) + (value ? value : 0);
      }
    }
  }
  return newObj;
}

export function AssetPositionNumbersToTable(props: {
  numbers?: AssetPositionNumbers;
}): JSX.Element {
  const { numbers } = props;
  return (
    <Table className={"table-sm"}>
      <tbody>
        {Object.entries(numbers || []).map((v, i) => (
          <tr key={i}>
            <td>{_.startCase(v[0])}</td>
            <td>{v[1] as number}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default function AssetPositionNumbersToStringList(props: {
  numbers?: AssetPositionNumbers;
  includeGateways?: boolean;
}): JSX.Element {
  const { numbers, includeGateways = false } = props;
  const filteredNumbers: AssetPositionNumbers = Object.fromEntries(
    Object.entries(numbers || [])
      .filter(([assetType, number]) => {
        const nonZero = number !== 0;
        return includeGateways ? nonZero : assetType !== "gateway" && nonZero;
      })
      .map(([assetType, number]) => {
        return [assetType, number];
      })
  );
  return (
    <>
      {Object.entries(filteredNumbers).map(
        ([assetType, number], index, all) => (
          <>
            {_.startCase(assetType)}s: {number as number}
            {index === all.length - 1 ? "" : ", "}
          </>
        )
      )}
    </>
  );
}
