import React from "react";
import { Button } from "reactstrap";
import { ButtonProps } from "reactstrap/es/Button";

interface EditButtonProps extends ButtonProps {
  text?: string;
  icon?: string;
}

const EditButton = (props: EditButtonProps): JSX.Element => {
  const { size = "sm", text, icon = "fa-pen", ...rest } = props;
  return (
    <Button size={size} {...rest}>
      {icon && <i className={`fas fa-fw ${icon}`} />}
      {text}
    </Button>
  );
};

export default EditButton;
