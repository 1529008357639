import React from 'react';
import {Progress} from 'reactstrap';
import {BulkUpdateExecutionStats} from "../../openapi/model/bulkUpdateExecutionStats";
import "./BulkUpdateProgressBar.css"

interface Props {
    executionStats?: BulkUpdateExecutionStats
}

const BulkUpdateProgressBar = (props: Props): JSX.Element => {
    const {executionStats} = {...props};

    if (executionStats) {
        const completed = (executionStats.created || 0) + (executionStats.updated || 0);
        const totalToDo = (executionStats.objects || 0) - (executionStats.noOp || 0);
        const failed = (executionStats.failed || 0);

        return <div>
            <Progress multi>
                <Progress bar color={'success'} value={completed / totalToDo * 100}>{completed}</Progress>
                <Progress bar color={'danger'} value={failed / totalToDo * 100}>{failed}</Progress>
            </Progress>
        </div>

    } else {
        return <></>
    }

}

export default BulkUpdateProgressBar