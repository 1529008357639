/**
 * Meterpoint.io
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type AssetSort = 'asset_id' | 'serial_number' | 'last_contact';

export const AssetSort = {
    AssetId: 'asset_id' as AssetSort,
    SerialNumber: 'serial_number' as AssetSort,
    LastContact: 'last_contact' as AssetSort
};