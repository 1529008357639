import React from 'react';
import {Col, Row} from "reactstrap";
import ButtonGroup from "../../components/Forms/ButtonGroup";
import ButtonGroupField from 'components/Forms/ButtonGroupField';

interface DataProcessingConfigurationFormProps {
    namespace: string
}

function DataProcessingConfigurationForm(props: DataProcessingConfigurationFormProps): JSX.Element {
    const {namespace} = props;
    const withNamespace = (fieldName: string): string => {
        return namespace ? `${namespace}.${fieldName}` : fieldName
    }

    return (
        <Row noGutters>
            <Col xs={12}>
                <h4>Data Processing</h4>
            </Col>
            <Col xs={12}>
                <ButtonGroupField
                    name={withNamespace("autoCreateNewAssets")}
                >
                    {(fieldProps) => <ButtonGroup
                        label={{label: "Auto Create New Assets"}}
                        options={[
                            {label: 'Yes', value: 'true'},
                            {label: 'No', value: 'false'},
                        ]}
                        {...fieldProps}
                        fullWidth={false}
                    />
                    }
                </ButtonGroupField>
            </Col>
        </Row>
    );
}

export default DataProcessingConfigurationForm;