import React, {useState} from "react";
import {Button, Col, Row} from "reactstrap";
import {DeviceAsset} from "../../../../openapi/model/deviceAsset";
import {Formik} from "formik";
import * as Yup from "yup";
import BlockSpinner from "../../../../components/Spinners/BlockSpinner";
import useUpdateEntity from "../../../../hooks/updateEntity";
import {ErrorBanner} from "components/Error/ErrorBanner";
import SuccessBanner from "../../../../components/Banner/SuccessBanner";
import {WirelessReceiverConfiguration} from "../../../../openapi/model/wirelessReceiverConfiguration";
import WirelessReceiverConfigurationForm, {
    WirelessReceiverConfigurationFormSchema,
} from "./WirelessReceiverConfigurationForm";
import CreateActionButton from "containers/assetActions/CreateActionButton";
import DisplayIfUserType from "components/utils/DisplayIfUserType/DisplayIfUserType";

interface Configuration {
    receiver: WirelessReceiverConfiguration;
}

export default function DeviceConfigurationTab(props: {
    asset: DeviceAsset;
}): JSX.Element {
    const configuration: WirelessReceiverConfiguration | undefined = props.asset
        .configuration as WirelessReceiverConfiguration | undefined;

    const [updated, setUpdated] = useState(false);
    const {updateEntity, updating, error} = useUpdateEntity(
        "ASSET",
        props.asset.assetId,
        () => {
            setUpdated(true);
        }
    );

    const initialValues: Configuration = {
        receiver: {
            mbusBaudRate: configuration?.mbusBaudRate || 2400,
            ageLimit: configuration?.ageLimit || 1440,
        },
    };

    function submit(values: Configuration) {
        setUpdated(false);
        updateEntity({
            ...props.asset,
            configuration: values.receiver,
        });
    }

    return (
        <Row>
            <Col xs={12}>
                <Formik
                    onSubmit={submit}
                    initialValues={initialValues}
                    validationSchema={Yup.object().shape({
                        receiver: WirelessReceiverConfigurationFormSchema,
                    })}
                    validateOnMount={true}
                    enableReinitialize={true}
                >
                    {({submitForm, isValid}): JSX.Element => {
                        return (
                            <BlockSpinner loading={updating}>
                                <Row>
                                    <Col xs={12}>
                                        <WirelessReceiverConfigurationForm namespace={"receiver"}/>
                                    </Col>
                                </Row>
                                <DisplayIfUserType userTypes={"admin"}>
                                    <Row>
                                        <Col xs={12} className={"text-right"}>
                                            <Button
                                                type={"submit"}
                                                color={"primary"}
                                                onClick={submitForm}
                                                disabled={updating || !isValid}
                                            >
                                                Save
                                            </Button>
                                        </Col>
                                        <Col xs={12}>
                                            {updated && (
                                                <SuccessBanner
                                                    text={"Configuration was updated successfully."}
                                                />
                                            )}
                                            <ErrorBanner error={error}/>
                                        </Col>
                                    </Row>
                                </DisplayIfUserType>
                            </BlockSpinner>
                        );
                    }}
                </Formik>
            </Col>
            <DisplayIfUserType userTypes={"admin"}>
                <Col xs={12} className={"text-right py-2"}>
                    <CreateActionButton
                        asset={props.asset}
                        actionType={"SYNC_DEVICE_LIST"}
                        actionName={"Set AES keys"}
                    />
                    <CreateActionButton
                        asset={props.asset}
                        actionType={"SYNC_CONFIG"}
                        actionName={"Update receiver settings"}
                    />
                </Col>
            </DisplayIfUserType>
        </Row>
    );
}
