import React from "react";
import {Option,} from "../../../components/Forms/SelectComponent";
import {SpreadsheetFileType} from "../../../openapi/model/spreadsheetFileType";
import {SelectCombo, SelectComboProps} from "../../../components/Forms/SelectCombo/SelectCombo";

export default function OutputFileTypeSelect(
  props:Omit<SelectComboProps, 'options'>
): JSX.Element {

  const options: Option[] = [
    { label: "CSV", value: SpreadsheetFileType.CSV },
    { label: "XLSX", value: SpreadsheetFileType.XLSX },
  ];

  return (
    <SelectCombo
      options={options}
      {...props}
    />
  );
}
