import React from "react";
import {
  AssetRegisterAggregatedTimeSeriesResponse
} from "../../../openapi/model/assetRegisterAggregatedTimeSeriesResponse";
import {AssetType} from "openapi/model/assetType";
import {
  AssetPositionRow,
  AssetRow,
  DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER,
  LocationRow,
} from "../../../components/Table/AssetRegisterData";
import moment from "moment";
import numberFormatter from "../../../components/Table/Formatters";
import {BillingReportRequestParams} from "./BillingReportSelectorForm";
import {Col, Row} from "reactstrap";
import CSVExportButton from "../../../components/utils/CSVExport/CSVExportButton";
import {Primitive} from "react-data-table-component/dist/src/DataTable/types";
import PrivacyModeDataTable, {PrivacyModeTableColumn,} from "../../../components/Text/PrivacyModeDataTableCell";
import {startCase} from "lodash";
import {assetPositionSortValue, locationSortValue} from "../allPeriodReportTable/AssetRegisterReportTable";

export interface BillingData {
  companyId: string;
  companyName: string;
  schemeName: string;
  schemeId: string;
  assetId?: string;
  serialNumber?: string;
  assetType: AssetType;
  assetPositionId: string;
  assetPositionReference?: string;
  locationId: string;
  locationAddress?: string;
  billableUnit: string;
  billableType: string;
  installDate?: Date;
  uninstallDate?: Date;
  [key: string]: number | undefined | string| Date;
}

const dateToString = (
  date: Date,
  dateFormat?: string,
  timezone?: string
): string => {
  const m = moment(date).tz(timezone ? timezone : "UTC");
  return dateFormat ? m.format(dateFormat) : m.toISOString(false);
};

const mapBillingData = (
  data: AssetRegisterAggregatedTimeSeriesResponse,
  query: Required<BillingReportRequestParams>,
  dateFormat?: string,
  timezone?: string
): Array<BillingData> => {
  return data.data.map((assetData) => {
    const { aggregations, ...asset } = assetData;

    switch (asset.assetType) {
      case "heat_meter":
      case "heating_and_cooling_meter":
        return {
          ...asset,
          billableType: "Energy (Heating)",
          billableUnit: query.configuration.energy?.units || "kWh",
          ...Object.fromEntries(
            data.times.map((e, i) => {
              return [
                dateToString(e, dateFormat, timezone),
                aggregations.energyHeating?.last?.[i],
              ];
            })
          ),
        };
      case "cooling_meter":
        return {
          ...asset,
          billableType: "Energy (Cooling)",
          billableUnit: query.configuration.energy?.units || "kWh",
          ...Object.fromEntries(
            data.times.map((e, i) => {
              return [
                dateToString(e, dateFormat, timezone),
                aggregations.energyCooling?.last?.[i],
              ];
            })
          ),
        };
      case "electricity_meter":
        return {
          ...asset,
          billableType: "Energy (Electrical Active Import)",
          billableUnit: query.configuration.energy?.units || "kWh",
          ...Object.fromEntries(
            data.times.map((e, i) => {
              return [
                dateToString(e, dateFormat, timezone),
                aggregations.energyElectricalActiveImport?.last?.[i],
              ];
            })
          ),
        };
      default:
        return {
          ...asset,
          billableType: "volume",
          billableUnit: query.configuration.volume?.units || "m3",
          ...Object.fromEntries(
            data.times.map((e, i) => {
              return [
                dateToString(e, dateFormat, timezone),
                aggregations.volume?.last?.[i],
              ];
            })
          ),
        };
    }
  });
};

const createCSV = (
  data: AssetRegisterAggregatedTimeSeriesResponse,
  query: Required<BillingReportRequestParams>
): (() => Promise<any>) => {
  return () => {
    return new Promise<any>((resolve) =>
      resolve(mapBillingData(data, query, "DD/MM/YYYY", "Europe/London"))
    );
  };
};

const BillingReportView = (props: {
  schemeId: string;
  data: AssetRegisterAggregatedTimeSeriesResponse;
  query: Required<BillingReportRequestParams>;
}): JSX.Element => {
  const billingData = mapBillingData(props.data, props.query);

  const periodLabel = props.query.configuration.periodLabel;
  const period = props.query.period;

  const mappedPeriod = {
    DAILY: "day",
    WEEKLY: "week",
    MONTHLY: "month",
    SEVEN_DAY: "seven day period",
    ALL: "all",
  }[period];


  const columns: PrivacyModeTableColumn<BillingData>[] = [
      {
      name: "Location",
      selector: locationSortValue,
      cell: LocationRow(props.query.startTime, props.query.endTime),
      sortable: true,
        width: "250px",
      compact: true,
      privacyMode: true
    },
    {
      name: "Asset Position",
      selector: assetPositionSortValue,
      cell: AssetPositionRow(props.query.startTime, props.query.endTime),
      sortable: true,
      width: "200px",
      compact: true,
      privacyMode: true
    } ,   {
      name: "Asset Type",
      selector: (row:BillingData) => row.assetType,
      cell: (row:BillingData) => startCase(row.assetType),
      sortable: true,
      compact: true,
      width: '120px',
    },{
      name: "Asset",
      selector: (row:BillingData) => row.serialNumber || "-",
      sortable: true,
      cell: AssetRow(props.query.startTime, props.query.endTime, false, true),
      width: "150px",
      compact: true,
      privacyMode: true
    },
    {
      name: "Type",
      selector: (row:BillingData) => row.billableType,
      width: "150px",
      compact: true,
    },
    {
      name: "Units",
      selector: (row:BillingData) => row.billableUnit,
      width: "75px",
      compact: true,
    },
    ...props.data.times.map((value) => {
      return {
        name: moment(value).format("DD/MM/YYYY"),
        selector: (row: BillingData) =>
          row[moment(value).toISOString(false)] as Primitive,
        right: true,
        sortable: true,
        width: "100px",
        compact: true,
        format: numberFormatter(moment(value).toISOString()),
      };
    }),
  ];

  return (
    <Row>
      <Col className={"text-right"} xs={12}>
        <CSVExportButton
          download={createCSV(props.data, props.query)}
          data={billingData}
          columnOrder={[
            ...DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER,
            "billableType",
            "billableUnit",
            ...props.data.times.map((value) =>
              moment(value).format("DD/MM/YYYY")
            ),
          ]}
          filename={`${props.schemeId}_billing_report_${dateToString(
            props.data.times[0],
            "YYYY-MM-DD",
            "Europe/London"
          )}_${dateToString(
            props.data.times[props.data.times.length - 1],
            "YYYY-MM-DD",
            "Europe/London"
          )}`}
        />
      </Col>
      <Col xs={{ size: 12 }} className={"text-right small"}>
        {period !== "DAILY" && (
          <>
            {periodLabel === "left"
              ? `Last reading for ${mappedPeriod} beginning on`
              : `Last reading for ${mappedPeriod} ending on`}
          </>
        )}
      </Col>
      <Col xs={12}>
        <PrivacyModeDataTable
          columns={columns}
          data={billingData}
          pagination
          fixedHeader
          noHeader={true}
        />
      </Col>
    </Row>
  );
};

export default BillingReportView;
