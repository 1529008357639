/**
 * Meterpoint.io
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type AssetType = 'heat_meter' | 'cooling_meter' | 'heating_and_cooling_meter' | 'electricity_meter' | 'gas_meter' | 'water_meter' | 'room_sensor' | 'co2_room_sensor' | 'input_output_module' | 'gateway' | 'wireless_receiver' | 'wireless_cluster' | 'api_integration' | 'sim_card' | 'unknown' | 'wireless_survey';

export const AssetType = {
    HeatMeter: 'heat_meter' as AssetType,
    CoolingMeter: 'cooling_meter' as AssetType,
    HeatingAndCoolingMeter: 'heating_and_cooling_meter' as AssetType,
    ElectricityMeter: 'electricity_meter' as AssetType,
    GasMeter: 'gas_meter' as AssetType,
    WaterMeter: 'water_meter' as AssetType,
    RoomSensor: 'room_sensor' as AssetType,
    Co2RoomSensor: 'co2_room_sensor' as AssetType,
    InputOutputModule: 'input_output_module' as AssetType,
    Gateway: 'gateway' as AssetType,
    WirelessReceiver: 'wireless_receiver' as AssetType,
    WirelessCluster: 'wireless_cluster' as AssetType,
    ApiIntegration: 'api_integration' as AssetType,
    SimCard: 'sim_card' as AssetType,
    Unknown: 'unknown' as AssetType,
    WirelessSurvey: 'wireless_survey' as AssetType
};