import {AssetPosition} from "../../../../openapi/model/assetPosition";
import useGetData from "../../../../hooks/getData";
import {WirelessReceiverLink} from "../../../../openapi/model/wirelessReceiverLink";
import BlockSpinner from "../../../../components/Spinners/BlockSpinner";
import React from "react";
import {ErrorBanner} from "../../../../components/Error/ErrorBanner";


interface Props {
    assetPosition: AssetPosition;
    children: (props: {history: Array<WirelessReceiverLink>}) => React.ReactNode;
}


function ReceiverHistoryGetter(props: Props) {
  const {assetPosition} = props;
const { assetPositionId, companyId, schemeId } = assetPosition;

  const [{ data, loading, error }] = useGetData<Array<WirelessReceiverLink>>({
    path: `asset_position/${assetPositionId}/wireless_receivers/history`,
    companyId,
    schemeId,
    params:{},
    post: false,
    singletonApi: true,
  });

    return    <BlockSpinner loading={loading}>
      {data && props.children({history:data})}
      {error && <ErrorBanner error={error}/>}
      </BlockSpinner>
}

export default ReceiverHistoryGetter;