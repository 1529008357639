import React from 'react';
import {BulkUpdateExecutionStats} from "../../openapi/model/bulkUpdateExecutionStats";
import BulkUpdateProgressBar from "./BulkUpdateProgressBar";
import {Alert} from "reactstrap";
import "./UpdateProgressBanner.scss"
import {BulkUpdatePreviewStats} from "../../openapi/model/bulkUpdatePreviewStats";
import {BulkUpdateStatus} from "../../openapi/model/bulkUpdateStatus";

interface Props {
    status: BulkUpdateStatus;
    previewStats: BulkUpdatePreviewStats;
    executeStats: BulkUpdateExecutionStats;
}


const UpdateProgressBanner = (props: Props): JSX.Element => {
    const {status, executeStats, previewStats} = {...props};

    const isPreviewGenerating = (status === BulkUpdateStatus.PREVIEWGENERATING);
    const hasCompleted = (status === BulkUpdateStatus.EXECUTIONCOMPLETED);

    const previewErrors = (previewStats.newInvalid || 0) + (previewStats.updateInvalid || 0);
    const hasExecuteErrors = (executeStats.failed !== 0);

    const heading = hasCompleted ? 'Update Complete' :
        (isPreviewGenerating ? 'Preview Generating' : 'Update In Progress');
    const errorText = hasExecuteErrors ? `${executeStats.failed} errors occurred during execution` : undefined;
    const previewErrorText = (previewErrors !== 0) ? `${previewErrors} lines were ignored because they were not valid when the update was created` : undefined;

    return <>
        {
            (status !== BulkUpdateStatus.PREVIEWCREATED) &&
            <Alert color={hasExecuteErrors ? 'danger' : hasCompleted ? 'success' : 'grey'}>
                <h3>{heading}</h3>
                {
                    errorText &&
                    <p>{errorText}</p>
                }
                {
                    !hasCompleted &&
                    <p>
                        <i className="fa fa-spinner fa-spin" aria-hidden="true"/>
                        <span className="px-2">
                        {isPreviewGenerating ? 'Preview is generating.' : 'Update is in progress.'}
                            Please wait.
                    </span>
                    </p>
                }
                {
                    hasCompleted && !hasExecuteErrors &&
                    <p>The update has completed with no errors.</p>
                }
                {
                    previewErrorText &&
                    <p>{previewErrorText}</p>
                }
                {
                    !isPreviewGenerating &&
                    <BulkUpdateProgressBar executionStats={executeStats}/>
                }
            </Alert>
        }
    </>


}


export default UpdateProgressBanner;
