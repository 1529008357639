import React, { useContext } from "react";
import { Card, CardHeader } from "reactstrap";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import { SchemeContext } from "../../../reducers/scheme";
import {
  AssetPositionNumbersToTable,
  computeTotalNumberOfAssetPositionsForListOfSchemes,
} from "../../../components/AssetPosition/AssetPositionNumberSummaryList";
import { AssetPositionNumbers } from "openapi/model/assetPositionNumbers";

export default function AssetPositionCountWidget(): JSX.Element {
  const { state } = useContext(SchemeContext);

  const numberOfAssets: AssetPositionNumbers | undefined = state.selected
    ? state.selected.numberOfAssetPositions
    : computeTotalNumberOfAssetPositionsForListOfSchemes(
        state.schemes ? state.schemes : []
      );

  return (
    <Card style={{ overflow: "auto" }}>
      <BlockSpinner loading={state.loading || false}>
        <CardHeader className="border-0">
          <h3 className="mb-0">Asset Positions</h3>
        </CardHeader>
        <div style={{ height: "448px" }}>
          {numberOfAssets ? (
            <AssetPositionNumbersToTable numbers={numberOfAssets} />
          ) : (
            <h3>No assets yet.</h3>
          )}
        </div>
      </BlockSpinner>
    </Card>
  );
}
