import React, { useCallback } from "react";
import { Form, Formik } from "formik";
import { Button, Col, Row } from "reactstrap";
import useGetParsedSearchString from "../../hooks/getParsedSearchString";
import DateRangePicker from "../../components/Forms/DateRangePicker/DateRangePicker";
import moment, { Moment } from "moment";
import { ParsedQs } from "qs";
import _ from "lodash";
import EventTypeSelect from "./EventTypeSelect";
import { EventTypes } from "openapi/model/eventTypes";
import { FilterFormProps } from "../../components/Pagination/GetListPaginatedTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
export interface EventListFilterParams {
  eventTypes?: EventTypes[];
  startDatetime?: Moment;
  endDatetime?: Moment;
}

export const useGetParsedEventListFilterParams = () =>
  useGetParsedSearchString<EventListFilterParams>(
    useCallback((p: ParsedQs): EventListFilterParams => {
      return {
        startDatetime: _.isString(p.startDatetime)
          ? moment(p.startDatetime)
          : undefined,
        endDatetime: _.isString(p.endDatetime)
          ? moment(p.endDatetime)
          : undefined,
        eventTypes: _.isString(p.eventTypes)
          ? [p.eventTypes as EventTypes]
          : (p.eventTypes as EventTypes[]),
      };
    }, [])
  );

const EventListFilterForm = (props: FilterFormProps): JSX.Element => {
  const { ready, updateFilters, params } = useGetParsedEventListFilterParams();

  return (
    <>
      {ready && (
        <Row className={"justify-content-end"}>
          <Col xs={12} className={"mb-2"}>
            <Formik
              initialValues={params}
              onSubmit={(x): void => {
                updateFilters(x);
              }}
            >
              {(): JSX.Element => (
                <Form>
                  <Row>
                    <Col
                      xs={{ size: 12 }}
                      sm={{ size: 12 }}
                      lg={{ size: 5 }}
                      xl={{ size: 6 }}
                      className={"px-3 pr-sm-1 px-lg-1"}
                    >
                      <EventTypeSelect
                        fieldName={"eventTypes"}
                        submitSpinner={false}
                        paddingX={0}
                      />
                    </Col>
                    <Col
                      xs={{ size: 12 }}
                      sm={{ size: 8 }}
                      lg={{ size: 5 }}
                      xl={{ size: 4 }}
                      className={"px-3 pr-sm-1 px-lg-1"}
                    >
                      <DateRangePicker
                        startDateName={"startDatetime"}
                        endDateName={"endDatetime"}
                        maxDate={moment().endOf("day")}
                        clearable={true}
                        minDays={0}
                      />
                    </Col>
                    <Col
                      xs={12}
                      sm={4}
                      lg={2}
                      xl={2}
                      className={"py-1 px-3 px-sm-1 text-right"}
                    >
                      <Button
                        type={"submit"}
                        color={"primary"}
                        data-cy={"export"}
                        style={{ width: "75%" }}
                      >
                        Filter
                      </Button>
                      <Button
                        type={"button"}
                        color={"primary"}
                        onClick={props.refresh}
                        style={{ width: "20%" }}
                        aria-label={"Refresh"}
                      >
                        <FontAwesomeIcon icon={faRefresh} />
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      )}
    </>
  );
};

export default EventListFilterForm;
