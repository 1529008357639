import React, { useContext, useEffect } from "react";
import { CompanyContext } from "../../reducers/company";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import { Company } from "openapi/model/company";
import useGetList from "../../hooks/getList";
import CompanySelectInit from "./CompanySelectInit";

const CompanyListInit = (props: { children: React.ReactNode }): JSX.Element => {
  const { state, dispatch } = useContext(CompanyContext);
  const { stale } = state;

  const [{ data, loading, error }, refresh] = useGetList<Company>(
    "COMPANY",
    {},
    { maxItems: 250 }
  );

  useEffect(() => {
    if (data !== undefined) {
      dispatch({ type: "LOADING_COMPANIES_SUCCESS", payload: data.data });
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (loading && !stale) {
      dispatch({ type: "LOADING_COMPANIES_STARTED" });
    }
  }, [loading, stale, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch({ type: "LOADING_COMPANIES_ERROR", payload: error });
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (stale) {
      refresh();
    }
  }, [stale, refresh]);

  return (
    <>
      <BlockSpinner loading={state.loading}>
        {state.companies && (
          <CompanySelectInit>{props.children}</CompanySelectInit>
        )}
      </BlockSpinner>
    </>
  );
};

export default CompanyListInit;
