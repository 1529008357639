import React from "react";
import {Routes} from "react-router-dom";

import {SentryRoute} from "../../App";
import {IntegrationListPage} from "./IntegrationListPage";
import {IntegrationCreatePage} from "./IntegrationCreatePage";

export default function IntegrationPage(): JSX.Element {
  return (
    <Routes>
      <SentryRoute path="" element={<IntegrationListPage />} />
      <SentryRoute path={"create"} element={<IntegrationCreatePage />} />

    </Routes>
  );
}
