import React from 'react';
import {AssetPositionAssetLinkOut} from "../../../openapi/model/assetPositionAssetLinkOut";
import AssetIdLink from "../../../components/Asset/AssetIdLink";
import PrivacyMode from "../../../components/Text/PrivacyMode";
import AssetTypeHumanLabel from "../../../components/Asset/AssetTypeHumanLabel";
import AssetPositionIdLink from "../../../components/AssetPosition/AssetPositionIdLink";
import {formatDate} from "../../../components/utils/HighlightNullAndOldDates";
import {Badge} from "reactstrap";
import DisplayIfUserType from "../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import UnlinkAssetPositionFromAssetButton from "../UnlinkAssetPositionFromAssetButton";
import {UpdateEntity} from "../../../hooks/updateEntity";
import AssetPositionAssetUpdateModal from "./AssetPositionAssetUpdateModal";
import {isUndefined} from "lodash";
import moment from "moment";


export interface AssetPositionAssetRowInnerProps {
        link: AssetPositionAssetLinkOut,
        updateEntity: UpdateEntity<AssetPositionAssetLinkOut>,
        refreshList?: () => void,
    }
function AssetPositionAssetRow(props: AssetPositionAssetRowInnerProps & {view: 'asset'| 'assetPosition'}) {
    const {link, refreshList, view} = props;

    const installedAlwaysText = !link.installDate && !link.uninstallDate ? 'Forever' : 'Not set'
    const canEdit = !link.cancelled || isUndefined(link.cancelled);
    return <tr key={link.linkId} data-cy={"list-item"}>
      {view === "asset" ? (
        <>
          <td>
            <AssetIdLink assetId={link.assetId} full={true} />
          </td>
          <td>{link.manufacturer}</td>
          <td>
            <PrivacyMode>{link.serialNumber}</PrivacyMode>
          </td>
          <td>
            <AssetTypeHumanLabel assetType={link.assetType} />
          </td>
        </>
      ) : (
        <>
          <td>
            <AssetPositionIdLink
              assetPositionId={link.assetPositionId}
              full={true}
            />
          </td>
          <td>
            <PrivacyMode>{link.assetPositionCustomerReference}</PrivacyMode>
          </td>
          <td>
            <AssetTypeHumanLabel assetType={link.assetPositionType} />
          </td>
        </>
      )}
      <td className={"align-top"}>
        {link.installDate ? formatDate(link.installDate) : installedAlwaysText}
          {canEdit &&<DisplayIfUserType userTypes={'superadmin'}><AssetPositionAssetUpdateModal
              link={link}
              update={props.updateEntity}
              className={'mx-2'}
          /></DisplayIfUserType>}
      </td>
      <td className={"align-top"}>
        {link.uninstallDate ? formatDate(moment(link.uninstallDate).subtract(1,'minutes')) : installedAlwaysText}
          {canEdit &&<DisplayIfUserType userTypes={'superadmin'}><AssetPositionAssetUpdateModal link={link}
                                           update={props.updateEntity}
                        className={'mx-2'}
          /></DisplayIfUserType>}
      </td>
      <td>
        {link.cancelled ? (
          <Badge color={"secondary"}>cancelled</Badge>
        ) : (
          <Badge color={"success"} className={"mx-2"}>
            active
          </Badge>
        )}
      </td>
      <DisplayIfUserType userTypes={"admin"}>
        <td>
          {!link.cancelled && (
            <UnlinkAssetPositionFromAssetButton
              assetPositionAssetLink={link}
              refresh={refreshList}
            />
          )}
        </td>
      </DisplayIfUserType>
    </tr>
;
}

AssetPositionAssetRow.whyDidYouRender=true;
export default AssetPositionAssetRow;