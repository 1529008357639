import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { OneCardPage } from "../../components/utils/OneCardPage";
import FormComponent from "../../components/Forms/FormComponent";
import { Button } from "reactstrap";
import {
  yupMatchPassword,
  yupPassword,
  yupRequiredString,
} from "../../components/Forms/yupValidators";
import { User } from "openapi/model/user";
import useCreateEntity from "../../hooks/createEntity";
import { CompanyRole } from "openapi/model/companyRole";
import { useNavigate } from "react-router-dom";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import RadioButtonGroup from "../../components/Forms/RadioButtonGroup";

interface FormValues {
  emailAddress: string;
  userType: CompanyRole;
  password: string;
  confirmPassword: string;
}

const CreateUserForm = (props: { companyId: string }): JSX.Element => {
  const { createEntity, error } = useCreateEntity<User>("USER");
  const navigate = useNavigate();

  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ): void => {
    setSubmitting(true);
    createEntity({
      ...values,
      companyId: props.companyId,
      fullname: values.emailAddress,
      confirmPassword: undefined,
    } as unknown as User)
      .then(() => {
        resetForm();
        navigate({ pathname: "/admin/users" });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialValues: FormValues = {
    emailAddress: "",
    userType: CompanyRole.Viewer,
    password: "",
    confirmPassword: "",
  };
  return (
    <OneCardPage
      headerTitle={"Create New User"}
      breadcrumbs={[
        { url: "/admin/users", name: "Users" },
        { url: "/admin/users/create", name: "Create" },
      ]}
      noSchemeInBreadcrumbs={true}
    >
      <Formik
        onSubmit={submit}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          emailAddress: yupRequiredString.email(),
          userType: yupRequiredString,
          password: yupPassword(),
          confirmPassword: yupMatchPassword(),
        })}
        render={({
          values,
          setFieldValue,
          isValid,
          isSubmitting,
        }): JSX.Element => (
          <BlockSpinner loading={isSubmitting}>
            <Form>
              <div className={"row"}>
                <div className={"col-3"}>
                  <h3 className="text-white mb-0">Add Gateway</h3>
                </div>

                <FormComponent
                  fieldName={"emailAddress"}
                  label={"Email Address"}
                  submitSpinner={false}
                />
                <RadioButtonGroup
                  fieldName={"userType"}
                  title={"User type"}
                  options={[
                    { name: "admin", value: "admin" },
                    { name: "editor", value: "editor" },
                    { name: "viewer", value: "viewer" },
                  ]}
                  currentValue={values["userType"]}
                  setFieldValue={setFieldValue}
                  submitSpinner={false}
                />
                <FormComponent
                  fieldName={"password"}
                  label={"Users Password"}
                  type={"password"}
                  submitSpinner={false}
                />
                <FormComponent
                  fieldName={"confirmPassword"}
                  label={"Confirm Password"}
                  type={"password"}
                  submitSpinner={false}
                />
                <div className={"col-12"}>
                  <div className={"form-group row"}>
                    <Button
                      type={"submit"}
                      color={"primary"}
                      disabled={isSubmitting || !isValid}
                    >
                      Add User
                    </Button>
                  </div>
                </div>
              </div>
              <ErrorBanner error={error} />
            </Form>
          </BlockSpinner>
        )}
      />
    </OneCardPage>
  );
};

export default CreateUserForm;
