import React from "react";
import { GroupBy } from "../../../openapi/model/groupBy";
import { Button, ButtonGroup } from "reactstrap";
import { FormFieldProps } from "../LabelWrapper";
import RadioButtonGroup, {
  Option,
  RadioButtonGroupProps,
} from "../RadioButtonGroup";
import _ from "lodash";

interface CommonProps {
  noneLabel?: string;
  includeLocationGroup?: boolean;
  includeScheme?: boolean;
  disableLocationGroup?: boolean;
  buttonSize?: string;
}

export function GroupByPickerRadioButtonGroup(
  props: Omit<RadioButtonGroupProps, "options"> &
    React.HTMLAttributes<React.InputHTMLAttributes<string>> &
    FormFieldProps &
    CommonProps
): React.ReactElement {
  const options: Option[] = [
    { value: GroupBy.NONE, name: props.noneLabel || "NONE" },
    props.includeScheme ? { value: GroupBy.SCHEME, name: "SCHEME" } : undefined,
    props.includeLocationGroup
      ? { value: GroupBy.GROUPALL, name: "GROUP" }
      : undefined,
  ].filter((v) => !_.isNil(v)) as Option[];

  return <RadioButtonGroup options={options} {...props} />;
}

export default function GroupByPickerToggle(
  props: {
    setGroupBy: (groupBy: GroupBy) => void;
    selectedGroupBy?: GroupBy;
  } & CommonProps
): React.ReactElement {
  const {
    setGroupBy,
    selectedGroupBy,
    noneLabel = "COMPANY",
    buttonSize = "btn-sm",
  } = props;

  return (
    <>
      <ButtonGroup>
        <Button
          className={buttonSize}
          color={"primary"}
          active={selectedGroupBy === GroupBy.NONE}
          onClick={(): void => {
            setGroupBy(GroupBy.NONE);
          }}
        >
          {noneLabel}
        </Button>
        {props.includeScheme && (
          <Button
            className={buttonSize}
            color={"primary"}
            active={selectedGroupBy === GroupBy.SCHEME}
            onClick={(): void => {
              setGroupBy(GroupBy.SCHEME);
            }}
          >
            SCHEME
          </Button>
        )}
        {props.includeLocationGroup && (
          <Button
            className={buttonSize}
            color={"primary"}
            active={selectedGroupBy === GroupBy.GROUPALL}
            onClick={(): void => {
              setGroupBy(GroupBy.GROUPALL);
            }}
            disabled={props.disableLocationGroup}
          >
            GROUP
          </Button>
        )}
      </ButtonGroup>
    </>
  );
}
