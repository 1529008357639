/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { CompanyType } from "../openapi/model/companyType";

interface BaseRoute {
  name: string;
  layout: string;
  icon?: string;
  userGroup?: string;
  companyType: (CompanyType | "NONE")[];
  invisible?: boolean;
}

export interface PageRoute extends BaseRoute {
  path: string;
  beta?: boolean;
}

export interface CollapseRoute extends BaseRoute {
  collapse: boolean;
  views: PageRoute[];
}

export type RouteTypes = PageRoute | CollapseRoute;

export function isCollapseRoute(route: RouteTypes): route is CollapseRoute {
  return (route as CollapseRoute).collapse !== undefined;
}

const routes: RouteTypes[] = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fa fa-fw fa-home text-teal",
    layout: "/admin",
    companyType: ["CLIENT"],
  },
  {
    path: "/schemes",
    name: "Schemes",
    icon: "fa fa-fw fa-object-group text-teal",
    layout: "/admin",
    companyType: ["CLIENT"],
  },
  {
    path: "/asset",
    name: "Assets",
    icon: "fa fa-fw fa-map-marker-alt text-teal",
    layout: "/admin",
    userGroup: "viewer",
    companyType: ["PARTNER", "NONE"],
  },
  {
    path: "/report/gateway",
    name: "Gateway Status",
    icon: "fa fa-fw fa-signal text-primary",
    layout: "/admin",
    userGroup: "viewer",
    companyType: ["PARTNER", "NONE"],
  },
  {
    name: "Assets",
    icon: "fa fa-fw fa-map-marker-alt text-teal",
    collapse: true,
    companyType: ["CLIENT"],

    views: [
      {
        path: "/asset_register",
        name: "Asset Register",
        layout: "/admin",
        companyType: ["CLIENT"],
      },
      {
        path: "/location",
        name: "Locations",
        layout: "/admin",
        userGroup: "editor",
        companyType: ["CLIENT"],
      },
      {
        path: "/asset",
        name: "Assets",
        layout: "/admin",
        userGroup: "admin",
        companyType: ["CLIENT"],
      },
      {
        name: "Asset Positions",
        path: "/asset_position",
        layout: "/admin",
        userGroup: "editor",
        companyType: ["CLIENT"],
      },
      {
        path: "/report/gateway",
        name: "Gateway Status",
        layout: "/admin",
        companyType: ["CLIENT"],
      },
      {
        path: "/wireless_cluster",
        name: "Wireless Clusters",
        layout: "/admin",
        companyType: ["CLIENT"],
      },
      {
        path: "/wireless_survey",
        name: "Wireless Survey",
        layout: "/admin",
        companyType: ["CLIENT"],
        userGroup: 'superadmin'
      },
      {
        path: "/integrations",
        name: "Integrations",
        layout: "/admin",
        companyType: ["CLIENT"],
        userGroup: 'superadmin'
      },

    ],
    layout: "/admin",
  },
  {
    path: "/alarm",
    name: "Alarms",
    icon: "fa fa-fw fa-bell text-primary",
    layout: "/admin",
    companyType: ["CLIENT"],
  },
  {
    name: "Billing",
    icon: "fa fa-fw fa-clipboard text-primary",
    collapse: true,
    companyType: ["CLIENT"],
    views: [
      {
        path: "/report/billing/data",
        name: "Data",
        layout: "/admin",
        companyType: ["CLIENT"],
      },
      {
        path: "/billing/tariff",
        name: "Tariffs",
        layout: "/admin",
        companyType: ["CLIENT"],
        userGroup: "superadmin",
      },
      {
        path: "/billing/account",
        name: "Accounts",
        layout: "/admin",
        companyType: ["CLIENT"],
        userGroup: "superadmin",
      },
      {
        path: "/billing/report",
        name: "Reports",
        layout: "/admin",
        companyType: ["CLIENT"],
        userGroup: "superadmin",
      },
    ],
    layout: "/admin",
  },

  {
    name: "Performance",
    icon: "fa fa-fw fa-chart-area text-primary",
    collapse: true,
    companyType: ["CLIENT"],
    views: [
      {
        path: "/report/time_series/latest",
        name: "Latest Data",
        layout: "/admin",
        companyType: ["CLIENT"],
      },
      {
        path: "/report/time_series/aggregated",
        name: "Data Explorer",
        layout: "/admin",
        companyType: ["CLIENT"],
      },
      {
        path: "/report/time_series/stats",
        name: "Stats Explorer",
        layout: "/admin",
        beta: true,
        companyType: ["CLIENT"],
        userGroup: "superadmin",
      },
      {
        path: "/report/heat_network",
        name: "Heat Network Technical",
        layout: "/admin",
        beta: true,
        companyType: ["CLIENT"],
      },
      {
        path: "/report/row_data_quality",
        name: "Data Quality",
        layout: "/admin",
        companyType: ["CLIENT"],
      },
    ],
    layout: "/admin",
  },
  {
    path: "/export",
    name: "Export",
    icon: "fa fa-fw fa-save text-primary",
    layout: "/admin",
    companyType: ["CLIENT"],
  },
  {
    path: "/notes",
    name: "Notes",
    icon: "fa fa-fw fa-pen text-primary",
    layout: "/admin",
    companyType: ["CLIENT"],
  },
  {
    path: "/job",
    name: "Jobs",
    icon: "fa fa-fw fa-tasks text-primary",
    layout: "/admin",
    companyType: ["CLIENT"],
  },
  {
    path: "/event",
    name: "Events",
    icon: "fa fa-fw fa-list text-primary",
    layout: "/admin",
    companyType: ["CLIENT"],
  },
  {
    path: "/company",
    name: "Companies",
    icon: "fa fa-fw fa-building text-primary",
    layout: "/admin",
    companyType: ["NONE", "PARTNER"],
  },
  {
    path: "/users",
    name: "Users",
    icon: "fa fa-fw fa-users text-primary",
    layout: "/admin",
    userGroup: "admin",
    companyType: ["CLIENT", "PARTNER"],
  },
  {
    path: "/configuration",
    name: "Configuration",
    icon: "fa fa-fw fa-wrench text-primary",
    layout: "/admin",
    userGroup: "admin",
    companyType: ["CLIENT"],
  },
];

export default routes;
