import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import "./Breadcrumbs.scss";
import { AssetPosition } from "../../../openapi/model/assetPosition";
import { Asset } from "../../../openapi/model/asset";
import { Location } from "../../../openapi/model/location";
import CompanyNavLink from "../CompanyNavLink";
import { Link } from "react-router-dom";
import _ from "lodash";
import { useSelectedScheme } from "../../../reducers/scheme";
import PrivacyMode from "components/Text/PrivacyMode";

export interface PageDefinition {
  name: string;
  url?: string;
  privacyMode?: boolean;
}

const Breadcrumbs = (props: {
  showScheme?: boolean;
  pages?: PageDefinition[];
}): JSX.Element => {
  const selectedScheme = useSelectedScheme();

  return (
    <Breadcrumb
      className="d-none d-md-inline-block ml-lg-4"
      listClassName="breadcrumb-links breadcrumb-dark"
    >
      <BreadcrumbItem>
        <CompanyNavLink to={"/admin/dashboard"} tag={Link}>
          <i className="link fas fa-home" />
        </CompanyNavLink>
      </BreadcrumbItem>
      {selectedScheme && !(props.showScheme === false) && (
        <BreadcrumbItem>
          <span>
            <CompanyNavLink tag={Link} to={"/admin/schemes"}>
              <PrivacyMode>{selectedScheme.schemeName}</PrivacyMode>
            </CompanyNavLink>
          </span>
        </BreadcrumbItem>
      )}
      {props.pages &&
        props.pages.map((page, index) => {
          const content = page.privacyMode ? (
            <PrivacyMode>{page.name}</PrivacyMode>
          ) : (
            page.name
          );
          return (
            <BreadcrumbItem key={index}>
              <span>
                {page.url ? (
                  <CompanyNavLink to={page.url} tag={Link}>
                    {content}
                  </CompanyNavLink>
                ) : (
                  <a>{content}</a>
                )}
              </span>
            </BreadcrumbItem>
          );
        })}
    </Breadcrumb>
  );
};

export const assetToBreadcrumb = (asset: Asset): PageDefinition => {
  return {
    name: asset.serialNumber,
    url: `/admin/asset/${asset.assetId}`,
    privacyMode: true,
  };
};

export const assetPositionToBreadcrumb = (
  assetPosition: AssetPosition
): PageDefinition => {
  const id = assetPosition.customerReference
    ? assetPosition.customerReference
    : "..." + assetPosition.assetPositionId.slice(26);

  return {
    name: `${id} (${_.startCase(assetPosition.assetPositionType)})`,
    url: `/admin/asset_position/${assetPosition.assetPositionId}`,
    privacyMode: true,
  };
};

export const locationToBreadcrumb = (location: Location): PageDefinition => {
  return {
    name: location.address,
    url: `/admin/location/${location.locationId}`,
    privacyMode: true,
  };
};

export default Breadcrumbs;
