import React from "react";
import useGetEntity from "../../hooks/getEntity";
import { Asset } from "openapi/model/asset";

export interface AssetChildProps {
  asset: Asset;
  refresh: () => void;
  loading: boolean;
}

export interface OptionalAssetChildProps {
  asset?: Asset;
  refresh: () => void;
  loading: boolean;
}

interface AssetProps {
  asset: Asset;
  children: (props: AssetChildProps) => React.ReactElement;
}

interface AssetIdProps {
  assetId: string;
  companyId: string;
  children: (props: OptionalAssetChildProps) => React.ReactElement;
}

type Props = AssetIdProps | AssetProps;

function isAssetProps(props: Props): props is AssetProps {
  return (props as AssetProps).asset !== undefined;
}

function AssetContainer(props: Props) {
  const [{ data, loading }, refresh] = useGetEntity<Asset>({
    entityType: "ASSET",
    entityId: isAssetProps(props) ? props.asset.assetId : props.assetId,
    companyId: isAssetProps(props) ? props.asset.companyId : props.companyId,
    manual: !!isAssetProps(props), // we don't need to load if passed an asset
  });
  if (isAssetProps(props)) {
    return props.children({
      asset: data || props.asset, // prioritise data incase of a refreshed item
      refresh,
      loading,
    });
  } else {
    return props.children({
      asset: data,
      refresh,
      loading,
    });
  }
}

export default AssetContainer;
