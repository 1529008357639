import React, { useContext } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Button } from "reactstrap";
import { OneCardPage } from "../../components/utils/OneCardPage";
import FormComponent from "../../components/Forms/FormComponent";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import { useNavigate } from "react-router-dom";
import useCreateEntity from "../../hooks/createEntity";
import { Scheme } from "../../openapi/model/scheme";
import { SchemeContext } from "reducers/scheme";
import { useSelectedCompany } from "../../reducers/company";
import { ErrorBanner } from "../../components/Error/ErrorBanner";

interface FormValues {
  schemeId: string;
  schemeName: string;
  location: string;
}

const NewSchemeForm = (): JSX.Element => {
  const { createEntity, error } = useCreateEntity<Scheme>("SCHEME");
  const selectedCompany = useSelectedCompany();
  const { dispatch } = useContext(SchemeContext);
  const navigate = useNavigate();
  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ): void => {
    setSubmitting(true);
    createEntity({ ...values, companyId: selectedCompany?.companyId })
      .then(() => {
        resetForm();
        dispatch({ type: "SELECT_SCHEME", payload: values });
        setSubmitting(false);
        navigate("/admin/location");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialValues = {
    schemeId: "",
    schemeName: "",
    location: "",
  };
  return (
    <OneCardPage
      headerName={"Create"}
      headerParentName={"Schemes"}
      headerTitle={"Create Scheme"}
      breadcrumbs={[
        { url: "/admin/schemes", name: "Schemes" },
        { url: "/admin/schemes/create", name: "Create Scheme" },
      ]}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={Yup.object().shape({
          schemeName: Yup.string().required("Required."),
          schemeId: Yup.string()
            .required()
            .min(5, "Id is too short -  should be 5 chars minimum")
            .max(50, "Id is too long - should be be 50 chars maximum")
            .matches(
              /[a-z0-9-]/,
              "Id can only contain letters a-z, numbers 0-9 or a `-`"
            ),
          location: Yup.string(),
        })}
      >
        {({ isValid, isSubmitting }): JSX.Element => (
          <BlockSpinner loading={isSubmitting}>
            <Form>
              <FormComponent
                fieldName={"schemeId"}
                label={"Scheme Id"}
              />
              <FormComponent
                fieldName={"schemeName"}
                label={"Scheme Name"}
              />
              <FormComponent
                fieldName={"location"}
                label={"Location"}
              />

              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting || !isValid}
              >
                Create
              </Button>
            </Form>
            <ErrorBanner error={error} />
          </BlockSpinner>
        )}
      </Formik>
    </OneCardPage>
  );
};

export default NewSchemeForm;
