import React from "react";
import { Button, NavLink } from "reactstrap";
import { Link, NavLink as NavLinkRRD, useNavigate } from "react-router-dom";
import { getUrlHash } from "./URL";
import "./CompanyNavLink.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { useSelectedCompany } from "../../reducers/company";
import { useSelectedScheme } from "../../reducers/scheme";
import { usePrivacyMode } from "reducers/privacy";
import classNames from "classnames";

interface PProps {
  to: string;
  children: any;
  tag?: React.ElementType;
  dataCy?: string;
  className?: string;
  onClick?: React.MouseEventHandler<any>;
  external?: boolean;
  inline?: boolean;
}

type Props = PProps;

function CompanyNavLink(props: Props): any {
  const company = useSelectedCompany();
  const scheme = useSelectedScheme();
  const privacyMode = usePrivacyMode();

  const to = props.to + "#" + getUrlHash(company, scheme, privacyMode);
  const navigate = useNavigate();

  if (!props.tag) {
    props = {
      ...props,
      tag: NavLinkRRD,
    };
  }

  if (props.tag === Button) {
    return (
      <Button
        color="primary"
        className={"mx-2"}
        onClick={(e): void => {
          navigate(to);
          if (props.onClick) {
            props.onClick(e);
          }
        }}
        data-cy={props.dataCy}
      >
        {props.children}
      </Button>
    );
  }
  const adjustProps = {
    children: props.children,
    tag: props.tag,
    to: to,
    className: props.className,
    onClick: props.onClick,
  };

  if (props.tag === Link) {
    return (
      <>
        <Link
          {...adjustProps}
          data-cy={props.dataCy}
          target={props.external ? "_blank" : undefined}
          className={'px-0'}
        />
        {props.external && <i className={"px-1 fa fa-external-link"} />}
      </>
    );
  } else {
    return (
      <>
        <NavLink {...adjustProps} data-cy={props.dataCy} className={classNames({'d-inline-block px-0': props.inline,"blurry-text" : privacyMode})}/>
        {props.external && (
          <NavLink
            to={to}
            className={classNames(props.className, "mx-1", {'d-inline-block': props.inline,"blurry-text" : privacyMode})}
            tag={props.tag}
            target={"_blank"}
          >
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </NavLink>
        )}
      </>
    );
  }
}

export default CompanyNavLink;
