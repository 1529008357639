import React from "react";
import { DeviceAsset } from "../../../openapi/model/deviceAsset";
import { DeviceConfiguration } from "../../../openapi/model/deviceConfiguration";

const MBusAssetListContext = (props: { asset: DeviceAsset }): JSX.Element => {
  const configuration: DeviceConfiguration | undefined =
    props.asset.configuration &&
    Object.keys(props.asset.configuration).includes("mbus")
      ? (props.asset.configuration as DeviceConfiguration)
      : undefined;

  if (configuration?.mbus?.addressingMode === "primary") {
    return (
      <small className={"warning"}>
        Warning. MBus Primary Addressing Mode in use. Address{" "}
        {configuration.mbus?.primaryAddress}
      </small>
    );
  }
  return <></>;
};
export default MBusAssetListContext;
