import React from "react";
import { AssetRegisterItem } from "../../openapi/model/assetRegisterItem";
import { AssetRegisterSort } from "../../openapi/model/assetRegisterSort";
import AssetRegisterListItem, {
  AssetRegisterListHeading,
} from "./AssetRegisterListItem";
import PaginatedApiToCSVExportButton from "../../components/utils/CSVExport/PaginatedApiToCSVExportButton";
import { GatewayAsset } from "openapi/model/gatewayAsset";
import { DeviceAsset } from "../../openapi/model/deviceAsset";
import GetListPaginatedList from "../../components/Pagination/GetListPaginatedList";
import AssetRegisterToAsseTagButton from "./AssetRegisterToAssetTagButton";
import { Status } from "openapi/model/status";

interface Props {
  companyId: string;
  schemeId?: string;
  locationId?: string;
  assetType?: string;
  assetId?: string;
  textSearch?: string;
  parentAssetPositionId?: string;
  status?: Status[];
  sort?: AssetRegisterSort;
  row?: (props: {
    showScheme?: boolean;
    assetRegisterItem: AssetRegisterItem;
    index: number;
    refresh?: () => void;
  }) => JSX.Element;
  header?: JSX.Element;
  download?: boolean;
  createAssetTags?: boolean;
  showLocation?: boolean;
  lastChange?: Date;
  additionalFunctionButtons?: JSX.Element;
}

const assetToAssetRegisterExportDict = (
  asset: GatewayAsset | DeviceAsset
): any => {
  const {
    assetId,
    serialNumber,
    manufacturer,
    model,
    type,
    parentAssetId,
    version,
    lastUpdated: _0,
    createdAt: _1,
    assetType: _2,
    assetPositionId: _3,
    schemeId: _4,
    companyId: _5 /* pull this row out so not exported*/,
    ...rest
  } = asset;

  if ("credentials" in rest) {
    delete (rest as any).credentials;
  }

  return {
    assetId,
    serialNumber,
    manufacturer,
    model,
    type,
    parentAssetId,
    version,
    assetProperties: rest,
  };
};

const mapAssetRegisterItemForCSV = (
  assetRegisterItem: AssetRegisterItem
): any => {
  const { location, assetPosition, asset } = assetRegisterItem;

  const { companyId, schemeId, address, locationId } = location;
  const {
    assetPositionType,
    customerReference,
    parentAssetPositionId,
    assetPositionId,
  } = assetPosition;
  const assetProperties = asset ? assetToAssetRegisterExportDict(asset) : {};
  return {
    companyId,
    schemeId,
    address,
    locationId,
    assetPositionType,
    customerReference,
    parentAssetPositionId,
    assetPositionId,
    ...assetProperties,
  };
};

export default function AssetRegisterList(props: Props): JSX.Element {
  const {
    row,
    header,
    download = true,
    createAssetTags = true,
    showLocation = true,
    additionalFunctionButtons,
    ...params
  } = props;
  const showScheme = props.schemeId === undefined;
  const rowFunction = (
    item: AssetRegisterItem,
    index: number,
    refresh?: () => void
  ): JSX.Element => {
    const Row = row ? row : AssetRegisterListItem;
    return (
      <Row
        assetRegisterItem={item}
        showScheme={showScheme}
        index={index}
        key={index}
        refresh={refresh}
        showLocation={showLocation}
      />
    );
  };

  const Header = header ? (
    header
  ) : (
    <AssetRegisterListHeading
      showScheme={showScheme}
      showLocation={showLocation}
    />
  );
  const filtered = true;

  const filename =
    `${props.companyId}_${props.schemeId ? props.schemeId + "_" : ""}` +
    `${filtered ? "filtered_" : ""}asset_register`;
  return (
    <GetListPaginatedList
      entityType={"ASSET_REGISTER"}
      params={params as unknown as Record<string, string>}
      lastChange={props.lastChange}
      cursor={{ maxItems: 25 }}
      header={Header}
      row={rowFunction}
      tableClassName={"list-group-flush"}
      data-cy={"asset-register-list"}
      additionalFunctionButtons={
        <>
          {download && (
            <PaginatedApiToCSVExportButton
              entityType={"ASSET_REGISTER"}
              params={params as unknown as Record<string, string>}
              mapFunction={mapAssetRegisterItemForCSV}
              filename={filename}
            />
          )}
          {props.schemeId && createAssetTags && (
            <AssetRegisterToAsseTagButton
              companyId={props.companyId}
              schemeId={props.schemeId}
            />
          )}
          {additionalFunctionButtons &&
            React.cloneElement(additionalFunctionButtons)}
        </>
      }
    />
  );
}
