import React, { useContext } from "react";
import { SchemeContext } from "../../reducers/scheme";
import { Scheme } from "../../openapi/model/scheme";

export interface SchemeSelectProps {
  selected?: Scheme;
  loading: boolean;
  schemes: Scheme[];
  select: (scheme?: Scheme) => void;
}

export default function SchemeSelectWrapper(props: {
  children: (props: SchemeSelectProps) => React.ReactNode;
}): JSX.Element {
  const { state, dispatch } = useContext(SchemeContext);
  const { selected, loading, schemes = [] } = state;

  const selectScheme = (scheme?: Scheme): void => {
    dispatch({ type: "SELECT_SCHEME", payload: scheme });
  };

  return (
    <>{props.children({ selected, loading, schemes, select: selectScheme })}</>
  );
}
