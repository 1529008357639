import React, {useState} from "react";
import {Dropdown} from "@psd-platform/monday-ui-react-core";
import {ActionMeta, GroupBase, OnChangeValue, OptionsOrGroups} from "react-select";
import NewLabelWrapper, {LabelProps} from "../NewLabelWrapper";
import _, {isArray, isNull, isUndefined} from "lodash";
import classNames from "classnames";

import styles from './SelectCombo.module.scss';


export interface FormComponentOptions {
    fieldName: string
}

export type  Value = string | number | boolean;
export type ValueList = Value | Value[];

export interface Option {
    label: string | number;
    value: Value;
}

export function isOption(option: Option|GroupBase<Option>): option is Option {
    return (option as Option).value !== undefined
}

export type OptionsList = OptionsOrGroups<Option, GroupBase<Option>>

export interface DropDownProps {
    clearable?: boolean,
    disabled?: boolean,
    placeholder?: string,
}

export interface SelectComboProps extends DropDownProps {
    name: string,
    label?: LabelProps,
    onChange: (newValue: OnChangeValue<Option, boolean>, actionMeta: ActionMeta<Option>) => void,
    isMulti?: boolean,
    options?: OptionsList;
    value: ValueList,
    error?: string,
    touched?: boolean,
}


export function stringListToOptions(lst: (string | undefined)[]): Option[] {
    return lst
        .filter((v) => v)
        .map((v): Option => {
            return {
                value: v as string,
                label: _.startCase(v),
            };
        });
}


export function isGroupBase(option: Option | GroupBase<Option>): option is GroupBase<Option> {
    return (option as GroupBase<Option>).options !== undefined
}


function flattenGroupedOptions(options: OptionsList): Option[] {
    return options.reduce((accumulator: Option[], value) =>
        accumulator.concat(isGroupBase(value) ? value.options : [value]), [])
}


function valueToOptionsList(value: ValueList | null | undefined, options: Option[]): Option[] | null | undefined {
    if (isNull(value)) {
        return null
    }
    if (isUndefined(value)) {
        return undefined
    }
    if (isArray(value)) {
        const filtered = options.filter((o) =>
            value.indexOf(o.value) !== -1
        )
        if (filtered.length !== value.length) {
            console.error(`not all values are in the option list ${value} ${options}`)
        }
        return filtered
    } else {
        const filtered = options.filter((o) =>
            !isGroupBase(o) && value === o.value
        )
        if (filtered.length === 0) {
            console.error(`Select value not found ${value} ${options}`)
        }
        return filtered
    }
}

export const SelectCombo = (props: SelectComboProps): JSX.Element => {
    const {name, value, options, label, isMulti, disabled, error, touched, onChange,...rest} = props

    const [inFocus, setInFocus] = useState<boolean>(false)
    const selectRef = React.createRef<typeof Dropdown>();
    return <NewLabelWrapper {...{...label, error: error}}>
        <Dropdown
            ref={selectRef}
            className={classNames({
                'mx-2': true,
                [styles.selectComp]: true,
                [styles.isInvalid]: touched && error,
            })}
            dropdownMenuWrapperClassName={styles.z20}
            menuAriaLabel={""}
            id={`select-${name}`}
            menuId={`select-${name}`}
            multi={isMulti}
            disabled={disabled}
            options={options}
            menuIsOpen={inFocus}
            onChange={(v: OnChangeValue<Option, boolean>, m: ActionMeta<Option>)=>{
                onChange(v,m)
                if (!props.isMulti) {
                    selectRef.current.blur()?.then(() => setInFocus(false));
                }
            }}
            onMenuClose={()=> {
                selectRef.current.blur();
                setInFocus(false);
            }}
            value={valueToOptionsList(value, options ? flattenGroupedOptions(options) : [])}
            closeMenuOnSelect={!props.isMulti}
            customChipClassName={styles.chip}
            customControlClassName={inFocus && styles.selectControlInFocus}
            customCounterClassName={styles.counter}
            onFocus={()=>setInFocus(true)}
            onBlur={()=>setInFocus(false)}
            {...rest}
        />

    </NewLabelWrapper>
}

export default SelectCombo
