import React, { useRef } from "react";
import {
  ColumnDefinitionWithData,
  TimeSeriesDataParams,
} from "../../../../model/deviceTimeSeries";
import Highcharts, { Dictionary, SVGElement } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { Moment } from "moment";
import _ from "lodash";
import BlockSpinner from "../../../../components/Spinners/BlockSpinner";
import ErrorOverlappingBanner from "../../../../components/Error/ErrorOverlappingBanner";
import { ErrorBand } from "../../../../time_series/asset/data/valueErrors";
// eslint-disable-next-line @typescript-eslint/no-var-requires
require("highcharts/modules/no-data-to-display.js")(Highcharts);
// eslint-disable-next-line @typescript-eslint/no-var-requires
require("highcharts/modules/annotations")(Highcharts);

export const columnToNumberData = (
  column: ColumnDefinitionWithData | undefined
): (number | null)[] => {
  if (column) {
    return (column.data as string[]).map((v) =>
      v === null ? null : parseFloat(v)
    );
  } else {
    return [];
  }
};

export const columnToTimeNumberData = (
  column: ColumnDefinitionWithData<any> | Array<number> | undefined,
  times: Moment[]
): [number, number | null][] => {
  if (_.isArray(column) && times) {
    return column.map((data, i) => {
      return [times[i].valueOf(), data];
    });
  } else if ((column as ColumnDefinitionWithData<any>)?.data && times) {
    return ((column as ColumnDefinitionWithData<any>).data as string[]).map(
      (v, index) => {
        return [times[index].valueOf(), v === null ? null : parseFloat(v)];
      }
    );
  } else {
    return [];
  }
};

const removeNulls = (array: (number | null)[]): number[] => {
  return array.filter((v) => v !== null) as number[];
};

const HeatMeterFlowAndTemperatureChart = (props: {
  flowRateData: ColumnDefinitionWithData;
  flowTemperatureData: ColumnDefinitionWithData;
  returnTemperatureData: ColumnDefinitionWithData;
  powerData: ColumnDefinitionWithData;
  errorBands?: Array<ErrorBand>;
  times: Moment[];
  params: TimeSeriesDataParams;
  loading: boolean;
  error?: Error;
}): JSX.Element => {
  const series: Highcharts.SeriesLineOptions[] = [
    {
      data: columnToTimeNumberData(props.flowTemperatureData, props.times),
      type: "line",
      animation: false,
      color: "red",
      name: "Flow Temperature",
      yAxis: 0,
    },
    {
      data: columnToTimeNumberData(props.returnTemperatureData, props.times),
      type: "line",
      animation: false,
      color: "blue",
      name: "Return Temperature",
      yAxis: 0,
    },
    {
      data: columnToTimeNumberData(props.flowRateData, props.times),
      type: "line",
      animation: false,
      color: "yellow",
      name: "Flow Rate",
      yAxis: 1,
    },
    {
      data: columnToTimeNumberData(props.powerData, props.times),
      type: "line",
      animation: false,
      color: "orange",
      name: "Power",
      yAxis: 2,
    },
  ];

  const minTemperature = Math.min(
    ...[
      ...removeNulls(columnToNumberData(props.flowTemperatureData)),
      ...removeNulls(columnToNumberData(props.returnTemperatureData)),
      0,
    ]
  );
  const maxTemperature = Math.max(
    ...[
      ...removeNulls(columnToNumberData(props.flowTemperatureData)),
      ...removeNulls(columnToNumberData(props.returnTemperatureData)),
      60,
    ]
  );
  const chartEl = useRef<HighchartsReact.RefObject>(null);

  let tooltip: SVGElement | undefined = undefined;
  const ticksAboveZero = Math.ceil(maxTemperature / 20);
  const ticksBelowZero = Math.floor(minTemperature / 20);
  const options: Highcharts.Options = {
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      min: props.params.startDatetime?.valueOf(),
      max: props.params.endDatetime?.valueOf(),
      plotBands: props.errorBands?.flatMap((v) => {
        return {
          from: v.start.valueOf(),
          to: v.end.valueOf(),
          color: "lightgrey",
          events: {
            mouseout: function () {
              if (tooltip) {
                tooltip.destroy();
                tooltip = undefined;
              }
            },
            mouseover: function (e: Event | Dictionary<any> | undefined) {
              if (e && chartEl.current) {
                const chart = chartEl.current.chart;

                const [x, y] = [
                  (e as MouseEvent).offsetX + 20,
                  (e as MouseEvent).offsetY + 20,
                ];

                tooltip = chart.renderer
                  .label(
                    "<div style='border: 1px solid red'><p class='py-0 my-0' style='font-size: 10px'>Value Errors Removed</p>" +
                      `<p class='py-0 my-0' style='font-size: 12px'>${Object.keys(
                        v.errors
                      ).join(",")}</p> ` +
                      "</div>",
                    x,
                    y,
                    "callout",
                    undefined,
                    undefined,
                    true
                  )
                  .css({
                    color: "#333333",
                    width: 200,
                  })
                  .attr({
                    fill: "rgba(247, 247, 247, 0.85)",
                    padding: 0,
                    zIndex: 20,
                    r: 5,
                  })
                  .add();
              }
            },
          },
        };
      }),
    },
    series: series,
    legend: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    lang: {
      noData: props.loading ? "Loading Data" : "No data for this time period",
    },
    tooltip: {
      shared: true,
    },
    yAxis: [
      {
        // Primary yAxis
        labels: {
          format: "{value}°C",
        },
        title: {
          text: "Temperature",
        },
        min: ticksBelowZero * 20,
        max: ticksAboveZero * 20,
        tickAmount: ticksAboveZero + ticksBelowZero + 1,
      },
      {
        // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: "Flow Rate",
        },
        labels: {
          format: "{value}m3/hr",
        },
        min: Math.min(
          ...[...removeNulls(columnToNumberData(props.flowRateData)), 0]
        ),
        max: Math.max(
          ...[...removeNulls(columnToNumberData(props.flowRateData)), 1]
        ),
        opposite: true,
      },
      {
        // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: "Power",
        },
        labels: {
          format: "{value}W",
        },
        min: Math.min(
          ...[...removeNulls(columnToNumberData(props.powerData)), 10000]
        ),
        max: Math.max(
          ...[...removeNulls(columnToNumberData(props.powerData)), 1]
        ),
        opposite: true,
      },
    ],
    noData: {
      style: {
        fontWeight: "normal",
        fontSize: "15px",
        color: "black",
      },
    },
    exporting: {
      fallbackToExportServer: false,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "downloadSVG",
          ],
        },
      },
    },
  };

  return (
    <BlockSpinner loading={props.loading}>
      <Col xl="12" className={"pt-2"}>
        <ErrorOverlappingBanner error={props.error}>
          <Card>
            <CardHeader>
              <h6 className="surtitle">Overview</h6>
              <h5 className="h3 mb-0">Flow Diagnostics</h5>
            </CardHeader>
            <CardBody>
              <div className="chart">
                <HighchartsReact
                  allowChartUpdate={true}
                  highcharts={Highcharts}
                  options={options}
                  ref={chartEl}
                />
              </div>
            </CardBody>
          </Card>
        </ErrorOverlappingBanner>
      </Col>
    </BlockSpinner>
  );
};

export default HeatMeterFlowAndTemperatureChart;
