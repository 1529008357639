/**
 * Meterpoint.io
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type AlarmTypes = 'GATEWAY_OFFLINE' | 'GATEWAY_CONFIGURATION_ERROR_UPDATING';

export const AlarmTypes = {
    OFFLINE: 'GATEWAY_OFFLINE' as AlarmTypes,
    CONFIGURATIONERRORUPDATING: 'GATEWAY_CONFIGURATION_ERROR_UPDATING' as AlarmTypes
};