import React, { useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import PeriodPickerToggle from "components/Forms/PeriodPicker/PeriodPickerToggle";
import TimeSeriesStatsApiWrapper, {
  AggregatedTimeSeriesStatsParams,
} from "../../../report/latestDataReport/TimeSeriesStatsApiWrapper";
import { Period } from "../../../../openapi/model/period";
import { inclusiveDatePeriod } from "../../../../components/Forms/DateTimePicker";
import moment, { Moment } from "moment";
import { useSelectedCompany } from "../../../../reducers/company";
import {
  useLocationGroups,
  useSelectedScheme,
} from "../../../../reducers/scheme";
import _ from "lodash";
import { GroupBy } from "../../../../openapi/model/groupBy";
import GroupByPickerToggle from "../../../../components/Forms/GroupByPicker/GroupByPickerToggle";
import { Company } from "../../../../openapi/model/company";
import DisplayIfUserType from "components/utils/DisplayIfUserType/DisplayIfUserType";
import AggregatedStatsChart from "../../../../components/charts/AggregatedStatsChart";
import {
  BillableAssets,
  NonBillableAssetTypes,
} from "../../../../model/assetPosition";
import { AssetType } from "../../../../openapi/model/assetType";

function periodName(period: Period): string {
  switch (period) {
    case "DAILY":
      return "Daily";
    case "MONTHLY":
      return "Monthly";
    case "WEEKLY":
      return "Weekly";
    case "SEVEN_DAY":
      return "7 Day";
    case "ALL":
      return "All";
  }
}

const monthInclusiveDatePeriod = (length = 3): [Moment, Moment] => {
  const endDate: Moment = moment().startOf("day").subtract(1, "day");
  const startDate: Moment = moment(endDate)
    .subtract(length, "month")
    .startOf("month");
  return [startDate, endDate];
};

const periodLength = (period: Period): [Moment, Moment] => {
  switch (period) {
    case "MONTHLY":
      return monthInclusiveDatePeriod();
    case "SEVEN_DAY":
      return inclusiveDatePeriod(undefined, undefined, 56);
    default:
      return inclusiveDatePeriod();
  }
};

const DailyReadSuccessWidget = (): React.ReactElement => {
  const selectedCompany = useSelectedCompany() as Company;
  const selectedScheme = useSelectedScheme();
  const locationGroups = useLocationGroups();

  let assetTypes: AssetType[] = Object.values(NonBillableAssetTypes);
  assetTypes = assetTypes.concat(Object.values(BillableAssets));

  const [period, setPeriod] = useState<Period>(Period.SEVENDAY);
  const [groupBy, setGroupBy] = useState<GroupBy>(
    selectedScheme
      ? locationGroups
        ? GroupBy.GROUPALL
        : GroupBy.NONE
      : GroupBy.SCHEME
  );

  const [startDatetime, endDatetime] = periodLength(period);

  const params: AggregatedTimeSeriesStatsParams = {
    startDatetime,
    endDatetime,
    parameter: "Quality Assessment",
    aggregation: "received_rows_basic_complete",
    companyId: selectedCompany?.companyId || "NONE",
    schemeId: selectedScheme?.schemeId,
    groupBy,
    assetTypes: assetTypes,
    period: period,
  };

  const periodNameText = periodName(period);
  const schemeMode = !_.isNil(selectedScheme);

  return (
    <Card>
      <CardHeader className="border-0">
        <Row className={"justify-content-between"}>
          <Col sm={"auto"}>
            <h3 className="mb-0">Billing Read Success ({periodNameText})</h3>
          </Col>
          <Col sm={"auto"}>
            <span className={"float-right px-1"}>
              <PeriodPickerToggle
                selectedPeriod={period}
                setPeriod={setPeriod}
              />
            </span>
            <DisplayIfUserType userTypes="superadmin">
              <span className={"float-right px-1"}>
                <GroupByPickerToggle
                  noneLabel={schemeMode ? "SCHEME" : "COMPANY"}
                  selectedGroupBy={groupBy}
                  setGroupBy={setGroupBy}
                  includeLocationGroup={schemeMode}
                  includeScheme={!schemeMode}
                  disableLocationGroup={!locationGroups}
                />
              </span>
            </DisplayIfUserType>
          </Col>
        </Row>
      </CardHeader>

      <CardBody>
        <TimeSeriesStatsApiWrapper request={params}>
          {(chartProps) => (
            <AggregatedStatsChart
              {...chartProps}
              clickUrl={"/admin/report/row_data_quality"}
              groupBy={groupBy}
              seriesName={`% of meter points with &gt; 1 read in period `}
              showCountAsPercentage={true}
              units={'%'}
            />
          )}
        </TimeSeriesStatsApiWrapper>
      </CardBody>
    </Card>
  );
};

export default DailyReadSuccessWidget;
