import React from "react";
import { Alert } from "reactstrap";

const LoginErrorBanner = (props: { authError?: any }): JSX.Element => {
  return (
    <>
      {props.authError &&
        (["NotAuthorizedException", "UserNotFoundException"].includes(
          props.authError.code
        ) ? (
          <Alert color="danger" className={"mt-3"}>
            <p className={"text-lg font-weight-bold"}>Login failed</p>
            <p> Your email address or password are not valid.</p>
            <p>Please try again.</p>
          </Alert>
        ) : (
          <Alert color="danger" className={"mt-3"}>
            <p className={"text-lg font-weight-bold"}>Login failed</p>
            <p>{props.authError.message}</p>
            <p>Please try again.</p>
          </Alert>
        ))}
    </>
  );
};

export default LoginErrorBanner;
