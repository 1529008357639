import React from "react";
import { Alert, Button, Col } from "reactstrap";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import { AuthContext, logout } from "../../../reducers/auth";

interface State {
  success: boolean;
  error: boolean;
  errorMessage: string;
}

interface FormValues {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export default function ChangePasswordForm() {
  const [state, setState] = React.useState<State>({
    success: false,
    error: false,
    errorMessage: "",
  });
  const dispatch = React.useContext(AuthContext).dispatch;

  const submit = (
    { currentPassword, newPassword }: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ): void => {
    setSubmitting(true);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, currentPassword, newPassword);
      })
      .then(() => {
        resetForm();
        setState({ success: true, error: false, errorMessage: "" });
        logout(dispatch);
      })
      .catch((err) => {
        setState({
          success: false,
          error: true,
          errorMessage: err.message,
        });
      });
    setSubmitting(false);
  };

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={Yup.object().shape({
          currentPassword: Yup.string().required(
            "Current password is required."
          ),
          newPassword: Yup.string().required("New Password is required"),
          newPasswordConfirm: Yup.string()
            .required("Confirmation of your new password is required")
            .oneOf(
              [Yup.ref("newPassword"), null],
              "Must match your new password"
            ),
        })}
      >
        {({ errors, touched, isValid, isSubmitting }) => (
          <Form>
            <div className="form-row">
              <Col className="mb-3" md="12">
                <label
                  className="form-control-label"
                  htmlFor="changePasswordCurrentPassword"
                >
                  Current password
                </label>
                <Field
                  aria-describedby="inputGroupPrepend"
                  id="changePasswordCurrentPassword"
                  type="password"
                  name="currentPassword"
                  className={
                    "form-control " +
                    (errors.currentPassword && touched.currentPassword
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="currentPassword"
                  component="div"
                  className="invalid-feedback"
                />
              </Col>
              <Col className="mb-3" md="12">
                <label
                  className="form-control-label"
                  htmlFor="changePasswordNewPassword"
                >
                  New password
                </label>
                <Field
                  aria-describedby="inputGroupPrepend"
                  id="changePasswordNewPassword"
                  type="password"
                  name="newPassword"
                  className={
                    "form-control " +
                    (errors.newPassword && touched.newPassword
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="newPassword"
                  component="div"
                  className="invalid-feedback"
                />
              </Col>
              <Col className="mb-3" md="12">
                <label
                  className="form-control-label"
                  htmlFor="changePasswordNewPasswordConfirm"
                >
                  Confirm new password
                </label>
                <Field
                  aria-describedby="inputGroupPrepend"
                  id="changePasswordNewPasswordConfirm"
                  type="password"
                  name="newPasswordConfirm"
                  className={
                    "form-control " +
                    (errors.newPasswordConfirm && touched.newPasswordConfirm
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name="newPasswordConfirm"
                  component="div"
                  className="invalid-feedback"
                />
              </Col>
            </div>
            <Button
              color="primary"
              disabled={!isValid || isSubmitting}
              type="submit"
            >
              Save password
            </Button>
          </Form>
        )}
      </Formik>
      {state.success ? (
        <Alert color="success" className={"mt-3"}>
          Password successfully changed.
        </Alert>
      ) : (
        <></>
      )}
      {state.error ? (
        <Alert color="danger" className={"mt-3"}>
          Password change failed with error: {state.errorMessage}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
