import React from "react";
import { TariffOut } from "../../openapi/model/tariffOut";
import { Col, Row } from "reactstrap";
import TariffInstanceList from "./TariffInstanceList";
import PrivacyMode from "../../components/Text/PrivacyMode";

function TariffHomepage(props: { tariff: TariffOut }) {
  const { tariff } = props;

  return (
    <>
      <Row>
        <Col xs={12} md={7}>
          <div className={"d-flex flex-wrap"}>
            <>
              <div className={"mr-4"}>
                <h6 style={{ textTransform: "uppercase", marginBottom: 0 }}>
                  Tariff Name
                </h6>
                <h2>
                  <PrivacyMode>{tariff.tariffName}</PrivacyMode>
                </h2>
              </div>
            </>
          </div>
        </Col>
      </Row>
      <div style={{ display: "flex", flexWrap: "nowrap", overflowX: "auto" }}>
        <div style={{ width: "100vw" }}>
          <TariffInstanceList tariff={tariff} />
        </div>
      </div>
    </>
  );
}

export default TariffHomepage;
