import React, {useEffect} from "react";
import {AssetRegisterEntitySuccessProps} from "../../core/action/GetAssetRegisterEntitiesCombination";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Status} from "../../openapi/model/status";
import {AssetType} from "../../openapi/model/assetType";
import useUpdateEntity from "../../hooks/updateEntity";
import {AssetPosition} from "../../openapi/model/assetPosition";
import {OneCardPage} from "../../components/utils/OneCardPage";
import {assetPositionToBreadcrumb, locationToBreadcrumb,} from "../../components/navigation/breadcrumbs/Breadcrumbs";
import {Col, Row} from "reactstrap";
import AssetPositionPreview from "./AssetPositionPreview";
import RoutedTabGroupInterface from "../../components/TabGroup/RoutedTabGroupInterface";
import SimpleAssetRegisterUploadContainer from "./simpleAssetRegisterUpload/SimpleAssetRegisterUploadContainer";
import EventList from "../event/EventList";
import AssetPositionDeleteTab from "./tabs/AssetPositionDeleteTab";
import {Tab} from "../../components/TabGroup/TabGroupInterface";
import WirelessClusterGatewayList from "../wirelessCluster/WirelessClusterGatewayList";
import WirelessClusterGatewayAddList from "../wirelessCluster/WirelessClusterGatewayAddList";
import WirelessClusterReceiverAssignmentTab from "../wirelessCluster/WirelessClusterReceiverAssignmentTab";
import NoteList from "../notes/NotesList";
import IntegrationHomePage from "../integrations/IntegrationHomePage";
import AssetRegisterList from "../assetRegister/AssetRegisterList";
import AssetPositionAssetHistoryTab from "./tabs/assetPositionAssetHistoryTab";
import AssetPositionConfigurationTab from "./tabs/AssetPositionConfigurationTab";

const notNoneNullOrUndefined = (obj: any): boolean => {
    return obj !== "NONE" && obj !== undefined && obj !== null;
};

const AssetPositionDisplay = ({
                                  assetPosition,
                                  location,
                                  asset,
                                  loading,
                                  refresh,
                              }: AssetRegisterEntitySuccessProps): JSX.Element => {
    const {tab} = useParams();

    const urlLocation = useLocation();
    const navigate = useNavigate();

    const attached = notNoneNullOrUndefined(assetPosition?.assetId);
    const deleted = assetPosition?.status === Status.DELETED;
    const gateway = assetPosition?.assetPositionType === AssetType.Gateway;
    const wireless_cluster =
        assetPosition?.assetPositionType === AssetType.WirelessCluster;

    if (assetPosition === undefined) {
        throw new Error("Asset Position Not Found");
    }

    const {updateEntity} = useUpdateEntity<AssetPosition>(
        "ASSET_POSITION",
        assetPosition.assetPositionId
    );

    const deleteAssetPosition = (): Promise<any> => {
        if (assetPosition) {
            return updateEntity({
                ...assetPosition,
                status: Status.DELETED,
            });
        } else {
            throw new Error("Can not delete without an asset position");
        }
    };

    const handlePage = () => {
        const moveTab = (newTabLink: string): void => {
            navigate({
                ...urlLocation,
                pathname: urlLocation.pathname.replace(tab as string, newTabLink),
            });
        };
        if (tab === "assign_asset" && attached) {
            moveTab("link");
        } else if (tab === "assign_asset" && deleted) {
            moveTab("link");
        } else if (tab === "add_positions" && deleted) {
            moveTab("link");
        }
    };

    useEffect(handlePage, [
        assetPosition,
        tab,
        attached,
        deleted,
        navigate,
        urlLocation,
    ]);

    if (assetPosition?.assetPositionType === AssetType.ApiIntegration) {
        return <IntegrationHomePage integration={{
            assetPosition,
            location,
            asset,
            loading,
            refresh,
        }} deleteAssetPosition={deleteAssetPosition}/>
    }
    const clusterGatewayTab = {
        tabName: "Cluster Gateways",
        tabPath: "gateways",
        tabContent: (
            <WirelessClusterGatewayList
                clusterPosition={assetPosition}
            />
        ),
    }
    const clusterReceiverAssignmentTab = {
        tabName: "Receiver Assignment",
        tabPath: "receiver_assignment",
        tabContent: (
            <WirelessClusterReceiverAssignmentTab
                clusterPosition={assetPosition}
            />
        ),
    }
    const clusterAddGatewaysTab = {
        tabName: "Add Cluster Gateways",
        tabPath: "add_gateways",
        tabContent: (
            <WirelessClusterGatewayAddList
                clusterPosition={assetPosition}
            />
        ),
        userTypes: "admin",
    }

    const ownedAssetPositionsTab = {
        tabName: "Owned Asset Positions",
        tabPath: "positions",
        tabContent: (
            <Row>
                <Col xs={12}>
                    <h3>
                        {gateway && "Gateway"}
                        {wireless_cluster && "Cluster"} Owned Asset
                        Positions
                    </h3>
                </Col>
                <Col xs={12}>
                    <AssetRegisterList
                        parentAssetPositionId={
                            assetPosition.assetPositionId
                        }
                        companyId={assetPosition.companyId}
                        schemeId={assetPosition.schemeId}
                        createAssetTags={false}
                    />
                </Col>
            </Row>
        ),
    }
    const uploadAssetRegisterTab = {
        tabName: "Add Owned Asset Positions",
        tabPath: "add_positions",
        tabContent: (
            <>
                <Col xs={12}>
                    <h3>Add Simple Asset Register</h3>
                </Col>
                <Col xs={12}>
                    <SimpleAssetRegisterUploadContainer
                        assetPosition={assetPosition}
                    />
                </Col>
            </>
        ),
        userTypes: "admin",
        disabled: deleted,
    }
    const configuration = {
        tabName: 'Configuration',
        tabPath: 'configuration',
        tabContent: <AssetPositionConfigurationTab assetPosition={assetPosition}
                                                   refresh={refresh}
        />,
        userTypes: 'admin',
        disabled: !wireless_cluster && !gateway
    }

    const assetHistoryTab = {
        tabName: "Asset History",
        tabPath: "link",
        tabContent: <AssetPositionAssetHistoryTab assetPosition={assetPosition}
                                                  refresh={refresh}/>,
        disabled: false,
    }
    const notesTab = {
        tabName: "Notes",
        tabPath: "note",
        tabContent: (
            <Row>
                <Col xs={12}>
                    <h3>Notes</h3>
                    <NoteList assetPosition={assetPosition}/>
                </Col>
            </Row>
        ),
        disabled: false,
    }
    const eventsTab = {
        tabName: "Events",
        tabPath: "events",
        tabContent: (
            <Row>
                <Col xs={12}>
                    <h3>Asset Position Events</h3>
                    <EventList
                        assetPositionId={assetPosition.assetPositionId}
                        companyId={assetPosition.companyId}
                    />
                </Col>
            </Row>
        ),
        disabled: false,
    }
    const deleteTab = {
        tabName: "Delete",
        tabPath: "delete",
        tabContent: (
            <AssetPositionDeleteTab
                assetPosition={assetPosition}
                // refresh={loadAssetPosition}
                delete={deleteAssetPosition}
                title={"Asset Position"}
            />
        ),
        userTypes: "admin",
        disabled: false,
    }
    const getTabs = (): Tab[] => {
        switch (assetPosition.assetPositionType) {
            case AssetType.Gateway:
                return assetPosition.assetId? [
                    ownedAssetPositionsTab,
                    uploadAssetRegisterTab,
                    configuration,
                    assetHistoryTab,
                    notesTab,
                    eventsTab,
                    deleteTab,
                ] : [
                    assetHistoryTab,
                    ownedAssetPositionsTab,
                    uploadAssetRegisterTab,
                    configuration,
                    notesTab,
                    eventsTab,
                    deleteTab,
                ]
            case AssetType.WirelessCluster:
                return [
                    clusterGatewayTab,
                    clusterAddGatewaysTab,
                    clusterReceiverAssignmentTab,
                    ownedAssetPositionsTab,
                    uploadAssetRegisterTab,
                    configuration,
                    notesTab,
                    eventsTab,
                    deleteTab,
                ]
            default:
                return [
                    assetHistoryTab,
                    notesTab,
                    eventsTab,
                    deleteTab
                ]
        }
    }

    return (
        <OneCardPage
            headerTitle={
                wireless_cluster ? "Wireless Cluster Homepage" : "Asset Position Detail"
            }
            breadcrumbs={
                assetPosition &&
                location && [
                    locationToBreadcrumb(location),
                    assetPositionToBreadcrumb(assetPosition),
                ]
            }
            loading={loading}
        >
            {assetPosition && location && (
                <>
                    <Row>
                        <AssetPositionPreview
                            assetPosition={assetPosition}
                            asset={asset}
                            location={location}
                            refresh={refresh}
                        />
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <RoutedTabGroupInterface
                                tabs={getTabs()}
                                topLevelPath={`/admin/asset_position/${assetPosition.assetPositionId}/:tab`}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </OneCardPage>
    );
};

export default AssetPositionDisplay;
