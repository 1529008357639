import React, {useState} from "react";
import DisplayIfUserType from "../utils/DisplayIfUserType/DisplayIfUserType";
import {Tab, TabGroupInterfaceProps} from "./TabGroupInterface";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle,} from "reactstrap";

export default function TabSwitchSelector(
  props: TabGroupInterfaceProps
): JSX.Element {
  const { liveTab } = props;

  const [open, setOpen] = useState(false);

  const toggle = (): void => {
    setOpen(!open);
  };


  return (
    <>
      <Dropdown isOpen={open} toggle={toggle} className={""}>
        <DropdownToggle
          caret
          size={"sm"}
          className={"px-4 py-2"}
          color={"primary"}
        >
          {props.liveTab?.tabName || "-"}
        </DropdownToggle>
        <DropdownMenu right={true} data-cy={"tab-interface"}>
          {props.tabs.map((tab: Tab, index) => {
            return (
              <DisplayIfUserType
                userTypes={tab.userTypes || ["admin", "editor", "viewer"]}
                key={index}
              >
                <DropdownItem
                  onClick={() => props.switchTab(tab)}
                  data-cy={`tab-${tab.tabName}`}
                  active={tab.tabPath === liveTab?.tabPath}
                >
                  {tab.tabName}
                </DropdownItem>
              </DisplayIfUserType>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    </>
  );
}
