import React from "react";
import {
  ColumnDefinitionWithData,
  ColumnMap,
  TimeSeriesDataParams,
} from "../../../model/deviceTimeSeries";
import { Moment } from "moment";
import { DeviceAsset } from "../../../openapi/model/deviceAsset";
import { GatewayAsset } from "../../../openapi/model/gatewayAsset";
import AssetDataSearchStringParser from "./AssetDataSearchStringParser";
import AssetTimeSeriesDataGetter from "./AssetTimeSeriesDataGetter";

export type DeviceTimeSeriesDisplayComponentProps<
  T = string | undefined,
  D extends ColumnDefinitionWithData<T> = ColumnDefinitionWithData<T>
> = {
  mappedTimeSeriesColumns: ColumnMap<T, D>;
  times: Moment[];
  params: TimeSeriesDataParams;
  loading: boolean;
  error?: Error;
};

export type DeviceTimeSeriesDisplayComponent<
  T = string | undefined,
  D extends ColumnDefinitionWithData<T> = ColumnDefinitionWithData<T>
> = (props: DeviceTimeSeriesDisplayComponentProps<T, D>) => JSX.Element;

interface Props<
  T = string | undefined,
  D extends ColumnDefinitionWithData<T> = ColumnDefinitionWithData<T>
> {
  device: DeviceAsset | GatewayAsset;
  render: DeviceTimeSeriesDisplayComponent<T, D>;
}

const DeviceTimeSeriesContainer = (props: Props): JSX.Element => {
  return (
    <AssetDataSearchStringParser device={props.device}>
      {({ searchParams }) => {
        return (
          <AssetTimeSeriesDataGetter
            device={props.device}
            searchParams={searchParams}
            render={props.render}
          />
        );
      }}
    </AssetDataSearchStringParser>
  );
};

export default DeviceTimeSeriesContainer;
