import { API } from "aws-amplify";
import { getAuthHeader } from "./auth";

export const loadFileFromApi = async (url: string): Promise<void> => {
  return API.get("management", url, await _getHeaders()).then((data) => {
    window.open(data, "_blank");
  });
};
const _getHeaders = async (): Promise<any> => {
  return {
    headers: await getAuthHeader(),
    responseType: "text",
  };
};
