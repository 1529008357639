import {LocationGroup} from "../../openapi/model/locationGroup";
import {Option} from "../Forms/SelectComponent";

export function flattenNestedLists(arr: Option[][]): Option[] {
    return arr.reduce((r: Option[], i: Option[]) => [...r, ...i], []);
}

function locationGroupToOptions(group: LocationGroup, level: number): Option[] {
    const thisLevel = {
        value: group.group,
        label: group.group,
    };
    if (group.children) {
        return [
            thisLevel,
            ...locationGroupListToOptions(group.children, level + 1),
        ];
    } else {
        return [thisLevel];
    }
}

export function locationGroupListToOptions(
    locationGroups: LocationGroup[],
    level: number
): Option[] {
    return flattenNestedLists(
        locationGroups.map((value) => {
            return locationGroupToOptions(value, level);
        })
    );
}