import React from "react";
import { useParams } from "react-router-dom";
import { SchemeElementProps } from "../../components/Scheme/SelectedScheme";
import GetAssetRegisterEntitiesCombination from "../../core/action/GetAssetRegisterEntitiesCombination";
import { OneCardPage } from "../../components/utils/OneCardPage";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import AssetDisplay from "./AssetDisplay";
import { NotFoundErrorForEntity } from "../../model/error";

const AssetHomepage = (props: SchemeElementProps): JSX.Element => {
  const { assetId } = useParams<{ assetId: string }>();
  if (assetId === undefined) {
    throw new Error("assetId must be set");
  }

  return (
    <GetAssetRegisterEntitiesCombination
      assetId={assetId}
      error={({ error }) => (
        <OneCardPage
          headerTitle={"Asset Detail"}
          breadcrumbs={[{ name: "Error" }]}
        >
          <ErrorBanner error={error} />
        </OneCardPage>
      )}
      placeholder={() => (
        <OneCardPage
          headerTitle={"Asset Detail"}
          loading={true}
          breadcrumbs={[{ name: "Loading" }]}
        >
          <></>
        </OneCardPage>
      )}
      {...props}
    >
      {({ asset, ...rest }) =>
        asset ? (
          <AssetDisplay {...rest} asset={asset} />
        ) : (
          <OneCardPage
            headerTitle={"Asset Detail"}
            breadcrumbs={[{ name: "Error" }]}
          >
            <ErrorBanner error={NotFoundErrorForEntity("ASSET", assetId)} />
          </OneCardPage>
        )
      }
    </GetAssetRegisterEntitiesCombination>
  );
};

export default AssetHomepage;
