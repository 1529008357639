import React from "react";
import {Option,} from "../../../components/Forms/SelectComponent";
import {LabellingCases} from "../../../openapi/model/labellingCases";
import {SelectCombo, SelectComboProps} from "../../../components/Forms/SelectCombo/SelectCombo";

export default function HeaderLabelCaseSelect(
  props: Omit<SelectComboProps,'options'>): JSX.Element {

  const options: Option[] = [
    { label: "Title Case", value: LabellingCases.TITLECASE },
    { label: "snake_case", value: LabellingCases.SNAKECASE },
    { label: "camelCase", value: LabellingCases.CAMELCASE },
  ];

  return (
    <SelectCombo
      options={options}
      {...props}
    />
  );
}
