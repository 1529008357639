import React, { useState } from "react";
import { Asset } from "../../openapi/model/asset";
import CompanySelectDropdown from "containers/company/CompanySelectDropdown";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Button } from "reactstrap";
import AssetActionConfirmModal from "../assetActions/AssetActionConfirmModal";
import { AssetAction } from "../../openapi/model/assetAction";
import { useNavigate, useLocation } from "react-router-dom";

interface FormValues {
  companyId: string;
}

interface Props {
  asset: Asset;
}

const AssetCompanyAssignForm = (props: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState<AssetAction | undefined>(undefined);
  const [complete, setComplete] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const openModal = (): void => {
    setOpen(true);
  };

  const closeModal = (): void => {
    setOpen(false);
  };

  const submit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ): void => {
    setSubmitting(true);
    setAction({
      actionType: "ASSIGN_TO_COMPANY",
      payload: { companyId: values.companyId },
    });
    openModal();
  };

  const onComplete = (): void => {
    navigate({ pathname: "/admin/asset", hash: location.hash });
  };

  const initialValues = {
    companyId: "",
  };
  return (
    <>
      <Formik
        onSubmit={submit}
        initialValues={initialValues}
        validateOnMount={true}
        validationSchema={Yup.object().shape({
          companyId: Yup.string()
            .required("Required")
            .notOneOf(["undefined", ""]),
        })}
        render={({
          isValid,
          isSubmitting,
          resetForm,
          setSubmitting,
        }): JSX.Element => (
          <Form data-cy={"assign-asset-form"}>
            <div className={"row"}>
              <div className={"col-12"}>
                <CompanySelectDropdown
                  fieldName={"companyId"}
                  allowNone={true}
                />
              </div>
              <div className={"col-12"}>
                <div className={"form-group row"}>
                  <div className={"col-12"}>
                    <Button
                      type={"submit"}
                      color={"primary"}
                      disabled={isSubmitting || !isValid}
                    >
                      Assign
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <AssetActionConfirmModal
              show={open}
              asset={props.asset}
              action={action}
              close={closeModal}
              description={"Assign Asset"}
              onComplete={(): void => {
                resetForm();
                setSubmitting(false);
                setComplete(true);
              }}
              onClose={(): void => {
                setSubmitting(false);
                if (complete) {
                  onComplete();
                }
              }}
            />
          </Form>
        )}
      />
    </>
  );
};

export default AssetCompanyAssignForm;
