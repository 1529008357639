import React from "react";
import { Account } from "../../openapi/model/account";
import { NewAccount } from "../../openapi/model/newAccount";
import { Location } from "../../openapi/model/location";
import { CreateEntity } from "../../hooks/createEntity";
import { Form, Formik, FormikHelpers } from "formik";
import TextFormComponent from "../../components/Forms/TextFormComponent";
import { Button, Col } from "reactstrap";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import * as Yup from "yup";
import { yupRequiredString } from "../../components/Forms/yupValidators";
import SingleDatePickerFormInput from "../../components/Forms/SingleDatePickerFormInput";
import moment from "moment";
import {ErrorBanner} from "../../components/Error/ErrorBanner";

interface FormValues extends Omit<NewAccount, "companyId" | "schemeId" | "locationId" | "openingDate" > {
    openingDate: moment.Moment
}

const NewAccountSchema = Yup.object().shape({
  accountReference: yupRequiredString,
  openingDate: Yup.date().required(),
});

function NewAccountForm(props: {
  location: Location;
  create: CreateEntity<Account, NewAccount>;
}) {
  const { create, location } = props;

  const initialValues: FormValues = {
    accountReference: "",
    openingDate: moment().startOf('day'),
  };
  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ): void => {
    setSubmitting(true);
    create
      .createEntity({
        ...values,
          openingDate: values.openingDate.toISOString(true).slice(0,10),
        companyId: location.companyId,
        schemeId: location.schemeId,
        locationId: location.locationId,
      })
      .finally(() => {
        setSubmitting(false);
        resetForm();
      });
  };
  return (
    <DisplayIfUserType userTypes={"superadmin"}>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={NewAccountSchema}
      >
        {({ submitForm, isSubmitting, isValid }) => (
          <Form>
            <TextFormComponent
              fieldName={"accountReference"}
              label={"Account Reference"}
              submitSpinner={false}
              paddingX={0}
            />
            <SingleDatePickerFormInput
              name={"openingDate"}
              showPresets={false}
              label={"Opening Date"}
              timeZone={'Europe/London'}
             />
            <Col xs={12}>
              <div className={"form-group row"}>
                <Button
                  color={"primary"}
                  onClick={submitForm}
                  disabled={isSubmitting || !isValid}
                >
                  Create Account
                </Button>
              </div>
            </Col>
          </Form>
        )}
      </Formik>
        <ErrorBanner error={create.error}/>
    </DisplayIfUserType>
  );
}

export default NewAccountForm;
