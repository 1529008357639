import React from 'react'
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import ChangePasswordForm from "./ChangePasswordForm";


export default class AuthenticationCard extends React.Component {
    render(): JSX.Element {
        return <Card className="shadow">
            <CardHeader className="bg-default border-0">
                <h3 className="text-white mb-0">Authentication</h3>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md="4">
                        <h3>
                            Password
                        </h3>
                        <p>
                            After a successful password update, you will be redirected to the login page where you can
                            log in with your new password.
                        </p>
                    </Col>
                    <Col md="8">
                        <ChangePasswordForm/>
                    </Col>
                </Row>
            </CardBody>
        </Card>

    }
}

