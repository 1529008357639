import React from "react";
import ReportExecutionList from "./executions/ReportExecutionList";
import ReportExecutionCreator from "./executions/ReportExecutionCreator";
import MultipleCardPage from "../../components/utils/MutlipleCardPage";
import MeterpointCard from "../../components/utils/Cards/MeterpointCard";
import CreateAndGetListCombo from "../../components/CreateAndGetListCombo";
import { useSelectedCompany } from "../../reducers/company";
import { useSelectedScheme } from "../../reducers/scheme";
import { OutputType } from "../../openapi/model/outputType";
import _ from "lodash";

function BillingReportPage() {
  const company = useSelectedCompany();
  const scheme = useSelectedScheme();

  if (_.isUndefined(company)) {
    throw new Error("Company must be selected");
  }
  return (
    <CreateAndGetListCombo
      entityType={"REPORT_EXECUTION"}
      listParams={{
        companyId: company?.companyId,
        schemeId: scheme?.schemeId,
        format: [OutputType.BILLING],
      }}
      cursor={{ maxItems: 15 }}
    >
      {({ create, createError, creating, list }) => (
        <MultipleCardPage>
          <MeterpointCard headerTitle={"Execute Billing Report"}>
            <ReportExecutionCreator
              scheme={scheme}
              company={company}
              {...{ create, createError, creating }}
              outputTypeFilter={[OutputType.BILLING]}
            />
          </MeterpointCard>
          <MeterpointCard headerTitle={"Previous Executions"}>
            <ReportExecutionList list={list} />
          </MeterpointCard>
        </MultipleCardPage>
      )}
    </CreateAndGetListCombo>
  );
}

export default BillingReportPage;
