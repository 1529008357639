import React from "react";
import { Event } from "../../openapi/model/event";
import { formatDate } from "../../components/utils/HighlightNullAndOldDates";
import { ListGroupItem } from "reactstrap";
import { AssetAlarmEvent } from "../../openapi/model/assetAlarmEvent";
import GetListPaginatedList from "../../components/Pagination/GetListPaginatedList";
import useUserHasRoles from "../../hooks/user/getCurrentUserRole";
import { AlarmTypes } from "../../openapi/model/alarmTypes";

interface Props {
  companyId: string;
  schemeId?: string;
  assetId?: string;
  assetPositionId?: string;
  locationId?: string;
  openAlarmsOnly?: boolean;
  list?: boolean;
  lastUpdated?: string;
  row?: (value: AssetAlarmEvent, index: number) => JSX.Element;
  showPagination?: boolean;
  maxDisplay?: number;
  noDataRow?: JSX.Element;
}

const AlarmList = (props: Props): JSX.Element => {
  const filterAlarms = !useUserHasRoles("superadmin", [], false);

  const row = props.row
    ? props.row
    : (value: Event, index: number): JSX.Element => (
        <ListGroupItem key={index}>
          {formatDate(value.eventDateTime)}: {value.eventType}
        </ListGroupItem>
      );

  return (
    <GetListPaginatedList
      row={row}
      entityType={"ALARM"}
      params={{
        companyId: props.companyId,
        schemeId: props.schemeId,
        assetId: props.assetId,
        assetPositionId: props.assetPositionId,
        locationId: props.locationId,
        openAlarmsOnly: props.openAlarmsOnly || false,
        alarmTypes: filterAlarms ? [AlarmTypes.OFFLINE] : undefined,
      }}
      cursor={{ maxItems: 100, direction: "desc" }}
      tableClassName={"list-group-flush"}
      showPagination={props.showPagination}
      noDataRow={props.noDataRow}
    />
  );
};

export default AlarmList;
