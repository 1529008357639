import React from "react";
import CompanyNavLink from "../navigation/CompanyNavLink";
import { Moment } from "moment";
import { createSearchString } from "components/navigation/SearchString";

const AssetRowLink = (props: {
  assetId?: string;
  startDatetime?: Moment;
  endDatetime?: Moment;
  children: React.ReactNode;
}): JSX.Element => {
  const { assetId, startDatetime, endDatetime } = props;

  const link =
    startDatetime || endDatetime
      ? `/admin/asset/${assetId}?${createSearchString({
          startDatetime: startDatetime?.toISOString(true),
          endDatetime: endDatetime?.toISOString(true),
        })}`
      : `/admin/asset/${assetId}`;

  return (
    <>
      {assetId && (
        <CompanyNavLink to={link} className={"px-0"} external={true} inline>
            {props.children}
        </CompanyNavLink>
      )}
    </>
  );
};

export default AssetRowLink;
