import React from 'react';
import {Alert} from "reactstrap";
import {BulkUpdatePreviewStats} from "../../openapi/model/bulkUpdatePreviewStats";

interface Props {
    previewStats: BulkUpdatePreviewStats;
}

const GenericUpdateErrorsBanner = (props: Props): JSX.Element => {
    const previewErrors = (props.previewStats.newInvalid ?? 0) + (props.previewStats.updateInvalid ?? 0);


    if (previewErrors > 0) {
        return <Alert color={'warning'}>
            <p>This update has {previewErrors} error{previewErrors > 1 ? 's. These are' : '. It is'} highlighted
                below.</p>
            <p className={'mb-0'}>
                The update can still be executed but {previewErrors > 1 ? 'these lines' : 'this line'} will be
                ignored.
            </p>
        </Alert>

    } else {
        return <></>;
    }
}

export default GenericUpdateErrorsBanner;