import { ErrorMessage, Field } from "formik";
import React from "react";
import { FormFieldProps } from "./LabelWrapper";
import "./H2InputLinkComponent.scss";
import classNames from "classnames";

interface Props extends FormFieldProps {
  to?: string;
  fieldName: string;
  errors: any;
  touched: any;
  values: any;
  editing?: boolean;
  disabled?: boolean;
  innerRef?: any;
}

const H2InputLinkComponent = (props: Props): JSX.Element => {
  const { disabled = false } = { ...props };
  const errors = Object.prototype.hasOwnProperty.call(
    props.errors,
    props.fieldName
  )
    ? props.errors[props.fieldName]
    : false;
  const touched = Object.prototype.hasOwnProperty.call(
    props.touched,
    props.fieldName
  )
    ? props.touched[props.fieldName]
    : false;
  return (
    <>
      <Field
        name={props.fieldName}
        type={"text"}
        className={classNames({
          "form-control h2": true,
          "is-invalid": errors && touched,
          display: !props.editing,
        })}
        innerRef={props.innerRef}
        disabled={disabled}
      />

      <ErrorMessage
        name={props.fieldName}
        component="div"
        className="invalid-feedback"
      />
    </>
  );
};

export default H2InputLinkComponent;
