import React from "react";
import { Col, Row } from "reactstrap";
import TabSwitchButtonGroup from "./TabSwitchButtonGroup";
import TabSwitchSelector from "./TabSwitchSelector";

export interface Tab {
  tabName: string;
  tabPath: string;
  tabContent: JSX.Element;
  userTypes?: string[] | string;
  keepSearchString?: boolean;
  disabled?: boolean;
}


export interface TabGroupInterfaceProps  {
  liveTab?: Tab;
  tabs: Tab[];
  additionalButtons?: JSX.Element;
  switchTab: (tab: Tab) => void;
}

const TabGroupInterface = (props: TabGroupInterfaceProps): JSX.Element => {

  return (
    <Row>
      <Col xs={12}>
        <Row>
          <Col xs={12} className={"d-md-block d-none"}>
            <TabSwitchButtonGroup {...props} />
          </Col>
          <Col xs={12} className={"d-md-none d-block text-right px-2"}>
            <TabSwitchSelector {...props}  />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>{props.liveTab?.tabContent}</Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TabGroupInterface;
