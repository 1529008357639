import Spinner from "../../action/Spinner";
import { FaCloudDownloadAlt } from "react-icons/fa";
import React from "react";

const DownloadButtonContent = ({
  loading = false,
}: {
  loading?: boolean;
}): JSX.Element => {
  return (
    <>
      <Spinner alt={FaCloudDownloadAlt} spin={loading} className={"mr-2"} />
      Download
    </>
  );
};

export default DownloadButtonContent;
