import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { PrivacyContext } from "../../reducers/privacy";
import { selectPropertyFromLocationHash } from "../../containers/login/Login";

const PrivacyModeInit = (props: { children: React.ReactNode }): JSX.Element => {
  const [init, setInit] = React.useState(false);

  const location = useLocation();
  const { dispatch } = React.useContext(PrivacyContext);

  useEffect(() => {
    const privacyMode = selectPropertyFromLocationHash("privacy", location);
    const privacy = privacyMode ? privacyMode.toLowerCase() === "true" : false;
    if (privacy) {
      dispatch({ type: "TURN_PRIVACY_MODE_ON" });
    } else {
      dispatch({ type: "TURN_PRIVACY_MODE_OFF" });
    }
    setInit(true);
  }, [dispatch, location]);

  return <>{init && props.children}</>;
};

export default PrivacyModeInit;
