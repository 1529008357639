/*
Provides formatters to use with data tables for dealing with numeric and undefined values.
 */
import * as _ from "lodash";
import moment from "moment";
import { TableRow } from "react-data-table-component";
import { Primitive } from "type-fest";
import {SingleAggregationTimeAssetData} from "../TimeSeries/model";

export const numberFormatter = (
  valueName: string,
  numberOfDigits?: number,
  undefinedString = "-"
) => {
  return (row: TableRow): string => {
    const data: Primitive | null | undefined = row[valueName] as
      | Primitive
      | null
      | undefined;
    if (data !== null && data !== undefined) {
      if (_.isNaN(data)) {
        return undefinedString;
      } else {
        return numberOfDigits !== undefined
          ? _.toString(
              (Math.round(_.toNumber(data) * 100) / 100).toFixed(numberOfDigits)
            )
          : _.toString(data);
      }
    } else {
      return undefinedString;
    }
  };
};

export const singleAggregationTimeAssetDataSelector = (date: Date) => {
  return (row: SingleAggregationTimeAssetData) => {
    const data = row.data[moment(date).toISOString(true)];
    return data !== null && data !== undefined
      ? Number(data)
      : Number.NEGATIVE_INFINITY;
  };
};

export const singleAggregationTimeAssetDataFormatter = (
  date: Date,
  numberOfDigits?: number,
  undefinedString = "-"
) => {
  return (row: SingleAggregationTimeAssetData): string => {
    const data: Primitive | null | undefined =
      row.data[moment(date).toISOString(true)];
    if (data !== null && data !== undefined) {
      if (_.isNaN(data)) {
        return undefinedString;
      } else {
        return numberOfDigits !== undefined
          ? _.toString(
              (Math.round(_.toNumber(data) * 100) / 100).toFixed(numberOfDigits)
            )
          : _.toString(data);
      }
    } else {
      return undefinedString;
    }
  };
};

export const percentageFormatter = (number?: string | number): string => {
  const value =
    _.isNumber(number) && !_.isNaN(number)
      ? _.round(_.toNumber(number) * 100, 1)
      : "-";

  return `${value} %`;
};

export default numberFormatter;
