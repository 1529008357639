import React from "react";
import { ReportExecutionOut } from "../../../openapi/model/reportExecutionOut";
import { Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons";

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  execution: ReportExecutionOut;
  wasCalculating: boolean;
}
function ReportExecutionCalculatingBadge(props: Props): JSX.Element {
  const { execution, wasCalculating, ...rest } = props;

  switch (execution.status) {
    case "CALCULATING":
      return (
        <Badge color={"primary"} {...rest}>
          <FontAwesomeIcon icon={faSpinner} spin={true} fixedWidth={true} />
          Calculating
        </Badge>
      );
    case "COMPLETED":
      if (wasCalculating) {
        return (
          <Badge color={"success"} {...rest}>
            <FontAwesomeIcon icon={faCheck} fixedWidth={true} />
            Ready
          </Badge>
        );
      } else {
        return <></>;
      }
    case "FAILED":
      return (
        <Badge color={"danger"} {...rest}>
          <FontAwesomeIcon icon={faBan} fixedWidth={true} className={"pr-1"} />
          Failed
        </Badge>
      );
    default:
      return <></>;
  }
}

export default ReportExecutionCalculatingBadge;
