import React from "react";
import AllPeriodReportTable from "../allPeriodReportTable/NewAllPeriodReportTable";
import {AggregatedReportRequest} from "../../../openapi/model/aggregatedReportRequest";
import {AssetType} from "../../../openapi/model/assetType";
import AggregatedTimeSeriesApiWrapper from "../aggregatedTimeSeriesReport/AggregatedTimeSeriesApiWrapper";
import {
  AssetRegisterAggregatedTimeSeriesResponse
} from "../../../openapi/model/assetRegisterAggregatedTimeSeriesResponse";
import {HeatNetworkReportParams} from "./model";

interface Props {
  companyId?: string;
  schemeId?: string;
  query: HeatNetworkReportParams
}

export const SYNTHETIC_VOLUME_REPORT_AGGREGATIONS = [
  "sum_estimated_volume",
  "sum_estimated_forward_volume",
  "sum_estimated_negative_volume",
];

const VOLUME_REPORT_COLS = [
  {
    parameter: "syntheticVolume",
    aggregation: "sumEstimatedVolume",
    name: "Estimated Volume Change",
  },
  {
    parameter: "syntheticVolume",
    aggregation: "sumEstimatedForwardVolume",
    name: "Estimated Forward Volume Change",
  },
  {
    parameter: "syntheticVolume",
    aggregation: "sumEstimatedNegativeVolume",
    name: "Estimated Negative Volume Change",
  },
  {
    parameter: "volume",
    aggregation: "difference",
    name: "Recorded Volume Change",
  },
  {
    parameter: "syntheticVolume",
    aggregation: "difference",
    name: "Difference",
  },
  {
    parameter: "syntheticVolume",
    aggregation: "differencePercentage",
    name: "Difference (%)",
  },
];

const addDifferenceValues = (
  data?: AssetRegisterAggregatedTimeSeriesResponse
): AssetRegisterAggregatedTimeSeriesResponse | undefined => {
  if (data) {
    return {
      times: data.times,
      data: data.data.map((value) => {
        const { aggregations, ...asset } = value;

        const synthetic_total =
          (aggregations.syntheticVolume.sumEstimatedVolume[0] as number) || 0;
        const recorded_total =
          (aggregations.volume.difference[0] as number) || 0;
        const difference = recorded_total - synthetic_total;
        const percentage =
          recorded_total !== 0
            ? Math.abs(difference / recorded_total) * 100
            : undefined;

        return {
          ...asset,
          aggregations: {
            syntheticVolume: {
              ...aggregations.syntheticVolume,
              difference: [difference],
              differencePercentage: [percentage as number],
            },
            volume: aggregations.volume,
          },
        };
      }),
    };
  }
};

const SyntheticVolumeReportPage = (props: Props): JSX.Element => {
  const [startDatetime, endDatetime] = [props.query.startDate, props.query.endDate];

  const params = (): AggregatedReportRequest => {
    if (props.companyId && props.schemeId) {
      return {
        companyId: props.companyId,
        schemeId: props.schemeId,
        aggregations: [
          ...SYNTHETIC_VOLUME_REPORT_AGGREGATIONS.map((value) => {
            return {
              parameter: "Synthetic Volume",
              aggregation: value,
            };
          }),
          {
            parameter: "Volume",
            aggregation: "difference",
          },
        ],
        assetTypes: [AssetType.HeatMeter, AssetType.HeatingAndCoolingMeter],
        startTime: startDatetime.toDate(),
        endTime: endDatetime.toDate(),
        period: "ALL",
        useCase: props.query.useCases,
        group: props.query.group,
      };
    } else {
      throw new Error("Scheme or Company is not selected.");
    }
  };

  return (
    <>
      {props.companyId && props.schemeId && (
        <AggregatedTimeSeriesApiWrapper request={params()}>
          {({ data }) => (
            <AllPeriodReportTable
              data={addDifferenceValues(data)}
              columnDefinitions={VOLUME_REPORT_COLS}
              downloadFilename={`${props.schemeId}_synthetic_volume_report`}
              startDatetime={startDatetime}
              endDatetime={endDatetime}
            />
          )}
        </AggregatedTimeSeriesApiWrapper>
      )}
    </>
  );
};

export default SyntheticVolumeReportPage;
