import React, { useContext } from "react";
import { DropdownItem } from "reactstrap";
import DisplayIfUserType from "../utils/DisplayIfUserType/DisplayIfUserType";
import { ProfileContext } from "reducers/profile";

export default function SwitchUserType(): JSX.Element {
  const { state, dispatch } = useContext(ProfileContext);

  const toggle = () => {
    dispatch({
      type: "SWITCH_TO_VIEWER_TYPE",
      payload: !state.forceViewerMode,
    });
  };

  return (
    <DisplayIfUserType userTypes={"superadmin"} ignoreViewerModeSwitch={true}>
      <DropdownItem onClick={toggle}>
        <i className="ni ni-single-02" />

        <span>Switch to {state.forceViewerMode ? "Admin" : "Viewer"} Mode</span>
      </DropdownItem>
    </DisplayIfUserType>
  );
}
