import React from "react";
import { TariffInstanceOut } from "../../openapi/model/tariffInstanceOut";
import {
  getTariffServices,
  TariffServiceDescription,
  TariffServiceDescriptionMap,
} from "./model";
import TariffInstanceListDisplay from "./TariffInstanceListDisplay";
import useCreateEntity from "../../hooks/createEntity";
import { TariffInstanceIn } from "../../openapi/model/tariffInstanceIn";
import { TariffOut } from "../../openapi/model/tariffOut";

interface TariffInstanceWithCompany extends TariffInstanceIn {
  companyId: string;
}

function TariffInstanceList(props: { tariff: TariffOut }) {
  const { createEntity } = useCreateEntity<
    TariffInstanceOut,
    TariffInstanceWithCompany
  >("TARIFF_INSTANCE", `tariff/${props.tariff.tariffId}/instance`);

  const tariffInstances = props.tariff.instances || [];

  const [services, setServices] = React.useState<TariffServiceDescriptionMap>(
    getTariffServices(tariffInstances)
  );
  const addNewService = (
    newService: TariffServiceDescription
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (newService.serviceName in services) {
        reject("Service already exists.");
      } else {
        setServices({ ...services, [newService.serviceName]: newService });
      }
      resolve(null);
    });
  };

  const addNewInstance = (instance: TariffInstanceIn) => {
    return new Promise((resolve, reject) => {
      createEntity({ companyId: props.tariff.companyId, ...instance })
        .then(resolve)
        .catch(reject);
    });
  };

  return (
    <TariffInstanceListDisplay
      tariffInstances={tariffInstances}
      services={services}
      addNewService={addNewService}
      addNewInstance={addNewInstance}
    />
  );
}

export default TariffInstanceList;
