import React from "react";
import { useSelectedScheme } from "../../reducers/scheme";

const RequireScheme = (props: { children: React.ReactNode }): JSX.Element => {
  return (
    <>
      {useSelectedScheme() ? props.children : <h3>Please select a scheme.</h3>}
    </>
  );
};

export default RequireScheme;
