import React from "react";
import { Status } from "../../openapi/model/status";
import { Badge } from "reactstrap";
import { JobStatus } from "../../openapi/model/jobStatus";

function AssetRegisterStatusLabel(props: {
  status: Status | JobStatus;
  className?: string;
  displayActive?: boolean;
}): JSX.Element {
  const { className, status, displayActive = false } = props;

  const activeElement = (
    <>
      {" "}
      {displayActive && (
        <Badge color={"success"} className={className}>
          Active
        </Badge>
      )}
    </>
  );
  switch (status) {
    case "ACTIVE":
      return activeElement;
    case "active":
      return activeElement;
    case "DELETED":
      return (
        <Badge color={"danger"} className={className}>
          Deleted
        </Badge>
      );
    case "disabled":
      return (
        <Badge color={"danger"} className={className}>
          Disabled
        </Badge>
      );
      return <></>;
  }
}

export default AssetRegisterStatusLabel;
