import React from 'react';
import {AssetPosition} from "../../../openapi/model/assetPosition";
import {Col, Row} from "reactstrap";
import AssetPositionAssetList from "../assetPositionAssetLinks/AssetPositionAssetList";
import AssetPositionAssetLinkCreateForm from "../assetPositionAssetLinks/AssetPositionAssetLinkCreateForm";
import StateTabGroupInterface from "../../../components/TabGroup/StateTabGroupInterface";
import CreateAssetForm from "../CreateAssetForm";
import DisplayIfUserType from "../../../components/utils/DisplayIfUserType/DisplayIfUserType";

function AssetPositionAssetHistoryTab(props: {
    assetPosition: AssetPosition,
    refresh: () => void
}) {
    const {assetPosition, refresh} = props;
    return (
        <Row>
            <Col xs={12}>
                <h3>Asset Position Asset History</h3>
                <AssetPositionAssetList
                    companyId={assetPosition.companyId}
                    schemeId={assetPosition.schemeId}
                    assetPositionId={assetPosition.assetPositionId}
                    refreshList={refresh}
                />
            </Col>
            <DisplayIfUserType userTypes={'admin'}>
                <Col xs={12} lg={12}>
                    <h3>Assign Asset</h3>
                </Col>
                <Col xs={12} lg={12}>
                    <StateTabGroupInterface tabs={
                        [
                            {
                                tabName: 'Select',
                                tabPath: 'select',
                                tabContent:
                                    <AssetPositionAssetLinkCreateForm assetPosition={assetPosition}
                                                                      refresh={refresh}/>
                            },
                            {
                                tabName: 'Create',
                                tabPath: 'create',
                                tabContent: <Row>
                                    <Col xs={12} className={'py-2'}>
                                        <h4>Create Asset</h4>
                                    </Col>
                                    <Col>
                                        <CreateAssetForm
                                            assetPosition={assetPosition}
                                            refresh={refresh}
                                        />
                                    </Col>
                                </Row>
                            }

                        ]}
                    />
                </Col>
            </DisplayIfUserType>

        </Row>


    );
}

export default AssetPositionAssetHistoryTab;