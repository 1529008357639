import { Company } from "../../openapi/model/company";
import { Scheme } from "../../openapi/model/scheme";
import _ from "lodash";
import { NavigateFunction, Location } from "react-router-dom";

export const addCompanyAndSchemeToUrl = (
  location: Location,
  navigate: NavigateFunction,
  selectedCompany?: Company,
  selectedScheme?: Scheme,
  privacyMode?: boolean
): void => {
  navigate({
    ...location,
    hash: getUrlHash(selectedCompany, selectedScheme, privacyMode),
  });
};

export const getUrlHash = (
  selectedCompany?: Company | string,
  selectedScheme?: Scheme | string,
  privacyMode = false
): string => {
  const companyHash = selectedCompany
    ? `company=${
        _.isString(selectedCompany)
          ? selectedCompany
          : selectedCompany.companyId
      }`
    : "company=NONE";
  const schemeHash = selectedScheme
    ? `scheme=${
        _.isString(selectedScheme) ? selectedScheme : selectedScheme.schemeId
      }`
    : "";
  let hash;
  if (companyHash !== "" && schemeHash !== "") {
    hash = [companyHash, schemeHash].join("&");
  } else {
    if (companyHash !== "") {
      hash = companyHash;
    } else {
      hash = schemeHash;
    }
  }
  if (privacyMode) {
    const privacyModeHash = "privacy=true";

    if (hash) {
      hash = [hash, privacyModeHash].join("&");
    } else {
      hash = privacyModeHash;
    }
  }
  return hash;
};

export function parseHash(hash: string): { [key: string]: string } {
  hash = hash.substring(1);
  const params: { [key: string]: string } = {};
  hash.split("&").forEach((hk) => {
    const temp = hk.split("=");
    params[temp[0]] = temp[1];
  });
  return params;
}
