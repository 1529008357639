import React from "react";
import {Option,} from "../../../components/Forms/SelectComponent";
import {Rounding} from "../../../openapi/model/rounding";
import {SelectCombo, SelectComboProps} from "../../../components/Forms/SelectCombo/SelectCombo";

const RoundingMethodSelect = (
  props: Omit<SelectComboProps, 'options'>
): JSX.Element => {

  const options: Option[] = [
    { label: "None", value: Rounding.NONE },
    { label: "0 decimal places", value: Rounding.WHOLE },
    { label: "1 decimal place", value: Rounding.ONEDP },
    { label: "2 decimal places", value: Rounding.TWODP },
  ];

  return (
    <SelectCombo
        {...props}
      options={options}
    />
  );
};

export default RoundingMethodSelect;
