import React from 'react';
import {Option} from "../Forms/SelectComponent";
import {TimeFilter} from "../../openapi/model/timeFilter";
import {SelectCombo, SelectComboProps} from "../Forms/SelectCombo/SelectCombo";

const timeFilterOptions: Option[] = [
    {label: "All", value: TimeFilter.ALL},
    {label: '30 minute', value: TimeFilter.MINUTE30},
    {label: 'Hourly', value: TimeFilter.HOURLY},
    {label: "Daily", value: TimeFilter.DAILY},
];

type TimeFilterSelectProps = Omit<SelectComboProps, 'options' >;

function TimeFilterSelect(props: TimeFilterSelectProps) {
    return <SelectCombo
        {...props}
        options={timeFilterOptions}
    />;
}

export default TimeFilterSelect;