import React from 'react';
import {WirelessSurveyDataCollectorOut} from "../../openapi/model/wirelessSurveyDataCollectorOut";
import UpdateButton from "../../components/Buttons/UpdateButton";

function WirelessSurveyDataCollectorCloseButton(props: {
    dataCollector: WirelessSurveyDataCollectorOut,
    postUpdate?: () => any

}) {
    const {dataCollector} = props;
    return (
        <UpdateButton
            entityType={'WIRELESS_SURVEY_DATA_COLLECTOR'}
            entityId={props.dataCollector.dataCollectorId}
            updatedEntity={
            {
                ...dataCollector,
                closed: true
            }
        }
            path={`wireless_survey/${dataCollector.surveyId}/data_collector/${dataCollector.dataCollectorId}`}
            color={'warning'}
            text={'Close'}
            icon={'fa-folder-closed'}
            postUpdate={props.postUpdate}
            />
    );
}

export default WirelessSurveyDataCollectorCloseButton;