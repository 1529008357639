import React from "react";
import {OneCardPage} from "../../components/utils/OneCardPage";
import GetListPaginatedList from "../../components/Pagination/GetListPaginatedList";
import WirelessSurveyListRowInner from "./WirelessSurveyListRow";
import {useSelectedCompany} from "../../reducers/company";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import {Button} from "reactstrap";

export default function WirelessSurveyListPage(): JSX.Element {
    const company = useSelectedCompany();

  return (
    <OneCardPage
      headerTitle={"Wireless Surveys"}
      breadcrumbs={[
        {
          url: "/admin/wireless_survey",
          name: "Wireless Surveys",
        },
      ]}
    >
    <GetListPaginatedList
      entityType={"WIRELESS_SURVEY"}
      params={{companyId: company?.companyId}}
      cursor={{ maxItems: 25 }}
      data-cy={"wireless-survey-list"}
      row={(d) => <WirelessSurveyListRowInner wirelessSurvey={d}/>}
      additionalFunctionButtons={<>
          <CompanyNavLink to={'./create'}>
              <Button color={'primary'}>Create New Survey</Button>
          </CompanyNavLink>
    </>}
    />
    </OneCardPage>
  );
}
