import React from 'react';
import {WirelessSurveyOut} from "../../openapi/model/wirelessSurveyOut";
import {Col, Row} from "reactstrap";
import ObjectPropertyItem from "../../components/Headers/ObjectPropertyItem";
import PrivacyMode from "../../components/Text/PrivacyMode";
import CopyToClipboard from "../../components/utils/CopyToClipboard";
import {formatDate} from "../../components/utils/HighlightNullAndOldDates";

function WirelessSurveyHeading(props: {
    survey: WirelessSurveyOut
}) {
    const {survey} = props
    return (
        <Row>
                    <Col xs={12} md={7}>
                        <div className={"d-flex flex-wrap"}>
                            <ObjectPropertyItem title={"Survey Name"} type={"heading"}>
                                <PrivacyMode>{survey.surveyName}</PrivacyMode>
                            </ObjectPropertyItem>
                        </div>
                    </Col>
                    <Col xs={12} md={5}>
                        <div
                            className={
                                "d-flex justify-content-md-end text-sm text-md-right text-left flex-wrap"
                            }
                        >
                            <ObjectPropertyItem title={"Survey ID"}>
                                <CopyToClipboard value={survey.surveyId} slice={8}/>
                            </ObjectPropertyItem>
                            <ObjectPropertyItem title={"Created"}>
                                {formatDate(survey.createdAt, undefined, false)}
                            </ObjectPropertyItem>
                            <ObjectPropertyItem title={"Updated"}>
                                {formatDate(survey.lastUpdated, undefined, false)}
                            </ObjectPropertyItem>
                        </div>
                    </Col>
                </Row>
    );
}

export default WirelessSurveyHeading;