import React from 'react';
import {AssetRegisterEntitySuccessProps} from "../../core/action/GetAssetRegisterEntitiesCombination";
import {assetPositionToBreadcrumb} from "../../components/navigation/breadcrumbs/Breadcrumbs";
import {OneCardPage} from "../../components/utils/OneCardPage";
import {Col, Row} from "reactstrap";
import ObjectPropertyItem from "../../components/Headers/ObjectPropertyItem";
import PrivacyMode from "../../components/Text/PrivacyMode";
import AssetRegisterStatusLabel from "../../components/Labels/AssetRegisterStatusLabel";
import CopyToClipboard from "../../components/utils/CopyToClipboard";
import {formatDate} from "../../components/utils/HighlightNullAndOldDates";
import {Status} from "../../openapi/model/status";
import RoutedTabGroupInterface from "../../components/TabGroup/RoutedTabGroupInterface";
import NoteList from "../notes/NotesList";
import EventList from "../event/EventList";
import AssetPositionDeleteTab from "../assetPositions/tabs/AssetPositionDeleteTab";
import GatewayFilelist from "../assets/gateway/GatewayFilelist";
import AssetRegisterList from "../assetRegister/AssetRegisterList";

function IntegrationHomePage(props: {
    integration: AssetRegisterEntitySuccessProps,
    deleteAssetPosition: () => Promise<any>;

}) {
    const {
  assetPosition,
  location,
  loading,
} = props.integration
    const {deleteAssetPosition} = props;

      const deleted = assetPosition?.status === Status.DELETED;


    return (
    <OneCardPage
      headerTitle={
        "Integration Homepage"
      }
      breadcrumbs={
        assetPosition &&
        location && [
          assetPositionToBreadcrumb(assetPosition),
        ]
      }
      loading={loading}
    >
      {assetPosition && location && (
        <>
          <Row>
      <Col xs={12} md={7}>
        <div className={"d-flex flex-wrap"}>
          <ObjectPropertyItem title={"Integration Title"} type={"heading"}>
              <PrivacyMode>{location?.address}</PrivacyMode>
          </ObjectPropertyItem>
          <ObjectPropertyItem title={"Integration Type"} type={"heading"}>
              <PrivacyMode>{assetPosition?.configuration?.integration?.integrationType}</PrivacyMode>
          </ObjectPropertyItem>
        </div>
      </Col>
      <Col xs={12} md={5}>
        <div
          className={
            "d-flex justify-content-md-end text-sm text-md-right text-left flex-wrap"
          }
        >
          {deleted && (
            <ObjectPropertyItem title={"Status"}>
              <AssetRegisterStatusLabel status={assetPosition.status} />
            </ObjectPropertyItem>
          )}

          <ObjectPropertyItem title={"Asset Position ID"}>
            <CopyToClipboard value={assetPosition?.assetPositionId} slice={8} />
          </ObjectPropertyItem>

          <ObjectPropertyItem title={"Created"}>
            {formatDate(assetPosition?.createdAt, undefined, false)}
          </ObjectPropertyItem>
          <ObjectPropertyItem title={"Updated"}>
            {formatDate(assetPosition?.lastUpdated, undefined, false)}
          </ObjectPropertyItem>
        </div>
      </Col>
          </Row>
                      <Row>
            <Col xs={12}>
              <RoutedTabGroupInterface
                tabs=
                    {
                  [
                        {
      tabName: "Files",
      tabContent: <GatewayFilelist assetPosition={assetPosition} />,
      tabPath: "files",
      keepSearchString: false,
    },{
                      tabName: "Owned Asset Positions",
                      tabPath: "positions",
                      tabContent: (
                        <Row>
                          <Col xs={12}>
                            <h3>
                              Integration Owned Asset Positions
                            </h3>
                          </Col>
                          <Col xs={12}>
                            <AssetRegisterList
                              parentAssetPositionId={
                                assetPosition.assetPositionId
                              }
                              companyId={assetPosition.companyId}
                              schemeId={assetPosition.schemeId}
                              createAssetTags={false}
                            />
                          </Col>
                        </Row>
                      ),
                    },
                    {
                      tabName: "Notes",
                      tabPath: "note",
                      tabContent: (
                        <Row>
                          <Col xs={12}>
                            <h3>Notes</h3>
                            <NoteList assetPosition={assetPosition} />
                          </Col>
                        </Row>
                      ),
                      disabled: false,
                    },
                    {
                      tabName: "Events",
                      tabPath: "events",
                      tabContent: (
                        <Row>
                          <Col xs={12}>
                            <h3>Asset Position Events</h3>
                            <EventList
                              assetPositionId={assetPosition.assetPositionId}
                              companyId={assetPosition.companyId}
                            />
                          </Col>
                        </Row>
                      ),
                      disabled: false,
                    },
                    {
                      tabName: "Delete",
                      tabPath: "delete",
                      tabContent: (
                        <AssetPositionDeleteTab
                          assetPosition={assetPosition}
                          // refresh={loadAssetPosition}
                          delete={deleteAssetPosition}
                          title={"Asset Position"}
                        />
                      ),
                      userTypes: "admin",
                      disabled: false,
                    },
                  ]
                }
                topLevelPath={`/admin/asset_position/${assetPosition.assetPositionId}/:tab`}

              />
            </Col>
          </Row>

        </>
      )
      }</OneCardPage>

    );
}

export default IntegrationHomePage;