import {ErrorMessage, Field, useField} from "formik";
import React from "react";
import LabelWrapper, { FormFieldProps } from "./LabelWrapper";
import classNames from "classnames";

interface Props extends FormFieldProps, React.HTMLProps<HTMLInputElement> {
  fieldName: string;
  type?: string;
  ref?: any;
  disabled?: boolean;
  infoText?: string;
  style?: React.CSSProperties;
  as?: string;
  inputClassName?: string;
}

export default function FormComponent(props: Props): JSX.Element {
  const { ref, fieldName, disabled = false, ...rest } = { ...props };

  const [_, {touched, error}] = useField(fieldName);

  return (
    <LabelWrapper {...props}>
      <Field
        name={fieldName}
        id={fieldName}
        className={classNames(
          "form-control",
          { "is-invalid": error && touched },
          props.inputClassName
        )}
        innerRef={ref}
        disabled={disabled}
        {...rest}
      />
      <ErrorMessage
        name={fieldName}
        component="div"
        className="invalid-feedback"
      />
    </LabelWrapper>
  );
}
