import moment, {Moment} from "moment";
import {StreamingSurveyDataRow} from "../../containers/wirelessSurvey/WirelessSurveyLiveData";
import {isNil} from "lodash";
import {WirelessSurveyDataCollectorOut} from "../../openapi/model/wirelessSurveyDataCollectorOut";

export class WirelessSurveyDataPoint {
    dataCollectorId: string;
    sampleId: string;
    timestamp: Moment;
    secondaryAddress: string;
    serialNumber: string;
    manufacturer: string;
    medium: string;
    version: string;
    rssi: string;
    data: string;

    constructor(
        dataCollectorId: string,
        sampleId: string,
        timestamp: Moment,
        secondaryAddress: string,
        manufacturer: string,
        serialNumber: string,
        medium: string,
        version: string,
        rssi: string,
        data: string,
    ) {
        this.dataCollectorId = dataCollectorId;
        this.sampleId = sampleId;
        this.timestamp = timestamp;
        this.serialNumber = serialNumber;
        this.medium = medium;
        this.manufacturer = manufacturer;
        this.version = version;
        this.rssi = rssi;
        this.data = data;
        this.secondaryAddress = secondaryAddress;
    }

    age() {
        return (moment().diff(this.timestamp, 'seconds'))
    }

}


export function wirelessSurveyDataPointFromStreamingSurveyDataRow(row: StreamingSurveyDataRow): WirelessSurveyDataPoint {
    return new WirelessSurveyDataPoint(
        row[0],
        row[1],
        moment(row[3]),
        `${row[5]}${row[6]}${row[7]}${row[8]}`,
        row[5],
        row[8],
        row[6],
        row[7],
        row[9],
        row[4]
    )
}


export function parseWebsocketMessage(message: string | null): WirelessSurveyDataPoint[] {
    if (isNil(message) || message === 'null') {
        return []
    } else {
        return JSON.parse(message).map(wirelessSurveyDataPointFromStreamingSurveyDataRow)
    }
}

export function handleReceivingNewStreamingSurveyDataRowMessage(
    newRows: WirelessSurveyDataPoint[],
    currentData: WirelessSurveyDataPoint[]
): WirelessSurveyDataPoint[] {

    if ((newRows.length) === 0) {
        return currentData
    }
    if ((newRows.length === 1) && (newRows[0].timestamp.unix() > currentData[0]?.timestamp.unix())) {
        return [...newRows, ...currentData] /* no sort required, already in order */
    } else {
        return [...newRows, ...currentData].sort(sortWirelessSurveyDataPoint)
    }
}


function sortWirelessSurveyDataPoint(a: WirelessSurveyDataPoint, b: WirelessSurveyDataPoint) {
    if (a.timestamp < b.timestamp) {
        return 1;
    }
    if (a.timestamp > b.timestamp) {
        return -1;
    }
    return 0
}

export interface WirelessDataSurveyMapItem {
    serialNumber: string;
    manufacturer: string;
    medium: string;
    version: string;
    dataCollectorDataMap: Map<string, WirelessSurveyDataPoint[]>;
    dataCollectorSamplePointMap: Map<string, WirelessSurveyDataPoint[]>;

}

export type WirelessDataSurveyMap = Map<string, WirelessDataSurveyMapItem>;

export type DataCollectorMap = Map<string, WirelessSurveyDataCollectorOut>;

export function groupWirelessSurveyDataByDataCollector(
    newRows: WirelessSurveyDataPoint[],
    currentMap: WirelessDataSurveyMap,
    dataCollectors: DataCollectorMap = new Map()
): WirelessDataSurveyMap {
    return newRows.reduce((accumulator, v) => {
            const newMap = new Map(accumulator.entries())
            const newDataCollectorDataMap = new Map(
                (
                    accumulator.get(v.secondaryAddress) || {
                        serialNumber: v.serialNumber,
                        manufacturer: v.manufacturer,
                        medium: v.medium,
                        version: v.version,
                        dataCollectorDataMap: new Map(),
                        dataCollectorSamplePointMap: new Map(),
                    }
                ).dataCollectorDataMap
            )

            const newDataCollectorSamplePointMap = new Map(
                (
                    accumulator.get(v.secondaryAddress) || {
                        serialNumber: v.serialNumber,
                        manufacturer: v.manufacturer,
                        medium: v.medium,
                        version: v.version,
                        dataCollectorDataMap: new Map(),
                        dataCollectorSamplePointMap: new Map(),
                    }
                ).dataCollectorSamplePointMap
            )

            const samplePointName = identifySamplePointForData(v, dataCollectors)
            newMap.set(
                v.secondaryAddress,
                {
                    serialNumber: v.serialNumber,
                    medium: v.medium,
                    manufacturer: v.manufacturer,
                    version: v.version,
                    dataCollectorDataMap: newDataCollectorDataMap.set(v.dataCollectorId, [...(newDataCollectorDataMap.get(v.dataCollectorId) || []), v]),
                    dataCollectorSamplePointMap: newDataCollectorSamplePointMap.set(samplePointName, [...(newDataCollectorSamplePointMap.get(samplePointName) || []), v]),
                }
            )
            return newMap
        }, currentMap
    )
}

export function identifySamplePointForData(data: WirelessSurveyDataPoint, dataCollectors: DataCollectorMap): string {
    const dataCollector = dataCollectors.get(data.dataCollectorId)
    if (dataCollector && dataCollector.samplePoints) {
        const filtered = dataCollector.samplePoints.filter(
            (v) => {
                return (
                    moment(v.startDateTime) <= data.timestamp
                ) && (
                    isNil(v.endDateTime) || (moment(v.endDateTime) > data.timestamp)
                )
            }
        )
        if (filtered.length > 0) {
            return `${data.dataCollectorId}-${filtered[0].samplePointName}`
        }
    }
    return `${data.dataCollectorId}-NONE`

}


