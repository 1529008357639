import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Tooltip } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import CompanyNavLink from "components/navigation/CompanyNavLink";
import PrivacyMode from "../Text/PrivacyMode";

const TextWithClipboard = (
  props: {
    value?: string;
    slice?: number;
    link?: string;
    className?: string;
    privacyMode?: boolean;
    children?: JSX.Element;

  } & React.HTMLAttributes<HTMLSpanElement> & {
      dataCy?: string;
    }
): JSX.Element => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [id, setId] = useState("");
  if (id === "") {
    setId(`c-${uuidv4().slice(0, 7)}`);
  }

  const open = (): void => {
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 2500);
  };

  const { value, slice, link, className, ...rest } = props;

  const text = slice ? value?.slice(0, slice) + "..." : value;

  const display = props.privacyMode ? <PrivacyMode>{text}</PrivacyMode> : text;

  return (
    <span {...rest} className={className}>
      {link !== undefined ? (
        <CompanyNavLink className={className} to={link}>
          {display}
        </CompanyNavLink>
      ) : !props.children && <>{display}</>
      }
      <CopyToClipboard text={value || ""} onCopy={(): void => open()}>

        <a id={id}>
            {props.children ? props.children :
          <i className={"far fa-fw fa-clipboard px-2"} />
            }
          <Tooltip target={id} placement={"top"} isOpen={tooltipOpen}>
            Copied!
          </Tooltip>
        </a>

      </CopyToClipboard>
    </span>
  );
};

export default TextWithClipboard;
