import React from "react";
import { Routes } from "react-router-dom";

import AssetPositionListPage from "./AssetPositionListPage";
import AssetPositionHomepage from "./AssetPositionHomepage";
import GenericUpdatesPage from "../../components/BulkUpdate/GenericUpdatesPage";
import BulkUpdateList from "../../components/BulkUpdate/BulkUpdateList";
import { SentryRoute } from "../../App";
import SelectedScheme from "../../components/Scheme/SelectedScheme";

const AssetPositionPage = (): JSX.Element => {
  return (
    <Routes>
      <SentryRoute path="/" element={<AssetPositionListPage />} />
      <SentryRoute
        path="/updates"
        element={
          <SelectedScheme>
            {(props) => (
              <BulkUpdateList {...props} objectType={"AssetPosition"} />
            )}
          </SelectedScheme>
        }
      />
      <SentryRoute
        path={"/updates/:updateId"}
        element={
          <SelectedScheme>
            {(props) => (
              <GenericUpdatesPage
                {...props}
                title={"Asset Positions"}
                header={"Updated Asset Positions"}
                tableFields={[
                  "companyId",
                  "schemeId",
                  "locationId",
                  "assetPositionId",
                  "assetId",
                  "customerReference",
                  "assetPositionType",
                  "receiverPositionIds",
                ]}
                tableFieldHeaders={[
                  "Company ID",
                  "Scheme ID",
                  "Location ID",
                  "Asset Position Id",
                  "Asset Id",
                  "Customer Reference",
                  "Type",
                ]}
                objectKey={"assetPositionId"}
              />
            )}
          </SelectedScheme>
        }
      />
      <SentryRoute
        path=":assetPositionId"
        element={
          <SelectedScheme>
            {(props) => <AssetPositionHomepage {...props} />}
          </SelectedScheme>
        }
      >
        <SentryRoute
          path=":tab"
          element={
            <SelectedScheme>
              {(props) => <AssetPositionHomepage {...props} />}
            </SelectedScheme>
          }
        />
      </SentryRoute>
    </Routes>
  );
};

export default AssetPositionPage;
