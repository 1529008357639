import React from "react";
import { OneCardPage } from "../../components/utils/OneCardPage";
import EventList from "./EventList";
import SelectedScheme from "../../components/Scheme/SelectedScheme";

const EventListPage = (): JSX.Element => {
  return (
    <OneCardPage
      headerTitle={"Event List"}
      breadcrumbs={[
        { url: "/admin/event", name: "Events" },
        { url: "/admin/event", name: "List" },
      ]}
    >
      <SelectedScheme>{(props) => <EventList {...props} />}</SelectedScheme>
    </OneCardPage>
  );
};

export default EventListPage;
