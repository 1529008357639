import React from "react";
import useUpdateEntity, { UpdateEntityProps } from "../../hooks/updateEntity";
import { Button } from "reactstrap";
import classNames from "classnames";
import ErrorModal from "../Error/ErrorModal";

export interface CommonButtonProps {
  text?: string;
  icon?: string;
  size?: string;
  disabled?: boolean;
}

interface UpdateButtonProps extends CommonButtonProps {
  errorMessageBody?: JSX.Element;
  updatedEntity: any;
  color?: string;
}

const UpdateButton = (
  props: UpdateEntityProps & UpdateButtonProps
): JSX.Element => {
  const { updating, updateEntity, error } = useUpdateEntity(
    props.entityType,
    props.entityId,
    props.postUpdate,
      props.path
  );

  const { text = "", icon = "", color,size='sm' } = props;

  return (
    <>
      <ErrorModal error={error} body={props.errorMessageBody} />
      <Button
        size={size}
        color={color}
        outline={false}
        onClick={() => updateEntity(props.updatedEntity)}
        disabled={updating || props.disabled}
      >
        <i
          className={classNames({
            "fas fa-fw": true,
            [icon]: !updating,
            "fa-cog fa-spinner fa-spin": updating,
          })}
        />
        {text}
      </Button>
    </>
  );
};

export default UpdateButton;
