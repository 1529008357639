import React from "react";
import MultipleChart from "../../../components/charts/MultipleChart";
import moment, { Moment } from "moment";

import {SingleAggregationTimeAssetData} from "../../../components/TimeSeries/model";

const TimeSeriesReportGraph = (props: {
  times: Array<Date>;
  data: Array<SingleAggregationTimeAssetData>;
  startTime: Moment;
  endTime: Moment;
}): JSX.Element => {
  return (
    <MultipleChart
      xaxis={props.times.map((d) => moment(d))}
      data={props.data}
      startTime={props.startTime}
      endTime={props.endTime}
    />
  );
};

export default TimeSeriesReportGraph;
