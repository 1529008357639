import React from "react";
import {AssetPosition} from "../../../openapi/model/assetPosition";
import GetAssetRegisterEntitiesCombination from "../../../core/action/GetAssetRegisterEntitiesCombination";
import ObjectPropertyItem from "../../../components/Headers/ObjectPropertyItem";
import AssetPositionIdLink from "../../../components/AssetPosition/AssetPositionIdLink";
import AssetIdLink from "../../../components/Asset/AssetIdLink";

const GatewayLink = (props: {
  deviceAssetPosition?: AssetPosition;
}): JSX.Element => {
  const { deviceAssetPosition } = props;
  return (
    <>
      {deviceAssetPosition && deviceAssetPosition.parentAssetPositionId ? (
        <GetAssetRegisterEntitiesCombination
          assetPositionId={deviceAssetPosition.parentAssetPositionId}
          companyId={deviceAssetPosition.companyId}
          schemeId={deviceAssetPosition.schemeId}
          placeholder={() => (
                                                    <ObjectPropertyItem title={"Parent"}>
            <i className="fa fa-spinner fa-spin" aria-hidden="true" />
                                </ObjectPropertyItem>

          )}
          error={() => <></>}
        >
          {({ asset, assetPosition, location }) => (
            <>
              {
                assetPosition && assetPosition.assetPositionType === "wireless_cluster" ? (
                                <ObjectPropertyItem title={"Wireless Cluster"}>
                                  <AssetPositionIdLink assetPosition={assetPosition} text={location?.address}/>
                                </ObjectPropertyItem>

                ) :
                asset ? (
                                                    <ObjectPropertyItem title={"Gateway"}>
                                                      <AssetIdLink asset={asset} text={asset.serialNumber} />
                                </ObjectPropertyItem>
              ) : (
                                                    <ObjectPropertyItem title={"Gateway"}>
                                                      None
                                </ObjectPropertyItem>
              )}
            </>
          )}
        </GetAssetRegisterEntitiesCombination>
      ) : (
        <>-</>
      )}
    </>
  );
};

export default GatewayLink;
