import React from 'react';
import {
    LAST_28_DAYS,
    LAST_3_CALENDAR_MONTHS,
    LAST_CALENDAR_MONTH,
    LAST_CALENDAR_WEEK,
    Preset
} from "../DateRangePicker/DateRangePickerPresets";
import {Moment} from "moment";
import {RangeDate} from "@psd-platform/monday-ui-react-core/src/components/DatePicker/types";
import NewButton from "../NewButton/NewButton";
import {ListGroup, ListGroupItem} from "reactstrap";
import {isSameDay} from "./model";

interface NewDateRangePickerPresetsProps {
    value?: RangeDate,
    onChange: (value: (Moment | RangeDate)) => void,
    presets?: Preset[];
}

export const DEFAULT_PRESETS: Preset[] = [
  LAST_CALENDAR_WEEK,
  LAST_28_DAYS,
  LAST_CALENDAR_MONTH,
  LAST_3_CALENDAR_MONTHS,
];

function NewDateRangePickerPresets(props: NewDateRangePickerPresetsProps) {

    const {presets = DEFAULT_PRESETS} = props;

    return (
        <div style={{width: '230px',padding: '22px 22px 22px 0'}}>
            <div><h3>Presets</h3></div>
            <ListGroup>

            {presets.map(({ text, start, end }, i) => {
              const isSelected = isSameDay(start, props.value?.startDate) &&
                isSameDay(end, props.value?.endDate);
                return (
                <ListGroupItem className={'p-1 border-0'} key={i}>
                    <NewButton
                  key={text}
                  size={"small"}
                  className={"w-100"}
                  // eslint-disable-next-line   @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                        // these are not exported correctly
                  color={'primary'}
                  onClick={(): void =>
                    props.onChange({ startDate: start, endDate: end })
                  }
                  selected={isSelected}
                >
                  {text}
                </NewButton></ListGroupItem>
              );
            })}
            </ListGroup>
            </div>
    )
}

export default NewDateRangePickerPresets;