import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import classNames from "classnames";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FormikProps } from "formik/dist/types";
import _ from "lodash";
import { UpdateEntity } from "../../hooks/updateEntity";
import { ErrorBanner } from "../Error/ErrorBanner";
import * as yup from "yup";

interface EditModalProps<T> {
  values: T;
  title: string;
  validator?: yup.ObjectSchema<any>;
  update: UpdateEntity<T>;
  children: ((props: FormikProps<T>) => React.ReactNode) | React.ReactNode;
  setModalFocus: () => void;
  size?: string;
}

export default function EditModal<T>(
  props: EditModalProps<T>
): [() => void, JSX.Element] {
  const [editing, setEditing] = useState(false);
  const [values, setValues] = useState(props.values);

  const { updateEntity, error, updating, reset } = props.update;

  useEffect(() => {
    setValues(props.values);
  }, [props.values]);

  const modal = (
    <Modal
      isOpen={editing}
      onOpened={() => {
        reset();
        props.setModalFocus();
      }}
      unmountOnClose={true}
      data-cy={"edit-modal"}
      size={props.size}
    >
      <Formik
        initialValues={values}
        enableReinitialize={true}
        onSubmit={async (d) => {
          await updateEntity(d);
          setEditing(false);
        }}
        onReset={() => {
          setEditing(false);
        }}
        validationSchema={props.validator}
      >
        {(formikProps) => {
          return (
            <Form>
              <ModalHeader>Update {props.title}</ModalHeader>
              <ModalBody>
                <>
                  {_.isFunction(props.children)
                    ? props.children(formikProps)
                    : props.children}
                  <ErrorBanner error={error} />
                </>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="success"
                  onClick={formikProps.submitForm}
                  disabled={
                    updating || !formikProps.dirty || !formikProps.isValid
                  }
                >
                  <i
                    className={classNames({
                      "fa fa-fw": true,
                      "fa-check": !updating,
                      "fa-spinner fa-spin": updating,
                    })}
                  />
                  Confirm
                </Button>
                <Button
                  color="danger"
                  onClick={() => formikProps.resetForm()}
                  disabled={updating}
                >
                  <i className={"fa fa-times fa-fw"} />
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );

  return [() => setEditing((v) => !v), modal];
}
