import React, {Component} from "react";
import SimpleHeader from "../../components/Headers/SimpleHeader";
import {Col, Container, Row} from "reactstrap";
import AuthenticationCard from "./authentication/AuthenticationCard";
import UserCompanyList from "./UserCompanyList";

class ProfilePage extends Component {

    render(): JSX.Element {
        return <>
            <SimpleHeader breadcrumbs={[{url: '/admin/profile', name: 'Profile'}]}
                          noSchemeInBreadcrumbs={true}
            />
            <Container className="mt--6" fluid>
                <Row>
                    <Col xs={12}>
                        <AuthenticationCard/>
                    </Col>
                </Row>
                <Row>
                        <UserCompanyList/>
                </Row>
            </Container>
        </>
    }
}

export default ProfilePage;