import React from "react";
import GatewayFilelist from "./GatewayFilelist";
import {GatewayAsset} from "../../../openapi/model/gatewayAsset";
import EventList from "../../event/EventList";
import {Tab} from "../../../components/TabGroup/TabGroupInterface";
import GatewayConfigurationTab from "./GatewayConfigurationTab";
import RoutedTabGroupInterface from "../../../components/TabGroup/RoutedTabGroupInterface";
import GatewayJobTab from "./GatewayJobTab";
import {Col, Row} from "reactstrap";
import AssetPositionAssetList from "../../assetPositions/assetPositionAssetLinks/AssetPositionAssetList";
import MeterTimeSeriesCharts from "../meters/MeterTimeSeriesCharts";
import AssetRegisterList from "../../assetRegister/AssetRegisterList";
import StreamingGatewayData from "./data/StreamingGatewayData";
import AssetConfigurationPage from "../AssetConfigurationPage";

interface Props {
  gateway: GatewayAsset;
}

const GatewayHomepage = (props: Props): JSX.Element => {
  const assetId = props.gateway.assetId;

  const tabs: (Tab|undefined)[] = [
    {
      tabName: "Statistics",
      tabContent: <MeterTimeSeriesCharts device={props.gateway} />,
      tabPath: "statistics",
      userTypes: "superadmin",
    },
    {
      tabName: "Devices",
      tabContent: (
          <><AssetRegisterList
              companyId={props.gateway.companyId}
              parentAssetPositionId={props.gateway.assetPositionId}
              createAssetTags={false}
            />
</>
      ),
      tabPath: "devices",
      keepSearchString: false,
    },
    {
      tabName: "Files",
      tabContent: <GatewayFilelist asset={props.gateway} />,
      tabPath: "files",
      keepSearchString: false,
    },
      (props.gateway.manufacturer ==='lansen' ? {
          tabName: 'LiveData',
          tabContent: <StreamingGatewayData gateway={props.gateway}/>,
          tabPath: "live_data",
          userTypes: "super_admin",
      }: undefined),
    {
      tabName: "Events",
      tabContent: (
        <EventList assetId={assetId} companyId={props.gateway.companyId} />
      ),
      tabPath: "events",
      keepSearchString: false,
    },
    {
      tabName: "Configuration",
      tabContent: <GatewayConfigurationTab gateway={props.gateway} />,
      tabPath: "configuration",
      keepSearchString: false,
    },
    {
      tabName: "Configuration (detailed)",
      tabContent: <AssetConfigurationPage asset_id={props.gateway.assetId}/>,
      tabPath: "detailed",
      keepSearchString: false,
        userTypes: "superadmin",
    },
    {
      tabName: "Asset Position History",
      tabPath: "link",
      tabContent: (
        <Row>
          <Col xs={12}>
            <h3>Asset Position Asset History</h3>
            <AssetPositionAssetList
              assetId={assetId}
              view={"assetPosition"}
              companyId={props.gateway.companyId}
              schemeId={props.gateway.schemeId}
            />
          </Col>
        </Row>
      ),
      userTypes: "superadmin",
      keepSearchString: false,
    },
    {
      tabName: "Jobs",
      tabContent: <GatewayJobTab gateway={props.gateway} />,
      tabPath: "job",
      keepSearchString: false,
    },
  ];

  return (
    <RoutedTabGroupInterface
      tabs={tabs}
      topLevelPath={`/admin/asset/${assetId}/:tab`}
    />
  );
};

export default GatewayHomepage;
