import React from "react";
import { AssetFile as GatewayFile } from "../../../openapi/model/assetFile";
import GatewayFileListRow from "./GatewayFileListRow";
import { Asset } from "openapi/model/asset";
import GetListPaginatedTable from "../../../components/Pagination/GetListPaginatedTable";
import GatewayFileListFilterForm, {
  useGetParsedGatewayFileListFilterParams,
} from "./GatewayFileListFilterForm";
import {AssetPosition} from "../../../openapi/model/assetPosition";
import {RequireExactlyOne} from "type-fest";

type Props =  RequireExactlyOne<{
    asset?: Asset;
    assetPosition?: AssetPosition;
},'asset' | 'assetPosition'
  > ;


const GatewayFilelist = ({ asset, assetPosition }: Props): JSX.Element => {
  const useAsset = asset !== undefined;

  const { companyId } = useAsset ? asset : assetPosition as AssetPosition;

  const path = useAsset ? `asset/${asset.assetId}/files` : `asset_position/${assetPosition?.assetPositionId}/files`
  const { params } = useGetParsedGatewayFileListFilterParams();
  const header = (
      <tr>
          <th className="text-left">&nbsp;</th>
          <th className="text-left">Filename</th>
          <th className="text-left">Date received</th>
          <th className="text-left">File size</th>
          <th className="text-left">Status</th>
          <th className="text-left">&nbsp;</th>
      </tr>
  );
    const row = (value: GatewayFile): JSX.Element => (
    <GatewayFileListRow file={value} key={value.fileId} index={value.fileId} />
  );

  return (
    <>
      <GatewayFileListFilterForm />
      <GetListPaginatedTable
        entityType={"GATEWAY_FILE"}
        params={{
          companyId,
          startDatetime: params.startDatetime?.toISOString(true),
          endDatetime: params.endDatetime?.toISOString(true),
        }}
        cursor={{ maxItems: 10, direction: "desc" }}
        path={path}
        header={[header, 5]}
        row={row}
      />
    </>
  );
};

export default GatewayFilelist;
