/**
 * Meterpoint.io
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type ConfigurationState = 'IN_SYNC' | 'UPDATING_CONFIGURATION' | 'UPDATING_DEVICE_LIST' | 'ERROR_UPDATING_CONFIGURATION' | 'ERROR_UPDATING_DEVICE_LIST' | 'OUT_OF_SYNC' | 'UNKNOWN';

export const ConfigurationState = {
    INSYNC: 'IN_SYNC' as ConfigurationState,
    UPDATINGCONFIGURATION: 'UPDATING_CONFIGURATION' as ConfigurationState,
    UPDATINGDEVICELIST: 'UPDATING_DEVICE_LIST' as ConfigurationState,
    ERRORUPDATINGCONFIGURATION: 'ERROR_UPDATING_CONFIGURATION' as ConfigurationState,
    ERRORUPDATINGDEVICELIST: 'ERROR_UPDATING_DEVICE_LIST' as ConfigurationState,
    OUTOFSYNC: 'OUT_OF_SYNC' as ConfigurationState,
    UNKNOWN: 'UNKNOWN' as ConfigurationState
};