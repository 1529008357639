import React from "react";
import { Status } from "openapi/model/status";
import { Badge } from "reactstrap";

const DeletedLabel = (props: { object: { status: Status } }): JSX.Element => {
  if (props.object.status === Status.DELETED) {
    return (
      <Badge size={"small"} color={"danger"} className={"mx-1"}>
        Deleted
      </Badge>
    );
  } else {
    return <></>;
  }
};

export default DeletedLabel;
