import React, {useContext, useEffect} from "react";
import classNames from "classnames";
import ErrorTriangle from "../../components/Error/ErrorTriangle";
import {AssetPositionCacheContext, useGetCachedAssetPosition,} from "../../reducers/assetPositionCachingReducer";
import useUserHasRoles from "../../hooks/user/getCurrentUserRole";
import {getData} from "../../hooks/getData";
import useUpdateEntity from "../../hooks/updateEntity";
import {useSelectedCompany} from "../../reducers/company";

export default function ReceiverAssignmentButton(props: {
    assetPositionId: string;
    receiverPositionId: string;
    companyId: string;
    schemeId: string;
    initLoad?: boolean;
}): JSX.Element {
    const company = useSelectedCompany();
    const {assetPositionId, companyId, receiverPositionId, schemeId} = props;
    const {dispatch} = useContext(AssetPositionCacheContext);
    const assetPositionState = useGetCachedAssetPosition(assetPositionId);

    const isUserAdmin = useUserHasRoles("admin");

    function refresh() {
        dispatch({
            type: "FETCHING_ITEM",
            assetPositionId: assetPositionId,
        });

        getData<Array<string>>({
            path: `asset_position/${assetPositionId}/wireless_receivers`,
            companyId,
            schemeId,
            params: {assetPositionId},
            post: false,
            singletonApi: true,
            transform: false
        }).then((links) => {
            dispatch({
                type: "FETCHED_ITEM",
                assetPositionId: assetPositionId,
                payload: links,
            });
        });
    }

    useEffect(() => {
        if (assetPositionState === undefined && props.initLoad) {
            refresh();
        }

    });

    const {updateEntity, error: updateError} = useUpdateEntity(
        "ASSET_POSITION",
        assetPositionId,
        () => refresh(),
        `asset_position/${assetPositionId}/wireless_receivers?company_id=${company?.companyId}`,
    );

    const assigned =
        assetPositionState &&
        assetPositionState.data &&
        (assetPositionState.data || []).includes(
            receiverPositionId
        );

    function removeReceiverFromPosition(
        receivers: string[],
        receiverPositionId: string
    ): string[] {
        const index = receivers.indexOf(receiverPositionId, 0);
        if (index > -1) {
            receivers.splice(index, 1);
        }
        return receivers;
    }

    function toggleAssignment() {
        if (assetPositionState?.data && isUserAdmin) {
            const updatedReceivers = !assigned ? [...assetPositionState.data, receiverPositionId] : removeReceiverFromPosition(
                assetPositionState.data,
                receiverPositionId
            )
            if (
                assetPositionState.data &&
                (assetPositionState.updating === false ||
                    assetPositionState.updating === undefined)
            ) {
                dispatch({type: "UPDATING_ITEM", assetPositionId});
                updateEntity(updatedReceivers).then(() => {
                    dispatch({type: "UPDATED_ITEM", assetPositionId});
                });
            }
        }
    }

    return (
        <div style={{width: "20px"}} className={"align-middle"}>
            {(assetPositionState?.loading || assetPositionState?.updating) && (
                <span className={"fa fa-fw fa-spinner fa-spin"}/>
            )}
            {!assetPositionState?.loading &&
                !assetPositionState?.updating &&
                assetPositionState?.data && (
                    <span
                        role={isUserAdmin ? "button" : undefined}
                        className={classNames({
                            "fa fa-fw ": true,
                            "fa-regular fa-check-square": assigned,
                            "fa-regular fa-square": !assigned,
                        })}
                        onClick={toggleAssignment}
                    />
                )}
            {!assetPositionState?.loading && (
                <ErrorTriangle error={assetPositionState?.error}/>
            )}
            {!assetPositionState?.updating && <ErrorTriangle error={updateError}/>}
        </div>
    );
}
