import React, { useContext, useState } from "react";
import CreateCompanyContainer from "./CreateCompanyContainer";
import { Col, Row, UncontrolledTooltip } from "reactstrap";
import { OneCardPage } from "../../components/utils/OneCardPage";
import classNames from "classnames";
import { CompanyContext } from "../../reducers/company";
import { Company } from "../../openapi/model/company";
import DisplayIfUserType from "components/utils/DisplayIfUserType/DisplayIfUserType";
import _ from "lodash";
import GetListPaginatedTable from "../../components/Pagination/GetListPaginatedTable";
import { CompanyType } from "../../openapi/model/companyType";
import PrivacyMode from "components/Text/PrivacyMode";

const CompanyList = (): JSX.Element => {
  const { state, dispatch } = useContext(CompanyContext);
  const { selected } = state;

  const [requestAge, setRequestAge] = useState(new Date());

  const refresh = (): void => {
    setRequestAge(new Date());
  };

  const selectCompany = (company: Company) => {
    dispatch({ type: "SELECT_COMPANY", payload: company });
  };

  const header = (
    <tr>
      <th scope="col" className="text-left">
        Id
      </th>
      <th scope="col" className="text-left">
        Name
      </th>
      <th scope="col" className="text-left">
        Type
      </th>
      <th scope="col" className="text-left">
        Number of Assets
      </th>
      <th scope="col" className={"text-left"}>
        Partner
      </th>
      <th scope="col" className="text-left">
        &nbsp;
      </th>
    </tr>
  );

  const row = (value: Company) => (
    <tr
      key={value.companyId}
      data-cy="list-item"
      className={classNames({
        "table-primary": selected && value.companyId === selected.companyId,
      })}
    >
      <th scope={"row"} data-cy="company-id">
        <PrivacyMode>{value.companyId}</PrivacyMode>
      </th>
      <td data-cy="company-name">
        <PrivacyMode>{value.name}</PrivacyMode>
      </td>
      <td data-cy="company-type" className="text-left">
        {_.startCase(_.lowerCase(value.companyType))}
      </td>
      <td data-cy="no-of-gateways">{value.numberOfAssets}</td>
      <td data-cy={"partner"}>
        <PrivacyMode>
          {value.partner
            ? `${value.partner.partnerName} (${value.partner.partnerId})`
            : "-"}
        </PrivacyMode>
      </td>
      <td className="table-actions">
        <a
          className="table-action table-action-delete"
          href={`#company=${value.companyId}`}
          id={`tooltip-select-company-${value.companyId}`}
          onClick={(): void => {
            selectCompany(value);
          }}
        >
          <i className="fas fa-hand-point-up" />
        </a>
        <UncontrolledTooltip
          delay={0}
          target={`tooltip-select-company-${value.companyId}`}
        >
          Select {value.name}
        </UncontrolledTooltip>
      </td>
    </tr>
  );

  return (
    <OneCardPage
      headerName={"List"}
      headerParentName={"Companies"}
      headerTitle={"Companies List"}
    >
      <Row>
        <Col xs={12}>
          <GetListPaginatedTable
            entityType={"COMPANY"}
            params={{
              partnerId:
                selected?.companyType === CompanyType.PARTNER
                  ? selected.companyId
                  : undefined,
              refreshToken: requestAge.toISOString(),
            }}
            cursor={{ maxItems: 50 }}
            data-cy={"company-list"}
            header={[header, 6]}
            row={row}
          />
        </Col>
        <Col xs={12}>
          <DisplayIfUserType userTypes={"admin"}>
            <DisplayIfUserType userTypes={"admin"} companyType={"PARTNER"}>
              <CreateCompanyContainer onSubmit={refresh} />
            </DisplayIfUserType>
          </DisplayIfUserType>
        </Col>
      </Row>
    </OneCardPage>
  );
};

export default CompanyList;
