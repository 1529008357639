import React from 'react';
import {Col, Row} from "reactstrap";
import AccountList from "../account/AccountList";
import {Location} from "../../openapi/model/location";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import NewAccountForm from "../account/NewAccountForm";
import useCreateEntity from "../../hooks/createEntity";
import {Account} from "../../openapi/model/account";
import {NewAccount} from "../../openapi/model/newAccount";

export interface LocationAccountTabProps {
    location: Location
}

function LocationAccountTab(props: LocationAccountTabProps) {
  const createAccount = useCreateEntity<Account, NewAccount>("ACCOUNT");

    return <Row><Col xs={12}><h3>Accounts</h3>
    </Col>
        <Col xs={12}>
            <AccountList locationId={props.location.locationId}
                         companyId={props.location.companyId}

            />
        </Col>
        <Col xs={12}>
                        <DisplayIfUserType userTypes={"superadmin"}>
                            <h3>Create New Account</h3>

                          <NewAccountForm
                            location={props.location}
                            create={createAccount}
                          />
                        </DisplayIfUserType>

        </Col>
    </Row>
}

export default LocationAccountTab;