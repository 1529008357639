import _ from "lodash";
import {ParsedQs} from "qs";

export function convertStringToOpenApiEnum<E, K extends string = string>(
  str: string,
  type: {  [key in K]: E },
  uppercase = false,
): E | undefined {
  const val = (uppercase ? _.upperCase : _.startCase)(_.camelCase(str)).replace(/ /g, "");
  if (val in type) {
    return type[val as keyof typeof type];
  }
  return undefined;
}


export function matchStringsToEnum<E, K extends string=string>(
  lst: string | string[] | ParsedQs | ParsedQs[] | undefined,
  type: { [key in K]: E },
  uppercase = false,
): E[] | undefined {
  if (_.isNil(lst)) {
    return undefined;
  }
  if (_.isArray(lst)) {
    return lst
      .map((v) => {
        if (_.isString(v)) {
          return convertStringToOpenApiEnum<E, K>(v, type, uppercase);
        } else {
          return undefined;
        }
      })
      .filter((v) => !_.isNil(v)) as E[];
  }
  const val = _.isString(lst)
    ? convertStringToOpenApiEnum<E, K>(lst, type, uppercase)
    : undefined;
  if (val) {
    return [val as E];
  }
}
