import React, { useCallback, useEffect, useState } from "react";
import { parseSearchString } from "../navigation/SearchString";
import { useLocation } from "react-router-dom";

type Props = { initialState: any; children: React.ReactNode };

export default function SearchParamPage(props: Props): JSX.Element {
  /*
    Simple component to parse search strings, detect changes and pass the params as an object to a single child element.
     */
  const [state, setState] = useState({
    ...props.initialState,
    initialized: false,
  });
  const location = useLocation();

  const update = useCallback((): void => {
    const getSearchParams = (): {
      assetType?: string;
      assetPositionId?: string;
    } => {
      return parseSearchString(location.search);
    };
    setState({
      ...props.initialState,
      initialized: true,
      ...getSearchParams(),
    });
  }, [props.initialState, location.search]);

  useEffect(update, [location.search, update]);

  return state.initialized && React.isValidElement(props.children) ? (
    React.cloneElement(props.children, state)
  ) : (
    <></>
  );
}
