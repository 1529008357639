/**
 * Meterpoint.io
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type Actions = 'UPDATE' | 'NEW' | 'NOT_FOUND' | 'DELETED' | 'UNCHANGED';

export const Actions = {
    UPDATE: 'UPDATE' as Actions,
    NEW: 'NEW' as Actions,
    NOTFOUND: 'NOT_FOUND' as Actions,
    DELETED: 'DELETED' as Actions,
    UNCHANGED: 'UNCHANGED' as Actions
};