import React, { useState } from "react";
import ForgottenPasswordForm from "./authentication/ForgottenPasswordForm";
import ResetCodeForm from "./authentication/ResetCodeForm";

interface State {
  emailAddress: string;
}

const ForgottenPasswordPage = (): JSX.Element => {
  const [state, setState] = useState<State>({
    emailAddress: "",
  });

  const handleEmailAddress = (emailAddress: string): void => {
    setState({ emailAddress: emailAddress });
  };

  return (
    <>
      <div className="Login">
        {state.emailAddress !== "" ? (
          <ResetCodeForm emailAddress={state.emailAddress} />
        ) : (
          <ForgottenPasswordForm handleEmail={handleEmailAddress} />
        )}
      </div>
    </>
  );
};

export default ForgottenPasswordPage;
