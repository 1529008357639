import React from "react";
import { Alert } from "reactstrap";

const UnusedColumnsBanner = (props: {
  unusedColumns: string[];
}): JSX.Element => {
  if (props.unusedColumns.length === 0) {
    return <></>;
  }
  if (props.unusedColumns.length === 1) {
    return (
      <Alert color={"warning"}>
        The {props.unusedColumns[0]} column in your spreadsheet has been ignored
        as as it is not a valid property.
      </Alert>
    );
  } else {
    return (
      <Alert color={"warning"}>
        Columns in your spreadsheet have been ignored as they are not valid
        properties. They are {props.unusedColumns.join(", ")}.
      </Alert>
    );
  }
};

export default UnusedColumnsBanner;
