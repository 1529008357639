import React from "react";
import { Alert } from "reactstrap";

export default function SuccessBanner(props: {
  title?: string;
  text?: string;
}): JSX.Element {
  const { text = "Your action was successful.", title } = props;
  return (
    <Alert color={"success"} className={"my-2"}>
      <h3>Success</h3>
      {title && <b>{title}:</b>}
      {text}
    </Alert>
  );
}
