import { ErrorMessage, Field, useField } from "formik";
import React from "react";
import LabelWrapper, { FormFieldProps } from "./LabelWrapper";
import classNames from "classnames";

interface Props extends FormFieldProps {
  fieldName: string;
  disabled?: boolean;
  infoText?: string;
}

export default function TextFormComponent(props: Props): JSX.Element {
  const { disabled = false } = { ...props };

  const [_field, meta, _helpers] = useField(props.fieldName);

  return (
    <LabelWrapper {...props}>
      <Field
        name={props.fieldName}
        id={props.fieldName}
        type="text"
        className={classNames("form-control", {
          "is-invalid": meta.error && meta.touched,
        })}
        disabled={disabled}
      />
      <ErrorMessage
        name={props.fieldName}
        component="div"
        className="invalid-feedback"
      />
    </LabelWrapper>
  );
}
