import React from "react";
import GetListPaginatedList from "../../components/Pagination/GetListPaginatedList";
import { Account } from "../../openapi/model/account";
import { Col, ListGroupItem, Row } from "reactstrap";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import PrivacyMode from "../../components/Text/PrivacyMode";

function AccountList(props: {
  companyId: string;
  schemeId?: string;
  locationId?: string;
}) {

    const showLocationCol = props.locationId === undefined;

  function row(account: Account) {
    return (
      <ListGroupItem>
        <Row>
          <Col xs={showLocationCol ? 2 : 6}>{account.accountReference}</Col>
          {showLocationCol && <Col xs={6}>
                                <CompanyNavLink
                    className={"p-0 d-inline"}
                    to={`/admin/location/${account.locationId}/accounts`}
                    dataCy={"locationId"} inline={true}
                  >
                    <PrivacyMode>{
                        account.locationReference ? `${account.locationAddress}(${account.locationReference})` : account.locationAddress}</PrivacyMode>
                  </CompanyNavLink>
              </Col>}
          <Col xs={showLocationCol ? 2: 3}>{account.openingDate}</Col>
          <Col xs={showLocationCol ? 2: 3}>{account.closingDate || '-'}</Col>
        </Row>
      </ListGroupItem>
    );
  }

  return (
    <GetListPaginatedList
      row={row}
      header={      <ListGroupItem>
        <Row>
          <Col xs={showLocationCol ? 2: 6}><h5>Account Ref</h5></Col>
          {showLocationCol && <Col xs={6}><h5>Location</h5></Col>}
          <Col xs={showLocationCol ? 2: 3}><h5>Opening Date</h5></Col>
          <Col xs={showLocationCol ? 2: 3}><h5>Closing Date</h5></Col>
        </Row>
      </ListGroupItem>}
      entityType={"ACCOUNT"}
      params={props}
      cursor={{ maxItems: 100, direction: "desc" }}
      tableClassName={"list-group-flush"}
      showPagination={true}
    />
  );
}

export default AccountList;
