import React, { useState } from "react";
import { AssetPositionNumbers } from "../../openapi/model/assetPositionNumbers";
import { Tooltip } from "reactstrap";
import * as _ from "lodash";

const NumberOfAssetPositionsDisplayWidget = (props: {
  positions?: AssetPositionNumbers;
  index: number | string;
}): JSX.Element => {
  const [tooltip, setTooltip] = useState(false);
  const positions: AssetPositionNumbers = props.positions
    ? props.positions
    : {};
  const total = Object.values(positions).reduce((a, b) => a + b, 0);
  const tooltipContent = (
    Object.keys(positions) as Array<keyof AssetPositionNumbers>
  ).map((key) => (
    <p key={key}>
      {_.startCase(key)}: {positions[key]}
    </p>
  ));

  return (
    <>
      {" "}
      {total}
      <i
        className={"fa fa-fw fa-info-circle"}
        id={`AssetPositionCountWidget-${props.index}`}
      />
      <Tooltip
        placement="right"
        isOpen={tooltip}
        target={`AssetPositionCountWidget-${props.index}`}
        toggle={(): void => {
          setTooltip(!tooltip);
        }}
      >
        {tooltipContent}
      </Tooltip>
    </>
  );
};

export default NumberOfAssetPositionsDisplayWidget;
