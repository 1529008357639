import React, { useEffect } from "react";
import { Cursor } from "../../openapi/model/cursor";
import { Alert, Table } from "reactstrap";
import BlockSpinner from "../Spinners/BlockSpinner";
import { AxiosError } from "axios";
import { PaginatedData } from "hooks/getList";
import ApiPagination from "./ApiPagination";

export default function ApiPaginatedTable(props: {
  cursor?: Cursor;
  error?: Error | null;
  data?: PaginatedData<any>;
  loading?: boolean;
  refresh?: () => any;
  updateItem?: (item: any, index: number) => void;
  header: [JSX.Element, number];
  row: (
    item: any,
    update: (updatedItem: any) => void,
    key: number,
  ) => React.ReactElement<{ key: string }>;
  "data-cy"?: string; // optional cypress label for the table
  tableClassName?: string;
  noDataRow?: JSX.Element;
  showPagination?: boolean;
  additionalFunctionButtons?: JSX.Element;
  lastChange?: Date;
}): JSX.Element {
  const { refresh, lastChange } = props;
  useEffect(() => {
    refresh && refresh();
  }, [refresh, lastChange]);
  const errorRow = (
    message: string,
    code?: number,
    tableWidth = 1
  ): JSX.Element => {
    const color = !code || code >= 500 ? "danger" : "warning";
    return (
      <tbody key={"list-error"}>
        <tr>
          <td colSpan={tableWidth} data-cy={"list-error"}>
            <Alert color={color}>
              <h3 style={{ color: "black" }}>An error has occurred.</h3>
              <p style={{ color: "black" }}>
                The sever has responded: {message} {code && <>({code})</>}.
              </p>
            </Alert>
          </td>
        </tr>
      </tbody>
    );
  };

  const displayError = (
    error: Error | AxiosError,
    tableWidth = 1
  ): JSX.Element => {
    if (error && Object.prototype.hasOwnProperty.call(error, "isAxiosError")) {
      const err = error as AxiosError;
      const message = err.response?.data?.message || err.response?.statusText;
      return errorRow(message, err.response?.status, tableWidth);
    } else {
      return errorRow(error.message, undefined, tableWidth);
    }
  };

  const requestedSize = (props.cursor as Cursor)?.maxItems || 10;
  const error = props.error;
  const [header, tableWidth] = props.header;

  const missingElements = Math.max(
    requestedSize - (props.data?.data.length || 0),
    0
  );

  return (
    <>
      <BlockSpinner loading={props.loading || false} scrollX={true}>
        <Table
          className={"align-items-center table-flush " + props.tableClassName}
          data-cy={props["data-cy"]}
        >
          <thead className="thead-light">{header}</thead>
          {props.data && (
            <tbody>
              {props.data?.data.map((value, index) =>
                props.row(
                  value,
                  (updateItem) =>
                    props.updateItem && props.updateItem(updateItem, index),
                    index
                )
              )}
            </tbody>
          )}
          {props.loading &&
            [...Array(missingElements)].map((_, i) => (
              <tbody key={i}>
                <tr>
                  <td colSpan={tableWidth}>&nbsp;</td>
                </tr>
              </tbody>
            ))}
          {!props.loading && props.data?.data.length === 0 && !error && (
            <tbody key={"no-data"}>
              {props.noDataRow || (
                <tr>
                  <td colSpan={tableWidth} data-cy={"no-data"}>
                    No data found.
                  </td>
                </tr>
              )}
            </tbody>
          )}
          {!props.loading && error && displayError(error, tableWidth)}
        </Table>
      </BlockSpinner>

      {(props.showPagination === undefined || props.showPagination) &&
        props.data && (
          <ApiPagination
            cursor={props.data.cursor}
            newCursor={props.data.setCursor}
            refresh={props.refresh}
            // prevCursor={this.props.state.list.prevCursor}
            // newCursor={this.debouncedGetPage}
          />
        )}
      {props.additionalFunctionButtons}
    </>
  );
}
