import React from "react";
import {AssetPosition} from "../../openapi/model/assetPosition";
import * as _ from "lodash";
import {AssetUseCase} from "../../openapi/model/assetUseCase";
import {Badge} from "reactstrap";

export default function AssetUseCaseHumanLabel(props: {
  asset: AssetPosition;
  asBadge?: boolean;
}): JSX.Element {
  const { asset, asBadge = true } = props;
  const useCase: AssetUseCase = asset.useCase || AssetUseCase.NONE;

  if (asBadge) {
    return <Badge>{useCase}</Badge>;
  } else {
    return <>{_.startCase(useCase.toLowerCase())}</>;
  }
}
