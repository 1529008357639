import React from "react";
import { AssetPosition } from "../../openapi/model/assetPosition";
import { Button } from "reactstrap";
import NoteModal from "./NoteModal";

interface Props {
  assetPosition: AssetPosition;
  onCreate?: () => void;
}

export default function CreateNoteModalButton(
  props: Props
): React.ReactElement {
  return (
    <>
      <NoteModal
        onCreate={props.onCreate}
        assetPosition={props.assetPosition}
        creating={true}
      >
        {({ openModal, isOpen }) => (
          <Button onClick={openModal} disabled={isOpen} color={"primary"}>
            <i className={"fa fa-fw fa-pen px-0 mx-2"} />
            <span>Add Note</span>
          </Button>
        )}
      </NoteModal>
    </>
  );
}
