import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { yupRequiredString } from "../../components/Forms/yupValidators";
import FormComponent from "../../components/Forms/FormComponent";
import { Button } from "reactstrap";
import { Location } from "../../openapi/model/location";
import useCreateEntity from "../../hooks/createEntity";
import { AssetPosition } from "../../openapi/model/assetPosition";
import { AssetType } from "../../openapi/model/assetType";
import BlockSpinner from "components/Spinners/BlockSpinner";
import { ErrorBanner } from "components/Error/ErrorBanner";

interface Props {
  companyId: string;
  schemeId: string;
  postCreateActions?: () => any;
}

interface FormValues {
  address: "";
}

export default function AssetPositionCreateForm(props: Props): JSX.Element {
  const createLocation = useCreateEntity<Location>("LOCATION");
  const createAssetPosition = useCreateEntity<AssetPosition>("ASSET_POSITION");

  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ): void => {
    setSubmitting(true);
    createLocation
      .createEntity({
        schemeId: props.schemeId,
        companyId: props.companyId,
        address: values.address,
      })
      .then(([location]) => {
        createAssetPosition.createEntity({
          schemeId: props.schemeId,
          companyId: props.companyId,
          locationId: location.locationId,
          assetPositionType: AssetType.WirelessCluster,
        });
      })
      .then(() => {
        resetForm();
        if (props.postCreateActions) {
          props.postCreateActions();
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialValues: FormValues = {
    address: "",
  };
  const creating = createLocation.creating || createAssetPosition.creating;

  return (
    <Formik
      onSubmit={submit}
      initialValues={initialValues}
      validateOnMount={true}
      validationSchema={Yup.object().shape({
        address: yupRequiredString,
      })}
    >
      {({ isValid, isSubmitting }): JSX.Element => (
        <BlockSpinner loading={creating}>
          <Form data-cy={"wireless-cluster-create"}>
            <div className={"row"}>
              <div className={"col-3"}>
                <h3 className="text-white mb-0">Add Wireless Cluster</h3>
              </div>
              <FormComponent
                fieldName={"address"}
                label={"Description"}
              />
              <div className={"col-12"}>
                <div className={"form-group row"}>
                  <div className={"col-12"}>
                    <Button
                      type={"submit"}
                      color={"primary"}
                      disabled={isSubmitting || !isValid}
                    >
                      Add Wireless Cluster
                    </Button>
                  </div>
                </div>
                <ErrorBanner
                  error={createLocation.error || createAssetPosition.error}
                />
              </div>
            </div>
          </Form>
        </BlockSpinner>
      )}
    </Formik>
  );
}
