import React from "react";
import { Period } from "../../../openapi/model/period";
import { Button, ButtonGroup, ButtonToolbar } from "reactstrap";

const PeriodPickerToggle = (props: {
  setPeriod: (period: Period) => void;
  selectedPeriod: Period;
  includeWeekly?: boolean;
}): React.ReactElement => {
  const { setPeriod, selectedPeriod, includeWeekly = false } = props;

  return (
    <>
      <ButtonToolbar className={"float-right"}>
        <ButtonGroup>
          <Button
            className={"btn-sm"}
            color={"primary"}
            active={selectedPeriod === Period.DAILY}
            onClick={(): void => {
              setPeriod(Period.DAILY);
            }}
          >
            D
          </Button>
          {includeWeekly && (
            <Button
              className={"btn-sm"}
              color={"primary"}
              active={selectedPeriod === Period.WEEKLY}
              onClick={(): void => {
                setPeriod(Period.WEEKLY);
              }}
            >
              W
            </Button>
          )}
          <Button
            className={"btn-sm"}
            color={"primary"}
            active={selectedPeriod === Period.SEVENDAY}
            onClick={(): void => {
              setPeriod(Period.SEVENDAY);
            }}
          >
            7D
          </Button>
          <Button
            className={"btn-sm"}
            color={"primary"}
            active={selectedPeriod === Period.MONTHLY}
            onClick={(): void => {
              setPeriod(Period.MONTHLY);
            }}
          >
            M
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
    </>
  );
};

export default PeriodPickerToggle;
