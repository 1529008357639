import * as Yup from "yup";
import { nameRegEx } from "./utils/schema";

export const locationSchema = {
  address: Yup.string()
    .required("Required.")
    .matches(...nameRegEx),
  group: Yup.string().notRequired().matches(RegExp("^[a-z0-9\\-/]*$"), {
    message: "Only lowercase letters, numbers, - and / are allowed.",
  }),
};
