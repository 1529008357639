import React from "react";
import {Option,} from "../../../components/Forms/SelectComponent";
import {EnergyUnits} from "../../../openapi/model/energyUnits";
import {SelectCombo, SelectComboProps} from "../../../components/Forms/SelectCombo/SelectCombo";

const EnergyUnitSelect = (
  props: Omit<SelectComboProps,'options'>
): JSX.Element => {

  const options: Option[] = [
    { label: "kWh", value: EnergyUnits.KWh },
    { label: "Wh", value: EnergyUnits.Wh },
    { label: "MWh", value: EnergyUnits.MWh },
  ];

  return (
    <SelectCombo
      {...props}
        options={options}
    />
  );
};

export default EnergyUnitSelect;
