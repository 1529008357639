import React from 'react';
import {useField} from "formik";

import {DateRange} from "../NewDateRangePicker/model";

interface NewDateRangePickerFieldProps {
    startDateFieldName: string;
    endDateFieldName: string;
    children: (props: {
        onChange: (value: DateRange) => void,
        value: DateRange,
        error?: string,
        touched?: boolean,
    }) => React.ReactNode;
    onChange?: (value: DateRange) => Promise<any>

}

function NewDateRangePickerField(props: NewDateRangePickerFieldProps) {
    const startDateField = useField(props.startDateFieldName);
    const endDateField = useField(props.endDateFieldName);

    const onChangeFormikSet = (newValue: DateRange) => {
        startDateField[2].setTouched(true)
            .then(
                () => endDateField[2].setTouched(true)
                    .then(
                        () => {
                            if (props.onChange) {
                                props.onChange(newValue).then(() => {
                                    startDateField[2].setValue(
                                        newValue.startDate
                                    ).then(() => {
                                        return endDateField[2].setValue(newValue.endDate)
                                    })
                                })
                            } else {
                                startDateField[2].setValue(
                                    newValue.startDate
                                ).then(() => {
                                    return endDateField[2].setValue(newValue.endDate)
                                })
                            }
                        })
            )
    }

    const value: DateRange = {
        startDate: startDateField[0].value,
        endDate: endDateField[0].value
    }
    const touched = startDateField[1].touched || endDateField[1].touched;
    const error = startDateField[1].error || endDateField[1].error;


    return <>{props.children({onChange: onChangeFormikSet, value, touched, error})}</>
}

export default NewDateRangePickerField;