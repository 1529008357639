import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUrlHash } from "../../components/navigation/URL";
import { useSelectedCompany } from "../../reducers/company";
import { useSelectedScheme } from "../../reducers/scheme";
import { usePrivacyMode } from "../../reducers/privacy";
import { useProfile } from "../../reducers/profile";

export default function AdminLandingPage(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const userProfile = useProfile();

  const hash = getUrlHash(
    useSelectedCompany(),
    useSelectedScheme(),
    usePrivacyMode()
  );
  const search = location.search;

  useEffect(() => {
    if (location.pathname === "/admin" || location.pathname === "/admin/") {
      if (userProfile?.userType === "superadmin") {
        navigate({ pathname: "/admin/company", hash: hash, search: search });
      } else {
        navigate({ pathname: "dashboard", hash: hash, search: search });
      }
    }
  }, [navigate, hash, search, location, userProfile]);

  return <></>;
}
