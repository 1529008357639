import { isMoment } from "moment";
import { parse, ParsedQs, stringify } from "qs";

export const parseSearchString = (search: string): ParsedQs => {
  return parse(search.slice(1));
};

export const createSearchString = (obj: any): string => {
  return stringify(obj);
};

export const convertStringUndefined = (obj: any): any => {
  const rtr: any = {};
  Object.keys(obj).forEach((k) => {
    if (obj[k] === "undefined") {
      rtr[k] = undefined;
    } else if (isMoment(obj[k])) {
      rtr[k] = obj[k].toISOString(true);
    } else {
      rtr[k] = obj[k];
    }
  });
  return rtr;
};
