import { ValueErrors } from "../../../openapi/model/valueErrors";
import { ColumnMap } from "../../../model/deviceTimeSeries";
import moment, { Duration, duration, Moment } from "moment";

export interface ErrorBand {
  start: Moment;
  end: Moment;
  errors: { [key: string]: ValueErrors[] };
}

export const identifyErrorBands = (
  data: ColumnMap,
  times: Moment[],
  errorBandDuration?: Duration
): Array<ErrorBand> => {
  /*
              Maps a set of ColumnMap data and matching times array to an array of ErrorBands.
  
              Each ErrorBand will have matching start and end time, overlaps are not taken into account.
               */

  const timeAdjust: Duration =
    errorBandDuration !== undefined
      ? duration(errorBandDuration.asMinutes() / 2, "minute")
      : duration(0, "seconds");

  return times
    .flatMap((time, time_index): ErrorBand | undefined => {
      let errorCount = 0;
      const errors: { [key: string]: Array<ValueErrors> } = Object.entries(
        data
      ).reduce((e, [key, v]) => {
        const thisErrors =
          v.errors?.[time_index].filter((v) => v !== null && v !== undefined) ||
          [];
        const thisErrorCount = thisErrors.length || 0;

        if (thisErrorCount !== 0) {
          errorCount += thisErrorCount;
          return {
            ...e,
            [key]: thisErrors,
          };
        } else {
          return e;
        }
      }, {});
      if (errorCount === 0) {
        return undefined;
      } else {
        const start = moment(time.clone().subtract(timeAdjust));
        const end = moment(time.clone().add(timeAdjust));

        return { start, end, errors };
      }
    })
    .filter((v) => {
      return v !== undefined;
    }) as Array<ErrorBand>;
};

export const removeValueErrorsFromData = (data: ColumnMap): ColumnMap => {
  return Object.entries(data).reduce(
    (p, [k, v]) => ({
      ...p,
      [k]: { ...v, data: removeErrorsFromDataArray(v.data, v.errors) },
    }),
    {}
  );
};
const removeErrorsFromDataArray = (
  data: (string | undefined)[],
  errors?: ValueErrors[][]
): Array<string | undefined | null> => {
  if (errors === undefined || errors.length !== data.length) {
    return data;
  } else {
    return data.flatMap((value, index) => {
      const thisErrors = errors[index].filter((v) => v !== null);
      return thisErrors.length === 0 ? value : null;
    });
  }
};

export const identifyBestDurationForErrorBands = (
  times: Moment[]
): Duration => {
  if (times.length > 1) {
    const firstTime = times[0];
    const lastTime = times[times.length - 1];
    const diff = lastTime.diff(firstTime, "hours");
    switch (true) {
      case diff <= 23:
        return duration(30, "minute");
      case diff <= 167:
        return duration(2, "hours");
      case diff <= 671:
        return duration(6, "hours");
      default:
        return duration(24, "hours");
    }
  }
  return duration(2, "hours");
};
