import React from "react";
import { Row } from "reactstrap";
import { DeviceAsset } from "../../../openapi/model/deviceAsset";
import { GatewayAsset } from "../../../openapi/model/gatewayAsset";
import { AssetTimeSeriesRequestParams } from "./AssetDataSelectionForm";
import {
  DeviceTimeSeriesDisplayComponent,
  DeviceTimeSeriesDisplayComponentProps,
} from "./DeviceTimeSeriesDataContainer";
import useGetData from "../../../hooks/getData";
import {
  convertTimesToMoment,
  DeviceTimeSeriesData,
  extendDataToTimeRange,
  mapColumns,
} from "../../../model/deviceTimeSeries";
import { Moment } from "moment";
import { RawTimeSeriesResponse } from "../../../openapi/model/rawTimeSeriesResponse";
import { ParsedTimeSeriesResponse } from "../../../openapi/model/parsedTimeSeriesResponse";

interface Props {
  device: DeviceAsset | GatewayAsset;
  searchParams: AssetTimeSeriesRequestParams;
  render: DeviceTimeSeriesDisplayComponent;
}

const AssetTimeSeriesDataGetter = (props: Props): JSX.Element => {
  const { device, searchParams } = props;
  const { assetId, companyId, schemeId } = device;
  const { startDatetime, endDatetime } = searchParams;

  const viewStyle = searchParams.projection === "Raw" ? "Raw" : "Standard";

  const [{ data, loading, error }] = useGetData<
    RawTimeSeriesResponse | ParsedTimeSeriesResponse
  >({
    path: `data/asset/${assetId}/time_series`,
    companyId,
    schemeId,
    params: {
      startDatetime: searchParams.startDatetime.toISOString(),
      endDatetime: searchParams.endDatetime.toISOString(),
      projection: searchParams.projection === "Raw" ? "raw" : "parsed",
    },
    singletonApi: true,
  });

  const timeseries: DeviceTimeSeriesData | undefined =
    data &&
    extendDataToTimeRange(
      data.columns,
      data.data,
      data.errors,
      'deviceErrors' in data ? data.deviceErrors : undefined,
      convertTimesToMoment(data.times),
      startDatetime,
      endDatetime
    );


  const displayProps = (): DeviceTimeSeriesDisplayComponentProps => {
    if (data && timeseries) {
      return {
        mappedTimeSeriesColumns: mapColumns(timeseries, viewStyle === "Raw"),
        times: timeseries.times as Moment[],
        params: searchParams,
        loading: loading,
        error: error ? error : undefined,
      };
    } else {
      return {
        mappedTimeSeriesColumns: {},
        times: [],
        params: searchParams,
        loading: loading,
        error: error ? error : undefined,
      };
    }
  };

  return <Row>{props.render(displayProps())}</Row>;
};

export default AssetTimeSeriesDataGetter;
