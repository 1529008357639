import React from 'react';
import {WirelessSurveyDataCollectorOut} from "../../openapi/model/wirelessSurveyDataCollectorOut";
import {Badge, Button, Col, Row, Table} from "reactstrap";
import WirelessSurveyDataCollectorCloseButton from "./WirelessSurveyDataCollectorCloseButton";
import {formatDate} from "../../components/utils/HighlightNullAndOldDates";
import CopyToClipboard from "../../components/utils/CopyToClipboard";
import AssetContainer, {OptionalAssetChildProps} from "../assets/AssetContainer";
import {isGatewayAsset} from "../assets/model";

function WirelessSurveyDataCollectorsList(props: {
    dataCollectors: WirelessSurveyDataCollectorOut[],
    refresh?: () => any

}) {

    return (
        <Row noGutters={true}>
            <Col xs={12}>


                <Table>
                    <thead>
                    <tr>
                        <th>
                            Data Collector ID
                        </th>
                        <th>Data Collector Name</th>
                        <th>Gateway Serial Number</th>
                        <th>Data Collection Times</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>
                    {
                        props.dataCollectors.map((dataCollector, index) => <tbody key={index}>
                        <tr>
                            <td>
                                <CopyToClipboard value={dataCollector.dataCollectorId} slice={8}/>
                            </td>
                            <td>
                                {dataCollector.dataCollectorName}
                            </td>
                            <td>
                                {dataCollector.serialNumber}{" "}
                                <AssetContainer assetId={dataCollector.assetId} companyId={dataCollector.companyId}>
                                    {({asset, loading}: OptionalAssetChildProps) =>
                                        <>{loading ?
                                            'LOADING' :
                                            asset && isGatewayAsset(asset) && (asset.state?.connection || 'UNKNOWN')}</>
                                    }
                                </AssetContainer>
                            </td>
                            <td>
                                {formatDate(dataCollector.startDateTime, undefined, true)}
                                -
                                {dataCollector.endDateTime === undefined ? 'now' : formatDate(dataCollector.endDateTime, undefined, true)}
                            </td>
                            <td>
                                {dataCollector.endDateTime === undefined ? <WirelessSurveyDataCollectorCloseButton
                                        dataCollector={dataCollector}
                                        postUpdate={props.refresh}
                                    /> :
                                    <Badge color={'warning'}>Closed</Badge>}
                            </td>
                        </tr>
                        </tbody>)
                    }
                </Table>
            </Col>
            {props.refresh &&<Col xs={12} className={'text-right' }>
                <Button size={'sm'} color={'primary'}
                onClick={props.refresh}>
                    Refresh
                </Button>

            </Col>}
        </Row>

    );
}

export default WirelessSurveyDataCollectorsList;