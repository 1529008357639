import React from "react";
import ReportConfigurationForm, {
  ReportConfigurationFormProps,
} from "./ReportConfigurationForm";
import { ErrorBanner } from "../../../components/Error/ErrorBanner";

interface Props extends ReportConfigurationFormProps {
  createError?: Error;
  creating: boolean;
}

function ReportExecutionCreator(props: Props) {
  return (
    <>
      <ReportConfigurationForm {...props} />
      <ErrorBanner error={props.createError} />
    </>
  );
}

export default ReportExecutionCreator;
