import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CompanyContext } from "../../reducers/company";
import { useSelectedScheme } from "../../reducers/scheme";
import { addCompanyAndSchemeToUrl } from "../../components/navigation/URL";
import { usePrivacyMode } from "../../reducers/privacy";

const CompanySchemeHashUpdate = (props: {
  children: React.ReactNode;
}): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const company = useContext(CompanyContext);
  const scheme = useSelectedScheme();
  const privacyMode = usePrivacyMode();

  const companyState = company.state;

  useEffect(() => {
    if (!companyState.loading && !companyState.stale) {
      addCompanyAndSchemeToUrl(
        location,
        navigate,
        companyState.selected,
        scheme,
        privacyMode
      );
    }
    // we should ignore changes in location when updating the hash
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, companyState, scheme]);

  return <>{props.children}</>;
};

export default CompanySchemeHashUpdate;
