import React from "react";
import CompanyNavLink from "../navigation/CompanyNavLink";
import {Moment} from "moment";
import {createSearchString} from "components/navigation/SearchString";

const LocationRowLink = (props: {
    locationId?: string;
    children: React.ReactNode;
    startDatetime?: Moment;
    endDatetime?: Moment;
}): JSX.Element => {
    const {locationId, startDatetime, endDatetime} = props;

    const link =
        startDatetime || endDatetime
            ? `/admin/location/${locationId}?${createSearchString({
                startDatetime: startDatetime?.toISOString(true),
                endDatetime: endDatetime?.toISOString(true),
            })}`
            : `/admin/location/${locationId}`;

    return (
        <>
            {locationId &&
                    <CompanyNavLink to={link} className={"px-0"} external={true} inline>
                    {props.children}
                </CompanyNavLink>
            }
        </>
    );
};

export default LocationRowLink;
