import React from "react";
import { PageDefinition } from "../navigation/breadcrumbs/Breadcrumbs";
import MeterpointCard from "./Cards/MeterpointCard";
import MultipleCardPage from "./MutlipleCardPage";

interface Props {
  headerName?: string;
  headerParentName?: string;
  headerTitle: string;
  children: any;
  breadcrumbs?: PageDefinition[];
  noSchemeInBreadcrumbs?: boolean;
  loading?: boolean;
  "data-cy"?: string;
}

export const OneCardPage = (props: Props): JSX.Element => {
  return (
    <MultipleCardPage
      breadcrumbs={props.breadcrumbs}
      noSchemeInBreadcrumbs={props.noSchemeInBreadcrumbs}
    >
      <MeterpointCard {...props} />
    </MultipleCardPage>
  );
};
