/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// reactstrap components
import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";
import md5 from "js-md5";
import SchemeSelect from "../../containers/schemes/SchemeSelect";
import CompanySelect from "../../containers/company/CompanySelect";
import SwitchUserType from "../user/switchUserType";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../reducers/reactStore";
import { SchemeContext } from "../../reducers/scheme";
import { CompanyContext } from "reducers/company";
import { PrivacyContext } from "reducers/privacy";
import SwitchPrivacyMode from "components/user/switchPrivacyMode";
import { ProfileContext, useProfile } from "../../reducers/profile";
import { AuthContext, logout } from "../../reducers/auth";

interface PProps {
  toggleSidenav: () => void;
  sidenavOpen: boolean;
  theme: string;
}

export default function AdminNavbar(props: PProps): React.ReactElement {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useProfile();

  const { dispatch } = useContext(AppContext);
  const schemeContext = useContext(SchemeContext);
  const companyContext = useContext(CompanyContext);
  const privacyContext = useContext(PrivacyContext);
  const authContext = useContext(AuthContext);
  const profileContext = useContext(ProfileContext);

  // function that on mobile assets makes the search open
  const openSearch = (): void => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };
  // function that on mobile assets makes the search close
  // const closeSearch = (): void => {
  //   document.body.classList.remove("g-navbar-search-shown");
  //   setTimeout(function () {
  //     document.body.classList.remove("g-navbar-search-show");
  //     document.body.classList.add("g-navbar-search-hiding");
  //   }, 150);
  //   setTimeout(function () {
  //     document.body.classList.remove("g-navbar-search-hiding");
  //     document.body.classList.add("g-navbar-search-hidden");
  //   }, 300);
  //   setTimeout(function () {
  //     document.body.classList.remove("g-navbar-search-hidden");
  //   }, 500);
  // };

  const handleLogout = async (): Promise<void> => {
    dispatch({ type: "CLEAR_REFERER" });
    companyContext.dispatch({ type: "LOGOUT" });
    schemeContext.dispatch({ type: "LOGOUT" });
    privacyContext.dispatch({ type: "LOGOUT" });
    logout(authContext.dispatch).finally(() => {
      authContext.dispatch({ type: "LOGOUT" });
      profileContext.dispatch({ type: "LOGOUT" });
      navigate("/auth/login");
    });
  };

  return user ? (
    <>
      <Navbar
        className={classNames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark bg-primary": props.theme === "dark" },
          { "navbar-light bg-secondary": props.theme === "light" }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Nav
              className="align-items-center align-self-center align-self-start mr-auto"
              navbar
            >
              <NavItem className="d-xl-none d-lg-none">
                <div
                  className={classNames(
                    "pr-3 sidenav-toggler",
                    { active: props.sidenavOpen },
                    { "sidenav-toggler-dark": props.theme === "dark" }
                  )}
                  onClick={props.toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
              <NavItem className="d-sm-none">
                <NavLink onClick={openSearch}>
                  <i className="ni ni-zoom-split-in" />
                </NavLink>
              </NavItem>
            </Nav>
            <Nav
              className="align-items-center ml-auto align-self-end ml-md-0"
              navbar
            >
              <CompanySelect />
              <SchemeSelect />
              <UncontrolledDropdown nav>
                <DropdownToggle
                  className="nav-link pr-0"
                  color=""
                  tag="a"
                  data-cy={"navbar-profile"}
                >
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={
                          "https://www.gravatar.com/avatar/" +
                          md5(user.emailAddress as string) +
                          "?d=mp"
                        }
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold nav-link-text avatar-link">
                        {user.emailAddress}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem
                    onClick={(): void => {
                      navigate("/admin/profile" + location.hash);
                    }}
                  >
                    <i className="ni ni-single-02" />
                    <span>Profile</span>
                  </DropdownItem>
                  <SwitchUserType />
                  <SwitchPrivacyMode />
                  <DropdownItem divider />
                  <DropdownItem
                    data-cy={"navbar-logout"}
                    onClick={handleLogout}
                  >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  ) : (
    <></>
  );
}
