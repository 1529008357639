import React from "react";


const BlockSpinner = (props: { loading: boolean; children: React.ReactNode, scrollX?: boolean }): JSX.Element => {
    const {scrollX=false} = props;
    return <div style={{position: 'relative', display: 'inline-block', width: '100%',
        overflowX: scrollX ? 'scroll' : 'inherit'
    }} >
        {props.children}
        {props.loading && <div style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: 100
        }} data-cy={'block-spinner'}>
            <div style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                opacity: 0.7
            }}/>
            <div style={{position: 'absolute', width: '100%', height: '100%'}}>
                <ul className="bokeh" style={{
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                    position: 'absolute'
                }}>
                    <li/>
                    <li/>
                    <li/>
                </ul>
            </div>
        </div>}
    </div>

}


export default BlockSpinner;