/**
 * Meterpoint.io
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type ActionTypes = 'FACTORY_RESET_2100' | 'FIRMWARE_UPGRADE' | 'SYNC_CONFIG' | 'SYNC_DEVICE_LIST' | 'SYNC_CONFIG_SMS' | 'SYNC_DEVICE_LIST_SMS' | 'SETUP_WIRELESS_RECEIVERS' | 'MBUS_SEARCH' | 'GET_CONFIG' | 'ASSIGN_TO_COMPANY' | 'CREATE_ELVACO_2100' | 'SWITCH_RELAY';

export const ActionTypes = {
    FACTORYRESET2100: 'FACTORY_RESET_2100' as ActionTypes,
    FIRMWAREUPGRADE: 'FIRMWARE_UPGRADE' as ActionTypes,
    SYNCCONFIG: 'SYNC_CONFIG' as ActionTypes,
    SYNCDEVICELIST: 'SYNC_DEVICE_LIST' as ActionTypes,
    SYNCCONFIGSMS: 'SYNC_CONFIG_SMS' as ActionTypes,
    SYNCDEVICELISTSMS: 'SYNC_DEVICE_LIST_SMS' as ActionTypes,
    SETUPWIRELESSRECEIVERS: 'SETUP_WIRELESS_RECEIVERS' as ActionTypes,
    MBUSSEARCH: 'MBUS_SEARCH' as ActionTypes,
    GETCONFIG: 'GET_CONFIG' as ActionTypes,
    ASSIGNTOCOMPANY: 'ASSIGN_TO_COMPANY' as ActionTypes,
    CREATEELVACO2100: 'CREATE_ELVACO_2100' as ActionTypes,
    SWITCHRELAY: 'SWITCH_RELAY' as ActionTypes
};