import React from "react";
import AssetDataSearchStringParser from "../AssetDataSearchStringParser";
import { DeviceAsset } from "../../../../openapi/model/deviceAsset";
import { GatewayAsset } from "../../../../openapi/model/gatewayAsset";
import AssetTimeSeriesQualityGetter from "../AssetTimeSeriesQualityGetter";
import AssetDataQualityChart from "./AssetDataQualityChart";

interface Props {
  device: DeviceAsset | GatewayAsset;
}

const AssetDataQualityContainer = (props: Props): JSX.Element => {
  return (
    <AssetDataSearchStringParser device={props.device}>
      {({ searchParams }) => {
        return (
          <AssetTimeSeriesQualityGetter
            device={props.device}
            searchParams={searchParams}
            render={(props) => <AssetDataQualityChart {...props} />}
          />
        );
      }}
    </AssetDataSearchStringParser>
  );
};

export default AssetDataQualityContainer;
