import {Routes} from "react-router-dom";
import {SentryRoute} from "../App";
import React from "react";
import DashboardPage from "containers/dashboard/DashboardPage";
import CompanyPage from "../containers/company/CompanyPage";
import ProfilePage from "../containers/profile/ProfilePage";
import UsersPage from "../containers/users/UsersPage";
import SchemesPage from "../containers/schemes/SchemesPage";
import LocationsPage from "../containers/locations/LocationsPage";
import AssetPositionPage from "../containers/assetPositions/AssetPositionPage";
import AssetPage from "../containers/assets/AssetPage";
import ReportPage from "../containers/report/ReportPage";
import AssetRegisterPage from "../containers/assetRegister/AssetRegisterPage";
import JobsPage from "../containers/jobs/JobsPage";
import EventListPage from "../containers/event/EventListPage";
import AlarmListPage from "containers/event/AlarmListPage";
import AdminLandingPage from "layouts/admin/AdminLandingPage";
import WirelessClusterPage from "../containers/wirelessCluster/WirelessClusterPage";
import NotesListPage from "../containers/notes/NotesListPage";
import ConfigurationHomePage from "../containers/configuration/ConfigurationHomePage";
import TariffPage from "../containers/tariff/TariffPage";
import DebugLayout from "../components/navigation/DebugLayout";
import AccountPage from "../containers/account/AccountPage";
import BillingReportPage from "../containers/report/BillingReportPage";
import IntegrationPage from "../containers/integrations/IntegrationPage";
import WirelessSurveyPage from "../containers/wirelessSurvey/WirelessSurveyPage";
import TimeSeriesDataExportPage from "../containers/report/TimeSeriesDataExportPage/TimeSeriesDataExportPage";


const AdminRoutes = (): JSX.Element => {
  return (
    <Routes>
      <SentryRoute element={<DebugLayout />}>
        <SentryRoute path={"/"} element={<AdminLandingPage />} />
        <SentryRoute path={"/dashboard"} element={<DashboardPage />} />
        <SentryRoute path={"/schemes/*"} element={<SchemesPage />} />
        <SentryRoute
          path={"/asset_register/*"}
          element={<AssetRegisterPage />}
        />
        <SentryRoute path={"/notes"} element={<NotesListPage />} />
        <SentryRoute
          path="/wireless_cluster/*"
          element={<WirelessClusterPage />}
        />
        <SentryRoute
          path="/wireless_survey/*"
          element={<WirelessSurveyPage />}
        />
        <SentryRoute
          path={"/integrations/*"}
          element={<IntegrationPage/>}
          />
        <SentryRoute
          path={"/asset_position/*"}
          element={<AssetPositionPage />}
        />
        <SentryRoute path={"/location/*"} element={<LocationsPage />} />
        <SentryRoute path={"/asset/*"} element={<AssetPage />} />
        <SentryRoute path={"/users/*"} element={<UsersPage />} />
        <SentryRoute path={"/report/*"} element={<ReportPage />} />
        <SentryRoute path={"/job/*"} element={<JobsPage />} />
        <SentryRoute path={"/company/*"} element={<CompanyPage />} />
        <SentryRoute path={"/event/*"} element={<EventListPage />} />
        <SentryRoute path={"/alarm/*"} element={<AlarmListPage />} />
        <SentryRoute path={"/profile/*"} element={<ProfilePage />} />

        <SentryRoute path="/export" element={<TimeSeriesDataExportPage />} />
        <SentryRoute
          path={"/configuration/*"}
          element={<ConfigurationHomePage />}
        />
        <SentryRoute path={"/billing/tariff/*"} element={<TariffPage />} />
        <SentryRoute path={"/billing/account/*"} element={<AccountPage />} />
        <SentryRoute
          path={"/billing/report/*"}
          element={<BillingReportPage />}
        />
      </SentryRoute>
    </Routes>
  );
};

export default AdminRoutes;
