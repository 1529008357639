import React from 'react';
import {WirelessSurveyDataCollectorOut} from "../../openapi/model/wirelessSurveyDataCollectorOut";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import {WirelessSurveyOut} from "../../openapi/model/wirelessSurveyOut";
import WirelessSurveyDataCollectorCreateForm from "./WirelessSurveyDataCollectorCreateForm";
import {Col, Row} from "reactstrap";
import WirelessSurveyDataCollectorsList from "./WirelessSurveyDataCollectorsList";

function WirelessSurveyDataCollectorsTab(props: {
    survey: WirelessSurveyOut,
    dataCollectors?: WirelessSurveyDataCollectorOut[],
    loading: boolean,
    refresh?: () => any
}) {
    const {dataCollectors, loading, survey} = props;
    return (
        <>
            <BlockSpinner loading={loading}>
                {!loading && <><Row>
                    <Col xs={12}>
                <h3>Data Collectors</h3>
                <WirelessSurveyDataCollectorsList dataCollectors={dataCollectors || []}
                                                  refresh={props.refresh}/>

                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <h3>Add New Data Collector</h3>
                        <WirelessSurveyDataCollectorCreateForm survey={survey}
                                                               refresh={props.refresh}/>
                    </Col>
                </Row></>}
            </BlockSpinner>
        </>

    );
}

export default WirelessSurveyDataCollectorsTab;