import React from "react";
import { Location } from "../../openapi/model/location";
import LocationIdLink from "../../components/Location/LocationIdLink";
import NumberOfAssetPositionsDisplayWidget from "../../components/Common/NumberOfAssetPositionsDisplayWidget";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import { Button } from "reactstrap";
import BulkUpdate from "../../components/BulkUpdate/BulkUpdate";
import ExportActionButton from "core/buttons/ExportActionButton";
import GetListPaginatedTable from "../../components/Pagination/GetListPaginatedTable";
import PrivacyMode from "components/Text/PrivacyMode";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";

interface Props {
  companyId: string;
  schemeId?: string;
}

export default function LocationsList(props: Props): JSX.Element {
  const { companyId, schemeId } = props;

  const params = {
    companyId,
    schemeId,
  };

  const header = (
    <tr>
      <th scope={"col"} className={"text-left"}>
        Location ID
      </th>
      <th scope="col" className="text-left">
        Scheme ID
      </th>
      <th scope="col" className="text-left">
        Address
      </th>
      <th scope="col" className={"text-left"}>
        Reference
      </th>
      <th scope={"col"} className={"text-left"}>
        Asset Positions
      </th>
      <DisplayIfUserType userTypes={"superadmin"}>
        <th scope={"col"} className={"text-left"}>
          Group
        </th>
      </DisplayIfUserType>
    </tr>
  );

  const row = (location: Location): JSX.Element => {
    return (
      <tr data-cy="locations-list-item" key={location.locationId}>
        <td data-cy={"location-id"}>
          <LocationIdLink location={location} />
        </td>
        <td data-cy="location-name">
          <PrivacyMode>{location.schemeId}</PrivacyMode>
        </td>
        <td data-cy="location-location">
          <PrivacyMode>{location.address}</PrivacyMode>
        </td>
        <td data-cy="location-reference">
          <PrivacyMode>{location.customerReference || "-"}</PrivacyMode>
        </td>
        <td data-cy={"location-asset-positions"}>
          <NumberOfAssetPositionsDisplayWidget
            positions={location.numberOfAssetPositions}
            index={location.locationId}
          />
        </td>
        <DisplayIfUserType userTypes={"superadmin"}>
          <td>
            <PrivacyMode>{location.group || "-"}</PrivacyMode>
          </td>
        </DisplayIfUserType>
      </tr>
    );
  };
  const extraButtons = (
    <>
      <ExportActionButton entityType={"LOCATION"} params={params} />
      {schemeId && (
        <>
          <CompanyNavLink to={`/admin/location/create`} tag={Button}>
            Add Location
          </CompanyNavLink>
          <BulkUpdate
            companyId={companyId}
            schemeId={schemeId}
            objectType={"location"}
          />
          <CompanyNavLink to={"/admin/location/updates"} tag={Button}>
            Previous Uploads
          </CompanyNavLink>
        </>
      )}
    </>
  );

  return (
    <GetListPaginatedTable
      entityType={"LOCATION"}
      params={{ companyId, schemeId }}
      cursor={{ maxItems: 10 }}
      data-cy={"location-list"}
      header={[header, 4]}
      row={row}
      additionalFunctionButtons={extraButtons}
    />
  );
}
