import { Moment } from "moment";
import Highcharts from "highcharts";
import { columnToTimeNumberData } from "../../containers/assets/meters/heatMeters/HeatMeterFlowAndTemperatureChart";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import HighchartsReact from "highcharts-react-official";
import React from "react";
import BlockSpinner from "../Spinners/BlockSpinner";
import ErrorOverlappingBanner from "../Error/ErrorOverlappingBanner";

export interface DataQualityTimeSeriesAreaChartProps {
  times: Array<Moment>;
  missing: Array<number>;
  errors: Array<number>;
  valueErrors: Array<number>;
  incomplete: Array<number>;
  complete: Array<number>;
  expected: Array<number>;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  loading: boolean;
  error?: Error | Error[];
  size?: number;
}

export const DataQualityTimeSeriesAreaChart = (
  props: DataQualityTimeSeriesAreaChartProps
): JSX.Element => {
  const cardSizes = {
    xs: props.xs || 12,
    sm: props.sm,
    md: props.md,
    lg: props.lg,
    xl: props.xl,
  };

  const {
    times,
    missing,
    errors,
    incomplete,
    expected,
    complete,
    valueErrors,
    size = 400,
  } = props;

  const series: (
    | Highcharts.SeriesAreaOptions
    | Highcharts.SeriesLineOptions
  )[] = [
    {
      data: columnToTimeNumberData(missing, times),
      name: "Rows Not Received",
      type: "area",
      color: "var(--red)",
      animation: false,
      showInLegend: true,
    },
    {
      data: columnToTimeNumberData(valueErrors, times),
      name: "Rows With Value Error(s)",
      type: "area",
      color: "var(--red-orange)",
      animation: false,
      showInLegend: true,
    },
    {
      data: columnToTimeNumberData(errors, times),
      name: "Invalid Rows",
      type: "area",
      color: "var(--yellow)",
      animation: false,
      showInLegend: true,
    },
    {
      data: columnToTimeNumberData(incomplete, times),
      name: "Incomplete but Otherwise Valid Rows",
      type: "area",
      color: "var(--orange-green)",
      animation: false,
      showInLegend: true,
    },
    {
      data: columnToTimeNumberData(complete, times),
      name: "Complete Valid Rows",
      type: "area",
      color: "var(--green)",
      animation: false,
      showInLegend: true,
    },
    {
      data: columnToTimeNumberData(expected, times).map((val, index) => {
        return {
          x: val[0],
          y: val[1],
          dataLabels:
            index === expected.length - 2
              ? {
                  enabled: true,
                  format: "Expected: {y}",
                }
              : { enabled: false },
        };
      }),
      name: "Expected Rows",
      type: "line",
      color: "red",
      lineWidth: 2,
      dashStyle: "LongDash",
      animation: false,
      showInLegend: false,
      enableMouseTracking: false,
    },
  ];

  const options: Highcharts.Options = {
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
    },
    chart: {
      animation: false,
      height: size,
    },
    series: series,
    legend: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    lang: {
      noData: props.loading ? "Loading Data" : "No data for this time period",
    },
    tooltip: {
      shared: true,
    },
    yAxis: [
      {
        // Primary yAxis
        title: {
          text: "Data Points",
        },
        min: 0,
        max: expected[0],
      },
    ],
    plotOptions: {
      area: {
        stacking: "normal",
        marker: {
          enabled: false,
        },
        lineWidth: 0,
      },
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    noData: {
      style: {
        fontWeight: "normal",
        fontSize: "15px",
        color: "black",
      },
    },
    exporting: {
      fallbackToExportServer: false,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "downloadSVG",
          ],
        },
      },
    },
  };

  return (
    <>
      <Col {...cardSizes}>
        <BlockSpinner loading={props.loading}>
          <ErrorOverlappingBanner error={props.error}>
            <Card>
              <CardHeader>
                <h6 className="surtitle">Quality</h6>
                <h5 className="h3 mb-0">Daily Data Quality Assessment</h5>
              </CardHeader>
              <CardBody>
                <div
                  className="chart"
                  style={{ minHeight: `${size}px`, height: `${size}px` }}
                >
                  <HighchartsReact highcharts={Highcharts} options={options} />
                </div>
              </CardBody>
            </Card>
          </ErrorOverlappingBanner>
        </BlockSpinner>
      </Col>
    </>
  );
};

export default DataQualityTimeSeriesAreaChart;
