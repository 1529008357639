import React from "react";
import {OneCardPage} from "components/utils/OneCardPage";
import RequireScheme from "../../../components/Scheme/RequireScheme";
import {Col, Row} from "reactstrap";
import {AggregatedReportRequest} from "../../../openapi/model/aggregatedReportRequest";
import BillingReportSelectorForm, {BillingReportRequestParams,} from "./BillingReportSelectorForm";
import {inclusiveDatePeriod} from "../../../components/Forms/DateTimePicker";
import {createSearchString, parseSearchString,} from "../../../components/navigation/SearchString";
import moment from "moment";
import {BillingConfigurationInitialValues} from "../../jobs/FormatConfigurationForms/BillingConfigurationForm";
import {AssetType} from "openapi/model/assetType";
import BillingReportView from "./BillingReportView";
import AggregatedTimeSeriesApiWrapper from "../aggregatedTimeSeriesReport/AggregatedTimeSeriesApiWrapper";
import {useLocation, useNavigate} from "react-router-dom";
import {AggregationDefinition} from "../../../openapi/model/aggregationDefinition";
import {CumulativeDataConfiguration} from "../../../openapi/model/cumulativeDataConfiguration";
import {matchPeriod} from "../../../components/TimeSeries/model";

const BillingReportPage = (props: {
  companyId?: string;
  schemeId?: string;
}): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchStringToParams = (): Required<BillingReportRequestParams> => {
    const [startDate, endDate] = inclusiveDatePeriod(undefined, undefined, 7);
    const searchParams = parseSearchString(location.search);
    const startTime = searchParams.startTime
      ? moment(searchParams.startTime as string)
      : startDate;
    const endTime = searchParams.startTime
      ? moment(searchParams.endTime as string)
      : moment(+endDate);
    const period = matchPeriod(searchParams.period as string);
    console.log(searchParams)
    return {
      startTime,
      endTime,
      period,
      configuration: {
        ...BillingConfigurationInitialValues(false).configuration,
        ...(searchParams?.configuration as unknown as CumulativeDataConfiguration),
      },
    };
  };

  const selectData = (values: BillingReportRequestParams): void => {
    if (values.endTime) {
      const [startTime, endTime] = [
        values.startTime.toISOString(true),
        values.endTime.toISOString(true),
      ];
      navigate({
        search: createSearchString({
          startTime,
          endTime,
          period: values.period,
          configuration: values.configuration,
        }),
        hash: location.hash,
      });
    }
  };

  const { startTime, endTime, period, configuration } = searchStringToParams();
  const request: AggregatedReportRequest | undefined =
    props.companyId && props.schemeId
      ? {
          companyId: props.companyId,
          schemeId: props.schemeId,
          assetTypes: configuration.includedAssetTypes?.map((x) =>
            (x as string).toLowerCase()
          ) as Array<unknown> as Array<AssetType>,
          aggregations: [
            "Energy (Heating)",
            "Energy (Cooling)",
            "Energy (Electrical Active Import)",
          ]
            .map((v): AggregationDefinition => {
              return {
                parameter: v,
                aggregation: "last",
                ...configuration.energy,
              };
            })
            .concat([
              {
                parameter: "Volume",
                aggregation: "last",
                ...configuration.volume,
              },
            ]),
          period,
          startTime: startTime.toDate(),
          endTime: endTime.toDate(),
          dataLabel: configuration.periodLabel,
        }
      : undefined;
  console.log(request)
  const queryParams = searchStringToParams();
  return (
    <OneCardPage
      headerTitle={"Billing Data Report"}
      breadcrumbs={[
        { url: "/admin/report/billing/data", name: "Billing Data" },
      ]}
    >
      <RequireScheme>
        <Row>
          <Col xs={12}>
            <BillingReportSelectorForm {...queryParams} action={selectData} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {request && (
              <AggregatedTimeSeriesApiWrapper request={request}>
                {({ data }) => (
                  <>
                    {data && props.schemeId && (
                      <BillingReportView
                        schemeId={props.schemeId}
                        data={data}
                        query={queryParams}
                      />
                    )}
                  </>
                )}
              </AggregatedTimeSeriesApiWrapper>
            )}
          </Col>
        </Row>
      </RequireScheme>
    </OneCardPage>
  );
};

export default BillingReportPage;
