import React from 'react';
import {AssetPositionAssetLinkOut} from "../../../openapi/model/assetPositionAssetLinkOut";
import {AssetPositionAssetRowInnerProps} from "./AssetPositionAssetRowInner";
import useUpdateEntity from "../../../hooks/updateEntity";

function AssetPositionAssetRowContainer(props: {
    link: AssetPositionAssetLinkOut,
    refreshList?: () => void,
    children: ((props: AssetPositionAssetRowInnerProps) => React.ReactNode)
}): React.ReactElement {

    const {link, refreshList} = props;

    const updateEntity = useUpdateEntity<AssetPositionAssetLinkOut>(
        'LINK', link.linkId, props.refreshList
    )

    return <>{props.children({
        link, refreshList, updateEntity
    })}</>
}

export default AssetPositionAssetRowContainer;