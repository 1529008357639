import React from "react";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import FormComponent from "../../components/Forms/FormComponent";
import { Alert, Button, Col } from "reactstrap";
import { Company } from "../../openapi/model/company";
import { useSelectedCompany } from "../../reducers/company";
import RadioButtonGroup from "../../components/Forms/RadioButtonGroup";
import { CompanyType } from "openapi/model/companyType";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import { CompanyRole } from "openapi/model/companyRole";
import { yupPassword } from "components/Forms/yupValidators";
import { NewCompany } from "openapi/model/newCompany";
import { NewUser } from "../../openapi/model/newUser";

export interface CreateCompanyFormValues {
  companyName: string;
  companyId: string;
  emailAddress: string;
  createUser: boolean;
  companyType: CompanyType;
  password: string;
  userType: CompanyRole;
}

interface Props {
  onSubmit: (newCompany: NewCompany, newUser?: NewUser) => Promise<any>;
}

export default function CreateCompanyForm(props: Props): JSX.Element {
  const selectedCompany = useSelectedCompany();

  const partner: Company | undefined =
    selectedCompany?.companyType === "PARTNER" ? selectedCompany : undefined;

  const submit = (
    values: CreateCompanyFormValues,
    { setSubmitting, resetForm }: FormikHelpers<CreateCompanyFormValues>
  ): void => {
    setSubmitting(true);
    const newCompany = {
      name: values.companyName,
      companyId: values.companyId,
      companyType: values.companyType,
      partnerId:
        values.companyType === CompanyType.CLIENT
          ? selectedCompany?.companyId
          : undefined,
    };
    const newUser = values.createUser
      ? {
          emailAddress: values.emailAddress,
          fullname: values.emailAddress,
          password: values.password,
          userType: values.userType,
        }
      : undefined;
    props
      .onSubmit(newCompany, newUser)
      .then(() => resetForm())
      .finally(() => setSubmitting(false));
  };

  const initialValues = {
    companyName: "",
    emailAddress: "",
    password: "",
    createUser: true,
    companyType: CompanyType.CLIENT,
    companyId: "",
    userType: CompanyRole.Viewer,
  };
  return (
    <DisplayIfUserType userTypes={"admin"} companyType={"PARTNER"}>
      <Formik
        onSubmit={submit}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          companyName: Yup.string().required("Required"),
          companyId: Yup.string()
            .required()
            .min(5, "Id is too short -  should be 5 chars minimum")
            .max(50, "Id is too long - should be be 50 chars maximum")
            .matches(
              /[a-z0-9-]/,
              "Id can only contain letters a-z, numbers 0-9 or a `-`"
            ),
          emailAddress: Yup.string()
            .email()
            .when("createUser", {
              is: true,
              then: Yup.string().email().required("Required"),
            }),
          password: Yup.string().when("createUser", {
            is: true,
            then: yupPassword(),
          }),
        })}
      >
        {({
          isValid,
          isSubmitting,
          values,
          setFieldValue,
        }): JSX.Element => (
          <Form aria-label={"create-company-form"}>
            <div className={"row"}>
              <div className={"col-3"}>
                <h3 className="text-white mb-0">Add Company</h3>
              </div>
            </div>
            <div className={"row"}>
              <FormComponent
                fieldName={"companyId"}
                label={"Id"}
                submitSpinner={false}
              />
              <FormComponent
                fieldName={"companyName"}
                label={"Name"}
                submitSpinner={false}
              />
              <DisplayIfUserType userTypes={"superadmin"}>
                <RadioButtonGroup
                  fieldName={"companyType"}
                  title={"Company type"}
                  options={[
                    { name: "partner", value: "PARTNER" },
                    { name: "client", value: "CLIENT" },
                  ]}
                  currentValue={values["companyType"]}
                  setFieldValue={setFieldValue}
                  submitSpinner={false}
                />
              </DisplayIfUserType>{" "}
              <RadioButtonGroup
                fieldName={"createUser"}
                title={"Create User"}
                options={[
                  { name: "yes", value: true },
                  { name: "no", value: false },
                ]}
                currentValue={values["createUser"]}
                setFieldValue={setFieldValue}
                submitSpinner={false}
              />
              {values.createUser && (
                <>
                  <FormComponent
                    fieldName={"emailAddress"}
                    label={"User email"}
                    submitSpinner={false}
                    disabled={!values.createUser}
                  />
                  <FormComponent
                    fieldName={"password"}
                    label={"User password"}
                    type={"password"}
                    submitSpinner={false}
                    disabled={!values.createUser}
                  />
                  <RadioButtonGroup
                    fieldName={"userType"}
                    title={"User type"}
                    options={[
                      { name: "admin", value: "admin" },
                      { name: "editor", value: "editor" },
                      { name: "viewer", value: "viewer" },
                    ]}
                    currentValue={values["userType"]}
                    setFieldValue={setFieldValue}
                    submitSpinner={false}
                    disabled={!values.createUser}
                  />
                </>
              )}
              {partner && (
                <Col xs={12}>
                  <Alert color={"primary"}>
                    This new company will be created as a client of{" "}
                    {partner.name}
                  </Alert>
                </Col>
              )}
              <Col xs={12} className={"text-right"}>
                <Button
                  type={"submit"}
                  color={"primary"}
                  disabled={isSubmitting || !isValid}
                >
                  Create
                </Button>
              </Col>
            </div>
          </Form>
        )}
      </Formik>
    </DisplayIfUserType>
  );
}
