/**
 * Meterpoint.io
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type Period = 'DAILY' | 'WEEKLY' | 'SEVEN_DAY' | 'MONTHLY' | 'ALL';

export const Period = {
    DAILY: 'DAILY' as Period,
    WEEKLY: 'WEEKLY' as Period,
    SEVENDAY: 'SEVEN_DAY' as Period,
    MONTHLY: 'MONTHLY' as Period,
    ALL: 'ALL' as Period
};