import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  convertStringUndefined,
  createSearchString,
  parseSearchString,
} from "../components/navigation/SearchString";
import { ParsedQs } from "qs";

interface Result<T> {
  params: T;
  updateFilters: (values: T) => void;
  ready: boolean;
}

export default function useGetParsedSearchString<T>(
  convert?: (params: ParsedQs) => T
): Result<T> {
  const location = useLocation();
  const navigate = useNavigate();

  const [state, setState] = useState<{ params: T; ready: boolean }>({
    ready: false,
    params: {} as T,
  });

  const setFiltersToRoute = useCallback(
    (values: T): void => {
      const stringValues = { ...values };
      navigate({
        pathname: location.pathname,
        search: createSearchString(convertStringUndefined(stringValues)),
        hash: location.hash,
      });
    },
    [location.pathname, location.hash, navigate]
  );

  useEffect(() => {
    const queryParams: ParsedQs = parseSearchString(location.search);
    const params = convert
      ? convert(queryParams)
      : (queryParams as unknown as T);

    setState({
      params: params,
      ready: true,
    });
    setFiltersToRoute(params);
  }, [location.search, setFiltersToRoute, convert]);

  return {
    ...state,
    updateFilters: setFiltersToRoute,
  };
}
