import React from "react";
import { useParams } from "react-router-dom";
import { SchemeElementProps } from "../Scheme/SelectedScheme";
import GetEntity from "../../core/action/GetEntity";
import GenericUpdatesDisplay from "./GenericUpdatesDisplay";
import { OneCardPage } from "../utils/OneCardPage";
import { ErrorBanner } from "../Error/ErrorBanner";

export interface GenericUpdateProps extends SchemeElementProps {
  title: string;
  header: string;
  tableFieldHeaders: string[];
  tableFields: string[];
  objectKey: string;
}

export default function GenericUpdatesPage(
  props: GenericUpdateProps
): JSX.Element {
  const { updateId } = useParams();
  if (!updateId) {
    throw new Error("SelectedUpdateId is required");
  }
  const { companyId, schemeId } = props;

  return (
    <GetEntity
      entityType={"UPDATE"}
      entityId={updateId}
      companyId={companyId}
      schemeId={schemeId}
      singletonApi={true}
      error={({ error }) => (
        <OneCardPage headerTitle={"Update"} breadcrumbs={[{ name: "Error" }]}>
          <ErrorBanner error={error} />
        </OneCardPage>
      )}
      placeholder={() => (
        <OneCardPage
          headerTitle={"Update"}
          loading={true}
          breadcrumbs={[{ name: "Loading" }]}
        >
          <></>
        </OneCardPage>
      )}
    >
      {(display) => <GenericUpdatesDisplay {...display} {...props} />}
    </GetEntity>
  );
}
