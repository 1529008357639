import React from "react";
import {
  Option,
  SelectComponent,
  SelectFieldProps,
} from "../../components/Forms/SelectComponent";
import { Company } from "openapi/model/company";
import { useCompanies } from "../../reducers/company";

interface PProps {
  fieldName: string;
  allowNone?: boolean;
  errors?: any;
  touched?: any;
}

type Props = PProps & SelectFieldProps;

const CompanySelectDropdown = (props: Props): JSX.Element => {
  const { fieldName, allowNone, ...rest } = props;

  const companies = useCompanies() || [];
  let options: Option[] = companies.map((company: Company) => {
    return {
      value: company.companyId,
      label: company.name,
    };
  });

  options = allowNone
    ? [{ value: "", label: "Select Company" }, ...options]
    : options;

  return (
    <SelectComponent
      title={"Company"}
      options={options}
      name={fieldName}
      {...rest}
    />
  );
};

export default CompanySelectDropdown;
