import React from 'react';
import {HeatNetworkReportParams} from "./heatNetworkAnalysis/model";
import {AssetType} from "../../openapi/model/assetType";
import {Col, Row} from "reactstrap";
import EnergyBalancerView from "../../components/TimeSeries/EnergyBalanceView/EnergyBalancerView";
import TimeSeriesStatsApiWrapper, {AggregatedTimeSeriesStatsParams} from "./latestDataReport/TimeSeriesStatsApiWrapper";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import {GroupBy} from "../../openapi/model/groupBy";

interface Props {
    companyId?: string;
    schemeId?: string;
    query: HeatNetworkReportParams
}

function EnergyBalanceReportPage(props: Props) {
    const [startDatetime, endDatetime] = [props.query.startDate, props.query.endDate];
    const params = (): AggregatedTimeSeriesStatsParams => {
        if (props.companyId && props.schemeId) {
            return {
                companyId: props.companyId,
                schemeId: props.schemeId,
                parameter: "Energy (Heating)",
                aggregation: "difference",
                assetTypes: [AssetType.HeatMeter, AssetType.HeatingAndCoolingMeter],
                startDatetime,
                endDatetime,
                period: "MONTHLY",
                groupBy: GroupBy.GROUPALL

            };
        } else {
            throw new Error("Scheme or Company is not selected.");
        }
    };
    return (
        <>
            {props.companyId && props.schemeId && (
                        <>
                            <Row>
                                <TimeSeriesStatsApiWrapper request={params()}>
                                    {({data, loading}) => (
                                        <BlockSpinner loading={loading}>
                                            <Col xs={12}>
                                                <EnergyBalancerView title={'Energy Balance'} data={data || {}}
                                                startDate={startDatetime} endDate={endDatetime}
                                                />
                                            </Col>
                                        </BlockSpinner>
                                    )}
                                </TimeSeriesStatsApiWrapper>
                            </Row>
                        </>
            )}
        </>
    )
}

export default EnergyBalanceReportPage;