import React from 'react';
import {useField} from "formik";


export interface TextInputFieldProps {
    name: string;
    children: (props: {
        name: string,
    onChange: (value: string, event: Pick<React.ChangeEvent, "target">) => void;
        value: string,
        error?: string,
        touched?: boolean,
    }) => React.ReactNode;
    onChange?: (value: string, name: string) => Promise<any>
}
function TextInputField(props: TextInputFieldProps) {
    const [{value,}, {error, touched}, {setValue, setTouched}] = useField(props.name);

    const onChangeFormikSet = (newValue: string) => {
        setTouched(true);

        if (props.onChange) {
            props.onChange(newValue, props.name).then(() => setValue(newValue))
        } else {
            setValue(newValue)
        }
    };

    return <>{props.children({name: props.name, onChange: onChangeFormikSet, value, error, touched})}</>
}

export default TextInputField;