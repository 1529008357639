import React from "react";
import MeterpointCard from "../../components/utils/Cards/MeterpointCard";
import { Table } from "reactstrap";
import UserCompanyListItemForm from "./UserCompanyListItemForm";
import { convertUserToCompanyUser } from "../users/users";
import { useProfile } from "../../reducers/profile";

export default function UserCompanyList(): JSX.Element {
  const user = useProfile();

  return (
    <MeterpointCard headerTitle={"Companies"}>
      <Table>
        <thead>
          <tr>
            <th>Company</th>
            <th>User Group</th>
            <th>Receive Alerts</th>
            <th> </th>
          </tr>
        </thead>
        {user ? (
          <tbody>
            {user.companies.map((c, i) => {
              return (
                <UserCompanyListItemForm
                  key={i}
                  showCompanyId={true}
                  userCompany={convertUserToCompanyUser(user, c.companyId)}
                />
              );
            })}
          </tbody>
        ) : (
          <></>
        )}
      </Table>
    </MeterpointCard>
  );
}
