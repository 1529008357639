import React, {useState} from "react";
import {Button, Col, Row} from "reactstrap";
import {DeviceAsset} from "../../../../openapi/model/deviceAsset";
import {Formik} from "formik";
import * as Yup from "yup";
import BlockSpinner from "../../../../components/Spinners/BlockSpinner";
import MBusConfigurationSubForm, {MBusConfigurationFormSchema,} from "./MbusConfigurationForm";
import {DeviceConfiguration} from "../../../../openapi/model/deviceConfiguration";
import useUpdateEntity from "../../../../hooks/updateEntity";
import {ErrorBanner} from "components/Error/ErrorBanner";
import {objectBlankStringsToUndefined} from "../../../../utils/object";
import SuccessBanner from "../../../../components/Banner/SuccessBanner";
import _ from "lodash";

export default function DeviceConfigurationTab(props: {
  asset: DeviceAsset;
}): JSX.Element {
  const configuration: DeviceConfiguration = props.asset
    .configuration as DeviceConfiguration;

  const [updated, setUpdated] = useState(false);
  const { updateEntity, updating, error } = useUpdateEntity(
    "ASSET",
    props.asset.assetId,
    () => {
      setUpdated(true);
    }
  );

  const initialValues: DeviceConfiguration = {
    mbus: {
      primaryAddress: _.isNumber(configuration.mbus?.primaryAddress)
        ? configuration.mbus?.primaryAddress
        : undefined,
      addressingMode: configuration.mbus?.addressingMode || "secondary",
      encryptionKey: configuration.mbus?.encryptionKey || "",
      baudRate: configuration.mbus?.baudRate || 2400,
    },
  };

  function submit(values: DeviceConfiguration) {
    setUpdated(false);
    updateEntity({
      ...props.asset,
      configuration: {
        ...values,
        mbus: objectBlankStringsToUndefined(values.mbus),
      },
    });
  }

  return (
    <Row>
      <Col xs={12}>
        <Formik
          onSubmit={submit}
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            mbus: MBusConfigurationFormSchema,
          })}
          validateOnMount={true}
          enableReinitialize={true}
        >
          {({ submitForm, isValid, values }): JSX.Element => {
            return (
              <BlockSpinner loading={updating}>
                <Row>
                  <Col xs={12}>
                    <MBusConfigurationSubForm addressingMode={values?.mbus?.addressingMode} namespace={"mbus"}/>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className={"text-right"}>
                    <Button
                      type={"submit"}
                      color={"primary"}
                      onClick={submitForm}
                      disabled={updating || !isValid}
                    >
                      Update
                    </Button>
                  </Col>
                  <Col xs={12}>
                    {updated && (
                      <SuccessBanner
                        text={"Configuration was updated successfully."}
                      />
                    )}
                    <ErrorBanner error={error} />
                  </Col>
                </Row>
              </BlockSpinner>
            );
          }}
        </Formik>
      </Col>
    </Row>
  );
}
