export type DateValueMap = { [datetime: string]: number }
export type DeliveredEnergy = {
    [useCase: string]: DateValueMap;
}
export type DeliveredEnergyByGroup = {
    [group: string]: DeliveredEnergy
}
export type UnaccountedEnergyByGroup = {
    [group: string]: DateValueMap
}


export function EnergyDataToCSV(
    deliveredEnergyData: DeliveredEnergyByGroup,
    unaccountedEnergyData: UnaccountedEnergyByGroup,
): any[] {
    return [...Object.entries(deliveredEnergyData).map(([group, deliveredEnergy]) => {
        return Object.entries(deliveredEnergy).map(([useCase, dateValueMap]) => {
                return {
                    group: `delivered-${group}`,
                    useCase,
                    ...dateValueMap
                }
            }
        )
    }).flat().flat(),
        ...Object.entries(unaccountedEnergyData).map(([group, dateValueMap]) => {
            return {
                group,
                useCase: 'UNACCOUNTED',
                ...dateValueMap
            }
        }).flat()
    ]
}


