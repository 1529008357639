import { Cursor } from "../../openapi/model/cursor";
import { RequestHeaders } from "./apiService";
import { getAuthHeader } from "./auth";
import { removeMapBlanks } from "../../utils/object";

export interface GetResponse {
  data: any;
  cursor?: Cursor;
  headers: any;
}

export const parseEtags = (response: GetResponse) => {
  return response.headers["xEtags"]
    ? JSON.parse(response.headers["xEtags"])
    : {};
};

export async function getHeaders(
  request: RequestHeaders = {}
): Promise<{ [key: string]: string }> {
  const auth = await getAuthHeader();
  const { traceId, spanId, eTag } = request;
  return removeMapBlanks({
    ...auth,
    Accept: "application/xls",
    "X-Transaction-ID": traceId,
    "X-Span-ID": spanId,
    "If-Match": eTag,
  });
}
