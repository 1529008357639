/**
 * Meterpoint.io
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type CumulativeValueTimeSeriesField = 'Energy (Heating)' | 'Energy (Cooling)' | 'Energy (Electrical Active Import)' | 'Energy (Electrical Active Export)' | 'Energy (Electrical Reactive Import)' | 'Energy (Electrical Reactive Export)' | 'Volume';

export const CumulativeValueTimeSeriesField = {
    EnergyHeating: 'Energy (Heating)' as CumulativeValueTimeSeriesField,
    EnergyCooling: 'Energy (Cooling)' as CumulativeValueTimeSeriesField,
    EnergyElectricalActiveImport: 'Energy (Electrical Active Import)' as CumulativeValueTimeSeriesField,
    EnergyElectricalActiveExport: 'Energy (Electrical Active Export)' as CumulativeValueTimeSeriesField,
    EnergyElectricalReactiveImport: 'Energy (Electrical Reactive Import)' as CumulativeValueTimeSeriesField,
    EnergyElectricalReactiveExport: 'Energy (Electrical Reactive Export)' as CumulativeValueTimeSeriesField,
    Volume: 'Volume' as CumulativeValueTimeSeriesField
};