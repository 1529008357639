import React from "react";
import useGetAssetRegisterEntitiesCombination, {
  AssetRegisterEntityResult,
  GetAssetRegisterEntitiesProps,
} from "../../hooks/getAssetRegisterEntitiesCombination";
import _ from "lodash";

export interface AssetRegisterEntityProps extends AssetRegisterEntityResult {
  refresh: () => void;
}

export type AssetRegisterEntitySuccessProps = Omit<
  AssetRegisterEntityProps,
  "error"
>;

interface Props {
  children: (props: AssetRegisterEntitySuccessProps) => JSX.Element;
  error: (props: { error: Error }) => JSX.Element;
  placeholder: () => JSX.Element;
}

const GetAssetRegisterEntitiesCombination = (
  props: Props & GetAssetRegisterEntitiesProps
): JSX.Element => {
  const { children, ...params } = props;
  const [{ error, asset, assetPosition, location, loading }, refresh] =
    useGetAssetRegisterEntitiesCombination(params);

  const apiError = error !== null && error !== undefined;

  const notFoundError =
    (props.assetPositionId &&
      (assetPosition === undefined || location === undefined)) ||
    (assetPosition?.assetId && asset === undefined);

  const actualError = apiError
    ? _.isArray(error)
      ? error[0]
      : error
    : notFoundError && Error("Not found.");

  return (
    <>
      {loading
        ? props.placeholder()
        : actualError
        ? props.error({ error: actualError })
        : !notFoundError &&
          children({ loading, asset, assetPosition, location, refresh })}
    </>
  );
};

export default GetAssetRegisterEntitiesCombination;
