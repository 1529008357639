import { DateRangePicker } from "react-dates";
import React, { useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./DateRangePicker.scss";
import { useField } from "formik";
import {
  FieldHelperProps,
  FieldInputProps,
  FieldMetaProps,
} from "formik/dist/types";
import moment, { Moment } from "moment";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";
import { DEFAULT_PRESETS, Preset } from "./DateRangePickerPresets";
import { Col, Row } from "reactstrap";

function isSameDay(a: any, b: any): boolean {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  // Compare least significant, most likely to change units first
  // Moment's isSame clones moment inputs and is a tad slow
  return (
    a.date() === b.date() && a.month() === b.month() && a.year() === b.year()
  );
}

interface PropTypes {
  startDateName: string; // name of the form field for the startDate
  endDateName: string; // name of the form field for the endDate
  minDate?: Moment;
  maxDate?: Moment;
  clearable?: boolean;
  minDays?: number;
}

function useFieldProperties<Val = any>(
  fieldName: string
): {
  field: FieldInputProps<Val>;
  meta: FieldMetaProps<Val>;
  helpers: FieldHelperProps<Val>;
} {
  const [field, meta, helpers] = useField(fieldName);
  return { field, meta, helpers } as const;
}

const DateRangePickerComponent = (props: PropTypes): JSX.Element => {
  const [focusedDates, setFocusedDates] = useState<
    "startDate" | "endDate" | null
  >(null);
  const [id, setId] = useState<string>(moment().toISOString());
  const startDate = useFieldProperties(props.startDateName);
  const endDate = useFieldProperties(props.endDateName);
  const isSmall = useMediaQuery({ maxDeviceWidth: 999 });
  const isXSmall = useMediaQuery({ maxDeviceWidth: 700 });
  const { clearable = false, minDays = 1 } = props;

  const handleSelect = (
    ranges: { startDate: moment.Moment | null; endDate: moment.Moment | null },
    preset = false
  ): void => {
    if (ranges.startDate) {
      startDate.helpers.setValue(moment(ranges.startDate).startOf("day"));
    } else {
      startDate.helpers.setValue(undefined);
    }
    if (ranges.endDate) {
      endDate.helpers.setValue(moment(ranges.endDate).endOf("day"));
    } else {
      endDate.helpers.setValue(undefined);
    }
    if (preset) {
      setId(moment().toISOString());
    }
  };

  const presets: Preset[] = DEFAULT_PRESETS;

  const renderDatePresets = (): JSX.Element => {
    return (
      <Col className={"pb-4 pt-0 px-4 mb-2"}>
        <Row className={"mx-0"}>
          <Col xs={12} className={"py-1 px-0"}>
            <h3>Presets</h3>
          </Col>
          <Col xs={12} className={"py-1 px-0"}>
            {presets.map(({ text, start, end }) => {
              const isSelected =
                isSameDay(start, startDate.field.value) &&
                isSameDay(end, endDate.field.value);
              return (
                <button
                  key={text}
                  type="button"
                  className={classNames({
                    active: isSelected,
                    "btn btn-primary": true,
                  })}
                  onClick={(): void =>
                    handleSelect({ startDate: start, endDate: end }, true)
                  }
                >
                  {text}
                </button>
              );
            })}
          </Col>
        </Row>
      </Col>
    );
  };

  return (
    <DateRangePicker
      key={id}
      startDate={startDate.field.value} // momentPropTypes.momentObj or null,
      startDateId="datepickerstart" // PropTypes.string.isRequired,
      endDate={endDate.field.value} // momentPropTypes.momentObj or null,
      endDateId="datepickerend" // PropTypes.string.isRequired,
      onDatesChange={handleSelect} // PropTypes.func.isRequired,
      focusedInput={focusedDates} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
      onFocusChange={(focusedInput): void => setFocusedDates(focusedInput)} // PropTypes.func.isRequired,
      inputIconPosition={"after"}
      showDefaultInputIcon={true}
      numberOfMonths={isSmall ? (isXSmall ? 1 : 2) : 3}
      isDayBlocked={(): boolean => false}
      isOutsideRange={(day: moment.Moment): boolean => {
        const minDate = props.minDate || moment().subtract(5, "year");
        const maxDate = props.maxDate || moment();
        return minDate < day && day > maxDate;
      }}
      renderCalendarInfo={renderDatePresets}
      minDate={props.minDate || moment().subtract(5, "year")}
      maxDate={props.maxDate || moment()}
      navPosition={"navPositionTop"}
      showClearDates={clearable}
      minimumNights={minDays}
    />
  );
};

export default DateRangePickerComponent;
