import React, { useEffect, useState } from "react";
import { AssetTimeSeriesRequestParams } from "./AssetDataSelectionForm";
import { parseSearchString } from "../../../components/navigation/SearchString";
import moment from "moment";
import { doesDeviceHaveParsedData } from "../../../model/assets/deviceData";
import inclusiveDatePeriod from "../../../components/Forms/DateTimePicker";
import { DeviceAsset } from "../../../openapi/model/deviceAsset";
import { GatewayAsset } from "../../../openapi/model/gatewayAsset";
import { useLocation } from "react-router-dom";

interface Props {
  children: ({
    searchParams,
  }: {
    searchParams: AssetTimeSeriesRequestParams;
  }) => JSX.Element;
  device: DeviceAsset | GatewayAsset;
}

const AssetDataSearchStringParser = (props: Props): JSX.Element => {
  const [searchParams, setSearchParams] = useState<
    AssetTimeSeriesRequestParams | undefined
  >(undefined);

  const location = useLocation();

  useEffect(() => {
    const [startDate, endDate] = inclusiveDatePeriod(
      undefined,
      undefined,
      undefined,
      true
    );
    const searchParams = parseSearchString(location.search);

    const endDatetime = searchParams.startDatetime
      ? moment(searchParams.endDatetime as string)
      : moment(+endDate);

    const startDatetime = searchParams.startDatetime
      ? moment(searchParams.startDatetime as string)
      : startDate;

    const style: string = (
      searchParams.projection
        ? searchParams.projection
        : doesDeviceHaveParsedData(props.device)
        ? "Standard"
        : "Raw"
    ) as string;

    setSearchParams({
      startDatetime,
      endDatetime,
      projection: style,
    });
  }, [location.search, props.device]);

  return <>{searchParams && props.children({ searchParams })}</>;
};

export default AssetDataSearchStringParser;
