import React, {useEffect, useState} from 'react';
import useWebSocket, {ReadyState} from "react-use-websocket";
import {GatewayAsset} from "../../../../openapi/model/gatewayAsset";
import {getWebSocketURL} from "../../../../actions/helpers/auth";
import {Table} from "reactstrap";
import StreamingGatewayDataTableRow from './StreamingGatewayDataTableRow';


type StreamingGatewayDataRow = [string,string, string, string, string, string, string, string, string, string,string, string,];


function StreamingGatewayData(props: {gateway: GatewayAsset}) {
  const [messageHistory, setMessageHistory] = useState<StreamingGatewayDataRow[]>([]);
  const { sendMessage, lastMessage, readyState } = useWebSocket(
      getWebSocketURL
  );
 useEffect(() => {
    if (lastMessage !== null) {
        setMessageHistory(prevState => [...(JSON.parse(lastMessage.data) as StreamingGatewayDataRow[]), ...prevState])
    }
  }, [lastMessage, setMessageHistory]);

    useEffect(() => {
        if (readyState === ReadyState.OPEN) {
            sendMessage(
                JSON.stringify({
                    "action": "GetGatewayData",
                    "company_id": props.gateway.companyId,
                    "asset_id": props.gateway.assetId
                })
            )
        }
    }, [readyState, sendMessage, props.gateway.companyId, props.gateway.assetId]);

    return (
        <>
            {readyState !== ReadyState.OPEN && <>Connection: {ReadyState[readyState]}</>}
            <Table size={'sm'}>
                <tr>
                    <th>Timestamp</th>
                    <th>Manufacturer</th>
                    <th>Secondary Address</th>
                    <th>Medium</th>
                    <th>Version</th>
                    <th>RSSI</th>
                    <th>Data</th>
                </tr>
                {messageHistory.map((message, idx) =>
        <StreamingGatewayDataTableRow key={messageHistory.length - idx}
                                      date={message[2]}
                                      data={message[3]}
                                      secondaryAddress={message[7]}
                                      manufacturer={message[4]}
                                      medium={message[5]}
                                      version={message[6]}
                                      rssi={message[8]}
        />)
      }
            </Table>
            </>
    );
}

export default StreamingGatewayData;
