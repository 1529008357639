import React, { useState } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { Alert, Button, Col } from "reactstrap";
import { Auth } from "aws-amplify";
import PageSpinner from "components/Spinners/PageSpinner";

interface Props {
  handleEmail: (emailAddress: string) => any;
}

interface State {
  success: boolean;
  error: boolean;
  errorMessage: string;
}

interface FormValues {
  emailAddress: string;
}

const ForgottenPasswordForm = (props: Props): JSX.Element => {
  const [state, setState] = useState<State>({
    success: false,
    error: false,
    errorMessage: "",
  });

  const submit = (
    { emailAddress }: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ): void => {
    setSubmitting(true);
    Auth.forgotPassword(emailAddress)
      .then(() => {
        resetForm();
        setState({ success: true, error: false, errorMessage: "" });
        props.handleEmail(emailAddress);
      })
      .catch((err) => {
        setState({
          success: false,
          error: true,
          errorMessage: err.message,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialValues = {
    emailAddress: "",
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={Yup.object().shape({
          emailAddress: Yup.string().email().required("Required."),
        })}
        validateOnMount={true}
      >
        {({ errors, touched, isValid, isSubmitting }): JSX.Element => (
          <Form>
            <PageSpinner spin={isSubmitting}>
              <div className="form-row">
                <Col className="mb-3" md="12">
                  <label
                    className="form-control-label"
                    htmlFor="resetPasswordEmailAddress"
                  >
                    Enter email address
                  </label>
                  <Field
                    aria-describedby="inputGroupPrepend"
                    id="resetPasswordEmailAddress"
                    type="text"
                    name="emailAddress"
                    className={
                      "form-control " +
                      (errors.emailAddress && touched.emailAddress
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="emailAddress"
                    component="div"
                    className="invalid-feedback"
                  />
                </Col>
              </div>
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting || !isValid}
              >
                <span>Reset password</span>
              </Button>
            </PageSpinner>
          </Form>
        )}
      </Formik>
      {state.success && (
        <Alert color="success" className={"mt-3"}>
          A reset token has been sent to your email address.
        </Alert>
      )}
      {state.error && (
        <Alert color="danger" className={"mt-3"}>
          Password reset failed with error: {state.errorMessage}
        </Alert>
      )}
    </>
  );
};

export default ForgottenPasswordForm;
