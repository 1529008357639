import React from "react";
import {Routes} from "react-router-dom";

import {SentryRoute} from "../../App";
import WirelessSurveyListPage from "./WirelessSurveyListPage";
import WirelessSurveyCreatePage from "./WirelessSurveyCreatePage";
import WirelessSurveyHomepage from "./WirelessSurveyHomepage";

export default function WirelessSurveyPage(): JSX.Element {
  return (
    <Routes>
      <SentryRoute path="" element={<WirelessSurveyListPage />} />
      <SentryRoute path={"create"} element={<WirelessSurveyCreatePage/>}/>
        <SentryRoute path={"view/:surveyId"} element={<WirelessSurveyHomepage/>}>
            <SentryRoute path={":tab"} element={<WirelessSurveyHomepage/>}/>
        </SentryRoute>

    </Routes>
  );
}
