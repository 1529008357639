import { User } from "openapi/model/user";
import React, { createContext, useReducer } from "react";
import {
  updateUserCompany,
  updateUserCompanyProperties,
} from "../containers/users/users";

interface ProfileState {
  user?: User;
  loading: boolean;
  forceViewerMode?: boolean;
}

type UserActions =
  | "PROFILE_FETCHING_SUCCESS"
  | "PROFILE_FETCHING"
  | "PROFILE_FETCHING_ERROR"
  | "SWITCH_TO_VIEWER_TYPE"
  | "PROFILE_UPDATING_SUCCESS"
  | "USER_UPDATING_SUCCESS"
  | "LOGOUT";

export interface UserAction {
  type: UserActions;
  params?: any;
  payload?: any;
}

const Reducer = (state: ProfileState, action: UserAction): ProfileState => {
  console.log(action);
  switch (action.type) {
    /// Profile
    case "PROFILE_FETCHING_SUCCESS":
      return {
        ...state,
        user: action.payload,
        loading: false,
      };

    case "PROFILE_FETCHING":
      return {
        ...state,
        user: undefined,
        loading: true,
      };

    case "PROFILE_FETCHING_ERROR":
      return {
        ...state,
        user: undefined,
        loading: false,
      };

    case "SWITCH_TO_VIEWER_TYPE":
      return {
        ...state,
        forceViewerMode: action.payload,
      };

    case "USER_UPDATING_SUCCESS":
      return {
        ...state,
        user:
          state.user && state.user.userId === action.params.userId
            ? updateUserCompany(
                state.user,
                action.params.companyId,
                action.payload.object
              )
            : state.user,
      };

    case "PROFILE_UPDATING_SUCCESS":
      return {
        ...state,
        user: state.user
          ? updateUserCompanyProperties(
              state.user,
              action.params.companyId,
              action.payload.object
            )
          : state.user,
      };

    case "LOGOUT":
      return {
        ...state,
        user: undefined,
      };

    default:
      return state;
  }
};

const ProfileContext = createContext<{
  state: ProfileState;
  dispatch: React.Dispatch<UserAction>;
}>({ state: { loading: false }, dispatch: () => null });

const ProfileProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(Reducer, { loading: false });

  return (
    <ProfileContext.Provider value={{ state, dispatch }}>
      {children}
    </ProfileContext.Provider>
  );
};

function useProfile(): User | undefined {
  return React.useContext(ProfileContext).state.user;
}

export { ProfileProvider, ProfileContext, useProfile };
