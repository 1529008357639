import {AssetUseCase} from "openapi/model/assetUseCase";
import React from "react";
import _ from "lodash";
import {OptionsList, SelectCombo, SelectComboProps} from "../Forms/SelectCombo/SelectCombo";
import {ParsedQs} from "qs";
import {convertStringToOpenApiEnum} from "../../utils/enum";

type  NewAssetUseCaseSelectProps = Omit<SelectComboProps, 'options' | 'placeholder'>

export function matchStringsToAssetUseCaseEnum(
  lst: string | string[] | ParsedQs | ParsedQs[] | undefined
): AssetUseCase[] | undefined {
  if (_.isNil(lst)) {
    return undefined;
  }
  if (_.isArray(lst)) {
    return lst
      .map((v) => {
        if (_.isString(v)) {
          return convertStringToOpenApiEnum<AssetUseCase>(v, AssetUseCase, true);
        } else {
          return undefined;
        }
      })
      .filter((v) => !_.isNil(v)) as AssetUseCase[];
  }
  const val = _.isString(lst)
    ? convertStringToOpenApiEnum<AssetUseCase>(lst, AssetUseCase, true)
    : undefined;
  if (val) {
    return [val as AssetUseCase];
  }
}
export  default function AssetUseCaseSelect(
  props: NewAssetUseCaseSelectProps
): JSX.Element {

  const options: OptionsList = [
    { label: "None", value: AssetUseCase.NONE },
    { label: "Domestic", value: AssetUseCase.DOMESTIC },
    { label: "Commercial", value: AssetUseCase.COMMERCIAL },
    { label: "Process", value: AssetUseCase.PROCESS },
  ].filter((v) => !_.isNil(v)) as OptionsList;

  return <SelectCombo options={options} {...props} placeholder={'All'}/>;
}
