import React from "react";
import {WirelessSurveyOut} from "../../openapi/model/wirelessSurveyOut";
import {ListGroupItem} from "reactstrap";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";

export default function WirelessSurveyListRowInner(props: {
  wirelessSurvey: WirelessSurveyOut;
}): JSX.Element {
  const { wirelessSurvey } = props;

  return (
          <ListGroupItem className={"px-0 py-1 flex-column align-items-start"}>
      <div className="d-flex w-100 justify-content-between">
        <div>
          <div className="d-flex w-100 align-items-center">
              <CompanyNavLink to={`view/${wirelessSurvey.surveyId}`}>
                  <h5 className={"mb-0"}>{wirelessSurvey.surveyName}</h5>
              </CompanyNavLink>
          </div>
        </div>
      </div>
              <p className="text-sm mb-0 mt-1">{wirelessSurvey.surveyId}</p>
    </ListGroupItem>

  );
}
