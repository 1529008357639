import React, { useCallback, useState } from "react";
import { Badge, Collapse, Nav, NavItem, NavLink } from "reactstrap";
import routes, {
  CollapseRoute,
  isCollapseRoute,
  RouteTypes,
} from "../../routes/routes";
import DisplayIfUserType from "../utils/DisplayIfUserType/DisplayIfUserType";
import classnames from "classnames";
import CompanyNavLink from "../navigation/CompanyNavLink";
import { NavLink as NavLinkRRD, useLocation } from "react-router-dom";
import { useSelectedCompany } from "../../reducers/company";
import { Company } from "../../openapi/model/company";
import { CompanyType } from "../../openapi/model/companyType";

interface State {
  collapseStates: { [key: string]: boolean };
}

const getCompanyType = (company?: Company): CompanyType | undefined => {
  return company?.companyType;
};

const SidebarMenu = (props: { closeSidenav: () => void }): JSX.Element => {
  const { closeSidenav } = props;

  const location = useLocation();
  const selectedCompany = useSelectedCompany();

  const [state, setState] = useState<State>({
    collapseStates: {},
  });

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string): string => {
    return location.pathname === routeName ? "active" : "";
  };

  const filterRoutes = (routes: RouteTypes[]): RouteTypes[] => {
    const companyType = getCompanyType(selectedCompany);
    const companyTypeFilter = companyType ? companyType : "NONE";

    const filteredRoutes = routes.filter(
      (route: RouteTypes) =>
        route.layout === "/admin" &&
        route.companyType.includes(companyTypeFilter)
    );
    return filteredRoutes.map((v) => {
      if (Object.hasOwnProperty.call(v, "views")) {
        const collapseRoute = v as CollapseRoute;
        return {
          ...collapseRoute,
          views: filterRoutes(collapseRoute.views),
        } as CollapseRoute;
      } else {
        return v;
      }
    });
  };

  const toggleCollapseLink = (name: string): void => {
    setState({
      ...state,
      collapseStates: {
        ...state.collapseStates,
        [name]: !state.collapseStates[name],
      },
    });
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  const getCollapseInitialState = useCallback(
    (routes: RouteTypes[]): boolean => {
      for (const route of routes) {
        if (isCollapseRoute(route)) {
          if (route.collapse && getCollapseInitialState(route.views)) {
            return true;
          }
        } else {
          if (window.location.href.indexOf(route.path) !== -1) {
            return true;
          }
        }
      }
      return false;
    },
    []
  );
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes: RouteTypes[]): React.ReactNode => {
    return routes.map((prop, key) => {
      if (prop.invisible) {
        return null;
      }
      if (isCollapseRoute(prop)) {
        return (
          <DisplayIfUserType userTypes={prop.userGroup || "viewer"} key={key}>
            <NavItem>
              <NavLink
                href="#"
                data-toggle="collapse"
                aria-expanded={state.collapseStates[prop.name]}
                className={classnames({
                  active: getCollapseInitialState(prop.views),
                })}
                onClick={(e): void => {
                  e.preventDefault();
                  toggleCollapseLink(prop.name);
                }}
              >
                {prop.icon ? <i className={prop.icon} /> : null}
                <span className="nav-link-text">{prop.name}</span>
              </NavLink>
              <Collapse isOpen={state.collapseStates[prop.name]}>
                <Nav className="nav-sm flex-column">
                  {createLinks(prop.views)}
                </Nav>
              </Collapse>
            </NavItem>
          </DisplayIfUserType>
        );
      }
      return (
        <DisplayIfUserType userTypes={prop.userGroup || "viewer"} key={key}>
          <NavItem className={activeRoute(prop.layout + prop.path)}>
            <CompanyNavLink
              to={prop.layout + prop.path}
              onClick={closeSidenav}
              tag={NavLinkRRD}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <span className="nav-link-text">{prop.name}</span>
                  {prop.beta && (
                    <Badge className={"mx-2"} color={"warning"}>
                      Preview
                    </Badge>
                  )}
                </>
              ) : (
                <>
                  <span className={"nav-minor-link-text"}>{prop.name}</span>
                  {prop.beta && (
                    <Badge className={"mx-2"} color={"warning"}>
                      Preview
                    </Badge>
                  )}
                </>
              )}
            </CompanyNavLink>
          </NavItem>
        </DisplayIfUserType>
      );
    });
  };

  return (
    <Collapse navbar isOpen={true}>
      <Nav navbar>{createLinks(filterRoutes(routes))}</Nav>
    </Collapse>
  );
};
export default SidebarMenu;
