import React from 'react';
import CreateAndGetListCombo from "../../../components/CreateAndGetListCombo";
import {OutputType} from "../../../openapi/model/outputType";
import MultipleCardPage from "../../../components/utils/MutlipleCardPage";
import MeterpointCard from "../../../components/utils/Cards/MeterpointCard";
import ReportExecutionCreator from "../executions/ReportExecutionCreator";
import ReportExecutionList from "../executions/ReportExecutionList";
import {useSelectedCompany} from "../../../reducers/company";
import {useSelectedScheme} from "../../../reducers/scheme";
import _ from "lodash";
import BetaAlert from "../../../components/Beta/BetaAlert";


function TimeSeriesDataExportPage() {
    const company = useSelectedCompany();
    const scheme = useSelectedScheme();

      if (_.isUndefined(company)) {
        throw new Error("Company must be selected");
      }

    return (
            <CreateAndGetListCombo
      entityType={"REPORT_EXECUTION"}
      listParams={{
        companyId: company?.companyId,
        schemeId: scheme?.schemeId,
        format: [OutputType.AGGREGATEDDATA],
      }}
      cursor={{ maxItems: 15 }}
    >
      {({ create, createError, creating, list }) => (
        <MultipleCardPage>
          <MeterpointCard headerTitle={"Export Aggregated Data"}>
              <BetaAlert></BetaAlert>
            <ReportExecutionCreator
              scheme={scheme}
              company={company}
              {...{ create, createError, creating }}
              outputTypeFilter={[OutputType.AGGREGATEDDATA]}
            />
          </MeterpointCard>
          <MeterpointCard headerTitle={"Previous Executions"}>
            <ReportExecutionList list={list} />
          </MeterpointCard>
        </MultipleCardPage>
      )}
    </CreateAndGetListCombo>

    );
}

export default TimeSeriesDataExportPage;