import React from "react";
import { OneCardPage } from "../../components/utils/OneCardPage";
import AssetRegisterList from "./AssetRegisterList";
import AssetListFilterForm from "../assets/list/AssetListFilterForm";
import SearchParamPage from "../../components/Search/SearchParamPage";
import SelectedScheme from "components/Scheme/SelectedScheme";

const InnerAssetRegisterList = (props: any): JSX.Element => {
  return (
    <SelectedScheme>
      <AssetRegisterList {...props} />
    </SelectedScheme>
  );
};

const AssetPositionListPage = (): JSX.Element => {
  return (
    <OneCardPage
      headerTitle={"Asset Register"}
      breadcrumbs={[{ url: "/admin/asset_register", name: "Asset Register" }]}
    >
      <AssetListFilterForm
        includeNonCreateables={false}
        filterUnassignedField={"assetId"}
        includeTextSearch={true}
      />
      <SearchParamPage
        initialState={{
          assetType: undefined,
          assetId: undefined,
          textSearch: undefined,
          sort: 'location.address'
        }}
      >
        <InnerAssetRegisterList />
      </SearchParamPage>
    </OneCardPage>
  );
};

export default AssetPositionListPage;
