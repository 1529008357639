import React from "react";
import { Routes } from "react-router-dom";
import GatewayConnectivityReportPage from "../assets/gateway/GatewayConnectivityReportPage";
import SchemeAggregatedTimeSeriesDataReport from "./aggregatedTimeSeriesReport/SchemeAggregatedTimeSeriesDataReport";
import SchemeLatestTimeSeriesReport from "./latestDataReport/SchemeLatestTimeSeriesDataReport";
import { SentryRoute } from "../../App";
import DataQualityReportPageByRows from "./dataQualityReport/DataQualityReportPageByRows";
import HeatNetworkAnalysisReportPage from "./heatNetworkAnalysis/HeatNetworkAnalysisReportPage";
import BillingReportPage from "./billing/BillingReportPage";
import { useSelectedCompany } from "../../reducers/company";
import { useSelectedScheme } from "../../reducers/scheme";
import StatsTimeSeriesDataReportPage from "./statsReport/StatsTimeSeriesDataReportPage";

const ReportPage = () => {
  const schemeId = useSelectedScheme()?.schemeId;
  const companyId = useSelectedCompany()?.companyId;

  return (
    <Routes>
      <SentryRoute
        path="/billing/data"
        element={
          <BillingReportPage companyId={companyId} schemeId={schemeId} />
        }
      />

      <SentryRoute
        path="/gateway"
        element={<GatewayConnectivityReportPage />}
      />

      <SentryRoute
        path="/gateway/unassigned"
        element={<GatewayConnectivityReportPage assigned={false} />}
      />

      <SentryRoute
        path="time_series/aggregated"
        element={<SchemeAggregatedTimeSeriesDataReport />}
      >
        <SentryRoute
          path=":tab"
          element={<SchemeAggregatedTimeSeriesDataReport />}
        />
      </SentryRoute>

      <SentryRoute
        path="time_series/stats"
        element={<StatsTimeSeriesDataReportPage />}
      />

      <SentryRoute
        path="time_series/latest"
        element={
          <SchemeLatestTimeSeriesReport
            companyId={companyId}
            schemeId={schemeId}
          />
        }
      />

      <SentryRoute
        path="heat_network"
        element={
          <HeatNetworkAnalysisReportPage
            companyId={companyId}
            schemeId={schemeId}
          />
        }
      >
        <SentryRoute
          path=":tab"
          element={
            <HeatNetworkAnalysisReportPage
              companyId={companyId}
              schemeId={schemeId}
            />
          }
        />
      </SentryRoute>
      <SentryRoute
        path="/row_data_quality"
        element={
          <DataQualityReportPageByRows
            companyId={companyId}
            schemeId={schemeId}
          />
        }
      />
    </Routes>
  );
};

export default ReportPage;
