import React, { useState } from "react";
import GetListPaginatedTable from "../../components/Pagination/GetListPaginatedTable";
import { AssetPosition } from "openapi/model/assetPosition";
import { Note } from "../../openapi/model/note";
import { ExpandedNote } from "../../openapi/model/expandedNote";
import { Col, ListGroupItem, Row } from "reactstrap";
import moment from "moment";
import CreateNoteModalButton from "./CreateNoteModalButton";
import { useSelectedCompany } from "reducers/company";
import { useSelectedScheme } from "reducers/scheme";
import { NoteOutput } from "openapi/model/noteOutput";
import _ from "lodash";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import { Link } from "react-router-dom";
import NoteModal from "./NoteModal";
import PaginatedApiToCSVExportButton from "../../components/utils/CSVExport/PaginatedApiToCSVExportButton";
import AssetRegisterStatusLabel from "../../components/Labels/AssetRegisterStatusLabel";

interface Props {
  assetPosition?: AssetPosition;
}

function NoteListRow(
  note: Note | ExpandedNote,
  update: (updatedItem: Note) => void
): React.ReactElement {
  const expanded = !_.isNil((note as ExpandedNote).locationId);

  return (
    <ListGroupItem
      className={"align-items-start border-0 py-2"}
      key={note.noteId}
    >
      <Row>
        <Col xs={12} md={8}>
          <div className=" align-items-center">
            <span className={"align-bottom"}>
              <NoteModal note={note} onUpdate={update}>
                {({ openModal }) => {
                  return (
                    <h4 className={"mb-0"} role={"button"} onClick={openModal}>
                      {note.title}
                      <AssetRegisterStatusLabel
                        status={note.status}
                        className={"ml-2"}
                      />
                    </h4>
                  );
                }}
              </NoteModal>

              {expanded && (
                <>
                  <small className={"pr-2"}>
                    <CompanyNavLink
                      to={`/admin/location/${
                        (note as ExpandedNote).locationId
                      }`}
                      tag={Link}
                      external={true}
                    >
                      {(note as ExpandedNote).address}
                    </CompanyNavLink>
                  </small>
                  <small className={"pr-2"}>
                    Position:{" "}
                    <CompanyNavLink
                      tag={Link}
                      to={`/admin/asset_position/${note.assetPositionId}`}
                      external={true}
                    >
                      {(note as ExpandedNote).customerReference ||
                        `...${note.assetPositionId.slice(26)}`}
                    </CompanyNavLink>
                  </small>
                </>
              )}
            </span>
          </div>
        </Col>
        <Col xs={12} md={4} className={"text-md-right"}>
          <small>
            {moment(note.createdAt).format("LLL")} by {note.creator.userName}
          </small>
        </Col>
      </Row>
    </ListGroupItem>
  );
}

export default function NoteList(props: Props): React.ReactElement {
  const { assetPosition } = props;

  const selectedCompany = useSelectedCompany();
  const selectedScheme = useSelectedScheme();

  const [lastChange, setLastChange] = useState(new Date());

  const params = {
    companyId: selectedCompany?.companyId,
    schemeId: selectedScheme?.schemeId,
    assetPositionId: assetPosition?.assetPositionId,
    output: assetPosition ? NoteOutput.NOTE : NoteOutput.EXPANDED,
  };

  const filename: string = assetPosition
    ? `notes_export_${assetPosition.companyId}_${assetPosition.schemeId}_${assetPosition.assetPositionId}`
    : selectedScheme
    ? `notes_export_${selectedScheme.companyId}_${selectedScheme.schemeId}`
    : `notes_export_${selectedCompany?.companyId}`;

  return (
    <Row>
      {assetPosition && (
        <Col xs={12} className={"text-right"}>
          <CreateNoteModalButton
            assetPosition={assetPosition}
            onCreate={() => setLastChange(new Date())}
          />
        </Col>
      )}

      <Col xs={12}>
        <GetListPaginatedTable
          entityType={"NOTE"}
          params={params}
          lastChange={lastChange}
          cursor={{ maxItems: 25 }}
          data-cy={"note-list"}
          row={NoteListRow}
          header={[<></>, 4]}
          additionalFunctionButtons={
            <PaginatedApiToCSVExportButton
              entityType={"NOTE"}
              params={params as unknown as Record<string, string>}
              columnOrder={[
                "title",
                "content",
                "companyId",
                "schemeId",
                "locationId",
                "address",
                "assetPositionId",
                "customerReference",
                "createdAt",
                "creator.userId",
                "creator.userName",
                "noteId",
                "status",
              ]}
              filename={filename}
            />
          }
        />
      </Col>
    </Row>
  );
}
