import React from "react";
import SelectComponent from "../../../../components/Forms/SelectComponent";
import * as Yup from "yup";
import FormComponent from "../../../../components/Forms/FormComponent";
import {Col, Row} from "reactstrap";
import useUserHasRoles from "hooks/user/getCurrentUserRole";

export const WirelessReceiverConfigurationFormSchema = Yup.object().shape({
  ageLimit: Yup.number().min(1).max(9999),
  mbusBaudRate: Yup.number(),
});

const WirelessReceiverConfigurationForm = ({
  namespace,
}: { namespace: string }): JSX.Element => {

  const allowEditing = useUserHasRoles("admin");

    const withNamespace = (fieldName:string): string => {
    return namespace ? `${namespace}.${fieldName}` : fieldName
  }

  return (
    <>
      <Row>
        <Col xs={12}>
          <h4>Wireless Receiver Configuration</h4>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <SelectComponent
            label={"Baud Rate"}
            options={[
              { label: 2400, value: 2400 },
              { label: 9600, value: 9600 },
            ]}
            name={withNamespace("mbusBaudRate")}
            paddingX={0}
            submitSpinner={false}
            disabled={!allowEditing}
          />
        </Col>
        <Col xs={12}>
          <FormComponent
            fieldName={withNamespace("ageLimit")}
            label={"Data Age Limit (minutes)"}
            type={"number"}
            paddingX={0}
            submitSpinner={false}
            disabled={!allowEditing}
          />
        </Col>
      </Row>
    </>
  );
};


export default WirelessReceiverConfigurationForm;
