import React from "react";
import {
  ColumnMap,
  TimeSeriesDataParams,
} from "../../../model/deviceTimeSeries";
import { Moment } from "moment";
import ChartForSingleTimeSeriesItem from "./ChartForSingleTimeSeriesItem";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import ErrorOverlappingBanner from "../../../components/Error/ErrorOverlappingBanner";

const GenericCharts = (props: {
  mappedTimeSeriesColumns: ColumnMap;
  times: Moment[];
  params: TimeSeriesDataParams;
  loading: boolean;
  error?: Error;
}): JSX.Element => {
  return (
    <>
      {props.loading || props.error ? (
        <>
          <BlockSpinner loading={props.loading}>
            <ErrorOverlappingBanner error={props.error}>
              <ChartForSingleTimeSeriesItem
                column={{
                  name: "Data Field 1",
                  unit: "?",
                  data: [],
                  type: "instantaneous",
                }}
                times={[]}
                params={{}}
                loading={props.loading}
              />
            </ErrorOverlappingBanner>
          </BlockSpinner>
          <BlockSpinner loading={props.loading}>
            <ErrorOverlappingBanner error={props.error}>
              <ChartForSingleTimeSeriesItem
                column={{
                  name: "Data Field 2",
                  unit: "?",
                  data: [],
                  type: "instantaneous",
                }}
                times={[]}
                params={{}}
                loading={props.loading}
              />
            </ErrorOverlappingBanner>
          </BlockSpinner>
        </>
      ) : (
        Object.entries(props.mappedTimeSeriesColumns).map(
          ([_name, column], index) => {
            return (
              <ChartForSingleTimeSeriesItem
                column={column}
                times={props.times}
                params={props.params}
                key={index}
                loading={false}
              />
            );
          }
        )
      )}
    </>
  );
};

export default GenericCharts;
