import React, { useState } from "react";
import { Form, Formik } from "formik";
import AssetExpectedFrequencySwitch from "../../../components/Asset/AssetExpectedFrequencySwitch";
import { Alert, Button, Col } from "reactstrap";
import { GatewayAsset } from "../../../openapi/model/gatewayAsset";
import _ from "lodash";
import useCreateEntity from "../../../hooks/createEntity";
import AssetGatewaySettingTypeSwitch from "../../../components/Asset/AssetGatewaySettingTypeSwtich";
import { GatewaySettingsType } from "../../../openapi/model/gatewaySettingsType";
import RadioButtonGroup from "components/Forms/RadioButtonGroup";
import { ErrorBanner } from "../../../components/Error/ErrorBanner";

interface Props {
  gateway: GatewayAsset;
}

const GatewaySettingForm = (props: Props): JSX.Element => {
  const [state, setState] = useState<"error" | "completed" | undefined>(
    undefined,
  );

  const { createEntity, error } = useCreateEntity(
    "ASSET_CONFIGURATION",
    `asset/${props.gateway.assetId}`,
    true,
  );

  const isElvaco = props.gateway.manufacturer === "elvaco";

  const initialValues = {
    assetExpectedFrequency: props.gateway.configuration.collectionFrequency
      ? props.gateway.configuration.collectionFrequency.toString()
      : "15",
    assetSettingType:
      props.gateway.configuration.manufacturerSpecificSettings?.mbusSettings ||
      (isElvaco ? GatewaySettingsType.ELVACODEFAULT : undefined),
    assetPostFrequency: props.gateway.configuration.postFrequency
      ? props.gateway.configuration.postFrequency.toString()
      : "Default",
    postRawData:
      props.gateway.configuration.collectRawData ||
      (isElvaco ? false : undefined),
    autoSync: props.gateway.configuration.autoSync || false,
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }): void => {
        setState(undefined);
        createEntity({
          ...props.gateway,
          configuration: {
            ...props.gateway.configuration,
            autoSync: values.autoSync,
            collectionFrequency: _.toInteger(values.assetExpectedFrequency),
            manufacturerSpecificSettings: isElvaco
              ? {
                  mbusSettings: values.assetSettingType,
                }
              : undefined,
            postFrequency:
              values.assetPostFrequency === "Default"
                ? null
                : _.toInteger(values.assetPostFrequency),
            collectRawData: isElvaco ? values.postRawData : undefined,
          },
        })
          .then(() => setState("completed"))
          .finally(() => setSubmitting(false));
      }}
    >
      {({ setFieldValue, values, isSubmitting, isValid }): JSX.Element => (
        <Form>
          {isElvaco && (
            <AssetGatewaySettingTypeSwitch
              fieldName={"assetSettingType"}
              values={values}
              setFieldValue={setFieldValue}
              paddingX={0}
            />
          )}
          <AssetExpectedFrequencySwitch
            fieldName={"assetExpectedFrequency"}
            values={values}
            setFieldValue={setFieldValue}
            title={"Data Collection Frequency"}
            abbreviate={true}
            paddingX={0}
          />
          <AssetExpectedFrequencySwitch
            fieldName={"assetPostFrequency"}
            values={values}
            setFieldValue={setFieldValue}
            title={"Data Post Frequency"}
            abbreviate={true}
            paddingX={0}
            include1Minute={false}
            includeDeviceNormal={true}
          />
          {isElvaco && (
            <RadioButtonGroup
              fieldName={"postRawData"}
              title={"Post Raw MBus"}
              paddingX={0}
              options={[
                { name: `Yes`, value: true },
                { name: `No`, value: false },
              ]}
              setFieldValue={setFieldValue}
              currentValue={values["postRawData"]}
              submitSpinner={false}
            />
          )}

          <RadioButtonGroup
            fieldName={"autoSync"}
            title={"Automatically Sync Configuration"}
            paddingX={0}
            options={[
              { name: `Yes`, value: true },
              { name: `No`, value: false },
            ]}
            setFieldValue={setFieldValue}
            currentValue={values["autoSync"]}
            submitSpinner={false}
          />
          <div className={"form-group row py-4"}>
            <Col className={"px-0"}>
              <span className={"float-right"}>
                <Button
                  type={"submit"}
                  color={"primary"}
                  disabled={isSubmitting || !isValid}
                >
                  Update
                </Button>
              </span>
            </Col>
          </div>
          {(state || error) && (
            <div className={"form-group row"}>
              {state === "completed" && (
                <Alert className={"col-12"} color={"success"}>
                  Settings successfully updated.
                </Alert>
              )}
              {state === "error" && (
                <Alert color={"danger"}>
                  Error updating settings.{" "}
                  {error ? JSON.stringify(error) : "Unknown"}
                </Alert>
              )}
              <ErrorBanner error={error} />
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default GatewaySettingForm;
