import React from 'react';
import {Col, Row} from "reactstrap";
import SingleDatePickerFormInput from "../../../components/Forms/SingleDatePickerFormInput";
import {useField} from "formik";

function AssetPositionAssetLinkDateSelect(
    props: {
        serialNumber?: string,
        customerReference?: string,
        newLink?: boolean,
    }
) {
    const {
        serialNumber, customerReference
    } = props;
    const [installDate] = useField('installDate');
    const [uninstallDate] = useField('uninstallDate');

    const installDateText = installDate.value ? installDate.value.format('dddd D MMMM YYYY') + ' 00:00:00' : 'forever';
    const uninstallDateText = uninstallDate.value ? uninstallDate.value.clone().subtract(1, 'days').format('dddd D MMMM YYYY') + ' 23:59:59' : 'forever';

    return (
        <>
            <Row>
                <Col xs={12} sm={6}>
                    <h4>Installed</h4>
                    <SingleDatePickerFormInput
                        name={'installDate'}
                        showPresets={false}
                        placeholder={'Forever'}
                        timeZone={'Europe/London'}
                    />
                </Col>
                <Col xs={12} sm={6}>
                    <h4>Uninstalled</h4>
                    <SingleDatePickerFormInput
                        name={'uninstallDate'}
                        showPresets={false}
                        placeholder={'Forever'}
                        timeZone={'Europe/London'}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    {props.newLink ?
                <>Data for new asset with be used from {installDateText} till {uninstallDateText}.</>

                        :
                        <> Data from {serialNumber} will be used for
                            position {customerReference} from {installDateText} till {uninstallDateText}.
                        </>
                    }
                </Col>
            </Row>

        </>
    );
}

export default AssetPositionAssetLinkDateSelect;