import React, {ReactElement} from 'react';
import {DatePicker, Dialog, DialogContentContainer} from "@psd-platform/monday-ui-react-core";

import './styles.scss';
import {Preset} from "../DateRangePicker/DateRangePickerPresets";
import NewDateRangePickerPresets from "../NewDateRangePicker/NewDateRangePickerPresets";
import {SetRequired} from 'type-fest';
import {DialogPosition} from "@psd-platform/monday-ui-react-core/dist/types/types";
import { Moment } from 'moment';
import {limitRange} from "../NewDateRangePicker/model";

export interface DatePickerModalProps extends SetRequired<React.ComponentProps<typeof DatePicker>, 'onPickDate'> {
    isOpen?: boolean,
    close: () => void,
    children?: ReactElement | ReactElement[] | string;
    presets?: Preset[];
    maxDate?: Moment;
}


function DatePickerModal(props: DatePickerModalProps) {

    const {isOpen = false, maxDate, shouldBlockRange, ...rest} = props

    return <Dialog
        open={isOpen}
        showTrigger={[]}
        onClickOutside={props.close}
        wrapperClassName={'dialogWrapper'}
        position={'bottom' as DialogPosition}
        content={<DialogContentContainer>
            <div className={'d-inline-block align-top'}>
                <DatePicker
                    className={'calendarWrapper'}
                    shouldBlockRange={maxDate ? limitRange(undefined, maxDate) : shouldBlockRange}
                    {...rest}
                />
            </div>
            {props.presets &&
                <div className={'d-inline-block align-top'}>

                    <NewDateRangePickerPresets
                        value={(rest.date && rest.endDate) ?{startDate: rest.date, endDate: rest.endDate}:undefined}
                        onChange={props.onPickDate}
                        presets={props.presets}
                    />
                </div>
            }
        </DialogContentContainer>}
    >
        {props.children}
    </Dialog>
}

export default DatePickerModal;