import React from 'react';
import {Button, Col, Row} from "reactstrap";
import {Form, Formik, FormikHelpers} from "formik";
import {AssetPosition} from "../../../openapi/model/assetPosition";
import AssetPositionAssetLinkDateSelect from "./AssetPositionAssetLinkDateSelect";
import {Moment} from "moment";
import useCreateEntity from "../../../hooks/createEntity";
import {AssetPositionAssetLinkIn} from "../../../openapi/model/assetPositionAssetLinkIn";
import * as yup from "yup";
import AssetSelectFormField from "../../assets/AssetSelectFormField";

interface FormValues {
    assetId?: string,
    installDate?: Moment,
    uninstallDate?: Moment
}

function AssetPositionAssetLinkCreateForm(props: {
    assetPosition: AssetPosition,
    refresh?: () => any
}) {

    const {createEntity} = useCreateEntity<AssetPositionAssetLinkIn>("LINK")
    const initialValues: FormValues = {}


    const handleSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
        helpers.setSubmitting(true)
        createEntity({
                companyId: assetPosition.companyId,
                installDate: values.installDate?.toDate(),
                uninstallDate: values.uninstallDate?.toDate(),
                cancelled: false,
                assetId: values.assetId,
                assetPositionId: assetPosition.assetPositionId,
            }
        )
            .then(() => {
                helpers.resetForm();
                props.refresh && props.refresh()

            })
            .finally(() => {
                helpers.setSubmitting(false);
            })
    }
    const {assetPosition,} = props;
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnMount={true}
            validationSchema={yup.object().shape({
                assetId: yup.string().required()
            })}
        >
            {({isValid, isSubmitting}) => {
                return <Form>
                    <Row>
                        <Col xs={12} className={'py-2'}>
                            <h4>Select Asset</h4>
                            <AssetSelectFormField
                                name={'assetId'}
                                assetType={assetPosition.assetPositionType}
                                companyId={assetPosition.companyId}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <h4>Dates</h4>
                        </Col>
                    </Row>
                    <AssetPositionAssetLinkDateSelect newLink={true}/>

                    <Row>
                        <Col xs={12} className={'text-right'}>
                            <Button
                                type={'submit'}
                                color={'primary'}
                                disabled={!isValid || isSubmitting}
                            >Assign</Button>
                        </Col>
                    </Row>
                </Form>
            }}
        </Formik>
    );
}

export default AssetPositionAssetLinkCreateForm;