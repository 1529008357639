import React from "react";
import DisplayIfUserType from "../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import GetListPaginatedTable from "../../../components/Pagination/GetListPaginatedTable";
import AssetPositionAssetRow from "./AssetPositionAssetRow";

interface Props {
  assetId?: string;
  companyId: string;
  schemeId?: string;
  assetPositionId?: string;
  lastUpdated?: string;
  view?: "asset" | "assetPosition";
  refreshList?: () => void;
}

const AssetPositionAssetList = (props: Props): JSX.Element => {
  const { assetId, companyId, schemeId, assetPositionId } = props;

  const { view = "asset" } = props;

  const header = (
    <tr>
      {view === "asset" ? (
        <>
          <th>Asset</th>
          <th>Manufacturer</th>
          <th>Serial Number</th>
          <th>Asset Type</th>
        </>
      ) : (
        <>
          <th>Asset Position</th>
          <th>Reference</th>
          <th>Asset Position Type</th>
        </>
      )}
      <th>Install Date</th>
      <th>Uninstall Date</th>
      <th>Status</th>
      <DisplayIfUserType userTypes={"admin"}>
        <th />
      </DisplayIfUserType>
    </tr>
  );

  return (
    <GetListPaginatedTable
      entityType={"LINK"}
      params={{
        assetId,
        assetPositionId,
        companyId,
        schemeId,
      }}
      cursor={{ maxItems: 5 }}
      header={[header, 4]}
      data-cy={"asset-position-asset-history"}
      row={(item, _, key) => <AssetPositionAssetRow value={item} refreshList={props.refreshList}
      view={view} key={key}/>}
    />
  );
};

export default AssetPositionAssetList;
