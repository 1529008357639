import { UserCompanyProperties } from "../../openapi/model/userCompanyProperties";
import { User } from "../../openapi/model/user";
import { UserCompanyLink } from "../../openapi/model/userCompanyLink";
import { CompanyRole } from "openapi/model/companyRole";
import { UserType } from "../../openapi/model/userType";
import { Status } from "openapi/model/status";

export interface CompanyUser {
  emailAddress?: string;
  fullname: string;
  userId: string;
  companyId: string;
  apiUser: boolean;
  userType: CompanyRole;
  properties?: UserCompanyProperties;
  status: Status;
}

export const convertUserToCompanyUser = (
  user: User,
  companyId?: string
): CompanyUser => {
  let company;
  if (companyId) {
    company = user.companies.filter((c) => {
      return c.companyId === companyId;
    })[0];
  } else {
    company = user.companies[0];
  }
  return {
    ...user,
    fullname: user.fullname as string,
    apiUser: user.userType === UserType.Api,
    userType: company.userType,
    companyId: company.companyId,
    properties: company.properties,
  };
};

export const updateUserCompany = (
  user: User,
  companyId: string,
  updateCompanyLink: UserCompanyLink
): User => {
  return {
    ...user,
    companies: user.companies.map((companyLink) => {
      if (companyLink.companyId === companyId) {
        return updateCompanyLink;
      } else {
        return companyLink;
      }
    }),
  };
};

export const updateUserCompanyProperties = (
  user: User,
  companyId: string,
  updateCompanyProperties: UserCompanyProperties
): User => {
  return {
    ...user,
    companies: user.companies.map((companyLink) => {
      if (companyLink.companyId === companyId) {
        return {
          ...companyLink,
          properties: updateCompanyProperties,
        };
      } else {
        return companyLink;
      }
    }),
  };
};
