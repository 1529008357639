import React from "react";

import Dropzone from "react-dropzone";
import Papa, { ParseResult } from "papaparse";
import { Button } from "reactstrap";

interface Props {
  parseComplete: (data: Promise<ParseResult<any>>, filename: string) => any;
  buttonLabel?: string;
}

const processAcceptFiles = (
  acceptedFiles: File[],
  parseComplete: (data: Promise<ParseResult<any>>, filename: string) => any
): void => {
  const f = acceptedFiles[0];
  const parsePromise = new Promise<ParseResult<any>>((resolve, reject) => {
    Papa.parse<any, File>(f, {
      header: true,
      skipEmptyLines: true,
      complete(results) {
        resolve(results);
      },
      error(error: Error) {
        reject(error);
      },
    });
  });
  parseComplete(parsePromise, f.name);
};

const BulkUpdateCSVDropZone = (props: Props): JSX.Element => {
  const { buttonLabel = "Upload update file", parseComplete } = props;
  return (
    <Dropzone
      onDrop={(acceptedFiles): void =>
        processAcceptFiles(acceptedFiles, parseComplete)
      }
      accept={".csv"}
      multiple={false}
    >
      {({ getRootProps, getInputProps }): JSX.Element => (
        <Button color={"primary"} {...getRootProps()}>
          <input {...getInputProps()} />
          {buttonLabel}
        </Button>
      )}
    </Dropzone>
  );
};

export default BulkUpdateCSVDropZone;
