import {
  isSameDay,
  SingleDatePicker as ReactDatesSingleDatePicker,
} from "react-dates";
import React, { useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./DateRangePicker/DateRangePicker.scss";
import { useField } from "formik";
import {
  FieldHelperProps,
  FieldInputProps,
  FieldMetaProps,
} from "formik/dist/types";
import moment, { Moment } from "moment";
import { useMediaQuery } from "react-responsive";
import LabelWrapper from "./LabelWrapper";
import {
  DEFAULT_DAY_PRESETS,
  PresetDay,
} from "./DateRangePicker/DateRangePickerPresets";
import { Col, Row } from "reactstrap";
import classNames from "classnames";
// import {DEFAULT_PRESETS, Preset} from "./DateRangePickerPresets";

interface PropTypes {
  label?: string;
  placeholder?: string;
  dateName: string; // name of the form field for the date
  max?: Moment;
  showPresets?: boolean;
}

function useFieldProperties<Val = any>(
  fieldName: string
): {
  field: FieldInputProps<Val>;
  meta: FieldMetaProps<Val>;
  helpers: FieldHelperProps<Val>;
} {
  const [field, meta, helpers] = useField(fieldName);
  return { field, meta, helpers } as const;
}

export default function SingleDatePicker(props: PropTypes): JSX.Element {
  const [focusedDates, setFocusedDates] = useState<boolean>(false);
  const date = useFieldProperties(props.dateName);
  const isSmall = useMediaQuery({ maxDeviceWidth: 999 });
  const isXSmall = useMediaQuery({ maxDeviceWidth: 700 });
  const {label, placeholder='Date', showPresets=false} = props;
  const handleSelect = (newDate: moment.Moment | null): void => {
    if (newDate) {
      date.helpers.setValue(newDate);
    }
  };

  const presets: PresetDay[] = DEFAULT_DAY_PRESETS;

  // TODO: presets?

  const renderDatePresets = (): JSX.Element => {
    return (
      <Col className={"pb-4 pt-0 px-4 mb-2"}>
        <Row className={"mx-0"}>
          <Col xs={12} className={"py-1 px-0"}>
            <h3>Presets</h3>
          </Col>
          <Col xs={12} className={"py-1 px-0"}>
            {presets.map(({ text, day }) => {
              const isSelected = isSameDay(day, date.field.value);
              return (
                <button
                  key={text}
                  type="button"
                  className={classNames({
                    active: isSelected,
                    "btn btn-primary": true,
                  })}
                  onClick={(): void => handleSelect(day)}
                >
                  {text}
                </button>
              );
            })}
          </Col>
        </Row>
      </Col>
    );
  };

  return (
    <LabelWrapper
      label={label}
      textPosition={"left"}
      paddingX={0}
      submitSpinner={false}
    >
      <ReactDatesSingleDatePicker
        date={date.field.value} // momentPropTypes.momentObj or null,
        id={"datepickerdate"}
        // startDateId="datepickerstart" // PropTypes.string.isRequired,
        onDateChange={handleSelect} // PropTypes.func.isRequired,
        focused={focusedDates} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={({ focused }): void => setFocusedDates(focused)} // PropTypes.func.isRequired,
        inputIconPosition={"after"}
        showDefaultInputIcon={true}
        numberOfMonths={isSmall ? (isXSmall ? 1 : 2) : 3}
        isDayBlocked={(): boolean => false}
        isOutsideRange={(day: moment.Moment): boolean => {
          return day > (props.max || moment());
        }}
        renderCalendarInfo={showPresets ? renderDatePresets : undefined}
        // minDate={moment().subtract(5, 'year')}
        navNext={
          <>
            <div
              className={
                "DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default" +
                " DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal" +
                " DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault" +
                " DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault" +
                " DayPickerNavigation_rightButton__horizontalDefault_5"
              }
              style={{ transform: "translateX(-30px)" }}
            >
              <i className={"fa fa-angle-right"} />
            </div>
            <div
              className={
                "DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default" +
                " DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal" +
                " DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault" +
                " DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault" +
                " DayPickerNavigation_rightButton__horizontalDefault_5"
              }
            >
              <i className={"fa fa-angle-double-right"} />
            </div>
          </>
        }
        navPosition={"navPositionTop"}
        placeholder={placeholder}
      />
    </LabelWrapper>
  );
}
