import React from 'react';
import {AggregatedReportRequest} from "../../../openapi/model/aggregatedReportRequest";
import {AssetType} from "../../../openapi/model/assetType";
import AggregatedTimeSeriesApiWrapper from "../aggregatedTimeSeriesReport/AggregatedTimeSeriesApiWrapper";
import {Col, Row} from "reactstrap";
import VWADTReportView from "./VWADTReportView";
import AggregationScatterChart from "../../../components/charts/AggregationsScatterChart";
import {HeatNetworkReportParams} from "../heatNetworkAnalysis/model";

interface Props {
   companyId?: string;
  schemeId?: string;
  query: HeatNetworkReportParams

}




export function VWADTReportContainer(props: Props): JSX.Element {
  const [startDatetime, endDatetime] = [props.query.startDate, props.query.endDate];

  const params = (): AggregatedReportRequest => {
    if (props.companyId && props.schemeId) {
      return {
        companyId: props.companyId,
        schemeId: props.schemeId,
        aggregations: [
          {
            numeratorParameter: 'Energy (Heating)',
            denominatorParameter: 'Volume',
            aggregation: 'difference'
          },
          {
            parameter: 'Volume',
            aggregation: 'difference'
          }
        ],
        assetTypes: [AssetType.HeatMeter, AssetType.HeatingAndCoolingMeter],
        startTime: startDatetime.toDate(),
        endTime: endDatetime.toDate(),
        period: "ALL",
        useCase: props.query.useCases,
                group: props.query.group,

      };
    } else {
      throw new Error("Scheme or Company is not selected.");
    }
  };

  return (
    <>
      {props.companyId && props.schemeId && (
        <AggregatedTimeSeriesApiWrapper request={params()}>
          {({ data, loading }) => (
            <>
              <Row>
                <Col xs={12}>
                  <AggregationScatterChart
                    data={data}
                    name={"Energy - Volume"}
                    xAggregation={"difference"}
                    xParameter={"volume"}
                    yAggregation={"difference"}
                    yParameter={"energyHeatingVolume"}
                    loading={loading}
                    xLabel={"Volume"}
                    xUnit={"m3"}
                    yLabel={"Energy:Volume Ratio"}
                    yUnit={""}
                    height={"400px"}
                    companyId={props.companyId as string}
                    schemeId={props.schemeId as string}
                    startTime={startDatetime}
                    endTime={endDatetime}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <VWADTReportView
                    data={data}
                    schemeId={props.schemeId}
                    startDatetime={startDatetime}
                    endDatetime={endDatetime}
                  />
                </Col>
              </Row>
            </>
          )}
        </AggregatedTimeSeriesApiWrapper>
      )}
    </>
  );
}