import useGetList, { PaginatedData, PaginatedListResult } from "hooks/getList";
import React from "react";
import { Entities } from "../../constants/entities";
import { Cursor } from "../../openapi/model/cursor";
import { AxiosPromise, AxiosRequestConfig } from "axios";
import { RefetchOptions } from "axios-hooks";

export interface PaginatedListResultProps<T> {
  data?: PaginatedData<T>;
  error?: Error;
  loading: boolean;
  refresh: (
    config?: AxiosRequestConfig,
    options?: RefetchOptions
  ) => AxiosPromise<PaginatedData<T>>;
  cancel: () => void;
  updateItem: (item: T, index: number) => void;
}

export interface GetListProps {
  entityType: Entities;
  params?: Record<string, string | string[] | boolean | undefined>;
  cursor?: Cursor;
  path?: string;
  lastChange?: Date;
}

interface Props extends GetListProps {
  children: (props: PaginatedListResultProps<any>) => React.ReactElement;
}

function GetList(props: Props): JSX.Element {
  const { children, entityType, params, cursor, path } = props;
  const [{ data, error, loading }, refresh, cancel, updateItem] = useGetList<
    PaginatedListResult<any>
  >(entityType, params, cursor, path);

  React.useEffect(() => {
    refresh();
  }, [props.lastChange, refresh]);

  return (
    <>
      {children({
        data,
        error: error !== null ? error : undefined,
        loading,
        refresh,
        cancel,
        updateItem,
      })}
    </>
  );
}

export default GetList;
