import React, { useEffect, useState } from "react";
import { ReportExecutionOut } from "../../../openapi/model/reportExecutionOut";
import { Col, ListGroupItem } from "reactstrap";
import ReportExecutionArtifactsDownloadButton from "./ReportExecutionArtifactsDownloadButton";
import _ from "lodash";
import moment from "moment";
import { OutputType } from "../../../openapi/model/outputType";
import { ElvacoX109ReportConfiguration } from "../../../openapi/model/elvacoX109ReportConfiguration";
import { AllDataReportConfiguration } from "../../../openapi/model/allDataReportConfiguration";
import ReportExecutionCalculatingBadge from "./ReportExecutionCalculatingBadge";

function ReportExecutionListRow(props: {
  execution: ReportExecutionOut;
  refresh: () => void;
}) {
  const { execution, refresh } = props;
  const calculating = execution.status === "CALCULATING";
  const [wasCalculating, _0] = useState<boolean>(calculating);

  useEffect(() => {
    if (calculating) {
      const timer = setTimeout(refresh, 10000);
      return () => clearTimeout(timer);
    }
  }, [calculating, refresh]);
  function description(execution: ReportExecutionOut): string {
    switch (execution.reportFormat.format) {
      case OutputType.ELVACOX109:
        return `Time Filter: ${
          (
            execution.reportFormat
              .configuration as ElvacoX109ReportConfiguration
          ).timeFilter
        }`;
      case OutputType.ELVACOX110:
        return `Time Filter: ${
          (
            execution.reportFormat
              .configuration as ElvacoX109ReportConfiguration
          ).timeFilter
        }`;
      case OutputType.ALLDATA:
        return `Time Filter: ${
          (execution.reportFormat.configuration as AllDataReportConfiguration)
            .timeFilter
        }`;

      default:
        return "";
    }
  }
  const name = _.startCase(_.lowerCase(execution.reportFormat.format));
  const dates = `${
    execution.startDate
      ? moment(execution.startDate).format("Do MMM YYYY")
      : moment(execution.endDate).format("Do MMM YYYY")
  } - ${moment(execution.endDate).format("Do MMM YYYY")}`;

  return (
    <ListGroupItem className={"d-flex justify-content-between"}>
      <Col className="d-md-flex d-none justify-content-between p-0">
        <Col>
          {name}
          <ReportExecutionCalculatingBadge
            execution={execution}
            wasCalculating={wasCalculating}
            className={"px-2 mx-2"}
          />
        </Col>
        <Col>{dates}</Col>
        <Col>{description(execution)}</Col>
        <Col className={"font-italic text-muted"}>
          Created: {moment(execution.createdAt).format("Do MMM YYYY HH:mm")}
        </Col>
      </Col>
      <Col className="d-md-none p-0">
        <Col className={"font-weight-bold"}>{name}</Col>
        <Col>{dates}</Col>
        <Col className={"font-italic text-muted"}>{description(execution)}</Col>
      </Col>

      <ReportExecutionArtifactsDownloadButton
        execution={execution}
        className={"h-100"}
      />
    </ListGroupItem>
  );
}

export default ReportExecutionListRow;
