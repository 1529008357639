import React from "react";
import { ParseResult } from "papaparse";
import { BulkUpdateDetailed } from "../../openapi/model/bulkUpdateDetailed";
import flatten from "flat";
import BulkUpdateCSVDropZone from "./BulkUpdateCSVDropZone";
import useCreateEntity from "../../hooks/createEntity";
import { useLocation, useNavigate } from "react-router-dom";
import {
  objectBlankStringsToUndefined,
  removeMapBlankObjects,
} from "../../utils/object";

interface Props {
  companyId: string;
  schemeId?: string;
  objectType: string;
}

export function unFlattenData(data: any[]): any[] {
  return data.map((d) => {
    return flatten.unflatten(d, { object: false });
  });
}

export const identifyUniqueArrayValues = (
  arrayOne: any[],
  arrayTwo: any[]
): any[] => {
  const uniqueInArrayOne: any[] = [];
  arrayOne.forEach((value: any) => {
    if (!arrayTwo.includes(value)) {
      uniqueInArrayOne.push(value);
    }
  });
  return uniqueInArrayOne;
};

export default function BulkUpdate(props: Props): JSX.Element {
  const { createEntity } = useCreateEntity<BulkUpdateDetailed, any>("UPDATE");

  const navigate = useNavigate();
  const location = useLocation();

  const navigateToUpdate = (update: BulkUpdateDetailed): void => {
    navigate({
      pathname: `/admin/${props.objectType}/updates/${update.updateId}`,
      hash: location.hash,
    });
  };

  const parseComplete = async (
    inputData: Promise<ParseResult<any>>,
    filename: string
  ): Promise<any> => {
    const data = await inputData;
    const objects = unFlattenData(data.data).map((v) =>
      removeMapBlankObjects(objectBlankStringsToUndefined(v))
    );
    const [update] = await createEntity({
      objects: objects,
      companyId: props.companyId,
      schemeId: props.schemeId,
      objectType: props.objectType,
      filename: filename,
    });
    navigateToUpdate(update);
  };

  return (
    <span className={"mx-2"}>
      <BulkUpdateCSVDropZone parseComplete={parseComplete} />
    </span>
  );
}
