import React from "react";
import { Form, Formik } from "formik";
import { Button, Col, Row } from "reactstrap";
import DateRangePicker from "../../../components/Forms/DateRangePicker/DateRangePicker";
import { doesDeviceHaveParsedData } from "../../../model/assets/deviceData";
import RadioButtonGroup from "../../../components/Forms/RadioButtonGroup";
import { DeviceAsset } from "../../../openapi/model/deviceAsset";
import { GatewayAsset } from "../../../openapi/model/gatewayAsset";
import moment, { Moment } from "moment";

export interface AssetTimeSeriesRequestParams {
  startDatetime: Moment;
  endDatetime: Moment;
  projection: string;
}

interface PProps {
  device: DeviceAsset | GatewayAsset;
  loadData: (values: AssetTimeSeriesRequestParams) => void;
  showProjection: boolean;
}

const AssetDataSelectionForm = (
  props: PProps & AssetTimeSeriesRequestParams
): JSX.Element => {
  const initialValues = (): AssetTimeSeriesRequestParams => {
    const { startDatetime, endDatetime, projection } = props;
    return { startDatetime, endDatetime, projection };
  };

  const { device, loadData } = props;

  const showProjection = doesDeviceHaveParsedData(device) && props.showProjection
  return (
    <Formik
      initialValues={initialValues()}
      onSubmit={({ startDatetime, endDatetime, projection }): void => {
        loadData({ startDatetime, endDatetime, projection });
      }}
    >
      {({ values, setFieldValue }): JSX.Element => (
        <Form>
          <Row>
            <Col xs={12} md={{size: 6, offset: !showProjection ? 4:0}} xl={{size:8,offset: !showProjection?3:0}}>
              <DateRangePicker
                startDateName={"startDatetime"}
                endDateName={"endDatetime"}
                maxDate={moment().endOf("day")}
              />
            </Col>
            {showProjection && (
              <Col xs={12} md={4} xl={3}>
                <RadioButtonGroup
                  fieldName={"projection"}
                  title={"Data Style"}
                  currentValue={values.projection}
                  options={["Standard", "Raw"]}
                  setFieldValue={setFieldValue}
                  paddingX={0}
                />
              </Col>
            )}
            <Col xs={12} md={2} xl={1} className={"text-right py-1"}>
              <Button
                type={"submit"}
                color={"primary"}
                style={{ width: "100%" }}
              >
                Search
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default AssetDataSelectionForm;
