import React from "react";
import { Col, Row } from "reactstrap";
import { OneCardPage } from "../../components/utils/OneCardPage";
import StreamingSettingsFormPage from "./StreamingSettingsFormPage";

export default function ConfigurationHomePage(): JSX.Element {
  return (
    <>
      <OneCardPage
        headerTitle={"Configuration"}
        breadcrumbs={[{ url: "/admin/configuration", name: "Configuration" }]}
      >
        <Row>
          <Col xs={12}>
            <h3>Data Streaming</h3>
            <p>
              Configure streaming of formatted and error checked meter data via
              HTTPS to up to 3 endpoints.
            </p>
            <StreamingSettingsFormPage />
          </Col>
        </Row>
      </OneCardPage>
    </>
  );
}
