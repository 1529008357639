import React from "react";
import CompanyNavLink from "../../../components/navigation/CompanyNavLink";
import HighlightNullAndOldDates from "../../../components/utils/HighlightNullAndOldDates";
import moment from "moment";
import { formatDataCollectionFrequency } from "../../../model/assets/deviceData";
import { GatewayAsset } from "../../../openapi/model/gatewayAsset";
import { AssetRegisterItem } from "../../../openapi/model/assetRegisterItem";
import { Asset } from "../../../openapi/model/asset";
import AssetRegisterListItem from "../../assetRegister/AssetRegisterListItem";
import { Col, Row } from "reactstrap";
import GatewayStateLabel from "./GatewayStateLabel";
import { GatewayState } from "../../../openapi/model/gatewayState";
import {GatewaySettingsType} from "../../../openapi/model/gatewaySettingsType";

const GatewayRows = (gateway: GatewayAsset): JSX.Element => {
  return (
    <>
      <td data-cy={"gateway-asset-id"}>
        <CompanyNavLink to={`/admin/asset/${gateway?.assetId}`}>
          ...{gateway?.assetId.slice(-8)}
        </CompanyNavLink>
      </td>
      <td data-cy={"gateway-serial-number"}>{gateway?.serialNumber}</td>
      <td data-cy={"gateway-last-contact"}>
        <HighlightNullAndOldDates ageHighlight={moment.duration(3, "days")}>
          {gateway?.lastContact}
        </HighlightNullAndOldDates>
      </td>
      <td data-cy={"gateway-expected-connectivity"}>
        {formatDataCollectionFrequency(
          gateway?.configuration?.collectionFrequency
        )}
      </td>
      <td data-cy={"gateway-model"}>{gateway?.model}</td>
      <td data-cy={"gateway-ip-address"}>{gateway?.ipAddress}</td>
      <td data-cy={"gateway-manufacturer"}>{gateway?.manufacturer}</td>
      <td data-cy={"gateway-mac-address"}>{gateway?.macAddress}</td>
      <td data-cy={"gateway-firmware-version"}>{gateway?.firmwareVersion}</td>
    </>
  );
};

function ReportDetail(props: { title: string; value?: string }): JSX.Element {
  return (
    <small className={"ml-2 text-nowrap text-muted"}>
      <span className={"font-weight-bold"}>{props.title}</span>:{" "}
      {props.value || "Unknown"}
    </small>
  );
}

function GatewayConnectivityDataColumn(props: {
  assetRegisterItem: AssetRegisterItem;
}): JSX.Element {
  const { assetRegisterItem } = props;
  const gateway = assetRegisterItem.asset as GatewayAsset | undefined;
  return (
    <Row className={"d-flex"}>
      <Col
        xs={{ size: 12, order: 0 }}
        sm={{ size: 4, order: 3 }}
        md={{ size: 7, order: 0 }}
        lg={{ size: 6 }}
        data-cy={"gateway-last-contact"}
        className={"text-right text-md-left"}
      >
        <HighlightNullAndOldDates ageHighlight={moment.duration(3, "days")}>
          {gateway?.lastContact}
        </HighlightNullAndOldDates>
      </Col>
      <Col
        xs={{ size: 6, order: 1 }}
        sm={{ size: 2, order: 1 }}
        md={{ size: 2, order: 3 }}
        lg={{ size: 3 }}
        data-cy={"gateway-expected-connectivity"}
        className={"text-left text-md-right d-none d-sm-block"}
      >
        <span className={"d-inline-block d-lg-none"}>
          {formatDataCollectionFrequency(
            gateway?.configuration?.collectionFrequency,
            true
          )}
        </span>
        <span className={"d-none d-lg-inline-block"}>
          {formatDataCollectionFrequency(
            gateway?.configuration?.collectionFrequency,
            false
          )}
        </span>
      </Col>
      <Col
        xs={{ size: 6, order: 1 }}
        sm={{ size: 3, order: 2 }}
        md={{ size: 3, order: 3 }}
        className={"text-right text-md-right"}
      >
        {gateway?.model || "Unknown"}
      </Col>
      <Col
        xs={{ size: 6, order: 2 }}
        sm={{ size: 3 }}
        md={{ size: 2, order: 4 }}
        className={"text-right text-md-left"}
      >
        {gateway && (
          <GatewayStateLabel
            size={"sm"}
            short={true}
            state={(gateway.state as GatewayState).configurationState}
          />
        )}
      </Col>
      {gateway && (
        <Col
          xs={{ size: 10, order: 6 }}
          className={"text-right d-none d-md-block"}
        >
          <ReportDetail title={"SIM"} value={gateway.simCardIccid} />
          <ReportDetail title={"IP"} value={gateway.ipAddress} />
          <ReportDetail title={"Firmware"} value={gateway.firmwareVersion} />
          <ReportDetail
            title={"Config"}
            value={
              (gateway.configuration.manufacturerSpecificSettings?.mbusSettings || GatewaySettingsType.ELVACODEFAULT )=== GatewaySettingsType.ELVACODEFAULT
                ? "Default"
                : "CMi Wireless"
            }
          />
        </Col>
      )}
    </Row>
  );
}

export function GatewayConnectivityDataColumnHeader(): JSX.Element {
  return (
    <Row className={"d-flex"}>
      <Col
        xs={{ size: 12, order: 0 }}
        sm={{ size: 4, order: 3 }}
        md={{ size: 7, order: 0 }}
        lg={{ size: 6 }}
        className={"text-right text-md-left"}
      >
        Last Contact
        <span className={"text-sm d-block"}>Status</span>
      </Col>
      <Col
        xs={{ size: 6, order: 1 }}
        sm={{ size: 2, order: 1 }}
        md={{ size: 2, order: 3 }}
        lg={{ size: 3 }}
        className={"text-left text-md-right d-none d-sm-block"}
      >
        <span>Collection Frequency</span>
      </Col>
      <Col
        xs={{ size: 6, order: 1 }}
        sm={{ size: 3, order: 2 }}
        md={{ size: 3, order: 3 }}
        className={"text-right text-md-right"}
      >
        Model
      </Col>
    </Row>
  );
}

export default function GatewayConnectivityReportDataRow(props: {
  assetRegisterItem: AssetRegisterItem;
  showScheme?: boolean;
  actionButtons?: (props: {
    assetRegisterItem: AssetRegisterItem;
  }) => React.ReactNode;
  index: number;
}): JSX.Element {
  const { assetRegisterItem, showScheme = true } = props;
  return (
    <AssetRegisterListItem
      assetRegisterItem={assetRegisterItem}
      showScheme={showScheme}
      showType={false}
      denseColumn={GatewayConnectivityDataColumn}
      actionButtons={props.actionButtons}
      index={props.index}
    />
  );
}

export const UnassignedGatewayConnectivityReportDataRow = (value: Asset) => {
  return <tr>{GatewayRows(value as GatewayAsset)}</tr>;
};
