import React from "react";
import LatestTimeSeriesApiWrapper from "./LatestTimeSeriesApiWrapper";
import LatestDataReportTable from "./LatestDataReportTable";
import {Col, Row} from "reactstrap";
import LatestDataReportQueryUrlWrapper from "./LatestDataReportQueryUrlWrapper";
import {LatestDataReportParams} from "./model";
import LatestDataReportQueryForm from "./LatestDataReportQueryForm";

interface PProps {
  companyId: string;
  schemeId: string;
}

const LatestTimeSeriesDataReport = (props: PProps): JSX.Element => {
  return (
        <LatestDataReportQueryUrlWrapper defaultValues={{
        }}>
            {
                (
                    params: LatestDataReportParams,
                    onChange: (v: LatestDataReportParams) => void
                ) => {
                  return <>
                      <Row>
                    <Col xs={12}>
                      <LatestDataReportQueryForm
                          onSubmit={(v) => {
                            onChange(v)
                            return Promise.resolve()
                          }}
                          initialValues={params}
                      />
                    </Col>
                    <LatestTimeSeriesApiWrapper request={{...props, ...params}}>
                      {({data}) => {
                          return <Col xs={12}><LatestDataReportTable data={data} {...props}/></Col>;
                      }}
                    </LatestTimeSeriesApiWrapper>
                      </Row>

                  </>
                }
            }
        </LatestDataReportQueryUrlWrapper>





  );
};

export default LatestTimeSeriesDataReport;
