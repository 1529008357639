import React from "react";
import CompanyNavLink from "../navigation/CompanyNavLink";
import { Moment } from "moment";
import { createSearchString } from "components/navigation/SearchString";

const AssetPositionRowLink = (props: {
  assetPositionId?: string;
  startDatetime?: Moment;
  endDatetime?: Moment;
  children: React.ReactNode
}): JSX.Element => {
  const { assetPositionId, startDatetime, endDatetime } = props;

  const link =
    startDatetime || endDatetime
      ? `/admin/asset_position/${assetPositionId}?${createSearchString({
          startDatetime: startDatetime?.toISOString(true),
          endDatetime: endDatetime?.toISOString(true),
        })}`
      : `/admin/asset_position/${assetPositionId}`;

  return (
    <>
      {assetPositionId && (
        <CompanyNavLink to={link} className={"px-0"} external={true} inline>
          {props.children}
        </CompanyNavLink>
      )}
    </>
  );
};

export default AssetPositionRowLink;
