import React from "react";
import DataQualityTimeSeriesAreaChart, {
  DataQualityTimeSeriesAreaChartProps,
} from "../../../components/charts/DataQualityTimeSeriesAreaChart";
import { AssetRegisterAggregatedTimeSeriesResponse } from "../../../openapi/model/assetRegisterAggregatedTimeSeriesResponse";
import { AssetRegisterItemAggregatedTimeSeries } from "../../../openapi/model/assetRegisterItemAggregatedTimeSeries";
import moment from "moment";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import SimpleLineChart from "../../../components/charts/SimpleLineChart";

interface SummaryDataQualityTimeSeriesAreaChartProps
  extends Omit<
    DataQualityTimeSeriesAreaChartProps,
    | "missing"
    | "errors"
    | "incomplete"
    | "expected"
    | "complete"
    | "times"
    | "loading"
    | "valueErrors"
    | "error"
  > {
  data?: AssetRegisterAggregatedTimeSeriesResponse;
}

interface DataQualitySummary {
  expectedRows: number;
  receivedRows: number;
  missingRows: number;
  incompleteRows: number;
  valueErrorRows: number;
  errorRows: number;
  assets: number;
  aboveThreshold050Assets: number;
  aboveThreshold075Assets: number;
  aboveThreshold095Assets: number;
  aboveThreshold100Assets: number;
  date?: Date;
}

const sumDataQualityForDate =
  (dateIndex: number) =>
  (
    accumulator: DataQualitySummary,
    current: AssetRegisterItemAggregatedTimeSeries
  ): DataQualitySummary => {
    const { aggregations } = current;
    const { qualityAssessment } = aggregations;
    const expected = qualityAssessment.expectedRows[dateIndex] || 0;
    const good = qualityAssessment.receivedRowsComplete[dateIndex] || 0;

    const achieved = good / expected;

    return {
      ...accumulator,
      expectedRows: accumulator.expectedRows + expected,
      receivedRows: accumulator.receivedRows + good,
      incompleteRows:
        accumulator.incompleteRows +
        qualityAssessment.receivedRowsIncomplete[dateIndex],
      errorRows:
        accumulator.errorRows +
        qualityAssessment.receivedRowsWithErrors[dateIndex],
      valueErrorRows:
        accumulator.valueErrorRows +
        qualityAssessment.receivedRowsWithValueErrors[dateIndex],
      aboveThreshold050Assets:
        accumulator.aboveThreshold050Assets + (achieved >= 0.5 ? 1 : 0),
      aboveThreshold075Assets:
        accumulator.aboveThreshold075Assets + (achieved >= 0.75 ? 1 : 0),
      aboveThreshold095Assets:
        accumulator.aboveThreshold095Assets + (achieved >= 0.95 ? 1 : 0),
      aboveThreshold100Assets:
        accumulator.aboveThreshold100Assets + (achieved >= 1 ? 1 : 0),
    };
  };

const summariseData = (
  data: AssetRegisterAggregatedTimeSeriesResponse
): Array<Required<DataQualitySummary>> => {
  const aggregations = data.data.filter(
    (item) => item.assetType !== "gateway" && item.assetId !== undefined
  );

  return data.times.map((time, timeIndex) => {
    const d = {
      ...aggregations.reduce(sumDataQualityForDate(timeIndex), {
        expectedRows: 0,
        receivedRows: 0,
        missingRows: 0,
        incompleteRows: 0,
        valueErrorRows: 0,
        errorRows: 0,
        assets: 0,
        aboveThreshold050Assets: 0,
        aboveThreshold075Assets: 0,
        aboveThreshold095Assets: 0,
        aboveThreshold100Assets: 0,
      }),
      date: time,
    };
    d.missingRows =
      d.expectedRows -
      d.incompleteRows -
      d.errorRows -
      d.receivedRows -
      d.valueErrorRows;
    d.assets = aggregations.length;
    return d;
  });
};

export const SummaryDataQualityTimeSeriesAreaChart = (
  props: SummaryDataQualityTimeSeriesAreaChartProps
): JSX.Element => {
  const { data, ...rest } = props;
  const summary = data
    ? summariseData(data)
    : [
        {
          expectedRows: 0,
          receivedRows: 0,
          missingRows: 0,
          incompleteRows: 0,
          valueErrorRows: 0,
          errorRows: 0,
          assets: 0,
          aboveThreshold050Assets: 0,
          aboveThreshold075Assets: 0,
          aboveThreshold095Assets: 0,
          aboveThreshold100Assets: 0,
          date: new Date(),
        },
      ];

  return (
    <>
      <DataQualityTimeSeriesAreaChart
        missing={summary.map((d) => d.missingRows)}
        expected={summary.map((d) => d.expectedRows)}
        incomplete={summary.map((d) => d.incompleteRows)}
        errors={summary.map((d) => d.errorRows)}
        valueErrors={summary.map((d) => d.valueErrorRows)}
        complete={summary.map((d) => d.receivedRows)}
        times={summary.map((d) => d.date).map((t) => moment(t))}
        {...rest}
        loading={false}
      />
      <Col {...rest}>
        <Card>
          <CardHeader>
            <h6 className="surtitle" style={{ color: "red-orange" }}>
              Quality
            </h6>
            <h5 className="h3 mb-0">% of assets achieving % data quality</h5>
          </CardHeader>
          <CardBody>
            <div className="chart">
              <SimpleLineChart
                multipleData={[
                  ["", summary.map((d) => [moment(d.date), 100]), "0", "red"],
                  [
                    ">= 50%",
                    summary.map((d) => [
                      moment(d.date),
                      Math.round((d.aboveThreshold050Assets / d.assets) * 100),
                    ]),
                    "50",
                    "red-orange",
                  ],
                  [
                    ">= 75%",
                    summary.map((d) => [
                      moment(d.date),
                      Math.round((d.aboveThreshold075Assets / d.assets) * 100),
                    ]),
                    "75",
                    "yellow",
                  ],
                  [
                    ">= 95%",
                    summary.map((d) => [
                      moment(d.date),
                      Math.round((d.aboveThreshold095Assets / d.assets) * 100),
                    ]),
                    "95",
                    "orange-green",
                  ],
                  [
                    "= 100%",
                    summary.map((d) => [
                      moment(d.date),
                      Math.round((d.aboveThreshold100Assets / d.assets) * 100),
                    ]),
                    "100",
                    "green",
                  ],
                ]}
                name={"% of assets"}
                type={"areaspline"}
                unit={"%"}
                yMax={100}
                yMin={0}
                legend={true}
                loading={false}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default SummaryDataQualityTimeSeriesAreaChart;
