import React, { useCallback, useEffect, useState } from "react";
import { Alert, Button } from "reactstrap";
import { AssetPosition } from "../../openapi/model/assetPosition";
import { Location } from "../../openapi/model/location";
import classNames from "classnames";
import { ErrorBanner } from "components/Error/ErrorBanner";

interface DeletionState {
  deleting: boolean;
  success?: boolean;
  error?: Error;
  refreshTimeout?: NodeJS.Timeout;
}

const AssetPositionDeleteButton = (props: {
  assetPosition: AssetPosition | Location;
  delete: () => Promise<any>;
  refresh?: () => any;
  disabled?: boolean;
  title: string;
}): JSX.Element => {
  const [state, setState] = useState<DeletionState>({ deleting: false });

  const { refreshTimeout } = state;
  const cancelTimeout = useCallback(() => {
    refreshTimeout && clearTimeout(refreshTimeout);
    setState((s) => {
      return { ...s, refreshTimeout: undefined };
    });
  }, [refreshTimeout]);

  useEffect(() => {
    return cancelTimeout;
  }, [cancelTimeout]);

  const update = async (): Promise<void> => {
    cancelTimeout();
    setState({ deleting: true, success: false, error: undefined });
    return props
      .delete()
      .then()
      .then(() => {
        props.refresh &&
          setState({
            ...state,
            refreshTimeout: setTimeout(() => {
              props.refresh && props.refresh();
            }, 2000),
          });
        setState({ deleting: false, success: true });
      })
      .catch((error) => {
        setState({ deleting: false, success: false, error });
      });
  };

  return (
    <>
      <Button
        color="danger"
        onClick={update}
        disabled={state.deleting || props.disabled || state.success}
      >
        <i
          className={classNames({
            "fa fa-fw mr-2": true,
            "fa-spinner fa-spin": state.deleting,
            "fa-trash-alt": !state.deleting,
          })}
        />
        Delete
      </Button>
      {state.success && (
        <Alert color={"success"}>
          <h3>Deleted</h3>
          <p>{props.title} has been successfully deleted.</p>
        </Alert>
      )}
      <ErrorBanner error={state.error} />
    </>
  );
};

export default AssetPositionDeleteButton;
