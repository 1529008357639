import React, { useState } from "react";
import { Asset } from "../../openapi/model/asset";
import { DropdownItem } from "reactstrap";
import AssetActionConfirmModal from "./AssetActionConfirmModal";
import { AssetAction } from "../../openapi/model/assetAction";
import { ActionTypes } from "../../openapi/model/actionTypes";

interface PProps {
  asset: Asset;
  actionType: ActionTypes;
  actionName: string;
  actionPayload?: any;
  refresh?: () => any;
}

const CreateActionDropdown = (props: PProps): JSX.Element => {
  const [open, setOpen] = useState(false);

  const openModal = (): void => {
    setOpen(true);
  };

  const closeModal = (): void => {
    setOpen(false);
  };

  const action: AssetAction = {
    actionType: props.actionType,
    payload: props.actionPayload,
  };

  return (
    <>
      <DropdownItem onClick={openModal}>{props.actionName}</DropdownItem>
      <AssetActionConfirmModal
        show={open}
        asset={props.asset}
        action={action}
        close={closeModal}
        description={props.actionName}
        onComplete={props.refresh}
      />
    </>
  );
};

export default CreateActionDropdown;
