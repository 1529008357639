import moment, {Moment} from "moment/moment";

export interface DateRange {
    startDate: Moment | undefined,
    endDate: Moment | undefined
}


export function isSameDay(a: any, b: any): boolean {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  // Compare least significant, most likely to change units first
  // Moment's isSame clones moment inputs and is a tad slow
  return (
    a.date() === b.date() && a.month() === b.month() && a.year() === b.year()
  );
}

export function limitRange(startDate?: Moment, endDate?: Moment): ((date: Moment) => boolean ) {
    return (date: Moment) => {
        if ((startDate) && (date < startDate)) {
            return true
        }
        else if ((endDate) && (date > endDate)) {
            return true
        }
        return false
    }
}