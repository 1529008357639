import React from "react";
import { Routes } from "react-router-dom";
import UserList from "./UserList";
import ProfilePage from "../profile/ProfilePage";
import { SentryRoute } from "../../App";
import SelectedScheme from "components/Scheme/SelectedScheme";
import CreateUserForm from "./UserCreateForm";
import ApiUserCreateForm from "./ApiUserCreateForm";

const UsersPage = (): JSX.Element => {
  return (
    <Routes>
      <SentryRoute
        path="/"
        element={
          <SelectedScheme>{(props) => <UserList {...props} />}</SelectedScheme>
        }
      />
      <SentryRoute
        path="/create"
        element={
          <SelectedScheme>
            {(props) => <CreateUserForm {...props} />}
          </SelectedScheme>
        }
      />
      <SentryRoute
        path="/api/create"
        element={
          <SelectedScheme>
            {(props) => <ApiUserCreateForm {...props} />}
          </SelectedScheme>
        }
      />
      <SentryRoute path="/profile" element={<ProfilePage />} />
    </Routes>
  );
};

export default UsersPage;
