import React from "react";
import { Form, Formik } from "formik";
import { Button, Col, Row } from "reactstrap";
import { Moment } from "moment";
import { createSearchString } from "../../../components/navigation/SearchString";
import DateRangePicker from "../../../components/Forms/DateRangePicker/DateRangePicker";
import { useLocation, useNavigate } from "react-router-dom";

export interface DateRangeParams {
  startDatetime?: Moment;
  endDatetime?: Moment;
}

const DateRangeOnlySelectorForm = (
  props: Required<DateRangeParams>
): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const initialValues = (): Required<DateRangeParams> => {
    return props;
  };

  const selectData = (values: Required<DateRangeParams>): void => {
    const [startDatetime, endDatetime] = [
      values.startDatetime.toISOString(true),
      values.endDatetime.toISOString(true),
    ];
    navigate({
      search: createSearchString({
        startDatetime,
        endDatetime,
      }),
      hash: location.hash,
    });
  };

  return (
    <Formik
      initialValues={initialValues()}
      onSubmit={selectData}
      enableReinitialize={true}
    >
      {(): JSX.Element => {
        return (
          <Form>
            <Row>
              <Col xs={12} sm={8} lg={10} xl={10} className={"px-3 pr-sm-1"}>
                <DateRangePicker
                  startDateName={"startDatetime"}
                  endDateName={"endDatetime"}
                />
              </Col>
              <Col xs={12} sm={4} lg={2} xl={2} className={"py-1 px-3 pl-sm-1"}>
                <Button
                  type={"submit"}
                  color={"primary"}
                  style={{ width: "100%" }}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DateRangeOnlySelectorForm;
