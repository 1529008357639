import React, {useState} from 'react';
import {WirelessSurveyOut} from "../../openapi/model/wirelessSurveyOut";
import {Col, Row} from "reactstrap";
import BulkUpdateCSVDropZone from "../../components/BulkUpdate/BulkUpdateCSVDropZone";
import * as yup from "yup";
import {WirelessSurveyTarget} from "../../openapi/model/wirelessSurveyTarget";
import {convertCSVToDefinitions, UploadDefinition} from "../assetPositions/simpleAssetRegisterUpload/GenericUploadPage";
import UploadPreviewTable from "../assetPositions/simpleAssetRegisterUpload/UploadPreviewTable";
import UpdateButton from "../../components/Buttons/UpdateButton";


type ParsedTargetDefinition = UploadDefinition<WirelessSurveyTarget>


const importSchema = yup.object().shape({
    serialNumber: yup.number().integer().positive().required(),
    label: yup.string(),
    sortOrder: yup.number().transform((_, val) => val !== "" ? Number(val) : null).nullable(true).integer(),
});


function WirelessSurveyTargetTab(props: {
    survey: WirelessSurveyOut,
    refresh?: () => any;
}) {


    const [newTargets, setNewTargets] = useState<ParsedTargetDefinition[] | undefined>(undefined);

    const currentNumberOfTargets = props.survey.configuration?.targets?.length || 0;

    function processFile(data: Promise<ParsedTargetDefinition[]>) {
        data.then((
            values
        ) => {
            setNewTargets(values.map(v => {
                return {
                    ...v,
                    original: {...v.original, serialNumber: (!isNaN(v.original.serialNumber)) ? String(parseInt(v.original?.serialNumber)).padStart(8,'0'): v.original.serialNumber},
                    object: {...v.object, serialNumber: String(v.object?.serialNumber).padStart(8,'0')}
                }
            }))
        })
    }

    function postUpload() {
        setNewTargets(undefined)
        props.refresh && props.refresh()
    }


    return (
        <>
            <Row>
                <Col xs={12}>
                    <p>This survey currently has {currentNumberOfTargets} targets</p>
                    <p>You can change the targets for the survey by uploading a new CSV file. Once uploaded, you will be
                        shown a preview before you
                        save the changes.</p>
                    <p>Your csv file must contain the columns:
                        <ul>
                            <li>
                                <b>serialNumber</b> - The 8 digit secondary address for the device you want to find
                            </li>
                        </ul>
                    </p>
                    <p>Your csv file may contain the columns:
                        <ul>
                            <li>
                                <b>label</b> - A description you want to give to the device
                            </li>
                            <li>
                                <b>sortOrder</b> - An integer to set the default sort order of targets
                            </li>
                        </ul>
                    </p>
                    <p>
                        <a
                            href={
                                process.env.PUBLIC_URL + "/examples/wireless_survey_targets.csv"
                            }
                        >
                            Click to download an example file.
                        </a>
                    </p>


                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <BulkUpdateCSVDropZone
                        buttonLabel={"Upload new target CSV"}
                        parseComplete={(d) => {
                            processFile(convertCSVToDefinitions(d, importSchema))
                        }}
                    />
                    <UpdateButton
                        entityId={props.survey.surveyId}
                        entityType={"WIRELESS_SURVEY"}
                        text={'Save new targets'}
                        updatedEntity={{
                            ...props.survey, configuration: {
                                targets:
                                    newTargets?.filter(v => (v.errors || []).length===0).map(v => v.object)
                            }
                        }}
                        icon={'fa-save'}
                        size={'md'}
                        color={'primary'}
                        disabled={newTargets === undefined}
                        postUpdate={postUpload}
                    />
                    <UpdateButton
                        entityId={props.survey.surveyId}
                        entityType={"WIRELESS_SURVEY"}
                        text={'Delete current targets'}
                        updatedEntity={{...props.survey, configuration: {targets: []}}}
                        icon={'fa-trash'}
                        size={'md'}
                        color={'danger'}
                        disabled={currentNumberOfTargets === 0}
                        postUpdate={props.refresh}
                    />
                </Col>
                {newTargets &&
                    <Col xs={12}>
                        <UploadPreviewTable
                            definitions={newTargets}
                            requiredHeaders={['serialNumber']}
                            optionalHeaders={['label', 'sortOrder']}
                            uploadedEntityName={'target'}
                        />
                    </Col>}
            </Row>
        </>

    );
}

export default WirelessSurveyTargetTab;