import React from "react";
import { DeviceAsset } from "../../../openapi/model/deviceAsset";
import DeviceTimeSeriesDataContainer from "./DeviceTimeSeriesDataContainer";
import {
  ColumnMap,
  TimeSeriesDataParams,
} from "../../../model/deviceTimeSeries";
import { Moment } from "moment";
import DataTable, {
  TableColumn,
  ConditionalStyles,
} from "react-data-table-component";
import { formatDate } from "../../../components/utils/HighlightNullAndOldDates";
import { Col, Row } from "reactstrap";
import numberFormatter from "../../../components/Table/Formatters";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import AssetDataSearchStringParser from "./AssetDataSearchStringParser";
import AssetDataSelectorUrlQuerySetter from "../AssetDataSelectorUrlQuerySetter";
import DeviceTimeSeriesDataCsvExportButton from "./DeviceTimeSeriesDataCsvExportButton";
import _ from "lodash";
import { ErrorBanner } from "../../../components/Error/ErrorBanner";

const AssetTimeSeriesDataTableInner = (props: {
  mappedTimeSeriesColumns: ColumnMap;
  times: Moment[];
  params: TimeSeriesDataParams;
  loading: boolean;
  error?: Error;
}): JSX.Element => {
  const data: any[] = [];
  const columns: TableColumn<{ [key: string]: any }>[] = [
    {
      name: "Time",
      selector: (row) => row.time,
      sortable: true,
      minWidth: "150px",
      format: (row): string => formatDate(row.time as Date),
    },
  ];

  Object.entries(props.mappedTimeSeriesColumns).forEach(
    ([key, columnDefinition]) => {
      columns.push({
        name: (
          <span
            style={{
              textAlign: "right",
              whiteSpace: "normal",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {columnDefinition.name}
            {columnDefinition.unit !== "" && (
              <small>
                <br />({columnDefinition.unit})
              </small>
            )}
          </span>
        ),
        right: true,
        minWidth:
          _.lowerCase(props.params.projection) === "raw" ? "175px" : "100px",
        selector: (row) => row[key],
        sortable: true,
        format: numberFormatter(key),
        omit: columnDefinition.name === "errors",
      });
    }
  );

  props.times.forEach((time, index) => {
    const d: { [key: string]: any } = { time: time };
    Object.entries(props.mappedTimeSeriesColumns).forEach(
      ([key, columnDefinition]) => {
        d[key] = columnDefinition.data[index];
      }
    );

    data.push(d);
  });

  const rowHasError = (row: any): boolean => {
    if (row.errors === null || row.errors === undefined) {
      return false;
    } else {
      return row.errors !== [];
    }
  };

  const rowHighlighter: ConditionalStyles<{ [key: string]: string }> = {
    when: rowHasError,
    style: { backgroundColor: "orange" },
  };

  return (
    <Col>
      <BlockSpinner loading={props.loading}>
        <Row>
          <Col xs={12}>
            <span className={"float-right"}>
              <DeviceTimeSeriesDataCsvExportButton {...props} />
            </span>
          </Col>
          <Col xs={12}>
            <ErrorBanner error={props.error} />
          </Col>
          <DataTable
            columns={columns}
            data={data}
            pagination
            fixedHeader
            responsive={true}
            dense={true}
            conditionalRowStyles={[rowHighlighter]}
          />
        </Row>
      </BlockSpinner>
    </Col>
  );
};

const AssetTimeSeriesDataTableTab = (props: {
  device: DeviceAsset;
}): JSX.Element => {
  return (
    <>
      <AssetDataSearchStringParser device={props.device}>
        {({ searchParams }) => {
          return (
            <Row>
              <Col xs={12}>
                <AssetDataSelectorUrlQuerySetter
                  device={props.device}
                  {...searchParams}
                />
              </Col>
            </Row>
          );
        }}
      </AssetDataSearchStringParser>
      <DeviceTimeSeriesDataContainer
        device={props.device}
        render={AssetTimeSeriesDataTableInner}
      />
    </>
  );
};

export default AssetTimeSeriesDataTableTab;
