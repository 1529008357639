import React from 'react';
import {DeviceAsset} from "../../../openapi/model/deviceAsset";
import {GatewayAsset} from "../../../openapi/model/gatewayAsset";
import {AssetPosition} from "../../../openapi/model/assetPosition";
import {Col, Row} from "reactstrap";
import AssetDataSelectorUrlQuerySetter from "../AssetDataSelectorUrlQuerySetter";
import AssetRFAssessmentContainer from "./rfAssessment/AssetRFAssessmentContainer";
import AssetDataSearchStringParser from "./AssetDataSearchStringParser";
import ReceiverHistoryGetter from "./receivers/ReceiverHistoryGetter";
import ReceiverHistoryTable from "./receivers/ReceiverHistoryTable";
import DisplayIfUserType from "../../../components/utils/DisplayIfUserType/DisplayIfUserType";

interface Props {
  device: DeviceAsset | GatewayAsset;
  assetPosition: AssetPosition;
}
function AssetRfTab(props: Props) {

    return             <><Row>
              <Col xs={12} className={'align-right'}>
                      <AssetDataSearchStringParser device={props.device}>
      {({ searchParams }) =>
                 <AssetDataSelectorUrlQuerySetter
                  device={props.device}
                  {...searchParams}
                    showProjection={false}
                    />
      }</AssetDataSearchStringParser>
              </Col>
            </Row>
        <Row>
            <Col xs={12}>
                <AssetRFAssessmentContainer assetPosition={props.assetPosition} device={props.device}/>
            </Col>
            <DisplayIfUserType userTypes={'superadmin'}>
            <Col xs={12}>
                <h3>Receiver History</h3>
                <ReceiverHistoryGetter assetPosition={props.assetPosition}>
                    {({history}) => <ReceiverHistoryTable history={history}/> }
                </ReceiverHistoryGetter>
            </Col>
            </DisplayIfUserType>
        </Row>
        </>

}

export default AssetRfTab;