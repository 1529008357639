import ErrorOverlappingBanner from "components/Error/ErrorOverlappingBanner";
import React from "react";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import useGetData from "../../hooks/getData";
import { AssetRegisterWirelessTimeSeriesResponse } from "openapi/model/assetRegisterWirelessTimeSeriesResponse";
import { WirelessReportRequest } from "../../openapi/model/wirelessReportRequest";

export interface WirelessRFDataApiWrapperProps {
  error?: Error;
  data?: AssetRegisterWirelessTimeSeriesResponse;
  loading: boolean;
}

export default function WirelessRFDataApiWrapper(props: {
  request: WirelessReportRequest;
  children: (props: WirelessRFDataApiWrapperProps) => React.ReactNode;
}): JSX.Element {
  const [{ error, data, loading }] =
    useGetData<AssetRegisterWirelessTimeSeriesResponse>({
      path: "report/time_series/rf",
      companyId: props.request.companyId as string,
      schemeId: props.request.schemeId,
      params: props.request,
      post: true,
      singletonApi: true,
      convertSnakeCaseKeysOnlyToCamelCase: true,
    });

  return (
    <>
      <ErrorOverlappingBanner error={error || undefined}>
        <BlockSpinner loading={loading}>
          {props.children({ error: error || undefined, data, loading })}
        </BlockSpinner>
      </ErrorOverlappingBanner>
    </>
  );
}
