import React from 'react';
import NewLabelWrapper, {LabelProps} from "./NewLabelWrapper";
import {isGroupBase, isOption, Option, Value, ValueList} from "./SelectCombo/SelectCombo";
import {Checkbox} from "@psd-platform/monday-ui-react-core";
import {isArray, isArrayLike} from "lodash";

export interface NewCheckboxComponentProps {
    name: string;
    label?: LabelProps;
    onChange: (newValue: ValueList) => void,
    options?: Option[];
    value: ValueList,
    error?: string,
    touched?: boolean;
    disabled?: boolean;
}

function isValueInOptions(value: ValueList, option: Option): boolean {
    if (isOption(option)) {
        if (isArrayLike(value)) {
            return value.indexOf(option.value as string) !== -1
        } else {
            return option.value === value
        }
    } else {
        return false
    }

}

function NewCheckboxComponent(props: NewCheckboxComponentProps) {
    const {name, label, error, disabled} = props

    const clickHandler = (value: Option) => {
        const currentValue: Value[] = isArray(props.value) ? props.value : [props.value]
        const newValue = value.value as unknown as string
        if (isValueInOptions(currentValue, value)) {
            props.onChange(
                currentValue.filter(v => v !== newValue),
            )
        } else {
            props.onChange([
                ...currentValue,
                newValue
            ])
        }
    }

    return (
        <NewLabelWrapper {...{...label, error: error}}>
            <div className={'mx-2'}>
            {props.options?.map((option, key) => {
                if (isGroupBase(option)) {
                    throw new Error('Groups are not supported for checkboxes')
                } else {
                const selected = isValueInOptions(props.value, option)
                    return <Checkbox
                        name={name}
                        label={option.label}
                        key={key}
                        value={option.value as unknown as string}
                        disabled={disabled}
                        checked={selected}
                        onChange={() => clickHandler(option)}
                    />
                }
            })}
                </div>
        </NewLabelWrapper>
    );
}

export default NewCheckboxComponent;