import React from 'react';
import {PageDefinition} from "../navigation/breadcrumbs/Breadcrumbs";
import SimpleHeader from "../Headers/SimpleHeader";
import {Container, Row} from "reactstrap";


interface Props {
    breadcrumbs?: PageDefinition[];
    noSchemeInBreadcrumbs?: boolean;
    children?: React.ReactChild | React.ReactChild[];
}

const MultipleCardPage = (props: Props): JSX.Element => {
    return <>
        <SimpleHeader breadcrumbs={props.breadcrumbs} noSchemeInBreadcrumbs={props.noSchemeInBreadcrumbs}/>
        <Container className="mt--6" fluid>
            <Row>
                {props.children}
            </Row>
        </Container>
    </>
}


export default MultipleCardPage;