import React from 'react';
import {AssetPositionAssetLinkOut} from "../../../openapi/model/assetPositionAssetLinkOut";
import AssetPositionAssetRowContainer from "./AssetPositionAssetRowContainer";
import AssetPositionAssetRowInner, {AssetPositionAssetRowInnerProps} from "./AssetPositionAssetRowInner";

function AssetPositionAssetRow(props: {
    value: AssetPositionAssetLinkOut,
    view: 'asset'|"assetPosition"
    refreshList?: () => void
}) {
    return <AssetPositionAssetRowContainer
     link={props.value} refreshList={props.refreshList}>
        {(innerProps :AssetPositionAssetRowInnerProps) => {
            return <AssetPositionAssetRowInner view={props.view}
                                               {...innerProps}/>;
        }}
    </AssetPositionAssetRowContainer>
}

export default AssetPositionAssetRow;