import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CreateNoteForm from "./CreateNoteForm";
import { ExpandedNote } from "../../openapi/model/expandedNote";
import { Note } from "../../openapi/model/note";
import { AssetPosition } from "openapi/model/assetPosition";
import NoteHistoryAccordian from "./NoteHistoryAccordian";

interface Props {
  assetPosition?: AssetPosition;
  onCreate?: () => void;
  onUpdate?: (note: Note) => void;
  children: (props: { openModal: () => void; isOpen: boolean }) => JSX.Element;
  note?: Note | ExpandedNote;
  creating?: boolean;
  editing?: boolean;
}

function title(editing = false, creating = false): string {
  if (editing) {
    return "Edit Note";
  }
  if (creating) {
    return "Add New Note";
  }
  return "Note";
}

export default function NoteModal(props: Props): React.ReactElement {
  const [open, setOpen] = useState(false);
  const [refreshed, setRefreshed] = useState(new Date());

  function onCreate(): void {
    props.onCreate && props.onCreate();
    setOpen(false);
  }

  function onUpdate(note: Note): void {
    setRefreshed(new Date());
    props.onUpdate && props.onUpdate(note);
  }

  const { children, editing = false, creating = false, ...rest } = props;

  return (
    <>
      <Modal
        isOpen={open}
        unmountOnClose={true}
        toggle={() => setOpen(!open)}
        size={"lg"}
      >
        <ModalHeader toggle={() => setOpen(!open)}>
          {title(editing, creating)}
        </ModalHeader>
        <ModalBody>
          <CreateNoteForm {...rest} onCreate={onCreate} onUpdate={onUpdate} />
          {props.note && (
            <NoteHistoryAccordian note={props.note} lastUpdate={refreshed} />
          )}
        </ModalBody>
      </Modal>
      {children({
        isOpen: open,
        openModal: () => setOpen(true),
      })}
    </>
  );
}
