import React from "react";
import GetList, { GetListProps } from "core/action/GetList";
import NewApiPaginatedList from "./NewApiPaginatedList";

interface Props extends GetListProps {
  row: (item: any, index: number, refresh?: () => void) => JSX.Element;

  header?: JSX.Element;

  "data-cy"?: string; // optional cypress label for the table
  tableClassName?: string;
  noDataRow?: JSX.Element;
  showPagination?: boolean;
  additionalFunctionButtons?: JSX.Element;
}

export default function GetListPaginatedList(props: Props): JSX.Element {
  const { entityType, params, cursor, path, ...rest } = props;

  return (
    <GetList
      entityType={entityType}
      params={params}
      cursor={cursor}
      path={path}
      lastChange={props.lastChange}
    >
      {({ data, loading, error, refresh }) => (
        <NewApiPaginatedList
          data={data}
          error={error}
          loading={loading}
          refresh={refresh}
          {...rest}
        />
      )}
    </GetList>
  );
}
