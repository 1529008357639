import React from "react";
import classNames from "classnames";

export interface LabelProps {
    label?: string;
    size?: "sm" | "md" | "lg";
    bold?: boolean;
    error?: string;
    showErrors?: boolean;

}

export interface NewLabelWrapperProps extends LabelProps, React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
}

export default function NewLabelWrapper(
    props: NewLabelWrapperProps
): JSX.Element {
    const {
        label,
        children,
        error,
        className,
        size = "md",
        bold = undefined,
        showErrors = true,
        ...rest
    } = props;

    const Tag = {
        'sm': `h6`,
        'md': `h5`,
        'lg': `h4`
    }[size] as keyof JSX.IntrinsicElements;
    return (
        <div className={
            classNames({
                'd-block m-0 p': true, 'mb-4': showErrors, 'my-1': !showErrors, 'mt-1': true
            }, className
        )}
             {...rest}>
            {label &&
                <Tag
                    style={{
                        textTransform: size === "sm" ? "uppercase" : undefined,
                    }}
                    className={classNames({
                        "mb-0": size === 'sm',
                        "mb-1": size !== 'sm',
                        "px-2": size === 'sm',
                        "d-block": true,
                        'font-weight-normal': bold === false || (bold === undefined && size !== "sm"),
                    })}
                >
                    {label}
                </Tag>}
            {children}
            {showErrors &&
                <h6 className={'font-weight-normal mx-2 my-1 float-right'}>{error ? error : <>&nbsp;</>}</h6>}
        </div>
    );
}

