import React from 'react';
import {NewTimeSeriesRequestParams} from "../model";
import useGetData from "../../../hooks/getData";
import {AggregatedStatsRequest} from "../../../openapi/model/aggregatedStatsRequest";
import {UseAxiosResult} from "axios-hooks";
import {AggregatedStatsResponse} from "../../../containers/report/latestDataReport/TimeSeriesStatsApiWrapper";

interface AggregatedTimeSeriesStatsWrapperProps {
    companyId: string,
    schemeId?: string,
    params: NewTimeSeriesRequestParams,
    children: (data: UseAxiosResult<AggregatedStatsResponse>) => React.ReactNode,
}

function AggregatedTimeSeriesStatsWrapper(props: AggregatedTimeSeriesStatsWrapperProps) {

    const params = (): AggregatedStatsRequest => {
        const {
            startDate,
            endDate,
            parameter,
            aggregation,
            period,
            group,
            assetType,
            groupBy,
            useCase
        } = props.params;
        if (props.companyId) {
            return {
                companyId: props.companyId,
                schemeId: props.schemeId,
                parameter: parameter,
                aggregation: aggregation,
                startDatetime: startDate?.toDate(),
                endDatetime: endDate?.toDate(),
                period,
                group: group === "undefined" ? undefined : group,
                assetTypes: assetType,
                groupBy: groupBy,
                useCase,
            };
        } else {
            throw new Error("Company is not selected.");
        }
    };

    const data = useGetData<AggregatedStatsResponse>({
        path: "report/time_series/stats",
        companyId: props.companyId,
        schemeId: props.schemeId,
        params: params(),
        post: true,
        singletonApi: true,
        convertSnakeCaseKeysOnlyToCamelCase: true,  /*do not convert scheme names*/
    });

    return (
        <>{props.children(data)}</>
    );
}

export default AggregatedTimeSeriesStatsWrapper;