/**
 * Meterpoint.io
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type GatewaySettingsType = 'ELVACO_DEFAULT' | 'ELVACO_CMI_WIRELESS_ONLY' | 'CUSTOM';

export const GatewaySettingsType = {
    ELVACODEFAULT: 'ELVACO_DEFAULT' as GatewaySettingsType,
    ELVACOCMIWIRELESSONLY: 'ELVACO_CMI_WIRELESS_ONLY' as GatewaySettingsType,
    CUSTOM: 'CUSTOM' as GatewaySettingsType
};