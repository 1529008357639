import React from "react";
import GetList from "../../core/action/GetList";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import ErrorOverlappingBanner from "../../components/Error/ErrorOverlappingBanner";
import RequireScheme from "../../components/Scheme/RequireScheme";
import { useSelectedScheme } from "../../reducers/scheme";
import { PaginatedData } from "../../hooks/getList";
import { TariffOut } from "../../openapi/model/tariffOut";
import _ from "lodash";

interface SchemeTariffListWrapperProps {
  children: (props: { data: PaginatedData<TariffOut> }) => React.ReactNode;
}

function SchemeTariffListWrapper(props: SchemeTariffListWrapperProps) {
  const scheme = useSelectedScheme();

  return (
    <RequireScheme>
      {scheme && (
        <GetList
          entityType={"TARIFF"}
          params={{ companyId: scheme.companyId, schemeId: scheme.schemeId }}
        >
          {({ data, error, loading }) => (
            <BlockSpinner loading={loading}>
              <ErrorOverlappingBanner error={error}>
                {!_.isUndefined(data) ? props.children({ data }) : <></>}
              </ErrorOverlappingBanner>
            </BlockSpinner>
          )}
        </GetList>
      )}
    </RequireScheme>
  );
}

export default SchemeTariffListWrapper;
