import React from "react";
import { OneCardPage } from "components/utils/OneCardPage";
import JobCreateForm from "./JobCreateForm";
import { useSelectedCompany } from "../../reducers/company";
import { useSelectedScheme } from "../../reducers/scheme";

const JobCreatePage = (): JSX.Element => {
  const companyId = useSelectedCompany()?.companyId;
  const schemeId = useSelectedScheme()?.schemeId;
  return (
    <>
      {companyId && (
        <OneCardPage headerTitle={"Create New Data Job"}>
          <JobCreateForm companyId={companyId} schemeId={schemeId} />
        </OneCardPage>
      )}
    </>
  );
};

export default JobCreatePage;
