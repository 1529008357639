import Config from "../Config";
import useAxios, {UseAxiosResult} from "axios-hooks";
import {objectKeysToSnakeCase} from "../actions/helpers/apiService";
import {convertKeys} from "./getList";
import {useCallback} from "react";
import axios, {AxiosResponse} from "axios";

interface GetDataProps {
    path: string;
    companyId: string;
    schemeId?: string;
    params: Record<string, any>;
    singletonApi?: boolean;
    convertSnakeCaseKeysOnlyToCamelCase?: boolean;
    transform?: boolean;
    post?: boolean;
}


export const getData = <T, >({
                                 path,
                                 companyId,
                                 schemeId,
                                 params,
                                 singletonApi = false,
                                 convertSnakeCaseKeysOnlyToCamelCase = false,
                                 transform = true,
                                 post = false,
                             }: GetDataProps): Promise<AxiosResponse<T>> => {
    const url = Config.apiGateway.URL + "/" + path;

    const request = objectKeysToSnakeCase({
        companyId,
        schemeId,
        ...params,
    });

    return axios({
        url,
        method: post ? "POST" : "GET",
        params: !post && request,
        data: post && request,
        transformResponse: (data: any) => {
            const parsed = singletonApi ? JSON.parse(data) : JSON.parse(data)[0];
            return transform ? convertKeys(parsed, convertSnakeCaseKeysOnlyToCamelCase) : parsed;
        }
    });
}

const useGetData = <T, >({
                             path,
                             companyId,
                             schemeId,
                             params,
                             singletonApi = false,
                             convertSnakeCaseKeysOnlyToCamelCase = false,
                             post = false,
                         }: GetDataProps): UseAxiosResult<T> => {
    const url = Config.apiGateway.URL + "/" + path;

    const request = objectKeysToSnakeCase({
        companyId,
        schemeId,
        ...params,
    });
    return useAxios<T>({
        url,
        method: post ? "POST" : "GET",
        params: !post && request,
        data: post && request,
        transformResponse: useCallback(
            (data: any) => {
                const parsed = singletonApi ? JSON.parse(data) : JSON.parse(data)[0];
                return convertKeys(parsed, convertSnakeCaseKeysOnlyToCamelCase);
            },
            [singletonApi, convertSnakeCaseKeysOnlyToCamelCase]
        ),
    });
};

export default useGetData;
