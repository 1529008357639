import React from 'react';
import {Col, Row} from "reactstrap";

function IntegrationListHeader() {
    return     <li
      className={
        "checklist-entry list-group-item px-0 px-md-2 list-heading"
      }
      data-cy={"asset-register-list-heading"}
    >
        <div className={"d-sm-block d-none"}>
            <Row>
            <Col sm={8} md={5} lg={3}>Name</Col>
            <Col sm={4} md={3} lg={3}>Type</Col>
            <Col className={'d-none d-md-block'} md={4} lg={6}>Assets</Col>

            </Row>
        </div>
    </li>
}

export default IntegrationListHeader;