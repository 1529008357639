import React from "react";
import { AssetPosition } from "../../openapi/model/assetPosition";
import { Asset } from "../../openapi/model/asset";
import { AssetPositionAssetLinkIn } from "../../openapi/model/assetPositionAssetLinkIn";
import CreateButton from "../../components/Buttons/CreateButton";

type Props = {
  assetPosition: AssetPosition;
  asset: Asset;
  refresh?: () => any;
};

const LinkAssetPositionFromAssetButton = (props: Props): JSX.Element => {
  return (
    <CreateButton
      entityType={"LINK"}
      entity={
        {
          assetId: props.asset.assetId,
          assetPositionId: props.assetPosition.assetPositionId,
          companyId: props.asset.companyId,
        } as AssetPositionAssetLinkIn
      }
      color={"primary"}
      postCreate={props.refresh}
      text={"Link Asset"}
      icon={"fa-link"}
    />
  );
};

export default LinkAssetPositionFromAssetButton;
