import moment, { Moment } from "moment";

export const inclusiveDatePeriod = (
  startDate?: Moment,
  endDate?: Moment,
  numberOfDays = 14,
  defaultEndIsToday = false
): [Moment, Moment] => {
  const now = moment();

  const actualEndDate = endDate
    ? endOfDay(endDate)
    : defaultEndIsToday
    ? endOfDay(now)
    : endOfDay(now).subtract(1, "day");

  const actualStartDate = startDate
    ? startDate.startOf("day")
    : moment(
        +actualEndDate +
          +moment.duration(1, "millisecond") +
          +moment.duration(-numberOfDays, "days")
      );
  return [actualStartDate, actualEndDate];
};

export const endOfDay = (date: Moment): Moment => {
  return moment(+date.endOf("day"));
};

export default inclusiveDatePeriod;
