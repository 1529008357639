import React from 'react';
import {Form, Formik, FormikHelpers} from "formik";
import FormComponent from "../../components/Forms/FormComponent";
import {Button} from "reactstrap";
import * as Yup from "yup";
import {CreateEntity} from "../../hooks/createEntity";
import {WirelessSurveyOut} from "../../openapi/model/wirelessSurveyOut";
import {WirelessSurveyIn} from "../../openapi/model/wirelessSurveyIn";
import {useSelectedCompany} from "../../reducers/company";
import {ErrorBanner} from "../../components/Error/ErrorBanner";


interface FormValues {
    surveyName: string
}
function WirelessSurveyCreateForm(props: {
    createWirelessSurvey: CreateEntity<WirelessSurveyOut, WirelessSurveyIn>,
    onCreate?: (surveyId: string) => void;
}) {
    const company = useSelectedCompany();

    const {createEntity,  error}= props.createWirelessSurvey
    async function submit(values: FormValues,
                              { setSubmitting, resetForm }: FormikHelpers<FormValues>

    ) {
        if (company === undefined) {
            throw new Error('Company must be selected.')
        }
        setSubmitting(true);
        createEntity({
            companyId: company.companyId,
            ...values
        }).then(
            ([d]) => {
                resetForm()
                props.onCreate && props.onCreate(d.surveyId);
            }
        ).finally(
            () => {setSubmitting(false)}
        )
    }

    return (
        <Formik
            initialValues={{
            surveyName: ''
        }}
            onSubmit={submit}
            validationSchema={Yup.object().shape({
                surveyName: Yup.string().required().min(5)
            })}
            validateOnMount={true}
        >
        {({  touched, isValid, isSubmitting }): JSX.Element => (

            <><Form>
              <FormComponent
                fieldName={"surveyName"}
                label={"Name"}
                  submitSpinner={false}
              />
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting || !isValid || !touched}
              >
                Create
              </Button>
            </Form>
            <ErrorBanner error={error}/>
            </>
        )
            }
        </Formik>
    );
}

export default WirelessSurveyCreateForm;