import React from "react";
import {Option,} from "../../../components/Forms/SelectComponent";
import {Frequency} from "../../../openapi/model/frequency";
import {SelectCombo, SelectComboProps} from "../../../components/Forms/SelectCombo/SelectCombo";

export default function FrequencySelect(
  props: Omit<SelectComboProps, 'options'>
): JSX.Element {

  const options: Option[] = [
    { label: "Daily", value: Frequency.DAILY },
    { label: "Weekly", value: Frequency.WEEKLY },
    { label: "Monthly", value: Frequency.MONTHLY },
  ];

  return (
    <SelectCombo
      options={options}
      {...props}
    />
  );
}
