import React from "react";
import {
  AssetRegisterDataItem,
  AssetRow,
  DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER,
} from "../../../components/Table/AssetRegisterData";
import AssetRegisterReportTable from "../allPeriodReportTable/AssetRegisterReportTable";
import moment from "moment";
import { LatestTimeSeriesResponse } from "./LatestTimeSeriesApiWrapper";
import HighlightNullAndOldDates from "../../../components/utils/HighlightNullAndOldDates";
import { Primitive } from "react-data-table-component/dist/src/DataTable/types";
import _ from "lodash";
import { PrivacyModeTableColumn } from "../../../components/Text/PrivacyModeDataTableCell";

export interface AssetRegisterLatestTimeSeriesData
  extends AssetRegisterDataItem {
  time: Date;
}

const mapData = (
  data?: LatestTimeSeriesResponse
): [Array<AssetRegisterLatestTimeSeriesData>, Array<string>] => {
  const keys: Set<string> = new Set<string>();
  const mappedData = (data ? data : []).map((value) => {
    const { values = [], time, ...asset } = value;
    const thisAssetMappedData = values ? Object.fromEntries(
      values.map((data) => {
        keys.add(data.name as string);
        return [data.name, data.value];
      })
    ) : {};

    return {
      ...asset,
      time: time as Date,
      ...thisAssetMappedData,
    };
  });

  return [mappedData, Array.from(keys.values())];
};

const LatestDataReportTable = (props: {
  data?: LatestTimeSeriesResponse;
  companyId: string;
  schemeId: string
}): JSX.Element => {
  const columns = (
    keys: Array<string>
  ): PrivacyModeTableColumn<AssetRegisterDataItem>[] => {
    return [
      {
        name: "Gateway",
        selector: (row) => row.gatewayAssetId || "-",
        sortable: true,
        compact: true,
        width: "150px",
        cell: AssetRow(undefined, undefined, true),
      },
      {
        name: "Gateway Last Contact",
        sortable: true,
        grow: 2,
        width: "225px",
        cell: function DataCell(row): JSX.Element {
          return (
            <HighlightNullAndOldDates ageHighlight={moment.duration(3, "days")}>
              {row.gatewayLastContactTime}
            </HighlightNullAndOldDates>
          );
        },
        selector: (row) =>
          row.gatewayLastContactTime
            ? (row.gatewayLastContactTime as unknown as string)
            : "-",
      },
      {
        name: "Time",
        sortable: true,
        width: "225px",
        cell: function DataCell(row): JSX.Element {
          return (
            <HighlightNullAndOldDates ageHighlight={moment.duration(3, "days")}>
              {row.time as Date}
            </HighlightNullAndOldDates>
          );
        },
        selector: (row) => (row.time ? (row.time as unknown as string) : "-"),
      },
      ...keys.map((value) => {
        return {
          name: (
            <span
              style={{
                textAlign: "right",
                whiteSpace: "normal",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {value}
            </span>
          ),
          minWidth: "100px",
          selector: (row: AssetRegisterDataItem) =>
            (Number(row[value]) as Primitive | undefined) ||
            Number.NEGATIVE_INFINITY,
          sortable: true,
          right: true,
          compact: true,
          format: (row: AssetRegisterDataItem) => {
            const data = row[value];
            if (_.isNil(data) || _.isNaN(data)) {
              return "-";
            } else {
              return _.toString(
                (Math.round(_.toNumber(data) * 100) / 100).toFixed(1)
              );
            }
          },
        };
      }),
    ];
  };

  const [mappedData, keys] = mapData(props.data);

  return (
    <AssetRegisterReportTable
      data={mappedData}
      columnDefinitions={columns(keys)}
      startDatetime={moment().endOf("day").subtract(15, "days")}
      endDatetime={moment().endOf("day")}
      csvColumnOrder={[
        ...DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER,
        "gatewayAssetId",
        "gatewayLastContactTime",
        "gatewaySerialNumber",
        "locationCustomerReference",
        "time",
        ...keys,
      ]}
      downloadFilename={`latest_data_${props.companyId}_${props.schemeId}`}
    />
  );
};

export default LatestDataReportTable;
