import React from 'react';
import {SelectCombo, SelectComboProps} from "components/Forms/SelectCombo/SelectCombo";
import {getAggregations} from "../model";
interface AssetAggregationSelectComboProps extends Omit<SelectComboProps, 'options'> {
    assetParameter: string
}

function renderAggregationLabel(value: string): string {

    return  value.replaceAll('(','- ').replaceAll('_',' ').replaceAll(')','').toLowerCase().replace(/(\s\w|^\w)/g, function (txt) { return txt.toUpperCase(); })
}

function AssetAggregationSelectCombo(props: AssetAggregationSelectComboProps) {
    const {assetParameter, ...rest} = props;

    return <SelectCombo {...rest} options={
        getAggregations(assetParameter).map((v) => {
            return {label: renderAggregationLabel(v), value: v}
        })}
    />
}

export default AssetAggregationSelectCombo;