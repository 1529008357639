import React from "react";
import AssetDataSelectionForm, {
  AssetTimeSeriesRequestParams,
} from "./meters/AssetDataSelectionForm";
import { DeviceAsset } from "../../openapi/model/deviceAsset";
import { GatewayAsset } from "../../openapi/model/gatewayAsset";
import { createSearchString } from "../../components/navigation/SearchString";
import { useLocation, useNavigate } from "react-router-dom";

interface Props extends AssetTimeSeriesRequestParams {
  device: DeviceAsset | GatewayAsset;
  showProjection?: boolean
}

const AssetDataSelectorUrlQuerySetter = (props: Props): JSX.Element => {
  const {showProjection=true} = props
  const initialValues = (): AssetTimeSeriesRequestParams => {
    const { startDatetime, endDatetime, projection } = props;
    return { startDatetime, endDatetime, projection };
  };
  const navigate = useNavigate();
  const location = useLocation();

  const selectData = (values: AssetTimeSeriesRequestParams): void => {
    const { projection, startDatetime, endDatetime } = values;
    navigate({
      search: createSearchString({
        startDatetime: startDatetime.toISOString(true),
        endDatetime: endDatetime.toISOString(true),
        projection,
      }),
      hash: location.hash,
    });
  };

  return (
    <AssetDataSelectionForm
      {...initialValues()}
      device={props.device}
      loadData={selectData}
      showProjection={showProjection}
    />
  );
};

export default AssetDataSelectorUrlQuerySetter;
