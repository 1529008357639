import React from "react";
import { IconBaseProps, IconType } from "react-icons";
import { FaSpinner } from "react-icons/fa";

interface SpinnerProps extends IconBaseProps {
  spin?: boolean;
  alt?: IconType;
}

const Spinner = ({
  spin = false,
  alt,
  className,
  ...rest
}: SpinnerProps): JSX.Element => {
  return (
    <>
      {spin ? (
        <FaSpinner {...rest} className={`fa-spin fa-fw ${className}`} />
      ) : (
        <>
          {alt &&
            React.createElement(alt, {
              className: `fa-fw ${className}`,
              ...rest,
            })}
        </>
      )}
    </>
  );
};

export default Spinner;
