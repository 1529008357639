import React from "react";

import { OneCardPage } from "../../components/utils/OneCardPage";
import JobList from "./JobList";
import CompanyNavLink from "components/navigation/CompanyNavLink";
import { Button } from "reactstrap";
import DisplayIfUserType from "components/utils/DisplayIfUserType/DisplayIfUserType";
import SelectedScheme from "components/Scheme/SelectedScheme";

export const JobsListPage = (): JSX.Element => {
  return (
    <OneCardPage
      headerTitle={"List All Scheduled Jobs"}
      breadcrumbs={[
        { url: "/admin/job", name: "Jobs" },
        { url: "/admin/job", name: "List" },
      ]}
    >
      <SelectedScheme>{(props) => <JobList {...props} />}</SelectedScheme>
      <DisplayIfUserType userTypes={"admin"}>
        <CompanyNavLink to={"/admin/job/create"} tag={Button}>
          Create New Job
        </CompanyNavLink>
      </DisplayIfUserType>
    </OneCardPage>
  );
};
