import React from 'react';
import {AssetPositionAssetLinkOut} from "../../../openapi/model/assetPositionAssetLinkOut";
import {FormikProps} from "formik";
import {Row} from "reactstrap";
import AssetPositionAssetLinkDateSelect from "./AssetPositionAssetLinkDateSelect";


function AssetPositionAssetLinkForm(props: FormikProps<AssetPositionAssetLinkOut>) {

    return <>
        <Row>
            <AssetPositionAssetLinkDateSelect
                serialNumber={props.values.serialNumber}
                customerReference={props.values.assetPositionCustomerReference}
            />
        </Row>
    </>
}

export default AssetPositionAssetLinkForm;