import React from "react";
import {Option,} from "../../../components/Forms/SelectComponent";
import {VolumeUnits} from "../../../openapi/model/volumeUnits";
import {SelectCombo, SelectComboProps} from "../../../components/Forms/SelectCombo/SelectCombo";

const VolumeUnitSelect = (
  props: Omit<SelectComboProps, 'options'>
): JSX.Element => {

  const options: Option[] = [
    { label: "m3", value: VolumeUnits.M3 },
    { label: "Litres", value: VolumeUnits.Litres },
  ];

  return (
    <SelectCombo
      options={options}
      {...props}
    />
  );
};

export default VolumeUnitSelect;
