import React, {useState} from 'react';
import {Event} from "../../openapi/model/event";

import * as _ from 'lodash';
import 'react-json-pretty/themes/monikai.css';
import classNames from "classnames";
import JSONPretty from "react-json-pretty";

const EventMoreDetailCaret = (props: { event: Event }): JSX.Element => {
    const [display, setDisplay] = useState(false);
    const event = props.event;

    return <>
        {!_.isEmpty(event.eventData) && event.eventType !== "JOB_COMPLETED" &&
        <>
        <span onClick={(): void => setDisplay(!display)}>
               <i className={classNames({
                   'fa fa-fw': true,
                   'fa-caret-right': !display,
                   'fa-caret-down': display
               })}/>{display ? 'Hide' : 'More'}</span>
            {display && <div style={{maxWidth: '35rem'}}><JSONPretty data={event.eventData}/></div>}
        </>

        }
    </>
}

export default EventMoreDetailCaret;