import React from "react";
import { Asset } from "openapi/model/asset";
import RelayStateLabel from "./input_output_module/RelayStateLabel";
import { GatewayState } from "../../openapi/model/gatewayState";
import GatewayStateLabel from "./gateway/GatewayStateLabel";
import { InputOutputModuleState } from "../../openapi/model/inputOutputModuleState";

const AssetStateLabel = (
  props: {
    asset?: Asset;
    size?: "sm" | "md" | "lg";
  } & React.HTMLAttributes<HTMLSpanElement>
): JSX.Element => {
  const { asset, ...rest } = props;
  const relay = asset?.assetType === "input_output_module";
  const gateway = asset?.assetType === "gateway";
  const receiver = asset?.assetType === "wireless_receiver";

  if (asset && asset.state) {
    if (relay) {
      return (
        <RelayStateLabel
          state={(asset.state as InputOutputModuleState).relayState}
          {...rest}
        />
      );
    } else if (gateway || receiver) {
      return (
        <GatewayStateLabel
          state={(asset.state as GatewayState).configurationState}
          {...rest}
        />
      );
    }
  }
  return <></>;
};

export default AssetStateLabel;
