import React from "react";
import {useField} from "formik";
import {Value} from "../ButtonGroup";

export interface ButtonGroupFieldProps {
    name: string;
    children: (props: {
        name: string,
        onChange: (value: Value, name: string) => void,
        value: Value,
        error?: string,
        touched?: boolean,
    }) => React.ReactNode;
    onChange?: (value: Value, name: string) => Promise<any>
}

export default function ButtonComboField(props: ButtonGroupFieldProps): React.ReactElement {
    const [{value,}, {error, touched}, {setValue, setTouched}] = useField(props.name);

    const onChangeFormikSet = (newValue: Value) => {
        setTouched(true);

        if (props.onChange) {
            props.onChange(newValue, props.name).then(() => setValue(newValue))
        } else {
            setValue(newValue)
        }
    };

    return <>{props.children({name: props.name, onChange: onChangeFormikSet, value, error, touched})}</>
}

