import {Form, Formik} from 'formik';
import React from 'react';
import * as Yup from "yup";
import {meterOptions} from "components/Asset/AssetTypeSelectCombo";
import {AssetType} from "../AssetParameterSelectCombo/AssetParameterSelectCombo";
import {Period} from "openapi/model/period";
import {NewTimeSeriesRequestParams,} from "../model";
import {FormikHelpers} from "formik/dist/types";
import {ReportView} from "../../../openapi/model/reportView";
import timeSeriesTemplates from "../../../constants/timeSeriesTemplates.json";
import AggregatedTimeSeriesQuerySubForm, {
    AggregatedTimeSeriesQuerySubFormValues,
    AggregatedTimeSeriesQuerySubFormProps,
    AggregatedTimeSeriesQuerySubFormSchema
} from "./AggregatedTimeSeriesQuerySubForm";
import NewButton from "../../Forms/NewButton/NewButton";
import {ButtonColor} from "@psd-platform/monday-ui-react-core/dist/types/components/Button/ButtonConstants";
import {isEqual} from "lodash";
import {Col} from "reactstrap";
import NewDateRangePickerField from "../../Forms/NewDateRangePickerField/NewDateRangePickerField";
import NewDateRangePicker from "../../Forms/NewDateRangePicker/NewDateRangePicker";
import {Moment} from "moment";


export interface AggregatedTimeSeriesQueryFormValues {
    query: AggregatedTimeSeriesQuerySubFormValues
    startDate: Moment
    endDate: Moment
}

export function mapFormValuesToTimeSeriesParams(values: AggregatedTimeSeriesQueryFormValues, includeGroupBy = false, includeViewBy = true, includeAssetUseCase=false): NewTimeSeriesRequestParams {
    const _q: NewTimeSeriesRequestParams = {
        ...values.query,
        parameter: values.query.parameter as string,
        aggregation: values.query.aggregation as string,
        assetType: values.query.assetType as unknown as (keyof typeof timeSeriesTemplates)[],
        startDate: values.startDate,
        endDate: values.endDate,
    }
    if (!includeGroupBy) {
        _q.groupBy = undefined
    }
    if (!includeViewBy) {
        _q.viewBy = undefined
    }
    if (!includeAssetUseCase) {
        _q.useCase = undefined
    }
    return _q

}

export function mapTimeSeriesRequestParamsToFormValues(params: NewTimeSeriesRequestParams): AggregatedTimeSeriesQueryFormValues {
    const {startDate, endDate, ...query} = params;
    return {
        startDate, endDate, query: {...query, viewBy: query.viewBy || ReportView.ASSETPOSITION}
    }
}


export interface AggregatedTimeSeriesQueryFormProps extends Omit<AggregatedTimeSeriesQuerySubFormProps, 'namespace'> {
    onSubmit: (values: AggregatedTimeSeriesQueryFormValues) => Promise<any>,
    initialValues?: AggregatedTimeSeriesQueryFormValues
}


function AggregatedTimeSeriesQueryForm(props: AggregatedTimeSeriesQueryFormProps) {
    const {
        initialValues = {
            query: {
                period: Period.DAILY,
                assetType: meterOptions as AssetType[],
                aggregation: 'last',
                parameter: 'Energy (Heating)',
                viewBy: ReportView.ASSETPOSITION,
            }
        } as AggregatedTimeSeriesQueryFormValues,
        onSubmit,
        ...rest
    } = props;
    const {showErrors, wide} = rest

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
                query: AggregatedTimeSeriesQuerySubFormSchema
            })}
            onSubmit={(values: AggregatedTimeSeriesQueryFormValues, helpers: FormikHelpers<AggregatedTimeSeriesQueryFormValues>): Promise<any> => {
                helpers.setSubmitting(true)
                return onSubmit(values).finally(() => helpers.setSubmitting(false))

            }}>
            {({values, submitForm}): JSX.Element => {
                const isChanged = !isEqual(values, initialValues)

                return (
                    <Form>
                        <AggregatedTimeSeriesQuerySubForm
                            namespace={'query'}
                            submitButton={
                                <><Col xs={12} md={wide ? 9 : 12}>

                                    <NewDateRangePickerField startDateFieldName={'startDate'}
                                                             endDateFieldName={'endDate'}>
                                        {(fieldProps) => <NewDateRangePicker
                                            label={{label: 'Date range', size: 'sm', showErrors}}
                                            numberOfMonths={2}
                                            {...fieldProps}/>}
                                    </NewDateRangePickerField>
                                </Col>
                                    <Col xs={12} md={wide ? 3 : 12} className={'mt-auto mb-1 px-2 pt-2 pt-lg-0'}>
                                        <NewButton color={'primary' as ButtonColor}
                                                   className={'w-100'}
                                                   onClick={submitForm}
                                                   disabled={!isChanged}
                                        >Search</NewButton>
                                    </Col></>

                            }
                            {...rest}/>
                    </Form>
                )
            }}
        </Formik>


    );
}

export default AggregatedTimeSeriesQueryForm;