import React from "react";
import {
  ColumnDefinitionWithData,
  ColumnMap,
} from "../../../../model/deviceTimeSeries";
import DataQualityTimeSeriesAreaChart from "../../../../components/charts/DataQualityTimeSeriesAreaChart";
import { DeviceTimeSeriesDisplayComponentProps } from "../DeviceTimeSeriesDataContainer";

const calculateMissingRows = (
  expected: ColumnDefinitionWithData<number>,
  complete: ColumnDefinitionWithData<number>,
  incomplete: ColumnDefinitionWithData<number>,
  errors: ColumnDefinitionWithData<number>,
  valueErrors: ColumnDefinitionWithData<number>
): ColumnDefinitionWithData<number> => {
  const missing = expected.data.map((v, i) => {
    return (
      v -
      complete.data[i] -
      incomplete.data[i] -
      errors.data[i] -
      valueErrors.data[i]
    );
  });

  return {
    name: "Missing Rows",
    data: missing,
    errors: [],
    type: "",
    unit: "",
  };
};

const AssetDataQualityChart = (
  props: DeviceTimeSeriesDisplayComponentProps
): JSX.Element => {
  const mappedData =
    props.mappedTimeSeriesColumns as unknown as ColumnMap<number>;
  const blankColumnData = { type: "", name: "", unit: "", data: [] };
  const expectedRows: ColumnDefinitionWithData<number> =
    mappedData["expected_rows"] || blankColumnData;
  const rowsComplete: ColumnDefinitionWithData<number> =
    mappedData["received_rows_complete"] || blankColumnData;
  const rowsIncomplete: ColumnDefinitionWithData<number> =
    mappedData["received_rows_incomplete"] || blankColumnData;
  const rowsErrors: ColumnDefinitionWithData<number> =
    mappedData["received_rows_with_errors"] || blankColumnData;
  const valueErrors: ColumnDefinitionWithData<number> =
    mappedData["received_rows_with_value_errors"] || blankColumnData;

  const missing = calculateMissingRows(
    expectedRows,
    rowsComplete,
    rowsIncomplete,
    rowsErrors,
    valueErrors
  );

  return (
    <DataQualityTimeSeriesAreaChart
      missing={missing.data}
      expected={expectedRows.data}
      incomplete={rowsIncomplete.data}
      valueErrors={valueErrors.data}
      errors={rowsErrors.data}
      complete={rowsComplete.data}
      times={props.times || []}
      loading={props.loading}
      error={props.error}
      size={200}
    />
  );
};

export default AssetDataQualityChart;
