import React from "react";

import { usePrivacyMode } from "../../reducers/privacy";
import DataTable, { TableProps, TableColumn } from "react-data-table-component";

export interface PrivacyModeTableColumn<T> extends TableColumn<T> {
  privacyMode?: boolean;
}

export interface PrivacyModeTableProps<T> extends TableProps<T> {
  columns: PrivacyModeTableColumn<T>[];
}

function addPrivacyModeStyleToColumn<T>(
  col: PrivacyModeTableColumn<T>
): TableColumn<T> {
  if (col.privacyMode) {
    return {
      ...col,
      style: {
        ...col.style,
        color: "transparent",
        textShadow: "0 0 10px rgba(0,0,0,0.5)",
      },
    };
  }
  return col;
}

function PrivacyModeDataTable<T>(props: PrivacyModeTableProps<T>): JSX.Element {
  const privacyMode = usePrivacyMode();

  const { columns, ...rest } = props;

  return (
    <DataTable
      {...rest}
      columns={privacyMode ? columns.map(addPrivacyModeStyleToColumn) : columns}
    />
  );
}

export default PrivacyModeDataTable;
