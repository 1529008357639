import React from "react";
import useDeleteEntity, { DeleteEntityProps } from "../../hooks/deleteEntity";
import DisplayIfUserType from "../utils/DisplayIfUserType/DisplayIfUserType";
import { Button } from "reactstrap";
import classNames from "classnames";
import ErrorModal from "../Error/ErrorModal";

interface DeleteButtonProps {
  errorMessageBody?: JSX.Element;
}

export default function DeleteButton(
  props: DeleteEntityProps & DeleteButtonProps
): JSX.Element {
  const { deleting, deleteEntity, error } = useDeleteEntity(
    props.entityType,
    props.entityId,
    props.postDelete,
    props.path
  );

  return (
    <DisplayIfUserType userTypes={["admin"]}>
      <ErrorModal error={error} body={props.errorMessageBody} />
      <Button
        size="sm"
        outline={false}
        onClick={deleteEntity}
        disabled={deleting}
      >
        <i
          className={classNames({
            "fas fa-fw": true,
            "fa-trash-alt": !deleting,
            "fa-cog fa-spinner fa-spin": deleting,
          })}
        />
      </Button>
    </DisplayIfUserType>
  );
}
