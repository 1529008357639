import React from 'react';
import {WirelessSurveyDataCollectorOut} from "../../openapi/model/wirelessSurveyDataCollectorOut";
import {isNil} from "lodash";
import WirelessSurveySamplePointAddModal from "./WirelessSurveySamplePointAddModal";
import moment from "moment";
import useUpdateEntity from "../../hooks/updateEntity";
import {Button} from "reactstrap";

function WirelessSurveySamplePointTableRows(props: {
    dataCollector: WirelessSurveyDataCollectorOut,
    refresh?: () => any

}) {
    const {dataCollector} = props;

    const samplePoints = dataCollector.samplePoints.sort(
        (a, b) => (a.startDateTime > b.startDateTime ? 1 : -1)
    )

    const add = samplePoints?.length === 0 || isNil(samplePoints[samplePoints.length - 1].endDateTime);
    const hasData = samplePoints?.length > 0;

    const update = useUpdateEntity<WirelessSurveyDataCollectorOut>(
        "WIRELESS_SURVEY_DATA_COLLECTOR",
        props.dataCollector.dataCollectorId,
        () => {
            props.refresh && props.refresh()
        },
        `wireless_survey/${props.dataCollector.surveyId}/data_collector/${props.dataCollector.dataCollectorId}`
    )

    return <tbody key={dataCollector.dataCollectorId}>
    {samplePoints?.map((sp, i) => (
        <tr key={sp.samplePointName}>
            {i === 0 &&
                <td rowSpan={samplePoints.length + (add ? 1 : 1)}>{dataCollector.dataCollectorName}{" "}({dataCollector.serialNumber})</td>}
            <td>{sp.samplePointName}</td>
            <td>{moment(sp.startDateTime).format('DD-MM-YYYY HH:mm')}</td>
            <td>{sp.endDateTime ? moment(sp.endDateTime).format('DD-MM-YYYY HH:mm') : '-'}</td>
            <td className={'p-1'}>
                <Button
                    color={'danger'}
                    size={'sm'}
                    disabled={update.updating}
                    onClick={() => {
                        if (!update.updating) {
                            update.updateEntity({
                                    ...dataCollector,
                                    samplePoints: samplePoints.filter((_v, j) => j !== i)
                                }
                            ).then(() => {
                                props.refresh && props.refresh()
                            })
                        }
                    }}
                >
                    Delete
         </Button>

            </td>
        </tr>
    ))}
    <tr>
        {!hasData &&
            <td rowSpan={1}>{dataCollector.dataCollectorName}{" "}({dataCollector.serialNumber})</td>}
        <td>{" "}</td>
        <td>{" "}</td>
        <td>{" "}</td>
        <td className={'p-1'}><WirelessSurveySamplePointAddModal
            disabled={update.updating}
            dataCollector={dataCollector} refresh={props.refresh}
            update={update}
        /></td>
    </tr>
    </tbody>

}

export default WirelessSurveySamplePointTableRows;