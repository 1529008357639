import { AxiosResponseHeaders } from "axios";

export function getFilenameFromHeaders(headers: AxiosResponseHeaders): string {
  return headers["content-disposition"]
    .split(";")[1]
    .split("filename")[1]
    .split("=")[1]
    .trim()
    .replace(/"/g, "");
}
