import React from "react";
import { AssetPosition } from "../../../openapi/model/assetPosition";
import { Location } from "../../../openapi/model/location";
import { Col } from "reactstrap";
import AssetPositionDeleteButton from "./../AssetPositionDeleteButton";
import { Status } from "../../../openapi/model/status";

const AssetPositionDeleteTab = (props: {
  assetPosition: AssetPosition | Location;
  title: string;
  refresh?: () => any;
  delete: () => Promise<any>;
}): JSX.Element => {
  const deleted = props.assetPosition.status === Status.DELETED;
  return (
    <>
      <Col xs={12}>
        <h3>Delete {props.title}</h3>
      </Col>
      <Col xs={12}>
        <AssetPositionDeleteButton {...props} disabled={deleted} />
        {deleted && <p>{props.title} has already been deleted.</p>}
      </Col>
    </>
  );
};

export default AssetPositionDeleteTab;
