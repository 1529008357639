import React from "react";
import {useField} from "formik";
import {OnChangeValue} from "react-select";
import {isArray, isNull} from "lodash";
import {Option} from "./SelectComponent";
import {ValueList} from "./SelectCombo/SelectCombo";

export interface SelectComboFieldProps {
    name: string;
    children: (props: {
        name: string,
        onChange: (newValue: OnChangeValue<Option, boolean>) => void,
        value: ValueList,
        error?: string,
        touched?: boolean,
    }) => React.ReactNode;

    onChange?: (newValue: ValueList | null ) => Promise<any>;
}

export default function SelectComboField(props: SelectComboFieldProps): React.ReactElement {
    const [{value}, {error, touched}, {setValue, setTouched}] = useField(props.name);

    const onChangeFormikSet = (newValue: OnChangeValue<Option, boolean>): void => {
        setTouched(true);
        let newVal: ValueList | null = null;
        if (isArray(newValue)) {
            newVal = (newValue as Option[]).map((v) => v.value)
        } else if (!isNull(newValue)) {
            newVal = (newValue as Option).value
        } else if (isNull(newValue) && isArray(value)) {
            newVal = []
        }
        if (props.onChange) {
          props.onChange(newVal).then(()=> setValue(newVal))
        } else {
            setValue(newVal)
        }
    };

    return <>{props.children({name:props.name, onChange:onChangeFormikSet, value, error, touched} )}</>
}

