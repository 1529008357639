/**
 * Meterpoint.io
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type RelayState = 'ON' | 'OFF' | 'SWITCHING_ON' | 'SWITCHING_OFF' | 'SWITCHING_ERROR' | 'UNKNOWN';

export const RelayState = {
    ON: 'ON' as RelayState,
    OFF: 'OFF' as RelayState,
    SWITCHINGON: 'SWITCHING_ON' as RelayState,
    SWITCHINGOFF: 'SWITCHING_OFF' as RelayState,
    SWITCHINGERROR: 'SWITCHING_ERROR' as RelayState,
    UNKNOWN: 'UNKNOWN' as RelayState
};