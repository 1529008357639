import React from "react";
import { OneCardPage } from "../../components/utils/OneCardPage";
import SchemeTariffListWrapper from "./SchemeTariffListWrapper";
import SchemeTariffListDisplay from "./SchemeTariffListDisplay";

export default function SchemeTariffHomepage(): JSX.Element {
  return (
    <OneCardPage
      headerTitle={"Tariff"}
      breadcrumbs={[
        { url: "/admin/tariff", name: "Tariff" },
        { url: "/admin/tariff", name: "Home" },
      ]}
    >
      <SchemeTariffListWrapper>
        {({ data }) => <SchemeTariffListDisplay data={data} />}
      </SchemeTariffListWrapper>
    </OneCardPage>
  );
}
