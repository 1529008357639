import React from "react";
import { Button, Table, UncontrolledTooltip } from "reactstrap";
import classNames from "classnames";
import { Scheme } from "../../openapi/model/scheme";
import NumberOfAssetPositionsDisplayWidget from "../../components/Common/NumberOfAssetPositionsDisplayWidget";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import PrivacyMode from "../../components/Text/PrivacyMode";
import SchemeSelectWrapper, { SchemeSelectProps } from "./SchemeSelectWrapper";

const SchemesList = (): JSX.Element => {
  const header = (
    <tr>
      <th scope="col" className="text-left">
        ID
      </th>
      <th scope="col" className="text-left">
        Name
      </th>
      <th scope="col" className="text-left">
        Location
      </th>
      <th scope="col" className="text-left">
        No. of Asset Positions
      </th>
      <th scope="col" className="text-left">
        No. of Locations
      </th>
      <th scope="col" className="text-left">
        &nbsp;
      </th>
    </tr>
  );

  return (
    <SchemeSelectWrapper>
      {({ selected, schemes, select }: SchemeSelectProps) => {
        const row = (value: Scheme, index: number): JSX.Element => (
          <tr
            key={index}
            data-cy="schemes-list-item"
            className={classNames({
              "table-primary": selected && value.schemeId === selected.schemeId,
              list: true,
            })}
          >
            <td data-cy="scheme-name">
              <PrivacyMode>{value.schemeId}</PrivacyMode>
            </td>
            <td data-cy="scheme-name">
              <PrivacyMode>{value.schemeName}</PrivacyMode>
            </td>
            <td data-cy="scheme-location">
              <PrivacyMode>{value.location}</PrivacyMode>
            </td>
            <td data-cy="scheme-devices">
              <NumberOfAssetPositionsDisplayWidget
                positions={value.numberOfAssetPositions}
                index={index}
              />
            </td>
            <td data-cy="scheme-locations">{value.numberOfLocations}</td>
            <td className="table-actions">
              <a
                className="table-action table-action-delete"
                href={`#company=${value.companyId}&scheme=${value.schemeId}`}
                id={`tooltip-select-company-${index}`}
                onClick={(): void => {
                  select(value);
                }}
              >
                <i className="fas fa-hand-point-up" />
              </a>
              <UncontrolledTooltip
                delay={0}
                target={`tooltip-select-company-${index}`}
              >
                Select <PrivacyMode>{value.schemeName}</PrivacyMode>
              </UncontrolledTooltip>
            </td>
          </tr>
        );
        return (
          <>
            <Table
              className="align-items-center table-flush"
              data-cy={"scheme-list"}
              responsive
            >
              <thead>{header}</thead>
              {schemes.length > 0 ? (
                <tbody>{schemes.map(row)}</tbody>
              ) : (
                <tbody data-cy={"no-data"}>
                  <tr>
                    <td colSpan={3}>No schemes</td>
                  </tr>
                </tbody>
              )}
            </Table>

            {
              <DisplayIfUserType userTypes={"admin"}>
                <CompanyNavLink tag={Button} to={"/admin/schemes/create"}>
                  Create New
                </CompanyNavLink>
              </DisplayIfUserType>
            }
          </>
        );
      }}
    </SchemeSelectWrapper>
  );
};

export default SchemesList;
