import React from "react";

import "./PrivacyMode.scss";
import { usePrivacyMode } from "../../reducers/privacy";

const PrivacyMode = (props: { children?: string | string[] }): JSX.Element => {
  const privacyMode = usePrivacyMode();
  return (
    <span className={privacyMode ? "blurry-text" : ""}>{props.children}</span>
  );
};

export default PrivacyMode;
