import React, {useState} from "react";
import {Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {Alert, Button, Col, Row} from "reactstrap";
import {OutputType} from "../../openapi/model/outputType";
import {ExportTypes} from "../../openapi/model/exportTypes";
import FTPExportConfigurationSubForm, {
    FTPExportConfigurationSchema,
    FTPExportInitialValues,
} from "./ExportConfigurationForms/FTPExportConfiguration";
import {yupRequiredString} from "../../components/Forms/yupValidators";
import {FTPConfiguration} from "../../openapi/model/fTPConfiguration";
import {JobDefinitionIn} from "../../openapi/model/jobDefinitionIn";
import {JobDefinitionOut} from "../../openapi/model/jobDefinitionOut";
import X109ConfigurationSubForm, {
    X109ConfigurationInitialValues,
    X109ConfigurationSubFormSchema,
    X110ConfigurationInitialValues,
} from "./FormatConfigurationForms/X109ConfigurationForm";
import {JobObjectTypes} from "../../openapi/model/jobObjectTypes";
import BillingConfigurationSubForm, {
    BillingConfigurationInitialValues,
    BillingConfigurationSubFormSchema,
} from "./FormatConfigurationForms/BillingConfigurationForm";
import {ErrorBanner} from "../../components/Error/ErrorBanner";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import useCreateEntity from "../../hooks/createEntity";
import SchemeSelectFormComponent from "../../components/Scheme/SchemeSelectFormComponent";
import AllDataReportConfigurationSubForm, {
    AllDataReportConfigurationInitialValues,
    AllDataReportConfigurationSubFormSchema,
} from "./FormatConfigurationForms/AllDataReportConfigurationForm";
import {EmailConfiguration} from "../../openapi/model/emailConfiguration";
import {Schedule} from "../../openapi/model/schedule";
import FrequencySelect from "./Common/FrequencySelect";
import DaySelect from "./Common/DaySelect";
import {BillingReportConfiguration} from "../../openapi/model/billingReportConfiguration";
import {AllDataReportConfiguration} from "../../openapi/model/allDataReportConfiguration";
import {ElvacoX109ReportConfiguration} from "../../openapi/model/elvacoX109ReportConfiguration";
import {CumulativeDataConfiguration} from "../../openapi/model/cumulativeDataConfiguration";
import {BillingReportFormat} from "../../openapi/model/billingReportFormat";
import {ElvacoX109ReportFormat} from "../../openapi/model/elvacoX109ReportFormat";
import {AllDataReportFormat} from "../../openapi/model/allDataReportFormat";
import {LatestDataReportFormat} from "../../openapi/model/latestDataReportFormat";
import {CumulativeDataReportFormat} from "../../openapi/model/cumulativeDataReportFormat";
import TextInputField from "../../components/Forms/TextInputField";
import TextInput from "../../components/Forms/TextInput";
import SelectComboField from "../../components/Forms/SelectComboField";
import SelectCombo from "../../components/Forms/SelectCombo/SelectCombo";
import {TextFieldTextType} from "@psd-platform/monday-ui-react-core/dist/types/components/TextField/TextFieldConstants";
import {isNil, isString} from "lodash";

interface Props {
    companyId: string;
    schemeId?: string;
    assetPositionId?: string;
    locked?: boolean;
    existingJob?: JobDefinitionOut;
}

interface FormValues {
    name: string;
    formatType: OutputType;
    exportType?: ExportTypes | "None";
    objectType?: JobObjectTypes;
    objectId?: string;
    export?: FTPConfiguration | EmailConfiguration;
    format?: {
        configuration:
            | BillingReportConfiguration
            | AllDataReportConfiguration
            | ElvacoX109ReportConfiguration
            | CumulativeDataConfiguration;
        fileName?: string;
    };
    schemeId?: string;
    schedule?: Schedule;
}

export default function JobCreateForm(props: Props): JSX.Element {
    const {locked = false, existingJob} = {...props};

    const {createEntity} = useCreateEntity<JobDefinitionOut>("JOB");

    const [error, setError] = useState<Error | undefined>(undefined);
    const [createdJob, setCreatedJob] = useState<JobDefinitionOut | undefined>(
        undefined
    );

    const submit = (
        values: FormValues,
        {setSubmitting, resetForm}: FormikHelpers<FormValues>
    ): void => {
        setSubmitting(true);
        setError(undefined);
        setCreatedJob(undefined);
        const job = convertFormToDefinition(values);
        createEntity(job)
            .then(([data]) => {
                resetForm();
                setCreatedJob(data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const formValuesToObjectTypeAndId = (
        values: FormValues
    ): { objectType?: JobObjectTypes; objectId?: string } => {
        if (props.assetPositionId) {
            return {
                objectId: props.assetPositionId,
                objectType: "asset_position",
            };
        }
        if (values.schemeId && values.schemeId !== 'undefined') {
            return {
                objectId: values.schemeId,
                objectType: "scheme",
            };
        }
        return {
            objectId: undefined,
            objectType: undefined,
        };
    };

    const convertFormToDefinition = (values: FormValues): JobDefinitionIn => {
        return {
            name: values.name,
            ...formValuesToObjectTypeAndId(values),
            exportConfiguration:
                values.exportType !== "None" && values.export
                    ? {
                        exportType: values.exportType as ExportTypes,
                        configuration: values.export,
                    }
                    : undefined,
            reportFormat: {
                format: values.formatType,
                fileName: values.format?.fileName,
                configuration:
                    (values.format?.configuration as
                        | BillingReportConfiguration
                        | AllDataReportConfiguration
                        | ElvacoX109ReportConfiguration
                        | CumulativeDataConfiguration) ||
                    ({} as
                        | BillingReportConfiguration
                        | AllDataReportConfiguration
                        | ElvacoX109ReportConfiguration
                        | CumulativeDataConfiguration) /* hack to make typ check work */,
            } as
                | BillingReportFormat
                | ElvacoX109ReportFormat
                | AllDataReportFormat
                | LatestDataReportFormat
                | CumulativeDataReportFormat,
            companyId: props.companyId,
            schedule: values.schedule,
        };
    };

    const convertJobDefinitionToFormValues = (
        job: JobDefinitionOut
    ): FormValues => {
        const {format, ...formatConfig} = job.reportFormat;
        const schemeId = job.objectType === "scheme" ? job.objectId : "undefined";

        return {
            name: job.name,
            schemeId,
            formatType: format as OutputType,
            exportType: job.exportConfiguration?.exportType || "None",
            objectType: job.objectType,
            objectId: job.objectId !== "" ? job.objectId : undefined,
            export: job.exportConfiguration?.configuration,
            format: formatConfig as {
                fileName?: string;
                configuration:
                    | BillingReportConfiguration
                    | AllDataReportConfiguration
                    | ElvacoX109ReportConfiguration
                    | CumulativeDataConfiguration;
            },
            schedule: job.schedule,
        };
    };

    const initialValues: FormValues = existingJob
        ? convertJobDefinitionToFormValues(existingJob)
        : {
            name: "",
            schemeId: props.schemeId,
            formatType: props.assetPositionId
                ? OutputType.ELVACOX109
                : OutputType.CUMULATIVEDATA,
            exportType: "None",
            objectType: props.assetPositionId ? "asset_position" : undefined,
            objectId: props.assetPositionId ? props.assetPositionId : "",
            export: props.assetPositionId ? FTPExportInitialValues : undefined,
            format: props.assetPositionId
                ? X109ConfigurationInitialValues
                : BillingConfigurationInitialValues(),
            schedule: {frequency: 'DAILY'}
        };

    const defaultValuesForExport = (exportType: string) => {
        switch (exportType) {
            case "FTP":
                return FTPExportInitialValues;
            default:
                return {};
        }
    };

    const defaultValuesForReport = (reportType: string) => {
        switch (reportType) {
            case "ELVACO_X109":
                return X109ConfigurationInitialValues;
            case "ELVACO_X110":
                return X110ConfigurationInitialValues;
            case "CUMULATIVE_DATA":
                return BillingConfigurationInitialValues();
            case "ALL_DATA":
                return AllDataReportConfigurationInitialValues;
            default:
                return undefined;
        }
    };

    return (
        <>
            <Formik
                onSubmit={submit}
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    name: yupRequiredString,
                    export: Yup.object().when("exportType", (exportType: string) => {
                        switch (exportType) {
                            case "FTP":
                                return FTPExportConfigurationSchema;
                            case "None":
                                return Yup.object();
                            default:
                                throw new Error("exportType is not understood");
                        }
                    }),
                    format: Yup.object().when("formatType", (formatType: string) => {
                        switch (formatType) {
                            case "ELVACO_X109":
                                return X109ConfigurationSubFormSchema;
                            case "ELVACO_X110":
                                return X109ConfigurationSubFormSchema;
                            case "CUMULATIVE_DATA":
                                return BillingConfigurationSubFormSchema();
                            case "LATEST":
                                return Yup.object();
                            case "ALL_DATA":
                                return AllDataReportConfigurationSubFormSchema();
                            default:
                                throw new Error(`formatType ${formatType} is not understood`);
                        }
                    }),
                    formatType: yupRequiredString,
                    exportType: yupRequiredString,
                })}
                validateOnMount={true}
                enableReinitialize={true}
            >
                {({
                      values,
                      submitForm,
                      isValid,
                      isSubmitting,
                      setFieldValue,
                      validateForm,
                  }): JSX.Element => {
                    return (
                        <BlockSpinner loading={isSubmitting}>
                            <Row>
                                <Col xs={12}>
                                    <h4>Basic</h4>

                                    <TextInputField
                                        name={"name"}

                                    >
                                        {(fieldProps) => <TextInput
                                            {...fieldProps}
                                            label={{label: 'Report Name'}}
                                            disabled={locked}
                                        />}
                                    </TextInputField>

                                    {!props.assetPositionId && (
                                        <SelectComboField name={'schemeId'}>
                                            {(fieldProps) => <SchemeSelectFormComponent
                                                label={{label: "Scheme Filter"}}
                                                disabled={locked}
                                                {...fieldProps}
                                            />
                                            }
                                        </SelectComboField>
                                    )}
                                    <SelectComboField name={'schedule.frequency'}
                                                      onChange={async (value) => {
                                                          if (!isNil(value) && isString(value)) {
                                                              if (value === "WEEKLY") {
                                                                  await setFieldValue("schedule.dayOfWeek", "MONDAY");
                                                                  return await setFieldValue("schedule.dayOfMonth", undefined);

                                                              } else if (value === "MONTHLY") {
                                                                  await setFieldValue("schedule.dayOfWeek", undefined);
                                                                  return await setFieldValue("schedule.dayOfMonth", 2);
                                                              } else {
                                                                  await setFieldValue("schedule.dayOfWeek", undefined);
                                                                  return await setFieldValue("schedule.dayOfMonth", undefined);
                                                              }
                                                          }
                                                          throw new Error('Schedule value is not valid')
                                                      }}

                                    >
                                        {(fieldProps) =>
                                            <FrequencySelect
                                                {...fieldProps}
                                                disabled={locked}
                                                label={{label: 'Frequency'}}
                                                clearable={false}
                                            />
                                        }
                                    </SelectComboField>
                                    {values.schedule?.frequency === "WEEKLY" && (
                                        <SelectComboField name={'schedule.dayOfWeek'}>
                                            {(fieldProps) =>
                                                <DaySelect
                                                    {...fieldProps}
                                                    disabled={locked}
                                                    clearable={false}
                                                />
                                            }
                                        </SelectComboField>
                                    )}
                                    {values.schedule?.frequency === "MONTHLY" && (
                                        <TextInputField
                                            name={"schedule.dayOfMonth"}
                                        >
                                            {(fieldProps) => <TextInput
                                                {...fieldProps}
                                                label={{label: 'Scheduled Day of Month'}}
                                                disabled={locked}
                                                type={'number' as TextFieldTextType}
                                            />}
                                            {/* TODO: validation on min/max */}
                                        </TextInputField>
                                    )}
                                    <SelectComboField name={'formatType'}
                                                      onChange={
                                                          async (event) => {
                                                              if (isString(event)) {
                                                                  await setFieldValue(
                                                                      "format",
                                                                      defaultValuesForReport(
                                                                          event as unknown as string
                                                                      )
                                                                  );
                                                                  return await validateForm();
                                                              } else {
                                                                  throw new Error('formatType must be a single string')
                                                              }
                                                          }}
                                    >
                                        {(fieldProps) =>
                                            <SelectCombo
                                                {...fieldProps}
                                                label={{label: "Format"}}
                                                options={[
                                                    {label: "Billing", value: OutputType.CUMULATIVEDATA},
                                                    {label: "Latest Data", value: OutputType.LATEST},
                                                    {label: "Elvaco X109", value: OutputType.ELVACOX109},
                                                    {label: "Elvaco X110", value: OutputType.ELVACOX110},
                                                    {label: "All data", value: OutputType.ALLDATA},
                                                ]}
                                                disabled={locked}
                                                clearable={false}
                                            />
                                        }
                                    </SelectComboField>
                                    <SelectComboField
                                        name={'exportType'}

                                        onChange={async (event) => {
                                            if (!isNil(event) && isString(event)) {
                                                await setFieldValue(
                                                    "export",
                                                    defaultValuesForExport(event)
                                                )
                                                return await validateForm()
                                            }
                                            throw new Error('exportType is not understood')
                                        }}
                                    >
                                        {(fieldProps) => <SelectCombo
                                            {...fieldProps}
                                            label={{label: "Destination"}}
                                            options={[
                                                {label: "None", value: "None"},
                                                {label: "FTP", value: ExportTypes.FTP},
                                            ]}
                                            disabled={locked}
                                            clearable={false}
                                        />}
                                    </SelectComboField>
                                </Col>
                                <Col xs={12}>
                                    {values.exportType === "FTP" && (
                                        <FTPExportConfigurationSubForm namespace={'export'} disabled={locked}/>
                                    )}
                                </Col>
                                <Col xs={12}>
                                    {values.formatType === OutputType.ELVACOX109 && (
                                        <X109ConfigurationSubForm
                                            namespace={"format"}
                                            disabled={locked}
                                        />
                                    )}
                                    {values.formatType === OutputType.ELVACOX110 && (
                                        <X109ConfigurationSubForm
                                            namespace={"format"}
                                            disabled={locked}
                                        />
                                    )}
                                    {values.formatType === OutputType.CUMULATIVEDATA && (
                                        <BillingConfigurationSubForm
                                            namespace={"format.configuration"}
                                            disabled={locked}
                                        />
                                    )}
                                    {values.formatType === OutputType.ALLDATA && (
                                        <AllDataReportConfigurationSubForm
                                            namespace={"format"}
                                            disabled={locked}
                                        />
                                    )}
                                </Col>
                                {!locked && (
                                    <Col xs={12}>
                                        <div className={"form-group row"}>
                                            <Button
                                                type={"submit"}
                                                color={"primary"}
                                                onClick={submitForm}
                                                disabled={isSubmitting || !isValid || locked}
                                            >
                                                Add Job
                                            </Button>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </BlockSpinner>
                    );
                }}
            </Formik>
            <ErrorBanner error={error}/>
            {createdJob && (
                <Alert color={"success"} className={"my-2"}>
                    <h4>
                        <FontAwesomeIcon icon={faCheck} className={"mx-1"}/>
                        Job ...{createdJob.jobId.slice(-8)} was successfully created.
                    </h4>
                    <p>
                        <CompanyNavLink
                            to={`/admin/job/${createdJob.jobId}`}
                            className={"px-1 text-white"}
                        >
                            View job.
                        </CompanyNavLink>
                    </p>
                </Alert>
            )}
        </>
    );
}
