import React from "react";
import { DeviceAsset } from "../../../openapi/model/deviceAsset";
import GenericCharts from "./GenericCharts";
import HeatMeterCharts from "./heatMeters/HeatMeterCharts";
import DeviceTimeSeriesDataContainer, {
  DeviceTimeSeriesDisplayComponentProps,
} from "./DeviceTimeSeriesDataContainer";
import ElectricityMeterCharts from "./electricityMeters/ElectricityMeterCharts";
import { GatewayAsset } from "../../../openapi/model/gatewayAsset";
import AssetDataQualityContainer from "./dataQuality/AssetDataQualityContainer";
import { Col, Row } from "reactstrap";
import AssetDataSelectorUrlQuerySetter from "../AssetDataSelectorUrlQuerySetter";
import AssetDataSearchStringParser from "./AssetDataSearchStringParser";
import DeviceTimeSeriesDataCsvExportButton from "./DeviceTimeSeriesDataCsvExportButton";

const MeterTimeSeriesCharts = (props: {
  device: DeviceAsset | GatewayAsset;
}): JSX.Element => {
  let child = GenericCharts;

  switch (props.device.assetType) {
    case "heat_meter":
      child = HeatMeterCharts;
      break;
    case "cooling_meter":
      child = HeatMeterCharts;
      break;
    case "heating_and_cooling_meter":
      child = HeatMeterCharts;
      break;
    case "electricity_meter":
      child = ElectricityMeterCharts;
      break;
  }

  const render = (
    innerProps: DeviceTimeSeriesDisplayComponentProps
  ): JSX.Element => {
    return (
      <>
        <Col xs={12}>
          <span className={"float-right"}>
            <DeviceTimeSeriesDataCsvExportButton {...innerProps} />
          </span>
        </Col>
        {props.device.assetType !== "gateway" &&
          props.device.assetType !== "unknown" && (
            <>
              <AssetDataQualityContainer device={props.device} />
            </>
          )}
        {child(innerProps)}
      </>
    );
  };

  return (
    <>
      <AssetDataSearchStringParser device={props.device}>
        {({ searchParams }) => {
          return (
            <Row>
              <Col xs={12}>
                <AssetDataSelectorUrlQuerySetter
                  device={props.device}
                  {...searchParams}
                />
              </Col>
            </Row>
          );
        }}
      </AssetDataSearchStringParser>

      <DeviceTimeSeriesDataContainer device={props.device} render={render} />
    </>
  );
};

export default MeterTimeSeriesCharts;
