import React from 'react';
import {OneCardPage} from "../../components/utils/OneCardPage";
import WirelessSurveyCreateForm from "./WirelessSurveyCreateForm";
import {Col, Row} from "reactstrap";
import useCreateEntity from "../../hooks/createEntity";
import {WirelessSurveyOut} from "../../openapi/model/wirelessSurveyOut";
import {WirelessSurveyIn} from "../../openapi/model/wirelessSurveyIn";
import {useNavigate} from "react-router-dom";

function WirelessSurveyCreatePage() {

  const createEntity = useCreateEntity<WirelessSurveyOut, WirelessSurveyIn>("WIRELESS_SURVEY");

  const navigate = useNavigate();

  return (
    <OneCardPage
      headerTitle={"Create Wireless Survey"}
      breadcrumbs={[
        {
          url: "/admin/wireless_survey",
          name: "Wireless Surveys",
        },
        {
          url: "/admin/wireless_survey/create",
          name: "Create",
        },
      ]}
    >
        <Row>
            <Col xs={12}>
                <h3>Create New Wireless Survey</h3>
            </Col>
            <Col xs={12}>
        <WirelessSurveyCreateForm
            createWirelessSurvey={createEntity}
            onCreate={(surveyId) => navigate({pathname:'/admin/wireless_survey/view/'+surveyId})}
        />

            </Col>

        </Row>
    </OneCardPage>
  );
}

export default WirelessSurveyCreatePage;