import React from "react";
import { useLocation } from "react-router-dom";
import { SchemeContext } from "../../reducers/scheme";
import { selectPropertyFromLocationHash } from "../../containers/login/Login";

const SchemeSelectInit = (props: {
  children: React.ReactNode;
}): JSX.Element => {
  const location = useLocation();
  const [init, setInit] = React.useState(false);

  const { dispatch } = React.useContext(SchemeContext);

  React.useEffect(() => {
    const schemeId = selectPropertyFromLocationHash("scheme", location);
    if (schemeId) {
      dispatch({ type: "SELECT_SCHEME_BY_ID", payload: schemeId });
    }
    setInit(true);
    // only want this to run first time it renders - detect inbound scheme selection
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return <>{init && props.children}</>;
};

export default SchemeSelectInit;
