import React, {useState} from 'react';
import TabGroupInterface, {Tab} from "./TabGroupInterface";

interface Props {
    tabs: (Tab | undefined)[];

}
function StateTabGroupInterface(props: Props) {
  const { tabs} = props;
  const actualTabs =        tabs.filter((t) => {
          return t !== undefined;
        }) as Tab[]

  const [liveTabName, setLiveTabName] = useState<string>(
      actualTabs[0].tabPath

  )

  const liveTab: Tab = actualTabs.filter((tab: Tab) => tab?.tabPath === liveTabName)[0];

  const clickTab = (tab: Tab) => {
      setLiveTabName(tab.tabPath)
  }

    return (
    <TabGroupInterface
      tabs={actualTabs}
      liveTab={liveTab}
      switchTab={clickTab}
    />
    );
}

export default StateTabGroupInterface;