import React, { useState } from "react";
import { DeviceAsset } from "../../../openapi/model/deviceAsset";
import { AssetPosition } from "../../../openapi/model/assetPosition";
import { AssetType } from "../../../openapi/model/assetType";
import { Form, Formik, FormikHelpers } from "formik";
import {
  yupRequiredString,
  yupString,
} from "../../../components/Forms/yupValidators";
import * as Yup from "yup";
import AssetTypeSelect from "../AssetTypeSelect";
import FormComponent from "../../../components/Forms/FormComponent";
import { Alert, Button } from "reactstrap";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import { ErrorBanner } from "../../../components/Error/ErrorBanner";
import useCreateEntity from "../../../hooks/createEntity";
import { VirtualAssetDefinition } from "../../../openapi/model/virtualAssetDefinition";
import {useSelectedCompany} from "../../../reducers/company";

interface FormValues {
  assetType?: AssetType;
  customerReference?: string;
  sourceField?: string;
}

interface PProps {
  parentAsset: DeviceAsset;
  parentAssetPosition: AssetPosition;
}

const VirtualAssetCreateForm = (props: PProps): JSX.Element => {
  const { parentAssetPosition } = props;
  const company = useSelectedCompany();

  const [error, setError] = useState<Error | undefined>(undefined);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);

  const { createEntity } = useCreateEntity<VirtualAssetDefinition>(
    "ASSET",
    `asset_register/${parentAssetPosition.assetPositionId}/virtual`
  );

  const initialValue: FormValues = {
    customerReference: "",
    sourceField: "",
  };

  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ): void => {
    setSubmitting(true);
    setSuccess(undefined);
    createEntity({
      assetType: values.assetType,
      assetPositionCustomerReference: values.customerReference,
      assetConfiguration: { virtual: { field: values.sourceField } },
      companyId: company?.companyId
    })
      .then(() => {
        setSuccess(true);
        resetForm();
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      onSubmit={submit}
      initialValues={initialValue}
      validationSchema={Yup.object().shape({
        assetType: yupRequiredString,
        customerReference: yupString,
      })}
      validateOnMount={true}
    >
      {({ isValid, isSubmitting }): JSX.Element => (
        <Form data-cy={"asset-position-create"}>
          <BlockSpinner loading={isSubmitting}>
            <div className={"row my-2"}>
              <div className={"col-3"}>
                <h3 className="text-white mb-0">Add Virtual Asset</h3>
              </div>

              <AssetTypeSelect fieldName={"assetType"} includeSelect={true} />
              <FormComponent
                fieldName={"customerReference"}
                label={"Customer Reference"}
              />
              <FormComponent
                fieldName={"sourceField"}
                label={"Source Field"}
              />
              <div className={"col-12"}>
                <div className={"form-group row"}>
                  <div className={"col-12"}>
                    <Button
                      type={"submit"}
                      color={"primary"}
                      disabled={isSubmitting || !isValid}
                    >
                      Add Virtual Asset
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {error && <ErrorBanner error={error} />}
            {success && (
              <Alert color={"success"}>
                Virtual Asset Successfully Created.
              </Alert>
            )}
          </BlockSpinner>
        </Form>
      )}
    </Formik>
  );
};

export default VirtualAssetCreateForm;
