/**
 * Meterpoint.io
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type UserType = 'superadmin' | 'user' | 'api';

export const UserType = {
    Superadmin: 'superadmin' as UserType,
    User: 'user' as UserType,
    Api: 'api' as UserType
};