import React, {useState} from 'react';
import {Tooltip} from "reactstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck, faExclamationCircle, faSpinner} from "@fortawesome/free-solid-svg-icons";
export interface SubmitStateSpinnerProps {
    submitState?: 'submitting' | 'error' | 'success';
    submitError?: Error;
}


const SubmitStateSpinner = (props: SubmitStateSpinnerProps): JSX.Element => {

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    const getTooltipText = (): string | undefined => {
        switch (props.submitState) {
            case "submitting":
                return "Update in progress"
            case "success":
                return "Updated successfully"
            case "error":
                return props.submitError ? `Error updating: ${props.submitError.message}` : `Unknown error updating.`
            default:
                return undefined;
        }
    }

    const getIcon = (): JSX.Element => {

        switch (props.submitState) {
            case 'submitting':
                return <FontAwesomeIcon icon={faSpinner} spin={true} fixedWidth={true}/>
            case 'success':
                return <FontAwesomeIcon icon={faCheck} fixedWidth={true} className={'text-success'}/>
            case "error":
                return <FontAwesomeIcon icon={faExclamationCircle} fixedWidth={true} className={'text-danger'}/>
            default:
                return <i className={'fa px-2 fa-fw'}/>
        }
    }

    const tooltipText = getTooltipText();
    return <><a href={'#'} id="SubmitStateSpinner"><span className={'px-2'}>{getIcon()}</span></a>
        {tooltipText &&
        <Tooltip placement="top" target="SubmitStateSpinner" isOpen={tooltipOpen} toggle={toggle}>
            {tooltipText}
        </Tooltip>}</>

}


export default SubmitStateSpinner;


