import React from "react";
import {yupRequiredString} from "../../../components/Forms/yupValidators";
import * as Yup from "yup";
import {SubFormProps,} from "../../../components/Forms/WithSubForm";
import {TimeFilter} from "../../../openapi/model/timeFilter";
import {ElvacoX109ReportConfiguration} from "../../../openapi/model/elvacoX109ReportConfiguration";
import {Col, Row} from "reactstrap";
import TextInputField from "../../../components/Forms/TextInputField";
import TextInput from "../../../components/Forms/TextInput";
import SelectComboField from "../../../components/Forms/SelectComboField";
import TimeFilterSelect from "../../../components/TimeSeries/TimeFilterSelect";

export interface X109ConfigurationProps {
    fileName: string;
    configuration: ElvacoX109ReportConfiguration;
}

export const X109ConfigurationSubFormSchema = Yup.object().shape({
    fileName: yupRequiredString,
});

export const X109ConfigurationInitialValues: X109ConfigurationProps = {
    fileName: "{gatewaySerialNumber}_{meterSerialNumber}_{date}_2109.csv",
    configuration: {
        timeFilter: TimeFilter.ALL,
    },
};

export const X110ConfigurationInitialValues: X109ConfigurationProps = {
    fileName: "{gatewaySerialNumber}_{date}_2110.csv",
    configuration: {
        timeFilter: TimeFilter.ALL,
    },
};


const X109ConfigurationForm = ({
                                   namespace,
    disabled
                               }: SubFormProps): JSX.Element => {

    const withNamespace = (fieldName: string): string => {
        return namespace ? `${namespace}.${fieldName}` : fieldName
    }

    return (
        <>
            <Row>
                <Col xs={12}>
                    <h4>X109 Report Configuration</h4>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <TextInputField
                        name={withNamespace("fileName")}
                        >
                        {(fieldProps) => <TextInput
                            {...fieldProps}
                            label={{label:'Filename'}}
                            placeholder={"Keywords for filename are: gatewaySerialNumber, meterSerialNumber and date"}
                            disabled={disabled}
                        />}
                    </TextInputField>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <SelectComboField
                        name={withNamespace("configuration.timeFilter")}
                        >
                        {(fieldProps) => <TimeFilterSelect
                            {...fieldProps}
                            label={{label:'Time Filter'}}
                            disabled={disabled}
                        />}
                    </SelectComboField>
                </Col>
            </Row>
        </>
    );
};


export default X109ConfigurationForm;
