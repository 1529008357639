import CreateButton from "components/Buttons/CreateButton";
import React from "react";
import { saveAs } from "file-saver";
import { getFilenameFromHeaders } from "../../components/utils/FileDownloads";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";

export default function AssetRegisterToAsseTagButton(props: {
  companyId: string;
  schemeId: string;
}): JSX.Element {
  const { companyId, schemeId } = props;
  return (
    <DisplayIfUserType userTypes={"superadmin"}>
      <CreateButton
        entityType={"ASSET_REGISTER"}
        entity={{ companyId, schemeId }}
        path={"asset_register/labels"}
        text={"Create Asset Tags"}
        size={"md"}
        color={"primary"}
        icon={"fa-tag"}
        parseToJson={false}
        postCreate={(response, headers) => {
          console.log(response);
          saveAs(response, getFilenameFromHeaders(headers));
        }}
      />
    </DisplayIfUserType>
  );
}
