import React, {useState} from "react";
import * as Yup from "yup";
import {SubFormProps,} from "../../../components/Forms/WithSubForm";
import {
    AllDataReportConfiguration as AllDataReportConfigurationModel
} from "../../../openapi/model/allDataReportConfiguration";
import {Col, Collapse, Row, Table} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusCircle, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {TimeFilter} from "../../../openapi/model/timeFilter";
import {SpreadsheetFileType} from "../../../openapi/model/spreadsheetFileType";
import {StandardSpreadsheetOptions} from "../../../openapi/model/standardSpreadsheetOptions";
import {LabellingCases} from "../../../openapi/model/labellingCases";
import OutputFileTypeSelect from "../Common/OutputFileTypeSelect";
import {AssetType} from "../../../openapi/model/assetType";
import _ from "lodash";
import HeaderLabelCaseSelect from "../Common/HeaderLabelCaseSelect";
import {SingleColumnContentDefinition} from "../../../openapi/model/singleColumnContentDefinition";
import {EquationContentDefinition} from "../../../openapi/model/equationContentDefinition";
import {StaticContentDefinition} from "../../../openapi/model/staticContentDefinition";
import {useField} from "formik";
import TextInputField from "../../../components/Forms/TextInputField";
import TextInput from "../../../components/Forms/TextInput";
import NewLabelWrapper from "../../../components/Forms/NewLabelWrapper";
import SelectComboField from "../../../components/Forms/SelectComboField";
import SelectCombo from "../../../components/Forms/SelectCombo/SelectCombo";
import TimeFilterSelect from "../../../components/TimeSeries/TimeFilterSelect";
import NewCheckboxComponent from "../../../components/Forms/NewCheckboxComponent";
import NewCheckboxField from "../../../components/Forms/NewCheckboxField";

export interface AllDataReportConfigurationProps {
    fileName?: string;
    configuration: AllDataReportConfigurationModel;
}

export const AllDataReportConfigurationSubFormSchema = () => {
    return Yup.object().shape({
        configuration: Yup.object().shape({
            includedAssetTypes: Yup.array().required().min(1),
        }),
    });
};

export const AllDataReportConfigurationInitialValues: AllDataReportConfigurationProps =
    {
        fileName: "all_data_{company_id}_{scheme_id}_{date}.csv",
        configuration: {
            timeFilter: TimeFilter.ALL,
            fileType: SpreadsheetFileType.CSV,
            includedAssetTypes: [
                AssetType.HeatMeter,
                AssetType.CoolingMeter,
                AssetType.HeatingAndCoolingMeter,
                AssetType.ElectricityMeter,
                AssetType.GasMeter,
                AssetType.WaterMeter,
                AssetType.RoomSensor,
                AssetType.Co2RoomSensor,
            ],
            output: {
                outputType: "standard",
                headerLabels: LabellingCases.TITLECASE,
                includeUnits: false,
            } as StandardSpreadsheetOptions,
        },
    };

function customColumnContent(
    row:
        | SingleColumnContentDefinition
        | EquationContentDefinition
        | StaticContentDefinition
): JSX.Element {
    switch (row.outputType) {
        case "static":
            return <>{row.value}</>;
        case "equation":
            return <>{row.equation}</>;
        case "column":
            return (
                <>
                    {row.column}
                    {row.difference && " (difference)"}
                </>
            );
        default:
            return <></>;
    }
}

const AllDataReportConfigurationForm = ({
                                            namespace,
                                            disabled,
                                        }: SubFormProps): JSX.Element => {
    const withNamespace = (fieldName: string): string => {
        return namespace ? `${namespace}.${fieldName}` : fieldName
    }

    const [{value}] = useField(namespace);
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);
    const custom = value.configuration.output.outputType === "custom";
    return (
        <>
            <Row>
                <Col xs={12}>
                    <TextInputField
                        name={withNamespace("fileName")}
                    >
                        {(fieldProps) => <TextInput
                            {...fieldProps}
                            label={{label: 'Filename'}}
                            placeholder={"Keywords for filename are: company_id, scheme_id and date"}
                            disabled={disabled}

                        />}
                    </TextInputField>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h4>
                        Advanced Configuration
                        <FontAwesomeIcon
                            icon={isOpen ? faMinusCircle : faPlusCircle}
                            onClick={toggle}
                            className={"mx-1"}
                        />
                    </h4>
                </Col>
            </Row>
            <Collapse isOpen={isOpen}>
                {custom ? (
                    <>
                        <NewLabelWrapper
                            label={"This report has a custom output"}
                        >
                            <Table size={"sm"}>
                                <thead>
                                <tr>
                                    <th>Header</th>
                                    <th>Type</th>
                                    <th>Content</th>
                                    <th>Factor</th>
                                    <th>Rounding</th>
                                    <th>Format</th>
                                </tr>
                                </thead>
                                <tbody>
                                {value.configuration.output.columns.map(
                                    (v: any, i: number) => {
                                        return (
                                            <tr key={i}>
                                                <td>{v.headerLabel}</td>
                                                <td>{v.outputType}</td>
                                                <td>{customColumnContent(v)}</td>
                                                <td>{v.factor || "-"}</td>
                                                <td>{v.rounding || "-"}</td>
                                                <td>{v.format || "-"}</td>
                                            </tr>
                                        );
                                    }
                                )}
                                </tbody>
                            </Table>
                        </NewLabelWrapper>
                    </>
                ) : (
                    <>
                        <Row>
                            <Col xs={12}>
                                <SelectComboField
                                    name={withNamespace("configuration.output.headerLabels")}
                                >
                                    {(theFieldProps) => <HeaderLabelCaseSelect
                                        label={{label: "Headers Case"}}
                                        {...theFieldProps}
                                        disabled={disabled}

                                    />}
                                </SelectComboField>

                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <SelectComboField
                                    name={withNamespace("configuration.output.includeUnits")}
                                >
                                    {(theFieldProps) => <SelectCombo
                                        label={{label: "Headers Case"}}
                                        {...theFieldProps}
                                        options={[
                                            {label: "Yes", value: true},
                                            {label: "No", value: false},
                                        ]}
                                        disabled={disabled}

                                    />}
                                </SelectComboField>
                            </Col>
                        </Row>
                    </>
                )}
                <Row>
                    <Col xs={12}>
                        <SelectComboField
                            name={withNamespace("configuration.timeFilter")}
                        >
                            {(theFieldProps) => <TimeFilterSelect
                                label={{label: "Time Filter"}}
                                {...theFieldProps}
                                disabled={disabled}

                            />}
                        </SelectComboField>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <SelectComboField
                            name={withNamespace("configuration.fileType")}
                        >
                            {(theFieldProps) =>
                                <OutputFileTypeSelect
                                    label={{label: "File Type"}}
                                    {...theFieldProps}
                                    disabled={disabled}

                                />}
                        </SelectComboField>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <NewCheckboxField name={withNamespace("configuration.includedAssetTypes")}>
                            {(fieldProps) =>
                                <NewCheckboxComponent
                                    label={{label: "Asset Type Filter"}}
                                    options={Object.values([
                                        AssetType.HeatMeter,
                                        AssetType.HeatingAndCoolingMeter,
                                        AssetType.ElectricityMeter,
                                        AssetType.GasMeter,
                                        AssetType.WaterMeter,
                                        AssetType.CoolingMeter,
                                        AssetType.RoomSensor,
                                        AssetType.Co2RoomSensor,
                                        AssetType.Gateway,
                                    ]).map((key) => {
                                        return {label: _.upperFirst(_.lowerCase(key)), value: key};
                                    })}
                                    disabled={disabled}
                                    {...fieldProps}
                                />
                            }
                        </NewCheckboxField>

                    </Col>
                </Row>
            </Collapse>
        </>
    );
};


export default AllDataReportConfigurationForm;
