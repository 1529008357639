import React from "react";
import { AxiosError } from "axios";
import { Alert } from "reactstrap";
import { TypedError } from "../../model/error";
import { errorToTitleAndText } from "./errorMessages";

export const ErrorBanner = (props: {
  error?: Error | AxiosError | TypedError;
}): JSX.Element => {
  const { error } = props;

  if (error) {
    const { text, title } = errorToTitleAndText(error);
    return (
      <Alert color={"danger"} className={"my-2"}>
        <h3>Error</h3>
        {title && <b>{title}:</b>}
        {text}
      </Alert>
    );
  } else {
    return <></>;
  }
};
