import React, { useContext, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import _ from "lodash";
import { CompanyAction, CompanyContext } from "reducers/company";
import { Company } from "openapi/model/company";
import { SchemeAction, SchemeContext } from "reducers/scheme";
import { isUserPartnerAdmin, isUserSuperAdmin } from "../../model/user";
import PrivacyMode from "components/Text/PrivacyMode";
import { useProfile } from "../../reducers/profile";

export function selectCompany(
  companyDispatch: React.Dispatch<CompanyAction>,
  schemeDispatch: React.Dispatch<SchemeAction>,
  company?: Company
) {
  schemeDispatch({ type: "LOADING_SCHEMES_SUCCESS", payload: undefined });
  companyDispatch({ type: "SELECT_COMPANY", payload: company });
}

export default function CompanySelect(): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);
  const { state, dispatch } = useContext(CompanyContext);
  const schemeContext = useContext(SchemeContext);
  const { companies = [], selected } = state;
  const user = useProfile();
  return (
    <>
      {(companies.length > 1 ||
        isUserSuperAdmin(user) ||
        isUserPartnerAdmin(user)) && (
        <Dropdown
          isOpen={open}
          toggle={() => {
            setOpen((prevState) => !prevState);
          }}
        >
          <DropdownToggle
            caret
            size={"sm"}
            className={"px-2 py-2"}
            data-cy={"company-select-toggle"}
          >
            Company:{" "}
            <span
              className={"d-none d-sm-inline text-grey"}
              data-cy={"company-select-selected"}
            >
              {selected ? <PrivacyMode>{selected.name}</PrivacyMode> : "None"}
            </span>
          </DropdownToggle>
          <DropdownMenu right={true} data-cy={"company-select-menu"}>
            <DisplayIfUserType userTypes={"superadmin"}>
              <DropdownItem
                key={"NONE"}
                onClick={(): void => {
                  selectCompany(dispatch, schemeContext.dispatch, undefined);
                }}
              >
                NONE
              </DropdownItem>
            </DisplayIfUserType>
            {companies
              .sort((c1, c2) => {
                if (_.lowerCase(c1.name) > _.lowerCase(c2.name)) {
                  return 1;
                }
                if (_.lowerCase(c1.name) < _.lowerCase(c2.name)) {
                  return -1;
                }
                return 0;
              })
              .map((company, index): JSX.Element => {
                return (
                  <DropdownItem
                    data-cy={"company-select-item"}
                    key={index}
                    onClick={(): void => {
                      selectCompany(dispatch, schemeContext.dispatch, company);
                    }}
                    active={company.companyId === selected?.companyId}
                  >
                    <PrivacyMode>{company.name}</PrivacyMode>
                  </DropdownItem>
                );
              })}
          </DropdownMenu>
        </Dropdown>
      )}{" "}
    </>
  );
}
