import * as React from 'react';
import SelectedScheme from "../../components/Scheme/SelectedScheme";
import {OneCardPage} from "../../components/utils/OneCardPage";
import RequireScheme from "../../components/Scheme/RequireScheme";
import {IntegrationCreateForm} from "./IntegrationCreateForm";


export function IntegrationCreatePage() {
  return (
    <SelectedScheme>
      {(schemeProps) => (
        <OneCardPage
          headerTitle={
            schemeProps.schemeId
              ? `Add an Integration to ${schemeProps.schemeId}`
              : "Add an Integration"
          }
      breadcrumbs={[
        {
          url: "/admin/integrations",
          name: "Integrations",
        },
            {
              url: "/admin/integrations/create",
              name: "Create",
            },
          ]}
        >
          <RequireScheme>
            <IntegrationCreateForm
              schemeId={schemeProps.schemeId as string}
              companyId={schemeProps.companyId}
            />
          </RequireScheme>
        </OneCardPage>
      )}
    </SelectedScheme>
  );
}