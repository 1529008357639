import React from "react";
import { WirelessRFDataApiWrapperProps } from "./WirelessRFDataApiWrapper";
import AssetRegisterReportTable from "../report/allPeriodReportTable/AssetRegisterReportTable";
import { TableColumn } from "react-data-table-component";
import { AssetRegisterDataItem } from "../../components/Table/AssetRegisterData";
import moment from "moment";
import { GroupedAssetRegisterItemAggregatedTimeSeries } from "../../openapi/model/groupedAssetRegisterItemAggregatedTimeSeries";
import { AssetPosition } from "../../openapi/model/assetPosition";
import ReceiverAssignmentTableCell, {
  getReceiverAssignmentTableValue,
} from "./ReceiverAssignmentTableCell";
import { AssetPositionCacheProvider } from "../../reducers/assetPositionCachingReducer";
import CreateActionButton from "../assetActions/CreateActionButton";
import DisplayIfUserType from "components/utils/DisplayIfUserType/DisplayIfUserType";
import { AssetRegisterDataItemBase } from "../../openapi/model/assetRegisterDataItemBase";

function convertGroupedAssetRegisterItemAggregatedTimeSeries(
  data: GroupedAssetRegisterItemAggregatedTimeSeries,
  field: string,
  aggregation: string,
): { [key: string]: Array<number> } {
  return Object.fromEntries(
    Object.keys(data.aggregations).map((k) => {
      return [k, data.aggregations[k][field][aggregation]];
    }),
  );
}

function makeConvertGroupedAssetRegisterItemAggregatedTimeSeriesForAssetRegisterReportTableFunction(
  field: string,
  aggregation: string,
) {
  return function convertGroupedAssetRegisterItemAggregatedTimeSeriesForAssetRegisterReportTable(
    data: GroupedAssetRegisterItemAggregatedTimeSeries,
  ): AssetRegisterDataItem {
    return {
      assetId: data.assetId,
      serialNumber: data.serialNumber,
      assetType: data.assetType,
      assetPositionId: data.assetPositionId,
      assetPositionReference: data.assetPositionReference,
      locationId: data.locationId,
      locationAddress: data.locationAddress,
      installDate: data.installDate,
      uninstallDate: data.uninstallDate,
      ...convertGroupedAssetRegisterItemAggregatedTimeSeries(
        data,
        field,
        aggregation,
      ),
    };
  };
}

export default function ReceiverAssignmentTable(
  props: WirelessRFDataApiWrapperProps & { wirelessCluster: AssetPosition },
): JSX.Element {
  const { data } = props;

  const columns = (): TableColumn<AssetRegisterDataItem>[] => {
    if (data) {
      return data.receivers?.map(
        (receiver: AssetRegisterDataItemBase, index: number) => {
          return {
            name: (
              <span
                style={{
                  textAlign: "right",
                  whiteSpace: "normal",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {receiver.serialNumber}
              </span>
            ),
            selector: getReceiverAssignmentTableValue(receiver),
            cell: ReceiverAssignmentTableCell(receiver, index === 0),
            sortable: true,
            right: true,
            minWidth: "150px",
          };
        },
      );
    }
    return [];
  };

  const mappedData = data?.data.map(
    makeConvertGroupedAssetRegisterItemAggregatedTimeSeriesForAssetRegisterReportTableFunction(
      "rssi",
      "mean",
    ),
  );

  return (
    <>
      {data && (
        <AssetPositionCacheProvider>
          <AssetRegisterReportTable
            startDatetime={moment()}
            endDatetime={moment()}
            columnDefinitions={columns()}
            data={mappedData}
            extraActions={
              <DisplayIfUserType userTypes={"admin"}>
                <CreateActionButton
                  assetPosition={props.wirelessCluster}
                  actionType={"ASSIGN_ALL_ASSETS_TO_ALL_WIRELESS_RECEIVERS"}
                  actionName={"Assign All Assets to All Receivers"}
                />
                <CreateActionButton
                  assetPosition={props.wirelessCluster}
                  actionType={"AUTO_ASSIGN_ASSETS_TO_WIRELESS_RECEIVERS"}
                  actionName={"Optimise Assets to Receivers"}
                />
                <CreateActionButton
                  assetPosition={props.wirelessCluster}
                  actionType={
                    "AUTO_ASSIGN_ASSETS_TO_WIRELESS_RECEIVERS_AND_SYNC"
                  }
                  actionName={
                    "Optimise Assets to Receivers And Sync Device Lists"
                  }
                />
              </DisplayIfUserType>
            }
          />
        </AssetPositionCacheProvider>
      )}
    </>
  );
}
