import React from 'react';
import ReportExecutionList from "./ReportExecutionList";
import GetList, {PaginatedListResultProps} from "../../../core/action/GetList";
import {ReportExecutionOut} from "../../../openapi/model/reportExecutionOut";
import {useSelectedCompany} from "../../../reducers/company";
import {useSelectedScheme} from "../../../reducers/scheme";

interface ReportExecutionListContainerProps {
    jobId?: string
}

function ReportExecutionListContainer(props: ReportExecutionListContainerProps) {
    const company = useSelectedCompany();
    const scheme = useSelectedScheme();

    return (
        <GetList
            entityType={"REPORT_EXECUTION"}
            params={{
                companyId: company?.companyId,
                schemeId: scheme?.schemeId,
                jobId: props.jobId,
            }}
            cursor={{maxItems: 25}}
        >
            {(data: PaginatedListResultProps<ReportExecutionOut>) => (
                <>{data ? <ReportExecutionList
                    list={data}
                    showPagination={true}
                /> : <></>}</>
            )}


        </GetList>

    );
}

export default ReportExecutionListContainer;