import React, { useContext, useEffect } from "react";
import { SchemeContext, useSelectedScheme } from "../../reducers/scheme";
import useGetList from "../../hooks/getList";
import { LocationGroup } from "../../openapi/model/locationGroup";
import BlockSpinner from "../../components/Spinners/BlockSpinner";

function InnerSchemeGroupListInit(props: {
  children: React.ReactNode;
}): JSX.Element {
  const { state, dispatch } = useContext(SchemeContext);
  const selectedScheme = useSelectedScheme();
  const { stale } = state;

  const [init, setInit] = React.useState(false);

  if (!selectedScheme) {
    throw new Error("Scheme must have been selected.");
  }

  const [{ data, loading, error }, refresh] = useGetList<LocationGroup>(
    "LOCATION_GROUP",
    { companyId: selectedScheme.companyId },
    undefined,
    `scheme/${selectedScheme.schemeId}/groups`
  );

  useEffect(() => {
    if (data) {
      dispatch({ type: "LOADING_GROUPS_SUCCESS", payload: data.data });
    }
    setInit(true);
  }, [data, dispatch]);

  useEffect(() => {
    if (loading && !stale) {
      dispatch({ type: "LOADING_GROUPS_STARTED" });
    }
  }, [loading, dispatch, stale]);

  useEffect(() => {
    if (error) {
      dispatch({ type: "LOADING_GROUPS_ERROR", payload: error });
    }
  }, [error, dispatch]);
  useEffect(() => {
    if (stale) {
      refresh();
    }
  }, [stale, refresh]);

  return (
    <>
      <BlockSpinner loading={state.loading}>
        {init && !state.loading && <>{props.children}</>}
      </BlockSpinner>
    </>
  );
}

export default function SchemeGroupListInit(props: {
  children: React.ReactNode;
}): JSX.Element {
  const selectedScheme = useSelectedScheme();
  const { dispatch } = useContext(SchemeContext);

  useEffect(() => {
    if (!selectedScheme) {
      dispatch({ type: "LOADING_GROUPS_SUCCESS", payload: [] });
    }
  }, [selectedScheme, dispatch]);

  return (
    <>
      {selectedScheme ? (
        <InnerSchemeGroupListInit>{props.children}</InnerSchemeGroupListInit>
      ) : (
        <>{props.children}</>
      )}
    </>
  );
}
