import React from "react";
import * as _ from "lodash";
import { CompanyType } from "openapi/model/companyType";
import useUserHasRoles from "../../../hooks/user/getCurrentUserRole";

interface Props {
  children: any;
  userTypes: string[] | string;
  companyType?: CompanyType | CompanyType[];
  ignoreViewerModeSwitch?: boolean;
}

export default function DisplayIfUserType(props: Props): JSX.Element {
  const userTypes = _.isArray(props.userTypes)
    ? props.userTypes
    : singleUserTypeToList(props.userTypes);
  const companyTypes = _.isArray(props.companyType)
    ? props.companyType
    : props.companyType
    ? [props.companyType]
    : undefined;

  const display = useUserHasRoles(
    userTypes,
    companyTypes,
    props.ignoreViewerModeSwitch
  );

  return <>{display && props.children}</>;
}

const singleUserTypeToList = (userType: string): Array<string> => {
  switch (userType) {
    case "superadmin":
      return ["superadmin"];
    case "admin":
      return ["admin", "superadmin"];
    case "editor":
      return ["superadmin", "admin", "editor"];
    case "viewer":
      return ["superadmin", "admin", "editor", "viewer"];
    default:
      return ["superadmin", "admin", "editor", "viewer"];
  }
};
