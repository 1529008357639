import React, { useState } from "react";
import Papa from "papaparse";
import { Button, ButtonProps } from "reactstrap";
import FileSaver from "file-saver";
import flatten from "flat";
import _ from "lodash";

interface Props {
  // one of download or data must be specified
  download?: () => Promise<any[]>; // optional promise which returns the data that can be converted in to a csv
  data?: any[]; // optional data which can be converted into a csv.

  filename?: string;
  columnOrder?: string[];
  sortColumns?: boolean;
}

export const unparse = (
  data: Array<any>,
  columnOrder?: string[],
  sortColumns = true
): any => {
  const dataFields: Set<string> = new Set();
  const objects: Array<any> = data.map((d) => {
    const o: any = flatten(d);
    Object.keys(o).forEach((k) => {
      if (
        o[k] &&
        _.isEmpty(o[k]) &&
        o[k] !== null &&
        o[k].constructor === Object
      ) {
        delete o[k];
      } else {
        dataFields.add(k);
      }
    });
    return o;
  });
  let columns: string[];
  if (columnOrder) {
    const orderedColumns = _.intersection(columnOrder, [...dataFields]);
    const remainder = _.difference([...dataFields], orderedColumns);

    columns = [...orderedColumns, ...remainder.sort()];
  } else {
    if (sortColumns) {
      columns = [...dataFields].sort();
    } else {
      columns = [...dataFields];
    }
  }

  return Papa.unparse(objects, { columns: columns });
};

const saveDataAsFile = (
  data: Array<any>,
  filename?: string,
  columnOrder?: string[],
  sortColumns = true
): void => {
  FileSaver.saveAs(
    new Blob([unparse(data, columnOrder, sortColumns)], {
      type: "text/csv;charset=utf-8",
    }),
    `${filename ? filename : "export"}.csv`
  );
};

const CSVExportButton = (props: Props & ButtonProps): JSX.Element => {
  const {
    download,
    data,
    filename,
    columnOrder,
    sortColumns = true,
    ...rest
  } = props;

  const [loading, setLoading] = useState(false);

  const downloadClickHandler = (): void => {
    setLoading(true);
    if (download) {
      download()
        .then((data) => {
          saveDataAsFile(data, filename, columnOrder, sortColumns);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (data) {
      saveDataAsFile(data, filename, columnOrder, sortColumns);
      setLoading(false);
    } else {
      setLoading(false);
      throw new Error("Data is not available");
    }
  };

  return (
    <Button
      color="primary"
      onClick={downloadClickHandler}
      {...rest}
      disabled={loading}
    >
      {loading ? (
        <i className={"fa fa-spinner fa-spin fa-fw mr-2"} />
      ) : (
        <i className={"fa fa-cloud-download-alt fa-fw mr-2"} />
      )}
      Download
    </Button>
  );
};

export default CSVExportButton;
