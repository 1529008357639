import React from 'react';
import FormComponent from "../../components/Forms/FormComponent";

interface SftpReceiverIntegrationFormProps {
namespace: string;
}

export default function SftpReceiverIntegrationForm(props: SftpReceiverIntegrationFormProps): React.ReactElement {
    const withNamespace = (field: string) => {
        return `${props.namespace}.${field}`;
    }

    return (        <>
            <FormComponent
                fieldName={withNamespace('sftpCredentials.publicKey')}
                label={"Your Public SSH Key"}
                type={'textarea'}
            />
        </>

    );
}
