import React from "react";
import { LatestTimeSeriesItem } from "openapi/model/latestTimeSeriesItem";
import useGetData from "../../../hooks/getData";
import ErrorOverlappingBanner from "../../../components/Error/ErrorOverlappingBanner";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import {LatestDataReportParams} from "./model";

export type LatestTimeSeriesResponse = Array<LatestTimeSeriesItem>;

export interface LatestTimeSeriesReportProps {
  error?: Error;
  data?: LatestTimeSeriesResponse;
  loading: boolean;
}

export interface LatestTimeSeriesReportRequest extends LatestDataReportParams{
  companyId: string;
  schemeId: string;
}

const LatestTimeSeriesApiWrapper = (props: {
  request: LatestTimeSeriesReportRequest;
  children: (props: LatestTimeSeriesReportProps) => React.ReactNode;
}): JSX.Element => {
  const {companyId, schemeId, ...rest} = props.request
  const [{ data, error, loading }] = useGetData<LatestTimeSeriesResponse>({
    path: "report/time_series/latest",
    companyId, schemeId,
    params: rest,
    singletonApi: true,
    post: true,
  });

  return (
    <>
      <ErrorOverlappingBanner error={error || undefined}>
        <BlockSpinner loading={loading}>
          {props.children({ error: error || undefined, data, loading })}
        </BlockSpinner>
      </ErrorOverlappingBanner>
    </>
  );
};

export default LatestTimeSeriesApiWrapper;
