import ExportAction from "../action/ExportAction";
import DownloadButtonContent from "./content/DownloadButtonContent";
import React from "react";
import { GetWholeListParams } from "../../hooks/getWholeList";

const ExportActionButton = ({ ...rest }: GetWholeListParams): JSX.Element => {
  return (
    <ExportAction {...rest} buttonProps={{ color: "primary" }}>
      <DownloadButtonContent />
    </ExportAction>
  );
};

export default ExportActionButton;
