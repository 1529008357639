import React from "react";
import RadioButtonGroup from "../Forms/RadioButtonGroup";

const AssetExpectedFrequencySwitch = (props: {
  fieldName: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: any;
  submit?: any;
  title?: string;
  abbreviate?: boolean;
  paddingX?: number;
  includeDeviceNormal?: boolean;
  include1Minute?: boolean;
}): JSX.Element => {
  const {
    fieldName,
    setFieldValue,
    values,
    title = "Expected Data Frequency",
    abbreviate = false,
    includeDeviceNormal = false,
    include1Minute = true,
  } = props;

  const suffix = abbreviate ? "min" : "minute";
  return (
    <RadioButtonGroup
      fieldName={fieldName}
      title={title}
      paddingX={props.paddingX}
      options={(include1Minute ? [{ name: `1 ${suffix}`, value: "1" }] : [])
        .concat([
          { name: `5 ${suffix}s`, value: "5" },
          { name: `15 ${suffix}s`, value: "15" },
          { name: `30 ${suffix}s`, value: "30" },
          { name: `60 ${suffix}s`, value: "60" },
          { name: "Twice Daily", value: "720" },
          { name: "Daily", value: "1440" },
        ])
        .concat(
          includeDeviceNormal
            ? [{ name: `Device Default`, value: "Default" }]
            : []
        )}
      setFieldValue={setFieldValue}
      currentValue={values[fieldName]}
      onChange={(): void => {
        if (props.submit) {
          props.submit();
        }
      }}
      submitSpinner={false}
    />
  );
};

export default AssetExpectedFrequencySwitch;
