import React, { useState } from "react";
import { Alert } from "reactstrap";
import "./BetaAlert.scss";

interface Props {
  title?: string;
  children?: React.ReactNode | React.ReactNode[];
}

const BetaAlert = (props: Props): JSX.Element => {
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);

  return (
    <Alert color="info" isOpen={visible} toggle={onDismiss}>
      <h3>Preview Feature{props.title && <>: {props.title}</>}</h3>
      <p>
        This feature is currently in preview. We might remove it or change it at
        any time.
      </p>
      <p>
        We&apos;d love your feedback on it, please let us know if it helped you
        or if we could make a change to make it better
      </p>
      {props.children}
    </Alert>
  );
};

export default BetaAlert;
