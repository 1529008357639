import React from "react";
import {Col, Row} from "reactstrap";
import {JobDefinitionOut} from "../../openapi/model/jobDefinitionOut";
import {PageDefinition} from "../../components/navigation/breadcrumbs/Breadcrumbs";
import JobCreateForm from "./JobCreateForm";
import HighlightNullAndOldDates from "../../components/utils/HighlightNullAndOldDates";
import moment from "moment";
import _ from "lodash";
import ExecuteJobForm from "./ExecuteJobForm";
import {JobStatus} from "../../openapi/model/jobStatus";
import EventList from "../event/EventList";
import ChangeJobStateButton from "./ChangeJobState";
import {useParams} from "react-router-dom";
import GetEntity from "core/action/GetEntity";
import {OneCardPage} from "../../components/utils/OneCardPage";
import {ErrorBanner} from "../../components/Error/ErrorBanner";
import ObjectPropertyItem from "../../components/Headers/ObjectPropertyItem";
import CopyToClipboard from "../../components/utils/CopyToClipboard";
import RoutedTabGroupInterface from "../../components/TabGroup/RoutedTabGroupInterface";
import ReportExecutionListContainer from "../report/executions/ReportExecutionListContainer";
import AssetRegisterStatusLabel from "../../components/Labels/AssetRegisterStatusLabel";

const jobToBreadcrumb = (job: JobDefinitionOut): PageDefinition[] => {
    return [
        {
            name: `Jobs`,
            url: `/admin/job`,
        },
        {
            name: `${job.name} (${job.jobId})`,
            url: `/admin/job/${job.jobId}`,
        },
    ];
};

export default function JobHomePage(props: {
    companyId: string;
    schemeId?: string;
}): JSX.Element {
    const {jobId} = useParams<{ jobId: string }>();

    if (jobId === undefined) {
        throw new Error("jobId must be specified.");
    }

    return (
        <GetEntity
            entityType={"JOB"}
            entityId={jobId}
            error={({error}) => (
                <OneCardPage
                    headerTitle={"Job Detail"}
                    breadcrumbs={[{name: "Error"}]}
                >
                    <ErrorBanner error={error}/>
                </OneCardPage>
            )}
            placeholder={() => (
                <OneCardPage
                    headerTitle={"Job Detail"}
                    loading={true}
                    breadcrumbs={[{name: "Loading"}]}
                >
                    <></>
                </OneCardPage>
            )}
            {...props}
        >
            {({data, refresh}) => (
                <OneCardPage breadcrumbs={data && jobToBreadcrumb(data)}
                             headerTitle={`Job Detail`}
                >
                    <Row>
                        <Col xs={12} md={4}>
                            <div className={"d-flex flex-wrap"}>
                                <ObjectPropertyItem title={"Job Name"} type={"heading"}>
                                    {data.name}
                                </ObjectPropertyItem>
                            </div>
                        </Col>
                        <Col xs={12} md={8}>
                            <div className={
                                "d-flex justify-content-md-end text-sm text-md-right text-left flex-wrap"
                            }>
                                <ObjectPropertyItem title={"Job ID"}>
                                    <CopyToClipboard value={data.jobId} slice={8}/>
                                </ObjectPropertyItem>
                                {data.objectType && (
                                    <ObjectPropertyItem title={"Object Filter"}>
                                        {_.startCase(data.objectType)}:{data.objectId}
                                    </ObjectPropertyItem>
                                )}
                                <ObjectPropertyItem title={"Status"}>
                                    <AssetRegisterStatusLabel status={data.status}
                                                              displayActive={true}
                                    />
                                </ObjectPropertyItem>
                                <ObjectPropertyItem title={"Last Run"}>
                                    <HighlightNullAndOldDates
                                        ageHighlight={moment.duration(1, "day")}
                                    >
                                        {data.lastRun}
                                    </HighlightNullAndOldDates>
                                </ObjectPropertyItem>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <RoutedTabGroupInterface
                                tabs={[
                                    {
                                        tabName: 'Executions',
                                        tabPath: 'executions',
                                        tabContent: <>
                                            <Row>
                                                {data && data.status === JobStatus.Active ?
                                                    <Col xs={12}>
                                                        <h3>Active Job</h3>
                                                        <span>This job is currently active. It runs {data.schedule.frequency}.</span>
                                                    </Col>
                                                    :
                                                    <Col xs={12}>
                                                        <h3>Disabled job</h3>
                                                        <span>This job is currently disabled. It will not run.</span>
                                                    </Col>
                                                }
                                                <Col xs={12} className={'py-2'}>
                                                    <ChangeJobStateButton job={data} refresh={refresh}/>
                                                </Col>
                                                {data && data.status === JobStatus.Active &&
                                                    <Col xs={12}>
                                                        <h3>Run job now</h3>
                                                        <ExecuteJobForm job={data} refresh={refresh}/>
                                                    </Col>
                                                }
                                                <Col xs={12}>
                                                    <h3>Previous Executions</h3>
                                                    <ReportExecutionListContainer jobId={data.jobId}/>
                                                </Col>
                                            </Row>
                                        </>
                                    },
                                    {
                                        tabName: 'Configuration',
                                        tabPath: 'configuration',
                                        tabContent: <>
                                            <JobCreateForm
                                                existingJob={data}
                                                locked={true}
                                                companyId={data.companyId}
                                                key={data.jobId}
                                            />
                                        </>
                                    },
                                    {
                                        tabName: 'Events',
                                        tabPath: 'events',
                                        tabContent: <Col xs={12}>
                                            {data && (
                                                <EventList jobId={jobId} companyId={data?.companyId}/>
                                            )}
                                        </Col>
                                    }
                                ]}
                                topLevelPath={`/admin/job/${data.jobId}/:tab`}
                            />
                        </Col>
                    </Row>
                </OneCardPage>
            )}
        </GetEntity>
    );
}
