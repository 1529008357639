import React, { useEffect, useState } from "react";
import { RelayState } from "../../../openapi/model/relayState";
import { Button, ButtonGroup, Col, Row } from "reactstrap";
import { ActionTypes } from "openapi/model/actionTypes";
import { RelayStateCommand } from "../../../openapi/model/relayStateCommand";
import { Asset } from "../../../openapi/model/asset";
import _ from "lodash";
import moment from "moment";
import { InputOutputModuleState } from "../../../openapi/model/inputOutputModuleState";
import useCreateEntity from "../../../hooks/createEntity";
import { ErrorBanner } from "components/Error/ErrorBanner";

export const getRefreshDelay = (
  state: InputOutputModuleState
): number | undefined => {
  if (
    state.relayState &&
    [RelayState.SWITCHINGON, RelayState.SWITCHINGOFF].includes(state.relayState)
  ) {
    const now = new Date();
    let update = state.lastUpdate;
    if (update !== undefined) {
      if (_.isString(update)) {
        update = moment(update).toDate();
      }
      const diff = now.valueOf() - update.valueOf();
      switch (true) {
        case diff <= 2 * 60 * 1000:
          return 10000;
        case diff <= 10 * 60 * 1000:
          return 20000;
        default:
          return 60000;
      }
    } else {
      return 60000;
    }
  }
  return 0;
};

const SwitchRelayStateButtons = (props: {
  asset: Asset;
  refresh?: () => void;
}): JSX.Element => {
  const [assetStateSwitching, setAssetStateSwitching] =
    useState<boolean>(false);

  const state: InputOutputModuleState = props.asset
    .state as InputOutputModuleState;

  const { createEntity, error } = useCreateEntity(
    "ASSET",
    `asset/${props.asset.assetId}/action`
  );
  const { refresh, asset } = props;

  useEffect(() => {
    const delay = getRefreshDelay(state);
    if (delay && delay > 0 && refresh !== undefined) {
      const t = setTimeout(() => {
        refresh();
      }, delay);
      return () => clearTimeout(t);
    }
  }, [asset, refresh, state]);

  const currentState: RelayState = state.relayState || RelayState.UNKNOWN;

  const switchableOn = [
    RelayState.OFF,
    RelayState.UNKNOWN,
    RelayState.SWITCHINGERROR,
  ].includes(currentState);
  const switchableOff = [
    RelayState.ON,
    RelayState.UNKNOWN,
    RelayState.SWITCHINGERROR,
  ].includes(currentState);
  const switching = [RelayState.SWITCHINGOFF, RelayState.SWITCHINGON].includes(
    currentState
  );

  const buttonClick = (state: RelayStateCommand): void => {
    setAssetStateSwitching(true);
    createEntity({
      actionType: ActionTypes.SWITCHRELAY,
      companyId: props.asset.companyId,
      payload: { newRelayState: state },
    }).then(() => {
      if (props.refresh) {
        props.refresh();
      }
    });
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <ButtonGroup>
            <Button
              disabled={!switchableOn || assetStateSwitching}
              color={"success"}
              onClick={() => buttonClick("ON")}
            >
              Turn On
            </Button>
            <Button
              disabled={!switchableOff || assetStateSwitching}
              color={"danger"}
              onClick={() => buttonClick("OFF")}
            >
              Turn Off
            </Button>
          </ButtonGroup>
        </Col>
        {(switching || assetStateSwitching) && (
          <Col xs={12}>
            <small>State is currently switching. No actions possible.</small>
          </Col>
        )}
        <ErrorBanner error={error} />
      </Row>
    </>
  );
};

export default SwitchRelayStateButtons;
