import React from 'react';
import BlockSpinner from "../Spinners/BlockSpinner";
import {Alert, ListGroup, ListGroupItem} from "reactstrap";
import {AxiosError} from "axios";


export interface ListProps {
    data?: Array<any>;
    loading?: boolean;
    header?: JSX.Element;
    row: (item: any, index: number, refresh?: () => void) => JSX.Element;
    "data-cy"?: string; // optional cypress label for the table
    tableClassName?: string;
    noDataRow?: JSX.Element;
    error?: Error | null;
    refresh?: () => void;
    missingElements?: number
}


function List(props: ListProps) {
    const error = props.error;
    const header = props.header;

    const displayError = (error: Error | AxiosError): JSX.Element => {
        if (error && Object.prototype.hasOwnProperty.call(error, "isAxiosError")) {
            const err = error as AxiosError;
            const message = err.response?.data?.message || err.response?.statusText;
            return errorRow(message, err.response?.status);
        } else {
            return errorRow(error.message, undefined);
        }
    };

    const errorRow = (message: string, code?: number): JSX.Element => {
        const color = !code || code >= 500 ? "danger" : "warning";
        return (
            <ListGroupItem key={"list-error"}>
                <Alert color={color}>
                    <h3 style={{color: "black"}}>An error has occurred.</h3>
                    <p style={{color: "black"}}>
                        The sever has responded: {message} {code && <>({code})</>}.
                    </p>
                </Alert>
            </ListGroupItem>
        );
    };
    return (
        <BlockSpinner loading={props.loading || false} scrollX={false}>
            <ListGroup data-cy={props["data-cy"]} className={props.tableClassName}>
                {header}

                {props.data?.map((value, index) => {
                    return props.row(value, index, props.refresh);
                })}
                {props.loading &&
                    [...Array(props.missingElements)].map((_, i) => (
                        <ListGroupItem key={i}></ListGroupItem>
                    ))}
                {!props.loading && props.data?.length === 0 && !error && (
                    <ListGroupItem key={"no-data"}>
                        {props.noDataRow || "No Data Found"}
                    </ListGroupItem>
                )}
                {!props.loading && error && displayError(error)}
            </ListGroup>
        </BlockSpinner>
    );
}

export default List;