import {
  yupRequiredString,
  yupString,
} from "../components/Forms/yupValidators";
import { nameRegEx } from "./utils/schema";
import { AssetType } from "../openapi/model/assetType";

export const assetPositionSchema = {
  assetPositionType: yupRequiredString.notOneOf(["undefined", ""]),
  customerReference: yupString.notRequired().matches(...nameRegEx),
  parentAssetPositionId: yupString,
};

export const BillableAssets: AssetType[] = [
  AssetType.HeatMeter,
  AssetType.CoolingMeter,
  AssetType.HeatingAndCoolingMeter,
  AssetType.ElectricityMeter,
  AssetType.GasMeter,
  AssetType.WaterMeter,
];

export const NonBillableAssetTypes: AssetType[] = [
  AssetType.InputOutputModule,
  AssetType.RoomSensor,
  AssetType.Co2RoomSensor,
];

export const InfrastructureAssetTypes: AssetType[] = [
  AssetType.WirelessCluster,
  AssetType.WirelessReceiver,
  AssetType.Gateway,
  AssetType.SimCard,
];
