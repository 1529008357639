import { Entities, EntitiesPathMap } from "../constants/entities";

import { useState } from "react";
import axios from "axios";
import Config from "../Config";
import * as _ from "lodash";
import { objectKeysToSnakeCase } from "../actions/helpers/apiService";

export interface UpdateEntity<T = any> {
  updateEntity: (entity: Partial<T>, path?: string) => Promise<void>;
  updating: boolean;
  error?: Error;
  reset: () => void;
}

export interface UpdateEntityProps {
  entityType: Entities;
  entityId: string;
  postUpdate?: (updatedItem?: any) => any;
  path?: string;
}

const useUpdateEntity = <T,>(
  entityType: Entities,
  entityId: string,
  postUpdate?: (updatedEntity: Partial<T>) => any,
  path?: string
) => {
  const updateEntity = (entity: Partial<T>): Promise<void> => {
    updateState((s) => {
      return { ...s, updating: true };
    });
    return axios
      .put(
        Config.apiGateway.URL +
          "/" +
          (path ? path : EntitiesPathMap[entityType] + "/" + entityId),
        _.isArray(entity)
          ? entity.map(objectKeysToSnakeCase)
          : objectKeysToSnakeCase(entity)
      )
      .then(() => {
        postUpdate && postUpdate(entity);
        updateState((s) => {
          return { ...s, updating: false, error: undefined };
        });
        return;
      })
      .catch((error) => {
        updateState((s) => {
          return { ...s, updating: false, error };
        });
        throw error;
      });
  };

  const reset = () => {
    updateState((s) => {
      return { ...s, updating: false, error: undefined };
    });
  };

  const [state, updateState] = useState<UpdateEntity<T>>({
    updating: false,
    updateEntity,
    reset,
  });

  return state;
};

export default useUpdateEntity;
