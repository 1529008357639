import React, { useState } from "react";
import { Button } from "reactstrap";
import { JobDefinitionOut } from "../../openapi/model/jobDefinitionOut";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import useUpdateEntity from "../../hooks/updateEntity";

interface Props {
  job: JobDefinitionOut;
  refresh?: () => any;
}

const ChangeJobStateButton = (props: Props): JSX.Element => {
  const { job } = props;

  const { updateEntity, error } = useUpdateEntity<JobDefinitionOut>(
    "JOB",
    job.jobId,
    props.refresh
  );
  const [submitting, setSubmitting] = useState(false);

  const active = job.status === "active";

  const switchState = () => {
    setSubmitting(true);
    updateEntity({ ...job, status: active ? "disabled" : "active" }).finally(
      () => {
        setSubmitting(false);
      }
    );
  };

  return (
    <DisplayIfUserType userTypes={"admin"}>
      <span className={"px-2"}>
        <Button
          className={"btn-sm"}
          color={active ? "danger" : "success"}
          onClick={switchState}
          disabled={submitting}
        >
          {active ? "Set to disabled" : "Set to active"}
        </Button>
        <ErrorBanner error={error} />
      </span>
    </DisplayIfUserType>
  );
};

export default ChangeJobStateButton;
