import React from "react";

const AssetListGenericRowsHeader = (showPosition=true, showScheme=true): [JSX.Element, number] => {

    // eslint-disable-next-line react/jsx-key
    return [<tr>
        <th className="text-left">Device ID</th>
        {showScheme && <th className={'text-left'}>Scheme</th>}
        <th className="text-left">Serial Number</th>
        <th className="text-left">Type</th>
        <th className="text-left">Manufacturer</th>
        {showPosition && <th className="text-left">Position</th>}
        <th className="text-left">&nbsp;</th>
    </tr>, 5 + (showPosition ? 1 : 0) + (showScheme ? 1 : 0)]
}


export default AssetListGenericRowsHeader;