import React, { useState } from "react";
import useGetEntity from "../../hooks/getEntity";
import { useSelectedCompany } from "../../reducers/company";
import { useSelectedScheme } from "../../reducers/scheme";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import { Table } from "reactstrap";
import { DeviceConfigurationMap } from "../../openapi/model/deviceConfigurationMap";
import { formatDate } from "../../components/utils/HighlightNullAndOldDates";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import RadioButtonGroup from "../../components/Forms/RadioButtonGroup";
import AutoSubmit from "../../components/Forms/AutoSubmit";

interface AssetConfigurationPageProps {
  asset_id: string;
}

interface FormFilterValues {
  show: "all" | "set" | "incorrect";
}

export default function AssetConfigurationPage(
  props: AssetConfigurationPageProps,
): React.ReactElement {
  const company = useSelectedCompany();
  const scheme = useSelectedScheme();

  const [filterValues, setFilterValues] = useState<FormFilterValues>({
    show: "all",
  });
  const [{ data, loading, error }] = useGetEntity<DeviceConfigurationMap>({
    entityType: "DETAILED_ASSET_CONFIGURATION",
    entityId: props.asset_id,
    companyId: company?.companyId,
    schemeId: scheme?.schemeId,
    singletonApi: true,
    path: `asset/${props.asset_id}/configuration/detailed`,
  });

  const total = data?.values.length;
  const set = data?.values.filter((v) => v.target !== null).length;
  const incorrect = data?.values.filter(
    (v) => v.target !== null && v.target !== v.current,
  ).length;

  return (
    <BlockSpinner loading={loading}>
      <div>
        <Formik
          initialValues={filterValues}
          onSubmit={(v) => {
            setFilterValues(v);
          }}
          enableReinitialize={true}
        >
          {({ setFieldValue, values }) => {
            return (
              <>
                <AutoSubmit />
                <Form>
                  <RadioButtonGroup
                    fieldName={"show"}
                    title={""}
                    currentValue={values.show}
                    options={[
                      { name: `All (${total || "-"})`, value: "all" },
                      { name: `Set (${set || "-"})`, value: "set" },
                      {
                        name: `Incorrect (${incorrect || "-"})`,
                        value: "incorrect",
                      },
                    ]}
                    setFieldValue={setFieldValue}
                    submitSpinner={false}
                    paddingX={0}
                    fullWidth={false}
                  />
                </Form>
              </>
            );
          }}
        </Formik>
        <>
          {error && <ErrorBanner error={error} />}
          {data && (
            <Table size={"sm"} striped={true}>
              <thead>
                <tr>
                  <th>Key {filterValues.show}</th>
                  <th>Target</th>
                  <th>Current</th>
                  <th>Last change</th>
                </tr>
              </thead>
              <tbody>
                {data?.values
                  .filter((value) => {
                    if (filterValues.show === "all") {
                      return true;
                    } else {
                      if (filterValues.show === "incorrect") {
                        return (
                          value.target !== null &&
                          value.target !== value.current
                        );
                      } else {
                        return value.target !== null;
                      }
                    }
                  })
                  .map((value) => (
                    <tr key={value.key}>
                      <td>{value.key}</td>
                      <td>
                        <span style={{ whiteSpace: "pre-wrap" }}>
                          {value.target || "-"}
                        </span>
                      </td>
                      <td>
                        <span style={{ whiteSpace: "pre-wrap" }}>
                          {value.current || "-"}
                        </span>
                        {value.target &&
                          value.current &&
                          (value.target === value.current ? (
                            <FontAwesomeIcon
                              icon={faCheck}
                              className={"px-2 text-success"}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faXmark}
                              className={"px-2 text-danger"}
                            />
                          ))}
                      </td>
                      <td>
                        <span className={"text-nowrap"}>
                          {formatDate(value.lastChanged)}
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
        </>
      </div>
    </BlockSpinner>
  );
}
