import React from "react";
import { Col } from "reactstrap";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import SchemeLabel from "../../components/Scheme/SchemeLabel";
import AssetTypeHumanLabel from "../../components/Asset/AssetTypeHumanLabel";
import { AssetRegisterItem } from "openapi/model/assetRegisterItem";
import classNames from "classnames";
import AssetStateLabel from "containers/assets/AssetStateLabel";
import AssetRegisterStatusLabel from "../../components/Labels/AssetRegisterStatusLabel";
import PrivacyMode from "components/Text/PrivacyMode";
import AssetUseCaseHumanLabel from "../../components/Asset/AssetUseCaseHumanLabel";

import "./AssetRegisterListItem.scss";
export default function AssetRegisterListItem(props: {
  assetRegisterItem: AssetRegisterItem;
  showScheme?: boolean;
  showSchemeBp?: string;
  showType?: boolean;
  showReference?: boolean;
  showAsset?: boolean;
  denseColumn?: (props: {
    assetRegisterItem: AssetRegisterItem;
  }) => React.ReactNode;
  extensionLarge?: React.ReactNode;
  extensionSmall?: React.ReactNode;
  actionButtons?: (props: {
    assetRegisterItem: AssetRegisterItem;
  }) => React.ReactNode;
  index: number;
  showLocation?: boolean;
}): JSX.Element {
  const { location, assetPosition, asset } = props.assetRegisterItem;
  const {
    assetRegisterItem,
    showScheme = true,
    showSchemeBp = "sm",
    showType = true,
    showReference = true,
    showAsset = true,
    showLocation = true,
  } = props;

  const dense = props.denseColumn !== undefined;
  const actionButtons = props.actionButtons !== undefined;

  return (
    <li
      className={"checklist-entry list-group-item px-0 px-md-2"}
      data-cy={"asset-register-list-item"}
      key={props.index}
    >
      <div className={"d-md-flex d-none"}>
        {/* Version for large screens */}
        {dense ? (
          <>
            <Col md={6} lg={6} className={"d-flex flex-wrap px-0"}>
              <Col xs={12}>
                {showLocation && (
                  <CompanyNavLink
                    className={"p-0 d-inline"}
                    to={`/admin/location/${location?.locationId}`}
                    dataCy={"locationId"} inline={true}
                  >
                    <PrivacyMode>{location?.address}</PrivacyMode>
                  </CompanyNavLink>
                )}
                {" | "}
                {showReference && (
                  <>
                    <CompanyNavLink
                      className={"p-0 d-inline"}
                      to={`/admin/asset_position/${assetPosition?.assetPositionId}`}
                      dataCy={"assetPositionId"}
                      inline={true}
                    >
                      <PrivacyMode>
                        {assetPosition?.customerReference ||
                          "No Position Reference"}
                      </PrivacyMode>
                    </CompanyNavLink>
                    {" | "}
                    <PrivacyMode>{location.group || "No group"}</PrivacyMode>
                  </>
                )}
                <AssetRegisterStatusLabel status={assetPosition.status} />
              </Col>
              {showAsset && (
                <Col xs={12} className={"d-flex flex-wrap"}>
                  {asset ? (
                    <div className={"mr-4"}>
                      <CompanyNavLink
                        className={"p-0 d-inline"}
                        to={`/admin/asset/${asset?.assetId}`}
                        dataCy={"assetId"}
                        inline={true}
                      >
                        <PrivacyMode>{asset.serialNumber}</PrivacyMode>
                      </CompanyNavLink>
                      {asset?.manufacturer && (
                        <>
                          {" "}
                          <small>{asset.manufacturer}</small>
                        </>
                      )}
                    </div>
                  ) : (
                    <>Unassigned</>
                  )}
                  {showScheme && (
                    <div className={"mr-4"}>
                      (<SchemeLabel schemeId={location.schemeId} />)
                    </div>
                  )}
                  {showType && (
                    <div className={"ml-auto"}>
                      <AssetUseCaseHumanLabel asset={assetPosition} />
                      <AssetTypeHumanLabel
                        asset={assetPosition}
                        iconLocation={"left"}
                      />{" "}
                    </div>
                  )}
                </Col>
              )}
            </Col>
            <Col xs={actionButtons ? 5 : 6} className={"px-0"}>
              {props.denseColumn && props.denseColumn({ assetRegisterItem })}
            </Col>
            {props.actionButtons && (
              <Col xs={1} className={"px-0 text-right"}>
                {props.actionButtons({ assetRegisterItem })}
              </Col>
            )}
          </>
        ) : (
          <>
            {showLocation && (
              <Col md={3 + (showScheme ? 0 : 1)} lg={4 + (showScheme ? 0 : 1)}>
                <span>
                  <CompanyNavLink
                    className={"p-0 d-inline"}
                    to={`/admin/location/${location?.locationId}`}
                    dataCy={"locationId"}
                    inline={true}
                  >
                    <PrivacyMode>{location?.address}</PrivacyMode>
                  </CompanyNavLink>
                </span>
                <span className={"text-sm d-block"}>
                  <PrivacyMode>
                    {location.customerReference || "No Reference"}
                  </PrivacyMode>
                  {" | "}
                  <PrivacyMode>{location.group || "No group"}</PrivacyMode>
                </span>
              </Col>
            )}
            {showReference && (
              <Col
                md={3 + (showScheme ? 0 : showLocation ? 1 : 3)}
                lg={2 + (showScheme ? 0 : showLocation ? 1 : 3)}
              >
                <CompanyNavLink
                  className={"p-0 d-inline"}
                  to={`/admin/asset_position/${assetPosition?.assetPositionId}`}
                  dataCy={"assetPositionId"}
                    inline={true}
                >
                  <PrivacyMode>
                    {assetPosition?.customerReference ||
                      "No Position Reference"}
                  </PrivacyMode>
                </CompanyNavLink>

                <AssetRegisterStatusLabel status={assetPosition.status} />
              </Col>
            )}
            {showAsset && (
              <Col md={2} lg={2}>
                {asset ? (
                  <>
                    <span>
                      <CompanyNavLink
                        className={"p-0 d-inline"}
                        to={`/admin/asset/${asset?.assetId}`}
                        dataCy={"assetId"}
                    inline={true}
                      >
                        <PrivacyMode>{asset.serialNumber}</PrivacyMode>
                      </CompanyNavLink>
                    </span>
                    <span className={"text-sm d-block"}>
                      {asset?.manufacturer && (
                        <>
                          {" "}
                          <small>{asset.manufacturer}</small>
                        </>
                      )}
                    </span>
                  </>
                ) : (
                  <>Unassigned</>
                )}
              </Col>
            )}
            {showScheme && (
              <Col md={2}>
                <SchemeLabel schemeId={location.schemeId} />
              </Col>
            )}
            {showType && (
              <Col md={showLocation ? 2 : 4} className={"text-right"}>
                <AssetStateLabel asset={asset} className={"mr-2"} />
                <AssetUseCaseHumanLabel asset={assetPosition} />
                <AssetTypeHumanLabel
                  asset={assetPosition}
                  iconLocation={"left"}
                />
              </Col>
            )}
          </>
        )}
        {props.extensionLarge}
      </div>
      <div className={"d-md-none d-flex flex-wrap"}>
        {/* Version for small screens */}
        <Col xs={12} className={"d-inline-block d-sm-flex"}>
          <div className={"mr-4"}>
            <CompanyNavLink
              className={"p-0 d-inline"}
              to={`/admin/location/${location?.locationId}`}
              dataCy={"locationId"}
                    inline={true}
            >
              {location?.address}
            </CompanyNavLink>
            {" - "}
            {showReference && (
              <CompanyNavLink
                className={"p-0 d-inline"}
                to={`/admin/asset_position/${assetPosition?.assetPositionId}`}
                dataCy={"assetPositionId"}
                    inline={true}
              >
                {assetPosition?.customerReference || "No Position Reference"}
              </CompanyNavLink>
            )}

            <AssetRegisterStatusLabel status={assetPosition.status} />
          </div>
          {showAsset && (
            <div className={"ml-auto"}>
              {asset ? (
                <>
                  <CompanyNavLink
                    className={"p-0 d-inline"}
                    to={`/admin/asset/${asset?.assetId}`}
                    dataCy={"assetId"}
                    inline={true}
                  >
                    {asset.serialNumber}
                  </CompanyNavLink>
                  {asset?.manufacturer && (
                    <span>
                      &nbsp;
                      <small>{asset.manufacturer}</small>
                    </span>
                  )}
                  {showType && (
                    <div
                      className={"d-inline-block d-sm-none pl-2 float-right"}
                    >
                      <AssetStateLabel asset={asset} className={"mr-2"} />
                      <AssetUseCaseHumanLabel asset={assetPosition} />

                      <AssetTypeHumanLabel
                        asset={assetPosition}
                        iconLocation={"left"}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>Unassigned</>
              )}
            </div>
          )}
        </Col>
        <Col xs={12} className={"d-flex"}>
          {showScheme && (
            <div
              className={classNames({
                "mr-1": true,
                "d-none": showSchemeBp,
                [`d-${showSchemeBp}-block`]: showSchemeBp,
              })}
            >
              <SchemeLabel schemeId={location.schemeId} />
            </div>
          )}
          {showType && (
            <div className={"ml-auto text-right d-none d-sm-block"}>
              <AssetStateLabel asset={asset} className={"mr-2"} />
              <AssetUseCaseHumanLabel asset={assetPosition} />

              <AssetTypeHumanLabel
                asset={assetPosition}
                iconLocation={"left"}
              />
            </div>
          )}
        </Col>
        {props.denseColumn && (
          <Col xs={12}>
            {props.denseColumn({ assetRegisterItem: props.assetRegisterItem })}
          </Col>
        )}
        {props.extensionSmall}
      </div>
    </li>
  );
}

export function AssetRegisterListHeading(props: {
  showScheme?: boolean;

  showSchemeBp?: string;
  showType?: boolean;
  showReference?: boolean;
  showAsset?: boolean;
  showLocation?: boolean;
  denseColumn?: React.ReactNode;
  extensionLarge?: React.ReactNode;
  extensionSmall?: React.ReactNode;
  actionButtons?: boolean;
}): JSX.Element {
  const {
    showScheme = true,
    // showSchemeBp = "sm",
    showType = true,
    showReference = true,
    showAsset = true,
    showLocation = true,
    actionButtons = false,
  } = props;

  const dense = props.denseColumn !== undefined;

  return (
    <li
      className={
        "checklist-entry list-group-item px-0 px-md-2 list-heading d-none d-md-block"
      }
      data-cy={"asset-register-list-heading"}
    >
      <div className={"d-md-flex d-none"}>
        {/* Version for large screens */}
        {dense ? (
          <>
            <Col md={6} lg={6} className={"d-flex flex-wrap px-0"}>
              <Col xs={12}>
                {showLocation && "Location"}
                {" - "}
                {showReference && <>Asset Position - Asset</>}

                {/*<AssetRegisterStatusLabel status={assetPosition.status} />*/}
              </Col>
            </Col>
            <Col xs={actionButtons ? 5 : 6} className={"px-0"}>
              {props.denseColumn}
            </Col>
            &nbsp;
          </>
        ) : (
          <>
            {showLocation && (
              <Col md={3 + (showScheme ? 0 : 1)} lg={4 + (showScheme ? 0 : 1)}>
                Location
              </Col>
            )}
            {showReference && (
              <Col
                md={3 + (showScheme ? 0 : showLocation ? 1 : 3)}
                lg={2 + (showScheme ? 0 : showLocation ? 1 : 3)}
              >
                Asset Position Reference
              </Col>
            )}
            {showAsset && (
              <Col md={2} lg={2}>
                Asset
              </Col>
            )}
            {showScheme && <Col md={2}>Scheme </Col>}
            {showType && (
              <Col md={showLocation ? 2 : 4} className={"text-right"}>
                Asset Type
              </Col>
            )}
          </>
        )}
      </div>
    </li>
  );
}
