import React from "react";
import { AssetPosition } from "../../openapi/model/assetPosition";
import UpdateButton from "../../components/Buttons/UpdateButton";

export default function WirelessClusterGatewayLeaveButton(props: {
  gatewayPosition: AssetPosition;
  refresh?: () => void;
}): JSX.Element {
  const { gatewayPosition } = props;

  return (
    <UpdateButton
      updatedEntity={{
        ...gatewayPosition,
        parentAssetPositionId: undefined,
      }}
      entityId={gatewayPosition.assetPositionId}
      entityType={"ASSET_POSITION"}
      text={"Leave"}
      icon={"fa-solid fa-link-slash"}
      color={"primary"}
      size={"sm"}
      postUpdate={props.refresh}
    />
  );
}
