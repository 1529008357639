import React, {useEffect, useState} from 'react';
import {WirelessSurveyOut} from "../../openapi/model/wirelessSurveyOut";
import {Col, Row} from "reactstrap";
import RoutedTabGroupInterface from "../../components/TabGroup/RoutedTabGroupInterface";
import useGetList from "../../hooks/getList";
import WirelessSurveyDataCollectorsTab from "./WirelessSurveyDataCollectorsTab";
import WirelessSurveyLiveData from "./WirelessSurveyLiveData";
import useWebSocket, {ReadyState} from "react-use-websocket";
import {getWebSocketURL} from "../../actions/helpers/auth";
import {
    groupWirelessSurveyDataByDataCollector,
    handleReceivingNewStreamingSurveyDataRowMessage, parseWebsocketMessage, WirelessDataSurveyMap,
    WirelessSurveyDataPoint
} from "../../model/assets/wirelessSurveyData";
import {WirelessSurveyDataCollectorOut} from "../../openapi/model/wirelessSurveyDataCollectorOut";
import WirelessSurveySummaryTable from "./WirelessSurveySummaryTable";
import WirelessSurveyTargetTab from "./WirelessSurveyTargetTab";
import WirelessSurveySamplePointTab from "./WirelessSurveySamplePointTab";


function WirelessSurveyTabs(props: {
    survey: WirelessSurveyOut,
    refreshSurvey?: () => any
}) {
    const {survey} = props;

    const [{data, loading}, refresh] = useGetList<WirelessSurveyDataCollectorOut>(
        'WIRELESS_SURVEY',
        {},
        undefined,
        `wireless_survey/${survey.surveyId}/data_collector`)

    const [messageHistory, setMessageHistory] = useState<WirelessSurveyDataPoint[]>([]);
    const [messageMap, setMessageMap] = useState<WirelessDataSurveyMap>(new Map());

    const {sendMessage, lastMessage, readyState} = useWebSocket(
        getWebSocketURL
    );


    useEffect(() => {
        if (lastMessage !== null) {
            try {
                const parsed = parseWebsocketMessage(lastMessage.data);
                setMessageHistory(prevState => handleReceivingNewStreamingSurveyDataRowMessage(
                    parsed, prevState)
                );
                setMessageMap(prevState => groupWirelessSurveyDataByDataCollector(
                    parsed,
                    prevState,
                    new Map((data?.data || []).map((v) => [v.dataCollectorId, v]))
                ))
            } catch (e) {
                console.log(lastMessage.data)
                throw e
            }
        }
    }, [lastMessage, setMessageHistory, setMessageMap, data?.data]);



    useEffect(() => {
        if (readyState === ReadyState.OPEN) {
            sendMessage(
                JSON.stringify({
                    "action": "GetSurveyData",
                    "company_id": props.survey.companyId,
                    "survey_id": props.survey.surveyId
                })
            )
        }
    }, [readyState, sendMessage, props.survey.companyId, props.survey.surveyId]);

    return (
        <>
            <Row><Col xs={12}>
                {readyState !== ReadyState.OPEN && <>Connection: {ReadyState[readyState]}</>}
            </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <RoutedTabGroupInterface
                        tabs={
                            [
                                {
                                    tabName: "Data Collectors",
                                    tabPath: "data_collectors",
                                    tabContent: (
                                        <WirelessSurveyDataCollectorsTab
                                            survey={survey}
                                            dataCollectors={data?.data}
                                            loading={loading}
                                            refresh={refresh}
                                        />
                                    ),
                                },
                                {
                                    tabName: "Sample Points",
                                    tabPath: "sample_points",
                                    tabContent: (
                                        <WirelessSurveySamplePointTab
                                            dataCollectors={data?.data}
                                            loading={loading}
                                            refresh={refresh}
                                        />
                                    ),
                                },
                                {
                                    tabName: `Live Data (${messageHistory.length})`,
                                    tabPath: "live_data",
                                    tabContent: <WirelessSurveyLiveData
                                        survey={props.survey}
                                        dataCollectors={data?.data || []}
                                        data={messageHistory}

                                    />,
                                },
                                {
                                    tabName: `Targets`,
                                    tabPath: 'targets',
                                    tabContent: <WirelessSurveyTargetTab
                                        survey={props.survey}
                                        refresh={props.refreshSurvey}
                                    />
                                },
                                {
                                    tabName: `Summary`,
                                    tabPath: 'summary',
                                    tabContent: <WirelessSurveySummaryTable
                                        survey={props.survey}
                                        dataCollectors={data?.data || []}
                                        wirelessDataSurveyMappedData={messageMap}
                                        targets={props.survey.configuration?.targets}
                                    />
                                }


                            ]}
                        topLevelPath={`/admin/wireless_survey/view/${survey.surveyId}/:tab`}
                    />
                </Col>
            </Row>
        </>

    );
}

export default WirelessSurveyTabs;