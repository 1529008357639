import React from 'react';
import {SelectCombo, SelectComboProps} from "../../Forms/SelectCombo/SelectCombo";

function LabelSelect(props: Omit<SelectComboProps, 'options'>) {
    return (
        <SelectCombo {...props}
                     options={
                         [
                             {label: "Start", value: "left"},
                             {label: "End", value: "right"},
                         ]
                     }
        />
    );
}

export default LabelSelect;