import React from "react";
import { AssetPosition } from "../../openapi/model/assetPosition";
import AssetTypeHumanLabel from "../../components/Asset/AssetTypeHumanLabel";
import LocationIdLink from "../../components/Location/LocationIdLink";
import AssetPositionIdLink from "../../components/AssetPosition/AssetPositionIdLink";
import AssetIdLink from "../../components/Asset/AssetIdLink";
import BulkUpdate from "../../components/BulkUpdate/BulkUpdate";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import { Button } from "reactstrap";
import AssetRegisterStatusLabel from "../../components/Labels/AssetRegisterStatusLabel";
import PaginatedApiToCSVExportButton from "components/utils/CSVExport/PaginatedApiToCSVExportButton";
import GetListPaginatedTable from "../../components/Pagination/GetListPaginatedTable";
import PrivacyMode from "components/Text/PrivacyMode";
import { Status } from "../../openapi/model/status";

interface PProps {
  companyId: string;
  schemeId?: string;
  assetId?: string;
  assetType?: string;
  parentAssetPositionId?: string;
  group?: string;
  useCase?: string;
  status?: Status;
  locationId?: string;
  showLocationId?: boolean;
  lastUpdated?: string;
  showDownload?: boolean;
  showUploads?: boolean;
}

const AssetPositionList = (props: PProps): JSX.Element => {
  const listParams = {
    companyId: props.companyId,
    schemeId: props.schemeId,
    locationId: props.locationId,
    parentAssetPositionId: props.parentAssetPositionId,
    group: props.group,
    status: props.status,
    assetType: props.assetType,
    assetId: props.assetId,
    lastUpdated: props.lastUpdated,
    useCase: props.useCase,
  };

  const showLocationID =
    props.showLocationId !== undefined ? props.showLocationId : true;
  const showParentAssetPositionId = props.parentAssetPositionId === undefined;

  const header = (
    <tr>
      {showLocationID && <th className="text-left">Location ID</th>}
      <th className="text-left">Position ID</th>
      <th className={"text-left"}>Reference</th>
      <th className="text-left">Asset ID</th>
      <th className="text-left">Type</th>
      {showParentAssetPositionId && (
        <th className="text-left">Parent Asset Position Id</th>
      )}
      <th className="text-left">&nbsp;</th>
    </tr>
  );

  const colCount =
    5 + (showLocationID ? 1 : 0) + (showParentAssetPositionId ? 1 : 0);

  const row = (value: AssetPosition): JSX.Element => (
    <tr data-cy={"list-item"} key={value.assetPositionId}>
      {showLocationID && (
        <td className="text-left" data-cy={"locationId"}>
          <LocationIdLink locationId={value.locationId} />
        </td>
      )}
      <td className="text-left" data-cy={"assetPositionId"}>
        <AssetPositionIdLink assetPosition={value} />
      </td>
      <td className={"text-left"}>
        <PrivacyMode>{value.customerReference}</PrivacyMode>
      </td>
      <td className="text-left">
        {value.assetId && <AssetIdLink assetId={value.assetId} />}
      </td>
      <td className="text-left">
        <AssetTypeHumanLabel asset={value} />
      </td>
      {showParentAssetPositionId && (
        <td className="text-left">
          {value.parentAssetPositionId && (
            <AssetPositionIdLink
              assetPositionId={value.parentAssetPositionId}
            />
          )}
        </td>
      )}
      <td className={"text-left"}>
        <AssetRegisterStatusLabel status={value.status} />
      </td>
    </tr>
  );

  return (
    <GetListPaginatedTable
      entityType={"ASSET_POSITION"}
      params={listParams}
      cursor={{ maxItems: 10 }}
      data-cy={"asset-position-list"}
      header={[header, colCount]}
      row={row}
      additionalFunctionButtons={
        <>
          <PaginatedApiToCSVExportButton
            entityType={"ASSET_POSITION"}
            params={listParams}
          />
          {props.schemeId &&
            (props.showUploads === undefined || props.showUploads) && (
              <>
                <BulkUpdate
                  companyId={props.companyId}
                  schemeId={props.schemeId}
                  objectType={"asset_position"}
                />
                <CompanyNavLink
                  to={"/admin/asset_position/updates"}
                  tag={Button}
                  className={"d-inline-block d-md-none"}
                >
                  Previous Uploads
                </CompanyNavLink>
              </>
            )}
        </>
      }
    />
  );
};

export default AssetPositionList;
