import { User } from "../openapi/model/user";

export const isUserSuperAdmin = (user?: User): boolean => {
  return user?.userType === "superadmin";
};
export const isUserPartnerAdmin = (user?: User): boolean => {
  if (user) {
    return user.companies.some(
      (c) =>
        c.companyType === "PARTNER" &&
        userHasTypeForCompany(user, c.companyId, "admin")
    );
  }
  return false;
};
export const userHasTypeForCompany = (
  user: User,
  companyId: string,
  userTypes: string | string[]
): boolean => {
  const userCompany = user.companies?.filter((userCompany) => {
    return userCompany.companyId === companyId;
  });
  if (userCompany === undefined || userCompany.length !== 1) {
    throw Error("user company can not be undefined or have length !== 1");
  }

  if (companyId === "NONE") {
    return isUserPartnerAdmin(user) || isUserSuperAdmin(user);
  }

  return (
    (userCompany &&
      userCompany?.length === 1 &&
      userTypes.includes(userCompany[0].userType)) ||
    false
  );
};
