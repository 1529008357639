import React from "react";
import { TariffServiceDescription } from "./model";
import TextFormComponent from "../../components/Forms/TextFormComponent";
import { Button } from "reactstrap";

function NewTariffInstanceRatesForm(props: {
  services: TariffServiceDescription[];
}) {
  return (
    <tr>
      <td>
        <TextFormComponent fieldName={"startDate"} paddingX={0} paddingY={0} />
      </td>
      {props.services.map((v, i) => (
        <>
          <td width={400}>
            <TextFormComponent
              fieldName={`rates.${i}.unitConsumptionRate`}
              paddingY={0}
              paddingX={0}
            />
          </td>
          <td>
            <TextFormComponent
              fieldName={`rates.${i}.unitConsumptionVatRate`}
              paddingY={0}
              paddingX={0}
            />
          </td>
          <td>
            <TextFormComponent
              fieldName={`rates.${i}.standingChargeRate`}
              paddingY={0}
              paddingX={0}
            />
          </td>
          <td>
            <TextFormComponent
              fieldName={`rates.${i}.standingChargeVatRate`}
              paddingY={0}
              paddingX={0}
            />
          </td>
        </>
      ))}
      <td>
        <Button type={"submit"} color={"primary"} className={"btn-sm"}>
          Add Instance
        </Button>
      </td>
    </tr>
  );
}

export default NewTariffInstanceRatesForm;
