import {Form, Formik} from 'formik';
import React from 'react';
import * as Yup from "yup";
import {FormikHelpers} from "formik/dist/types";
import NewButton from "components/Forms/NewButton/NewButton";
import {ButtonColor} from "@psd-platform/monday-ui-react-core/dist/types/components/Button/ButtonConstants";
import {isEqual} from "lodash";
import {Col, Row} from "reactstrap";
import NewDateRangePickerField from "components/Forms/NewDateRangePickerField/NewDateRangePickerField";
import NewDateRangePicker from "components/Forms/NewDateRangePicker/NewDateRangePicker";
import {Moment} from "moment";
import {AssetUseCase} from "../../../openapi/model/assetUseCase";
import NewAssetUseCaseSelect from 'components/Asset/NewAssetUseCaseSelect';
import SelectComboField from "../../../components/Forms/SelectComboField";
import NewLabelWrapper from "../../../components/Forms/NewLabelWrapper";
import NewLocationGroupSelect from 'components/LocationGroup/NewLocationGroupSelect';


export interface HeatNetworkReportQueryFormValues {
    startDate: Moment
    endDate: Moment
    useCases: AssetUseCase[];
    group?: string;
}


export interface HeatNetworkReportQueryFormProps {
    onSubmit: (values: HeatNetworkReportQueryFormValues) => Promise<any>,
    initialValues?: HeatNetworkReportQueryFormValues,
    dateOnly?: boolean
}


function HeatNetworkReportQueryForm(props: HeatNetworkReportQueryFormProps) {
    const {
        initialValues = {} as HeatNetworkReportQueryFormValues,
        onSubmit,
    } = props;
    const wide = true;
    const showErrors = true;
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({})}
            onSubmit={(values: HeatNetworkReportQueryFormValues, helpers: FormikHelpers<HeatNetworkReportQueryFormValues>): Promise<any> => {
                helpers.setSubmitting(true)
                return onSubmit(values).finally(() => helpers.setSubmitting(false))

            }}>
            {({values, submitForm}): JSX.Element => {
                const isChanged = !isEqual(values, initialValues)


                return (
                    <Form>
                        <Row noGutters={true}>
                            <Col xs={12} md={wide ? props.dateOnly ? 9 :3 : 12}>
                                <NewDateRangePickerField startDateFieldName={'startDate'}
                                                         endDateFieldName={'endDate'}>
                                    {(fieldProps) => <NewDateRangePicker
                                        label={{label: 'Date range', size: 'sm', showErrors}}
                                        numberOfMonths={2}
                                        {...fieldProps}/>}
                                </NewDateRangePickerField>
                            </Col>

                            {!props.dateOnly && <><Col xs={12} md={wide ? 3 : 12}>
                                <SelectComboField name={'useCases'}>
                                    {(fieldProps) => <NewAssetUseCaseSelect
                                        label={{label: 'Asset Use Cases', size: 'sm', showErrors}}
                                        isMulti={true}
                                        {...fieldProps}/>}
                                </SelectComboField>
                            </Col>
                            <Col xs={12} md={wide ? 3 : 12}>
                                <SelectComboField name={'group'}>
                                    {(fieldProps) => <NewLocationGroupSelect
                                        label={{label: 'Group', size: 'sm', showErrors}}
                                        {...fieldProps}/>}
                                </SelectComboField>
                        </Col></>}
                            <Col xs={12} md={wide ? 3 : 12}>
                                <NewLabelWrapper
                                size={'sm'} showErrors={true} label={'\u00A0'}>
                                    <NewButton color={'primary' as ButtonColor}
                                               className={'w-100'}
                                               onClick={submitForm}
                                               disabled={!isChanged}
                                    >Search</NewButton>

                                </NewLabelWrapper>
                            </Col>
                        </Row>
                    </Form>
                )
            }}
        </Formik>


    );
}

export default HeatNetworkReportQueryForm;