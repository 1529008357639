import { AssetPosition } from "openapi/model/assetPosition";
import React from "react";
import AssetRegisterList from "../../assetRegister/AssetRegisterList";
import GatewayConnectivityReportDataRow, {
  GatewayConnectivityDataColumnHeader,
} from "./GatewayConnectivityReportDataRow";
import { AssetRegisterItem } from "../../../openapi/model/assetRegisterItem";
import WirelessClusterGatewayAssignButton from "../../wirelessCluster/WirelessClusterGatewayAssignButton";
import WirelessClusterGatewayLeaveButton from "../../wirelessCluster/WirelessClusterGatewayLeaveButton";
import DisplayIfUserType from "components/utils/DisplayIfUserType/DisplayIfUserType";
import { AssetRegisterListHeading } from "../../assetRegister/AssetRegisterListItem";
import AssetRegisterItemContainer from "../AssetRegisterItemContainer";
import GatewayConfigurationDropdown from "./GatewayConfigurationDropdown";
import { GatewayAsset } from "../../../openapi/model/gatewayAsset";
import useUserHasRoles from "../../../hooks/user/getCurrentUserRole";
import _ from "lodash";

export default function GatewayConnectivityReport(props: {
  companyId: string;
  schemeId?: string;
  parentAssetPositionId?: string;
  clusterForAssignment?: AssetPosition;
  additionalFunctionButtons?: JSX.Element;
}): JSX.Element {
  const isAdminUser = useUserHasRoles("admin");
  const { clusterForAssignment, ...rest } = props;
  return (
    <AssetRegisterList
      {...rest}
      row={GatewayConnectivityReportDataRowWithClusterAssignment(
        clusterForAssignment
      )}
      header={
        <AssetRegisterListHeading
          denseColumn={<GatewayConnectivityDataColumnHeader />}
          showType={false}
          showScheme={props.schemeId === undefined}
          actionButtons={!_.isUndefined(clusterForAssignment) || isAdminUser}
        />
      }
      assetType={"gateway"}
      createAssetTags={false}
    />
  );
}

function GatewayConnectivityReportDataRowWithClusterAssignment(
  clusterForAssignment?: AssetPosition
) {
  const GatewayConnectivityReportDataRowWithClusterAssignmentInner = (props: {
    assetRegisterItem: AssetRegisterItem;
    showScheme?: boolean;
    refresh?: () => void;
    index: number;
  }) => {
    const { assetRegisterItem, ...rest } = props;
    return (
      <AssetRegisterItemContainer assetRegisterItem={assetRegisterItem}>
        {({ assetRegisterItem, refresh }) => {
          return (
            <>
              {clusterForAssignment ? (
                <GatewayConnectivityReportDataRow
                  assetRegisterItem={assetRegisterItem}
                  {...rest}
                  actionButtons={({ assetRegisterItem }) => (
                    <>
                      {clusterForAssignment &&
                      assetRegisterItem.assetPosition.parentAssetPositionId !==
                        clusterForAssignment.assetPositionId ? (
                        <DisplayIfUserType userTypes={"admin"}>
                          <WirelessClusterGatewayAssignButton
                            clusterPosition={clusterForAssignment}
                            gatewayPosition={assetRegisterItem.assetPosition}
                            refresh={props.refresh}
                          />
                        </DisplayIfUserType>
                      ) : (
                        <DisplayIfUserType userTypes={"admin"}>
                          <WirelessClusterGatewayLeaveButton
                            gatewayPosition={assetRegisterItem.assetPosition}
                            refresh={props.refresh}
                          />
                        </DisplayIfUserType>
                      )}
                    </>
                  )}
                />
              ) : (
                <GatewayConnectivityReportDataRow
                  assetRegisterItem={assetRegisterItem}
                  {...rest}
                  actionButtons={({ assetRegisterItem }) => (
                    <>
                      {assetRegisterItem.asset && (
                        <GatewayConfigurationDropdown
                          size={"sm"}
                          color={"primary"}
                          direction={"end"}
                          gateway={assetRegisterItem.asset as GatewayAsset}
                          refresh={refresh}
                        />
                      )}
                    </>
                  )}
                />
              )}
            </>
          );
        }}
      </AssetRegisterItemContainer>
    );
  };
  return GatewayConnectivityReportDataRowWithClusterAssignmentInner;
}
