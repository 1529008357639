import React from "react";
import useCreateEntity from "../../hooks/createEntity";
import Action from "../../core/action/Action";
import Spinner from "../../core/action/Spinner";
import { FaPlay } from "react-icons/fa";

export default function ExecuteBulkUpdateButton(props: {
  updateId: string;
  schemeId: string;
  companyId: string;
  refresh?: () => any;
}): JSX.Element {
  const { createEntity, creating } = useCreateEntity(
    "UPDATE",
    `bulk/${props.updateId}`
  );

  return (
    <Action
      onClick={() => {
        createEntity({
          companyId: props.companyId,
          schemeId: props.schemeId,
        }).then(() => {
          props.refresh && props.refresh();
        });
      }}
      buttonProps={{ color: "primary" }}
    >
      <Spinner alt={FaPlay} spin={creating} className={"mr-2"} />
      Execute
    </Action>
  );
}
