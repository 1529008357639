import React, { useCallback, useContext, useEffect, useState } from "react";

import "./Login.css";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { Button, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { parseHash } from "../../components/navigation/URL";
import * as H from "history";
import { AppContext } from "../../reducers/reactStore";
import PageSpinner from "components/Spinners/PageSpinner";
import LoginErrorBanner from "./LoginErrorBanner";
import { AuthContext } from "../../reducers/auth";
import * as Sentry from "@sentry/react";
import { currentUser, signIn, signOut } from "../../actions/helpers/auth";
import { StandardApiService } from "../../actions/helpers/apiService";
import { ProfileContext } from "../../reducers/profile";

interface FormValues {
  email: string;
  password: string;
}

export const selectPropertyFromLocationHash = (
  param: string,
  location: H.Location
): string | undefined => {
  if (location.hash) {
    const params = parseHash(location.hash);
    if (params[param]) {
      if (params[param] === "undefined" || params[param] === "null") {
        return undefined;
      }
      return params[param];
    }
  }
  return undefined;
};

export default function Login(): JSX.Element {
  const navigate = useNavigate();
  const [silentAuthStatus, setSilentAuthStatus] = useState(true);

  const { state } = useContext(AppContext);

  const authContext = useContext(AuthContext);
  const profileContext = useContext(ProfileContext);
  const { dispatch } = authContext;

  const handleSuccessfulAuthentication = useCallback(
    () => {
      const referrer = state.referer.path;
      if (
        referrer &&
        referrer.pathname !== "/" &&
        referrer.pathname !== "/auth/reset-password"
      ) {
        navigate(referrer);
      } else {
        navigate("/admin");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.referer.path]
  );

  const silentAuth = (): Promise<any> => {
    dispatch({ type: "AUTHENTICATING" });
    return currentUser()
      .then(() => {
        return loadProfile()
          .then(() => {
            dispatch({ type: "AUTHENTICATING_SUCCESS" });
          })
          .catch((err) => {
            signOut().finally(() => {
              dispatch({ type: "AUTHENTICATING_ERROR", payload: err }); // no actual error here, but the auth state needs resetting
            });
            throw err;
          });
      })
      .catch((err) => {
        dispatch({ type: "AUTHENTICATING_ERROR", payload: null }); // no actual error here, but the auth state needs resetting
        throw err;
      });
  };

  const loadProfile = (): Promise<any> => {
    return new StandardApiService()
      .get("/user/profile", {})
      .then((resp) => {
        Sentry.setUser({ email: resp.data.emailAddress });
        profileContext.dispatch({
          type: "PROFILE_FETCHING_SUCCESS",
          payload: resp.data,
        });
      })
      .catch((err: Error) => {
        profileContext.dispatch({
          type: "PROFILE_FETCHING_ERROR",
          payload: err,
        });
        throw err;
      });
  };
  const authenticate = (email: string, password: string): Promise<any> => {
    dispatch({ type: "AUTHENTICATING" });
    Sentry.setUser({ email: email });
    return new Promise<void>((resolve, reject) => {
      signIn(email, password)
        .then(() => {
          loadProfile().then(() => {
            dispatch({ type: "AUTHENTICATING_SUCCESS" });
            resolve();
          });
        })
        .catch((err: Error) => {
          dispatch({ type: "AUTHENTICATING_ERROR", payload: err });
          reject();
        });
    });
  };

  const submit = (
    { email, password }: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ): void => {
    setSubmitting(true);
    authenticate(email, password)
      .then(handleSuccessfulAuthentication)
      .catch(() => {
        setSubmitting(false);
      });
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const sa = useCallback(
    silentAuth,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      sa()
        .then(handleSuccessfulAuthentication)
        .catch(() => {
          setSilentAuthStatus(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sa]
  );

  return (
    <PageSpinner spin={authContext.state.authenticating}>
      {silentAuthStatus ? (
        <div style={{ minHeight: "400px" }} />
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={Yup.object().shape({
              email: Yup.string().required("Required."),
              password: Yup.string().required("Required."),
            })}
          >
            {({ errors, touched, isValid, isSubmitting }): JSX.Element => (
              <Form>
                <div className="form-row">
                  <Col className="mb-3" md="12">
                    <label className="form-control-label" htmlFor="loginEmail">
                      Email address
                    </label>
                    <Field
                      aria-describedby="inputGroupPrepend"
                      id="loginEmail"
                      type="text"
                      name="email"
                      className={
                        "form-control " +
                        (errors.email && touched.email ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="invalid-feedback"
                    />
                  </Col>
                  <Col className="mb-3" md="12">
                    <label
                      className="form-control-label"
                      htmlFor="loginPassword"
                    >
                      Password
                    </label>
                    <Field
                      aria-describedby="inputGroupPrepend"
                      id="loginPassword"
                      type="password"
                      name="password"
                      className={
                        "form-control " +
                        (errors.password && touched.password
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="invalid-feedback"
                    />
                  </Col>
                  <Col xs={"auto mr-auto"}>
                    <a href={"/auth/reset-password"}>Forgotten password?</a>
                  </Col>
                  <Col className={"mb-3 text-right"} xs={"auto"}>
                    <Button
                      color="primary"
                      disabled={!isValid || isSubmitting}
                      type="submit"
                    >
                      Login
                    </Button>
                  </Col>
                </div>
              </Form>
            )}
          </Formik>
          <LoginErrorBanner authError={authContext.state.authError} />
        </>
      )}
    </PageSpinner>
  );
}
