import React from 'react';
import {GatewayAsset} from "../../../openapi/model/gatewayAsset";
import {Form, Formik} from "formik";
import {Button, Col, Row} from "reactstrap";
import NewTextFormComponent from "../../../components/Forms/NewTextFormComponent";
import {BasicAuthCredentials} from "../../../openapi/model/basicAuthCredentials";
import {PublicKeyCredentials} from "../../../openapi/model/publicKeyCredentials";
import useUpdateEntity from "../../../hooks/updateEntity";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import {ErrorBanner} from "../../../components/Error/ErrorBanner";
import NewRadioButtonGroup from "../../../components/Forms/NewRadioButtonGroup";
import {appConfig} from "../../../Config";


interface connectivitySettings {
    postUrl: string;
    sftpServer: string;
    username: string;
    password?: string;
    authenticationType: 'basic_auth' | 'public_key';
    publicKey?: string;
}

function isCredentialsBasic(credentials: BasicAuthCredentials | PublicKeyCredentials): credentials is BasicAuthCredentials {
    return (credentials as BasicAuthCredentials).type === 'basic_auth'
}

function isCredentialsPublicKey(credentials: BasicAuthCredentials | PublicKeyCredentials): credentials is PublicKeyCredentials {
    return (credentials as PublicKeyCredentials).type === 'public_key'
}

function GatewayConnectionDisplay(props: { gateway: GatewayAsset }) {
    const {gateway} = props;

    const {updating, updateEntity, error} = useUpdateEntity("ASSET", gateway.assetId)

    const isCMe2100 = gateway.model && gateway.model.indexOf('21') !== -1

    const initialValues: connectivitySettings = {
        postUrl: (isCMe2100 ? `${appConfig().hes.cme2100}${gateway.companyId}` : `${appConfig().hes.cme}/${gateway.companyId}`),
        sftpServer: (window.runConfig && window.runConfig.sftpServer) || 'unknown',
        username: gateway.credentials.username || '',
        password: isCredentialsBasic(gateway.credentials) ? gateway.credentials.password : '',
        publicKey: isCredentialsPublicKey(gateway.credentials) ? gateway.credentials.publicKey : '',
        authenticationType: gateway.credentials.type as 'basic_auth' | 'public_key'
    }

    const canSwitch: boolean = gateway.manufacturer === 'evinox';

    const submit = (formValues: connectivitySettings) => {
        updateEntity({
            ...gateway,
            credentials: {...gateway.credentials, type: formValues.authenticationType, publicKey: formValues.publicKey, password: formValues.password},
        })
    }
    return (
        <>
            <h3>Gateway Connectivity Settings</h3>

            <Formik initialValues={initialValues} onSubmit={submit}>{({values}) => {
                const basicAuth: boolean = values.authenticationType ==='basic_auth';

                return <Form>
                    <BlockSpinner loading={updating}>
                        <Row>

                            <Col xs={12}>
                                {
                                    canSwitch && <> 
                                        <NewRadioButtonGroup fieldName={'authenticationType'} title={'Credentials type'}
                                                             options={
                                                                 [{
                                                                     value: 'basic_auth',
                                                                     name: 'Basic'
                                                                 }, {value: 'public_key', name: 'Key pair'}]
                                                             } paddingX={0} submitSpinner={false}
                                        />
                                    </>
                                }
                                {gateway.manufacturer === 'elvaco' ? <NewTextFormComponent fieldName={'postUrl'} label={'Post URL'}
                                                                   staticValue disabled clipboard/> :
                                    <NewTextFormComponent fieldName={'sftpServer'} label={'SFTP Server'}
                                                          staticValue disabled clipboard/>
                                }
                                <NewTextFormComponent fieldName={'username'} label={'Username'}
                                                      staticValue disabled clipboard/>
                                {
                                    basicAuth ?
                                        <NewTextFormComponent fieldName={'password'} label={'Password'}
                                                              staticValue disabled clipboard/>
                                        :
                                        <NewTextFormComponent fieldName={'publicKey'} label={'SSH Public Key'}/>
                                }
                            </Col>
                        </Row>
                        {
                            (gateway.manufacturer === 'evinox') && <Row>
                                <Col xs={12}>
                                              <span className={"float-right"}>

                                <Button type={'submit'} color={'primary'} disabled={updating}>Update SSH Settings</Button>
                                              </span> </Col>
                                <ErrorBanner error={error}/>
                            </Row>
                        }
                    </BlockSpinner>
                </Form>
            }
            }
            </Formik>
        </>
    );
}

export default GatewayConnectionDisplay;