import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
  ResponseType,
} from "axios";
import Config from "../../Config";
import * as _ from "lodash";
import { getHeaders as defaultGetHeaders, GetResponse } from "./api";
import * as Sentry from "@sentry/react";

export function objectKeysToCamelCase(
  snakeCaseObject: { [key: string]: any },
  skipKeys: string[] = [],
  snakeCaseOnly = false
): { [key: string]: any } {
  const camelCaseObject: { [key: string]: any } = {};
  _.forEach(snakeCaseObject, function (value, key) {
    if (_.isArray(value)) {
      value = value.map((o) => {
        if (_.isPlainObject(o)) {
          return objectKeysToCamelCase(o, skipKeys, snakeCaseOnly);
        } else {
          return o;
        }
      });
    } else if (_.isPlainObject(value)) {
      if (!(skipKeys && key in skipKeys)) {
        value = objectKeysToCamelCase(value, skipKeys, snakeCaseOnly); // recursively update keys of any values that are also objects
      }
    }
    let newKey = key;
    if (!snakeCaseOnly || _.snakeCase(key) === key) {
      newKey = _.camelCase(key);
    }
    camelCaseObject[newKey] = value;
  });
  return camelCaseObject;
}

export function objectKeysToSnakeCase(camelCaseObject: {
  [key: string]: any;
}): { [key: string]: any } {
  const snakeCaseObject: { [key: string]: any } = {};
  if (!(_.isString(camelCaseObject) || _.isNumber(camelCaseObject))) {
    _.forEach(camelCaseObject, function (value, key) {
      if (_.isArray(value)) {
        value = value.map((o) => {
          return objectKeysToSnakeCase(o);
        });
      } else if (_.isPlainObject(value) && !_.isString(value)) {
        value = objectKeysToSnakeCase(value); // recursively update keys of any values that are also objects
      }
      snakeCaseObject[_.snakeCase(key)] = value;
    });
    return snakeCaseObject;
  } else {
    return camelCaseObject;
  }
}

export function arrayValuesToCamelCase(snakeCaseArray: string[]): string[] {
  const camelCaseArray: string[] = [];
  _.forEach(snakeCaseArray, function (value: string) {
    camelCaseArray.push(_.camelCase(value));
  });
  return camelCaseArray;
}

export interface ApiService extends Object {
  get: (
    path: string,
    params: { [key: string]: string },
    convertKeys: boolean,
    binary: boolean,
    skipConvertKeys: string[],
    convertSnakeCaseOnly: boolean,
    cancel?: CancelTokenSource
  ) => Promise<GetResponse>;
  post: (
    path: string,
    body: any,
    convertSnakeCaseOnly: boolean
  ) => Promise<any>;
  del: (path: string) => Promise<any>;
  put: (path: string, body: any) => Promise<any>;
}

export interface HttpService {
  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R>;

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R>;

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R>;

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R>;
}

export interface RequestHeaders {
  traceId?: string;
  spanId?: string;
  eTag?: string;
}

export class StandardApiService implements ApiService {
  getHeaders: (request?: RequestHeaders) => Promise<{ [key: string]: string }>;
  private http: HttpService;

  constructor(
    http: HttpService = axios,
    getHeaders: (
      request?: RequestHeaders
    ) => Promise<{ [key: string]: string }> = defaultGetHeaders
  ) {
    this.http = http;
    this.getHeaders = getHeaders;
  }

  get = async (
    path: string,
    params: { [key: string]: string | number | undefined },
    convertKeys = true,
    binary = false,
    skipConvertKeys: string[] = [],
    convertSnakeCaseOnly = false,
    cancel?: CancelTokenSource,
    responseType?: ResponseType
  ): Promise<GetResponse> => {
    const transaction = Sentry.getCurrentHub()?.getScope()?.getTransaction();
    const span = transaction?.startChild({
      op: "get",
      description: `get ${path}`,
    });
    const headers = await this.getHeaders({
      traceId: transaction?.traceId,
      spanId: span?.spanId,
    });

    const p: { [key: string]: string | number } = {};

    for (const [key, value] of Object.entries(params ? params : {})) {
      if (value !== null && value !== undefined) {
        p[key] = value;
      }
    }

    return (
      this.http
        .get(Config.apiGateway.URL + path, {
          headers: headers,
          params: objectKeysToSnakeCase(p),
          responseType: responseType || (binary ? "arraybuffer" : "json"),
          cancelToken: cancel?.token,
          // credentials: 'include'
        })
        // .then(handleErrors)
        .then((res) => {
          span?.finish();
          let data: { [key: string]: any };
          if (convertKeys) {
            if (_.isArray(res.data)) {
              data = res.data.map((d) =>
                objectKeysToCamelCase(d, skipConvertKeys, convertSnakeCaseOnly)
              );
            } else {
              data = objectKeysToCamelCase(
                res.data,
                skipConvertKeys,
                convertSnakeCaseOnly
              );
            }
          } else {
            data = res.data;
          }
          let cursor;
          if (res.headers["x-cursor"]) {
            cursor = objectKeysToCamelCase(JSON.parse(res.headers["x-cursor"]));
          }
          const headers = objectKeysToCamelCase(res.headers);
          return { data, cursor, headers };
        })
        .finally(() => {
          span?.finish();
        })
    );
  };

  post = async (
    path: string,
    body: any,
    convertSnakeCaseOnly = false
  ): Promise<any> => {
    const data = _.isArray(body)
      ? body.map(objectKeysToSnakeCase)
      : objectKeysToSnakeCase(body);

    return (
      this.http
        .post(Config.apiGateway.URL + path, data, {
          headers: await this.getHeaders(),
        })
        // .then(handleErrors)
        .then((res) => {
          return _.isArray(res.data)
            ? res.data.map((x) =>
                objectKeysToCamelCase(x, undefined, convertSnakeCaseOnly)
              )
            : objectKeysToCamelCase(res.data, undefined, convertSnakeCaseOnly);
        })
    );
  };

  del = async (path: string): Promise<any> => {
    return this.http.delete(Config.apiGateway.URL + path, {
      // credentials: 'include',
      headers: await this.getHeaders(),
    });
  };

  put = async (path: string, body: any, eTag?: string): Promise<any> => {
    return this.http.put(
      Config.apiGateway.URL + path,
      objectKeysToSnakeCase(body),
      {
        headers: await this.getHeaders({ eTag }),
      }
    );
  };
}
