import {Option,} from "components/Forms/SelectComponent";
import React, {useContext} from "react";
import {SchemeContext} from "../../reducers/scheme";
import SelectCombo, {SelectComboProps} from "../Forms/SelectCombo/SelectCombo";

type SchemeSelectFormComponentProps = Omit<SelectComboProps, 'options'>
export default function SchemeSelectFormComponent(
    props: SchemeSelectFormComponentProps
): JSX.Element {
    const {state} = useContext(SchemeContext);
    const {loading, schemes = []} = state;
    const ready = !state.loading && !state.error;
    const haveSchemes = schemes && schemes.length > 0;
    const options: Option[] = schemes.map((scheme) => {
        return {
            label: `${scheme.schemeName} (${scheme.schemeId})`,
            value: scheme.schemeId,
        };
    });

    const altText: string = ready
        ? haveSchemes ? "All" : "No Groups"
        : loading
            ? "Loading Schemes"
            : state.error?.message || "Error Loading Groups";

    return <SelectCombo
        disabled={loading}
        options={[...options]}
        {...props}
        placeholder={altText}
    />
}
