import React from "react";
import { Routes } from "react-router-dom";
import SchemeHomepage from "./SchemeHomepage";
import NewSchemeForm from "./NewSchemeForm";
import { SchemesListPage } from "./SchemesListPage";
import { SentryRoute } from "../../App";

const SchemesPage = (): JSX.Element => {
  return (
    <>
      <Routes>
        <SentryRoute path="" element={<SchemesListPage />} />
        <SentryRoute path="create" element={<NewSchemeForm />} />
        <SentryRoute path=":schemeId/*" element={<SchemeHomepage />} />
      </Routes>
    </>
  );
};

export default SchemesPage;
