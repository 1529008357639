import {
  CustomSelectProps,
  Option,
  SelectComponent,
} from "components/Forms/SelectComponent";
import { AssetUseCase } from "openapi/model/assetUseCase";
import React from "react";
import _ from "lodash";

export default function AssetUseCaseSelect(
  props: CustomSelectProps & {
    includeAll?: boolean;
    includeNone?: boolean;
  }
): JSX.Element {

  const options: Option[] = [
    props.includeAll ? { label: "All", value: "undefined" } : undefined,
    props.includeNone ? { label: "None", value: AssetUseCase.NONE } : undefined,
    { label: "Domestic", value: AssetUseCase.DOMESTIC },
    { label: "Commercial", value: AssetUseCase.COMMERCIAL },
    { label: "Process", value: AssetUseCase.PROCESS },
  ].filter((v) => !_.isNil(v)) as Option[];

  return <SelectComponent label={"Use Case"} options={options} {...props} />;
}
