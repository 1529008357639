import React from "react";
import { DeviceAsset } from "../../../../openapi/model/deviceAsset";
import { GatewayAsset } from "../../../../openapi/model/gatewayAsset";
import { AssetTimeSeriesRequestParams } from "../AssetDataSelectionForm";
import {
  DeviceTimeSeriesDisplayComponent,
  DeviceTimeSeriesDisplayComponentProps,
} from "../DeviceTimeSeriesDataContainer";
import useGetData from "../../../../hooks/getData";
import {
  ColumnDefinitionWithData,
  ColumnMap,
  convertTimesToMoment,
} from "../../../../model/deviceTimeSeries";
import { AssetRFTimeSeriesResponse } from "../../../../openapi/model/assetRFTimeSeriesResponse";
import { AssetRegisterDataItemBase } from "../../../../openapi/model/assetRegisterDataItemBase";

export interface AssetRegisterDataRf<T = string | undefined>
  extends AssetRegisterDataItemBase,
    ColumnDefinitionWithData<T> {}

interface Props {
  device: DeviceAsset | GatewayAsset;
  searchParams: AssetTimeSeriesRequestParams;
  render: DeviceTimeSeriesDisplayComponent<
    string | undefined,
    AssetRegisterDataRf
  >;
}

export default function AssetTimeSeriesRFGetter(props: Props): JSX.Element {
  const { device, searchParams } = props;
  const { assetId, companyId, schemeId } = device;

  const [{ data, loading, error }] = useGetData<AssetRFTimeSeriesResponse>({
    path: `data/asset/${assetId}/time_series/rf`,
    companyId,
    schemeId,
    params: {
      startDatetime: searchParams.startDatetime.toISOString(),
      endDatetime: searchParams.endDatetime.toISOString(),
    },
    singletonApi: true,
  });

  const displayProps = (): DeviceTimeSeriesDisplayComponentProps<
    string | undefined,
    AssetRegisterDataRf
  > => {
    if (data) {
      return {
        mappedTimeSeriesColumns: mapRFColumns(data),
        times: convertTimesToMoment(data.times),
        params: searchParams,
        loading: loading,
        error: error ? error : undefined,
      };
    } else {
      return {
        mappedTimeSeriesColumns: {},
        times: [],
        params: searchParams,
        loading: loading,
        error: error ? error : undefined,
      };
    }
  };

  return <>{props.render(displayProps())}</>;
}

export function mapRFColumns(
  data: AssetRFTimeSeriesResponse
): ColumnMap<string | undefined, AssetRegisterDataRf> {
  return data.columns.reduce(
    (result, curr, index) => ({
      ...result,
      [curr.assetPositionId]: {
        ...curr,
        name: "rssi",
        type: "instantaneous",
        unit: "dB",
        data: data.rssiData.map((tsValue) => {
          return tsValue[index];
        }),
      },
    }),
    {}
  );
}
