import React from "react";
import { Card, CardHeader } from "reactstrap";
import PieChart, {
  ColouredPieChartData,
} from "../../../components/charts/PieChart";

import colours from "./PieChartColours.module.scss";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import { PieChartDataItem } from "../../../openapi/model/pieChartDataItem";
import ErrorOverlappingBanner from "../../../components/Error/ErrorOverlappingBanner";
import AssetLatestTimeSeriesStatsGetter from "../AssetTimeLatestTimeSeriesStatsGetter";
import SelectedScheme from "components/Scheme/SelectedScheme";

type Props = { group: string; title: string };

const preprocessData = (data: PieChartDataItem[]): ColouredPieChartData => {
  /* removes 0 data returned by API and adds a color */
  const processedData: ColouredPieChartData = [];
  data.forEach((value) => {
    if (value.count && value.count > 0) {
      let colour = "";
      switch (value.label) {
        case "< 1 hour":
          colour = colours.great;
          break;
        case "< 1 day":
          colour = colours.good;
          break;
        case "1 - 7 days":
          colour = colours.bad;
          break;
        case "7 - 28 days":
          colour = colours.reallyBad;
          break;
        case "> 28 days":
          colour = colours.reallyReallyBad;
          break;
        default:
          colour = colours.awful;
      }
      processedData.push({ ...value, color: colour });
    }
  });
  return processedData;
};

const LatestTimeSeriesStatsWidget = (props: Props) => {
  return (
    <SelectedScheme>
      {(schemeProps) => {
        return (
          <AssetLatestTimeSeriesStatsGetter
            {...schemeProps}
            group={props.group}
            render={({ data, error, loading }) => {
              return (
                <Card>
                  <BlockSpinner loading={loading || false}>
                    <CardHeader className="border-0">
                      <h3 className="mb-0">{props.title}</h3>
                    </CardHeader>
                    <ErrorOverlappingBanner error={error || undefined}>
                      {
                        <PieChart
                          data={data ? preprocessData(data) : []}
                          half={true}
                          donut={true}
                        />
                      }
                    </ErrorOverlappingBanner>
                  </BlockSpinner>
                </Card>
              );
            }}
          ></AssetLatestTimeSeriesStatsGetter>
        );
      }}
    </SelectedScheme>
  );
};

export default LatestTimeSeriesStatsWidget;
