import React from "react";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import { UnassignedGatewayConnectivityReportDataRow } from "./GatewayConnectivityReportDataRow";
import CompanyNavLink from "../../../components/navigation/CompanyNavLink";
import AssetList from "../list/AssetList";
import { Col, Row } from "reactstrap";
import GatewayConnectivityReportHeaderRow from "./GatewayConnectivityReportHeaderRow";
import SelectedScheme from "components/Scheme/SelectedScheme";
import GatewayConnectivityReport from "./GatewayConnectivityReport";

export default function GatewayConnectivityReportPage(props: {
  assigned?: boolean;
}): JSX.Element {
  const { assigned = true } = props;

  return (
    <OneCardPage
      headerTitle={
        assigned ? "Gateway Connectivity" : "Unassigned Gateway Connectivity"
      }
      breadcrumbs={[
        { url: "/admin/report/gateway", name: "Gateway Connectivity" },
      ]}
    >
      <Row>
        <Col xs={12} className={"text-right text-sm"}>
          {assigned ? (
            <CompanyNavLink to={"/admin/report/gateway/unassigned"}>
              Switch to unassigned gateways
            </CompanyNavLink>
          ) : (
            <CompanyNavLink to={"/admin/report/gateway"}>
              Switch to assigned gateways
            </CompanyNavLink>
          )}
        </Col>
        <Col xs={12}>
          {" "}
          {assigned ? (
            <SelectedScheme>
              {(props) => <GatewayConnectivityReport {...props} />}
            </SelectedScheme>
          ) : (
            <SelectedScheme>
              {(props) => (
                <AssetList
                  {...props}
                  assetRow={UnassignedGatewayConnectivityReportDataRow}
                  headerRow={GatewayConnectivityReportHeaderRow(false)(false)}
                  assetType={"gateway"}
                  assetPositionId={"NONE"}
                  sort={"last_contact"}
                />
              )}
            </SelectedScheme>
          )}
        </Col>
      </Row>
    </OneCardPage>
  );
}
