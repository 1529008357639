import React from "react";
import { OneCardPage } from "../utils/OneCardPage";
import * as _ from "lodash";
import { Button } from "reactstrap";
import CompanyNavLink from "../navigation/CompanyNavLink";
import { BulkUpdateSummary } from "../../openapi/model/bulkUpdateSummary";
import { BulkUpdateStatus } from "../../openapi/model/bulkUpdateStatus";

import { formatDate } from "../utils/HighlightNullAndOldDates";
import ApiPaginatedTable from "../Pagination/NewApiPaginatedTable";
import GetList, { PaginatedListResultProps } from "core/action/GetList";

interface Props {
  companyId: string;
  schemeId?: string;
  objectType: string;
}

const BulkUpdateList = (props: Props): JSX.Element => {
  const type_url = _.snakeCase(props.objectType);

  const header = (
    <tr>
      <th scope="col" className="text-left">
        Filename
      </th>
      <th scope="col" className="text-left">
        Status
      </th>
      <th scope="col" className="text-left">
        Created At
      </th>
      <th scope="col" className="text-left">
        Executed At
      </th>
      <th scope="col" className="text-left">
        New
      </th>
      <th scope="col" className="text-left">
        Updates
      </th>
      <th scope="col" className="text-left">
        Unchanged
      </th>
      <th scope="col" className="text-left">
        Invalid
      </th>
      <th scope={"col"}>&nbsp;</th>
    </tr>
  );

  const row = (value: BulkUpdateSummary): JSX.Element => {
    const invalid =
      (value.previewStats.newInvalid ?? 0) +
      (value.previewStats.updateInvalid ?? 0) +
      (value.previewStats.notFound ?? 0);

    return (
      <tr data-cy="updates-list-item">
        <td data-cy="update-filename">{value.filename}</td>
        <td data-cy="update-status">{value.status}</td>
        <td data-cy={"update-created"}>{formatDate(value.createdAt)}</td>
        <td data-cy={"update-executed"}>
          {value.executedAt ? formatDate(value.executedAt) : "N/A"}
        </td>
        {value.status === BulkUpdateStatus.PREVIEWCREATED ? (
          <>
            <td data-cy={"update-new"}>{value.previewStats.newValid}</td>
            <td data-cy={"update-updated"}>{value.previewStats.updateValid}</td>
            <td data-cy={"update-unchanged"}>{value.previewStats.unchanged}</td>
            <td data-cy={"update-invalid"}>{invalid}</td>
          </>
        ) : (
          <>
            <td data-cy={"update-new"}>
              {value.executeStats?.created}/{value.previewStats.newValid}
            </td>
            <td data-cy={"update-updated"}>
              {value.executeStats?.updated}/{value.previewStats.updateValid}
            </td>
            <td data-cy={"update-unchanged"}>{value.previewStats.unchanged}</td>
            <td data-cy={"update-invalid"}>{invalid}</td>
          </>
        )}
        <td data-cy={"update-actions"}>
          <CompanyNavLink
            to={`/admin/${type_url}/updates/${value.updateId}`}
            tag={Button}
          >
            View
          </CompanyNavLink>
        </td>
      </tr>
    );
  };

  const title = _.startCase(`${props.objectType}s`);

  return (
    <GetList
      entityType={"UPDATE"}
      params={{
        companyId: props.companyId,
        schemeId: props.schemeId,
        objectType: props.objectType,
      }}
    >
      {({
        loading,
        error,
        data,
        refresh,
      }: PaginatedListResultProps<BulkUpdateSummary>) => (
        <OneCardPage
          headerName={"Update"}
          headerParentName={title}
          headerTitle={title + " Updates"}
          breadcrumbs={[
            { url: `/admin/${type_url}`, name: title },
            { url: `/admin/${type_url}/update`, name: "Bulk Update" },
          ]}
        >
          <ApiPaginatedTable
            data={data}
            error={error}
            loading={loading}
            refresh={refresh}
            header={[header, 9]}
            row={row}
          />
        </OneCardPage>
      )}
    </GetList>
  );
};

export default BulkUpdateList;
