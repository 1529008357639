import React from 'react';
import {DataPoint, StackedBarChart} from "../../charts/SingleBarChart";
import moment from "moment";
import {DateValueMap, DeliveredEnergyByGroup, UnaccountedEnergyByGroup} from "./model";


interface NetworkEnergyConsumptionBarChartProps {
    deliveredEnergyData: DeliveredEnergyByGroup,
    unaccountedEnergyData: UnaccountedEnergyByGroup,
    unit?: string
}

function mergeDeliveredEnergyByGroupToSingleBar(
    data: DeliveredEnergyByGroup
): DataPoint[] {
    return sumDateValueMaps(Object.values(data).map((d) => Object.values(d).map((d2) => d2)).flat())
}

function sumDateValueMaps(data: DateValueMap[]): DataPoint[] {
    return Object.entries(
        data.reduce((sumData, groupData) => {
            for (const [date, dateSum] of Object.entries(groupData)) {
                if (!sumData[date]) {
                    sumData[date] = 0
                }
                sumData[date] += dateSum
            }
            return sumData
        }, {})
    ).map(([v, d]) => [moment(v).tz("Europe/London"), d])

}

function mergeUnaccountedEnergyByGroupToSingleBar(
    data: UnaccountedEnergyByGroup
): DataPoint[] {
    return sumDateValueMaps(Object.values(data).flat());

}


export default function NetworkEnergyConsumptionBarChart(props: NetworkEnergyConsumptionBarChartProps): React.ReactElement {

    const d = mergeDeliveredEnergyByGroupToSingleBar(props.deliveredEnergyData)
    const d2 = mergeUnaccountedEnergyByGroupToSingleBar(props.unaccountedEnergyData)

    return (
        <>
            <
                StackedBarChart
                data={[d, d2]}
                definition={
                    [
                        {name: "Delivered", unit: props.unit || 'Wh', color: "#15803d"},
                        {name: "Lost", unit: props.unit || 'Wh', color: "#b91c1c"}
                    ]}
                loading={false}
                yAxisTitle={'Energy - Heating'}
            />

        </>

    );
}
