/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// react library that creates nice scrollbar on windows assets
import PerfectScrollbar from "react-perfect-scrollbar";
// reactstrap components
import { Navbar, NavbarBrand } from "reactstrap";
import "./Sidebar.scss";
import logoImage from "assets/img/brand/meterpoint.png";
import SidebarMenu from "./SidebarMenu";

interface Props {
  toggleSidenav: () => void; // function used to make sidenav mini or normal
  // prop to know if the sidenav is mini or normal
  sidenavOpen: boolean;
  // links that will be displayed inside the component
  logo: {
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: string;
    // the image src of the logo
    imgSrc: string;
    // the alt for the img
    imgAlt: string;
  };
}

interface State {
  collapseOpen: boolean;
}

const Sidebar = (props: Props): JSX.Element => {
  const [state, _setState] = useState<State>({
    collapseOpen: false,
  });

  const { toggleSidenav, logo } = props;

  // makes the sidenav normal on hover (actually when mouse enters on it)
  const onMouseEnterSidenav = (): void => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = (): void => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
    if (window.innerWidth < 1400 && state.collapseOpen) {
      toggleSidenav();
    }
  };

  // // toggles collapse between opened and closed (true/false)
  // const toggleCollapse = (): void => {
  //   setState({
  //     ...state,
  //     collapseOpen: !state.collapseOpen,
  //   });
  // };
  // // closes the collapse
  // const closeCollapse = (): void => {
  //   setState({
  //     ...state,
  //     collapseOpen: false,
  //   });
  // };

  // this is used on mobile assets, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = (): void => {
    if (window.innerWidth < 1400 && state.collapseOpen) {
      toggleSidenav();
    }
  };

  const scrollBarInner = (
    <div className="scrollbar-inner">
      <div className="sidenav-header d-flex align-items-center">
        {logo ? (
          <NavbarBrand>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logoImage}
            />
          </NavbarBrand>
        ) : null}
        <div className="ml-auto">
          <div
            className={classnames(
              "sidenav-toggler d-none d-xl-block d-lg-block",
              {
                active: props.sidenavOpen,
              }
            )}
            onClick={toggleSidenav}
          >
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-inner">
        <SidebarMenu closeSidenav={closeSidenav} />
      </div>
    </div>
  );
  return (
    <Navbar
      className="sidenav navbar-vertical fixed-left navbar-expand-xs navbar-dark bg-default"
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
    >
      {navigator.platform.indexOf("Win") > -1 ? (
        <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
      ) : (
        scrollBarInner
      )}
    </Navbar>
  );
};

export default Sidebar;
