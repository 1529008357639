import moment, { Moment } from "moment";
import {isNil, isString} from "lodash";
import { ValueErrors } from "openapi/model/valueErrors";
import { DeviceDataSource } from "openapi/model/deviceDataSource";
import { DataErrors } from "openapi/model/dataErrors";

export interface DeviceTimeSeriesData {
  columns: (ColumnDefinition | string)[];
  data: any[][];
  errors: any[][] | undefined;
  deviceErrors?: Array<Array<string|undefined>>;
  times: Moment[];
}

export interface  ColumnDefinition {
  name: string;
  unit: string;
}

export interface ColumnDefinitionWithData<T = string | undefined>
  extends ColumnDefinition {
  data: T[];
  errors?: ValueErrors[][];
  type: string;
}

export interface TimeSeriesDataParams {
  companyId?: string;
  startDatetime?: Moment;
  endDatetime?: Moment;
  projection?: string;
}

export interface TimeSeriesState {
  data?: DeviceTimeSeriesData;
  loading: boolean;
  error?: Error;
  params: TimeSeriesDataParams;
}

export const extendDataToTimeRange = (
  columns: (string | ColumnDefinition)[],
  data: (string | DeviceDataSource | number | undefined)[][],
  errors: (DataErrors | ValueErrors)[][] | undefined,
  deviceErrors: Array<Array<string|undefined>> | undefined,
  times: Moment[],
  startDatetime?: Moment,
  endDatetime?: Moment
): DeviceTimeSeriesData => {
  let returnTimes = undefined;
  let returnData = undefined;
  let returnErrors = undefined;
  if (startDatetime && endDatetime) {
    if (times.length === 0) {
      returnTimes = [startDatetime, endDatetime];
      returnData = [
        columns.map(() => {
          return undefined;
        }),
        columns.map(() => {
          return undefined;
        }),
      ];
      returnErrors = [
        columns.map(() => {
          return undefined;
        }),
        columns.map(() => {
          return undefined;
        }),
      ];
      return {
        columns,
        data: returnData,
        times: returnTimes,
        errors: returnErrors,
        deviceErrors: undefined,
      };
    }
  }
  return { columns, data, times, errors, deviceErrors };
};

export const convertTimesToMoment = (times: (string | Date)[]): Moment[] => {
  return times.map((t: string | Date): Moment => {
    return moment(t);
  });
};

export type ColumnMap<
  T = string | undefined,
  D extends ColumnDefinitionWithData<T> = ColumnDefinitionWithData<T>
> = {
  [key: string]: D;
};

export function mapColumns<T = string | undefined>(
  data: DeviceTimeSeriesData,
  keyAsNumber = false
): ColumnMap<T> {
  const columnMap: ColumnMap<T> = {};
  data.columns.forEach((column, index) => {
    if (isString(column)) {
      const key = keyAsNumber ? index.toString() : column;
      if (column !== "source") {
        columnMap[key] = {
          name: column,
          unit: "",
          data: data.data.map((tsValue): T => {
            return tsValue[index];
          }),
          type: "instantaneous",
        };
      }
    } else {
      const key = keyAsNumber ? index.toString() : column.name;
      columnMap[key] = {
        ...column,
        data: data.data.map((tsValue): T => {
          return tsValue[index];
        }),
        errors: data.errors?.map((tsValue): ValueErrors[] => {
          return [tsValue[index]];
        }),
        type:
          [
            "Volume",
            "Energy (Heating)",
            "Energy (Cooling)",
            "Energy (Electrical Active Import)",
            "Energy (Electrical Active Export)",
            "Energy (Electrical Reactive Import)",
            "Energy (Electrical Reactive Export)",
          ].indexOf(column.name) === -1
            ? "instantaneous"
            : "cumulative",
      };
    }
  });
  if (data.deviceErrors && ~isNil(data.deviceErrors)) {
    columnMap['Device Errors (Detailed)'] = {
      name: 'Device Errors (Detailed)',
      data:data.deviceErrors.map(
          v => (isNil(v[0]) ? '' :v[0]) as unknown as T
      ),
      type:'errors',
      unit:''
    }
    columnMap['Device Errors (Standard)'] = {
      name: 'Device Errors (Standard)',
      data:data.deviceErrors.map(
          v => (isNil(v[1]) ? '' :v[1]) as unknown as T
      ),
      type:'errors',
      unit:''
    }
  }
  return columnMap;
}
