import { ErrorBanner } from "components/Error/ErrorBanner";
import React, { useState } from "react";
import { Button, Col, ListGroupItem, Row } from "reactstrap";
import useGetList, { PaginatedListResult } from "../../hooks/getList";
import { Event } from "../../openapi/model/event";
import { Note } from "../../openapi/model/note";
import NewApiPaginatedList from "../../components/Pagination/NewApiPaginatedList";
import classNames from "classnames";
import { formatDate } from "components/utils/HighlightNullAndOldDates";
import _ from "lodash";

export default function NoteHistoryAccordian(props: {
  note: Note;
  lastUpdate: Date;
}): React.ReactElement {
  const [visible, setVisible] = useState(false);

  const [{ data, error }, refresh] = useGetList<PaginatedListResult<Event>>(
    "EVENT",
    {
      noteId: props.note.noteId,
      companyId: props.note.companyId,
      schemeId: props.note.schemeId,
    }
  );

  React.useEffect(() => {
    refresh();
  }, [props.lastUpdate, refresh]);

  return (
    <Row>
      <Col xs={12}>
        <Button
          onClick={() => setVisible(!visible)}
          className={"btn-link px-0"}
        >
          <h5 className={"mb-0"}>
            <i
              className={classNames("mr-1 fa fa-fw", {
                "fa-caret-right": !visible,
                "fa-caret-down": visible,
              })}
            />
            History (
            {data ? (
              data.data.length
            ) : (
              <i className={"ml-0 fa fa-fw fa-spin fa-spinner"} />
            )}{" "}
            {data ? (data.data.length === 1 ? "entry" : "entries") : "entries"}
            ).
          </h5>
        </Button>
      </Col>
      {visible && (
        <Col xs={12}>
          <NewApiPaginatedList
            data={data}
            row={(item: Event, index: number): React.ReactElement => {
              return (
                <ListGroupItem key={index} className={"py-1 border-0"}>
                  {item.eventType} on {formatDate(item.eventDateTime)} by{" "}
                  {item.user.userName}
                </ListGroupItem>
              );
            }}
            showPagination={
              !_.isNil(data?.cursor.after) || !_.isNil(data?.cursor.before)
            }
          />
          {error && <ErrorBanner error={error} />}
        </Col>
      )}
    </Row>
  );
}
