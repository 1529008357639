import React from "react";

export default function ErrorTriangle(props: {
  error?: Error | Error[] | null;
}): JSX.Element {
  return (
    <>
      {props.error && <span className={"fa fa-fw fa-triangle-exclamation"} />}
    </>
  );
}
