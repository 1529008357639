import React from "react";
import { Asset } from "../../../openapi/model/asset";
import CreateActionButton from "../../assetActions/CreateActionButton";
import DisplayIfUserType from "../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import AssetContainer, { OptionalAssetChildProps } from "../AssetContainer";
import AssetIdLink from "../../../components/Asset/AssetIdLink";
import GatewayConfigurationDropdown from "../gateway/GatewayConfigurationDropdown";
import { GatewayAsset } from "../../../openapi/model/gatewayAsset";
import AssetStateLabel from "../AssetStateLabel";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import { Button } from "reactstrap";
import _ from "lodash";

const SimCardListContent = (props: {
  asset: Asset;
  refresh: () => any;
}): JSX.Element => {
  if (props.asset.assetType !== "sim_card") {
    throw new Error("Unsupported asset type");
  }
  if (
    props.asset.parentAssetId === undefined ||
    props.asset.parentAssetId === null
  ) {
    return (
      <DisplayIfUserType userTypes={"superadmin"}>
        <CreateActionButton
          asset={props.asset}
          actionType={"CREATE_ELVACO_2100"}
          actionName={"Create New Gateway"}
          onComplete={props.refresh}
        />
      </DisplayIfUserType>
    );
  } else {
    return (
      <AssetContainer
        assetId={props.asset.parentAssetId}
        companyId={props.asset.companyId}
      >
        {({ asset, loading, refresh }: OptionalAssetChildProps) => (
          <BlockSpinner loading={loading}>
            <div>
              <small>
                <span className={"font-weight-bold"}>Assigned Gateway</span>
                <AssetIdLink
                  assetId={asset?.assetId}
                  placeholder={_.isUndefined(asset)}
                />{" "}
                {asset?.serialNumber || "Serial Number"}
              </small>
            </div>
            <div>
              <AssetStateLabel asset={asset} size={"sm"} />
            </div>
            <div>
              {asset ? (
                <GatewayConfigurationDropdown
                  gateway={asset as GatewayAsset}
                  size={"sm"}
                  refresh={refresh}
                  direction={"left"}
                />
              ) : (
                <Button disabled={true} color={"primary"}>
                  Configuration
                </Button>
              )}
            </div>
          </BlockSpinner>
        )}
      </AssetContainer>
    );
  }
};
export default SimCardListContent;
