import React from "react";
import { DeviceTimeSeriesDisplayComponentProps } from "../DeviceTimeSeriesDataContainer";
import { AssetRegisterDataRf } from "./AssetTimeSeriesRFGetter";
import SimpleLineChart from "../../../../components/charts/SimpleLineChart";
import BlockSpinner from "../../../../components/Spinners/BlockSpinner";

export default function AssetRFChart(
  props: DeviceTimeSeriesDisplayComponentProps<
    string | undefined,
    AssetRegisterDataRf
  >
): JSX.Element {
  return (
      <BlockSpinner loading={props.loading}>
        <SimpleLineChart
          multipleData={Object.values(props.mappedTimeSeriesColumns).map(
            (v) => [
              v.assetPositionId,
              v.data.map((d, i) => [props.times[i], d as unknown as number]),
              v.assetId,
              undefined,
            ]
          )}
          unit={'dB'}
          params={props.params}
          loading={false}
        />
      </BlockSpinner>
  );
}
