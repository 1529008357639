import React, {useState} from 'react';
import {Clickable, DatePicker, Icon, TextField} from "@psd-platform/monday-ui-react-core";
import {Calendar} from '@psd-platform/monday-ui-react-core/icons';
import moment, {isMoment, Moment} from 'moment';
import {RangeDate} from "@psd-platform/monday-ui-react-core/src/components/DatePicker/types";
import NewLabelWrapper, {LabelProps} from "../NewLabelWrapper";

import cx from "classnames";

import styles from './NewDateRangePicker.module.scss'
import DatePickerModal from "../DatePickerModal/DatePickerModal";
import {isUndefined} from "lodash";
import {DateRange} from "./model";
import {Preset} from "../DateRangePicker/DateRangePickerPresets";
import {DEFAULT_PRESETS} from "./NewDateRangePickerPresets";


interface NewDateRangePickerProps extends React.ComponentProps<typeof DatePicker> {
    label: LabelProps,
    onChange: (value: DateRange) => void,
    disabled?: boolean;
    value?: DateRange,
    error?: string,
    touched?: boolean,
    presets?: Preset[];
}

function NewDateRangePicker(props: NewDateRangePickerProps) {


    const {disabled, value, error, touched, presets=DEFAULT_PRESETS, ...rest} = props

    const [startDate, setStartDate] = useState<Moment | undefined>(value?.startDate);
    const [endDate, setEndDate] = useState<Moment | undefined>(value?.endDate);

    const [priorState, setPriorState] = useState<[Moment | undefined, Moment | undefined] | undefined>(undefined)

    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const open = () => {
        setPriorState([startDate, endDate])
        setModalOpen(true)
    }
    const close = () => {
        if ((isUndefined(startDate) || isUndefined(endDate)) && priorState) {
            setStartDate(priorState[0])
            setEndDate(priorState[1])
        } else {
            props.onChange({startDate, endDate})
        }
        setPriorState(undefined)
        setModalOpen(false)
    }

    const updateStartAndEndDate = (
        (date: Moment | RangeDate) => {
            if (isMoment(date)) {
                throw new Error('Expecting a date range')
            } else {
                setStartDate(date.startDate?.startOf('day') || undefined)
                setEndDate(date.endDate?.endOf('day') || undefined)
            }
        }
    )

    return (
        <>
            <NewLabelWrapper {...props.label} error={touched ? error : undefined}>
                <div className={'mx-2'}>

                    <div
                        className={cx(styles.textField, {
                            [styles.disabled]: disabled,
                            [styles.iconContainerHasIcon]: true,
                        })}
                    >
                        <div className={cx(styles.labelWrapper)}>
                            <div className={cx(styles.inputWrapper,  {
                                [styles.focus]: modalOpen,
                            },styles.wrapperSizeMedium,)}
                                 onClick={open}>
                                <input
                                    className={cx(styles.input, {
                                        [styles.readOnly]: false,
                                        'text-center': true,
                                    })}
                                    value={startDate?.format('yyyy-MM-DD') || ''}
                                    onChange={() => {
                                        return
                                    }}
                                    placeholder={'From'}
                                    disabled={disabled}
                                    readOnly={true}
                                    type={TextField.types.DATE}
                                />
                                <span>&rarr;</span>
                                <input
                                    className={cx(styles.input, {
                                        [styles.readOnly]: false,
                                        'text-center': true,

                                    })}
                                    onChange={() => {
                                        return
                                    }}
                                    value={endDate?.format('yyyy-MM-DD') || ''}
                                    placeholder={'To'}
                                    disabled={disabled}
                                    type={TextField.types.DATE}
                                />
                                <DatePickerModal
                                    isOpen={modalOpen}
                                    close={close}
                                    range={true}
                                    date={startDate}
                                    endDate={endDate}
                                    onPickDate={updateStartAndEndDate}
                                    presets={presets}
                                    maxDate={moment().endOf('day').subtract(1,'day')}
                                    {...rest}
                                >
                                    <Clickable
                                        className={cx(styles.iconContainer, {
                                            [styles.iconContainerHasIcon]: true,
                                            [styles.iconContainerClickable]: true,
                                            [styles.iconContainerActive]: true
                                        })}
                                        onClick={open}
                                    >
                                        <Icon
                                            icon={Calendar}
                                            className={cx(styles.icon)}
                                            clickable={true}
                                            iconType={Icon.type.ICON_FONT}
                                            ignoreFocusStyle
                                            iconSize={"18px"}
                                        />
                                    </Clickable>
                                </DatePickerModal>
                            </div>
                        </div>
                    </div>
                </div>

            </NewLabelWrapper>

        </>
    )
        ;
}

export default NewDateRangePicker;