import React from "react";
import {Form, Formik} from "formik";
import {Button, Col, Row} from "reactstrap";
import moment, {Moment} from "moment";
import PeriodSelector from "../selectors/PeriodSelector";
import DateRangePicker from "../../../components/Forms/DateRangePicker/DateRangePicker";
import BillingConfigurationForm from "../../jobs/FormatConfigurationForms/BillingConfigurationForm";
import {Period} from "../../../openapi/model/period";
import {CumulativeDataConfiguration} from "../../../openapi/model/cumulativeDataConfiguration";

export interface BillingReportRequestParams {
  startTime: Moment;
  endTime?: Moment;
  period?: Period;
  configuration?: CumulativeDataConfiguration;
}

interface Options {
  action: (request: BillingReportRequestParams) => void;
  displayWide?: boolean;
  actionLabel?: string;
}

const BillingReportSelectorForm = (
  props: BillingReportRequestParams & Options
): JSX.Element => {
  const { action, displayWide = false, actionLabel = "Search" } = props;

  const initialValues = (): BillingReportRequestParams => {
    return props;
  };

  const selectData = (values: BillingReportRequestParams): void => {
    action(values);
  };

  const _adjustForDisplayWide = (
    valueIfWide: any,
    valueIfNotWide: any = undefined
  ): any => {
    return displayWide ? valueIfWide : valueIfNotWide;
  };

  return (
    <Formik
      initialValues={initialValues()}
      onSubmit={selectData}
      enableReinitialize={true}
    >
      {(): JSX.Element => {
        return (
          <Form>
            <Row>
              <Col
                xs={12}
                sm={_adjustForDisplayWide(12, 8)}
                className={!displayWide ? "px-3 pr-sm-1 px-lg-1" : ""}
              >
                <DateRangePicker
                  startDateName={"startTime"}
                  endDateName={"endTime"}
                  maxDate={moment().startOf("day").subtract(1, "second")}
                />
              </Col>
              <Col
                xs={12}
                sm={_adjustForDisplayWide(12, 4)}
                className={!displayWide ? "px-3 pl-sm-1 px-lg-1" : ""}
              >
                <PeriodSelector
                  fieldName={"period"}
                  title={"Period"}
                  paddingX={0}
                  label={_adjustForDisplayWide("Period")}
                  submitSpinner={false}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <BillingConfigurationForm namespace={'configuration'} includedFieldsEditable={false}/>
              </Col>
            </Row>
            <Row className={"justify-content-end"}>
              <Col
                xs={12}
                sm={_adjustForDisplayWide(12, 2)}
                // md={_adjustForDisplayWide(12, 2)}
                // lg={_adjustForDisplayWide(12, 1)}
                className={
                  !displayWide
                    ? "py-1 px-3 px-lg-1 pl-sm-1 text-right"
                    : "pull-right"
                }
              >
                <Button
                  type={"submit"}
                  color={"primary"}
                  style={{ width: _adjustForDisplayWide(undefined, "100%") }}
                >
                  {actionLabel}
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BillingReportSelectorForm;
