/**
 * Meterpoint.io
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type DayOfWeek = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';

export const DayOfWeek = {
    MONDAY: 'MONDAY' as DayOfWeek,
    TUESDAY: 'TUESDAY' as DayOfWeek,
    WEDNESDAY: 'WEDNESDAY' as DayOfWeek,
    THURSDAY: 'THURSDAY' as DayOfWeek,
    FRIDAY: 'FRIDAY' as DayOfWeek,
    SATURDAY: 'SATURDAY' as DayOfWeek,
    SUNDAY: 'SUNDAY' as DayOfWeek
};