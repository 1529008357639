import { AssetFile as GatewayFile } from "../../../openapi/model/assetFile";
import { IntegrationFile } from "../../../openapi/model/integrationFile";
import { loadFileFromApi } from "../../../actions/helpers/presigned_s3";

export function isGatewayFile(
  file: GatewayFile | IntegrationFile,
): file is GatewayFile {
  return (file as GatewayFile).assetId !== undefined;
}

export function isIntegrationFile(
  file: GatewayFile | IntegrationFile,
): file is IntegrationFile {
  return (file as IntegrationFile).assetPositionId !== undefined;
}

export function fileUrl(file: GatewayFile | IntegrationFile): string {
  if (isGatewayFile(file)) {
    return `asset/${file.assetId}/files/${file.fileId}`;
  }
  if (isIntegrationFile(file)) {
    return `asset_position/${file.assetPositionId}/files/${file.fileId}`;
  }
  throw new Error("Unidentified file type");
}

export const getGatewayFileFromApi = async (
  file: GatewayFile | IntegrationFile,
): Promise<void> => {
  return loadFileFromApi("/" + fileUrl(file));
};
