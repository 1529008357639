import React from "react";
import AssetRowLink from "./AssetRowLink";
import {Moment} from "moment";
import {TableRow} from "react-data-table-component";
import AssetPositionRowLink from "./AssetPositionRowLink";
import LocationRowLink from "./LocationRowLink";
import {formatDate} from "../utils/HighlightNullAndOldDates";
import {BillingData} from "../../containers/report/billing/BillingReportView";
import {SingleAggregationTimeAssetData} from "../TimeSeries/model";

export type AssetRegisterData = AssetRegisterDataItem[];

export interface AssetRegisterDataItem extends TableRow {
    companyId?: string;
    companyName?: string;
    schemeName?: string;
    schemeId?: string;
    assetId?: string;
    serialNumber?: string;
    assetType: string;
    assetPositionId: string;
    assetPositionReference?: string;
    locationId: string;
    locationAddress?: string;
    locationCustomerReference?: string;

    gatewayAssetId?: string;
    gatewaySerialNumber?: string;
    gatewayAssetPositionId?: string;
    gatewayAssetPositionReference?: string;
    gatewayLocationId?: string;
    gatewayLocationAddress?: string;
    gatewayLastContactTime?: Date;

    installDate?: Date;
    uninstallDate?: Date;

    [dataIdentifier: string]: number | string | undefined | Date;
}

type AssetRowFunction = (
    row: AssetRegisterDataItem | SingleAggregationTimeAssetData
) => JSX.Element;

export const AssetRow = (
    startDatetime?: Moment,
    endDatetime?: Moment,
    gateway?: boolean,
    showDates?: boolean,
): AssetRowFunction => {

    const InnerAssetRowFunction = (
        row: AssetRegisterDataItem | SingleAggregationTimeAssetData | BillingData
    ): JSX.Element => {
        return <>
            {(row.assetId || (gateway && row.gatewayAssetId)) ? <div>
                    <AssetRowLink
                        assetId={gateway ? row.gatewayAssetId as string : row.assetId as string}
                        startDatetime={startDatetime}
                        endDatetime={endDatetime}
                    >
                        {gateway ? row.gatewaySerialNumber as string : row.serialNumber as string}
                    </AssetRowLink>
                    <br/>
                    {showDates && !gateway && (row.installDate || row.uninstallDate) &&
                        <><small>
                            {row.installDate ? formatDate(row.installDate as Moment, 'L') : 'Forever'}
                            {' - '}
                            {row.uninstallDate ? formatDate(row.uninstallDate as Moment, 'L') : 'Forever'}
                        </small>
                            <br/></>
                    }
                    <small>(...{(gateway ? row.gatewayAssetId as string: row.assetId)?.slice(-8)})</small>

                </div>
                :
                <div>
                    <>No asset</>
                    <br/>
                    {showDates && (row.installDate || row.uninstallDate) &&
                        <><small>
                            {row.installDate ? formatDate(row.installDate as Moment, 'L') : 'Forever'}
                            {' - '}
                            {row.uninstallDate ? formatDate(row.uninstallDate as Moment, 'L') : 'Forever'}
                        </small>
                            <br/></>
                    }
                </div>
            }</>
    };
    return InnerAssetRowFunction;
};

export const AssetPositionRow = (
    startDatetime?: Moment,
    endDatetime?: Moment
): AssetRowFunction => {
    const InnerAssetPositionRowFunction = (
        row: AssetRegisterDataItem | SingleAggregationTimeAssetData
    ): JSX.Element => {
        return <div><AssetPositionRowLink
            assetPositionId={row.assetPositionId}
            startDatetime={startDatetime}
            endDatetime={endDatetime}
        >
            {row.assetPositionReference ? row.assetPositionReference : `...${row.assetPositionId?.slice(-8)}`}
        </AssetPositionRowLink>
            {row.assetPositionReference && <><br/><small>(...{row.assetPositionId?.slice(-8)})</small></>}
        </div>
    };
    return InnerAssetPositionRowFunction;
};

export const LocationRow = (
    startDatetime?: Moment,
    endDatetime?: Moment,
): AssetRowFunction => {
    const InnerLocationRowFunction = (
        row: AssetRegisterDataItem | SingleAggregationTimeAssetData | BillingData
    ): JSX.Element => {
        return <div>
            <LocationRowLink
                locationId={row.locationId}
                startDatetime={startDatetime}
                endDatetime={endDatetime}
            >
                {row.locationAddress}
            </LocationRowLink>
            <br/>
            {row.locationCustomerReference && row.locationCustomerReference}&nbsp;
            <small>(...{row.locationId?.slice(-8)})</small>

        </div>
            ;
    }
    return InnerLocationRowFunction;
};

export const DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER_ASSET_POSITION_ONLY = [
    "companyId",
    "companyName",
    "schemeId",
    "schemeName",
    "locationId",
    "locationAddress",
    "locationCustomerReference",
    "group",
    "assetPositionId",
    "assetPositionReference",
    "assetType",
];

export const DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER = [
    ...DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER_ASSET_POSITION_ONLY,
    "assetId",
    "serialNumber",

    'installDate', "uninstallDate"
];

export default AssetRegisterData;
