import SelectedScheme from "components/Scheme/SelectedScheme";
import React from "react";

import { OneCardPage } from "../../components/utils/OneCardPage";
import LocationsList from "./LocationsList";

export const LocationsListPage = (): JSX.Element => {
  return (
    <OneCardPage
      headerTitle={"List Locations"}
      breadcrumbs={[
        { url: "/admin/location", name: "Locations" },
        { url: "/admin/location", name: "List" },
      ]}
    >
      <SelectedScheme>{(props) => <LocationsList {...props} />}</SelectedScheme>
    </OneCardPage>
  );
};
