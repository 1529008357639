import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  yupRequiredString,
  yupString,
} from "../../components/Forms/yupValidators";
import FormComponent from "../../components/Forms/FormComponent";
import { NoteIn } from "../../openapi/model/noteIn";
import useCreateEntity from "../../hooks/createEntity";
import { Alert, Badge, Button, Col } from "reactstrap";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import { Note } from "../../openapi/model/note";
import { ExpandedNote } from "../../openapi/model/expandedNote";
import _ from "lodash";
import { AssetPosition } from "../../openapi/model/assetPosition";

import useUpdateEntity from "../../hooks/updateEntity";
import { Status } from "openapi/model/status";

interface Props {
  assetPosition?: AssetPosition;
  onCreate?: () => void;
  onUpdate?: (note: Note) => void;
  note?: Note | ExpandedNote;
}

interface FormValues {
  title: string;
  content?: string;
  status?: Status;
}

export default function CreateNoteForm(props: Props): React.ReactElement {
  const { assetPosition, note } = props;
  const create = useCreateEntity<NoteIn>("NOTE");
  const update = useUpdateEntity<Note>("NOTE", note?.noteId as string);
  const [editing, setEditing] = useState(false);

  const [initialValues, setInitialValues] = useState<Note | undefined>(
    props.note
  );

  const [editSuccess, setEditSuccess] = useState(false);

  const existing = !_.isNil(props.note);
  const viewMode = existing && !editing;

  function submit(
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ) {
    setSubmitting(true);

    if (existing) {
      setEditSuccess(false);
      update
        .updateEntity({ ...note, ...values })
        .then(() => {
          setInitialValues({ ...initialValues, ...values } as Note);
          props.onUpdate && props.onUpdate({ ...note, ...values } as Note);
          setEditing(false);
          setEditSuccess(true);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      create
        .createEntity({
          companyId: assetPosition?.companyId,
          schemeId: assetPosition?.schemeId,
          assetPositionId: assetPosition?.assetPositionId,
          title: values.title,
          content: values.content,
        })
        .then((result) => {
          console.log(result);
          resetForm();
          props.onCreate && props.onCreate();
        })
        .finally(() => setSubmitting(false));
    }
  }

  return (
    <Formik
      initialValues={
        {
          title: initialValues ? initialValues.title : "",
          content: initialValues ? initialValues.content : "",
          status: initialValues ? initialValues.status : undefined,
        } as FormValues
      }
      validationSchema={Yup.object({
        title: yupRequiredString,
        content: yupString,
      })}
      onSubmit={submit}
      onReset={() => {
        if (existing) {
          setEditing(false);
        }
      }}
      enableReinitialize={true}
    >
      {({
        isSubmitting,
        isValid,
        dirty,
        resetForm,
        submitForm,
        setFieldValue,
        values,
      }) => (
        <Form>
          {values.status === "DELETED" && (
            <Col xs={12} className={"text-right py-0 px-0"}>
              <Badge color={"danger"}>DELETED</Badge>
            </Col>
          )}
          <FormComponent
            fieldName={"title"}
            label={"Title"}
            submitSpinner={false}
            disabled={viewMode}
            vertical={true}
            paddingX={0}
            labelClass={"text-muted small py-1"}
            inputClassName={viewMode ? "hide-editing title" : undefined}
          />
          <FormComponent
            fieldName={"content"}
            as={"textarea"}
            label={"Detail"}
            submitSpinner={false}
            style={{ height: "400px" }}
            disabled={viewMode}
            vertical={true}
            paddingX={0}
            labelClass={"text-muted small py-1"}
            inputClassName={viewMode ? "hide-editing" : undefined}
          />
          {!props.note ? (
            <Button
              type="submit"
              color="primary"
              disabled={isSubmitting || !isValid}
            >
              Add
            </Button>
          ) : editing ? (
            <>
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting || !isValid || !dirty}
              >
                Update
              </Button>
              <Button type="reset" color="danger" disabled={isSubmitting}>
                Cancel
              </Button>
            </>
          ) : (
            values.status !== "DELETED" && (
              <>
                <Button
                  type={"button"}
                  color={"primary"}
                  onClick={(e) => {
                    e.preventDefault();
                    setEditing(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  type={"button"}
                  color={"danger"}
                  onClick={(e) => {
                    e.preventDefault();
                    resetForm();
                    setFieldValue("status", "DELETED");
                    submitForm();
                  }}
                >
                  Delete
                </Button>
              </>
            )
          )}
          <ErrorBanner error={create.error || update.error} />
          {editSuccess && (
            <Alert color={"success"} className={"mt-1"}>
              <i className={"fa fa-fw fa-check"} />
              Note successfully updated.
            </Alert>
          )}
        </Form>
      )}
    </Formik>
  );
}
