import React, { Component } from "react";
import { OneCardPage } from "../../components/utils/OneCardPage";
import AssetPositionList from "./AssetPositionList";
import AssetListFilterForm from "../assets/list/AssetListFilterForm";
import SearchParamPage from "../../components/Search/SearchParamPage";
import SelectedScheme from "../../components/Scheme/SelectedScheme";

const InnerAssetPositionListPage = (props: any): JSX.Element => {
  return (
    <SelectedScheme>
      <AssetPositionList {...props} />
    </SelectedScheme>
  );
};

class AssetPositionListPage extends Component {
  render(): JSX.Element {
    return (
      <OneCardPage
        headerTitle={"Asset Position List"}
        breadcrumbs={[
          { url: "/admin/asset_position", name: "Asset Positions" },
          { url: "/admin/asset_position", name: "List" },
        ]}
      >
        <AssetListFilterForm
          includeNonCreateables={false}
          filterUnassignedField={"assetId"}
        />
        <SearchParamPage
          initialState={{ assetId: undefined, assetType: undefined }}
        >
          <InnerAssetPositionListPage />
        </SearchParamPage>
      </OneCardPage>
    );
  }
}

export default AssetPositionListPage;
