import React from "react";
import { OneCardPage } from "../../components/utils/OneCardPage";
import RequireScheme from "../../components/Scheme/RequireScheme";
import SelectedScheme from "../../components/Scheme/SelectedScheme";
import WirelessClusterCreateForm from "./WirelessClusterCreateForm";

export default function WirelessClusterCreatePage(): JSX.Element {
  return (
    <SelectedScheme>
      {(schemeProps) => (
        <OneCardPage
          headerTitle={
            schemeProps.schemeId
              ? `Add a wireless Cluster to ${schemeProps.schemeId}`
              : "Add a Wireless Cluster"
          }
          breadcrumbs={[
            {
              url: "/admin/wireless_cluster",
              name: "Wireless Clusters",
            },
            {
              url: "/admin/wireless_cluster/create",
              name: "Create",
            },
          ]}
        >
          <RequireScheme>
            <WirelessClusterCreateForm
              schemeId={schemeProps.schemeId as string}
              companyId={schemeProps.companyId}
            />
          </RequireScheme>
        </OneCardPage>
      )}
    </SelectedScheme>
  );
}
