/**
 * Meterpoint.io
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type BulkUpdateStatus = 'PREVIEW_GENERATING' | 'PREVIEW_CREATED' | 'PREVIEW_FAILED' | 'EXECUTION_STARTED' | 'EXECUTION_COMPLETED' | 'EXECUTION_FAILED';

export const BulkUpdateStatus = {
    PREVIEWGENERATING: 'PREVIEW_GENERATING' as BulkUpdateStatus,
    PREVIEWCREATED: 'PREVIEW_CREATED' as BulkUpdateStatus,
    PREVIEWFAILED: 'PREVIEW_FAILED' as BulkUpdateStatus,
    EXECUTIONSTARTED: 'EXECUTION_STARTED' as BulkUpdateStatus,
    EXECUTIONCOMPLETED: 'EXECUTION_COMPLETED' as BulkUpdateStatus,
    EXECUTIONFAILED: 'EXECUTION_FAILED' as BulkUpdateStatus
};