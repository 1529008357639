/**
 * Meterpoint.io
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type BillableAssets = 'HEAT_METER' | 'HEATING_AND_COOLING_METER' | 'GAS_METER' | 'COOLING_METER' | 'ELECTRICITY_METER' | 'WATER_METER';

export const BillableAssets = {
    HEATMETER: 'HEAT_METER' as BillableAssets,
    HEATINGANDCOOLINGMETER: 'HEATING_AND_COOLING_METER' as BillableAssets,
    GASMETER: 'GAS_METER' as BillableAssets,
    COOLINGMETER: 'COOLING_METER' as BillableAssets,
    ELECTRICITYMETER: 'ELECTRICITY_METER' as BillableAssets,
    WATERMETER: 'WATER_METER' as BillableAssets
};