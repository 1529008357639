import React from "react";
import {ReportExecutionOut} from "../../../openapi/model/reportExecutionOut";
import ReportExecutionListRow from "./ReportExecutionListRow";
import ReportExecutionContainer, {ReportExecutionChildProps,} from "./ReportExecutionContainer";
import ApiPaginatedList, {ApiPaginatedListProps} from "../../../components/Pagination/NewApiPaginatedList";
import {PaginatedListResultProps} from "../../../core/action/GetList";

interface Props  extends Omit<ApiPaginatedListProps, 'cursor' | 'data' | 'loading' | 'row' > {
    list: PaginatedListResultProps<ReportExecutionOut>;
}
function ReportExecutionList(props: Props) {
    const {list, ...rest} = props;
    const {loading, data} = list;
    return (
        <ApiPaginatedList
            cursor={data?.cursor}
            data={data}
            loading={loading}
            row={(v: ReportExecutionOut) => (
                <ReportExecutionContainer key={v.reportId} reportExecution={v}>
                    {({reportExecution, refresh}: ReportExecutionChildProps) => (
                        <ReportExecutionListRow
                            refresh={refresh}
                            execution={reportExecution}
                        />
                    )}
                </ReportExecutionContainer>
            )}
            {...rest}
        />
    );
}

export default ReportExecutionList;
