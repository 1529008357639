import React from "react";
import {Col, Row} from "reactstrap";
import {
  AssetPositionRow,
  AssetRegisterDataItem,
  AssetRow,
  DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER,
  LocationRow,
} from "../../../components/Table/AssetRegisterData";
import CSVExportButton from "../../../components/utils/CSVExport/CSVExportButton";

import {Moment} from "moment";
import PrivacyModeDataTable, {PrivacyModeTableColumn,} from "../../../components/Text/PrivacyModeDataTableCell";
import {isNull, startCase} from "lodash";

import {SingleAggregationTimeAssetData} from "../../../components/TimeSeries/model";

export interface AssetRegisterReportTableProps {
  data?: AssetRegisterDataItem[];
  columnDefinitions: PrivacyModeTableColumn<AssetRegisterDataItem>[];
  download?: boolean;
  downloadFilename?: string;
  startDatetime: Moment;
  endDatetime: Moment;
  csvColumnOrder?: Array<string>;
  expanded?: boolean;
  extraActions?: React.ReactNode;
}


export function assetPositionSortValue(row: AssetRegisterDataItem | SingleAggregationTimeAssetData): string {
    if (row.assetPositionReference &&!isNull(row.assetPositionReference) && row.assetPositionReference !== "" ){
        return row.assetPositionReference
    } else {
        return `_${row.assetPositionId}` // underscore sets these after references
    }
}

export function locationSortValue(row: AssetRegisterDataItem | SingleAggregationTimeAssetData): string {
    if (row.locationAddress) {
        return row.locationAddress.replace(/\d+/g, function(m){
          return "00000".substr(m.length - 1) + m;
        });
    }
    return `_${row.locationId}`
}

const AssetRegisterReportTable = (
  props: AssetRegisterReportTableProps
): JSX.Element => {
  const {
    columnDefinitions,
    startDatetime,
    endDatetime,
    download = true,
    downloadFilename = "export.csv",
    csvColumnOrder = DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER,
  } = props;
  const showAsset=true;
  const columns: PrivacyModeTableColumn<AssetRegisterDataItem>[] = [
      ...[{
      name: "Location",
      selector: locationSortValue,
      cell: LocationRow(startDatetime, endDatetime),
      sortable: true,
        width: "250px",
      compact: true,
      privacyMode: true
    },
    {
      name: "Asset Position",
      selector: assetPositionSortValue,
      cell: AssetPositionRow(startDatetime, endDatetime),
      sortable: true,
      width: "200px",
      compact: true,
      privacyMode: true
    },
    {
      name: "Asset Type",
      selector: (row:AssetRegisterDataItem) => row.assetType,
      cell: (row:AssetRegisterDataItem) => startCase(row.assetType),
      sortable: true,
      compact: true,
      width: '120px',
    }],
  ...(showAsset ? [{
      name: "Asset",
      selector: (row:AssetRegisterDataItem) => row.serialNumber || "-",
      sortable: true,
      cell: AssetRow(startDatetime, endDatetime, false, true),
      width: "150px",
      compact: true,
      privacyMode: true
    }]: []),
    ...columnDefinitions,
    ];

  return (
    <>
      {props.data && (
        <Row>
          <Col xs={12}>
            <PrivacyModeDataTable
              columns={columns}
              data={props.data}
              pagination
              fixedHeader
            />
          </Col>
          {(download || props.extraActions) && (
            <Col xs={12}>
              {download && (
                <CSVExportButton
                  data={props.data}
                  filename={downloadFilename}
                  columnOrder={csvColumnOrder}
                />
              )}
              {props.extraActions}
            </Col>
          )}
        </Row>
      )}
    </>
  );
};

export default AssetRegisterReportTable;
