import React from "react";
import { GatewayAsset } from "../../../openapi/model/gatewayAsset";

export default function GatewayListContent(props: {
  asset: GatewayAsset;
}): JSX.Element {
  if (props.asset.assetType !== "gateway") {
    throw new Error("Unsupported asset type");
  }

  return (
    <>
      {props.asset.simCardIccid && <>Sim Iccid: {props.asset.simCardIccid}</>}
    </>
  );
}
