import {FormFieldProps} from "./LabelWrapper";
import {Field, useField} from "formik";
import React from "react";
import {Button, Col, Input, InputGroup, Label, Row} from "reactstrap";

import './NewTextFormComponent.scss'
import classNames from "classnames";
import TextWithClipboard from "../utils/CopyToClipboard";
import {usePrivacyMode} from "../../reducers/privacy";

interface Props extends FormFieldProps {
    fieldName: string;
    disabled?: boolean;
    infoText?: string;
    clipboard?: boolean;
    staticValue?: boolean;
    privacyModeDisabled?: boolean;
    dataCy?: string;
}


function NewTextFormComponent(props: Props): JSX.Element {
    const {disabled = false, clipboard = false, staticValue = false, privacyModeDisabled=false} = props;
    const [_field, meta, _helpers] = useField(props.fieldName);
    const privacyMode = usePrivacyMode();


    return <Row>

        {props.label &&
            <Col xs={12} md={4}>
                <Label className='col-form-label' for={props.fieldName}>{props.label}</Label>
            </Col>
        }
        <Col xs={12} md={8}>
            <InputGroup>
                <Input
                    tag={Field}
                    name={props.fieldName}
                    id={props.fieldName}
                    type={'text'}
                    disabled={disabled || staticValue}
                    valid={meta.touched && !meta.error}
                    className={classNames({'static': staticValue, 'blurry-text': privacyMode && !privacyModeDisabled})}
                    data-cy={props.dataCy || props.fieldName}
                />
                {
                    clipboard &&
                    <TextWithClipboard value={_field.value}>
                        <Button color={'primary'} id={props.fieldName}>
                            <i className={"far fa-fw fa-clipboard"}/>
                        </Button>
                    </TextWithClipboard>
                }
            </InputGroup>
        </Col>

    </Row>
}

export default NewTextFormComponent