import React from "react";
import {yupRequiredString} from "../../../components/Forms/yupValidators";
import * as Yup from "yup";
import {FTPConfiguration} from "../../../openapi/model/fTPConfiguration";
import {Col, Row} from "reactstrap";
import TextInputField from "../../../components/Forms/TextInputField";
import TextInput from "../../../components/Forms/TextInput";

export const FTPExportConfigurationSchema = Yup.object().shape({
    host: yupRequiredString,
    username: yupRequiredString,
    password: yupRequiredString,
    port: Yup.number(),
    TLS: Yup.boolean(),
    path: Yup.string(),
});

export const FTPExportInitialValues: FTPConfiguration = {
    host: "",
    port: 21,
    username: "",
    password: "",
    path: "/",
    TLS: true,
};

const FTPExportConfigurationForm = ({
                                        namespace, disabled = false
                                    }: { namespace: string, disabled?: boolean }): JSX.Element => {

    const withNamespace = (fieldName: string): string => {
        return namespace ? `${namespace}.${fieldName}` : fieldName
    }

    return (
        <>
            <h4>FTP Configuration</h4>
            <Row>
                <Col xs={12}>
                    <TextInputField
                        name={withNamespace("host")}
                    >
                        {(fieldProps) => <TextInput
                            {...fieldProps}
                            label={{label: 'Host'}}
                            disabled={disabled}

                        />}
                    </TextInputField>
                    <TextInputField
                        name={withNamespace("port")}
                    >
                        {(fieldProps) => <TextInput
                            {...fieldProps}
                            label={{label: 'Port'}}
                            disabled={disabled}

                        />}
                    </TextInputField>
                    <TextInputField
                        name={withNamespace("username")}
                    >
                        {(fieldProps) => <TextInput
                            {...fieldProps}
                            label={{label: 'Username'}}
                            disabled={disabled}

                        />}
                    </TextInputField>
                    <TextInputField
                        name={withNamespace("password")}
                    >
                        {(fieldProps) => <TextInput
                            {...fieldProps}
                            label={{label: 'Password'}}
                            disabled={disabled}

                        />}
                    </TextInputField>
                    <TextInputField
                        name={withNamespace("path")}
                    >
                        {(fieldProps) => <TextInput
                            {...fieldProps}
                            label={{label: 'Remote path'}}
                            disabled={disabled}

                        />}
                    </TextInputField>

                </Col>
            </Row>
        </>
    );
};


export default FTPExportConfigurationForm;
