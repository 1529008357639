/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useEffect, useState } from "react";
// react library for routing
import { useLocation } from "react-router-dom";
// core components
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import AdminFooter from "../../components/Footers/AdminFooter";
import Sidebar from "../../components/Sidebar/Sidebar";

import AdminRoutes from "routes/adminRoutes";
import CompanySchemeHashUpdate from "./CompanySchemeHashUpdate";
import CompanyListInit from "./CompanyListInit";
import SchemeListInit from "./SchemeListInit";
import PrivacyModeInit from "./PrivacyModeInit";
import SchemeGroupListInit from "./SchemeGroupListInit";

const Admin = (): JSX.Element => {
  const [state, setState] = useState({ sidenavOpen: window.innerWidth > 1400 });

  const location = useLocation();

  const toggleBodyProps = useCallback(() => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
  }, []);

  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (): void => {
    toggleBodyProps();
    setState({
      sidenavOpen: !state.sidenavOpen,
    });
  };

  useEffect(() => {
    if (window.innerWidth < 1400) {
      toggleBodyProps();
    }
  }, [toggleBodyProps]);

  const getNavbarTheme = (): any => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1
      ? "dark"
      : "light";
  };

  const closeSidenav = (): void => {
    if (state.sidenavOpen && window.innerWidth < 1400) {
      toggleSidenav();
    }
  };

  return (
    <CompanyListInit>
      <SchemeListInit>
        <SchemeGroupListInit>
          <PrivacyModeInit>
            <CompanySchemeHashUpdate>
              <Sidebar
                toggleSidenav={toggleSidenav}
                sidenavOpen={state.sidenavOpen}
                logo={{
                  innerLink: "/",
                  imgSrc: "assets/img/brand/meterpoint.png",
                  imgAlt: "...",
                }}
              />
              <div
                className="main-content"
                // ref="mainContent"
                onClick={closeSidenav}
              >
                <AdminNavbar
                  theme={getNavbarTheme()}
                  toggleSidenav={toggleSidenav}
                  sidenavOpen={state.sidenavOpen}
                />
                <AdminRoutes />
                <AdminFooter />
              </div>
              {state.sidenavOpen ? (
                <div
                  className="backdrop d-xl-none d-lg-none"
                  onClick={toggleSidenav}
                />
              ) : null}
            </CompanySchemeHashUpdate>
          </PrivacyModeInit>
        </SchemeGroupListInit>
      </SchemeListInit>
    </CompanyListInit>
  );
};

export default Admin;
