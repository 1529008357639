import React from 'react';
import {OptionsList, SelectCombo, SelectComboProps} from "../Forms/SelectCombo/SelectCombo";
import {Period} from "../../openapi/model/period";

type PeriodSelectComboProps = Omit<SelectComboProps, 'options'>;


function getOptionsList(): OptionsList {
    return [
        {label: "Daily", value: Period.DAILY},
        {label: "Weekly", value: Period.WEEKLY},
        {label: "7 Day", value: Period.SEVENDAY},
        {label: "Monthly", value: Period.MONTHLY},

    ]
}


function PeriodSelectCombo(props: PeriodSelectComboProps) {

    return <SelectCombo {...props} options={getOptionsList()}/>
}

export default PeriodSelectCombo;