import React, { useState } from "react";
import { AssetFile as GatewayFile } from "../../../openapi/model/assetFile";
import { Media, UncontrolledTooltip } from "reactstrap";
import { formatDate } from "../../../components/utils/HighlightNullAndOldDates";
import PrettyBytes from "pretty-bytes";
import classNames from "classnames";
import PrivacyMode from "components/Text/PrivacyMode";
import { IntegrationFile } from "../../../openapi/model/integrationFile";
import GatewayFileStatusBadge from "./GatewayFileStatusBadge";
import { getGatewayFileFromApi } from "./model";
import ResendDataFileButton from "./ResendDataFileButton";
import DisplayIfUserType from "../../../components/utils/DisplayIfUserType/DisplayIfUserType";

function GatewayFileListRow(props: {
  file: GatewayFile | IntegrationFile;
  index: number | string;
}): JSX.Element {
  const { file, index } = props;
  const [loading, setLoading] = useState(false);
  const downloadable = file.size !== 0;

  const handleClick = async (): Promise<undefined> => {
    if (downloadable && !loading) {
      setLoading(true);
      await getGatewayFileFromApi(file);
      setLoading(false);
    }
    return;
  };

  return (
    <tr key={index}>
      <th scope="row">
        <Media className="align-items-center">
          <i
            className="fas fa-file-excel fa-2x"
            style={{ color: "rgb(29, 111, 66)" }}
          />
        </Media>
      </th>
      <td className="text-left align-middle">
        <PrivacyMode>{file.filename}</PrivacyMode>
      </td>
      <td className="text-left  align-middle">{formatDate(file.created)}</td>
      <td className="text-left  align-middle">
        {file.size ? PrettyBytes(file.size) : "-"}
      </td>
      <td className="text-left  align-middle">
        <GatewayFileStatusBadge file={file} />
      </td>
      <td className="table-actions">
        <a
          className={classNames({
            "p-1 table-action": true,
            disabled: !downloadable,
            "pointer-hover": downloadable,
          })}
          id={`tooltip-gateway-edit-${index}`}
          onClick={async (e): Promise<any> => {
            e.preventDefault();
            return await handleClick();
          }}
        >
          <i
            className={classNames({
              "fas fa-fw": true,
              "fa-download": !loading && downloadable,
              "fa-ban": !downloadable,
              "fa-cog fa-spinner fa-spin": loading,
            })}
          />
          <UncontrolledTooltip
            delay={0}
            target={`tooltip-gateway-edit-${index}`}
          >
            {downloadable ? "Download raw file" : "This file has no content"}
          </UncontrolledTooltip>
        </a>
        <DisplayIfUserType userTypes={"superadmin"}>
          <>
            <ResendDataFileButton
              file={file}
              id={`resend-${index}`}
              removeQuarantine={false}
            />
            <ResendDataFileButton
              file={file}
              id={`resend--rq-${index}`}
              removeQuarantine={true}
            />
          </>
        </DisplayIfUserType>
      </td>
    </tr>
  );
}

export default GatewayFileListRow;
