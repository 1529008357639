import React, { useCallback } from "react";
import { Form, Formik } from "formik";
import { Button, Col, Row } from "reactstrap";
import useGetParsedSearchString from "../../../hooks/getParsedSearchString";
import DateRangePicker from "../../../components/Forms/DateRangePicker/DateRangePicker";
import moment, { Moment } from "moment";
import { ParsedQs } from "qs";
import _ from "lodash";

export interface GatewayListFilterParams {
  startDatetime?: Moment;
  endDatetime?: Moment;
}

export const useGetParsedGatewayFileListFilterParams = () =>
  useGetParsedSearchString<GatewayListFilterParams>(
    useCallback((p: ParsedQs): GatewayListFilterParams => {
      return {
        startDatetime: _.isString(p.startDatetime)
          ? moment(p.startDatetime)
          : undefined,
        endDatetime: _.isString(p.endDatetime)
          ? moment(p.endDatetime)
          : undefined,
      };
    }, [])
  );

const GatewayFileListFilterForm = (): JSX.Element => {
  const { ready, updateFilters, params } =
    useGetParsedGatewayFileListFilterParams();

  return (
    <>
      {ready && (
        <Row className={"justify-content-end"}>
          <Col xs={12} className={"mb-2"}>
            <Formik
              initialValues={params}
              onSubmit={(x): void => {
                updateFilters(x);
              }}
            >
              {(): JSX.Element => (
                <Form>
                  <Row>
                    <Col
                      xs={{ size: 12 }}
                      sm={{ size: 8 }}
                      lg={{ offset: 5, size: 5 }}
                      xl={{ offset: 6, size: 4 }}
                      className={"px-3 pr-sm-1 px-lg-1"}
                    >
                      <DateRangePicker
                        startDateName={"startDatetime"}
                        endDateName={"endDatetime"}
                        maxDate={moment().endOf("day")}
                        clearable={true}
                        minDays={0}
                      />
                    </Col>
                    <Col
                      xs={12}
                      sm={4}
                      lg={2}
                      xl={2}
                      className={"py-1 px-3 pl-sm-1 text-right"}
                    >
                      <Button
                        type={"submit"}
                        color={"primary"}
                        data-cy={"export"}
                        className={"w-100"}
                      >
                        Filter
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      )}
    </>
  );
};

export default GatewayFileListFilterForm;
