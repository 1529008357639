import React, { useState } from "react";
import { GetEntityResultProps } from "../../core/action/GetEntity";
import { Status } from "../../openapi/model/status";
import { OneCardPage } from "../../components/utils/OneCardPage";
import { locationToBreadcrumb } from "../../components/navigation/breadcrumbs/Breadcrumbs";
import { Col, Row } from "reactstrap";
import HeadingEditorForm from "../../components/Forms/HeadingEditorForm";
import AssetRegisterStatusLabel from "../../components/Labels/AssetRegisterStatusLabel";
import CopyToClipboard from "../../components/utils/CopyToClipboard";
import { formatDate } from "../../components/utils/HighlightNullAndOldDates";
import RoutedTabGroupInterface from "../../components/TabGroup/RoutedTabGroupInterface";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import AssetPositionCreateForm from "../assetPositions/AssetPositionCreateForm";
import EventList from "../event/EventList";
import AssetPositionDeleteTab from "../assetPositions/tabs/AssetPositionDeleteTab";
import useUpdateEntity from "../../hooks/updateEntity";
import { Location } from "../../openapi/model/location";
import { locationSchema } from "../../model/location";
import { ErrorBanner } from "components/Error/ErrorBanner";
import PrivacyMode from "../../components/Text/PrivacyMode";
import AssetRegisterList from "../assetRegister/AssetRegisterList";
import LocationAccountTab from "./LocationAccountTab";

const LocationDisplay = ({
  data,
  refresh,
  error,
  loading,
}: GetEntityResultProps<any>): JSX.Element => {
  const location = data;
  const deleted = location?.status === Status.DELETED;

  const updateEntity = useUpdateEntity<Location>(
    "LOCATION",
    location?.locationId,
    () => refresh()
  );
  const [refreshed, setRefreshed] = useState<Date>(new Date());

  const updateList = (): void => {
    setRefreshed(new Date());
  };

  const deleteLocation = (): Promise<any> => {
    if (location) {
      return updateEntity.updateEntity({
        ...location,
        status: Status.DELETED,
      });
    } else {
      throw new Error("Can not delete with a location");
    }
  };

  return (
    <OneCardPage
      headerTitle={"Location Detail"}
      breadcrumbs={location && [locationToBreadcrumb(location)]}
      loading={loading}
      data-cy={"location-detail"}
    >
      {error && <ErrorBanner error={error} />}
      {location && (
        <>
          <Row>
            <Col xs={12} md={6}>
              <HeadingEditorForm
                update={updateEntity}
                label={"Address"}
                values={location}
                title={"Address"}
                fieldName={"address"}
                validator={locationSchema.address}
                editActionUserType={"editor"}
              />
              <div className={"d-flex flex-wrap"}>
                <HeadingEditorForm
                  update={updateEntity}
                  label={"Reference"}
                  values={location}
                  title={"Reference"}
                  fieldName={"customerReference"}
                  // validator={locationSchema.address}
                  editActionUserType={"editor"}
                  defaultValue={"-"}
                />
                <HeadingEditorForm
                  update={updateEntity}
                  label={"Group"}
                  values={location}
                  title={"Group"}
                  fieldName={"group"}
                  defaultValue={"-"}
                  validator={locationSchema.group}
                  editActionUserType={"superadmin"}
                />
              </div>
            </Col>

            <Col xs={12} md={6}>
              <div
                className={
                  "d-flex justify-content-md-end text-sm text-md-right text-left flex-wrap"
                }
              >
                {deleted && (
                  <div className={"ml-md-4 mr-md-0 mr-4"}>
                    <h6
                      style={{
                        textTransform: "uppercase",
                        marginBottom: 0,
                      }}
                    >
                      Status
                    </h6>
                    <AssetRegisterStatusLabel status={location.status} />
                  </div>
                )}
                <div className={"ml-md-4 mr-md-0 mr-4"}>
                  <h6
                    style={{
                      textTransform: "uppercase",
                      marginBottom: 0,
                    }}
                  >
                    Scheme
                  </h6>
                  <span>
                    <PrivacyMode>{location.schemeId}</PrivacyMode>
                  </span>
                </div>
                <div className={"ml-md-4 mr-md-0 mr-4"}>
                  <h6
                    style={{
                      textTransform: "uppercase",
                      marginBottom: 0,
                    }}
                  >
                    Location ID
                  </h6>
                  <span>
                    <CopyToClipboard value={location?.locationId} slice={8} />
                  </span>
                </div>
                <div className={"ml-md-4 mr-md-0 mr-4"}>
                  <h6
                    style={{
                      textTransform: "uppercase",
                      marginBottom: 0,
                    }}
                  >
                    Created
                  </h6>
                  <span>
                    {formatDate(location?.createdAt, undefined, false)}
                  </span>
                </div>
                <div className={"ml-md-4 mr-md-0 mr-4"}>
                  <h6
                    style={{
                      textTransform: "uppercase",
                      marginBottom: 0,
                    }}
                  >
                    Updated
                  </h6>
                  <span>
                    {formatDate(location?.lastUpdated, undefined, false)}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <RoutedTabGroupInterface
                tabs={[
                  {
                    tabName: "Asset Positions",
                    tabPath: "positions",
                    tabContent: (
                      <>
                        <Col xs={12}>
                          <h3>Asset Positions</h3>
                        </Col>
                        <Col xs={12}>
                          <AssetRegisterList
                            companyId={location.companyId}
                            schemeId={location.schemeId}
                            locationId={location.locationId}
                            download={false}
                            createAssetTags={false}
                            showLocation={false}
                            lastChange={refreshed}
                          />
                        </Col>
                        <DisplayIfUserType userTypes={"admin"}>
                          <Col xs={12}>
                            <h3>Add New Asset Position</h3>
                          </Col>
                          <Col xs={12}>
                            {" "}
                            <AssetPositionCreateForm
                              location={location}
                              postCreateActions={updateList}
                            />
                          </Col>
                        </DisplayIfUserType>
                      </>
                    ),
                    disabled: deleted,
                  },
                  {
                    tabName: "Accounts",
                    tabPath: "accounts",
                    tabContent: <LocationAccountTab  location={location}/>,
                    userTypes:'superadmin',
                    disabled: deleted,
                  },

                  {
                    tabName: "Events",
                    tabPath: "events",
                    tabContent: (
                      <Row>
                        <Col xs={12}>
                          <h3>Location Events</h3>
                          <EventList
                            locationId={location.locationId}
                            companyId={location.companyId}
                          />
                        </Col>
                      </Row>
                    ),
                  },
                  {
                    tabName: "Delete",
                    tabPath: "delete",
                    tabContent: (
                      <AssetPositionDeleteTab
                        assetPosition={location}
                        refresh={refresh}
                        delete={deleteLocation}
                        title={"Location"}
                      />
                    ),
                    userTypes: "admin",
                    disabled: false,
                  },
                ]}
                topLevelPath={`/admin/location/${location.locationId}/:tab`}
              />
            </Col>
          </Row>
        </>
      )}
    </OneCardPage>
  );
};

export default LocationDisplay;
