import React from "react";
import {Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {Button, Col, Row} from "reactstrap";
import useCreateEntity from "../../hooks/createEntity";
import {Asset} from "openapi/model/asset";
import SelectComponent from "../../components/Forms/SelectComponent";
import NewTextFormComponent from "../../components/Forms/NewTextFormComponent";
import {AssetClass} from "../../openapi/model/assetClass";
import {AssetPosition} from "../../openapi/model/assetPosition";
import AssetTypeSelect from "../assets/AssetTypeSelect";
import {AssetType} from "../../openapi/model/assetType";
import AssetPositionAssetLinkDateSelect from "./assetPositionAssetLinks/AssetPositionAssetLinkDateSelect";
import {Moment} from "moment/moment";
import {NewAsset} from "../../openapi/model/newAsset";

interface FormValues {
    serialNumber: string;
    manufacturer: string;
    assetType: AssetType;
    installDate?: Moment,
    uninstallDate?: Moment
}

interface Props {
    assetPosition: AssetPosition;
    refresh?: () => void;
}

const CreateAssetForm = (props: Props): JSX.Element => {
    const {createEntity} = useCreateEntity<Asset, NewAsset>("ASSET");
    const isGateway = props.assetPosition.assetPositionType === 'gateway';

    const submit = (
        values: FormValues,
        {setSubmitting, resetForm}: FormikHelpers<FormValues>
    ): void => {
        setSubmitting(true);

        createEntity({
            serialNumber: values.serialNumber,
            manufacturer: isGateway ? values.manufacturer : undefined,
            assetClass: isGateway ? (values.manufacturer === 'evinox' ? AssetClass.Evinox : AssetClass.Mbus) : AssetClass.Mbus,
            companyId: props.assetPosition.companyId,
            schemeId: props.assetPosition.schemeId,
            assetType: props.assetPosition.assetPositionType,
            currentLink: {
                assetPositionId: props.assetPosition.assetPositionId,
                installDate: values.installDate?.toDate(),
                uninstallDate: values.uninstallDate?.toDate()
            }
        })
            .then(() => {
                props.refresh && props.refresh();
                resetForm();
            })
            .finally(() => {
                setSubmitting(false);
            });
    };


    const initialValues = {
        serialNumber: "",
        manufacturer: isGateway ? 'elvaco' : '',
        assetType: props.assetPosition.assetPositionType
    };

    return (
        <Formik
            onSubmit={submit}
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
                serialNumber: Yup.string().required("Required"),
                manufacturer: isGateway ? Yup.string().required() : Yup.string().notRequired().max(0)
            })}
            validateOnMount={true}
        >
            {({isSubmitting, isValid}) => <Form data-cy={"create-asset-form"}>
                <Row>
                    <Col xs={12}>
                        <NewTextFormComponent fieldName={"serialNumber"} label={"Serial Number"}/>
                    </Col>
                    <Col xs={12}>
                        <AssetTypeSelect fieldName={'assetType'} label={'AssetType'} isDisabled={true}
                        paddingX={0} submitSpinner={false}/>
                    </Col>
                    {isGateway && <Col xs={12}>
                        <SelectComponent name={'manufacturer'} options={[{label: 'Elvaco', value: 'elvaco'}, {
                            label: 'Evinox',
                            value: 'evinox'
                        },
                {label: 'Lansen', value:'lansen'}
                        ]}
                                         label={'Manufacturer'} submitSpinner={false} paddingX={0}/>
                    </Col>}
                    <Col xs={12}>
                        <h4>Dates</h4>
                            <AssetPositionAssetLinkDateSelect newLink={true}/>
                    </Col>


                    <Col xs={12} className={'text-right'}>
                                <Button
                                    type={"submit"}
                                    color={"primary"}
                                    disabled={isSubmitting || !isValid}
                                >
                                    Add Asset
                                </Button>
                    </Col>
                </Row>
            </Form>
            }
        </Formik>
    );
};

export default CreateAssetForm;
