import React from "react";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import LatestTimeSeriesDataReport from "./LatestTimeSeriesDataReport";
import RequireScheme from "components/Scheme/RequireScheme";

interface SchemeId {
  companyId?: string;
  schemeId?: string;
}

const SchemeLatestTimeSeriesReport = (props: SchemeId): JSX.Element => {
  return (
    <OneCardPage
      headerTitle={"Reporting"}
      breadcrumbs={[{ url: "/admin/report/time_series", name: "Latest Data" }]}
    >
      <RequireScheme>
        <LatestTimeSeriesDataReport {...(props as Required<SchemeId>)} />
      </RequireScheme>
    </OneCardPage>
  );
};

export default SchemeLatestTimeSeriesReport;
