import React from "react";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import FormComponent from "../../components/Forms/FormComponent";
import { Button } from "reactstrap";
import { Location } from "../../openapi/model/location";
import AssetTypeSelect from "../assets/AssetTypeSelect";
import useCreateEntity from "../../hooks/createEntity";
import { AssetPosition } from "../../openapi/model/assetPosition";
import { AssetType } from "../../openapi/model/assetType";
import BlockSpinner from "components/Spinners/BlockSpinner";
import { ErrorBanner } from "components/Error/ErrorBanner";
import { assetPositionSchema } from "../../model/assetPosition";
import { AssetUseCase } from "../../openapi/model/assetUseCase";
import AssetUseCaseSelect from "../../components/Asset/AssetUseCaseSelect";
import _ from "lodash";

interface Props {
  location: Location;
  postCreateActions?: () => any;
}

interface FormValues {
  assetPositionType: AssetType | "undefined";
  useCase: AssetUseCase;
  customerReference?: string;
  parentAssetPositionId?: string;
}

function InnerAssetPositionCreateForm(
  props: FormikProps<FormValues> & { error?: Error; creating: boolean }
): JSX.Element {
  const {
    values,
    setFieldValue,
    isValid,
    isSubmitting,
    creating,
    error,
  } = props;
  React.useEffect(() => {
    if (
      [
        AssetType.HeatMeter,
        AssetType.CoolingMeter,
        AssetType.HeatingAndCoolingMeter,
      ].indexOf(values.assetPositionType as AssetType) < 0
    ) {
      setFieldValue("useCase", AssetUseCase.NONE);
    }
  }, [values.assetPositionType, setFieldValue]);

  return (
    <BlockSpinner loading={creating}>
      <Form data-cy={"asset-position-create"}>
        <div className={"row"}>
          <div className={"col-3"}>
            <h3 className="text-white mb-0">Add Asset Position</h3>
          </div>
          <AssetTypeSelect
            fieldName={"assetPositionType"}
            includeSelect={true}
          />
          <AssetUseCaseSelect
            name={"useCase"}
            paddingX={undefined}
            includeNone={true}
            disabled={
              _.isNil(values.assetPositionType) ||
              [
                AssetType.HeatMeter,
                AssetType.CoolingMeter,
                AssetType.HeatingAndCoolingMeter,
              ].indexOf(values.assetPositionType as AssetType) === -1
            }
          />
          <FormComponent
            fieldName={"customerReference"}
            label={"Customer Reference"}
          />
          <FormComponent
            fieldName={"parentAssetPositionId"}
            label={"Parent Asset Position Id"}
          />
          <div className={"col-12"}>
            <div className={"form-group row"}>
              <div className={"col-12"}>
                <Button
                  type={"submit"}
                  color={"primary"}
                  disabled={isSubmitting || !isValid}
                >
                  Add Position
                </Button>
              </div>
            </div>
            <ErrorBanner error={error} />
          </div>
        </div>
      </Form>
    </BlockSpinner>
  );
}

export default function AssetPositionCreateForm(props: Props): JSX.Element {
  const { createEntity, creating, error } =
    useCreateEntity<AssetPosition>("ASSET_POSITION");

  const submit = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ): void => {
    setSubmitting(true);
    createEntity({
      ...values,
      assetPositionType: values.assetPositionType as AssetType,
      useCase: values.useCase,
      locationId: props.location.locationId,
      schemeId: props.location.schemeId,
      companyId: props.location.companyId,
    })
      .then(() => {
        resetForm();
        if (props.postCreateActions) {
          props.postCreateActions();
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialValues: FormValues = {
    assetPositionType: "undefined",
    useCase: AssetUseCase.NONE,
    customerReference: "",
    parentAssetPositionId: "",
  };
  return (
    <Formik
      onSubmit={submit}
      initialValues={initialValues}
      validateOnMount={true}
      validationSchema={Yup.object().shape(assetPositionSchema)}
    >
      {(formikProps): JSX.Element => (
        <InnerAssetPositionCreateForm
          {...formikProps}
          error={error}
          creating={creating}
        />
      )}
    </Formik>
  );
}
