import React from "react";
import style from './styles.module.scss'
import {ButtonGroup as MondayButtonGroup} from "@psd-platform/monday-ui-react-core";
import NewLabelWrapper, {LabelProps} from "../NewLabelWrapper";
import classNames from "classnames";
import {SubIcon, VibeComponentProps} from "@psd-platform/monday-ui-react-core/dist/types/types";
import {ButtonValue} from "@psd-platform/monday-ui-react-core/dist/types/components/ButtonGroup/ButtonGroupConstants";
import {ButtonType, Size} from "@psd-platform/monday-ui-react-core/dist/types/components/Button/ButtonConstants";
import {DialogPosition} from "@psd-platform/monday-ui-react-core/dist/types/constants";
import {MoveBy} from "@psd-platform/monday-ui-react-core/dist/types/types/MoveBy";


export type  Value = string | number ;

export interface Option {
    label: string;
    value: Value;
}

type ButtonGroupOption = {
    icon?: SubIcon;
    leftIcon?: SubIcon;
    ariaLabel?: string;
    subText?: string;
    value: ButtonValue;
    text: string;
    disabled?: boolean;
    tooltipContent?: string;
};

export type OptionsList =  Option[]

export interface MondayButtonGroupProps extends VibeComponentProps {
  /**
   * @deprecated - use className instead
   */
  componentClassName?: string;
  options: Array<ButtonGroupOption>;
  value?: ButtonValue;
  onSelect?: (value: ButtonValue, name: string) => void;
  size?: Size;
  kind?: ButtonType.SECONDARY | ButtonType.TERTIARY;
  name?: string;
  disabled?: boolean;
  groupAriaLabel?: string;
  /**
   * Where the tooltip should be in reference to the children: Top, Left, Right, Bottom ...
   */
  tooltipPosition?: DialogPosition;
  tooltipHideDelay?: number;
  tooltipShowDelay?: number;
  tooltipContainerSelector?: string;
  tooltipMoveBy?: MoveBy;
  children?: React.ReactNode;
  activeButtonClassName?: string;
  fullWidth?: boolean;
}

export interface ButtonGroupProps extends Omit<MondayButtonGroupProps, 'options'> {
    name: string,
    label?: LabelProps,
    onChange: (value: Value, name: string) => void,
    options: OptionsList;
    value?: Value,
    error?: string,
    touched?: boolean,
}



export const ButtonGroup = (props: ButtonGroupProps): JSX.Element => {
    const {name, options, label, error, touched, onChange, ...rest} = props

    return <NewLabelWrapper {...label} error={error}
                className={style.buttonGroup}
>
        <MondayButtonGroup
            className={classNames({
                'mx-2': true,
                'is-invalid': touched && error,
                'd-inline-block': props.fullWidth === false,
            })}
            id={`select-${name}`}
            size={'medium'}
            options={options.map((v)=> {
                return {
                    value: v.value, text: v.label
                }
            })}
            onSelect={onChange}
            activeButtonClassName={style.activeButton}
            {...rest}

        />

    </NewLabelWrapper>
}

export default ButtonGroup
