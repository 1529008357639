import React from "react";
import BulkUpdateCSVDropZone from "../../../components/BulkUpdate/BulkUpdateCSVDropZone";
import {ParseResult} from "papaparse";
import {SimpleAssetDefinition} from "../../../openapi/model/simpleAssetDefinition";
import * as yup from "yup";
import {AssetType} from "../../../openapi/model/assetType";
import {Col, Row} from "reactstrap";
import {convertCSVToDefinitions, UploadDefinition} from "./GenericUploadPage";

const importSchema = yup.object().shape({
  serialNumber: yup.string().required(),
  address: yup.string().required(),
  assetType: yup.string().required().oneOf(Object.values(AssetType)),
  assetPositionCustomerReference: yup.string(),
  locationReference: yup.string(),
});


export type ParsedAssetDefinition = UploadDefinition<SimpleAssetDefinition>;




export const UploadPage = (props: {
  handleFile: (definitions: Promise<ParsedAssetDefinition[]>) => void;
}): JSX.Element => {
  const processFile = (data: Promise<ParseResult<any>>): void => {
    return props.handleFile(convertCSVToDefinitions(data, importSchema));
  };

  return (
    <Row>
      <Col xs={12}>
        <p>
          Here you can upload a combined list of locations, references and
          assets that will be assigned to this gateway position.
        </p>
        <p>
          Your csv file must contain the columns:
          <ul>
            <li>
              <b>serialNumber</b> - The physical serial number of the asset.
              Take care with serial numbers that start with a &ldquo;0&ldquo;,
              many spreadsheet programmes will delete these when they save the
              data. Make sure to add a &apos; when you type the data.
            </li>
            <li>
              <b>assetType</b> - The type of asset you wish to create (from{" "}
              {Object.values(AssetType)
                .filter(
                  (assetType) =>
                    !["gateway", "sim_card", "unknown"].includes(assetType)
                )
                .join(", ")}
              )
            </li>
            <li>
              <b>address</b> - The address of the asset. This will be assigned
              to the address of the location that is created
            </li>
          </ul>
        </p>
        <p>
          Your csv file can optionally contain the column:
          <ul>
            <li>
              <b>customerAssetPositionReference</b> - The reference for the
              asset position that is created.
            </li>
            <li>
          <b>configuration.xxx.yyy</b> - Additional asset configurations.
          </li>
            <li>
              <b>locationReference</b> - The reference for the location that is created.
            </li>
            <li>
              <b>Group</b> - The group that is assigned the location that is created.
            </li>
          </ul>
        </p>
        <p>Any additional columns in your spreadsheet will be ignored.</p>
        <h4>
          Adding multiple assets to one location
        </h4>
        <p>
          To add multiple assets to one location create one line per asset in the CSV file all with identical <i>`locationReference`</i> values.
        </p>
        <p>The `address` and `group` values, if set, must also be identical for each asset line.</p>
        <h4>Upload</h4>
        <p>
          <a
            href={
              process.env.PUBLIC_URL + "/examples/simple_asset_register.csv"
            }
          >
            Click to download an example file.
          </a>
        </p>
        <p>
          After you upload the spreadsheet you will be shown a preview of the
          sheet with any errors highlighted. After you are shown the preview you
          can then submit the file and create the items that do not have errors.
        </p>

        <BulkUpdateCSVDropZone
          buttonLabel={"Upload asset register CSV"}
          parseComplete={processFile}
        />
      </Col>
    </Row>
  );
};
