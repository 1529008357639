import React from "react";
import {OneCardPage} from "../../components/utils/OneCardPage";
import SchemesList from "./SchemesList";


export const SchemesListPage = (): JSX.Element => {

    return (
        <OneCardPage headerName={'List'} headerParentName={'Schemes'} headerTitle={'Schemes List'} breadcrumbs={
            [{url: '/admin/schemes', name: 'Schemes'}, {url: '/admin/schemes', name: 'List'}]
        } noSchemeInBreadcrumbs={true}>
            <SchemesList/>
        </OneCardPage>
    )
}
