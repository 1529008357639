import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { errorToTitleAndText } from "./errorMessages";

interface Props {
  title?: string;
  body?: JSX.Element;
  error?: Error;
}

const ErrorModal = (props: Props): JSX.Element => {
  const { title, body = "", error } = props;

  const [open, setOpen] = useState(error !== undefined);

  useEffect(() => {
    if (error !== undefined) {
      setOpen(true);
    }
  }, [error]);

  if (error) {
    const errorMessage = errorToTitleAndText(error);
    return (
      <Modal isOpen={open} toggle={() => setOpen(!open)} centered={true}>
        <ModalHeader>
          {title ? title : "Error"}{" "}
          {errorMessage.title && <>: {errorMessage.title}</>}
        </ModalHeader>
        <ModalBody>
          {body}
          {errorMessage.text}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setOpen(false)}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  } else {
    return <></>;
  }
};

export default ErrorModal;
