import React from "react";
import {OneCardPage} from "../../../components/utils/OneCardPage";
import SelectedScheme from "../../../components/Scheme/SelectedScheme";
import DisplayIfUserType from "../../../components/utils/DisplayIfUserType/DisplayIfUserType";
import AggregatedTimeSeriesStatsReport
    from "../../../components/TimeSeries/AggregatedTimeSeriesStatsReport/AggregatedTimeSeriesStatsReport";

export default function StatsTimeSeriesDataReportPage(): JSX.Element {
  return (
    <DisplayIfUserType userTypes={"superadmin"}>
      <OneCardPage
        headerTitle={"Stats Explorer"}
        breadcrumbs={[
          { url: "/admin/report/time_series/stats", name: "Stats Explorer" },
        ]}
      >
        <SelectedScheme>
          {(schemeProps) => {
            return <AggregatedTimeSeriesStatsReport {...schemeProps} />;
          }}
        </SelectedScheme>
      </OneCardPage>
    </DisplayIfUserType>
  );
}
