import React from "react";
import useCreateEntity, { CreateEntityProps } from "../../hooks/createEntity";
import { Button } from "reactstrap";
import classNames from "classnames";
import ErrorModal from "../Error/ErrorModal";
import { CommonButtonProps } from "./UpdateButton";

interface CreateButtonProps<T> extends CommonButtonProps {
  errorMessageBody?: JSX.Element;
  entity: T;
  color?: string;
  showErrorModal?: boolean;
}

const CreateButton = <T,>(
  props: CreateEntityProps & CreateButtonProps<T>
): JSX.Element => {
  const { entityType, path, parseToJson = true, put = false } = props;
  const { creating, createEntity, error } = useCreateEntity<T>(
    entityType,
    path,
    put,
    parseToJson
  );

  const {
    text = "",
    icon = "",
    size = "sm",
    color,
    showErrorModal = true,
  } = props;

  const click = () => {
    createEntity(props.entity).then(([data, headers]) => {
      props.postCreate && props.postCreate(data, headers);
    });
  };

  return (
    <>
      {showErrorModal && (
        <ErrorModal error={error} body={props.errorMessageBody} />
      )}
      <Button
        size={size}
        color={color}
        outline={false}
        onClick={click}
        disabled={creating}
      >
        <i
          className={classNames({
            "fas fa-fw mr-1": true,
            [icon]: !creating,
            "fa-cog fa-spinner fa-spin": creating,
          })}
        />
        {text}
      </Button>
    </>
  );
};

export default CreateButton;
