import React from "react";
import NewLocationForm from "../locations/NewLocationForm";
import { Col, Row } from "reactstrap";
import { OneCardPage } from "../../components/utils/OneCardPage";

interface ComponentProps {
  companyId: string;
  schemeId: string;
}

const SchemeNewLocationPage = (props: ComponentProps): JSX.Element => {
  return (
    <OneCardPage
      headerTitle={`Add Location to ${props.schemeId}`}
      breadcrumbs={[
        { url: `/admin/location`, name: "Locations" },
        {
          url: `/admin/schemes/${props.schemeId}/location/create`,
          name: "Add Location",
        },
      ]}
    >
      <Row>
        <Col xs={12}>
          <NewLocationForm {...props} />
        </Col>
      </Row>
    </OneCardPage>
  );
};

export default SchemeNewLocationPage;
