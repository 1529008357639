import React from "react";

const GatewayConnectivityReportHeaderRow = (assigned = true) => {
  const InnerGatewayConnectivityReportHeaderRow = (
    showScheme = true
  ): [JSX.Element, number] => {
    return [
      <tr key={0}>
        {showScheme && assigned && <th>Scheme Id</th>}
        {assigned && (
          <>
            <th>Location Id</th>
            <th>Address</th>
            <th>Asset Position Id</th>
            <th>Reference</th>
          </>
        )}
        <th>Asset Id</th>
        <th>Serial Number</th>
        <th>Last Contact</th>
        <th>Data Frequency</th>
        <th>Model</th>
        <th>IP Address</th>
        <th>Manufacturer</th>
        <th>Mac Address</th>
        <th>Firmware</th>
      </tr>,
      showScheme ? 14 : assigned ? 13 : 9,
    ];
  };
  return InnerGatewayConnectivityReportHeaderRow;
};

export default GatewayConnectivityReportHeaderRow;
