import React from "react";
import { Button } from "reactstrap";
import { OneCardPage } from "../../components/utils/OneCardPage";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import { User } from "../../openapi/model/user";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import { convertUserToCompanyUser } from "./users";
import UserCompanyListItemForm from "../profile/UserCompanyListItemForm";
import GetListPaginatedTable from "components/Pagination/GetListPaginatedTable";

interface Props {
  companyId: string;
}

const UserList = ({ companyId }: Props): JSX.Element => {
  const header = (
    <tr>
      <th scope="col" className="text-left">
        Name
      </th>
      <th scope="col" className="text-left">
        Email Address
      </th>
      <th scope="col" className="text-left">
        User Type
      </th>
      <th scope="col" className="text-left">
        Receives Alerts
      </th>
      <th scope="col" className="text-left">
        &nbsp;
      </th>
    </tr>
  );

  const row = (value: User): JSX.Element => {
    const user = convertUserToCompanyUser(value);
    return (
      <UserCompanyListItemForm
        userCompany={user}
        showUserDetails={true}
        profileAction={false}
      />
    );
  };

  return (
    <OneCardPage
      headerTitle={"Users List"}
      breadcrumbs={[
        { url: "/admin/users", name: "Users" },
        { url: "/admin/users", name: "List" },
      ]}
      noSchemeInBreadcrumbs={true}
    >
      <GetListPaginatedTable
        entityType={"USER"}
        params={{ companyId }}
        cursor={{ maxItems: 25 }}
        header={[header, 3]}
        row={row}
        data-cy={"user-list"}
        additionalFunctionButtons={
          <DisplayIfUserType userTypes={["admin"]}>
            <CompanyNavLink tag={Button} to={"/admin/users/create"}>
              Create New User
            </CompanyNavLink>
            <CompanyNavLink tag={Button} to={"/admin/users/api/create"}>
              Create New Api Key
            </CompanyNavLink>
          </DisplayIfUserType>
        }
      />
    </OneCardPage>
  );
};

export default UserList;
