import React from "react";
import { DeviceAsset } from "../../openapi/model/deviceAsset";
import { AssetType } from "../../openapi/model/assetType";
import timeSeries from "../../constants/timeSeriesTemplates.json";
import _ from "lodash";
import { AssetPositionNumbers } from "../../openapi/model/assetPositionNumbers";
import { GatewayAsset } from "../../openapi/model/gatewayAsset";


interface FieldDescription {
  name: string;
  factor?: number
}




interface TimeSeriesTemplates {
  [key: string]: {
    data_fields: {
      name: string;
      unit: string;
      field: string | string[] | { elvaco_mbus?: FieldDescription[], guru_systems?: FieldDescription[]};
      aggregations: string[];
    }[];

    aggregations: {
      data_fields: string[];
      aggregations: string[];
    }[];
  };
}

const timeSeriesTemplates: TimeSeriesTemplates = timeSeries;

export const doesDeviceHaveParsedData = (
  device: DeviceAsset | GatewayAsset
): boolean => {
  return [
    AssetType.HeatMeter,
    AssetType.HeatingAndCoolingMeter,
    AssetType.RoomSensor,
    AssetType.Co2RoomSensor,
    AssetType.ElectricityMeter,
    AssetType.WaterMeter,
    AssetType.GasMeter,
    AssetType.Gateway,
    AssetType.CoolingMeter,
  ].includes(device.assetType);
};

export const getFieldsForAssetPositions = (
  assetPositionNumbers: AssetPositionNumbers
): Array<string> => {
  const assetTypes: string[] = Object.keys(assetPositionNumbers);
  const fields = new Set<string>();

  assetTypes.forEach((assetType) => {
    assetType = _.snakeCase(assetType);
    if (Object.prototype.hasOwnProperty.call(timeSeriesTemplates, assetType)) {
      timeSeriesTemplates[
        assetType as keyof typeof timeSeriesTemplates
      ].data_fields.forEach((field: any) => {
        fields.add(field.name);
      });
    }
  });
  return [...fields];
};

export const getFieldsForAssetType = (assetType?: string): Array<string> => {
  if (
    assetType &&
    Object.prototype.hasOwnProperty.call(timeSeriesTemplates, assetType)
  ) {
    return timeSeriesTemplates[
      assetType as keyof typeof timeSeriesTemplates
    ].data_fields.map((x) => x.name);
  } else {
    return [];
  }
};

export const doesAssetHaveDataField = (
  assetType?: string,
  field?: string
): boolean => {
  assetType = _.snakeCase(assetType);
  if (
    Object.prototype.hasOwnProperty.call(timeSeriesTemplates, assetType) &&
    field
  ) {
    const fieldNames = timeSeriesTemplates[
      assetType as keyof typeof timeSeriesTemplates
    ].data_fields.map((x) => x.name);
    return fieldNames.includes(field);
  } else {
    return false;
  }
};

export const formatDataCollectionFrequency = (
  collectionFrequency?: number,
  abbreviate = false
): React.ReactNode => {
  if (collectionFrequency) {
    if (collectionFrequency === 1) {
      return abbreviate ? <>1&nbsp;min</> : "1 minute";
    } else if (collectionFrequency < 60) {
      return (
        <>
          {collectionFrequency}&nbsp;{abbreviate ? "mins" : "minutes"}
        </>
      );
    } else if (collectionFrequency === 60) {
      return "Hourly";
    } else if (collectionFrequency < 1440) {
      return (
        <>
          {collectionFrequency / 60}&nbsp;{abbreviate ? "hrs" : "hours"}
        </>
      );
    } else {
      return "Daily";
    }
  } else {
    return "-";
  }
};
