import React, {CSSProperties} from 'react';
import DataTable, {ConditionalStyles, TableColumn} from "react-data-table-component";
import _ from "lodash";
import {Col} from "reactstrap";
import {UploadDefinition} from "./GenericUploadPage";
import {ErrorRowInfoTooltipIcon} from "./ErrorRowInfoTooltipIcon";

interface UploadPreviewTableProps<T> {
    uploadedEntityName: string;
    definitions: UploadDefinition<T>[];
    requiredHeaders: string[]
    optionalHeaders?: string[]
}

const calculateStats = (
  definitions: UploadDefinition<any>[]
): { valid: number; errors: number } => {
  const errors = definitions.filter(
    (row) => row.errors && row.errors !== []
  ).length;
  return {
    valid: definitions.length - errors,
    errors: errors,
  };
};

const errorRowIcon = (
  row: UploadDefinition<any>,
  rowIndex: number
): JSX.Element => {
  return <ErrorRowInfoTooltipIcon item={row} index={rowIndex} />;
};


const errorRow = (
  style: CSSProperties,
  onError = true
): ConditionalStyles<UploadDefinition<any>> => {
  return {
    when: (row: any): boolean => {
      const errors = row.errors && row.errors !== [];
      if (onError) {
        return errors;
      } else {
        return !errors;
      }
    },
    style: style,
  };
};

const errorCell = (
  style: CSSProperties,
  key: string
): ConditionalStyles<UploadDefinition<any>> => {
  return {
    when: (row: UploadDefinition<any>): boolean => {
      return row.errors
        ? row.errors?.filter((err) => {
            return err?.field === key;
          }).length > 0
        : false;
    },
    style: style,
  };
};


function UploadPreviewTable<T>(props: UploadPreviewTableProps<T>) {
  const { definitions, requiredHeaders, optionalHeaders=[] } = props;
  const columns: TableColumn<UploadDefinition<T>>[] = requiredHeaders
    .map((key: string): TableColumn<UploadDefinition<T>> => {
      return {
        name: key,
        selector: (row: UploadDefinition<T>): string => _.isObject(row.original[key]) ? JSON.stringify(row.original[key]): row.original[key],
        conditionalCellStyles: [
          errorCell({ backgroundColor: "orangeRed" }, key),
        ],
      };
    })
    .concat(
      definitions.length > 0
        ? Object.keys(definitions[0].original)
            .filter((key) => {
              return !requiredHeaders.includes(key);
            })
            .map((key) => {
              const ignored = !optionalHeaders.includes(key)
              return {
                name: (
                  <span>
                    {key} {ignored && <small>(ignored)</small>}
                  </span>
                ),
                selector: (row: any): string => _.isObject(row.original[key]) ? JSON.stringify(row.original[key]): row.original[key],
                conditionalCellStyles: [
                  errorRow({ backgroundColor: ignored ? "grey":'white' }, false),
                ],
              };
            })
        : []
    )
    .concat([
      {
        name: "Errors",
        cell: errorRowIcon,
      },
    ]);

  const conditionalRows: ConditionalStyles<UploadDefinition<T>>[] = [
    errorRow({ backgroundColor: "orange" }),
  ];

  const stats = calculateStats(definitions);

  return (
    <Col xs={12}>
      {stats.valid > 0 ? (
        <>
          <p>
            Your upload is ready. We will attempt to create {stats.valid} {props.uploadedEntityName}
            {stats.valid > 1 && "s"}.
          </p>
          {stats.errors !== 0 && (
            <p>
              <b>
                Warning: Your spreadsheet contains {stats.errors} row
                {stats.errors > 1 && "s"} with a detected error.
              </b>
            </p>
          )}
        </>
      ) : (
        <p>Your upload doesnt contain any valid rows. Please try again.</p>
      )}
      <h3>Preview</h3>
      <DataTable
        columns={columns}
        data={definitions}
        fixedHeader={false}
        pagination={false}
        responsive={true}
        dense={true}
        conditionalRowStyles={conditionalRows}
        customStyles={{ header: { style: { display: "none" } } }}
      />
    </Col>
  )
}

export default UploadPreviewTable;