import React from "react";
import moment, {Moment} from "moment";
import {AssetPositionRow, AssetRow, LocationRow,} from "../../../components/Table/AssetRegisterData";
import {
  singleAggregationTimeAssetDataFormatter,
  singleAggregationTimeAssetDataSelector,
} from "../../../components/Table/Formatters";
import PrivacyModeDataTable, {PrivacyModeTableColumn,} from "../../../components/Text/PrivacyModeDataTableCell";
import {startCase} from "lodash";
import {assetPositionSortValue, locationSortValue} from "../allPeriodReportTable/AssetRegisterReportTable";
import {SingleAggregationTimeAssetData} from "../../../components/TimeSeries/model";

type Props = {
  reportData: Array<SingleAggregationTimeAssetData>;
  times: Array<Date>;
  startTime: Moment;
  endTime: Moment;
  showAsset?: boolean;
};

export default function TimeSeriesReportTable(props: Props): JSX.Element {
  const { startTime, endTime,showAsset=true } = props;
  const columns: PrivacyModeTableColumn<SingleAggregationTimeAssetData>[] = [
      ...[{
      name: "Location",
      selector: locationSortValue,
      cell: LocationRow(startTime, endTime),
      sortable: true,
        width: "250px",
      compact: true,
      privacyMode: true
    },

    {
      name: "Asset Position",
      selector: assetPositionSortValue,
      cell: AssetPositionRow(startTime, endTime),
      sortable: true,
      width: "200px",
      compact: true,
      privacyMode: true
    },
    {
      name: "Asset Type",
      selector: (row:SingleAggregationTimeAssetData) => row.assetType,
      cell: (row:SingleAggregationTimeAssetData) => startCase(row.assetType),
      sortable: true,
      compact: true,
      width: '120px',
    }],
  ...(showAsset ? [{
      name: "Asset",
      selector: (row:SingleAggregationTimeAssetData) => row.serialNumber || "-",
      sortable: true,
      cell: AssetRow(startTime, endTime, false, true),
      width: "150px",
      compact: true,
      privacyMode: true
    }]: []),

      ...props.times.map((value) => {
      return {
        name: moment(value).format("DD/MM/YYYY"),
        selector: singleAggregationTimeAssetDataSelector(value),
        right: true,
        compact: true,
        sortable: true,
        minWidth: "100px",
        format: singleAggregationTimeAssetDataFormatter(value, 1),
      } as PrivacyModeTableColumn<SingleAggregationTimeAssetData>;
    })
  ];

  return (
    <PrivacyModeDataTable
      columns={columns}
      data={props.reportData}
      pagination
      fixedHeader
    />
  );
}
