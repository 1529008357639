import React from 'react';

function FormatTextAtSize(props: {
    breakpoint: 'sm'|'md'|'lg';
    smallClassName?: string;
    largeClassName?: string;
    smallBrackets?: boolean
    children: React.ReactNode
}) {
    const {breakpoint, children, smallBrackets=false} = props;
    return <>
                <span className={`d-inline-block d-${breakpoint}-none ${props.smallClassName}`}>
                    {smallBrackets && "("}
                {children}
                    {smallBrackets && ")"}
                </span>
                <span className={`d-none d-${breakpoint}-inline-block ${props.largeClassName}`}>
                {children}
                </span>
    </>
}

export default FormatTextAtSize;