import React, { useState } from "react";
import { OneCardPage } from "../../../components/utils/OneCardPage";
import RequireScheme from "../../../components/Scheme/RequireScheme";
import { inclusiveDatePeriod } from "../../../components/Forms/DateTimePicker";
import { parseSearchString } from "../../../components/navigation/SearchString";
import moment, { Moment } from "moment";
import DateRangeOnlySelectorForm from "../vwartReport/DateRangeOnlySelectorForm";
import { Col, Row } from "reactstrap";
import { Form, Formik } from "formik";
import RadioButtonGroup from "../../../components/Forms/RadioButtonGroup";
import { AggregatedReportRequest } from "../../../openapi/model/aggregatedReportRequest";
import AggregatedTimeSeriesApiWrapper from "../aggregatedTimeSeriesReport/AggregatedTimeSeriesApiWrapper";
import DataQualityReportTable from "./DataQualityReportTable";
import { AssetRegisterAggregatedTimeSeriesResponse } from "../../../openapi/model/assetRegisterAggregatedTimeSeriesResponse";
import { AssetRegisterDataItem } from "../../../components/Table/AssetRegisterData";
import _ from "lodash";
import { AssetRegisterItemAggregatedTimeSeries } from "../../../openapi/model/assetRegisterItemAggregatedTimeSeries";
import SummaryDataQualityTimeSeriesAreaChart from "./SummaryDataQualityTimeSeriesAreaChart";
import { useLocation } from "react-router-dom";

interface VWARTReportParams {
  startDatetime?: Moment;
  endDatetime?: Moment;
}

const searchDatetimeParams = (
  searchString: string
): Required<VWARTReportParams> => {
  const [startDate, endDate] = inclusiveDatePeriod();
  const searchParams = parseSearchString(searchString);
  const startDatetime = searchParams.startDatetime
    ? moment(searchParams.startDatetime as string)
    : startDate;
  const endDatetime = searchParams.startDatetime
    ? moment(searchParams.endDatetime as string)
    : moment(+endDate);
  return { startDatetime, endDatetime };
};

export const QUALITY_AGGREGATIONS = [
  "expected_rows",
  "received_rows_complete",
  "received_rows_incomplete",
  "received_rows_with_errors",
  "received_rows_with_value_errors",
];

const sumValues = (values: any[]): number => {
  return values.filter(_.isNumber).reduce((sum, current) => sum + current, 0);
};

export interface AssetDataQualityDataItem extends AssetRegisterDataItem {
  expectedRows?: number;
  missingRows?: number;
  receivedRowsComplete?: number;
  receivedRowsIncomplete?: number;
  receivedRowsWithErrors?: number;
  receivedRowsWithValueErrors?: number;
}

const mapData = (
  data: AssetRegisterAggregatedTimeSeriesResponse
): Array<AssetDataQualityDataItem> => {
  return data.data.map((value: AssetRegisterItemAggregatedTimeSeries) => {
    const { aggregations, ...asset } = value;
    let mappedAggregations = Object.fromEntries(
      Object.entries(aggregations.qualityAssessment).map(([key, data]) => {
        return [key, sumValues(data)];
      })
    );
    mappedAggregations = {
      ...mappedAggregations,
      missingRows:
        mappedAggregations.expectedRows -
        mappedAggregations.receivedRowsComplete -
        mappedAggregations.receivedRowsIncomplete -
        mappedAggregations.receivedRowsWithErrors -
        mappedAggregations.receivedRowsWithValueErrors,
    };
    return {
      ...asset,
      ...mappedAggregations,
    };
  });
};

const DataQualityReportByRows = (props: {
  companyId?: string;
  schemeId?: string;
}): JSX.Element => {
  const { startDatetime, endDatetime } = searchDatetimeParams(
    useLocation().search
  );

  const params = (): AggregatedReportRequest => {
    if (props.companyId && props.schemeId) {
      return {
        companyId: props.companyId,
        schemeId: props.schemeId,
        aggregations: [
          ...QUALITY_AGGREGATIONS.map((value) => {
            return {
              parameter: "Quality Assessment",
              aggregation: value,
            };
          }),
        ],
        startTime: startDatetime.toDate(),
        endTime: endDatetime.toDate(),
        period: "DAILY",
      };
    } else {
      throw new Error("Scheme or Company is not selected.");
    }
  };

  const [expandedTable, setExpandedTable] = useState(false);

  return (
    <OneCardPage
      headerTitle={"Data Quality Report"}
      breadcrumbs={[
        { url: "/admin/report/data_quality", name: "Data Quality" },
      ]}
    >
      <RequireScheme>
        <Row>
          <Col xs={12}>
            <DateRangeOnlySelectorForm
              {...searchDatetimeParams(useLocation().search)}
            />
            <Formik
              initialValues={{ expanded: false }}
              onSubmit={(x): void => {
                setExpandedTable(x.expanded);
              }}
            >
              {({ setFieldValue, values, submitForm }): JSX.Element => (
                <Form>
                  <RadioButtonGroup
                    fieldName={"expanded"}
                    title={"Expanded"}
                    currentValue={values.expanded}
                    paddingX={0}
                    options={[
                      { name: "Yes", value: true },
                      { name: "No", value: false },
                    ]}
                    setFieldValue={setFieldValue}
                    onChange={async (): Promise<void> => {
                      await submitForm();
                    }}
                  />
                </Form>
              )}
            </Formik>
            {props.companyId && props.schemeId && (
              <AggregatedTimeSeriesApiWrapper request={params()}>
                {({ data }) => (
                  <>
                    <Row>
                      <SummaryDataQualityTimeSeriesAreaChart
                        data={data}
                        xl={6}
                        lg={6}
                        xs={12}
                        sm={12}
                        md={12}
                      />
                    </Row>

                    <Row>
                      {data && (
                        <>
                          {" "}
                          <Col xs={12}>
                            <DataQualityReportTable
                              data={mapData(data)}
                              {...{ startDatetime, endDatetime }}
                              expanded={expandedTable}
                              downloadFilename={`${props.schemeId}_data_quality_report`}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </>
                )}
              </AggregatedTimeSeriesApiWrapper>
            )}
          </Col>
        </Row>
      </RequireScheme>
    </OneCardPage>
  );
};

export default DataQualityReportByRows;
