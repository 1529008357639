import "./wdyr"; // <--- first import

import * as serviceWorker from "./serviceWorker";
// plugins styles from node_modules
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// our styles
import "./index.scss";
import App from "App";
import { configureAmplify } from "./Config";
// plugins styles downloaded
// core styles

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
configureAmplify();
App();
