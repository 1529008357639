import React from 'react';
import {Moment} from "moment/moment";
import {
    AssetRegisterAggregatedTimeSeriesResponse
} from "../../../openapi/model/assetRegisterAggregatedTimeSeriesResponse";
import AllPeriodReportTable from "../allPeriodReportTable/NewAllPeriodReportTable";

interface Props {
    schemeId?: string;
    startDatetime: Moment;
    endDatetime: Moment;
    data?: AssetRegisterAggregatedTimeSeriesResponse;
}

function VwadtReportView(props: Props) {
    const {startDatetime, endDatetime} = props;

    return (
        <>
            {props.schemeId && props.data && (
                <AllPeriodReportTable
                    data={props.data}
                    columnDefinitions={[
                        {
                            parameter: "energyHeatingVolume",
                            aggregation: "difference",
                            name: "Energy:Volume Ratio",
                        },
                        {
                            parameter: "volume",
                            aggregation: "difference",
                            name: "Volume",
                        },
                    ]}
                    startDatetime={startDatetime}
                    endDatetime={endDatetime}
                    downloadFilename={`${props.schemeId}_vwadt_report`}
                />
            )}
        </>
    );
}

export default VwadtReportView;