import React, { useCallback, useEffect, useRef, useState } from "react";
import AssetTypeSelect from "../AssetTypeSelect";
import { Form, Formik, FormikProps } from "formik";
import { Button, Col, Row } from "reactstrap";
import {
  convertStringUndefined,
  createSearchString,
  parseSearchString,
} from "../../../components/navigation/SearchString";
import RadioButtonGroup from "../../../components/Forms/RadioButtonGroup";
import _ from "lodash";
import FormComponent from "../../../components/Forms/FormComponent";
import { useLocation, useNavigate } from "react-router-dom";
import LocationGroupSelect from "../../../components/LocationGroup/LocationGroupSelect";
import DisplayIfUserType from "components/utils/DisplayIfUserType/DisplayIfUserType";
import useSetFormValueOnSchemeChange from "../../../hooks/setFormValueOnSchemeChange";
import { AssetUseCase } from "../../../openapi/model/assetUseCase";
import AssetUseCaseSelect from "../../../components/Asset/AssetUseCaseSelect";
import { Status } from "openapi/model/status";

type Props = {
  includeNonCreateables?: boolean;
  filterUnassignedField?: string;
  includeTextSearch?: boolean;
  includeGroup?: boolean;
  includeUseCase?: boolean;
  includeStatus?: boolean;
};

type State = {
  assetType?: string | string[];
  useCase?: AssetUseCase | AssetUseCase[];
  assetPositionId?: string;
  assetId?: string;
  textSearch?: string;
  group?: string;
  status?: Status;
  ready: boolean;
};

export default function AssetListFilterForm(props: Props): JSX.Element {
  const [state, setState] = useState<State>({ ready: false });

  const location = useLocation();
  const navigate = useNavigate();
  const formikRef = useRef<FormikProps<Record<string, unknown>>>(null);

  const {
    filterUnassignedField = "assetPositionId",
    includeNonCreateables = true,
    includeTextSearch = false,
    includeGroup = true,
    includeUseCase = true,
    includeStatus = true,
  } = props;

  const setFiltersToRoute = useCallback(
    (values: any): void => {
      const stringValues = { ...values };
      navigate({
        pathname: location.pathname,
        search: createSearchString(convertStringUndefined(stringValues)),
        hash: location.hash,
      });
    },
    [location.pathname, location.hash, navigate]
  );

  useEffect(() => {
    const queryParams = parseSearchString(location.search);
    const query = {
      assetType: (
        _.isString(queryParams.assetType)
          ? queryParams.assetType
          : _.isArray(queryParams.assetType)
      )
        ? (queryParams.assetType as string[])
        : "undefined",
      useCase: _.isString(queryParams.useCase)
        ? (queryParams.useCase as AssetUseCase)
        : _.isArray(queryParams.useCase)
        ? (queryParams.useCase as AssetUseCase[])
        : [AssetUseCase.NONE, AssetUseCase.DOMESTIC, AssetUseCase.PROCESS, AssetUseCase.COMMERCIAL],
      [filterUnassignedField]: _.isString(queryParams[filterUnassignedField])
        ? (queryParams[filterUnassignedField] as string)
        : "undefined",
      textSearch:
        includeTextSearch && _.isString(queryParams.textSearch)
          ? queryParams.textSearch
          : undefined,
      group:
        includeGroup && _.isString(queryParams.group)
          ? queryParams.group
          : "undefined",
      status: includeStatus
        ? _.isString(queryParams.status)
          ? (queryParams.status as Status)
          : Status.ACTIVE
        : undefined,
    };
    setState({ ...query, ready: true });
    setFiltersToRoute(query);
  }, [
    setFiltersToRoute,
    location.search,
    filterUnassignedField,
    includeGroup,
    includeTextSearch,
    includeStatus,
  ]);

  useSetFormValueOnSchemeChange(formikRef, "group", "undefined", true);

  return (
    <>
      {state.ready && (
        <Row>
          <Col xs={12} className={"mb-2"}>
            <Formik
              innerRef={formikRef}
              initialValues={state}
              onSubmit={(x): void => {
                setFiltersToRoute(x);
              }}
            >
              {({ setFieldValue, values }): JSX.Element => (
                <Form>
                  <Row>
                    {includeTextSearch && (
                      <FormComponent
                        label={"Search"}
                        fieldName={"textSearch"}
                        submitSpinner={false}
                      />
                    )}
                    <AssetTypeSelect
                      fieldName={"assetType"}
                      allowUnknown={true}
                      includeNonCreateables={includeNonCreateables}
                      isMulti={true}
                      submitSpinner={false}
                    />
                    {includeUseCase && (
                      <AssetUseCaseSelect
                        name={"useCase"}
                        paddingX={undefined}
                        includeAll={false}
                        isMulti={true}
                        submitSpinner={false}
                        includeNone={true}
                      />
                    )}
                    {includeGroup && (
                      <DisplayIfUserType userTypes={"superadmin"}>
                        <LocationGroupSelect
                          fieldName={"group"}
                          submitSpinner={false}
                        />
                      </DisplayIfUserType>
                    )}
                    <RadioButtonGroup
                      fieldName={filterUnassignedField}
                      title={"Filter Unassigned"}
                      options={[
                        { name: "Yes", value: "NONE" },
                        {
                          name: "No",
                          value: "undefined",
                        },
                      ]}
                      setFieldValue={setFieldValue}
                      currentValue={values[filterUnassignedField]}
                      submitSpinner={false}
                    />
                    {includeStatus && (
                      <RadioButtonGroup
                        fieldName={"status"}
                        title={"Status"}
                        options={[
                          { name: "Active", value: "ACTIVE" },
                          {
                            name: "Deleted",
                            value: "DELETED",
                          },
                        ]}
                        setFieldValue={setFieldValue}
                        currentValue={values.status}
                        submitSpinner={false}
                      />
                    )}
                    <Col xs={12} className={"text-right"}>
                      <Button type={"submit"} color={"primary"}>
                        Filter
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      )}
    </>
  );
}
