import React from "react";
import {
  ColumnMap,
  TimeSeriesDataParams,
} from "../../../../model/deviceTimeSeries";
import { Moment } from "moment";
import GenericCharts from "../GenericCharts";
// import HeatMeterFlowAndTemperatureChart from "./HeatMeterFlowAndTemperatureChart";

const ElectricityMeterCharts = (props: {
  mappedTimeSeriesColumns: ColumnMap;
  times: Moment[];
  params: TimeSeriesDataParams;
  loading: boolean;
  error?: Error;
}): JSX.Element => {
  if (props.params.projection === "raw") {
    return <GenericCharts {...props} />;
  } else {
    return (
      <>
        <GenericCharts {...props} />
      </>
    );
  }
};

export default ElectricityMeterCharts;
