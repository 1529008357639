import React from 'react';
import {Col, Row} from "reactstrap";
import SelectComboField from "../../Forms/SelectComboField";
import AssetTypeSelectCombo, {meterOptions} from "../../Asset/AssetTypeSelectCombo";
import AssetParameterSelectCombo, {
    AssetType,
    AssetTypeList
} from "../AssetParameterSelectCombo/AssetParameterSelectCombo";
import {get, isNil} from "lodash";
import AssetAggregationSelectCombo from "../AssetAggregationSelectCombo/AssetAggregationSelectCombo";
import PeriodSelectCombo from "../../DateTime/PeriodSelectCombo";
import LocationGroupSelectCombo from "../../Location/LocationGroupSelectCombo/LocationGroupSelectCombo";
import ButtonGroupField from "../../Forms/ButtonGroupField";
import ButtonGroup, {OptionsList} from "../../Forms/ButtonGroup";
import {ReportView} from "../../../openapi/model/reportView";
import {LabelProps} from "../../Forms/NewLabelWrapper";
import {Period} from "../../../openapi/model/period";
import {useFormikContext} from "formik";
import {OnChangeValue} from "react-select";
import {Value} from "../../Forms/SelectCombo/SelectCombo";
import {getAggregations, getFields} from "../model";
import * as Yup from "yup";
import {GroupBy} from "../../../openapi/model/groupBy";
import NewAssetUseCaseSelect from "../../Asset/NewAssetUseCaseSelect";
import {AssetUseCase} from "../../../openapi/model/assetUseCase";

export interface AggregatedTimeSeriesQuerySubFormProps {
    wide?: boolean,
    showErrors?: boolean,
    submitButton?: React.ReactNode,
    namespace: string;
    disabled?: boolean;
    showLocationGroupSelect?: boolean;
    showAssetUseCase?: boolean;
    showGroup?: boolean;
    showGroupByScheme?: boolean;
    showGroupByGroup?: boolean;
}

export interface AggregatedTimeSeriesQuerySubFormValues {
    assetType: AssetTypeList;
    parameter: string;
    aggregation: string;
    period: Period;
    group?: string;
    viewBy?: ReportView;
    groupBy?: GroupBy;
    useCase?: AssetUseCase[];
}

export const AggregatedTimeSeriesQueryDefaultValues: AggregatedTimeSeriesQuerySubFormValues = {
    assetType: meterOptions as AssetTypeList,
    parameter: "Energy (Heating)",
    aggregation: 'last',
    period: Period.DAILY,
    viewBy: ReportView.ASSETPOSITION,
}

function handleAssetTypeChange(
    setParameter: (v: string | null) => Promise<any>, setAggregation: (v: string | null) => Promise<any>
): (values: AggregatedTimeSeriesQuerySubFormValues, change: OnChangeValue<Value, boolean>) => Promise<any> {

    return (values, change) => {
        const [newParameter, newAggregation] = getNewParameterAndAggregation(
            values.parameter || null, values.aggregation || null, change as string[] | null
        )
        return setAggregation(newAggregation).then(() => setParameter(newParameter))

    }
}


function getNewParameterAndAggregation(
    currentParameter: string | null,
    currentAggregation: string | null,
    newAssetTypeList: string[] | null,
): (string | null)[] {
    if (isNil(newAssetTypeList) || ((newAssetTypeList as Value[]).length === 0)) {
        return [null, null]
    } else {
        const newParameters = getFields(newAssetTypeList as AssetTypeList)
        if (currentParameter) {
            if (newParameters.indexOf(currentParameter) === -1) {
                return [newParameters[0], getAggregations(newParameters[0])[0]]
            } else {
                if (currentAggregation) {
                    const newAggregations = getAggregations(currentParameter)
                    if (newAggregations.indexOf(currentAggregation) === -1) {
                        return [currentParameter, newAggregations[0]]
                    }
                }
            }
        }
    }
    return [currentParameter, currentAggregation]
}

function handleParameterChange(
    setAggregation: (v: string | null) => Promise<any>): (values: AggregatedTimeSeriesQuerySubFormValues, change: OnChangeValue<Value, boolean>) => Promise<any> {
    return (values, change) => {
        const [_, newAggregation] = getNewParameterAndAggregation(
            change as string | null, values.aggregation || null, values.assetType as string[] | null
        )
        return setAggregation(newAggregation)
    }
}

export const AggregatedTimeSeriesQuerySubFormSchema = Yup.object().shape({
    period: Yup.string().nullable().required('Required'),
    assetType: Yup.array().of(
        Yup.string().required(),
    ).nullable().min(1).required('Required'),
    aggregation: Yup.string().nullable().required('Required'),
    parameter: Yup.string().nullable().required('Required')
})

function AggregatedTimeSeriesQuerySubForm(props: AggregatedTimeSeriesQuerySubFormProps) {
    const {
        namespace,
        wide = false,
        showErrors = true,
        disabled = false

    } = props;

    const labelDefaults: Partial<LabelProps> = {size: 'sm', showErrors};

    const context = useFormikContext<{ namespace: AggregatedTimeSeriesQuerySubFormValues }>()

    const {setFieldValue} = context;

    const values: AggregatedTimeSeriesQuerySubFormValues = get(context.values, namespace);

    const withNamespace = (fieldName: string): string => {
        return namespace ? `${namespace}.${fieldName}` : fieldName
    }


    return (
        <Row noGutters>
            <Col md={wide ? 6 : 12} xs={12}>
                <Row noGutters>
                    <Col xs={wide ? (props.showAssetUseCase ? 8 : 12) : 12}>
                        <SelectComboField name={withNamespace('assetType')}
                                          onChange={(change) => {
                                              return handleAssetTypeChange(
                                                  (v) => setFieldValue(withNamespace('parameter'), v),
                                                  (v) => setFieldValue(withNamespace('aggregation'), v),
                                              )(values, change)
                                          }}
                        >
                            {(fieldProps) =>
                                <AssetTypeSelectCombo {...fieldProps}
                                                      includeNonCreateables={false}
                                                      includeWirelessCluster={false}
                                                      allowUnknown={false}
                                                      isMulti={true}
                                                      disabled={disabled}
                                                      label={{label: 'Asset Type', ...labelDefaults}}
                                />
                            }
                        </SelectComboField>
                    </Col>
                    {props.showAssetUseCase &&
                        <Col xs={wide ? 4 : 12}>
                            <SelectComboField name={withNamespace('useCase')}>
                                {(fieldProps) => <NewAssetUseCaseSelect
                                    label={{label: 'Asset Use Cases', size: 'sm', showErrors}}
                                    isMulti={true}
                                    {...fieldProps}/>}
                            </SelectComboField>
                        </Col>
                    }
                </Row>
                <Row noGutters>
                    <Col xs={wide ? 4 : 12}>
                        <SelectComboField name={withNamespace('parameter')}
                                          onChange={(change) => {
                                              return handleParameterChange((v) => setFieldValue(withNamespace('aggregation'), v))(values, change)
                                          }}
                        >
                            {(fieldProps) =>
                                <AssetParameterSelectCombo {...fieldProps}
                                                           clearable={false}
                                                           disabled={isNil(values.assetType) || (values.assetType as AssetType[]).length === 0 || disabled}
                                                           assetType={values.assetType}
                                                           isMulti={false}
                                                           label={{label: 'Parameter', ...labelDefaults}}
                                />
                            }
                        </SelectComboField>

                    </Col>
                    <Col xs={wide ? 4 : 12}>
                        <SelectComboField name={(withNamespace('aggregation'))}>
                            {(fieldProps) =>
                                <AssetAggregationSelectCombo {...fieldProps}
                                                             clearable={false}
                                                             isMulti={false}
                                                             label={{label: 'Aggregation', ...labelDefaults}}
                                                             assetParameter={values.parameter || ''}
                                                             disabled={isNil(values.parameter) || disabled}
                                />
                            }
                        </SelectComboField>

                    </Col>
                    <Col xs={wide ? 4 : 12}>
                        <SelectComboField name={withNamespace('period')}>
                            {(fieldProps) =>
                                <PeriodSelectCombo {...fieldProps}
                                                   isMulti={false}
                                                   clearable={false}
                                                   disabled={disabled}
                                                   label={{label: 'Period', ...labelDefaults}}
                                />
                            }
                        </SelectComboField>

                    </Col>
                </Row>
            </Col>
            <Col xs={12} md={wide ? 6 : 12}>
                <Row noGutters>
                    {
                        (props.showLocationGroupSelect === undefined || props.showLocationGroupSelect) &&
                        <Col xs={wide ? 5 : 12} lg={wide ? 6 : 12}>
                            <SelectComboField name={withNamespace('group')}>
                                {(fieldProps) =>
                                    <LocationGroupSelectCombo {...fieldProps}
                                                              isMulti={false}
                                                              clearable={false}
                                                              disabled={disabled}
                                                              label={{label: 'Group', ...labelDefaults}}
                                    />
                                }
                            </SelectComboField>

                        </Col>
                    }
                    <Col xs={wide ? 7 : 12} lg={wide ? 6 : 12}>
                        {props.showGroup ?
                            <ButtonGroupField name={withNamespace('groupBy')}>
                                {(fieldProps) =>
                                    <ButtonGroup {...fieldProps}
                                                 label={{label: 'Group By', ...labelDefaults}}
                                                 disabled={disabled}
                                                 options={[
                                                     {label: 'None', value: GroupBy.NONE},
                                                     props.showGroupByScheme ? {
                                                         label: 'Scheme',
                                                         value: GroupBy.SCHEME
                                                     } : undefined,
                                                     props.showGroupByGroup ? {
                                                         label: 'Group',
                                                         value: GroupBy.GROUPALL
                                                     } : undefined
                                                 ].filter(v => !isNil(v)) as OptionsList}/>
                                }
                            </ButtonGroupField>

                            : <ButtonGroupField name={withNamespace('viewBy')}>
                                {(fieldProps) =>
                                    <ButtonGroup {...fieldProps}
                                                 label={{label: 'View By', ...labelDefaults}}
                                                 disabled={disabled}
                                                 options={[{label: 'Asset', value: ReportView.ASSET}, {
                                                     label: 'Asset Position',
                                                     value: ReportView.ASSETPOSITION
                                                 }]}/>
                                }
                            </ButtonGroupField>}
                    </Col>
                    {props.submitButton}
                </Row>
            </Col>
        </Row>
    );
}

export default AggregatedTimeSeriesQuerySubForm;