import React from "react";
import { TariffInstanceOut } from "../../openapi/model/tariffInstanceOut";
import { TariffServiceDescription, TariffServiceDescriptionMap } from "./model";
import ModelLaunchButton from "./ModelLaunchButton";
import NewTariffServiceFormModal from "./NewTariffServiceFormModal";
import { TariffInstanceIn } from "../../openapi/model/tariffInstanceIn";
import { Table } from "reactstrap";
import NewTariffInstanceRatesForm from "./NewTariffInstanceRatesForm";
import { Form, Formik } from "formik";
import { TariffInstanceRates } from "../../openapi/model/tariffInstanceRates";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";

type FormRate = Pick<
  TariffInstanceRates,
  | "unitConsumptionRate"
  | "standingChargeRate"
  | "standingChargeVatRate"
  | "unitConsumptionVatRate"
>;

function TariffInstanceListDisplay(props: {
  tariffInstances: Array<TariffInstanceOut>;
  addNewService: (newService: TariffServiceDescription) => Promise<any>;
  services: TariffServiceDescriptionMap;
  addNewInstance: (newInstance: TariffInstanceIn) => Promise<any>;
}) {
  const { services, tariffInstances } = props;

  const serviceOrder = Object.keys(props.services).sort();
  const instances = Object.fromEntries(
    tariffInstances.map((v) => [v.startDate, v])
  );

  const instanceOrder = Object.keys(instances).sort();

  const addServiceDatesToRates = (
    formValues: FormRate[]
  ): TariffInstanceRates[] => {
    return formValues.map((v, i): TariffInstanceRates => {
      return {
        ...props.services[serviceOrder[i]],
        ...v,
      };
    });
  };

  return (
    <div>
      <Formik
        onSubmit={(v) =>
          props.addNewInstance({
            startDate: v.startDate,
            rates: addServiceDatesToRates(v.rates),
          })
        }
        initialValues={{
          startDate: new Date().toISOString().slice(0, 10),
          rates: [],
        }}
      >
        <Form>
          <Table>
            <thead>
              <tr>
                <th></th>
                {serviceOrder.map((value, i) => (
                  <th colSpan={4} key={i}>
                    {services[value].serviceName}
                  </th>
                ))}
                {tariffInstances.length === 0 && (
                  <th>
                    <ModelLaunchButton
                      color={"primary"}
                      buttonLabel={"Add New Service"}
                      className={"btn-sm"}
                    >
                      {({ toggle }) => (
                        <NewTariffServiceFormModal
                          create={(value) =>
                            props.addNewService(value).then(toggle)
                          }
                          toggle={toggle}
                        />
                      )}
                    </ModelLaunchButton>
                  </th>
                )}
              </tr>
              <tr>
                <th>Start Date</th>
                {serviceOrder.map((_value) => (
                  <>
                    <th>Net Unit Cost</th>
                    <th>Unit VAT Rate (%)</th>
                    <th>Daily Cost</th>
                    <th>Daily VAT Rate (%)</th>
                  </>
                ))}
              </tr>
            </thead>
            <tbody>
              {instanceOrder.map((value, i) => (
                <tr key={i}>
                  <td>{value}</td>
                  {serviceOrder.map((serviceName) => {
                    const instance = instances[value];
                    const service = instance.rates.filter(
                      (v) => v.serviceName === serviceName
                    );
                    if (service.length === 1) {
                      const theService = service[0];
                      return (
                        <>
                          <td width={50}>{theService.unitConsumptionRate}</td>
                          <td width={50}>
                            {theService.unitConsumptionVatRate}
                          </td>
                          <td width={50}>{theService.standingChargeRate}</td>
                          <td width={50}>{theService.standingChargeVatRate}</td>
                        </>
                      );
                    } else {
                      throw new Error(
                        "The instance has more than one service with the same name or it doesnt have a service it should."
                      );
                    }
                  })}
                </tr>
              ))}
              <DisplayIfUserType userTypes={"superadmin"}>
                <NewTariffInstanceRatesForm
                  services={serviceOrder.map((v) => services[v])}
                />
              </DisplayIfUserType>
            </tbody>
          </Table>
        </Form>
      </Formik>
    </div>
  );
}

export default TariffInstanceListDisplay;
