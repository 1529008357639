import React from "react";
import { Form, Formik } from "formik";
import { Col, Row } from "reactstrap";
import RadioButtonGroup from "../../components/Forms/RadioButtonGroup";
import _ from "lodash";
import useGetParsedSearchString from "../../hooks/getParsedSearchString";

const AlarmListFilterForm = (): JSX.Element => {
  const { ready, updateFilters, params } =
    useGetParsedSearchString<{ status: string }>();

  const status = _.isString(params.status) ? params.status : "all";

  return (
    <>
      {ready && (
        <Row className={"justify-content-end"}>
          <Col sm={"auto"} xs={12} className={"mb-2"}>
            <Formik
              initialValues={{ status }}
              onSubmit={(x): void => {
                updateFilters(x);
              }}
            >
              {({ setFieldValue, values, submitForm }): JSX.Element => (
                <Form>
                  <Row>
                    <RadioButtonGroup
                      fieldName={"status"}
                      title={"Alarm Status"}
                      options={[
                        { name: "All", value: "all" },
                        {
                          name: "Open",
                          value: "open",
                        },
                      ]}
                      labelPosition={"right"}
                      autoSize={true}
                      setFieldValue={setFieldValue}
                      currentValue={values.status}
                      submitSpinner={false}
                      onChange={() => {
                        setTimeout(submitForm, 0);
                      }}
                    />
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      )}
    </>
  );
};

export default AlarmListFilterForm;
