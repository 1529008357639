import React from 'react';
import {useParams} from "react-router-dom";
import useGetEntity from "../../hooks/getEntity";
import {useSelectedCompany} from "../../reducers/company";
import {OneCardPage} from "../../components/utils/OneCardPage";
import {WirelessSurveyOut} from "../../openapi/model/wirelessSurveyOut";
import WirelessSurveyHeading from "./WirelessSurveyHeading";
import WirelessSurveyTabs from "./WirelessSurveyTabs";

function WirelessSurveyHomepage() {

    const {surveyId} = useParams();
    const company = useSelectedCompany();

    if (surveyId === undefined || company === undefined) {
        throw new Error('survey and company must be defined')
    }


    const [{data, loading}, refresh] = useGetEntity<WirelessSurveyOut>(
        {
            entityType: 'WIRELESS_SURVEY',
            entityId: surveyId,
            companyId: company.companyId,
            schemeId: undefined
        }
    )


    return (
        <OneCardPage headerTitle={'Survey'} loading={loading}>
            {
                data &&
                <>
                    <WirelessSurveyHeading survey={data}/>
                    <WirelessSurveyTabs survey={data} refreshSurvey={refresh}/>
                </>
            }
        </OneCardPage>
    )
}

export default WirelessSurveyHomepage;