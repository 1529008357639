/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
// react library for routing
// core components
import AuthNavbar from "../components/Navbars/AuthNavbar";
import AuthFooter from "../components/Footers/AuthFooter";

import { Col, Container, Row } from "reactstrap";
import AuthRoutes from "../routes/authRoutes";

const Auth = () => {
  useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);

  return (
    <>
      <div className="main-content">
        <Container>
          <Row>
            <Col style={{ height: "88px" }}>
              <AuthNavbar />
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
              <AuthRoutes />
            </Col>
          </Row>
        </Container>
      </div>
      <AuthFooter />
    </>
  );
};

export default Auth;
