import {useField} from "formik";
import React from "react";
import Select, {
    ActionMeta,
    GroupBase,
    MultiValue,
    OnChangeValue,
    OptionsOrGroups,
    Props as SelectProps,
    SingleValue,
} from "react-select";
import LabelWrapper, {FormFieldProps} from "./LabelWrapper";
import _ from "lodash";

import {flattenNestedLists} from "../Location/model";

export interface Option {
    label: string | number;
    value: string | number | boolean;
}

export interface SelectFieldProps extends FormFieldProps, SelectProps<Option> {
    title?: string;
}

export function isResultSingle(
    result: SingleValue<Option> | MultiValue<Option>
): result is SingleValue<Option> {
    if (_.isArray(result)) {
        return false;
    }
    return true;
}

export interface CustomSelectProps extends SelectFieldProps {
    name: string;
    label?: string;
    title?: string;
    disabled?: boolean;
    errors?: any;
    touched?: any;
}

function flattenOptionGroups(
    lst: OptionsOrGroups<Option, GroupBase<Option>>
): Option[] {
    return flattenNestedLists(
        lst.map((v): Option[] => {
            if (Object.prototype.hasOwnProperty.call(v, "options")) {
                return (v as GroupBase<Option>).options as Option[];
            } else {
                return [v] as Option[];
            }
        })
    );
}

export const SelectComponent = (props: CustomSelectProps): JSX.Element => {
    const {
        options,
        name,
        onChange,
        paddingX = 4,
        textPosition = "left",
        disabled = false,
        isMulti = false,
        ...rest
    } = props;

    const [field, _meta, {setValue}] = useField(name);

    const actualOptions = flattenOptionGroups(
        options || []
    ) as ReadonlyArray<Option>;

    const onChangeFormikSet = (
        newValue: OnChangeValue<Option, boolean>,
        actionMeta: ActionMeta<Option>
    ): Promise<any> => {

        if (_.isArray(newValue)) {
            return setValue((newValue as Option[]).map((v) => v.value)).then(() => {
                if (onChange) {
                    return onChange(newValue, actionMeta);
                }
            });
        } else {
            return setValue((newValue as Option).value).then(() => {
                if (onChange) {
                    return onChange(newValue, actionMeta);
                }
            });
        }
    };

    const getValue = (): Option | Option[] | undefined => {
        if (options) {
            if (isMulti) {
                if (_.isArray(field.value)) {
                    return actualOptions.filter((option) =>
                        field.value.includes(option.value)
                    );
                } else {
                    return [];
                }
            } else {
                return actualOptions.filter(
                    (option) => option.value === field.value
                )[0];
            }
        }
    };
    return (
        <LabelWrapper paddingX={paddingX} textPosition={textPosition} {...props}>
            <Select
                name={field.name}
                id={`select-${field.name}`}
                onChange={onChangeFormikSet}
                value={getValue()}
                options={options}
                closeMenuOnSelect={!isMulti}
                styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided): any => ({...provided, zIndex: 9999}),
                    // Make the same height as other form objects
                    control: (provided): any => ({
                        ...provided,
                        paddingTop: "3px",
                        paddingBottom: "2px",
                    }),
                }}
                isDisabled={disabled}
                isMulti={isMulti}
                {...rest}
            />
        </LabelWrapper>
    );
};

export default SelectComponent;
