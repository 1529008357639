import React, {useState} from 'react';
import {AssetType} from "../../openapi/model/assetType";
import useGetList from "../../hooks/getList";
import {Asset} from "../../openapi/model/asset";
import ApiPaginatedTable from "../../components/Pagination/NewApiPaginatedTable";
import {Form, Formik, useField} from "formik";
import {Button, Col, Row} from "reactstrap";
import classNames from "classnames";
import TextFormComponent from "../../components/Forms/TextFormComponent";


interface SearchFormValues {
    serialNumber?: string;
}

function AssetSelectFormField(props: {
    name: string,
    assetType: AssetType,
    companyId: string,
    unassignedOnly?: boolean
}) {

    const [field, _meta, {setValue}] = useField(props.name);

    const [searchValues, setSearchValues] = useState<SearchFormValues>({});

    const {unassignedOnly=true} = props


    const [{data,loading,error}] = useGetList("ASSET",{
        companyId: props.companyId, assetType: props.assetType,
        assetPositionId: unassignedOnly ? 'NONE':undefined,
        ...searchValues
        }
        );

    function onClick(assetId: string) {
        setValue(assetId)
    }
    return (
            <Row>
                <Col xs={12}>

        <Formik
            initialValues={{} as SearchFormValues}
            onSubmit={(values) => setSearchValues(() => values)}
        >
            {() => {
         return                   <Form>
            <TextFormComponent fieldName={'serialNumber'} submitSpinner={false}
                               label={"Serial Number"} paddingX={0}
            />
                                <Button className={'float-right'} type={'submit'} color={'primary'}>Search</Button>
                            </Form>

            }
            }

        </Formik>
                </Col>
                <Col xs={12}>

        <ApiPaginatedTable
            tableClassName={'table-sm'}
            loading={loading}
            error={error}
            cursor={data?.cursor}
            data={data}
            header={[<><tr><th>Serial Number</th><th>Manufacturer</th><th>&nbsp;</th></tr></>,3]}
            row={(asset: Asset,_, key) => <tr key={key} className={
                classNames({'table-info':field.value === asset.assetId})
            }>
                <td>
                {asset.serialNumber}
            </td>
                <td>
                    {asset.manufacturer}
                </td>
                <td><Button color={'primary'} size={'sm'} onClick={() => onClick(asset.assetId)}>
                    Select
                </Button></td>
            </tr>}
        />
                </Col>
            </Row>
    );
}

export default AssetSelectFormField;