import React from "react";
import { Button, ButtonProps, Modal, ModalProps } from "reactstrap";

export interface ToggleProps {
  toggle: () => void;
}

interface Props extends Omit<ButtonProps, "onClick"> {
  children: (props: ToggleProps) => React.ReactNode;
  buttonLabel: React.ReactElement | React.ReactNode | string;
  modalProps?: Omit<ModalProps, "isOpen">;
}

function ModelLaunchButton(props: Props) {
  const [show, setShow] = React.useState<boolean>(false);

  const { children, modalProps, ...rest } = props;
  const toggle = () => {
    setShow(!show);
  };
  return (
    <>
      <Modal isOpen={show} {...modalProps}>
        {children({ toggle: toggle })}
      </Modal>
      <Button onClick={toggle} {...rest}>
        {props.buttonLabel}
      </Button>
    </>
  );
}

export default ModelLaunchButton;
