import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import BlockSpinner from "../../components/Spinners/BlockSpinner";
import ErrorOverlappingBanner from "../../components/Error/ErrorOverlappingBanner";
import AlarmWidgetRow from "./AlarmWidgetRow";
import { AssetAlarmEvent } from "../../openapi/model/assetAlarmEvent";
import AlarmList from "./AlarmList";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Cursor } from "openapi/model/cursor";
import GetList from "core/action/GetList";
import useUserHasRoles from "../../hooks/user/getCurrentUserRole";
import { AlarmTypes } from "../../openapi/model/alarmTypes";

const numberOfAlarms = (
  loading: boolean,
  error?: Error | null,
  list?: Array<AssetAlarmEvent>,
  cursor?: Cursor,
): string => {
  if (loading) {
    return "?";
  } else if (error) {
    return "-";
  } else if (cursor?.after) {
    return `${cursor.maxItems}+`;
  } else {
    return `${list?.length}`;
  }
};

const AlarmWidget = (props: {
  companyId: string;
  schemeId?: string;
}): JSX.Element => {
  const filterAlarms = !useUserHasRoles("superadmin", [], false);
  const params = {
    ...props,
    alarmTypes: filterAlarms ? [AlarmTypes.OFFLINE] : undefined,
  };

  return (
    <Card>
      <GetList entityType={"ALARM"} params={params} cursor={{ maxItems: 25 }}>
        {({ data, error, loading }) => (
          <BlockSpinner loading={false}>
            <CardHeader className="border-0">
              <h3 className="mb-0">
                Open Alarms (
                {numberOfAlarms(loading, error, data?.data, data?.cursor)}){" "}
              </h3>
            </CardHeader>
            <ErrorOverlappingBanner>
              <CardBody
                style={{ height: "180px", overflowY: "auto" }}
                className={"py-0"}
              >
                <AlarmList
                  companyId={props.companyId}
                  schemeId={props.schemeId}
                  openAlarmsOnly={true}
                  row={AlarmWidgetRow}
                  noDataRow={
                    <span className={"d-inline-block w-100 text-center py-2"}>
                      <div>
                        <FontAwesomeIcon
                          icon={faCheck}
                          className={"text-success my-2"}
                          size={"3x"}
                        />
                      </div>
                      <h4 className={"text-success"}>No open alarms</h4>
                    </span>
                  }
                  showPagination={false}
                  maxDisplay={5}
                />
                {data && data.data.length > 5 && (
                  <CompanyNavLink
                    to={"/admin/alarm?status=open"}
                    className={"text-sm"}
                  >
                    More
                  </CompanyNavLink>
                )}
              </CardBody>
            </ErrorOverlappingBanner>
          </BlockSpinner>
        )}
      </GetList>
    </Card>
  );
};

export default AlarmWidget;
