import React, { useState } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { Alert, Button, Col } from "reactstrap";
import { Auth } from "aws-amplify";
import PageSpinner from "components/Spinners/PageSpinner";
import { Link } from "react-router-dom";
import { AuthContext, logout } from "../../../reducers/auth";

interface Props {
  emailAddress: string;
}

interface State {
  success: boolean;
  error: boolean;
  errorMessage: string;
}

interface FormValues {
  resetCode: string;
  newPassword: string;
  newPasswordConfirm: string;
}

const ResetCodeForm = (props: Props): JSX.Element => {
  const [state, setState] = useState<State>({
    success: false,
    error: false,
    errorMessage: "",
  });

  const dispatch = React.useContext(AuthContext).dispatch;

  const submit = (
    { resetCode, newPassword }: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ): void => {
    setSubmitting(true);
    Auth.forgotPasswordSubmit(props.emailAddress, resetCode, newPassword)
      .then(() => {
        resetForm();
        setState({ success: true, error: false, errorMessage: "" });
        logout(dispatch);
      })
      .catch((err) => {
        setState({ success: false, error: true, errorMessage: err.message });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const initialValues = {
    newPassword: "",
    resetCode: "",
    newPasswordConfirm: "",
  };

  return (
    <>
      {state.success ? (
        <Alert color="success" className={"mt-3"}>
          <p>Password successfully changed.</p>
          <p>
            Continue to <Link to={"/auth/login"}>login.</Link>
          </p>
        </Alert>
      ) : (
        <>
          <Alert color="success" className={"mt-3"}>
            A reset code has been sent to your email address. Please enter it
            and your new password below.
          </Alert>

          <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={Yup.object().shape({
              resetCode: Yup.string().required("Required."),
              newPassword: Yup.string().required("New Password is required"),
              newPasswordConfirm: Yup.string()
                .required("Confirmation of your new password is required")
                .oneOf(
                  [Yup.ref("newPassword"), null],
                  "Must match your new password"
                ),
            })}
            validateOnMount={true}
          >
            {({ errors, touched, isValid, isSubmitting }): JSX.Element => (
              <Form>
                <PageSpinner spin={isSubmitting}>
                  <div className="form-row">
                    <Col className="mb-3" md="12">
                      <label
                        className="form-control-label"
                        htmlFor="resetPasswordCode"
                      >
                        Enter reset code
                      </label>
                      <Field
                        aria-describedby="inputGroupPrepend"
                        id="resetPasswordCode"
                        type="text"
                        name="resetCode"
                        className={
                          "form-control " +
                          (errors.resetCode && touched.resetCode
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="resetCode"
                        component="div"
                        className="invalid-feedback"
                      />
                    </Col>
                    <Col className="mb-3" md="12">
                      <label
                        className="form-control-label"
                        htmlFor="changePasswordNewPassword"
                      >
                        New password
                      </label>
                      <Field
                        aria-describedby="inputGroupPrepend"
                        id="changePasswordNewPassword"
                        type="password"
                        name="newPassword"
                        className={
                          "form-control " +
                          (errors.newPassword && touched.newPassword
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="newPassword"
                        component="div"
                        className="invalid-feedback"
                      />
                    </Col>
                    <Col className="mb-3" md="12">
                      <label
                        className="form-control-label"
                        htmlFor="changePasswordNewPasswordConfirm"
                      >
                        Confirm new password
                      </label>
                      <Field
                        aria-describedby="inputGroupPrepend"
                        id="changePasswordNewPasswordConfirm"
                        type="password"
                        name="newPasswordConfirm"
                        className={
                          "form-control " +
                          (errors.newPasswordConfirm &&
                          touched.newPasswordConfirm
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="newPasswordConfirm"
                        component="div"
                        className="invalid-feedback"
                      />
                    </Col>
                  </div>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting || !isValid}
                  >
                    Reset password
                  </Button>
                </PageSpinner>
              </Form>
            )}
          </Formik>
          {state.error && (
            <Alert color="danger" className={"mt-3"}>
              Password change failed with error: {state.errorMessage}
            </Alert>
          )}
        </>
      )}
    </>
  );
};

export default ResetCodeForm;
