import React, { useEffect } from "react";
import { AssetPosition } from "../../openapi/model/assetPosition";
import { Location } from "../../openapi/model/location";
import { Asset } from "../../openapi/model/asset";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import { Col } from "reactstrap";
import AssetTypeHumanLabel from "../../components/Asset/AssetTypeHumanLabel";
import GatewayLink from "../assets/gateway/GatewayLink";
import CopyToClipboard from "../../components/utils/CopyToClipboard";
import { formatDate } from "../../components/utils/HighlightNullAndOldDates";
import HeadingEditorForm from "../../components/Forms/HeadingEditorForm";
import { Status } from "../../openapi/model/status";
import AssetRegisterStatusLabel from "../../components/Labels/AssetRegisterStatusLabel";
import useUpdateEntity from "../../hooks/updateEntity";
import ObjectPropertyItem from "../../components/Headers/ObjectPropertyItem";
import PrivacyMode from "components/Text/PrivacyMode";
import { assetPositionSchema } from "../../model/assetPosition";
import { AssetType } from "../../openapi/model/assetType";
import AssetUseCaseHumanLabel from "../../components/Asset/AssetUseCaseHumanLabel";
import AssetTypeChangeForm from "../assets/AssetTypeChangeForm";

interface Props {
  assetPosition: AssetPosition;
  asset?: Asset;
  location: Location;
  idLink?: boolean;
  refresh?: () => any;
}

export default function AssetPositionPreview(props: Props): JSX.Element {
  const { assetPosition, asset, location } = props;

  useEffect(() => {
    return;
  }, [assetPosition, asset, location]);
  const assigned =
    assetPosition.assetId !== undefined && assetPosition.assetId !== null;
  const deleted = assetPosition.status === Status.DELETED;
  const isGateway = assetPosition.assetPositionType === "gateway";
  const isWirelessCluster =
    assetPosition.assetPositionType === "wireless_cluster";
  const hasUseCase =
    assetPosition.assetPositionType &&
    [
      AssetType.CoolingMeter,
      AssetType.HeatMeter,
      AssetType.HeatingAndCoolingMeter,
    ].indexOf(assetPosition.assetPositionType) >= 0;

  const updateEntity = useUpdateEntity<AssetPosition>(
    "ASSET_POSITION",
    assetPosition.assetPositionId,
    props.refresh
  );

  const [openModal, modal] = AssetTypeChangeForm(
    assetPosition,
    updateEntity,
    "Asset Position",
    true
  );

  return (
    <>
      {modal}
      <Col xs={12} md={7}>
        <div className={"d-flex flex-wrap"}>
          <ObjectPropertyItem title={"Location Address"} type={"heading"}>
            <CompanyNavLink
              className={"p-0 d-inline"}
              to={`/admin/location/${location?.locationId}`}
            >
              <PrivacyMode>{location?.address}</PrivacyMode>
            </CompanyNavLink>
          </ObjectPropertyItem>

          {!isWirelessCluster && (
            <>
              <HeadingEditorForm
                update={updateEntity}
                label={"Position Reference"}
                values={assetPosition}
                fieldName={"customerReference"}
                title={"Reference"}
                defaultValue={"No Position Reference"}
                validator={assetPositionSchema.customerReference}
                editActionUserType={"editor"}
              />
              <ObjectPropertyItem
                title={"Current Asset Serial Number"}
                type={"heading"}
              >
                {assigned ? (
                  asset && (
                    <CopyToClipboard
                      value={asset.serialNumber}
                      link={`/admin/asset/${asset.assetId}`}
                      className={"d-inline px-0"}
                      privacyMode={true}
                    />
                  )
                ) : (
                  <span className={"text-warning"}>No asset assigned</span>
                )}
              </ObjectPropertyItem>
            </>
          )}
        </div>
      </Col>
      <Col xs={12} md={5}>
        <div
          className={
            "d-flex justify-content-md-end text-sm text-md-right text-left flex-wrap"
          }
        >
          {deleted && (
            <ObjectPropertyItem title={"Status"}>
              <AssetRegisterStatusLabel status={assetPosition.status} />
            </ObjectPropertyItem>
          )}

          {!isWirelessCluster && (
            <ObjectPropertyItem
              title={"Type"}
              editActionUserType={"superadmin"}
              editAction={openModal}
            >
              {assetPosition && (
                <span>
                  <AssetTypeHumanLabel asset={assetPosition} />
                </span>
              )}
            </ObjectPropertyItem>
          )}
          {hasUseCase && (
            <ObjectPropertyItem
              title={"Use Case"}
              editActionUserType={"admin"}
              editAction={openModal}
            >
              <AssetUseCaseHumanLabel asset={assetPosition} asBadge={false} />
            </ObjectPropertyItem>
          )}

          {!isGateway && !isWirelessCluster && (
              <GatewayLink deviceAssetPosition={assetPosition} />
          )}

          <ObjectPropertyItem title={"Asset Position ID"}>
            <CopyToClipboard value={assetPosition?.assetPositionId} slice={8} />
          </ObjectPropertyItem>

          <ObjectPropertyItem title={"Created"}>
            {formatDate(assetPosition?.createdAt, undefined, false)}
          </ObjectPropertyItem>
          <ObjectPropertyItem title={"Updated"}>
            {formatDate(assetPosition?.lastUpdated, undefined, false)}
          </ObjectPropertyItem>
        </div>
      </Col>
    </>
  );
}
