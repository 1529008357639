import React from "react";
import {Form, Formik, FormikHelpers} from "formik";
import {OutputType} from "../../../openapi/model/outputType";
import {Button, Col, Row} from "reactstrap";
import X109ConfigurationSubForm, {
    X109ConfigurationInitialValues,
    X110ConfigurationInitialValues,
} from "../../jobs/FormatConfigurationForms/X109ConfigurationForm";
import BillingConfigurationSubForm, {
    BillingConfigurationInitialValues,
} from "../../jobs/FormatConfigurationForms/BillingConfigurationForm";
import AllDataReportConfigurationSubForm, {
    AllDataReportConfigurationInitialValues,
} from "../../jobs/FormatConfigurationForms/AllDataReportConfigurationForm";
import {ReportExecutionIn} from "../../../openapi/model/reportExecutionIn";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import moment, {Moment} from "moment";
import {Company} from "openapi/model/company";
import {Scheme} from "../../../openapi/model/scheme";
import {LatestDataReportFormat} from "../../../openapi/model/latestDataReportFormat";
import {CumulativeDataReportFormat} from "../../../openapi/model/cumulativeDataReportFormat";
import {BillingReportFormat} from "../../../openapi/model/billingReportFormat";
import {ElvacoX109ReportFormat} from "../../../openapi/model/elvacoX109ReportFormat";
import {AllDataReportFormat} from "../../../openapi/model/allDataReportFormat";
import AggregatedTimeSeriesQuerySubForm, {
    AggregatedTimeSeriesQueryDefaultValues
} from "../../../components/TimeSeries/AggregatedTimeSeriesQueryForm/AggregatedTimeSeriesQuerySubForm";
import * as Yup from "yup";
import {AggregatedDataReportFormat} from "../../../openapi/model/aggregatedDataReportFormat";
import SelectComboField from "../../../components/Forms/SelectComboField";
import SelectCombo from "../../../components/Forms/SelectCombo/SelectCombo";
import NewDateRangePickerField from "../../../components/Forms/NewDateRangePickerField/NewDateRangePickerField";
import NewDateRangePicker from "../../../components/Forms/NewDateRangePicker/NewDateRangePicker";

interface FormValues
    extends Omit<
        ReportExecutionIn,
        "endDate" | "startDate" | "companyId" | "schemeId"
    > {
    startDate: Moment;
    endDate: Moment;
}

export interface ReportConfigurationFormProps {
    company: Company;
    scheme?: Scheme;
    outputTypeFilter?: OutputType[];
    create: (report: ReportExecutionIn) => Promise<any>;
}

export type ReportFormat =
    | BillingReportFormat
    | ElvacoX109ReportFormat
    | AllDataReportFormat
    | LatestDataReportFormat
    | CumulativeDataReportFormat
    | AggregatedDataReportFormat;

function ReportConfigurationForm(props: ReportConfigurationFormProps) {
    const locked = false;
    const {
        outputTypeFilter = [
            OutputType.LATEST,
            OutputType.ELVACOX109,
            OutputType.ELVACOX110,
            OutputType.ALLDATA,
            OutputType.CUMULATIVEDATA,
            OutputType.AGGREGATEDDATA
        ],
    } = props;

    const defaultValuesForReport = (reportType: OutputType): ReportFormat => {
        switch (reportType) {
            case "ELVACO_X109":
                return {...X109ConfigurationInitialValues, format: reportType};
            case "ELVACO_X110":
                return {...X110ConfigurationInitialValues, format: reportType};
            case "CUMULATIVE_DATA":
                return {...BillingConfigurationInitialValues(), format: reportType};
            case "ALL_DATA":
                return {
                    ...AllDataReportConfigurationInitialValues,
                    format: reportType,
                };
            case "AGGREGATED_DATA":
                return {
                    configuration: AggregatedTimeSeriesQueryDefaultValues,
                    format: reportType
                } as unknown as AggregatedDataReportFormat  /* handle that paramater/aggregation ins't correct in the form */
            default:
                return {format: reportType, configuration: undefined};
        }
    };

    const initialValues: FormValues = {
        startDate: moment().startOf("month"),
        endDate: moment().endOf("day").subtract(1, "day"),
        reportFormat: defaultValuesForReport(outputTypeFilter[0]),
    };

    const mapFormToReport = (reportType: OutputType, values: any): ReportFormat => {
        const configuration = values.configuration
        switch (reportType) {
            case "AGGREGATED_DATA":
                return {
                    format: "AGGREGATED_DATA",
                    configuration: {
                        includedAssetTypes: configuration.assetType,
                        aggregation: {
                            parameter: configuration.parameter,
                            aggregation: configuration.aggregation,
                        },
                        period: configuration.period,
                        viewBy: configuration.viewBy,
                    }
                } as AggregatedDataReportFormat

            default:
                return values.reportFormat
        }
    }

    const handleSubmit = (
        values: FormValues,
        {setSubmitting}: FormikHelpers<FormValues>
    ) => {
        setSubmitting(true);
        props
            .create({
                reportFormat: mapFormToReport(
                    values.reportFormat.format as OutputType,
                    values.reportFormat
                ),
                schemeId: props.scheme?.schemeId,
                companyId: props.company.companyId,
                startDate: values.startDate.format("YYYY-MM-DD"),
                endDate: values.endDate.format("YYYY-MM-DD"),
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <Formik onSubmit={handleSubmit} initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    startDate: Yup.string().required(),
                    endDate: Yup.string().required(),
                })}

        >
            {({values, isSubmitting, setFieldValue, isValid}) => {
                return (
                    <Form>
                        <BlockSpinner loading={isSubmitting}>
                            <div style={{maxWidth: '800px'}}>
                                <Row noGutters>
                                    <Col xs={12}>
                                        <SelectComboField
                                            name={'reportFormat.format'}>
                                            {({name, value, onChange}) => <SelectCombo
                                                name={name}
                                                value={value}
                                                                          label={{
                                                                              label: 'Report Format',
                                                                              size: "sm",
                                                                              showErrors: true
                                                                          }}
                                                                          options={[
                                                                              {
                                                                                  label: "Cumulative Data",
                                                                                  value: OutputType.CUMULATIVEDATA,
                                                                              },
                                                                              {
                                                                                  label: "Aggregated Data",
                                                                                  value: OutputType.AGGREGATEDDATA
                                                                              },
                                                                              {
                                                                                  label: "Latest Data",
                                                                                  value: OutputType.LATEST
                                                                              },
                                                                              {
                                                                                  label: "Elvaco X109",
                                                                                  value: OutputType.ELVACOX109
                                                                              },
                                                                              {
                                                                                  label: "Elvaco X110",
                                                                                  value: OutputType.ELVACOX110
                                                                              },
                                                                              {
                                                                                  label: "All data",
                                                                                  value: OutputType.ALLDATA
                                                                              },
                                                                              {
                                                                                  label: "Billing",
                                                                                  value: OutputType.BILLING
                                                                              },
                                                                          ].filter((v) => outputTypeFilter.includes(v.value))}
                                                                          onChange={(event) => {
                                                                              return setFieldValue(
                                                                                  "reportFormat",
                                                                                  defaultValuesForReport(
                                                                                      (event as {
                                                                                          label: string;
                                                                                          value: string
                                                                                      })
                                                                                          .value as OutputType
                                                                                  )
                                                                              ).then(() => onChange(event))
                                                                          }}
                                                                          disabled={locked || outputTypeFilter.length === 1}

                                            />}

                                        </SelectComboField>
                                    </Col>
                                    <Col xs={12}>
                                        <NewDateRangePickerField startDateFieldName={'startDate'}
                                                                 endDateFieldName={'endDate'}>
                                            {(fieldProps) => <NewDateRangePicker
                                                label={{label: 'Report date range', size: 'sm', showErrors: true}}
                                                numberOfMonths={2}
                                                {...fieldProps}/>}
                                        </NewDateRangePickerField>
                                    </Col>
                                </Row>

                                {values.reportFormat.format === OutputType.ELVACOX109 && (
                                    <X109ConfigurationSubForm
                                        namespace={"reportFormat"}
                                        disabled={locked}
                                    />
                                )}
                                {values.reportFormat.format === OutputType.ELVACOX110 && (
                                    <X109ConfigurationSubForm
                                        namespace={"reportFormat"}
                                        disabled={locked}
                                    />
                                )}
                                {values.reportFormat.format === OutputType.CUMULATIVEDATA && (
                                    <BillingConfigurationSubForm
                                        namespace={"reportFormat"}
                                        disabled={locked}
                                    />
                                )}
                                {values.reportFormat.format === OutputType.ALLDATA && (
                                    <AllDataReportConfigurationSubForm
                                        namespace={"reportFormat"}
                                        disabled={locked}
                                    />
                                )}
                                {values.reportFormat.format === OutputType.AGGREGATEDDATA && (
                                    <AggregatedTimeSeriesQuerySubForm namespace={'reportFormat.configuration'}
                                                                      wide={false}
                                                                      showErrors={true}
                                                                      showLocationGroupSelect={false}
                                                                      disabled={locked}/>
                                )}
                                <Col xs={12} className={"d-flex justify-content-end"}>
                                    <Button color={"primary"} disabled={!isValid}>Execute Report</Button>
                                </Col>
                            </div>
                        </BlockSpinner>
                    </Form>
                );
            }}
        </Formik>
    )
        ;
}

export default ReportConfigurationForm;
