import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import "./ApiPagination.scss";
import { Cursor } from "../../openapi/model/cursor";

interface Props {
  cursor: Cursor;
  prevCursor?: Cursor;
  newCursor: (cursor?: Cursor) => any;
  all?: () => any;
  refresh?: () => any;
}

class ApiPagination extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.next = this.next.bind(this);
    this.back = this.back.bind(this);
    this.first = this.first.bind(this);
    this.last = this.last.bind(this);
    this.all = this.all.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  next(): void {
    this.props.newCursor({
      after: this.props.cursor.after,
      maxItems: this.props.cursor.maxItems,
      direction: this.props.cursor.direction,
    });
  }

  back(): void {
    this.props.newCursor({
      before: this.props.cursor.before,
      maxItems: this.props.cursor.maxItems,
      direction: this.props.cursor.direction,
    });
  }

  first(): void {
    this.props.newCursor({
      maxItems: this.props.cursor.maxItems,
      direction: this.props.cursor.direction,
    });
  }

  last(): void {
    const dir = this.props.cursor.direction === "asc" ? "desc" : "asc";
    this.props.newCursor({
      maxItems: this.props.cursor.maxItems,
      direction: dir,
    });
  }

  refresh(): void {
    this.props.refresh && this.props.refresh();
  }

  all(): void {
    if (this.props.all) {
      this.props.all();
    }
  }

  render(): JSX.Element {
    return (
      <>
        {this.props.cursor && (
          <Pagination>
            <PaginationItem
              disabled={
                this.props.cursor.before === null ||
                this.props.cursor.before === undefined
              }
            >
              <PaginationLink first onClick={(): void => this.first()} />
            </PaginationItem>
            <PaginationItem
              disabled={
                this.props.cursor.before === null ||
                this.props.cursor.before === undefined
              }
            >
              <PaginationLink previous onClick={(): void => this.back()} />
            </PaginationItem>
            <PaginationItem disabled={this.props.cursor.after === null}>
              <PaginationLink next onClick={(): void => this.next()} />
            </PaginationItem>
            {/*<PaginationItem disabled={this.props.cursor.after === null}>*/}
            {/*  <PaginationLink last onClick={(): void => this.last()} />*/}
            {/*</PaginationItem>*/}
            {this.props.refresh && (
              <PaginationItem>
                <PaginationLink onClick={(): void => this.refresh()}>
                  <span aria-hidden="true">
                    <i className={"fa fa-sync"} />
                  </span>
                  <span className={"sr-only"}>Refresh</span>
                </PaginationLink>
              </PaginationItem>
            )}
            {this.props.all ? (
              <PaginationItem
                disabled={
                  this.props.cursor.after === null &&
                  this.props.cursor.before === null
                }
              >
                <PaginationLink onClick={(): void => this.all()}>
                  A
                </PaginationLink>
              </PaginationItem>
            ) : (
              <></>
            )}
          </Pagination>
        )}
      </>
    );
  }
}

export default ApiPagination;
