import React from "react";

import { Button, ButtonProps } from "reactstrap";
import { RequireExactlyOne } from "type-fest";

export interface ActionProps {
  onClick: () => any;
  children?: React.ReactNode | string;
  buttonProps: ButtonProps;
}

export const Action = ({
  onClick,
  buttonProps,
  children,
}: RequireExactlyOne<ActionProps, "buttonProps">) => {
  if (buttonProps) {
    return (
      <Button onClick={onClick} {...buttonProps}>
        {children}
      </Button>
    );
  }
  throw new Error("Action has no props.");
};

export default Action;
