import React from "react";
import { Routes } from "react-router-dom";
import CompanyList from "./CompanyList";
import { SentryRoute } from "../../App";

const CompanyPage = (): JSX.Element => {
  return (
    <Routes>
      <SentryRoute path="/" element={<CompanyList />} />
    </Routes>
  );
};

export default CompanyPage;
