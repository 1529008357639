import React, {useEffect, useState} from 'react';
import {matchPeriod, NewTimeSeriesRequestParams} from "../model";
import {useLocation, useNavigate} from "react-router-dom";
import {convertStringUndefined, createSearchString, parseSearchString} from "../../navigation/SearchString";
import {matchStringsToAssetTypeEnum} from "../../../containers/assets/AssetTypeSelect";
import timeSeriesTemplates from "../../../constants/timeSeriesTemplates.json";
import moment from "moment";
import {ReportView} from "../../../openapi/model/reportView";
import {removeMapBlanks} from "../../../utils/object";
import {GroupBy} from "../../../openapi/model/groupBy";
import {matchStringsToEnum} from "../../../utils/enum";
import {AssetUseCase} from "../../../openapi/model/assetUseCase";

interface TimeSeriesQueryUrlWrapperProps {
    defaultValues: NewTimeSeriesRequestParams,
    children: (
        params: NewTimeSeriesRequestParams,
        onChange: (v: NewTimeSeriesRequestParams) => void,
    ) => React.ReactNode
}

function TimeSeriesQueryUrlWrapper(props: TimeSeriesQueryUrlWrapperProps) {
    const navigate = useNavigate();
    const location = useLocation();

    const [init, setInit] = useState<boolean>(false);

    const searchParams = parseSearchString(location.search);
    const currentParams: Partial<NewTimeSeriesRequestParams> = {
        startDate: searchParams.startDate
            ? moment(searchParams.startDate as string)
            : undefined,
        endDate: searchParams.endDate
            ? moment(searchParams.endDate as string)
            : undefined,
        parameter: searchParams.parameter
            ? (searchParams.parameter as string)
            : undefined,
        aggregation: searchParams.aggregation
            ? (searchParams.aggregation as string)
            : undefined,
        group: searchParams.group ? (searchParams.group as string) : undefined,
        viewBy: searchParams.viewBy ? (searchParams.viewBy as ReportView) : undefined,
        groupBy: searchParams.groupBy ? (searchParams.groupBy as GroupBy) : undefined,
        period: searchParams.period ? matchPeriod(searchParams.period as string) : undefined,
        assetType: matchStringsToAssetTypeEnum(searchParams.assetType as string[]) as unknown as (keyof typeof timeSeriesTemplates)[],
        useCase: matchStringsToEnum<AssetUseCase>(searchParams.useCase as string[], AssetUseCase, true)
    }

    const setLocationWithParams = (values: NewTimeSeriesRequestParams): void => {
        const [startDatetime, endDatetime] = [
            values.startDate?.toISOString(true),
            values.endDate?.toISOString(true),
        ];
        navigate({
            search: createSearchString(
                convertStringUndefined({
                    ...values,
                    startDatetime,
                    endDatetime,
                })
            ),
            hash: location.hash,
        })
    }
    useEffect(() => {

        setLocationWithParams({...removeMapBlanks(currentParams),...props.defaultValues,})
            setInit(true)
          // NOTE: Run effect once on component mount
          // recheck dependencies if effect is updated.
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    )


    return <>{init && props.children({...props.defaultValues,...removeMapBlanks(currentParams)}, setLocationWithParams)}</>
}

export default TimeSeriesQueryUrlWrapper;