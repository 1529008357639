import { AxiosError } from "axios";
import { TypedError } from "../../model/error";
import _ from "lodash";
import React from "react";

interface ErrorMessage {
  title?: string;
  text: JSX.Element;
}

const axiosErrorAsText = (error: AxiosError): ErrorMessage => {
  return error.response?.status === 422
    ? { text: <p>The request is not valid.</p> }
    : { text: <p>{error.response?.data?.["message"]}</p> };
};

const typedErrorAsText = (error: TypedError): ErrorMessage => {
  return { text: <p>{error.message}</p>, title: _.startCase(error.errorType) };
};

const errorToTitleAndText = (
  error: Error | AxiosError | TypedError
): ErrorMessage => {
  if (Object.prototype.hasOwnProperty.call(error, "isAxiosError")) {
    return axiosErrorAsText(error as AxiosError);
  }
  if (error instanceof TypedError) {
    return typedErrorAsText(error);
  }
  return { text: <p>error.message</p>, title: `Unhandled Error ${error.name}` };
};

export { errorToTitleAndText };
