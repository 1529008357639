import React from "react";
import useGetEntity, { GetEntityProps } from "../../hooks/getEntity";
import { AxiosPromise, AxiosRequestConfig } from "axios";
import { RefetchOptions } from "axios-hooks";
import _ from "lodash";

export interface GetEntityResultProps<T> {
  data?: T;
  error?: Error;
  loading: boolean;
  refresh: (
    config?: AxiosRequestConfig,
    options?: RefetchOptions
  ) => AxiosPromise<T>;
  cancel: () => void;
}

interface Props extends GetEntityProps {
  children: (props: GetEntityResultProps<any>) => React.ReactElement;
  error: (props: { error: Error }) => JSX.Element;
  placeholder: () => JSX.Element;
}

const GetEntity = (props: Props): JSX.Element => {
  const { children, ...rest } = props;
  const [{ data, error, loading }, refresh, cancel] = useGetEntity<any>(rest);

  const apiError = error !== null && error !== undefined;

  const notFoundError = data === undefined;

  const actualError = apiError
    ? _.isArray(error)
      ? error[0]
      : error
    : notFoundError && Error("Not found.");

  return (
    <>
      {loading
        ? props.placeholder()
        : actualError
        ? props.error({ error: actualError })
        : !notFoundError &&
          children({
            data,
            error: error !== null ? error : undefined,
            loading,
            refresh,
            cancel,
          })}
    </>
  );
};

export default GetEntity;
