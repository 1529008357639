import React from "react";
import {Cursor} from "../../openapi/model/cursor";
import ApiPagination from "./ApiPagination";
import {PaginatedData} from "../../hooks/getList";
import List, {ListProps} from "../List/List";

export interface ApiPaginatedListProps extends  Omit<ListProps, 'data' | 'missingElements'> {
  cursor?: Cursor;
  data?: PaginatedData<any>;
  showPagination?: boolean;
  additionalFunctionButtons?: JSX.Element;
}

export default function ApiPaginatedList(props: ApiPaginatedListProps): JSX.Element {

    const {cursor, data, showPagination, additionalFunctionButtons, ...rest} = props;

  const requestedSize = (cursor as Cursor)?.maxItems || 10;
  const missingElements = Math.max(
    requestedSize - (props.data?.data.length || 0),
    0
  );


  return (
    <>
      <List
          data={data?.data}
          missingElements={missingElements}
          {...rest}
      />


      {(showPagination === undefined || showPagination) &&
        data && (
          <ApiPagination
            cursor={data.cursor}
            newCursor={data.setCursor}
            refresh={props.refresh}
          />
        )}
      {/*{(props.showDownload === undefined || props.showDownload) &&*/}
      {/*  props.exportToCSV && (*/}
      {/*    <CSVExportButton*/}
      {/*      download={props.exportToCSV}*/}
      {/*      sortColumns={props.exportSortColumns}*/}
      {/*    />*/}
      {/*  )}*/}
      {additionalFunctionButtons}
    </>
  );
}
