import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {convertStringUndefined, createSearchString, parseSearchString} from "components/navigation/SearchString";
import moment from "moment";
import {removeMapBlanks} from "../../../utils/object";
import {HeatNetworkReportParams} from "./model";
import {matchStringsToAssetUseCaseEnum} from "../../../components/Asset/NewAssetUseCaseSelect";

interface HeatNetworkQueryUrlWrapperProps {
    defaultValues: HeatNetworkReportParams,
    children: (
        params: HeatNetworkReportParams,
        onChange: (v: HeatNetworkReportParams) => void,
    ) => React.ReactNode
}

function HeatNetworkQueryUrlWrapper(props: HeatNetworkQueryUrlWrapperProps) {
    const navigate = useNavigate();
    const location = useLocation();

    const [init, setInit] = useState<boolean>(false);

    const searchParams = parseSearchString(location.search);
    const currentParams: Partial<HeatNetworkReportParams> = {
        startDate: searchParams.startDate
            ? moment(searchParams.startDate as string)
            : undefined,
        endDate: searchParams.endDate
            ? moment(searchParams.endDate as string)
            : undefined,
        useCases: matchStringsToAssetUseCaseEnum(searchParams.useCases),
        group: searchParams.group as string || undefined
    }

    const setLocationWithParams = (values: HeatNetworkReportParams): void => {
        const [startDatetime, endDatetime] = [
            values.startDate?.toISOString(true),
            values.endDate?.toISOString(true),
        ];
        navigate({
            search: createSearchString(
                convertStringUndefined({
                    ...values,
                    startDatetime,
                    endDatetime,
                })
            ),
            hash: location.hash,
        })
    }
    useEffect(() => {

        setLocationWithParams({...removeMapBlanks(currentParams),...props.defaultValues,})
            setInit(true)
          // NOTE: Run effect once on component mount
          // recheck dependencies if effect is updated.
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    )


    return <>{init && props.children({...props.defaultValues,...removeMapBlanks(currentParams)}, setLocationWithParams)}</>
}

export default HeatNetworkQueryUrlWrapper;