import React from "react";
import NewTariffServiceForm, {
  NewTariffServiceFormProps,
} from "./NewTariffServiceForm";
import { ToggleProps } from "./ModelLaunchButton";
import { ModalBody, ModalHeader } from "reactstrap";

interface Props extends NewTariffServiceFormProps, ToggleProps {}

function NewTariffServiceFormModal(props: Props) {
  return (
    <div>
      <ModalHeader toggle={props.toggle}>Add Service</ModalHeader>
      <ModalBody>
        <NewTariffServiceForm create={props.create} />
      </ModalBody>
    </div>
  );
}

export default NewTariffServiceFormModal;
