import React from "react";
import { Routes } from "react-router-dom";
import { LocationsListPage } from "./LocationsListPage";
import BulkUpdateList from "../../components/BulkUpdate/BulkUpdateList";
import GenericUpdatesPage from "../../components/BulkUpdate/GenericUpdatesPage";
import LocationHomePage from "./LocationHomePage";
import { SentryRoute } from "../../App";
import SelectedScheme from "components/Scheme/SelectedScheme";
import SchemeNewLocationPage from "../schemes/SchemeNewLocationPage";
import RequireScheme from "../../components/Scheme/RequireScheme";

export default function LocationsPage(): JSX.Element {
  return (
    <Routes>
      <SentryRoute path="/" element={<LocationsListPage />} />
      <SentryRoute
        path="/updates"
        element={
          <SelectedScheme>
            {(props) => <BulkUpdateList {...props} objectType={"Location"} />}
          </SelectedScheme>
        }
      />
      <SentryRoute
        path={"/updates/:updateId"}
        element={
          <SelectedScheme>
            {(props) => (
              <GenericUpdatesPage
                {...props}
                title={"Locations"}
                header={"Updated Locations"}
                tableFields={[
                  "companyId",
                  "schemeId",
                  "locationId",
                  "address",
                  "customerReference",
                  "group",
                ]}
                tableFieldHeaders={[
                  "Company ID",
                  "Scheme ID",
                  "Location ID",
                  "Address",
                  "Reference",
                  "Group",
                ]}
                objectKey={"locationId"}
              />
            )}
          </SelectedScheme>
        }
      />
      <SentryRoute
        path={"create"}
        element={
          <RequireScheme>
            <SelectedScheme>
              {(schemeProps) => (
                <SchemeNewLocationPage
                  schemeId={schemeProps.schemeId as string}
                  companyId={schemeProps.companyId}
                />
              )}
            </SelectedScheme>
          </RequireScheme>
        }
      />
      <SentryRoute
        path={":locationId"}
        element={
          <SelectedScheme>
            {(props) => <LocationHomePage {...props} />}
          </SelectedScheme>
        }
      >
        <SentryRoute
          path={":tab"}
          element={
            <SelectedScheme>
              {(props) => <LocationHomePage {...props} />}
            </SelectedScheme>
          }
        />
      </SentryRoute>
    </Routes>
  );
}
