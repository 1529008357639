import React from "react";
import { OneCardPage } from "../../components/utils/OneCardPage";
import SelectedScheme from "components/Scheme/SelectedScheme";
import AssetRegisterList from "../assetRegister/AssetRegisterList";
import { AssetType } from "openapi/model/assetType";
import WirelessClusterListRow from "./WirelessClusterListRow";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import { Button } from "reactstrap";
import DisplayIfUserType from "components/utils/DisplayIfUserType/DisplayIfUserType";

export default function WirelessClusterListPage(): JSX.Element {
  return (
    <OneCardPage
      headerTitle={"Wireless Clusters"}
      breadcrumbs={[
        {
          url: "/admin/wireless_cluster",
          name: "Wireless Clusters",
        },
      ]}
    >
      <SelectedScheme>
        {(scheme) => (
          <>
            <AssetRegisterList
              {...scheme}
              assetType={AssetType.WirelessCluster}
              download={false}
              createAssetTags={false}
              row={WirelessClusterListRow}
            />
            {scheme.schemeId && (
              <DisplayIfUserType userTypes={"admin"}>
                <CompanyNavLink to={`create`} tag={Button}>
                  Add Wireless Cluster
                </CompanyNavLink>
              </DisplayIfUserType>
            )}
          </>
        )}
      </SelectedScheme>
    </OneCardPage>
  );
}
