import React, { useState } from "react";
import { AssetPosition } from "../../../openapi/model/assetPosition";
import { ParsedAssetDefinition, UploadPage } from "./UploadPage";
import BlockSpinner from "../../../components/Spinners/BlockSpinner";
import SimpleAssetRegisterUploadPreview from "./SimpleAssetRegisterUploadPreview";
import { Alert, Button, Col } from "reactstrap";
import { ErrorBanner } from "../../../components/Error/ErrorBanner";
import useCreateEntity from "hooks/createEntity";
import { SimpleAssetDefinition } from "../../../openapi/model/simpleAssetDefinition";

interface Props {
  assetPosition: AssetPosition;
}

const SimpleAssetRegisterUploadContainer = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [register, setRegister] = useState<ParsedAssetDefinition[]>([]);
  const [state, setState] = useState<"upload" | "complete" | "error">("upload");
  const [errors, setErrors] = useState<Error>();

  const { assetPosition } = props;

  const { createEntity } = useCreateEntity<Array<SimpleAssetDefinition>>(
    "ASSET_REGISTER",
    `asset_register/simple/${assetPosition.assetPositionId}`
  );

  const handleNewFile = (
    definitions: Promise<ParsedAssetDefinition[]>
  ): void => {
    setLoading(true);
    definitions
      .then((result) => {
        setRegister(result);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpload = (): void => {
    setLoading(true);
    const validRegister = register.filter((object: ParsedAssetDefinition) => {
      return !(object.errors && object.errors !== []);
    });

    createEntity(validRegister.map((o) => o.object))
      .then(() => {
        setState("complete");
      })
      .catch((err) => {
        setErrors(err);
        setState("error");
      })
      .finally(() => setLoading(false));
  };

  const cancelUpload = (): void => {
    setRegister([]);
  };

  return (
    <>
      {state === "upload" && (
        <BlockSpinner loading={loading}>
          {register.length === 0 ? (
            <UploadPage handleFile={handleNewFile} />
          ) : (
            <>
              <SimpleAssetRegisterUploadPreview definitions={register} />
              <Col xs={12} className={"text-right"}>
                <Button color={"primary"} onClick={handleUpload}>
                  Upload
                </Button>
                <Button color={"danger"} onClick={cancelUpload}>
                  Cancel
                </Button>
              </Col>
            </>
          )}
        </BlockSpinner>
      )}
      {state === "complete" && (
        <Alert color={"success"}>
          <h3>Complete</h3>
          <p>Your upload was successfully processed</p>
        </Alert>
      )}
      {state === "error" && errors && <ErrorBanner error={errors} />}
    </>
  );
};

export default SimpleAssetRegisterUploadContainer;
