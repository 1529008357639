import React from "react";
import {
  Option,
  SelectComponent,
  SelectFieldProps,
} from "../../components/Forms/SelectComponent";
import _ from "lodash";
import { GroupBase } from "react-select";
import { AssetType } from "../../openapi/model/assetType";
import { convertStringToOpenApiEnum } from "../../utils/enum";
import { ParsedQs } from "qs";

function stringListToOptions(lst: (string | undefined)[]): Option[] {
  return lst
    .filter((v) => v)
    .map((v): Option => {
      return {
        value: v as string,
        label: _.startCase(v),
      };
    });
}

export function matchStringsToAssetTypeEnum(
  lst: string | string[] | ParsedQs | ParsedQs[] | undefined
): AssetType[] | undefined {
  if (_.isNil(lst)) {
    return undefined;
  }
  if (_.isArray(lst)) {
    return lst
      .map((v) => {
        if (_.isString(v)) {
          return convertStringToOpenApiEnum<AssetType>(v, AssetType);
        } else {
          return undefined;
        }
      })
      .filter((v) => !_.isNil(v)) as AssetType[];
  }
  const val = _.isString(lst)
    ? convertStringToOpenApiEnum<AssetType>(lst, AssetType)
    : undefined;
  if (val) {
    return [val as AssetType];
  }
}

export default function AssetTypeSelect(
  props: {
    fieldName: string;
    allowUnknown?: boolean;
    includeSelect?: boolean;
    includeNonCreateables?: boolean;
    includeWirelessCluster?: boolean;
    displayGroups?: boolean;
  } & SelectFieldProps
): JSX.Element {
  const {
    fieldName,
    includeSelect,
    includeNonCreateables,
    includeWirelessCluster,
    allowUnknown,
    paddingX = null,
    ...rest
  } = props;

  const meterOptions = [
    "cooling_meter",
    "electricity_meter",
    "gas_meter",
    "heat_meter",
    "heating_and_cooling_meter",
    "water_meter",
  ];

  const infrastructureOptions = [
    "gateway",
    "wireless_receiver",
    includeWirelessCluster ? "wireless_cluster" : undefined,
    includeNonCreateables ? "sim_card" : undefined,
  ];

  const otherOptions = [
    "room_sensor",
    "co2_room_sensor",
    "input_output_module",
    allowUnknown ? "unknown" : undefined,
  ];

  const options: (Option | GroupBase<Option>)[] = [
    { label: "Meters", options: stringListToOptions(meterOptions) },
    {
      label: "Infrastructure",
      options: stringListToOptions(infrastructureOptions),
    },
    { label: "Other", options: stringListToOptions(otherOptions) },
  ];

  return (
    <SelectComponent
      label={"Asset Type"}
      options={
        includeSelect
          ? [{ label: "---Please Select---", value: "undefined" }, ...options]
          : options
      }
      name={fieldName}
      {...rest}
      paddingX={paddingX}
    />
  );
}
