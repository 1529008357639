import { GatewayAsset } from "../../../openapi/model/gatewayAsset";
import { Col, Row } from "reactstrap";

import React from "react";
import JobList from "../../jobs/JobList";
import JobCreateForm from "../../jobs/JobCreateForm";
import DisplayIfUserType from "../../../components/utils/DisplayIfUserType/DisplayIfUserType";

const GatewayJobTab = (props: { gateway: GatewayAsset }): JSX.Element => {
  const gatewayPosition = props.gateway.assetPositionId;

  return (
    <Row>
      {gatewayPosition ? (
        <>
          <Col xs={12}>
            <h3>Scheduled Jobs</h3>
            <JobList
              assetPositionId={gatewayPosition}
              companyId={props.gateway.companyId}
              schemeId={props.gateway.schemeId}
            />
          </Col>
          <DisplayIfUserType userTypes={"admin"}>
            <Col xs={12}>
              <h3>Add New Job</h3>
              <JobCreateForm
                assetPositionId={gatewayPosition}
                companyId={props.gateway.companyId}
              />
            </Col>
          </DisplayIfUserType>
        </>
      ) : (
        <Col xs={12}>
          <h3>Scheduled Jobs</h3>
          <p>
            This gateway is not assigned to a position. No jobs are supported.
          </p>
        </Col>
      )}
    </Row>
  );
};

export default GatewayJobTab;
