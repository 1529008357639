import React, { useState } from "react";
import classNames from "classnames";
import { Tooltip } from "reactstrap";
import { Actions } from "../../openapi/model/actions";

interface Props {
  index: string;
  status: Actions;
  executed: boolean;
  executedSuccess: boolean;
  immutableChangeErrors: string[];
  validationErrors?: string[];
  executionErrors: string[];
  changedProperties: string[];
}

const GenericUpdateTooltip = (props: Props): JSX.Element => {
  const [tooltip, setTooltip] = useState(false);

  const toggle = () => {
    console.log(`toggle ${props.index}`);
    setTooltip(!tooltip);
  };

  const {
    index,
    status,
    executed,
    executedSuccess,
    immutableChangeErrors,
    validationErrors = [],
    executionErrors,
    changedProperties,
  } = { ...props };

  const hasErrors =
    immutableChangeErrors.length +
      validationErrors.length +
      executionErrors.length >
    0;

  const tooltipContent = (): string => {
    if (executed) {
      if (executedSuccess || executionErrors.length > 0) {
        const success = executedSuccess && !hasErrors;
        const actionStatus = success
          ? "completed"
          : `failed ${describeErrors()}`;
        return `${status}: ${actionStatus}`;
      } else {
        return `${status}: in progress`;
      }
    } else {
      return `${status} ${
        status === Actions.UPDATE
          ? hasErrors
            ? describeErrors()
            : changedProperties
          : ""
      }`;
    }
  };

  const describeErrors = (): string => {
    if (executed && executionErrors.length > 0) {
      return executionErrors.join(", ");
    } else {
      return hasErrors
        ? validationErrors.join(", ") + immutableChangeErrors
          ? "immutable fields were changed: " + immutableChangeErrors.join(", ")
          : ""
        : "";
    }
  };

  const text = tooltipContent();

  return (
    <>
      <span id={`UpdateToolTip-${index}`}>
        <i
          className={classNames("fa fa-fw", {
            "fa-plus-circle": status === Actions.NEW && !hasErrors,
            "fa-check-circle": status === Actions.UPDATE && !hasErrors,
            "fa-minus-circle": status === Actions.DELETED,
            "fa-exclamation-triangle": hasErrors,
            "fa-exclamation-circle": status === Actions.NOTFOUND,
            "fa-circle": status === Actions.UNCHANGED,
          })}
        />
      </span>
      <Tooltip
        placement="right"
        isOpen={tooltip}
        target={`UpdateToolTip-${index}`}
        toggle={toggle}
      >
        {text}
      </Tooltip>
    </>
  );
};

export default GenericUpdateTooltip;
