import React from "react";
import { Moment } from "moment";
import { AssetRegisterAggregatedTimeSeriesResponse } from "../../../openapi/model/assetRegisterAggregatedTimeSeriesResponse";
import AllPeriodReportTable from "../allPeriodReportTable/NewAllPeriodReportTable";

export const VOLUME_WEIGHTED_AVERAGE_AGGREGATIONS = [
  "volume_weighted_average",
  "volume_weighted_average_(domestic_hot_water)",
  "volume_weighted_average_(standby)",
  "volume_weighted_average_(space_heating)",
];

export const VOLUME_WEIGHTED_AVERAGE_AGGREGATION_NAMES = [
  {
    parameter: "returnTemperature",
    aggregation: "volumeWeightedAverage",
    name: "VWART",
  },
  {
    parameter: "returnTemperature",
    aggregation: "volumeWeightedAverageDomesticHotWater",
    name: "VWART in Hot Water Mode",
  },
  {
    parameter: "returnTemperature",
    aggregation: "volumeWeightedAverageStandby",
    name: "VWART in Standby Mode",
  },
  {
    parameter: "returnTemperature",
    aggregation: "volumeWeightedAverageSpaceHeating",
    name: "VWART in Space Heating Mode",
  },
];

const VWARTReportView = (props: {
  schemeId?: string;
  startDatetime: Moment;
  endDatetime: Moment;
  data?: AssetRegisterAggregatedTimeSeriesResponse;
}): JSX.Element => {
  const { startDatetime, endDatetime } = props;

  return (
    <>
      {props.schemeId && props.data && (
        <AllPeriodReportTable
          data={props.data}
          columnDefinitions={VOLUME_WEIGHTED_AVERAGE_AGGREGATION_NAMES}
          startDatetime={startDatetime}
          endDatetime={endDatetime}
          downloadFilename={`${props.schemeId}_vwart_report`}
        />
      )}
    </>
  );
};

export default VWARTReportView;
