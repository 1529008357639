import React from 'react';
import AggregatedTimeSeriesDataReportView from "../AggregatedTimeSeriesDataReportView/AggregatedTImeSeriesDataReportView";
import AggregatedTimeSeriesDataWrapper from "../AggregatedTimeSeriesDataWrapper/AggregatedTimeSeriesDataWrapper";
import TimeSeriesQueryUrlWrapper from "../TimeSeriesQueryUrlWrapper/TimeSeriesQueryUrlWrapper";
import {meterOptions} from "../../Asset/AssetTypeSelectCombo";
import {AssetType} from "../AssetParameterSelectCombo/AssetParameterSelectCombo";
import {Period} from "../../../openapi/model/period";
import moment from "moment";
import {ReportView} from "../../../openapi/model/reportView";
import {NewTimeSeriesRequestParams} from "../model";
import {mapFormValuesToTimeSeriesParams} from "../AggregatedTimeSeriesQueryForm/AggregatedTimeSeriesQueryForm";

export interface AggregatedTimeSeriesDataReportProps {
    companyId: string;
    schemeId: string
}

function AggregatedTimeSeriesDataReport(props: AggregatedTimeSeriesDataReportProps) {
    return (
        <TimeSeriesQueryUrlWrapper
            defaultValues={{
                assetType: meterOptions as AssetType[],
                parameter: 'Energy (Heating)',
                aggregation: 'last',
                period: Period.DAILY,
                startDate: moment().startOf('day').subtract(14, 'days'),
                endDate: moment().endOf('day').subtract(1, 'days'),
                viewBy: ReportView.ASSETPOSITION,
            }}
        >
            {
                (
                    params: NewTimeSeriesRequestParams,
                    onChange: (v: NewTimeSeriesRequestParams) => void,
                ) => <AggregatedTimeSeriesDataWrapper companyId={props.companyId} schemeId={props.schemeId}
                                                      params={params}>
                    {
                        (data) => <AggregatedTimeSeriesDataReportView
                            companyId={props.companyId}
                            schemeId={props.schemeId}
                            data={data}
                            onSubmit={(v) => {
                                onChange(mapFormValuesToTimeSeriesParams(v,false, true, true))
                                return Promise.resolve()
                            }} request={params}/>
                    }
                </AggregatedTimeSeriesDataWrapper>
            }
        </TimeSeriesQueryUrlWrapper>
    );
}

export default AggregatedTimeSeriesDataReport;