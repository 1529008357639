import React from "react";
import { OneCardPage } from "../../components/utils/OneCardPage";
import AlarmWidgetRow from "./AlarmWidgetRow";
import AlarmList from "./AlarmList";
import AlarmListFilterForm from "./AlarmListFilterForm";
import SearchParamPage from "../../components/Search/SearchParamPage";
import SelectedScheme from "../../components/Scheme/SelectedScheme";

const AlarmListPageInner = (props: {
  status?: "all" | "open";
}): JSX.Element => {
  const open = props.status === "open";
  return (
    <SelectedScheme>
      {(props) => (
        <AlarmList
          {...props}
          row={AlarmWidgetRow}
          noDataRow={<h5>No alarms</h5>}
          showPagination={true}
          openAlarmsOnly={open}
        />
      )}
    </SelectedScheme>
  );
};

const AlarmListPage = (): JSX.Element => {
  return (
    <OneCardPage
      headerTitle={"Alarms"}
      breadcrumbs={[
        { url: "/admin/alarm", name: "Alarms" },
        { url: "/admin/alarm", name: "List" },
      ]}
    >
      <AlarmListFilterForm />
      <SearchParamPage initialState={{ status: "all" }}>
        <AlarmListPageInner />
      </SearchParamPage>
    </OneCardPage>
  );
};

export default AlarmListPage;
