import _ from "lodash";
import React from "react";
import { UserIdentifier } from "../../openapi/model/userIdentifier";

export default function UserReference(props: {
  userId?: string;
  user?: UserIdentifier;
}): JSX.Element {
  const haveUserId = props.userId && props.userId.length === 36;
  const haveUserName = !_.isNil(props.user?.userName);

  if (haveUserName) {
    return <>{props.user?.userName}</>;
  }
  if (haveUserId) {
    return <>...{props.userId?.slice(24)}</>;
  }
  return <>{props.userId}</>;
}
