import React, { useRef } from "react";
import { FormikProps } from "formik";
import H2InputLinkComponent from "./H2InputLinkComponent";
import EditModal from "./EditModal";
import { UpdateEntity } from "hooks/updateEntity";
import * as yup from "yup";

export interface EditStringModalProps<T> {
  values: T;
  fieldName: keyof T;
  title: string;
  label: string;
  update: UpdateEntity<T>;
  validator?: yup.StringSchema<string | undefined>;
}

function EditStringModal<T>(
  props: EditStringModalProps<T>
): [() => void, JSX.Element] {
  const inputRef = useRef();
  const setModalFocus = () => {
    const node = inputRef.current as any;
    node && node.focus();
  };

  const { fieldName, label, values, validator, ...rest } = props;

  return EditModal<T>({
    ...rest,
    values,
    validator: validator && yup.object({ [fieldName]: validator }),
    children: (formikProps: FormikProps<T>) => (
      <>
        <h6>{label}</h6>
        <H2InputLinkComponent
          innerRef={inputRef}
          fieldName={fieldName as unknown as string}
          {...formikProps}
          disabled={formikProps.isSubmitting}
        />
      </>
    ),
    setModalFocus,
  });
}

export default EditStringModal;
