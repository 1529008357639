/**
 * Meterpoint.io
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type FileProcessingStatus = 'QUEUED' | 'PROCESSED' | 'PROCESSED_WITH_ERRORS' | 'QUARANTINED' | 'UNKNOWN';

export const FileProcessingStatus = {
    QUEUED: 'QUEUED' as FileProcessingStatus,
    PROCESSED: 'PROCESSED' as FileProcessingStatus,
    PROCESSEDWITHERRORS: 'PROCESSED_WITH_ERRORS' as FileProcessingStatus,
    QUARANTINED: 'QUARANTINED' as FileProcessingStatus,
    UNKNOWN: 'UNKNOWN' as FileProcessingStatus
};