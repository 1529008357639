import { RefObject, useEffect } from "react";
import { FormikProps } from "formik";
import { useSelectedScheme } from "../reducers/scheme";

export default function useSetFormValueOnSchemeChange<
  T = Record<string, unknown>
>(
  formikRef: RefObject<FormikProps<T>>,
  fieldName: string,
  resetValue: string,
  submit = false
) {
  const selectedScheme = useSelectedScheme();

  useEffect(() => {
    if (formikRef) {
      formikRef.current?.setFieldValue(fieldName, resetValue);
      if (submit) {
        formikRef.current?.submitForm();
      }
    }
  }, [selectedScheme, fieldName, formikRef, resetValue, submit]);
}
