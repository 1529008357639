/*
Sort functions to assist with tables
 */

import _ from "lodash";
import { TableRow } from "react-data-table-component";

const sortNumbers =
  (
    propertyName: string,
    undefinedLow = true,
    percentagePropertyName?: string
  ) =>
  (rowA: TableRow, rowB: TableRow): number => {
    const undefinedValue = undefinedLow
      ? Number.NEGATIVE_INFINITY
      : Number.POSITIVE_INFINITY;
    if (percentagePropertyName) {
      const a =
        _.get(rowA as unknown as any, propertyName, undefinedValue) /
        _.get(rowA as unknown as any, percentagePropertyName, 1);
      const b =
        _.get(rowB as unknown as any, propertyName, undefinedValue) /
        _.get(rowB as unknown as any, percentagePropertyName, 1);
      return numericCompare(
        _.isNaN(a) ? undefinedValue : a,
        _.isNaN(b) ? undefinedValue : b
      );
    } else {
      return numericCompare(
        _.get(rowA as unknown as any, propertyName, undefinedValue),
        _.get(rowB as unknown as any, propertyName, undefinedValue)
      );
    }
  };

const numericCompare = (a: number, b: number): number => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export default sortNumbers;
