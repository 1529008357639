import React from 'react';
import {SelectCombo, SelectComboProps} from "../Forms/SelectCombo/SelectCombo";
import {SchemeContext} from "../../reducers/scheme";
import {locationGroupListToOptions} from "../Location/model";
import {LocationGroup} from "../../openapi/model/locationGroup";


type NewLocationGroupSelectProps = Omit<SelectComboProps, 'options' | 'placeholder'>


export default function LocationGroupSelect(props: NewLocationGroupSelectProps): JSX.Element {
    const locationGroups = React.useContext(SchemeContext).state.groups;

    const ready = !locationGroups.loading && !locationGroups.error;
    const haveGroups = locationGroups.groups && locationGroups.groups.length > 0;
    const altText: string = ready
        ? (haveGroups ? "All" : "No Groups")
        : locationGroups.loading
            ? "Loading Groups"
            : locationGroups.error?.message || "Error Loading Groups";
    return (
        <SelectCombo
            {...props}
            options={
                    haveGroups ? locationGroupListToOptions(
                        locationGroups.groups as LocationGroup[],
                        0
                    ) : []
            }
            disabled={props.disabled || (ready && !haveGroups) || !ready}
            placeholder={altText}
        />
    );
}
