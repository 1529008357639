import React from 'react';
import FormComponent from "../../components/Forms/FormComponent";

interface GuruApiIntegrationFormProps {
    namespace: string;
}

export default function GuruApiIntegrationForm(props: GuruApiIntegrationFormProps): React.ReactElement {
    const withNamespace = (field: string) => {
        return `${props.namespace}.${field}`;
    }
    return (
        <>
            <FormComponent
                fieldName={withNamespace('apiKey')}
                label={"API Key"}
            />
            <FormComponent
                fieldName={withNamespace('siteId')}
                label={"Site ID"}
            />
        </>
    );
}
