import React from "react";
import { JobDefinitionOut } from "../../openapi/model/jobDefinitionOut";
import HighlightNullAndOldDates from "../../components/utils/HighlightNullAndOldDates";
import moment from "moment";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import GetListPaginatedList from "../../components/Pagination/GetListPaginatedList";
import { Col, Row } from "reactstrap";
import PrivacyMode from "../../components/Text/PrivacyMode";
import AssetRegisterStatusLabel from "../../components/Labels/AssetRegisterStatusLabel";
import { JobStatus } from "../../openapi/model/jobStatus";
import _ from "lodash";
import { OutputType } from "../../openapi/model/outputType";
import { getNumberWithOrdinal } from "../../utils/number";

interface Props {
  companyId: string;
  schemeId?: string;
  assetPositionId?: string;
}

const JobList = (props: Props): JSX.Element => {
  const { companyId, schemeId, assetPositionId } = props;

  const niceFormatName = (format?: OutputType): string => {
    switch (format) {
      case "ELVACO_X109":
        return "Elvaco X109";
      case "ELVACO_X110":
        return "Elvaco X110";
      default:
        return _.capitalize(_.startCase(format));
    }
  };
  const row = (value: JobDefinitionOut): JSX.Element => (
    <li className={"checklist-entry list-group-item"}>
      <Row className={"d-flex justify-content-between"}>
        <Col className={"d-block flex-wrap"} md={6} sm={12}>
          <div>
            <CompanyNavLink to={`/admin/job/${value.jobId}`} className={"px-0"}>
              <PrivacyMode>{value.name}</PrivacyMode>{" "}
              <small> ({value.jobId.slice(24, 32)})</small>
            </CompanyNavLink>
          </div>
          <div>
            <small>
              {niceFormatName(value.reportFormat.format as OutputType)}
              {value.objectType && (
                <>
                  {" "}
                  for {value.objectType}/{value.objectId}
                </>
              )}
              <> | </>
              Destination {value.exportConfiguration?.exportType || "None"}
            </small>
          </div>
        </Col>
        <Col className={"d-block flex-wrap float-right"} sm={12} md={3}>
          <div>
            {_.capitalize(value.schedule?.frequency) || "Daily"}
            <span className={"px-2"}>
              <AssetRegisterStatusLabel
                status={value.status as JobStatus}
                displayActive={true}
              />
            </span>
          </div>
          <div className={"small py-1"}>
            {value.schedule?.frequency === "WEEKLY" && (
              <> (runs on {_.capitalize(value.schedule.dayOfWeek)})</>
            )}
            {value.schedule?.frequency === "MONTHLY" && (
              <>
                {" "}
                (runs on {getNumberWithOrdinal(value.schedule.dayOfMonth || 1)})
              </>
            )}
          </div>
        </Col>
        <Col className={"d-none d-md-flex flex-wrap float-right"} md={3}>
          <HighlightNullAndOldDates ageHighlight={moment.duration(24, "hours")}>
            {value.lastRun}
          </HighlightNullAndOldDates>
        </Col>
      </Row>
    </li>
  );

  const header = (
    <li className={"checklist-entry list-group-item"}>
      <Row className={"d-none d-md-flex justify-content-between"}>
        <Col className={"d-block flex-wrap"} md={6}>
          <h3>Job</h3>
        </Col>
        <Col className={"d-block flex-wrap float-right"} md={3}>
          <h3>Schedule</h3>
        </Col>
        <Col className={"d-flex flex-wrap float-right"} md={3}>
          <h3>Last run</h3>
        </Col>
      </Row>
    </li>
  );

  return (
    <GetListPaginatedList
      entityType={"JOB"}
      params={{ companyId, schemeId, assetPositionId }}
      cursor={{ maxItems: 10, direction: "desc" }}
      row={row}
      header={header}
    />
  );
};

export default JobList;
