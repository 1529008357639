import React from "react";
import AssetDataSearchStringParser from "../AssetDataSearchStringParser";
import {DeviceAsset} from "../../../../openapi/model/deviceAsset";
import {GatewayAsset} from "../../../../openapi/model/gatewayAsset";
import AssetRFChart from "./AssetRFChart";
import AssetTimeSeriesRFGetter from "./AssetTimeSeriesRFGetter";
import {AssetPosition} from "../../../../openapi/model/assetPosition";

interface Props {
  device: DeviceAsset | GatewayAsset;
  assetPosition: AssetPosition;
}

export default function AssetRFAssessmentContainer(props: Props): JSX.Element {
  return (
    <AssetDataSearchStringParser device={props.device}>
      {({ searchParams }) => {
        return (
          <AssetTimeSeriesRFGetter
            device={props.device}
            searchParams={searchParams}
            render={(props) => <AssetRFChart {...props} />}
          />
        );
      }}
    </AssetDataSearchStringParser>
  );
}
