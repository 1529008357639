/**
 * Meterpoint.io
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type Rounding = 'NONE' | 'WHOLE' | 'ONE_DP' | 'TWO_DP' | 'THREE_DP';

export const Rounding = {
    NONE: 'NONE' as Rounding,
    WHOLE: 'WHOLE' as Rounding,
    ONEDP: 'ONE_DP' as Rounding,
    TWODP: 'TWO_DP' as Rounding,
    THREEDP: 'THREE_DP' as Rounding
};