import React from "react";
import { Asset } from "../../openapi/model/asset";
import CompanyNavLink from "../navigation/CompanyNavLink";
import _ from "lodash";

interface Props {
  asset?: Asset;
  assetId?: string;
  full?: boolean;
  placeholder?: boolean;
  text?: string;
}

const AssetIdLink = (props: Props): JSX.Element => {
  const { asset, assetId, placeholder } = props;
  const actualAssetId = asset ? asset.assetId : assetId;

  const usePlaceholder = _.isUndefined(actualAssetId) && placeholder;

  if (actualAssetId) {
    if (actualAssetId !== "NONE") {
      const displayId = props.full
        ? actualAssetId
        : "..." + actualAssetId.slice(24);
      return (
        <CompanyNavLink to={`/admin/asset/${actualAssetId}`}>
          {props.text || displayId}
        </CompanyNavLink>
      );
    } else {
      return <>-</>;
    }
  } else if (usePlaceholder) {
    return <CompanyNavLink to={"#"}>...</CompanyNavLink>;
  }
  throw new Error("One of asset or assetId must be set");
};

export default AssetIdLink;
