import React from "react";
import DeviceSpecificListContent from "./DeviceSpecficListContent";
import LinkAssetPositionFromAssetButton from "../../assetPositions/LinkAssetPositionFromAssetButton";
import { Asset } from "../../../openapi/model/asset";
import { AssetPosition } from "../../../openapi/model/assetPosition";
import AssetTypeHumanLabel from "../../../components/Asset/AssetTypeHumanLabel";
import AssetIdLink from "../../../components/Asset/AssetIdLink";
import AssetPositionIdLink from "../../../components/AssetPosition/AssetPositionIdLink";
import PrivacyMode from "../../../components/Text/PrivacyMode";

const AssetListGenericRowsContent = (props: {
  asset: Asset;
  assignAssetPosition?: AssetPosition;
  showPosition?: boolean;
  showScheme?: boolean;
  refreshElement: () => any;
  refreshList?: () => any;
}): JSX.Element => {
  const showPosition =
    props.showPosition !== undefined ? props.showPosition : true;
  const showScheme = props.showScheme !== undefined ? props.showScheme : true;

  return (
    <tr data-cy={"list-item"}>
      <td className="text-left" data-cy={"asset-id"}>
        <AssetIdLink asset={props.asset} />
      </td>
      {showScheme && (
        <td className={"text-left"}>
          <PrivacyMode>{props.asset.schemeId}</PrivacyMode>
        </td>
      )}

      <td className="text-left">
        <PrivacyMode>{props.asset.serialNumber}</PrivacyMode>
      </td>
      <td className="text-left">
        <AssetTypeHumanLabel asset={props.asset} />
      </td>
      <td className="text-left">{props.asset.manufacturer}</td>
      {showPosition ? (
        <>
          <td className={"text-left"}>
            {props.asset.assetPositionId && (
              <AssetPositionIdLink
                assetPositionId={props.asset.assetPositionId}
              />
            )}
          </td>
          <td className="text-left">
            <DeviceSpecificListContent
              asset={props.asset}
              refreshElement={props.refreshElement}
            />
          </td>
        </>
      ) : (
        <td className={"text-left"}>
          {props.assignAssetPosition && (
            <LinkAssetPositionFromAssetButton
              asset={props.asset}
              assetPosition={props.assignAssetPosition}
              refresh={props.refreshList}
            />
          )}
        </td>
      )}
    </tr>
  );
};

export default AssetListGenericRowsContent;
