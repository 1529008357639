import React from "react";
import TabGroupInterface, {Tab} from "./TabGroupInterface";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {generatePath} from "react-router";
import {generateCompanySchemeHash} from "../navigation/LinkButton/LinkButton";
import {useSelectedCompany} from "../../reducers/company";
import {useSelectedScheme} from "../../reducers/scheme";

interface Props {
  tabs: (Tab | undefined)[];
  additionalButtons?: JSX.Element;
  tabRouteName?: string;
  topLevelPath: string;
}

const RoutedTabGroupInterface = (props: Props): JSX.Element => {
  const { tabs, additionalButtons, tabRouteName = "tab" } = props;
  const params = useParams();

  const actualTabs =        tabs.filter((t) => {
          return t !== undefined;
        }) as Tab[]

  const liveTab: Tab = actualTabs.filter((tab: Tab) => tab?.tabPath === params[tabRouteName])[0] || actualTabs[0];

  const navigate = useNavigate();
  const location = useLocation();
  const company = useSelectedCompany();
  const scheme = useSelectedScheme();

  const clickTab = (tab: Tab): void => {
    const { tabRouteName = "tab",...rest } = params;
    navigate({
      pathname: generatePath(props.topLevelPath, {
        ...rest,
        [tabRouteName]: tab.tabPath,
      }),
      search: tab.keepSearchString ? location.search : undefined,
      hash: generateCompanySchemeHash(company, scheme),
    });
  };

  return (
    <TabGroupInterface
      tabs={actualTabs}
      additionalButtons={additionalButtons}
      liveTab={liveTab}
      switchTab={clickTab}
    />
  );
};

export default RoutedTabGroupInterface;
