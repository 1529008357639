/**
 * Meterpoint.io
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type ReportView = 'ASSET' | 'ASSET_POSITION';

export const ReportView = {
    ASSET: 'ASSET' as ReportView,
    ASSETPOSITION: 'ASSET_POSITION' as ReportView
};