import React, { createContext, useReducer } from "react";

interface PrivacyState {
  privacyMode: boolean;
}

type PrivacyActions =
  | "TURN_PRIVACY_MODE_ON"
  | "TURN_PRIVACY_MODE_OFF"
  | "LOGOUT";

export interface PrivacyAction {
  type: PrivacyActions;
}

const Reducer = (state: PrivacyState, action: PrivacyAction): PrivacyState => {
  switch (action.type) {
    case "TURN_PRIVACY_MODE_ON":
      return { privacyMode: true };
    case "TURN_PRIVACY_MODE_OFF":
      return { privacyMode: false };
    case "LOGOUT":
      return { privacyMode: false };

    default:
      return state;
  }
};

const PrivacyContext = createContext<{
  state: PrivacyState;
  dispatch: React.Dispatch<PrivacyAction>;
}>({ state: { privacyMode: true }, dispatch: () => null });

const PrivacyProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(Reducer, { privacyMode: true });

  return (
    <PrivacyContext.Provider value={{ state, dispatch }}>
      {children}
    </PrivacyContext.Provider>
  );
};

function usePrivacyMode(): boolean {
  return React.useContext(PrivacyContext).state.privacyMode;
}

export { PrivacyContext, PrivacyProvider, usePrivacyMode };
