import React from "react";
import { AssetPositionAssetLinkOut } from "../../openapi/model/assetPositionAssetLinkOut";
import UpdateButton from "../../components/Buttons/UpdateButton";

type Props = {
  assetPositionAssetLink: AssetPositionAssetLinkOut;
  refresh?: (updatedItem: AssetPositionAssetLinkOut) => void;
};

const UnlinkAssetPositionFromAssetButton = (props: Props): JSX.Element => {
  return (
    <UpdateButton
      entityType={"LINK"}
      entityId={props.assetPositionAssetLink.linkId}
      updatedEntity={{ ...props.assetPositionAssetLink, cancelled: true }}
      postUpdate={props.refresh}
      icon={"fa-trash-alt"}
      text={"Cancel Link"}
      color={"danger"}
    />
  );
};

export default UnlinkAssetPositionFromAssetButton;
