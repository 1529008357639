import React from 'react';
import {AssetType} from "../../openapi/model/assetType";
import useGetList from "../../hooks/getList";
import {Asset} from "../../openapi/model/asset";
import ApiPaginatedTable from "../../components/Pagination/NewApiPaginatedTable";
import {useField} from "formik";
import {Button} from "reactstrap";
import classNames from "classnames";

function AssetSelectFormField(props: {
    name: string,
    assetType: AssetType,
    companyId: string,
    unassignedOnly?: boolean
}) {

    const [field, _meta, {setValue}] = useField(props.name);

    const {unassignedOnly=true} = props

    const [{data,loading,error}] = useGetList("ASSET",{
        companyId: props.companyId, assetType: props.assetType,
        assetPositionId: unassignedOnly ? 'NONE':undefined
        }
        );

    function onClick(assetId: string) {
        setValue(assetId)
    }
    return (
        <ApiPaginatedTable
            loading={loading}
            error={error}
            cursor={data?.cursor}
            data={data}
            header={[<><tr><th>Serial Number</th><th>Manufacturer</th></tr></>,2]}
            row={(asset: Asset,_, key) => <tr key={key} className={
                classNames({'table-info':field.value === asset.assetId})
            }>
                <td>
                {asset.serialNumber}
            </td>
                <td>
                    {asset.manufacturer}
                </td>
                <td><Button color={'primary'} onClick={() => onClick(asset.assetId)}>
                    Select
                </Button></td>
            </tr>}
        />
    );
}

export default AssetSelectFormField;