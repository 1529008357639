import React from "react";
import {
  ColumnMap,
  TimeSeriesDataParams,
} from "../../../../model/deviceTimeSeries";
import { Moment } from "moment";
import GenericCharts from "../GenericCharts";
import HeatMeterFlowAndTemperatureChart from "./HeatMeterFlowAndTemperatureChart";
import {
  ErrorBand,
  identifyBestDurationForErrorBands,
  identifyErrorBands,
  removeValueErrorsFromData,
} from "../../../../time_series/asset/data/valueErrors";

const HeatMeterCharts = (props: {
  mappedTimeSeriesColumns: ColumnMap;
  times: Moment[];
  params: TimeSeriesDataParams;
  loading: boolean;
  error?: Error;
}): JSX.Element => {
  if (props.params.projection === "Raw") {
    return <GenericCharts {...props} />;
  } else {
    const genericCharts: ColumnMap = {};
    const combinedHeatAndFlowCharts: ColumnMap = {};
    let errorBands: Array<ErrorBand> = [];

    if (props.loading) {
      combinedHeatAndFlowCharts["Flow"] = {
        name: "Flow Temperature",
        unit: "deg C",
        data: [],
        type: "instant",
      };
      combinedHeatAndFlowCharts["Return Temperature"] = {
        name: "Flow Temperature",
        unit: "deg C",
        data: [],
        type: "instant",
      };
      combinedHeatAndFlowCharts["Flow Temperature"] = {
        name: "Flow Temperature",
        unit: "deg C",
        data: [],
        type: "instant",
      };
      combinedHeatAndFlowCharts["Power"] = {
        name: "Flow Temperature",
        unit: "deg C",
        data: [],
        type: "instant",
      };
    } else {
      const errorFreeData = removeValueErrorsFromData(
        props.mappedTimeSeriesColumns
      );

      errorBands = identifyErrorBands(
        props.mappedTimeSeriesColumns,
        props.times,
        identifyBestDurationForErrorBands(props.times)
      );

      Object.entries(errorFreeData).forEach(([name, value]) => {
        if (
          ["Flow", "Flow Temperature", "Return Temperature", "Power"].indexOf(
            name
          ) !== -1
        ) {
          combinedHeatAndFlowCharts[name] = value;
        } else {
          genericCharts[name] = value;
        }
      });
    }
    return (
      <>
        <HeatMeterFlowAndTemperatureChart
          flowRateData={combinedHeatAndFlowCharts["Flow"]}
          flowTemperatureData={combinedHeatAndFlowCharts["Flow Temperature"]}
          returnTemperatureData={
            combinedHeatAndFlowCharts["Return Temperature"]
          }
          powerData={combinedHeatAndFlowCharts["Power"]}
          errorBands={errorBands}
          times={props.times}
          params={props.params}
          loading={props.loading}
          error={props.error}
        />
        <GenericCharts
          mappedTimeSeriesColumns={genericCharts}
          times={props.times}
          params={props.params}
          loading={props.loading}
          error={props.error}
        />
      </>
    );
  }
};

export default HeatMeterCharts;
