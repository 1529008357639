import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from "reactstrap";
import SimpleLineChart, {
  DataPoint,
} from "../../../components/charts/SimpleLineChart";
import {
  ColumnDefinitionWithData,
  TimeSeriesDataParams,
} from "../../../model/deviceTimeSeries";
import { Moment } from "moment";
import SingleBarChart from "../../../components/charts/SingleBarChart";

const ChartForSingleTimeSeriesItem = (props: {
  column: ColumnDefinitionWithData;
  times: Moment[];
  params: TimeSeriesDataParams;
  loading: boolean;
}): JSX.Element => {
  const [line, _switchLine] = useState(true);

  const column = props.column;
  const data = column.data.map(
    (tsValue: string | undefined, tsIndex: number) => {
      return [
        props.times[tsIndex],
        tsValue !== undefined ? parseFloat(tsValue as string) : undefined,
      ] as DataPoint;
    }
  );

  return (
    <Col xs={12} xl={6}>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <h6 className="surtitle">Overview</h6>
              <h5 className="h3 mb-0">{column.name}</h5>
            </Col>
            <Col className={"align-self-end"}>
              {column.type === "cumulative" && (
                <ButtonToolbar className={"float-right"}>
                  <ButtonGroup>
                    <Button
                      className={"btn-sm"}
                      color={"primary"}
                      active={line}
                      onClick={(): void => {
                        _switchLine(true);
                      }}
                    >
                      &Sigma;{" "}
                    </Button>
                    <Button
                      className={"btn-sm"}
                      color={"primary"}
                      active={!line}
                      onClick={(): void => {
                        _switchLine(false);
                      }}
                    >
                      &Delta;
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              )}
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <div className="chart">
            {line ? (
              <SimpleLineChart
                data={data}
                name={column.name}
                unit={column.unit}
                params={props.params}
                loading={props.loading}
              />
            ) : (
              <SingleBarChart
                data={data}
                definition={column}
                startDatetime={props.params.startDatetime}
                endDatetime={props.params.endDatetime}
                loading={props.loading}
              />
            )}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ChartForSingleTimeSeriesItem;
