import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { SchemeContext } from "../../reducers/scheme";

const SchemeHomepage = (): JSX.Element => {
  const { schemeId } = useParams<{ schemeId: string }>();
  const { dispatch } = React.useContext(SchemeContext);

  if (schemeId === undefined) {
    throw new Error("SchemeId must be specified");
  }

  useEffect(() => {
    dispatch({ type: "SELECT_SCHEME_BY_ID", payload: schemeId });
  }, [schemeId, dispatch]);

  return <></>;
};

export default SchemeHomepage;
