import React from "react";
import {LocationGroup} from "../../openapi/model/locationGroup";
import {SelectComponent, SelectFieldProps,} from "../Forms/SelectComponent";
import {SchemeContext} from "../../reducers/scheme";
import {locationGroupListToOptions} from "../Location/model";

interface Props extends SelectFieldProps {
  fieldName: string;
}

export default function LocationGroupSelect(props: Props): JSX.Element {
  const locationGroups = React.useContext(SchemeContext).state.groups;
  const { fieldName, ...rest } = props;

  const ready = !locationGroups.loading && !locationGroups.error;
  const haveGroups = locationGroups.groups && locationGroups.groups.length > 0;
  const altText: string = ready
    ? "No Groups"
    : locationGroups.loading
    ? "Loading Groups"
    : locationGroups.error?.message || "Error Loading Groups";
  return (
    <SelectComponent
      label={"Group"}
      name={fieldName}
      options={
        haveGroups
          ? [
              { label: "All", value: "undefined" },
              ...locationGroupListToOptions(
                locationGroups.groups as LocationGroup[],
                0
              ),
            ]
          : [{ label: altText, value: "undefined" }]
      }
      paddingX={null}
      disabled={!haveGroups}
      {...rest}
    />
  );
}
