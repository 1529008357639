import React, { useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import RadioButtonGroup from "../../components/Forms/RadioButtonGroup";
import { CompanyUser } from "../users/users";
import { UserCompanyProperties } from "../../openapi/model/userCompanyProperties";
import useUpdateEntity from "../../hooks/updateEntity";
import DeleteButton from "../../components/Buttons/DeleteButton";
import { Status } from "../../openapi/model/status";
import UserTypeLabel from "components/user/UserTypeLabel";
import PrivacyMode from "../../components/Text/PrivacyMode";
import EditUserTypeModel from "../users/EditUserTypeModel";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import EditButton from "../../components/Buttons/EditButton";

interface Props {
  userCompany: CompanyUser;
  showCompanyId?: boolean;
  showUserDetails?: boolean;
  profileAction?: boolean;
}

const UserCompanyListItemForm = (props: Props): JSX.Element => {
  const {
    showCompanyId = false,
    showUserDetails = false,
    profileAction = true,
  } = props;

  const [error, setError] = useState<Error | undefined>(undefined);
  const [user, setUser] = useState<CompanyUser>(props.userCompany);

  const { updateEntity } = useUpdateEntity(
    "USER",
    props.userCompany.userId,
    undefined,
    profileAction
      ? `user/profile/company/${props.userCompany.companyId}/properties`
      : `user/${user.userId}/company/${user.companyId}`
  );

  const initialValues: UserCompanyProperties = {
    receivesAlerts: user.properties?.receivesAlerts,
  };

  const postDelete = () => {
    setUser((s) => {
      return { ...s, status: "DELETED" };
    });
  };

  const submit = (
    values: UserCompanyProperties,
    {
      setSubmitting,
      resetForm,
      setStatus,
    }: FormikHelpers<UserCompanyProperties>
  ) => {
    setSubmitting(true);
    setStatus("submitting");
    setError(undefined);

    let p;

    if (profileAction) {
      p = updateEntity(values);
    } else {
      p = updateEntity({
        userType: user.userType,
        companyId: user.companyId,
        properties: values,
      });
    }

    p.then(() => {
      setStatus("success");
    })
      .catch((err: Error) => {
        resetForm();
        setStatus("error");
        setError(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const [openEditModal, editModal] = EditUserTypeModel(
    user,
    (updatedCompany: CompanyUser) => {
      setUser(updatedCompany);
    }
  );

  return (
    <tr data-cy={"list-item"} key={user.userId}>
      {editModal}
      {showCompanyId && <td data-cy={"user-company"}>{user.companyId}</td>}
      {showUserDetails && (
        <>
          <td data-cy="user-fullname">
            {" "}
            <PrivacyMode>{user.fullname}</PrivacyMode>{" "}
          </td>
          <td data-cy="user-email_address">
            <PrivacyMode>{user.emailAddress}</PrivacyMode>
          </td>
        </>
      )}
      <td data-cy={"user-type"}>
        <UserTypeLabel user={user} />
      </td>
      <td data-cy={"user-alerts"}>
        {!user.apiUser && (
          <Formik initialValues={initialValues} onSubmit={submit}>
            {({ values, setFieldValue, submitForm, status }): JSX.Element => (
              <Form>
                <RadioButtonGroup
                  fieldName={"receivesAlerts"}
                  title={""}
                  options={[
                    { name: "On", value: true },
                    { name: "Off", value: false },
                  ]}
                  currentValue={values["receivesAlerts"]}
                  onChange={(): void => {
                    submitForm();
                  }}
                  setFieldValue={setFieldValue}
                  textPosition={"left"}
                  buttonSize={"btn-sm"}
                  paddingX={0}
                  paddingY={0}
                  submitState={status}
                  submitError={error}
                />
              </Form>
            )}
          </Formik>
        )}
      </td>
      <td>
        {!user.apiUser && (
          <DisplayIfUserType userTypes={"admin"}>
            <EditButton onClick={openEditModal} />
          </DisplayIfUserType>
        )}
        {user.apiUser && user.status !== Status.DELETED && (
          <DeleteButton
            entityType={"USER"}
            entityId={user.fullname}
            path={`user/api/${user.fullname}?company_id=${user.companyId}`}
            postDelete={postDelete}
            errorMessageBody={<b>Failed to delete user</b>}
          />
        )}
      </td>
    </tr>
  );
};

export default UserCompanyListItemForm;
