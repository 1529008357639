import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import classNames from "classnames";
import PrivacyMode from "components/Text/PrivacyMode";
import SchemeSelectWrapper, { SchemeSelectProps } from "./SchemeSelectWrapper";

export default function SchemeSelect(): JSX.Element {
  const [open, setOpen] = useState(false);

  return (
    <SchemeSelectWrapper>
      {({ selected, loading, schemes, select }: SchemeSelectProps) => {
        return (
          <>
            <Dropdown isOpen={open} toggle={() => setOpen((p) => !p)}>
              <DropdownToggle
                caret
                data-cy={`scheme-select`}
                size={"sm"}
                className={"px-2 py-2"}
              >
                Scheme
                <span className={"d-none d-sm-inline text-grey"}>
                  :{" "}
                  {selected ? (
                    <PrivacyMode>{selected.schemeName}</PrivacyMode>
                  ) : (
                    "All"
                  )}
                </span>
              </DropdownToggle>
              {loading ? (
                <DropdownMenu data-cy={"scheme-select-loading"} right={true}>
                  <DropdownItem
                    data-cy={"scheme-select-loading"}
                    className={classNames({})}
                  >
                    Loading...
                  </DropdownItem>
                </DropdownMenu>
              ) : (
                <DropdownMenu data-cy={"scheme-select-content"} right={true}>
                  <DropdownItem
                    data-cy={"scheme-select-all"}
                    onClick={(): void => {
                      select();
                    }}
                    className={classNames({
                      active: selected === null,
                    })}
                  >
                    All
                  </DropdownItem>
                  <DropdownItem divider={true} />
                  {schemes.map((scheme, index): JSX.Element => {
                    return (
                      <DropdownItem
                        key={index}
                        data-cy={`scheme-select-${index}`}
                        onClick={(): void => {
                          select(scheme);
                        }}
                        active={scheme.schemeId === selected?.schemeId}
                      >
                        <PrivacyMode>{scheme.schemeName}</PrivacyMode>
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              )}
            </Dropdown>
          </>
        );
      }}
    </SchemeSelectWrapper>
  );
}
