import React, { useState } from "react";
import { JobDefinitionOut } from "../../openapi/model/jobDefinitionOut";
import { JobStatus } from "../../openapi/model/jobStatus";
import moment, { Moment } from "moment";
import { Form, Formik, FormikHelpers } from "formik";
import SingleDatePicker from "../../components/Forms/SingleDatePicker";
import { formatDate } from "../../components/utils/HighlightNullAndOldDates";
import { JobExecutionInstruction } from "openapi/model/jobExecutionInstruction";
import { Alert, Button, Col, Row } from "reactstrap";
import BlockSpinner from "components/Spinners/BlockSpinner";
import { ErrorBanner } from "../../components/Error/ErrorBanner";
import DisplayIfUserType from "../../components/utils/DisplayIfUserType/DisplayIfUserType";
import useCreateEntity from "../../hooks/createEntity";

interface Props {
  job: JobDefinitionOut;
  refresh: () => void;
}

interface FormValues {
  date: Moment;
}

const ExecuteJobForm = (props: Props): JSX.Element => {
  const { createEntity } =
    useCreateEntity<JobExecutionInstruction>("JOB_EXECUTE");

  const [error, setError] = useState<Error | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);

  const { job } = { ...props };
  const yesterday = moment().startOf("day").subtract(1, "second");

  const submit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ): void => {
    setSubmitting(true);
    setError(undefined);
    setSuccess(undefined);
    const definition: JobExecutionInstruction = {
      companyId: job.companyId,
      jobId: job.jobId,
      endDate: values.date.format("YYYY-MM-DD"),
    };
    createEntity(definition)
      .then(() => {
        setSuccess("Job Scheduled.")
        props.refresh();

      })
      .catch((err) => setError(err))
      .finally(() => setSubmitting(false));
  };

  const initialValues: FormValues = { date: yesterday };

  return (
    <DisplayIfUserType userTypes={"admin"}>
      {job.status === JobStatus.Active ? (
        <Formik initialValues={initialValues} onSubmit={submit}>
          {({ isSubmitting, submitForm }) => (
            <BlockSpinner loading={isSubmitting}>
              <Row>
                <Col xs={12}>
                  <p>
                    Job {job.name} ({job.jobId}) can be executed for any date
                    before
                    {formatDate(yesterday, "L")}.
                  </p>
                  <p>
                    Select a date to run the report for below and press execute.
                  </p>
                  <p>
                    Your output report may take a few minutes to be produced.
                  </p>
                </Col>
                <Col xs={12}>
                  <Form>
                    <Col xs={12}>
                      <SingleDatePicker dateName={"date"} max={yesterday} />
                    </Col>
                    <Col xs={12}>
                      <div className={"form-group row float-right"}>
                        <Button
                          type={"submit"}
                          color={"primary"}
                          onClick={submitForm}
                          disabled={isSubmitting}
                        >
                          Execute
                        </Button>
                      </div>
                    </Col>
                  </Form>
                </Col>
              </Row>
            </BlockSpinner>
          )}
        </Formik>
      ) : (
        <Row>
          <Col xs={12}>
            Job {job.name} ({job.jobId}) is not active and can not be executed.
          </Col>
        </Row>
      )}
      <Row className={"py-2"}>
        <Col xs={12}>
          {success && <Alert color={"success"}>{success}</Alert>}
          {error && <ErrorBanner error={error} />}
        </Col>
      </Row>
    </DisplayIfUserType>
  );
};

export default ExecuteJobForm;
