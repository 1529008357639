import React from "react";

import Action, { ActionProps } from "core/action/Action";

import useGetWholeList, { GetWholeListParams } from "hooks/getWholeList";
import FileSaver from "file-saver";
import { unparse } from "../../components/utils/CSVExport/CSVExportButton";

export interface ExportActionProps
  extends Omit<ActionProps, "onClick">,
    GetWholeListParams {
  exportFileProps?: ExportFileProps;
}

export interface ExportFileProps {
  filename?: string;
  columnOrder?: string[];
  sortColumns?: boolean;
}

const saveDataAsFile = (
  data: Array<any>,
  { filename, columnOrder, sortColumns = false }: ExportFileProps = {}
): void => {
  FileSaver.saveAs(
    new Blob([unparse(data, columnOrder, sortColumns)], {
      type: "text/csv;charset=utf-8",
    }),
    `${filename ? filename : "export"}.csv`
  );
};

const ExportAction = ({
  entityType,
  params,
  children,
  exportFileProps,
  ...rest
}: ExportActionProps) => {
  const { execute, loading } = useGetWholeList(entityType);

  const download = () => {
    execute(params || {}).then((data) => {
      if (data) {
        return saveDataAsFile(data, exportFileProps);
      } else {
        throw new Error("No data available.");
      }
    });
  };
  return (
    <Action {...rest} onClick={download}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { loading });
        }
        return child;
      })}
    </Action>
  );
};

export default ExportAction;
