export type Entities =
  | "ASSET"
  | "ASSET_ACTION"
  | "ASSET_CONFIGURATION"
  | "DETAILED_ASSET_CONFIGURATION"
  | "LOCATION"
  | "LOCATION_GROUP"
  | "ASSET_POSITION"
  | "ASSET_REGISTER"
  | "GATEWAY_FILE"
  | "EVENT"
  | "ALARM"
  | "JOB"
  | "JOB_EXECUTE"
  | "LINK"
  | "USER"
  | "API_USER"
  | "UPDATE"
  | "COMPANY"
  | "SCHEME"
  | "NOTE"
  | "STREAMING_CONFIGURATION"
  | "TARIFF"
  | "TARIFF_INSTANCE"
  | "ACCOUNT"
  | "REPORT_EXECUTION"
  | "WIRELESS_SURVEY"
    | "WIRELESS_SURVEY_DATA_COLLECTOR"

    ;

export const EntitiesPathMap: Record<Entities, string> = {
  ASSET: "asset",
  ASSET_ACTION: "asset",
  ASSET_CONFIGURATION: "asset",
  DETAILED_ASSET_CONFIGURATION: "asset",
  LOCATION: "location",
  LOCATION_GROUP: "scheme/{scheme_id}/groups",
  ASSET_POSITION: "asset_position",
  GATEWAY_FILE: "gateway_file",
  EVENT: "event",
  ALARM: "event/alarm",
  JOB: "job",
  JOB_EXECUTE: "job/execute",
  ASSET_REGISTER: "asset_register",
  LINK: "asset_position/link",
  USER: "user",
  API_USER: "user",
  UPDATE: "bulk",
  COMPANY: "company",
  SCHEME: "scheme",
  NOTE: "note",
  STREAMING_CONFIGURATION: "streaming",
  TARIFF: "tariff",
  TARIFF_INSTANCE: "tariff/{tariff_id}/instance",
  ACCOUNT: "account",
  REPORT_EXECUTION: "report",
  WIRELESS_SURVEY: 'wireless_survey',
  WIRELESS_SURVEY_DATA_COLLECTOR: 'wireless_survey/{survey_id}/data_collector'

};

export const EntitiesIdMap: Record<Entities, string | undefined> = {
  ASSET: "asset_id",
  ASSET_ACTION: "asset_id",
  ASSET_CONFIGURATION: "asset_id",
  DETAILED_ASSET_CONFIGURATION: "asset_id",
  LOCATION: "location_id",
  LOCATION_GROUP: undefined,
  ASSET_POSITION: "asset_position_id",
  GATEWAY_FILE: "gateway_file_id",
  EVENT: "event_id",
  ALARM: "event_id",
  JOB: "job_id",
  JOB_EXECUTE: "job_id",
  ASSET_REGISTER: undefined,
  LINK: "link_id",
  USER: "user_id",
  API_USER: "user_id",
  UPDATE: "update_id",
  COMPANY: "company_id",
  SCHEME: "scheme_id",
  NOTE: "note_id",
  STREAMING_CONFIGURATION: "id",
  TARIFF: "tariff_id",
  TARIFF_INSTANCE: "tariff_instance_id",
  ACCOUNT: "account_id",
  REPORT_EXECUTION: "report_id",
  WIRELESS_SURVEY: 'survey_id',
  WIRELESS_SURVEY_DATA_COLLECTOR: 'data_collector_id'
};
