import React from "react";
import { AssetPosition } from "../../openapi/model/assetPosition";
import WirelessRFDataApiWrapper from "./WirelessRFDataApiWrapper";
import ReceiverAssignmentTable from "./ReceiverAssignmentTable";

export default function WirelessClusterReceiverAssignmentTab(props: {
  clusterPosition: AssetPosition;
}): JSX.Element {
  const { clusterPosition } = props;
  return (
    <>
      <WirelessRFDataApiWrapper
        request={{
          companyId: clusterPosition.companyId,
          schemeId: clusterPosition.schemeId,
          period: "ALL",
          parentAssetPositionId: clusterPosition.assetPositionId,
        }}
      >
        {(props) => (
          <ReceiverAssignmentTable
            {...props}
            wirelessCluster={clusterPosition}
          />
        )}
      </WirelessRFDataApiWrapper>
    </>
  );
}
