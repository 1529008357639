import React, {useState} from 'react';
import {ParsedAssetDefinition} from "./UploadPage";
import {Tooltip} from "reactstrap";

export const ErrorRowInfoTooltipIcon = (props: { item: ParsedAssetDefinition; index: number }): JSX.Element => {
    const [tooltip, setTooltip] = useState(false);
    const {item, index} = props;

    const tooltipContent = (): string => {
        return item.errors?.map((err) => {return err.error}).join(', ') || ''
    }

    const id = `simple-ar-tt-${index}`;

    return <>
        {item.errors && <>
            <i className={'fa fa-fw fa-info-circle'} id={id}/>
            <Tooltip placement="right"
                     isOpen={tooltip}
                     target={id}
                     toggle={(): void => {
                         setTooltip(!tooltip);
                     }}>
                {tooltipContent()}
            </Tooltip></>
        }
    </>

}


