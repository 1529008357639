import React from 'react';
import AggregatedTimeSeriesStatsReportView
    from "../AggregatedTimeSeriesStatsReportView/AggregatedTimeSeriesStatsReportView";
import AggregatedTimeSeriesStatsWrapper from "../AggregatedTimeSeriesStatsWrapper/AggregatedTimeSeriesStatsWrapper";
import TimeSeriesQueryUrlWrapper from "../TimeSeriesQueryUrlWrapper/TimeSeriesQueryUrlWrapper";
import {meterOptions} from "../../Asset/AssetTypeSelectCombo";
import {AssetType} from "../AssetParameterSelectCombo/AssetParameterSelectCombo";
import {Period} from "../../../openapi/model/period";
import moment from "moment";
import {NewTimeSeriesRequestParams} from "../model";
import {mapFormValuesToTimeSeriesParams} from "../AggregatedTimeSeriesQueryForm/AggregatedTimeSeriesQueryForm";
import {GroupBy} from "../../../openapi/model/groupBy";
import AggregatedTimeSeriesDataWrapper from "../AggregatedTimeSeriesDataWrapper/AggregatedTimeSeriesDataWrapper";

export interface AggregatedTimeSeriesStatsReportProps {
    companyId: string;
    schemeId?: string
}


export function AggregatedTimeSeriesStatsReportComponent({companyId, schemeId, params, onChange, showForm}:{
    companyId: string;
    schemeId?: string;
    params: NewTimeSeriesRequestParams;
    onChange?: (v: NewTimeSeriesRequestParams) => void;
    showForm?: boolean;
}) {


    return <AggregatedTimeSeriesStatsWrapper companyId={companyId} schemeId={schemeId}
                                                       params={{...params, group: undefined, useCase: undefined}}>
                    {
                        (data) => <>
                            {
                                schemeId ?
                                    <AggregatedTimeSeriesDataWrapper companyId={companyId}
                                                                     schemeId={schemeId}
                                                                     params={{...params, period: Period.ALL}}>
                                        {
                                            (aggregatedData) =>
                                                <AggregatedTimeSeriesStatsReportView
                                                    companyId={companyId}
                                                    schemeId={schemeId}
                                                    data={data}
                                                    aggregatedData={aggregatedData}
                                                    showForm={showForm}
                                                    onSubmit={(v) => {
                                                        onChange && onChange(mapFormValuesToTimeSeriesParams(v, true, false, true))
                                                        return Promise.resolve()
                                                    }} request={params}/>
                                        }
                                    </AggregatedTimeSeriesDataWrapper>
                                    :
                                    <AggregatedTimeSeriesStatsReportView
                                        companyId={companyId}
                                        schemeId={schemeId}
                                        data={data}
                                        showForm={showForm}
                                        onSubmit={(v) => {
                                            onChange && onChange(mapFormValuesToTimeSeriesParams(v, true, false))
                                            return Promise.resolve()
                                        }} request={params}/>}
                        </>
                    }
                </AggregatedTimeSeriesStatsWrapper>

}

export default function AggregatedTimeSeriesStatsReport(props: AggregatedTimeSeriesStatsReportProps) {
    return (
        <TimeSeriesQueryUrlWrapper
            defaultValues={{
                assetType: meterOptions as AssetType[],
                parameter: 'Power',
                aggregation: 'mean',
                period: Period.DAILY,
                startDate: moment().startOf('day').subtract(14, 'days'),
                endDate: moment().endOf('day').subtract(1, 'days'),
                groupBy: GroupBy.NONE,
            }}
        >
            {
                (
                    params: NewTimeSeriesRequestParams,
                    onChange: (v: NewTimeSeriesRequestParams) => void,
                ) => <AggregatedTimeSeriesStatsReportComponent
                        params={params}
                        companyId={props.companyId}
                        schemeId={props.schemeId}
                        onChange={onChange}
                    />
            }
        </TimeSeriesQueryUrlWrapper>
    );
}
