import { Routes } from "react-router-dom";
import { SentryRoute } from "../App";
import Login from "../containers/login/Login";
import ForgottenPasswordPage from "../containers/profile/ForgottenPasswordPage";
import React from "react";
import DebugLayout from "../components/navigation/DebugLayout";

const AuthRoutes = (): JSX.Element => {
  return (
    <Routes>
      <SentryRoute element={<DebugLayout />}>
        <SentryRoute path={"/login"} element={<Login />} />
        <SentryRoute
          path={"/reset-password"}
          element={<ForgottenPasswordPage />}
        />
      </SentryRoute>
    </Routes>
  );
};

export default AuthRoutes;
