import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
// import { Moment } from "moment";
import * as mTZ from "moment-timezone";
// import { usePrivacyMode } from "reducers/privacy";
import HighchartsPrivacyModeFilter from "../Text/HighchartsPrivacyModeFilter";
import HC_more from "highcharts/highcharts-more";
import { AssetRegisterAggregatedTimeSeriesResponse } from "../../openapi/model/assetRegisterAggregatedTimeSeriesResponse";
import { AssetRegisterItemAggregatedTimeSeries } from "../../openapi/model/assetRegisterItemAggregatedTimeSeries";
import _, { round } from "lodash";
import { usePrivacyMode } from "../../reducers/privacy";
import {
  AggregatedChartsAmendedOptions,
  AggregatedDataItemClickHandlerProps,
  aggregationDataItemClickHandler,
  AssetRegisterItemColumn,
  AssetRegisterItemPoint,
} from "./AggregationChartFunctions";

// eslint-disable-next-line @typescript-eslint/no-var-requires
require("highcharts/modules/no-data-to-display.js")(Highcharts);
HC_more(Highcharts);

declare global {
  interface Window {
    moment: any;
  }
}

window.moment = mTZ;

export default function AggregationDistributionChart(
  props: {
    data?: AssetRegisterAggregatedTimeSeriesResponse;
    aggregation: string;
    parameter: string;
    loading: boolean;
    privacyModeEnabled?: boolean;
    name?: string;
    unit: string;
    height?: string;
  } & AggregatedDataItemClickHandlerProps
): JSX.Element {
  const { height = "400px" } = props;
  const privacyMode = usePrivacyMode();

  const aggregation = _.camelCase(props.aggregation);
  const parameter = _.camelCase(props.parameter);

  const dataMapper = (
    v: AssetRegisterItemAggregatedTimeSeries
  ): AssetRegisterItemColumn => {
    return {
      assetPositionId: v.assetPositionId,
      assetPositionReference: v.assetPositionReference,
      locationAddress: v.locationAddress,
      assetId: v.assetId,
      y: v.aggregations[parameter]?.[aggregation]?.[0],
    };
  };

  const seriesDefinition = (
    data: AssetRegisterAggregatedTimeSeriesResponse,
    name?: string,
    userRef?: string,
    color?: string
  ): Highcharts.SeriesColumnOptions & AggregatedChartsAmendedOptions => {
    return {
      id: userRef,
      data: data.data.map(dataMapper).sort((n1, n2) => n1.y - n2.y),
      type: "column",
      color: color ? `var(--${color})` : undefined,
      animation: false,
      events: {
        click: aggregationDataItemClickHandler({
          startTime: props.startTime,
          endTime: props.endTime,
          privacyMode: privacyMode,
          companyId: props.companyId,
          schemeId: props.schemeId,
        }),
      },
      turboThreshold: 0,
      tooltip: {
        pointFormatter: function (this: AssetRegisterItemPoint) {
          return (
            `<tspan style='fill:${this.color}'>●</tspan>` +
            `<tspan style='${privacyMode && "filter: url(#blur)"}'> 
${this.locationAddress} - ${this.assetPositionReference}
             </tspan>` +
            `<tspan style='font-weight:bold'> ${round(this.y as number, 1)}${
              props.unit
            } </tspan>`
          );
        },
      },
    };
  };

  const series: Highcharts.SeriesColumnOptions[] = props.data
    ? [seriesDefinition(props.data)]
    : [];

  const options: Highcharts.Options = {
    title: {
      text: "",
    },
    chart: {
      type: "column",
      height: height,
    },
    // xAxis: {
    //   type: "datetime",
    //   min: props.params?.startDatetime?.valueOf(),
    //   max: props.params?.endDatetime?.valueOf(),
    // },
    series: series,
    yAxis: props.data
      ? [
          {
            labels: {
              format: `{value}${props.unit}`,
            },
            title: {
              text: props.name,
            },
          },
        ]
      : [],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    lang: {
      noData: props.loading ? "Loading Data " : "No data for this time period",
    },
    noData: {
      style: {
        fontWeight: "normal",
        fontSize: "15px",
        color: "black",
      },
    },
    tooltip: {
      shared: true,
      // valueSuffix: props.unit,
      // valueDecimals: props.rounding,
      // pointFormat: `{series.name} {point.y}${props.unit}`
    },
    exporting: {
      fallbackToExportServer: false,
      buttons: {
        contextButton: {
          // enabled:
          //   props.displayExport !== undefined ? props.displayExport : true,
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "downloadSVG",
          ],
        },
      },
    },
    time: {
      timezone: "Europe/London",
    },
  };

  return (
    <>
      <HighchartsPrivacyModeFilter />
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  );
}
