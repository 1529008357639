import React from "react";
import {Col, Row} from "reactstrap";
import moment from "moment";
import BetaAlert from "../../../components/Beta/BetaAlert";
import {VOLUME_WEIGHTED_AVERAGE_AGGREGATIONS} from "containers/report/vwartReport/VWARTReportView";
import {
    AssetRegisterAggregatedTimeSeriesResponse
} from "../../../openapi/model/assetRegisterAggregatedTimeSeriesResponse";
import TimeSeriesReportTable from "containers/report/aggregatedTimeSeriesReport/TimeSeriesReportTable";
import _ from "lodash";
import RoutedTabGroupInterface from "components/TabGroup/RoutedTabGroupInterface";
import {AssetType} from "openapi/model/assetType";
import TimeSeriesReportGraph from "containers/report/aggregatedTimeSeriesReport/TimeSeriesReportGraph";
import {TableRow} from "react-data-table-component";
import CSVExportButton from "components/utils/CSVExport/CSVExportButton";
import {
    DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER,
    DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER_ASSET_POSITION_ONLY
} from "components/Table/AssetRegisterData";
import {ReportView} from "openapi/model/reportView";
import AggregatedTimeSeriesQueryForm, {
    AggregatedTimeSeriesQueryFormValues,
    mapTimeSeriesRequestParamsToFormValues
} from "components/TimeSeries/AggregatedTimeSeriesQueryForm/AggregatedTimeSeriesQueryForm";
import {UseAxiosResult} from "axios-hooks";
import {NewTimeSeriesRequestParams} from "components/TimeSeries/model";
import BlockSpinner from "../../Spinners/BlockSpinner";
import {ErrorBanner} from "../../Error/ErrorBanner";

interface AggregatedTimeSeriesDataReportViewProps {
    onSubmit: (v: AggregatedTimeSeriesQueryFormValues) => Promise<any>,
    request: NewTimeSeriesRequestParams,
    data: UseAxiosResult<AssetRegisterAggregatedTimeSeriesResponse>,
    companyId: string,
    schemeId: string
}


export interface SingleAggregationTimeAssetData extends TableRow {
    companyId: string;
    companyName: string;
    schemeName: string;
    schemeId: string;
    assetId?: string;
    serialNumber?: string;
    assetType: AssetType;
    assetPositionId: string;
    assetPositionReference?: string;
    locationId: string;
    locationAddress?: string;
    locationCustomerReference?: string;

    data: { [key: string]: number | undefined | string };
}

const mapReportData = (
    data: AssetRegisterAggregatedTimeSeriesResponse,
    parameter: string,
    aggregation: string,
    includeAssetDetails = true,
): Array<SingleAggregationTimeAssetData> => {
    return data.data.map((assetData) => {
        const {aggregations, ...asset} = assetData;
        const assetOnlyDetails = {...asset};
        delete assetOnlyDetails.assetId;
        delete assetOnlyDetails.serialNumber;
        delete assetOnlyDetails.installDate;
        delete assetOnlyDetails.uninstallDate;
        return {
            ...(includeAssetDetails ? asset : assetOnlyDetails),
            data: Object.fromEntries(
                data.times.map((e, i) => {
                    const aggregationParameter =
                        (aggregations[parameter] ?? {
                            [aggregation]: [],
                        })[aggregation] ?? [];
                    return [
                        moment(e).toISOString(true),
                        aggregationParameter[i] ?? undefined,
                    ];
                })
            ),
        };
    });
};

const csvExport = (
    data?: Array<SingleAggregationTimeAssetData>
): (() => Promise<any>) => {
    return () =>
        new Promise((resolve, reject) => {
            if (data) {
                resolve(
                    data.map((value) => {
                        const {data, ...asset} = value;
                        return {
                            ...asset,
                            ...data,
                        };
                    })
                );
            } else {
                reject(new Error("No Data"));
            }
        });
};

export default function AggregatedTimeSeriesDataReportView(props: AggregatedTimeSeriesDataReportViewProps): JSX.Element {

    const {aggregation, parameter, startDate, endDate} = props.request;
    const beta = VOLUME_WEIGHTED_AVERAGE_AGGREGATIONS.includes(aggregation as string);

    const [{data, loading, error}] = props.data;
    const showAssets = props.request.viewBy !== ReportView.ASSETPOSITION

    return (
        <BlockSpinner loading={loading}>
            <Row>

                <Col xs={12}>
                    <AggregatedTimeSeriesQueryForm
                        onSubmit={props.onSubmit}
                        initialValues={mapTimeSeriesRequestParamsToFormValues(props.request)}
                        wide={true}
                        showErrors={false}
                        showAssetUseCase={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} className={"mt-2"}>
                    {beta && <BetaAlert title={"Volume Weighted Aggregations"}/>}
                </Col>
                {error && <ErrorBanner error={error}/>}
            </Row>
            <Row>
                <Col xs={12}>
                    <>
                        <RoutedTabGroupInterface
                            tabs={[
                                {
                                    tabContent: (
                                        <>
                                            {data && (
                                                <TimeSeriesReportTable
                                                    reportData={mapReportData(
                                                        data,
                                                        _.camelCase(parameter),
                                                        _.camelCase(aggregation)
                                                    )}
                                                    times={data.times}
                                                    startTime={startDate}
                                                    endTime={endDate}
                                                    showAsset={showAssets}
                                                />
                                            )}
                                        </>
                                    ),
                                    tabName: "Table",
                                    tabPath: "table",
                                },
                                {
                                    tabContent: (
                                        <>
                                            {data && (
                                                <TimeSeriesReportGraph
                                                    times={data.times}
                                                    data={mapReportData(
                                                        data,
                                                        _.camelCase(parameter),
                                                        _.camelCase(aggregation)
                                                    )}
                                                    startTime={startDate}
                                                    endTime={endDate}
                                                />
                                            )}
                                        </>
                                    ),
                                    tabName: "Graph",
                                    tabPath: "graph",
                                },
                            ]}
                            additionalButtons={
                                <CSVExportButton
                                    download={csvExport(
                                        data
                                            ? mapReportData(
                                                data,
                                                _.camelCase(parameter),
                                                _.camelCase(aggregation),
                                                showAssets
                                            )
                                            : undefined
                                    )}
                                    disabled={data === undefined}
                                    columnOrder={
                                        showAssets ? DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER : DEFAULT_ASSET_REGISTER_DATA_EXPORT_COLUMN_ORDER_ASSET_POSITION_ONLY
                                    }
                                    filename={`${props.schemeId}_aggregated_${parameter}_${aggregation}`}
                                />
                            }
                            topLevelPath={"/admin/report/time_series/aggregated/:tab"}
                        />
                    </>
                </Col>
            </Row>
        </BlockSpinner>
    );
}
