import React, { useState } from "react";
import { IntegrationFile } from "../../../openapi/model/integrationFile";
import { AssetFile } from "../../../openapi/model/assetFile";
import { fileUrl, isIntegrationFile } from "./model";
import classNames from "classnames";
import { UncontrolledTooltip } from "reactstrap";
import useCreateEntity from "../../../hooks/createEntity";
import { FileProcessingStatus } from "../../../openapi/model/fileProcessingStatus";

interface ResentDataFileButtonProps {
  file: IntegrationFile | AssetFile;
  id: string;
  removeQuarantine: boolean;
}

export default function ResendDataFileButton(
  props: ResentDataFileButtonProps,
): React.ReactElement {
  const { file, id, removeQuarantine } = props;
  const [resent, setResent] = useState(false);

  const resendFile = useCreateEntity("GATEWAY_FILE", fileUrl(file));

  const handleResendFile = async (): Promise<any> => {
    return resendFile
      .createEntity({ companyId: file.companyId, removeQuarantine })
      .then(() => {
        setResent(true);
      });
  };

  return (
    <>
      {isIntegrationFile(file) && (
        <>
          {file.status === FileProcessingStatus.QUARANTINED ||
          !removeQuarantine ? (
            <a
              className={classNames({
                "p-1 table-action": true,
                "pointer-hover": true,
              })}
              id={`tooltip-file-resend-${id}`}
              onClick={async (e): Promise<any> => {
                e.preventDefault();
                return await handleResendFile();
              }}
            >
              <span
                className={classNames({
                  "fas fa-fw": true,
                  "fa-rotate":
                    !resendFile.creating &&
                    !resent &&
                    !resendFile.error &&
                    !removeQuarantine,
                  "fa-skull":
                    !resendFile.creating &&
                    !resent &&
                    !resendFile.error &&
                    removeQuarantine,
                  "fa-check": resent,
                  "fa-xmark": resendFile.error,
                  "fa-cog fa-spinner fa-spin": resendFile.creating,
                })}
              />
              {removeQuarantine ? (
                <UncontrolledTooltip
                  delay={0}
                  target={`tooltip-file-resend-${id}`}
                >
                  {resendFile.error
                    ? `Error removing quarantine from file ${resendFile.error.message}`
                    : "Remove file quarantine"}
                </UncontrolledTooltip>
              ) : (
                <UncontrolledTooltip
                  delay={0}
                  target={`tooltip-file-resend-${id}`}
                >
                  {resendFile.error
                    ? `Error resending file ${resendFile.error.message}`
                    : "Resend file"}
                </UncontrolledTooltip>
              )}
            </a>
          ) : (
            <span className={"fa fa-fw"} />
          )}
        </>
      )}
    </>
  );
}
