import React, { useState } from "react";
import { Event } from "../../openapi/model/event";

import * as _ from "lodash";
import "react-json-pretty/themes/monikai.css";
import classNames from "classnames";
import { UncontrolledTooltip } from "reactstrap";
import { loadFileFromApi } from "../../actions/helpers/presigned_s3";

const getArtifactsFileFromUrl = async (event: Event): Promise<void> => {
  const linked_jobs = event.affectedObjects.filter((e) => e.type === "job");
  const the_job = linked_jobs.length === 1 ? linked_jobs[0].id : undefined;

  return loadFileFromApi(
    `/job/artifacts?job_id=${the_job}&event_id=${event.eventId}&company_id=${event.companyId}`
  );
};

const EventJobCompleteArtifactsDownload = (props: {
  event: Event;
  index: string;
}): JSX.Element => {
  const { event, index } = { ...props };

  const [loading, setLoading] = useState(false);

  const downloadable = event.eventData?.artifactsFile !== undefined;

  const handleClick = async (): Promise<undefined> => {
    if (downloadable && !loading) {
      setLoading(true);

      getArtifactsFileFromUrl(event).finally(() => setLoading(false));
    }
    return;
  };

  return (
    <>
      {!_.isEmpty(event.eventData) &&
        (event.eventType === "JOB_COMPLETED" ||
          event.eventType === "REPORT_COMPLETED") && (
          <a
            className={classNames({
              "p-1 ": true,
              disabled: !downloadable,
              "pointer-hover": downloadable,
            })}
            id={`tooltip-artifacts-download-${index}`}
            onClick={async (e): Promise<any> => {
              e.preventDefault();
              return await handleClick();
            }}
          >
            <i
              className={classNames({
                "fas fa-fw": true,
                "fa-download": !loading && downloadable,
                "fa-ban": !downloadable,
                "fa-cog fa-spinner fa-spin": loading,
              })}
            />
            <UncontrolledTooltip
              delay={0}
              target={`tooltip-artifacts-download-${index}`}
            >
              {downloadable
                ? "Download job results"
                : "This file has no content"}
            </UncontrolledTooltip>
          </a>
        )}
    </>
  );
};

export default EventJobCompleteArtifactsDownload;
