import React from "react";
import { Routes } from "react-router-dom";
import { SentryRoute } from "../../App";
import SchemeTariffHomepage from "./SchemeTariffHomepage";

export default function TariffPage(): JSX.Element {
  return (
    <Routes>
      <SentryRoute path="" element={<SchemeTariffHomepage />} />
    </Routes>
  );
}
