import React from "react";
import { Routes } from "react-router-dom";
import { SentryRoute } from "../../App";
import AccountListPage from "./AccountListPage";

function AccountPage() {
  return (
    <Routes>
      <SentryRoute path={""} element={<AccountListPage />} />
    </Routes>
  );
}

export default AccountPage;
