import React from "react";
import {
  SelectComponent,
  SelectFieldProps,
} from "../../../components/Forms/SelectComponent";
import { Period } from "../../../openapi/model/period";

interface Props {
  fieldName: string;
}

export default function PeriodSelector(
  props: Props & SelectFieldProps
): JSX.Element {
  const options = [
    { label: "Daily", value: Period.DAILY },
    { label: "Weekly", value: Period.WEEKLY },
    { label: "7 Day", value: Period.SEVENDAY },
    { label: "Monthly", value: Period.MONTHLY },
  ];
  return (
    <SelectComponent name={props.fieldName} options={options} {...props} />
  );
}
