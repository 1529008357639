import React from "react";
import {
  Option,
  SelectComponent,
  SelectFieldProps,
} from "../../components/Forms/SelectComponent";
import { CumulativeValueTimeSeriesField } from "../../openapi/model/cumulativeValueTimeSeriesField";

function CumulativeDataSelect(props: { fieldName: string } & SelectFieldProps) {
  const values: Array<Option> = [
    {
      value: CumulativeValueTimeSeriesField.EnergyCooling,
      label: "Energy (Cooling)",
    },
    {
      value: CumulativeValueTimeSeriesField.EnergyHeating,
      label: "Energy (Heating)",
    },
    {
      value: CumulativeValueTimeSeriesField.EnergyElectricalActiveImport,
      label: "Energy (Electrical Active Import)",
    },
    {
      value: CumulativeValueTimeSeriesField.EnergyElectricalActiveExport,
      label: "Energy (Electrical Active Export)",
    },
    {
      value: CumulativeValueTimeSeriesField.EnergyElectricalReactiveImport,
      label: "Energy (Electrical Reactive Import)",
    },
    {
      value: CumulativeValueTimeSeriesField.EnergyElectricalReactiveExport,
      label: "Energy (Electrical Reactive Export)",
    },
    { value: CumulativeValueTimeSeriesField.Volume, label: "Volume" },
  ];
  return (
    <SelectComponent
      label={"Asset Type"}
      options={values}
      name={props.fieldName}
      {...props}
    />
  );
}

export default CumulativeDataSelect;
