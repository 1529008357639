import React, { useContext, useEffect, useMemo } from "react";
import "./App.scss";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import PageSpinner from "./components/Spinners/PageSpinner";
import AdminLayout from "layouts/admin/Admin";
import AuthLayout from "layouts/Auth";
import ReactDOM from "react-dom";
import "moment/locale/en-gb";
import * as Sentry from "@sentry/react";
import { configureSentry } from "./Config";
import useRoutingInstrumentation from "react-router-v6-instrumentation";
import { BrowserTracing } from "@sentry/tracing";
import { AppContext, AppProvider } from "./reducers/reactStore";
import { CompanyProvider } from "./reducers/company";
import { SchemeProvider } from "reducers/scheme";
import { PrivacyProvider } from "reducers/privacy";
import { ProfileProvider, useProfile } from "./reducers/profile";
import { AuthProvider } from "./reducers/auth";

const App = (): JSX.Element => {
  const location = useLocation();

  const { state, dispatch } = useContext(AppContext);

  const userProfile = useProfile();

  const routingInstrumentation = useRoutingInstrumentation();

  const browserTracing = useMemo(
    () => new BrowserTracing({ routingInstrumentation }),
    [routingInstrumentation]
  );

  useEffect(() => {
    configureSentry(browserTracing);
  }, [routingInstrumentation, browserTracing]);

  useEffect(() => {
    dispatch({ type: "SET_REFERER", payload: location });
  }, [location, dispatch]);
  return (
    <PageSpinner spin={!state.referer.initialized}>
      <Routes>
        {userProfile && (
          <SentryRoute path="/admin/*" element={<AdminLayout />} />
        )}
        <SentryRoute path="/auth/*" element={<AuthLayout />} />
        <SentryRoute
          path={"*"}
          element={<Navigate replace to={`/auth/login`} />}
        />
      </Routes>
    </PageSpinner>
  );
};

export const SentryRoute = Route;

const RootApp = (element?: HTMLElement): void => {
  ReactDOM.render(
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <BrowserRouter>
        <AppProvider>
          <AuthProvider>
            <ProfileProvider>
              <CompanyProvider>
                <SchemeProvider>
                  <PrivacyProvider>
                    <App />
                  </PrivacyProvider>
                </SchemeProvider>
              </CompanyProvider>
            </ProfileProvider>
          </AuthProvider>
        </AppProvider>
      </BrowserRouter>
      ,
    </Sentry.ErrorBoundary>,
    element !== undefined ? element : document.getElementById("root")
  );
};

export default RootApp;
