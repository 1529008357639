import React from "react";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import BlockSpinner from "../../Spinners/BlockSpinner";

interface Props {
  headerName?: string;
  headerTitle: string;
  children: any;
  loading?: boolean;
  "data-cy"?: string;
}

const MeterpointCard = (props: Props): JSX.Element => {
  const loading = props.loading || false;

  return (
    <Col xs={12} className={"px-1 px-sm-2 px-md-3"} data-cy={props["data-cy"]}>
      <Card className="shadow">
        <CardHeader className="bg-default border-0">
          <h3 className="text-white mb-0">{props.headerTitle}</h3>
        </CardHeader>
        <BlockSpinner loading={loading}>
          <CardBody
            className={"px-1 px-md-4 py-1 py-md-4"}
            style={{ minHeight: "400px" }}
          >
            {props.children}
          </CardBody>
        </BlockSpinner>
      </Card>
    </Col>
  );
};

export default MeterpointCard;
