import React from "react";
import { DeviceAsset } from "../../../openapi/model/deviceAsset";
import { GatewayAsset } from "../../../openapi/model/gatewayAsset";
import { AssetTimeSeriesRequestParams } from "./AssetDataSelectionForm";
import {
  DeviceTimeSeriesDisplayComponent,
  DeviceTimeSeriesDisplayComponentProps,
} from "./DeviceTimeSeriesDataContainer";
import useGetData from "../../../hooks/getData";
import {
  convertTimesToMoment,
  DeviceTimeSeriesData,
  mapColumns,
} from "../../../model/deviceTimeSeries";
import { Moment } from "moment";
import { AssetDataQualityResponse } from "openapi/model/assetDataQualityResponse";

interface Props {
  device: DeviceAsset | GatewayAsset;
  searchParams: AssetTimeSeriesRequestParams;
  render: DeviceTimeSeriesDisplayComponent;
}

const AssetTimeSeriesQualityGetter = (props: Props): JSX.Element => {
  const { device, searchParams } = props;
  const { assetId, companyId, schemeId } = device;

  const [{ data, loading, error }] = useGetData<AssetDataQualityResponse>({
    path: `data/asset/${assetId}/time_series/quality`,
    companyId,
    schemeId,
    params: {
      startDatetime: searchParams.startDatetime.toISOString(),
      endDatetime: searchParams.endDatetime.toISOString(),
    },
    singletonApi: true,
  });

  const timeseries: DeviceTimeSeriesData | undefined = data && {
    ...data,
    times: convertTimesToMoment(data.times),
    errors: [],
  };

  const displayProps = (): DeviceTimeSeriesDisplayComponentProps => {
    if (data && timeseries) {
      return {
        mappedTimeSeriesColumns: mapColumns(timeseries),
        times: timeseries.times as Moment[],
        params: searchParams,
        loading: loading,
        error: error ? error : undefined,
      };
    } else {
      return {
        mappedTimeSeriesColumns: {},
        times: [],
        params: searchParams,
        loading: loading,
        error: error ? error : undefined,
      };
    }
  };

  return <>{props.render(displayProps())}</>;
};

export default AssetTimeSeriesQualityGetter;
