import React, { createContext, useReducer } from "react";
import Reducer, { RefererState, Action } from "./refererReducer";

interface State {
  referer: RefererState;
}
const initialState: State = {
  referer: {
    initialized: false,
  },
};

const AppContext = createContext<{
  state: State;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

const mainReducer = (
  { referer }: { referer: RefererState },
  action: Action
) => ({
  referer: Reducer(referer, action),
});

const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
