/**
 * Meterpoint.io
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 */
export type BillingReportFields = 'schemeId' | 'schemeName' | 'locationId' | 'locationAddress' | 'assetPositionId' | 'assetPositionReference' | 'assetId' | 'assetType' | 'serialNumber' | 'billableType' | 'billableUnit';

export const BillingReportFields = {
    SchemeId: 'schemeId' as BillingReportFields,
    SchemeName: 'schemeName' as BillingReportFields,
    LocationId: 'locationId' as BillingReportFields,
    LocationAddress: 'locationAddress' as BillingReportFields,
    AssetPositionId: 'assetPositionId' as BillingReportFields,
    AssetPositionReference: 'assetPositionReference' as BillingReportFields,
    AssetId: 'assetId' as BillingReportFields,
    AssetType: 'assetType' as BillingReportFields,
    SerialNumber: 'serialNumber' as BillingReportFields,
    BillableType: 'billableType' as BillingReportFields,
    BillableUnit: 'billableUnit' as BillingReportFields
};