import React from "react";
// nodejs library to set properties for components
// reactstrap components
import {Col, Container, Row} from "reactstrap";
import Breadcrumbs, {PageDefinition} from "../navigation/breadcrumbs/Breadcrumbs";

const SimpleHeader = (props: {
    breadcrumbs?: PageDefinition[];
    hideBreadcrumbs?: boolean;
    noSchemeInBreadcrumbs?: boolean;
}): JSX.Element => {
    const showScheme = !props.noSchemeInBreadcrumbs;
    return <>
        <div className="header header-dark bg-primary pb-6 content__title content__title--calendar">
            <Container fluid>
                <div className="header-body">
                    <Row className="align-items-center py-4 d-none d-md-flex">
                        <Col>
                            {(props.hideBreadcrumbs !== true) && (props.breadcrumbs) &&
                            <Breadcrumbs pages={props.breadcrumbs} showScheme={showScheme}/>}
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    </>

}

export default SimpleHeader;
