import React from "react";
import { Asset } from "../../openapi/model/asset";
import AssetCompanyAssignForm from "./AssetCompanyAssignForm";
import HighlightNullAndOldDates, {
  formatDate,
} from "../../components/utils/HighlightNullAndOldDates";
import { Col, Row } from "reactstrap";
import AssetTypeHumanLabel from "../../components/Asset/AssetTypeHumanLabel";
import { AssetPosition } from "../../openapi/model/assetPosition";
import { Location } from "../../openapi/model/location";
import GatewayLink from "./gateway/GatewayLink";
import CopyToClipboard from "components/utils/CopyToClipboard";
import CompanyNavLink from "../../components/navigation/CompanyNavLink";
import { GatewayAsset } from "../../openapi/model/gatewayAsset";
import moment from "moment";
import GatewayStateLabel from "./gateway/GatewayStateLabel";
import PrivacyMode from "components/Text/PrivacyMode";
import { DeviceConfiguration } from "../../openapi/model/deviceConfiguration";
import { GatewayState } from "../../openapi/model/gatewayState";
import { WirelessReceiverState } from "../../openapi/model/wirelessReceiverState";
import AssetTypeChangeForm from "./AssetTypeChangeForm";
import useUpdateEntity from "../../hooks/updateEntity";
import ObjectPropertyItem from "../../components/Headers/ObjectPropertyItem";

export default function InnerAssetPreview(props: {
  asset?: Asset;
  assetPosition?: AssetPosition;
  location?: Location;
  refresh?: () => any;
}): JSX.Element {
  const { asset, assetPosition, location } = props;

  const updateEntity = useUpdateEntity<Asset>(
    "ASSET",
    asset?.assetId as string,
    props.refresh
  );

  const [openModal, modal] = AssetTypeChangeForm(
    asset as Asset,
    updateEntity,
    "Asset ",
    false
  );

  const assigned =
    asset?.assetPositionId !== undefined && asset?.assetPositionId !== null;
  const isGateway = asset?.assetType === "gateway" || false;
  const isReceiver = asset?.assetType === "wireless_receiver" || false;

  return (
    <Row>
      {modal}
      <Col xs={12} md={7}>
        <div className={"d-flex flex-wrap"}>
          {assigned ? (
            <>
          <ObjectPropertyItem title={"Location Address"} type={"heading"}>
                  <CompanyNavLink
                    className={"p-0 d-inline"}
                    to={`/admin/location/${location?.locationId}`}
                  >
                    <PrivacyMode>{location?.address}</PrivacyMode>
                  </CompanyNavLink>
          </ObjectPropertyItem>
          <ObjectPropertyItem title={"Position Reference"} type={"heading"}>
                  <CompanyNavLink
                    className={"p-0 d-inline"}
                    to={`/admin/asset_position/${assetPosition?.assetPositionId}`}
                  >
                    <PrivacyMode>
                      {assetPosition?.customerReference ||
                        "No Position Reference"}
                    </PrivacyMode>
                  </CompanyNavLink>
          </ObjectPropertyItem>            </>
          ) : (

          <ObjectPropertyItem title={"Location - Position"} type={"heading"}>
              <h2 className={"text-warning"}>Unassigned</h2>
          </ObjectPropertyItem>
          )}
          <ObjectPropertyItem title={"Serial Number"} type={"heading"}>
              {asset && (
                <CopyToClipboard
                  value={asset.serialNumber}
                  privacyMode={true}
                />
              )}
          </ObjectPropertyItem>
        </div>
      </Col>
      <Col xs={12} md={5}>
        <div
          className={
            "d-flex justify-content-md-end text-sm text-md-right text-left flex-wrap"
          }
        >
          <ObjectPropertyItem
            title={"Type"}
            editActionUserType={"superadmin"}
            editAction={
              asset?.assetPositionId === undefined ? openModal : undefined
            }
          >
            {asset && (
              <span>
                <AssetTypeHumanLabel asset={asset} />
              </span>
            )}
          </ObjectPropertyItem>
          <ObjectPropertyItem title={"Manufacturer"} type={"detail"}>
            {asset?.manufacturer || "-"}
          </ObjectPropertyItem>
          <ObjectPropertyItem title={"Model"} type={"detail"}>
            <span>{!isGateway ? asset?.version : asset?.model || "-"}</span>
          </ObjectPropertyItem>
          {!isGateway && (
              <GatewayLink deviceAssetPosition={assetPosition} />
          )}
          {isReceiver && (
          <ObjectPropertyItem title={"Status"} type={"detail"}>
              {asset && (
                <GatewayStateLabel
                  state={
                    (asset.state as WirelessReceiverState).configurationState
                  }
                  size={"sm"}
                  className={"px-1 py-1"}
                />
              )}
          </ObjectPropertyItem>
          )}
          {isGateway && (

              <>
          <ObjectPropertyItem title={"Last contact"} type={"detail"}>
                {asset && (
                  <HighlightNullAndOldDates
                    ageHighlight={moment.duration(1, "days")}
                  >
                    {(asset as GatewayAsset).lastContact}
                  </HighlightNullAndOldDates>
                )}
          </ObjectPropertyItem>
          <ObjectPropertyItem title={"Status"} type={"detail"}>
                {asset && (
                  <GatewayStateLabel
                    state={(asset.state as GatewayState).configurationState}
                    size={"sm"}
                    className={"px-1 py-1"}
                  />
                )}
          </ObjectPropertyItem>

          <ObjectPropertyItem title={"Firmware"} type={"detail"}>
                {(asset && (asset as GatewayAsset).firmwareVersion) || "-"}
          </ObjectPropertyItem>
                      <ObjectPropertyItem title={"IP Address"} type={"detail"}>

                <CopyToClipboard
                  value={(asset as GatewayAsset).ipAddress || "-"}
                  privacyMode={true}
                />
                      </ObjectPropertyItem>
                      <ObjectPropertyItem title={"Sim card"} type={"detail"}>

                <CopyToClipboard
                  value={(asset as GatewayAsset).simCardIccid || "-"}
                  privacyMode={true}
                />
                      </ObjectPropertyItem>
          </>
          )}
          {asset &&
            asset.configuration &&
            "mbus" &&
            (asset.configuration as DeviceConfiguration).mbus
              ?.addressingMode === "primary" && (
                        <ObjectPropertyItem title={"Addressing mode"} type={"detail"}>
                Primary (address:{" "}
                {
                  (asset.configuration as DeviceConfiguration).mbus
                    ?.primaryAddress
                }
                )
                        </ObjectPropertyItem>
            )}{" "}
          <ObjectPropertyItem title={"Asset Id"} type={"detail"}>
              <CopyToClipboard value={asset?.assetId} slice={8} />
          </ObjectPropertyItem>
          <ObjectPropertyItem title={"Created"} type={"detail"}>
            {formatDate(asset?.createdAt, undefined, false)}
          </ObjectPropertyItem>
        </div>
      </Col>
      {asset?.companyId === "NONE" && asset?.assetType === "gateway" && (
        <AssetCompanyAssignForm asset={asset} />
      )}
    </Row>
  );
}
