import {
  Entities,
  EntitiesIdMap,
  EntitiesPathMap,
} from "../constants/entities";
import Config from "../Config";
import useAxios, { UseAxiosResult } from "axios-hooks";
import { objectKeysToSnakeCase } from "../actions/helpers/apiService";
import { convertKeys } from "./getList";
import axios, { AxiosError } from "axios";
import _ from "lodash";
import { IsNotFoundError, NotFoundErrorForEntity } from "../model/error";

export interface GetEntityProps {
  entityType: Entities;
  entityId: string;
  companyId?: string;
  schemeId?: string;
  singletonApi?: boolean;
  manual?: boolean;
  entityKey?: string;
  path?: string;
}

export function convertBlankData<T>(obj: T): T | undefined {
  if (_.isEmpty(obj)) {
    return undefined;
  }
  return obj;
}

export function convertGetEntityResponseData<T>(
  singletonApi: boolean
): (data: any) => T {
  return (data): T => {
    return convertBlankData(
      convertKeys(singletonApi ? (data as T) : (data as Array<T>)[0])
    ) as T;
  };
}

const useGetEntity = <T,>({
  entityType,
  entityId,
  companyId,
  schemeId,
  singletonApi = false,
  manual = false,
  entityKey = undefined,
  path = undefined,
}: GetEntityProps): UseAxiosResult<T> => {
  if (!EntitiesIdMap[entityType] && !entityKey) {
    throw new Error(`${entityType} has no get method.`);
  }

  const url = Config.apiGateway.URL + "/" + (path || EntitiesPathMap[entityType]);

  const [{ data, error, loading }, refresh, cancel] = useAxios<T | Array<T>>(
    {
      url: path ? url : singletonApi ? url + "/" + entityId : url,
      params: objectKeysToSnakeCase(
        singletonApi
          ? {
              companyId,
              schemeId,
            }
          : {
              [entityKey || (EntitiesIdMap[entityType] as string)]: entityId,
              companyId,
              schemeId,
            }
      ),
    },
    { manual }
  );
  const convertedData: T | undefined =
    data && convertGetEntityResponseData<T>(singletonApi)(data);

  const actualError = !_.isNil(error)
    ? error
    : IsNotFoundError(error, convertedData)
    ? NotFoundErrorForEntity(entityType, entityId)
    : null;

  return [
    {
      error: actualError as unknown as AxiosError,
      loading,
      data: actualError ? undefined : convertedData,
    },
    (props) =>
      refresh(props).then((response) => {
        return {
          ...response,
          data: convertGetEntityResponseData<T>(singletonApi)(response.data),
        };
      }),
    cancel,
  ];
};

export interface EntityResult<T> {
  data?: T;
  error?: Error | null;
}

export const getEntity = <T,>({
  entityType,
  entityId,
  companyId,
  schemeId,
  singletonApi = false,
}: GetEntityProps): Promise<EntityResult<T>> => {
  if (!EntitiesIdMap[entityType]) {
    throw new Error(`${entityType} has no get method.`);
  }

  const url = Config.apiGateway.URL + "/" + EntitiesPathMap[entityType];
  const notFoundError = NotFoundErrorForEntity(entityType, entityId);

  return axios({
    url: singletonApi ? url + "/" + entityId : url,
    method: "GET",
    params: objectKeysToSnakeCase(
      singletonApi
        ? {
            companyId,
            schemeId,
          }
        : {
            [EntitiesIdMap[entityType] as string]: entityId,
            companyId,
            schemeId,
          }
    ),
  })
    .then((response): EntityResult<T> => {
      const data = convertBlankData(
        convertKeys(singletonApi ? response.data : response.data[0])
      );
      if (data) {
        return { data: data as T };
      } else {
        throw notFoundError;
      }
    })
    .catch((error: AxiosError) => {
      if (error?.response?.status === 400) {
        throw notFoundError;
      } else {
        throw error;
      }
    });
};

export default useGetEntity;
