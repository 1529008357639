import React from "react";
import {ParsedAssetDefinition} from "./UploadPage";
import UploadPreviewTable from "./UploadPreviewTable";

const requiredHeaders = [
  "serialNumber",
  "assetType",
  "address",
];

const optionalHeaders = [
  "assetPositionCustomerReference",
    "group",
    "locationReference",
    "configuration"
]


function SimpleAssetRegisterUploadPreview(props: {
  definitions: ParsedAssetDefinition[];
}): JSX.Element {
  const { definitions } = props;
  return <UploadPreviewTable definitions={definitions} requiredHeaders={requiredHeaders}
  optionalHeaders={optionalHeaders}
  uploadedEntityName={'asset'}/>
}

export default SimpleAssetRegisterUploadPreview;
