import React from "react";
import { Col, Container, Row } from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader";
import AssetPositionCountWidget from "../assets/widgets/AssetPositionCountWidget";
import LatestTimeSeriesStatsWidget from "../assets/widgets/LatestTimeSeriesStatsWidget";
import DailyReadSuccessWidget from "../assets/widgets/DailyReadSuccessWidget/DailyReadSuccessWidget";
import AlarmWidget from "../event/AlarmWidget";
import SelectedScheme from "components/Scheme/SelectedScheme";
import { useSelectedCompany } from "reducers/company";

export default function DashboardPage(): JSX.Element {
  const company = useSelectedCompany();
  return (
    <>
      <SimpleHeader hideBreadcrumbs={true} />
      {company && (
        <Container className="mt--6" fluid>
          <Row>
            <Col sm={12} md={4} lg={4} xl={4}>
              <LatestTimeSeriesStatsWidget
                title={"Asset Data Age"}
                group={"meter"}
              />
            </Col>
            <Col sm={12} md={4} lg={4} xl={4}>
              <LatestTimeSeriesStatsWidget
                title={"Gateway Last Contact"}
                group={"gateway"}
              />
            </Col>
            <Col sm={12} md={4} lg={4} xl={4}>
              <SelectedScheme>
                {(props) => <AlarmWidget {...props} />}
              </SelectedScheme>
            </Col>
            <Col sm={12} md={12} lg={6} xl={6}>
              <DailyReadSuccessWidget />
            </Col>
            <Col
              className={"d-md-none d-sm-none d-xs-none d-lg-block"}
              lg={6}
              xl={6}
            >
              <AssetPositionCountWidget />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}
