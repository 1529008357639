import React from "react";

import { useParams } from "react-router-dom";
import { SchemeElementProps } from "../../components/Scheme/SelectedScheme";
import GetAssetRegisterEntitiesCombination from "core/action/GetAssetRegisterEntitiesCombination";
import AssetPositionDisplay from "./AssetPositionDisplay";
import { ErrorBanner } from "components/Error/ErrorBanner";
import { OneCardPage } from "../../components/utils/OneCardPage";

const AssetPositionHomepage = (props: SchemeElementProps): JSX.Element => {
  const { assetPositionId } = useParams();

  if (assetPositionId === undefined) {
    throw new Error("AssetPositionId must be set.");
  }

  return (
    <GetAssetRegisterEntitiesCombination
      assetPositionId={assetPositionId}
      error={({ error }) => (
        <OneCardPage
          headerTitle={"Asset Position Detail"}
          breadcrumbs={[{ name: "Error" }]}
        >
          <ErrorBanner error={error} />
        </OneCardPage>
      )}
      placeholder={() => (
        <OneCardPage
          headerTitle={"Asset Position Detail"}
          loading={true}
          breadcrumbs={[{ name: "Loading" }]}
        >
          <></>
        </OneCardPage>
      )}
      {...props}
    >
      {(displayProps) => <AssetPositionDisplay {...displayProps} />}
    </GetAssetRegisterEntitiesCombination>
  );
};

export default AssetPositionHomepage;
