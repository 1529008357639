import React from "react";
import useGetEntity from "../../../hooks/getEntity";
import { ReportExecutionOut } from "../../../openapi/model/reportExecutionOut";

export interface ReportExecutionChildProps {
  reportExecution: ReportExecutionOut;
  refresh: () => void;
  loading: boolean;
}

export interface OptionalReportExecutionChildProps {
  reportExecution?: ReportExecutionOut;
  refresh: () => void;
  loading: boolean;
}

interface ReportExecutionProps {
  reportExecution: ReportExecutionOut;
  children: (props: ReportExecutionChildProps) => React.ReactElement;
}

interface ReportExecutionIdProps {
  reportExecutionId: string;
  companyId: string;
  children: (props: OptionalReportExecutionChildProps) => React.ReactElement;
}

type Props = ReportExecutionIdProps | ReportExecutionProps;

function isReportExecutionProps(props: Props): props is ReportExecutionProps {
  return (props as ReportExecutionProps).reportExecution !== undefined;
}

function ReportExecutionContainer(props: Props) {
  const [{ data, loading }, refresh] = useGetEntity<ReportExecutionOut>({
    entityType: "REPORT_EXECUTION",
    entityId: isReportExecutionProps(props)
      ? props.reportExecution.reportId
      : props.reportExecutionId,
    companyId: isReportExecutionProps(props)
      ? props.reportExecution.companyId
      : props.companyId,
    manual: !!isReportExecutionProps(props), // we don't need to load if passed an reportExecution
  });
  if (isReportExecutionProps(props)) {
    return props.children({
      reportExecution: data || props.reportExecution, // prioritise data incase of a refreshed item
      refresh,
      loading,
    });
  } else {
    return props.children({
      reportExecution: data,
      refresh,
      loading,
    });
  }
}

export default ReportExecutionContainer;
