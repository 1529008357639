import React from "react";
import { Col, Row } from "reactstrap";
import ButtonGroup from "../../components/Forms/ButtonGroup";
import ButtonGroupField from "components/Forms/ButtonGroupField";

interface WirelessClusterConfigurationFormProps {
  namespace: string;
}

export default function WirelessClusterConfigurationForm(
  props: WirelessClusterConfigurationFormProps,
): JSX.Element {
  const { namespace } = props;
  const withNamespace = (fieldName: string): string => {
    return namespace ? `${namespace}.${fieldName}` : fieldName;
  };

  return (
    <Row noGutters>
      <Col xs={12}>
        <h4>Wireless Cluster</h4>
      </Col>
      <Col xs={12}>
        <ButtonGroupField name={withNamespace("autoOptimiseAssets")}>
          {(fieldProps) => (
            <ButtonGroup
              label={{
                label:
                  "Daily Auto Optimise Receiver Positions and Sync Gateway Device Lists",
              }}
              options={[
                { label: "Yes", value: "true" },
                { label: "No", value: "false" },
              ]}
              {...fieldProps}
              fullWidth={false}
            />
          )}
        </ButtonGroupField>
      </Col>
    </Row>
  );
}
