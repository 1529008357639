import React, { ChangeEvent } from "react";
import classNames from "classnames";
import LabelWrapper, { FormFieldProps } from "./LabelWrapper";

export interface Option {
  value?: string | boolean | number;
  name: string;
}

export interface RadioButtonGroupProps {
  fieldName: string;
  title: string;
  errors?: any;
  touched?: any;
  currentValue: any;
  options: (Option | string)[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  buttonSize?: "btn-sm" | "btn-md" | "btn-lg";
  disabled?: boolean;
  fullWidth?: boolean;
}

export default function RadioButtonGroup(
  props: RadioButtonGroupProps &
    React.HTMLAttributes<React.InputHTMLAttributes<string>> &
    FormFieldProps
): JSX.Element {
  const {
    fieldName,
    title,
    currentValue,
    options,
    setFieldValue,
    onChange,
    buttonSize = "btn-md",
    fullWidth = false,
    ...rest
  } = props;

  const radio = (
    key: number,
    value: string | boolean | number,
    name: string
  ): JSX.Element => {
    return (
      <label
        className={classNames({
          "btn btn-primary": true,
          [buttonSize]: true,
          " active": currentValue === value,
          " disabled": props.disabled,
        })}
        key={key}
        data-cy={`option-${value}`}
      >
        <input
          type="radio"
          name={fieldName}
          id={value.toString()}
          autoComplete="off"
          onChange={(e): void => {
            setFieldValue(fieldName, value);
            if (onChange) {
              onChange(e);
            }
          }}
          checked={currentValue === value}
          disabled={props.disabled}
        />
        {name}
      </label>
    );
  };

  return (
    <LabelWrapper label={title} fieldName={fieldName} {...rest}>
      <div
        className={classNames({
          "btn-group btn-group-toggle": true,
          "w-100 px-0": fullWidth,
        })}
        data-toggle={fieldName}
        data-cy={`btn-group-${fieldName}`}
        data-testid={fieldName}
      >
        {options?.map((o: string | Option, i): JSX.Element => {
          if (typeof o === "string") {
            return radio(i, o, o);
          } else {
            return radio(
              i,
              o.value !== undefined ? o.value : "undefined",
              o.name
            );
          }
        })}
      </div>
    </LabelWrapper>
  );
}
