import React from 'react';
import {AssetRegisterItem} from "../../openapi/model/assetRegisterItem";
import {Col, Row} from "reactstrap";
import AssetPositionNumbersToStringList from "../../components/AssetPosition/AssetPositionNumberSummaryList";
import FormatTextAtSize from "../../components/Text/FormatTextAtSize";
import AssetPositionIdLink from "../../components/AssetPosition/AssetPositionIdLink";

function IntegrationListRow(props: {     showScheme?: boolean;
    assetRegisterItem: AssetRegisterItem;
    index: number;
    refresh?: () => void;
}) {
    const {assetRegisterItem, index} = props;
    return <li
      className={"checklist-entry list-group-item px-0 px-md-2"}
      data-cy={"asset-register-list-item"}
     key={index}>
            <Row>
            <Col sm={8} md={5} lg={3} className={'px-1'}>

                <AssetPositionIdLink
                    assetPosition={assetRegisterItem.assetPosition}
                    text={assetRegisterItem.location.address}
                    />
            </Col>
            <Col sm={4} md={3} lg={3}>
<FormatTextAtSize breakpoint={'sm'} smallClassName={'text-sm'} smallBrackets={true}>
                    {assetRegisterItem.assetPosition.configuration?.integration?.integrationType}
</FormatTextAtSize>
            </Col>
            <Col className={'d-none d-md-block'} md={4} lg={6}>            <AssetPositionNumbersToStringList
              numbers={assetRegisterItem.assetPosition.childAssetPositions}
              includeGateways={false}
            /></Col>
</Row>
    </li>
}

export default IntegrationListRow;